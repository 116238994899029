import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, Checkbox,Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

const ProductGroupEntry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "product-group-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});

  const { refetch } = useQuery(
    "productGroupEntry",
    api.getProductGroupEntry33,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // Checkbox
  // function RowInputData(props: any, type = null) {
  //   const rowData = props.cell._cell.row.data;
  //   let fieldName = props.field;
  //   let fieldType = props.fieldType;

  //   switch (fieldType) {
  //     case "check-box":
  //       //console.log(rowData);
  //       if (rowData.type === "BMajorGroup" || rowData.type === "isCOVID") {
  //         return <></>;
  //       } else {
  //         return (
  //           <Checkbox
  //             color="primary"
  //             checked={rowData[fieldName]}
  //             name={fieldName}
  //             value={rowData[fieldName]}
  //           />
  //         );
  //       }

  //     default:
  //       return <></>;
  //   }
  // }
  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;

    switch (fieldType) {
      case "check-box":
        //console.log(rowData);
        return rowData.type == "BMajorGroup" || rowData.type == "isCOVID" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );

      default:
        return <></>;
    }
  }

  const { mutate: deleteApi } = useMutation(api.deleteProductGroup, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        // api.getAllDropdown("RouteOfAdminlist").then((response) => {
        //   localStorage.setItem(
        //     "RouteOfAdminlist",
        //     JSON.stringify(response.datalist.RouteOfAdminlist)
        //   );
        // });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        refetch();
        queryClient.getQueriesData("productGroupEntry");
        //  swal(data.data.message, "", "success");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        // swal(data.data.message, "", "error");
      }
    },
  });
  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=ProductGroupEntryExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" +
      Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editunit(rowData);
  };

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      formatter: "rownum",
      width: 100,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group Name"],
      field: "GroupName",
      // width: "30%",
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Short Name"],
      field: "ShortName",
      // width: "28%",
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Major Group"],
      field: "BMajorGroup",
      hozAlign: "center",
      headerHozAlign: "center",
      // width: "13%",
      headerSort: true,
      formatter: reactFormatter(
        <RowInputData
          labelName="BMajorGroup"
          field="BMajorGroup"
          fieldType="check-box"
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Is Covid"],
      field: "isCOVID",
      hozAlign: "center",
      headerHozAlign: "center",
      // width: "13%",
      headerSort: true,
      formatter: reactFormatter(
        <RowInputData
          labelName="isCOVID"
          field="isCOVID"
          fieldType="check-box"
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 140,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editunit = (data) => {
    let route = `product-group-entry/edit/${data.id}`;
    props.history.push(route);
  };

  /*
  const deleteunit = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this Record!"
        ]
      ),
      icon: "warning",
      buttons: [
        t(DispensingLanguage[lan][menukey]["No"]),
        t(DispensingLanguage[lan][menukey]["Yes"]),
      ],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({
          ItemGroupId: data.id,
          menukey: menukey,
          lan: lan,
          UserName: UserName,
          action: "dataDalete",
        });
      }
    });
  };

  */


  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            //deleteunit(rowData);
            modalPopupDelete(deleteApi, rowData.id);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Product Group Entry"]}
              </div>
              <div className="float-right sw_btn_control">
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/product-group-entry/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add"]}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i className="fas fa-print"></i>
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height={"450px"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductGroupEntry;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
