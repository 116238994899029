import React, { useState, useEffect } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import { addDays, subDays } from "date-fns";

import { useQuery } from "react-query";
import * as api from "../../actions/api";
import { useMutation } from "react-query";

import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Button,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  //  Link,
  //  AddToHomeScreen,
  Launch,
} from "@material-ui/icons";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

// import DefaultFooter from "components/Footers/DefaultFooter";
import { getDefaultMonthYear } from "../../services/Common";
import { useTranslation } from "react-i18next";
import {
  checkLogin,
  checkUserPermission,
} from "../../services/CheckUserAccess";
import { ReactTabulator } from "react-tabulator";

import MapLeaflet from "./Leaft_Map/MapLeaflet.js";

//National Stock Summary (Month of Stock = MOS)

const Dashboard = (props) => {

  const [MapInfo, setMapInfo] = useState([]);
  /*
  const [MapInfo, setMapInfo] = useState([
    {
      District: "PPMR de Kayes",
      TotalBalance: "0",
      Latitude: "14.4367876",
      Longitude: "-11.44527",
    },
    {
      District: "PPMR de Bamako",
      TotalBalance: "11",
      Latitude: "12.638002299692525",
      Longitude: "-8.000339086767143",
    },
    {
      District: "PPMR de Kidal",
      TotalBalance: "0",
      Latitude: "18.4553483",
      Longitude: "1.4119709",
    },
    {
      District: "Depot de BANAMBA",
      TotalBalance: "7",
      Latitude: "13.5543249",
      Longitude: "-7.443544",
    },
    {
      District: "Depot de Bandiagara",
      TotalBalance: "2",
      Latitude: "14.5370435",
      Longitude: "-3.662323",
    },
    {
      District: "Depot de BARAOUELI",
      TotalBalance: "45",
      Latitude: "13.0693811",
      Longitude: "-6.83242",
    },
    {
      District: "Depot de Koutiala",
      TotalBalance: "33",
      Latitude: "12.3774106",
      Longitude: "-5.472538",
    },
    {
      District: "Depot de Dire",
      TotalBalance: "45",
      Latitude: "16.2661738",
      Longitude: "-3.404417",
    },
    {
      District: "CERKES Sikasso",
      TotalBalance: "10",
      Latitude: "11.3223834",
      Longitude: "-5.698397",
    },
    {
      District: "KONDO",
      TotalBalance: "42",
      Latitude: "14.55792",
      Longitude: "-4.889638",
    },
    {
      District: "DOUALE",
      TotalBalance: "45",
      Latitude: "14.34185",
      Longitude: "-10.53209",
    },
  ]);
  */
  

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t } = useTranslation();
  const menukey = "dashboard";
  const FacilityId = localStorage.getItem("FacilityId");
  
  const bFacilitySelected = localStorage.getItem("bFacilitySelected");
  const [activeProductfre, setProductRefec] = useState("");
  //const IsCovid = JSON.parse(localStorage.getItem("IsCovid"));

  //const [isCOVIDGrp, setisCOVIDGrp] = useState(IsCovid);

  exporting(Highcharts);
  const classes = useStyles();

  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroupForDashboard"));
  
  
  const [currProductGroupId, setCurrProdutlist] = useState(
    ProductGroup.length > 0 ? ProductGroup[0].id : ""
  );
  // const [ProductGroupName, setProductGroupName] = useState("");

  const [ProductGroupName, setProductGroupName] = useState(ProductGroup[0].name);

  const [CurrVaccineMonthId, setCurrVaccineMonthId] = useState(6);

  let TempZoneList = useState([]);
  const [Product_list, setProductData] = useState(TempZoneList);

  const [currProductId, setProductId] = useState(
    Product_list.length > Product_list ? Product_list[0].id : "0"
  );

  /*
  const [isCOVIDGrp, setisCOVIDGrp] = useState(0);

  if(Product_Group.length > 0){
    setisCOVIDGrp(Product_Group[Product_Group.findIndex(Arr_List => Arr_List.id == currProductGroupId)]['isCOVID']);
  }

  */

  // Facility Change
  const handleProductChange = (event, newValue) => {
    // console.log("hello",newValue.isCOVID);

    let rowId = "";
    let rowName = "";
    //let CovidGrp = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
     // CovidGrp = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
      //CovidGrp = newValue.isCOVID;
    }
    if (rowId !== currProductGroupId) {
      setCurrProdutlist(rowId);
      setProductGroupName(rowName);
      //setisCOVIDGrp(CovidGrp);
    }
    getProductData(rowId);
  };

  const getProductData = (rowId) => {
    let ProductParam = {
      action: "getItemListForDashboard",
      ItemGroupId: rowId,
      menukey: menukey,
      lan: lan,
    };
    cProductList(ProductParam);
  };

  const handleDateRangChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== CurrVaccineMonthId) {
      setCurrVaccineMonthId(rowId);
    }
  };

  const { mutate: cProductList } = useMutation(
    api.getProductListForDashboard, //api name
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setProductData(data.data.datalist);
          setProductRefec(data?.data?.datalist[0]);
          setProductId(data?.data?.datalist[0]?.id || "0");

          // setProductRefec({
          //   id: "0",
          //   name: t(DispensingLanguage[lan][menukey]["All"]),
          // });
        }
      },
    }
  );

  // ====TOTAL FACILITY END====

  // let All_ProduccgtList = {
  //   id: "0",
  //   name: t(DispensingLanguage[lan][menukey]["All"]),
  // };
  // const getProductList = [All_ProduccgtList].concat(Product_list);

  // console.log(getProductList, '==========')

  //console.log(isCOVIDGrp);

  //if (isCOVIDGrp == 1) {
  // const [currProductId, setProductId] = useState(
  //   Product_list.length > 0 ? Product_list[0].id : ""
  // );
  //} else {
  // const [currProductId, setProductId] = useState(
  //   Product_list.length > 0 ? Product_list[0].id : ""
  // );
  //}

  const [ProductName, setProductName] = useState([]);

  const handleProductListChange = (event, newValue) => {
    setProductRefec(newValue);
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductId) {
      setProductId(rowId);
      setProductName(rowName);
    }
  };

  React.useEffect(() => {
    if (currProductId === "0") {
      getProductData(currProductGroupId);
    }
  }, [currProductId]);

  // const [pickerData, setPickerData] = useState([
  //   {
  //     startDate: subDays(new Date(), 29),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  /*
  let currDate = new Date();
  let defaultYear = currDate.getFullYear();
  let defaultMonthId = currDate.getMonth();
  //0=jan
  if (defaultMonthId == 0) {
    defaultYear = defaultYear - 1;
    defaultMonthId = 12;
  } else {
    defaultMonthId = defaultMonthId;
  }

  */

  const goToStockStatusDetails = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/stock-status-details`);
  };

  const goToStockSummary = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/stock-summary`);
  };

  const goToConsumptionTrends = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +
        `/consumption-trends-for-the-top-five-products`
    );
  };

  const goToAMCTrends = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/amc-trends-for-the-top-five-products`
    );
  };

  const goToTracerCommodities = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME +
        `/percentage-of-facilities-with-a-stockout-of-tracer-commodities`
    );
  };

  const goToAvailabilityOfCommodities = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/availability-of-commodities`
    );
  };

  // const [isLoadingDashboard, setLoading] = useState(true);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );

  //const [currFacilityId, setCurrFacilityId] = useState(0);
  const [currFacilityId, setCurrFacilityId] = useState(
    bFacilitySelected === 1 ? FacilityId : 0
  );

  
  const [VaccinationTrendData, setVaccinationTrendData] = useState([]);
  
  // Vaccination Trend
  const getVaccinationTrendData = useQuery(
    ["VaccinationTrendData"],
    () =>
      api.getVaccinationTrendData(
        currYearId,
        currMonthId,
        currFacilityId,
        CurrVaccineMonthId,
        currProductId,
        currProductGroupId
      ),
    {
      onSuccess: (data) => {
        const month_name = data.month_name;
        let DataArr = data.Administered;
        const Administered = DataArr.map(parseFloat);

        setVaccinationTrendData({
          chart: {
            type: "line",
            height: 350,
            marginRight: 0,
            marginBottom: 50,
          },
          title: {
            text: null,
          },

          subtitle: {
            text: null,
          },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
          yAxis: {
            title: {
              text:null, //t(DispensingLanguage[lan][menukey]["Administered Dose"]),
            },
            x: -50,
            y: -50,
          },

          xAxis: {
            categories: month_name,
            crosshair: true,
            labels: {
              enabled: false,
            },
          },
          legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
          },

          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
              pointStart: 0,
            },
          },

          series: [
            {
              name: t(DispensingLanguage[lan][menukey]["Administered Dose"]),
              data: Administered,
            },
          ],
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                  },
                },
              },
            ],
          },
        });
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

 
 // Get Map 

  const getLeaftMapData = useQuery(
    ["gLeaftMapData"],
    () => api.getLeaftMapData(currProductGroupId, currProductId),
    {
      onSuccess: (data1) => {  
        console.log("Lot 11", data1.aaData);    
        setMapInfo(data1.MapData);       
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );


  const [NStockBalance, setNStockBalance] = useState(0);

  const getNationalBalance = useQuery(
    ["NationalBalance"],
    () => api.getNationalBalance(currProductGroupId, currProductId),
    {
      onSuccess: (data1) => {
        //console.log("ddddd", data1[0].NationalStockBalance);
        if(data1[0].NationalStockBalance!=null){
          setNStockBalance(data1[0].NationalStockBalance);         
        }else{
          setNStockBalance(0);
        }
        
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );


  const [StockOutSite, setStockOutSite] = useState(0);

  const getStockOutSite = useQuery(
    ["StockOutSite"],
    () => api.getStockOutSite(currProductGroupId, currProductId),
    {
      onSuccess: (data1) => {
        //console.log("data1 ------------dd", data1);
        setStockOutSite(data1);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );


  

  

  const [RedirectLogin, setRedirectLogin] = React.useState(true);

  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey)); // To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  /*
  const DateRangsList = [
    { name: "All", id: "" },
    { name: "7d", id: 6 },
    { name: "15d", id: 14 },
    { name: "1M", id: 29 },
    { name: "3M", id: 89 },
    { name: "1Y", id: 364 },
  ];
  */

  const DateRangsList = [
    { name: "7 D", id: 6 },
    { name: "15 D", id: 14 },
    { name: "1 M", id: 29 },
    { name: "3 M", id: 89 },
    { name: "1 Y", id: 364 },
  ];

  // Start Stock By Expiry Date Pie
  let initialstock_by_expiry_date = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 160,
    },
    legend: {
      align: "right",
      layout: "vertical",
      verticalAlign: "middle",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          style: {
            textShadow: false,
            textOutline: false,
          },
          formatter: function () {
            return Highcharts.numberFormat(this.y);
          },
        },
        tooltip: {
          shared: true,
        },
      },
      pie: {
        plotBorderWidth: 0,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          style: {
            textShadow: false,
            textOutline: false,
          },
          format: "{point.name}: <b>{point.y:.0f} ({point.percentage:.1f} %)",
        },
        showInLegend: true,
        tooltip: {
          pointFormat:
            "{series.name}: <b>{point.y:.0f} ({point.percentage:.1f}%)",
          shared: true,
        },
      },
    },

    series: [
      {
        name: DispensingLanguage[lan][menukey]["# of Lots"],
        colorByPoint: true,
        data: [],
      },
    ],
  };

  const [stock_by_expiry_date, setOption_stock_by_expiry_date] = useState(
    initialstock_by_expiry_date
  );

  const getStockByExpiry = useQuery(
    ["StockByExpiry", currProductGroupId, currProductId],
    () => api.getStockByExpiryDateChartData(currProductGroupId, currProductId),
    {
      onSuccess: (data) => {
        setOption_stock_by_expiry_date({
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: 383,
          },
          title: {
            text: "",
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              },
            },
          },
          credits: {
            enabled: false,
          },

          series: [
            {
              // name: DispensingLanguage[lan][menukey]["# of Lot"],
              name: DispensingLanguage[lan][menukey]["# of Lots"],
              colorByPoint: true,
              data: data?.series_data || [],
              // data: [{"name":"No Expiry","legendIndex":4,"y":790},{"name":"No Expiry","legendIndex":4,"y":900},{"name":"1 M","legendIndex":null,"y":700},{"name":"1 M","legendIndex":null,"y":500},{"name":"No Expiry","legendIndex":4,"y":550},{"name":"No Expiry","legendIndex":4,"y":600},{"name":"No Expiry","legendIndex":4,"y":500}]
            },
          ],
        });
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // End Stock By Expiry Date Pie

  // START OF RECEIVE DETAILS DONUT
  const initial_receive_details = {
    chart: {
      borderColor: "gray",
      backgroundColor: "transparent",
      //width:190,
      // width: 180,
      // height: 160,
      height: 160,
      borderWidth: 0,
      plotBorderWidth: 0,
      style: {
        fontFamily: "inherit",
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    title: {
      text: null, // '<a href="javascript:void(0)" " ><p style="font-size: 12px!important;font-weight:normal!important;">' +aaDataArray[i]['itemgroupname']+ '</p></a>',
      useHtml: true,
      align: "center",
      verticalAlign: "top",
      y: 1,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.stockValue:.0f} ({point.percentage:.1f}%)",
      shared: true,
    },
    legend: {
      align: "right",
      layout: "vertical",
      verticalAlign: "middle",
    },
    plotOptions: {
      pie: {
        showInLegend: true,
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["52%", "85%"],
        //center: ['50%', '30%'],
        //size: '180%'
        size: "160%",
      },
    },
    series: [
   /*   {
        type: "pie",
        name: "sxxx",
        innerSize: "70%",
        data: [
        /* { name: "No Expiry", legendIndex: 7, y: 2 },
          { name: "1 M", legendIndex: 0, y: 1 },
          {
            name: "1 M",
            legendIndex: 0,
            y: 1,
          },
        ],
      },*/
    ],
  };

  const [receive_details, setOption_receive_details] = useState(
    initial_receive_details
  );

  const getReceiveDetails = useQuery(
    ["ReceiveDetails", currProductGroupId, currProductId],
    () => api.getReceiveDetailsChartData(currProductGroupId, currProductId),
    {
      onSuccess: (data) => {
        console.log(currProductId, "=============");
        console.log(data, "============+++++++++++");

        setOption_receive_details({
          chart: {
            borderColor: "gray",
            backgroundColor: "transparent",
            //width:190,
            // width: 180,
            // height: 160,
            height: 160,
            borderWidth: 0,
            plotBorderWidth: 0,
            style: {
              fontFamily: "inherit",
            },
          },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: DispensingLanguage[lan][menukey]["Stock Status at Different Level"] , // '<a href="javascript:void(0)" " ><p style="font-size: 12px!important;font-weight:normal!important;">' +aaDataArray[i]['itemgroupname']+ '</p></a>',
            useHtml: true,
            align: "center",
            verticalAlign: "top",
            y: 3,
          },
          tooltip: {
            pointFormat: DispensingLanguage[lan][menukey]["Stock"] + " : <b>{point.stockValue}",
            shared: true,
          },
          legend: {
            align: "right",
            layout: "vertical",
            verticalAlign: "middle",
          },
          plotOptions: {
            pie: {
              showInLegend: true,
              dataLabels: {
                enabled: false,
                distance: -50,
                style: {
                  fontWeight: "bold",
                  color: "white",
                },
              },
              startAngle: -90,
              endAngle: 90,
              center: ["52%", "85%"],
              //center: ['50%', '30%'],
              //size: '180%'
              size: "160%",
            },
          },
          series: [
            {
              type: "pie",
              name: "sxxx",
              innerSize: "70%",
              data: data?.series_data || [],
              // data: [
              //   { "name": "No Expiry", "legendIndex": 7, "y": 2 }, { "name": "1 M", "legendIndex": 0, "y": 1 }, {
              //     "name": "1 M", "legendIndex": 0, "y": 1
              //   }]
            },
          ],
        });
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // END OF RECEIVE DETAILS DONUT

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 60,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["District"],
      field: "District",
      headerSort: false,
      // width: 85,
    },
    {
      title: DispensingLanguage[lan][menukey]["TotalBalance"],
      field: "TotalBalance",
      headerSort: false,
      width: 160,
      hozAlign: "right",
      headerHozAlign: "right",
    },
  ];

  const [dataItems, setDataItems] = useState([]);

  const getDistrictWiseBalance = useQuery(
    ["Dashboard111", currProductGroupId, currProductId],
    () => api.getDistrictWiseBalance(currProductGroupId, currProductId),
    {
      onSuccess: (data) => {
        // console.log(data, '===================')
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";

    window.open(
      finalUrl +
        "?action=DistrictWideTotalBalanceExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now() +
        "&productGroupId=" +
        currProductGroupId +
        "&ProductGroupName=" +
        ProductGroupName +
        "&productId=" +
        currProductId +
        "&ProductGroup=" +
        activeProductfre.name
    );
  };

  /* =====End of Excel Export Code==== */


  useEffect(() => {
    //console.log("useEffect calling for refresh");

    if (currProductGroupId > 0) {
      getNationalBalance.refetch();
      getStockOutSite.refetch();
      getVaccinationTrendData.refetch();
      getLeaftMapData.refetch();
      getDistrictWiseBalance.refetch();
      //getReceiveDetails.refetch();
    }
  }, [currProductGroupId, currProductId]);

  useEffect(() => {
    if (CurrVaccineMonthId > 0) {
      getVaccinationTrendData.refetch();
    }
  }, [CurrVaccineMonthId]);

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div className="section signup-top-padding sw_inner_container">
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Dashboard"])}
                </div>
              </div>
            </div>
            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="ProductGroup"
                        disableClearable
                        options={ProductGroup}
                        onChange={(event, newValue) =>
                          handleProductChange(event, newValue)
                        }
                        getOptionLabel={(option) => option.name || ""}
                        defaultValue={
                          ProductGroup[
                            ProductGroup.findIndex(
                              (ProductGroup) =>
                                ProductGroup.id == currProductGroupId
                            )
                          ]
                        }
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              DispensingLanguage[lan][menukey]["Product Group"]
                            }
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="getProductList"
                        disableClearable
                        options={Product_list}
                        value={activeProductfre}
                        onChange={(event, newValue) =>
                          handleProductListChange(event, newValue)
                        }
                        getOptionLabel={(option) => option.name || ""}
                        defaultValue={
                          Product_list[
                            Product_list.findIndex(
                              (Product_list) => Product_list.id == currProductId
                            )
                          ]
                        }
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Product"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                 
                 

                  <Grid item xs={6} sm={6}></Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}
            <div className="row mb-3">


              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <Card className="sw_card">
                      
                      
                      <CardContent>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="stat-cell bgPrimary text-center pt-4 dashboard_block">
                              <i className="fa fa-file bg-icon"></i>
                              <span className="text-xlg text-center">
                                {NStockBalance}
                              </span>
                              <br></br>
                              <span className="text-bg">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "NATOINAL STOCK BALANCE"
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>


              




              <div className="col-5">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <Card className="sw_card">
                          
                          <CardContent>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={receive_details}
                            />
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-3">
                <div className="row">
                  <div className="col-12">
                    <Card className="sw_card">
                      
                      
                      <CardContent>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="stat-cell bgPrimary text-center pt-4 dashboard_block">
                              <i className="fa fa-file bg-icon"></i>
                              <span className="text-xlg text-center">
                                {StockOutSite}
                              </span>
                              <br></br>
                              <span className="text-bg">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "STOCKOUT_SITES"
                                  ]
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>



            </div>
            {/* end row */}

            {/* new row */}
            <div className="row mb-3">
              
              
              

              <div className="col-md-6">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey]["FACILITY STOCK STATUS"]
                    }
                  />
                  <CardContent>
                    <MapLeaflet MapData={MapInfo} loadMap={7} {...props} />
                  </CardContent>
                </Card>
              </div>

              <div className="col-md-6">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey][
                        "District Wise Total Balance"
                      ]
                    }
                    action={
                      <div className="float-right sw_btn_control">
                      <Button
                     color="primary"
                     variant="contained"
                     className="mr-2 mt-1 sw_icons"
                     onClick={() => PrintPDFExcelExportFunction("print")}
                   >
                     <i class="fas fa-print"></i>
                   </Button>
 
                   <Button
                     color="primary"
                     variant="contained"
                     className="mr-2 mt-1   sw_icons"
                     onClick={() => PrintPDFExcelExportFunction("excel")}
                   >
                     <i className="far fa-file-excel"></i>
                   </Button>
 
                 </div>
                    }
                  />
                  <CardContent>
                    <ReactTabulator
                      height={380}
                      columns={columns}
                      data={dataItems}
                      layout={"fitColumns"}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}

            {/* new row */}
            <div className="row">


            <div className="col-md-6">
                <Card className="sw_card">
                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey]["VACCINATION TREND"]
                    )}
                    // action={
                    //   <a
                    //     href="javascript:void(0);"
                    //     className="HyColor"
                    //     onClick={goToAMCTrends}
                    //   >
                    //     <Launch />
                    //   </a>
                    // }
                  />

                  <CardContent>
                    <div className="row">
                      <div className="col-md-4">
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="ProductGroup"
                            disableClearable
                            options={DateRangsList}
                            onChange={(event, newValue) =>
                              handleDateRangChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name || ""}
                            defaultValue={
                              DateRangsList[
                                DateRangsList.findIndex(
                                  (DateRangsList) =>
                                    DateRangsList.id == CurrVaccineMonthId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label={
                                //   DispensingLanguage[lan][menukey]["Product Group"]
                                // }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="col-md-8"></div>
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={VaccinationTrendData}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              

              <div className="col-md-6">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <Card className="sw_card">
                          <CardHeader
                            title={
                              DispensingLanguage[lan][menukey][
                                "Stock by Expiry Date"
                              ]
                            }
                          />
                          <CardContent>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={stock_by_expiry_date}
                            />
                          </CardContent>
                        </Card>
                      </div>
                    </div>
                  </div>

                  
                  
                </div>
              </div>





            </div>
            {/* end row */}

            
          </div>
        </div>
      </>
    )
  );
};

export default Dashboard;

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
