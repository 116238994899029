import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
import { checkLogin, checkUserPermission, getUserActionPermission } from "../../../services/CheckUserAccess";

import swal from "sweetalert";
//screen
import DataExtractTool from "./DataExtractTool";
import { useEffect } from "react";
const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "unit-of-measure-entry-form";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);

  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);


  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    setMsgObj(obj);
  };
  const closeNoticeModal = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgObj({ isOpen: false });
  };
  return (
    hasUserPermission && (
      <div>
        <AfterLoginNavbar {...props} />
        <div className="section signup-top-padding sw_inner_container">

          <Switch>
            <Route
              path={`${path}/`}
              render={(props) => (
                <DataExtractTool {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
          </Switch>
          <Notification
            closeNoticeModal={closeNoticeModal}
            msgObj={msgObj}
            {...props}
          ></Notification>

        </div>
      </div>)
  );
};

export default Index;

const useStyles = makeStyles({

});
