import React, { useState } from "react";

import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";

import swal from "sweetalert";
import * as api from "../../../actions/api";
import StockTakeFormData from "./StockTakeFormData.js";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EditStockTake = (props) => {
  const FacilityId = localStorage.getItem("FacilityId");
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const menukey = "stock-take";
  const lan = localStorage.getItem("LangCode");
  const initFacilityData = JSON.parse(localStorage.getItem("initFacilityData"));

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    StockTakeDate: "",
    CompleteDate: "",
    PreparedBy:UserId,
   // ApprovedBy: "",
    ManyJsonSave: "",
    FacilityId: FacilityId,
    BmasterDirty: 0,
    Remarks:""
  });

  const [IsCompleted, setIsCompleted] = useState("0");
  const [ManyTableData, setManyTableData] = useState([]);
  const [StockTakeId, setStockTakeId] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [isFirstPhysical, setIsFirstPhysical] = useState(0);
  const [bFirst, setBFirst] = useState(true);
  const [deletedDataSet, setDeletedDataSet] = useState([]);
  const handleDataTableChange = (e) => {};

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };

  //console.log("Stock Take SAVE- ", formData.ItemGroupId);

  const [selecteEnddDate, setSelecteEnddDate] = useState(
    parseInt(initFacilityData[0].InitComplete)
      ? initFacilityData[0].validStartDate
      : moment().format("YYYY-MM-DD")
  );

  const { data, refetch } = useQuery(
    ["stock-take", id],
    () =>
      api.getStockTakeInv(id).then((res) => {
        setStockTakeId(id);
        setFormData(res.data);
        setIsCompleted(res.data.IsCompleted);
        setManyTableData(res.data.ManyJsonSave);
        setUpdatedDetailGridData(res.data.ManyJsonSave);
        setIsFirstPhysical(res.data.IsFirstPhysical);
        setBFirst(true);
        return res.data;
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
      enabled: Boolean(id),
    }
  );

  /*

  const { mutate: mutateUpdate } = useMutation(api.updateStockTakeInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        refetch();
        if (data.success == 1) {
          if (data.IsCompleted == 1) {
            mutatePost(StockTakeId);
          } else {
            let route = `${data.data.StockTakeId}`;
            setIsCompleted(data.IsCompleted);
            setManyTableData(data.data.ManyJsonSave);
            props.history.push(route);

            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });



  const { mutate: mutatePost } = useMutation(api.postStockTakeInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        refetch();
        setIsCompleted(1);
        if (data.success == 0) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      }
    },
  });

  */

  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };

  /*
  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (validateForm(formData)) {
      let fDtat = { ...formData, ManyJsonSave: rows };
      mutateUpdate(fDtat);
    }

    setManyTableData(rows);
    
  };

  */

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;
    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
  };

  const handleReset = () => {
    setFormData({
      StockTakeDate: "",
      CompleteDate: "",
      StockTakeId: "",
      PreparedBy:UserId,
      Remarks:""
     // ApprovedBy: "",
    });
  };
  const deleteAdjustmentInv = (data) => {
    if (data.StockTakeItemsId != null) {
      let newDeletedDataSet = [data.StockTakeItemsId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
    }
  };

  const validateForm = (formData, checkSupplierInv) => {
    // let validateFields = [ "StockTakeDate", "CompleteDate","PreparedBy"]

    let validateFields =
      checkSupplierInv == 2
        ? ["StockTakeDate", "CompleteDate"]
        : ["StockTakeDate"];

    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = "field is Required !";
        isValid = false;
      }
    });
    setErrorObject(errorData);

    setDeletedDataSet([]);
    return isValid;
  };

  /*
  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let FinalData = { ...formData, ManyJsonSave: ManyTableData };
      mutateUpdate(FinalData);
    }
  };

  const handlePost = async (e) => {
    if (validateForm(formData)) {
      
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to post the stock?"
          ]
        ),
        icon: "warning",
        buttons: [
          t(DispensingLanguage[lan][menukey]["No"]),
          t(DispensingLanguage[lan][menukey]["Yes"]),
        ],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          let FinalData = {
            ...formData,
            ManyJsonSave: ManyTableData,
            IsCompleted: 1,
          };
          mutateUpdate(FinalData);
        } else {
          setIsCompleted(0);
          let route = `${StockTakeId}`;
          props.history.push(route);
        }
      });
      
    }
  };


  */

  function checkValidateForm(rows, callfrom) {
    if (callfrom == "edit") {
      setManyTableData(rows);
    }

    if (validateForm(formData, 1)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: rows,
        deletedDataSet: deletedDataSet,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      return fDtat;
    } else {
      return false;
    }
  }

  function checkValidateFormPost(rows, callfrom) {
    if (callfrom == "edit") {
      setManyTableData(rows);
    }

    if (validateForm(formData, 2)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: rows,
        deletedDataSet: deletedDataSet,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      return fDtat;
    } else {
      return false;
    }
  }

  function successSave(bStockUpdated) {
    if (bStockUpdated !== 1) {
      refetch();
    }
  }

  function stockPosted(val) {
    setIsCompleted(val);
  }

  /*
useEffect(() => {    
}, [ManyTableData]); 

*/

  return (
    <>
      <StockTakeFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleRDateChange={handleRDateChange}
        handleReset={handleReset}
        //handleUpdate={handleUpdate}
        handleAdd={handleAdd}
        ManyTableData={updatedDetailGridData}
        handleDataTableChange={handleDataTableChange}
        IsCompleted={IsCompleted}
        selecteEnddDate={selecteEnddDate}
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        //handlePost={handlePost}
        refetch={refetch}
        bFirst={bFirst}
        isFirstPhysical={isFirstPhysical}
        handleChangeChoosenProps={handleChangeChoosenProps}
        checkValidateForm={checkValidateForm}
        checkValidateFormPost={checkValidateFormPost}
        successSave={successSave}
        stockPosted={stockPosted}
        deleteAdjustmentInv={deleteAdjustmentInv}
        {...props}
      />
    </>
  );
};

export default EditStockTake;
