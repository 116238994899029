import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
// import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
const MenuList = JSON.parse(localStorage.getItem("MenuList"));


//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "ui-language";
// const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
// const UserName = UserInfo===0?'': UserInfo[0].name;

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

const UiLanguageFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const [value, setValue] = useState(0);
  // const { t, i18n } = useTranslation();


  // function tabChangeHandler(event, newValue) {
  //   setValue(newValue);
  // }

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };



  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    "MenuKey": { "id": "", "name": "" }
  });


  useEffect(() => {

    let chosenValuesData = { ...chosenValues };
    chosenValuesData['MenuKey'] = MenuList[MenuList.findIndex(Menu_List => Menu_List.id === formData.MenuKey)];
    setChosenValues(chosenValuesData);

  }, [formData.MenuKey]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">
                {DispensingLanguage[lan][menukey]['UI Language - Add/Edit']}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['UI Language Information']} />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>

                      <Grid item xs={6} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]['Page Name']}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="MenuKey"
                            name="MenuKey"
                            value={formData.MenuKey}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                              {
                        MenuList.map(
                        (item, index) => {
                          return (
                            <MenuItem value={item.id}>
                              {DispensingLanguage[lan]['menu'][item.name]}
                            </MenuItem>
                          );
                        }
                      )}
                          </Select>
                          <FormHelperText error={errorObject.MenuKey}>
                            {errorObject.MenuKey}
                          </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                            //disableClearable
                            disabled={props.permissionType}
                            id="MenuKey"
                            options={MenuList}
                            onChange={(event, valueobj) => handleChangeChoosen('MenuKey', valueobj, valueobj ? valueobj.id : "")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['MenuKey']}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.MenuKey}
                                label={DispensingLanguage[lan][menukey]["Page Name"] + ' *'}
                                variant="standard"
                                error={errorObject.MenuKey}
                                helperText={errorObject.MenuKey}
                                id="MenuKey"
                                name="MenuKey"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.LangKey}
                          helperText={errorObject.LangKey}
                          required
                          id="LangKey"
                          name="LangKey"
                          label={DispensingLanguage[lan][menukey]['Language Tag']}
                          value={formData.LangKey}
                          fullWidth
                          autoComplete="family-name"

                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.EnglishTxt}
                          helperText={errorObject.EnglishTxt}
                          required
                          id="EnglishTxt"
                          name="EnglishTxt"
                          label={DispensingLanguage[lan][menukey]['English Text']}
                          value={formData.EnglishTxt}
                          fullWidth
                          autoComplete="family-name"

                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.FranceTxt}
                          helperText={errorObject.FranceTxt}
                          required
                          id="FranceTxt"
                          name="FranceTxt"
                          label={DispensingLanguage[lan][menukey]['French Text']}
                          value={formData.FranceTxt}
                          fullWidth
                          autoComplete="family-name"

                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>



                      <Grid item xs={4} sm={4}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/ui-language")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['Update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/ui-language")}
              >
                {DispensingLanguage[lan][menukey]['Update']}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default UiLanguageFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
