import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
import { useMutation } from "react-query";
// material components
import {
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
// import Notification from "../../../services/Notification";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const LossSummaryReport = (props) => {
  let TempZoneList = useState([]);
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "shipment-report";
  const [isLoadingUi, setLoading] = useState(true);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  //Shipment Status
  const ShipmentStatus_List = JSON.parse(
    localStorage.getItem("getShipmentStatusList")
  );
  let All_ShipmentStatus_List = {
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All Status"]),
  };
  const getShipmentStatusList = [All_ShipmentStatus_List].concat(
    ShipmentStatus_List
  );

  const [currShipmentStatusId, setShipmentStatusId] = useState(0);
  const [ShipmentStatusName, setShipmentStatusName] = useState(
    t(DispensingLanguage[lan][menukey]["All Status"])
  );

  //Owner type
  const Ownertype_list = JSON.parse(localStorage.getItem("Ownertypelist"));
  let All_Ownertype_list = {
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All Owner Type"]),
  };
  const Ownertypelist = [All_Ownertype_list].concat(Ownertype_list);

  const [currOwnerTypeId, setOwnerTypeId] = useState(0);
  const [OwnerTypeName, setOwnerTypeName] = useState(
    t(DispensingLanguage[lan][menukey]["All Owner Type"])
  );
  const handleProductGroupChange = (event, newValue) => {
    setProductGroupData(newValue);
    getFundingSourceData(newValue.id);
  };

  // Product Group Change
  //  const handleProductGroupChange = (event, newValue) => {
  //    let rowId = "";
  //    let rowName = "";
  //    if (newValue == null) {
  //      rowId = "";
  //      rowName = "";
  //    } else {
  //      rowId = newValue.id;
  //      rowName = newValue.name;
  //    }
  //    if (rowId !== currProductGroupId) {
  //       setProductGroupId(rowId);
  //       setProductGroupName(rowName);
  //    }

  //    refreshAllData(
  //     newValue.id,
  //     currFundingSourceId,
  //     currShipmentStatusId,
  //     currOwnerTypeId,
  //     selectedStartDate,
  //     selectedEndDate
  //   );
  //  };

  // Funding Source Change
  //  const handleFundingSourceChange = (event, newValue) => {
  //   let rowId = "";
  //   let rowName = "";
  //   if (newValue == null) {
  //     rowId = "";
  //     rowName = "";
  //   } else {
  //     rowId = newValue.id;
  //     rowName = newValue.name;
  //   }
  //   if (rowId !== currFundingSourceId) {
  //     setFundingSourceId(rowId);
  //     setFundingSourceName(rowName);
  //   }

  //   refreshAllData(
  //   currProductGroupId,
  //   newValue.id,
  //   currShipmentStatusId,
  //   currOwnerTypeId,
  //   selectedStartDate,
  //   selectedEndDate
  //  );
  // };
  // Funding Change
  const handleFundingSourceChange = (event, newValue) => {
    setFundingSourceData(newValue);
    refreshAllData(
      activeProductGroupData.id,
      newValue.id,
      currOwnerTypeId,
      currShipmentStatusId,
      selectedStartDate,
      selectedEndDate
    );

    //getFacilityData(activeProductGroupData.id, newValue.id);
  };
  // Shipment Status Change
  const handleShipmentStatusChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currShipmentStatusId) {
      setShipmentStatusId(rowId);
      setShipmentStatusName(rowName);
    }

    refreshAllData(
      activeProductGroupData.id,
      activeFundingSourceData.id,
      newValue.id,
      currOwnerTypeId,
      selectedStartDate,
      selectedEndDate,
 
    );
  };

  // Owner Type Change
  const handleOwnerTypeChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currOwnerTypeId) {
      setOwnerTypeId(rowId);
      setOwnerTypeName(rowName);
    }

    refreshAllData(
      activeProductGroupData.id,
      activeFundingSourceData.id,
      currShipmentStatusId,
      newValue.id,
      selectedStartDate,
      selectedEndDate,
 
    );
  };

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    moment()
      .add(365 + 365, "days")
      .format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    refreshAllData(
      activeProductGroupData.id,
      activeFundingSourceData.id,
      currShipmentStatusId,
      currOwnerTypeId,
      date,
      selectedEndDate
    );
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    refreshAllData(
      activeProductGroupData.id,
      activeFundingSourceData.id,
      currShipmentStatusId,
      currOwnerTypeId,
      selectedStartDate,
      date
    );
  };

  const [dataItems, setDataItems] = useState([]);

  const refreshAllData = (
    ItemGroupId,
    FundingSourceId,
    ShipmentStatusId,
    OwnerTypeId,
    StartDate,
    EndDate
  ) => {
    console.log(
      "Refreshing data with:",
      ItemGroupId,
      FundingSourceId,
      OwnerTypeId,
      ShipmentStatusId
    );
    let params1 = {
      menukey: menukey,
      ItemGroupId: ItemGroupId,
      FundingSourceId: FundingSourceId,
      ShipmentStatusId: ShipmentStatusId,
      OwnerTypeId: OwnerTypeId,
      StartDate: StartDate,
      EndDate: EndDate,
      lan: lan,
    };
    getShipmentReportTable({
      queryKey: [{ ...params1, action: "getShipmentReportList" }],
    });
  };

  const { mutate: getShipmentReportTable } = useMutation(
    api.getShipmentReport,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
    }
  );

  //Product Group combo list
  const [ProductGroupList, setProductGroupListData] = useState([]);
  const [activeProductGroupData, setProductGroupData] = useState({
    id: ProductGroupList.length > 0 ? ProductGroupList[0].id : 0,
    name: ProductGroupList.length > 0 ? ProductGroupList[0].name : "",
  });

  React.useEffect(() => {
    if (activeProductGroupData.id == 0) {
      getProductGroupData();
    }
  }, [activeProductGroupData.id]);

  const getProductGroupData = () => {
    let FacilitylevelParam = {
      action: "getProductGroupforreportList",
      RoleId: 1,
      //  UserId: UserId,
      IsAll: 0,
      menukey: menukey,
      lan: lan,
    };
    cProductList(FacilitylevelParam);
  };

  const { mutate: cProductList } = useMutation(api.getProductListData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setProductGroupListData(data.data.datalist);

        setProductGroupData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getFundingSourceData(data.data.datalist[0].id);
      }
    },
  });

  ////Funding Source combo list
  const [FundingSourceList, setFundingSourceListData] = useState([]);
  const [activeFundingSourceData, setFundingSourceData] = useState({
    id: -1,
    name: FundingSourceList.length > 0 ? FundingSourceList[0].name : "",
  });

  const getFundingSourceData = (ItemGroupId) => {
    let FacilitylevelParam = {
      action: "getFundingSourceforReport",
      RoleId: 1,
      //  UserId: UserId,
      ItemGroupId: ItemGroupId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(
    api.getFundingSourceforReport,
    {
      onSuccess: (data, params) => {
        if (data.status == 200) {
          setFundingSourceListData(data.data.datalist);

          setFundingSourceData({
            id: data.data.datalist.length ? data.data.datalist[0].id : 0,
            name: data.data.datalist.length ? data.data.datalist[0].name : "",
          });

          refreshAllData(
            params.ItemGroupId,
            data.data.datalist.length ? data.data.datalist[0].id : -1,
            currOwnerTypeId,
            currShipmentStatusId,
            selectedStartDate,
            selectedEndDate
          );

          //  getFacilityData(
          //    params.ItemGroupId,
          //    data.data.datalist.length ? data.data.datalist[0].id : 0
          //  );
        }
      },
    }
  );

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "5%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "GroupName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
      // width: 350,
    },
    {
      title: DispensingLanguage[lan][menukey]["Funding Source"],
      field: "FundingSourceName",
      headerFilter: true,
      width: 180,
    },
    {
      title: DispensingLanguage[lan][menukey]["Shipment Status"],
      field: "ShipmentStatusDesc",
      headerFilter: true,
      width: 180,
    },
    {
      title: DispensingLanguage[lan][menukey]["Owner Type"],
      field: "OwnerTypeName",
      headerFilter: true,
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["Shipment Date"],
      field: "ShipmentDate",
      headerFilter: false,
      width: 200,
      formatter: function (cell) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "Qty",
      headerFilter: false,
      width: 150,
      hozAlign: "right",
      headerHozAlign: "right",
    },
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    window.open(
      finalUrl +
        "?action=getShipmentReport" +
        "&reportType=" +
        reportType +
        "&ProductGroupId=" +
        activeProductGroupData.id +
        "&FundingSourceId=" +
        activeFundingSourceData.id +
        "&ProductGroupName=" +
        activeProductGroupData.name +
        "&FundingSourceName=" +
        activeFundingSourceData.name +
        "&ShipmentStatusId=" +
        currShipmentStatusId +
        "&ShipmentStatusName=" +
        ShipmentStatusName +
        "&OwnerTypeId=" +
        currOwnerTypeId +
        "&OwnerTypeName=" +
        OwnerTypeName +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  useEffect(() => {
    refreshAllData(
      activeProductGroupData.id,
      activeFundingSourceData.id,
      currShipmentStatusId,
      currOwnerTypeId,
      selectedStartDate,
      selectedEndDate
    );
  }, []);

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="section signup-top-padding sw_inner_container">
        <div className="sw_inner_card">
          <div className="d-flex justify-uiLanguage">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Shipment Report"]}
                </div>
              </div>
            </Grid>
          </div>
          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroupList"
                          disableClearable
                          options={ProductGroupList}
                          value={activeProductGroupData}
                          onChange={(event, newValue) =>
                            handleProductGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Product Group"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FundingSourceList"
                          disableClearable
                          options={FundingSourceList}
                          value={activeFundingSourceData}
                          onChange={(event, newValue) =>
                            handleFundingSourceChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Funding Source"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="OwnerTypeId"
                          disableClearable
                          options={Ownertypelist}
                          onChange={(event, newValue) =>
                            handleOwnerTypeChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            Ownertypelist[
                              Ownertypelist.findIndex(
                                (Ownertypelist) =>
                                  Ownertypelist.id == currOwnerTypeId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Owner Type"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ShipmentStatusId"
                          disableClearable
                          options={getShipmentStatusList}
                          onChange={(event, newValue) =>
                            handleShipmentStatusChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            getShipmentStatusList[
                              getShipmentStatusList.findIndex(
                                (getShipmentStatusList) =>
                                  getShipmentStatusList.id ==
                                  currShipmentStatusId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Shipment Status"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            className={classes.fullWidth}
                            //disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label={
                              DispensingLanguage[lan][menukey]["Start date"]
                            }
                            value={selectedStartDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            // disableFuture={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            className={classes.fullWidth}
                            //disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label={DispensingLanguage[lan][menukey]["End date"]}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            // disableFuture={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* end of filter */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    // options={{
                    //   groupBy: ["FacilityName", "GroupName", "ItemName"],
                    //   columnCalcs: "both",
                    // }}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end of row */}
        </div>
      </div>
    </>
  );
};

export default LossSummaryReport;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});
