import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { ColorPicker } from "material-ui-color";

const NationalMosTypeEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  handleChangeColorAdd,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "national-mos-type-entry";

  const classes = useStyles();
  const { t } = useTranslation();

  const handleChangeColor = (name) => {
    if (typeof name == "string") {
      formData["ColorCode"] = name;
      handleChangeColorAdd(name);
    } else {
      formData["ColorCode"] = name.css.backgroundColor;
      handleChangeColorAdd(name.css.backgroundColor);
    }
  };


  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">
                {
                  DispensingLanguage[lan][menukey][
                  "National MOS Type Entry Form- Add/Edit"
                  ]
                }
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["National MOS Type Entry"]}
              />
              <CardContent>
                <Grid container >
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errorObject.MosTypeName}
                          helperText={errorObject.MosTypeName}
                          required
                          id="MosTypeName"
                          name="MosTypeName"
                          label={DispensingLanguage[lan][menukey]["National MOS Type Name"]}
                          value={formData.MosTypeName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errorObject.MinMos}
                          helperText={errorObject.MinMos}
                          required
                          id="MinMos"
                          name="MinMos"
                          label={DispensingLanguage[lan][menukey]["Minimum MOS"]}
                          value={formData.MinMos}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errorObject.MaxMos}
                          helperText={errorObject.MaxMos}
                          required
                          id="MaxMos"
                          name="MaxMos"
                          label={DispensingLanguage[lan][menukey]["Maximum MOS"]}
                          value={formData.MaxMos}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errorObject.MosLabel}
                          helperText={errorObject.MosLabel}
                          required
                          id="MosLabel"
                          name="MosLabel"
                          label={DispensingLanguage[lan][menukey]["MOS Label"]}
                          value={formData.MosLabel}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={2} className="marginTop10">
                        <ColorPicker
                          error={errorObject.ColorCode}
                          helperText={errorObject.ColorCode}
                          required
                          name="ColorCode"
                          defaultValue="#00aabb"
                          value={formData.ColorCode}
                          onChange={handleChangeColor}
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {t(DispensingLanguage[lan][menukey]["Reset"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {t(DispensingLanguage[lan][menukey]["Save"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/national-mos-type-entry")}
              >
                {t(DispensingLanguage[lan][menukey]["Cancel"])}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {t(DispensingLanguage[lan][menukey]["update"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/national-mos-type-entry")}
              >
                {t(DispensingLanguage[lan][menukey]["Cancel"])}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default NationalMosTypeEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
