import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import swal from "sweetalert";
import { Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import Autocomplete from "@material-ui/lab/Autocomplete";


//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import {
  FormControl,
  TextField,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";

//const UserInfo = JSON.parse(sessionStorage.getItem("User_info"));

// const fileType =
//   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
// const fileExtension = ".xlsx";

const RecipientGroup = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "recipient-group-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  const { useState } = React;

  const [numberRowPerPage, setNumberRowPerPage] = useState(15);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [dataItems, setDataItems] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  const LanCode = localStorage.getItem("LanCode");
  const LanguageList = JSON.parse(localStorage.getItem("LanguageList"));
  const [currLangCode, setCurrLangCode] = useState(lan);
  const { data: limitData, refetch: limitRefetch } = useQuery(
    [
      {
        action: "getTotal",
        menukey: menukey,
      },
    ],
    api.getTotalRecipientGroup,
    {
      onSuccess: (data) => {
        if (data != undefined)
          setTotalRow(Math.ceil(data[0].TotalRow / numberRowPerPage));
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    },
    {
      useErrorBoundary: (error) => error.response?.status >= 500,
    }
  );

  let params = {
    action: "ShowDataList",
    menukey: menukey,
    page_limit: numberRowPerPage,
    page_number: pageNumber,
    search_key: "",
    lan: currLangCode,
  };
  const { data: uomData, refetch: tableData } = useQuery(
    [params],
    api.getRecipientGroupList,
    {
      onSuccess: (data) => {
        // setTotalRow(data.length);
        // setTotalRow(Math.round(data.length/numberRowPerPage));
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const { mutate: deleteApi } = useMutation(api.deleteRecipientGroup, {
    onSuccess: (data) => {
      //console.log(data);

      if (data.data.status == 200) {

        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'IssuedToGroupItemList'
        };
        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
        .then((response) => {
          localStorage.setItem(
            "IssuedToGroupItemList",
            JSON.stringify(response.datalist.IssuedToGroupItemList)
          );
        });

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        tableData();
        queryClient.getQueriesData("recipientgroup");

        //swal(data.data.message, "", "success");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
    onError: (data) => {
      // console.log("vbdsjgf");
    },
  });

  // Facility
  const handleLanCodeChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }

    if (rowId !== currLangCode) {
      // Facility List By User Change
      setCurrLangCode(rowId);
      setLoading(true);
    }
  };

  React.useEffect(() => {
    localStorage.setItem("LanCode", currLangCode);
  }, [currLangCode]);

  // End Facility

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getIssuedToGroupEntryData" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        currLangCode +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editunit(rowData);
  };

  let sl = 0;
  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl."],
      // formatter: "rownum",
      formatter: function (cell, formatterParams) {
        sl++;
        return (pageNumber - 1) * numberRowPerPage + sl;
      },
      width: 70,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },

    {
      title: DispensingLanguage[lan][menukey]["CS/Team Group Name"],
      field: "LangText",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 130,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editunit = (data) => {
    let route = `recipient_group/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteunit = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //         "Once deleted, you will not be able to recover this Data!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       deleteApi({
  //         RecordId: data.RecordId,
  //         menukey: menukey,
  //         lan: lan,
  //         UserName: UserName,
  //         action: "dataDalete",
  //       });
  //     }
  //   });
  // };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            //deleteunit(rowData);
            modalPopupDelete(deleteApi, rowData.RecordId);
          }}
        />
      </>
    );
  }

  const handleChange = (event, value) => {
    console.log("value: ", value);
    console.log("event: ", event);

    setPageNumber(value);
  };

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["CS/Team Group Entry"])}
              </div>
            </div>
          </Grid>
        </div>

        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="LanguageList"
                    disableClearable
                    options={LanguageList}
                    // disabled={UserName == "admin" ? false : true}
                    onChange={(event, newValue) =>
                      handleLanCodeChange(event, newValue)
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      LanguageList[
                        LanguageList.findIndex(
                          (LanguageList) => LanguageList.id == currLangCode
                        )
                      ]
                    }
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Language"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={9} sm={9}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/recipient_group/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add"]}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
          <div className="marginTop20">
            <Grid container spacing={3}>
              {/* <Grid item xs={12} sm={12}>
                <Pagination
                  className="pull-right"
                  count={totalRow}
                  variant="outlined"
                  shape="rounded"
                  page={pageNumber}
                  rowsPerPage={numberRowPerPage}
                  onChange={handleChange}
                  
                />
              </Grid> */}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipientGroup;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
