import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { ColorPicker } from "material-ui-color";
const AEFIGenderTypeFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  handleChangeColorAdd,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "aefi-gender-type";

  const classes = useStyles();
  const { t } = useTranslation();


  const handleChangeColor = (name) => {
    if (typeof name == "string") {
      formData["STL_Color"] = name;
      handleChangeColorAdd(name);
    } else {
      formData["STL_Color"] = name.css.backgroundColor;
      handleChangeColorAdd(name.css.backgroundColor);
    }
  };

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">
                {
                  DispensingLanguage[lan][menukey][
                  "AEFI Gender Type Entry Form - Add/Edit"
                  ]
                }
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container justifyContent="center" spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}  >
            <Card className="sw_card" >
              <CardHeader
                title={DispensingLanguage[lan][menukey]["AEFI Gender Type Entry Form"]}
              />
              <CardContent>
                <Grid container  >
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4} sm={4} >
                        <TextField
                          error={errorObject.GenderTypeId}
                          helperText={errorObject.GenderTypeId}
                          required
                          id="GenderTypeId"
                          name="GenderTypeId"
                          label={
                            DispensingLanguage[lan][menukey]["AEFI Gender Type"]
                          }
                          value={formData.GenderTypeId}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5} >
                        <TextField
                          error={errorObject.GenderType}
                          helperText={errorObject.GenderType}
                          required
                          id="GenderType"
                          name="GenderType"
                          label={
                            DispensingLanguage[lan][menukey]["AEFI Gender Type Name"]
                          }
                          value={formData.GenderType}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4} lg={2} className="">
                            {/* <FormLabel component="legend" >
                          {DispensingLanguage[lan][menukey]["Color Code"]}
                        </FormLabel> */}

                            <ColorPicker
                              error={errorObject.STL_Color}
                              helperText={errorObject.STL_Color}
                              required
                              name="STL_Color"
                              defaultValue="#00aabb"
                              value={formData.STL_Color}
                              onChange={handleChangeColor}
                            />
                          </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {t(DispensingLanguage[lan][menukey]["Reset"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {t(DispensingLanguage[lan][menukey]["Save"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/aefi-gender-type")}
              >
                {t(DispensingLanguage[lan][menukey]["Cancel"])}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {t(DispensingLanguage[lan][menukey]["update"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/aefi-gender-type")}
              >
                {t(DispensingLanguage[lan][menukey]["Cancel"])}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default AEFIGenderTypeFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
