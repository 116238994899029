import React, { useState, useEffect, } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import {  subDays } from "date-fns";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, } from "react-tabulator";

import { useQuery } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Grid,

} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Button } from "reactstrap";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { getDefaultMonthYear } from "../../services/Common";

import { useTranslation } from "react-i18next";
import { checkLogin, checkUserPermission, } from "../../services/CheckUserAccess";


const DashboardPage = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, } = useTranslation();
  const menukey = "amc-trends-for-the-top-five-products";
  const FacilityId = localStorage.getItem("FacilityId");
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const Facility_List = JSON.parse(localStorage.getItem("FacilityList"));

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);
  const bFacilitySelected = localStorage.getItem("bFacilitySelected");

  exporting(Highcharts);
  const classes = useStyles();

  // const [pickerData, setPickerData] = useState([
  //   {
  //     startDate: subDays(new Date(), 29),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );

  const [currFacilityId, setCurrFacilityId] = useState(bFacilitySelected == 1 ? FacilityId : 0);

  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
  };

  /*  const handleFacilityChange = (event) => {
     setCurrFacilityId(event.target.value);
   }; */

  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    setCurrFacilityId(rowId);
  };

  const [AMCTrendsTopFiveProducts, setAMCTrendsTopFiveProducts] = useState([]);

  const getAMCTrendsTopFiveProducts = useQuery(
    ["amctrendstopfiveproducts"],
    () =>
      api.getAMCTrendsforTheTopFiveProductsChart(currYearId, currMonthId, currFacilityId, menukey),
    {
      onSuccess: (data) => {
        setAMCTrendsTopFiveProducts({
          chart: {
            type: "line",
          },
          title: {
            text: data.range,
          },
          xAxis: {
            categories: data.category,
          },
          yAxis: {
            title: {
              text: t(
                DispensingLanguage[lan][menukey]["Average Monthly Consumption"]
              ),
            },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#808080",
              },
            ],
            min: 0,
          },
          tooltip: {
            shared: true,
            crosshairs: true,
          },
          credits: {
            enabled: false,
          },
          series: data.seriesdata,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [columnsStockStatusTable, setColumnsStockStatusTable] = useState([]);
  const [stockStatusTableData, setStockStatusTableData] = useState([]);

  const { refetch: fetchDashboardStockStatusTableData } = useQuery(
    ["dashboardStockStatusTableDataList"],
    () => api.getAMCTrendsforTheTopFiveProductsTable(currYearId, currMonthId, currFacilityId, menukey),
    {
      onSuccess: (data) => {

        setColumnsStockStatusTable([
          {
            title: DispensingLanguage[lan][menukey]["Product Code"],
            field: "ItemCode",
            width: 120,
            headerSort: false,
          },
          {
            title: DispensingLanguage[lan][menukey]["Product Name"],
            field: "ItemName",
            headerSort: false,
          },
          {
            title: data.category['Month1'],
            field: "Month1",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month2'],
            field: "Month2",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month3'],
            field: "Month3",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month4'],
            field: "Month4",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month5'],
            field: "Month5",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month6'],
            field: "Month6",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month7'],
            field: "Month7",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month8'],
            field: "Month8",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month9'],
            field: "Month9",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month10'],
            field: "Month10",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month11'],
            field: "Month11",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          },
          {
            title: data.category['Month12'],
            field: "Month12",
            width: 75,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          }
        ]);

        setStockStatusTableData(data.data);

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  useEffect(() => {
    if (currYearId > 0 && currMonthId > 0) {
      getAMCTrendsTopFiveProducts.refetch();
      fetchDashboardStockStatusTableData();
    }
  }, [currYearId, currMonthId, currFacilityId]);

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  /* const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
  }, []);

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintExportFunction = (reportType) => {
    let Print_finalUrl = EXCEL_EXPORT_URL + "report/AMCTrendsfortheTopFiveProducts_print.php";

    window.open(
      Print_finalUrl +
      "?reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" + Date.now() +
      "&Month=" + currMonthId +
      "&Year=" + currYearId +
      "&FacilityId=" + currFacilityId
    );

  };

  const ExcelExportFunction = (reportType) => {
    let Excel_finalUrl = EXCEL_EXPORT_URL + "report/AMCTrendsfortheTopFiveProducts_excel.php";

    window.open(
      Excel_finalUrl +
      "?reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" + Date.now() +
      "&Month=" + currMonthId +
      "&Year=" + currYearId +
      "&FacilityId=" + currFacilityId
    );

  };

  /* =====End of Excel Export Code==== */

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div
          className="section signup-top-padding sw_inner_container"
        >
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["AMC Trends for the Top Five Products"])}
                </div>
              </div>
            </div>
            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={1}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="YearList"
                        name="YearList"
                        value={currYearId}
                        onChange={handleYearChange}
                        fullWidth
                      >
                        {YearList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="MonthList"
                        name="MonthList"
                        value={currMonthId}
                        onChange={handleMonthChange}
                        fullWidth
                      >
                        {MonthList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      {/*  <Select
                          labelId="demo-simple-select-helper-label"
                          id="FacilityList"
                          name="FacilityList"
                          value={currFacilityId}
                          onChange={handleFacilityChange}
                          fullWidth
                        >
                          <MenuItem value="0">
                            {t(DispensingLanguage[lan][menukey]["All Facility"])}
                          </MenuItem>

                          {FacilityList.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select> */}

                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="FacilityList"
                        disableClearable
                        options={FacilityList}
                        onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label={DispensingLanguage[lan][menukey]["All Facility"]}
                            variant="standard"
                            hiddenLabel
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}></Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey][
                      "AMC Trends for the Top Five Products"
                      ]
                    )}
                  />

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={AMCTrendsTopFiveProducts}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12">
                <Card className="sw_card">
                  <CardHeader
                    title={t(DispensingLanguage[lan][menukey]["AMC Trends for the Top Five Products"])}

                    action={
                      <div className="float-right sw_btn_control">
                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintExportFunction("print")}
                        >
                          <i className="fas fa-print"></i>
                        </Button>

                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => ExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    }
                  />

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="uniqueName">
                          <ReactTabulator
                            columns={columnsStockStatusTable}
                            data={stockStatusTableData ? stockStatusTableData : []}
                            layout={"fitColumns"}
                          />
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}

          </div>
        </div>
      </>)
  );
};

export default DashboardPage;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
