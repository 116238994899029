import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
const BasicInformation = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "country-profile-entry";
  const FacilityId = localStorage.getItem("FacilityId");
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [manyTableData, setManyTableData] = useState([]);

  useEffect(() => {
    refreshAllData(props.ItemGroupId, props.YearId);
  }, [props.ItemGroupId, props.YearId]);

  const refreshAllData = (pItemGroupId, pyearid) => {
    let params = {
      menukey: menukey,
      lan: lan,
      ItemGroupId: pItemGroupId,
      YearId: pyearid,
    };
    GetbasicinfoTable({
      queryKey: [{ ...params, action: "getYcProfileData" }],
    });
  };

  const { mutate: GetbasicinfoTable } = useMutation(
    api.getSingleBasicInfoData,
    {
      onSuccess: (data) => {
        setManyTableData(data.data.datalist);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const Columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Parameter Name"],
      field: "ParamName",
      //width: 130,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Value"],
      field: "YCValue",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 170,
      cssClass: "tabluator-column-editable",
      //headerFilter: true,
      validator: ["integer", "min:0"],
      editable: true,
      editor: "input",
      cellEdited: function (cell) {
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const id = rowdata.YCProfileId;
        const DataSource = rowdata.DataSource;
        const YCValue = cell.getValue().trim();

        let CellOldValue = cell._cell.oldValue;
        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }
        if (CellOldValue != YCValue) {
          cellDataUpdate(id, YCValue, DataSource);
        }
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Data Source"],
      field: "DataSource",
      hozAlign: "left",
      headerHozAlign: "left",
      width: 400,
      cssClass: "tabluator-column-editable text-field-editable",
      //headerFilter: true,
      //validator: ["integer", "min:0"],
      editable: true,
      editor: true,
      cellEdited: function (cell) {
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const id = rowdata.YCProfileId;
        const YCValue = rowdata.YCValue;
        const DataSource = cell.getValue().trim();

        let CellOldValue = cell._cell.oldValue;
        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }
        if (CellOldValue != DataSource) {
          cellDataUpdate(id, YCValue, DataSource);
        }
      },
    },
  ];

  const cellDataUpdate = (id, YCValue, DataSource) => {
    mutatedUpdate({ id, YCValue, DataSource });
  };

  const { mutate: mutatedUpdate } = useMutation(api.updateBasicInfoData, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        //console.log("RRRR", data);

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const handleSubmit = async (e) => {
    //console.log("from submit ", e);
    //if (validateForm(formData)) {
    let fDtat = {
      menukey: menukey,
      lan: lan,
      UserName: UserName,
      UserId: UserId,
      FacilityId: FacilityId,
      CountryProfileType: 1,
      YearId: props.YearId,
      ItemGroupId: props.ItemGroupId,
    };
    mutateSave(fDtat);

    //}
  };

  const { mutate: mutateSave } = useMutation(api.SaveYcProfileData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        refreshAllData(props.ItemGroupId, props.YearId);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        queryClient.getQueriesData("source"); //api page name
        //props.history.push("/source-entry");//
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleDelete = async (e) => {
    //console.log("from submit ", e);
    //if (validateForm(formData)) {
    let fDtat = {
      menukey: menukey,
      lan: lan,
      UserName: UserName,
      UserId: UserId,
      FacilityId: FacilityId,
      CountryProfileType: 1,
      YearId: props.YearId,
      ItemGroupId: props.ItemGroupId,
    };
    mutateDelete(fDtat);

    //}
  };

  const { mutate: mutateDelete } = useMutation(api.DeleteCountryProfile, {
    onSuccess: (data) => {
      if (data.status == 200) {
        refreshAllData(props.ItemGroupId, props.YearId);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        queryClient.getQueriesData("source"); //api page name
        //props.history.push("/source-entry");//
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  return (
    <>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-1 ">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Basic Information"]}
              </div>

              <div className="float-right sw_btn_control">
                <Grid item xs={12} className="text-center">
                  <Button
                    color="primary"
                    className="mr-2"
                    variant="contained"
                    onClick={() => handleSubmit()}
                    // onClick={() =>
                    //   props.history.push("/forecasted-consumption-entry")
                    // }
                    //onClick={() => backToList()}
                  >
                    <i class="fas fa-plus"></i>
                    {/* {DispensingLanguage[lan][menukey]["Add Record"]} */}
                  </Button>
                  <Button
                    style={{ background: "#f50057" }}
                    variant="contained"
                    className="mr-2"
                    onClick={() => handleDelete()}
                  >
                    <i style={{ color: "white" }} class="fas fa-times"></i>
                    {/* {
                        DispensingLanguage[lan][menukey][
                          "Clear Basic Information"
                        ]
                      } */}
                  </Button>
                </Grid>
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}

        {/* <Grid container spacing={3}> */}
        <div className="sw_relative">
          <div className="uniqueName">
            <Grid item xs={12} sm={12}>
              <Card className="sw_card">
                <CardContent>
                  {isLoading && <LoadingSpinnerOpaque />}
                  <ReactTabulator
                    //ref={(r) => (ReceiveDetailsRef = r)}
                    columns={Columns}
                    data={manyTableData}
                    height="400px"
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card>
            </Grid>
          </div>
        </div>
      </div>

      {/* </Box> */}
    </>
  );
};

export default BasicInformation;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
});
