import {
  MapContainer,
  TileLayer,
  Polygon,
  CircleMarker,
  Tooltip,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
//import Control from 'react-leaflet-control';
import statesData from "../../../services/mapjsonfile/mali.json";

import React from "react";

//const center = [13.025965926333539, -6.96533203125];
const center = [18.113637527839103, -1.8086445733566374];
const fillBlueOptions = { fillColor: "blue" };

const MapLeaflet = (props) => {
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "dashboard";
  console.log("Maappppppppppppppppppppppss", props.MapData);

  let MAXQTY = 45;


  return (
    <MapContainer
      center={center}
      zoom={5}
      style={{ height: "385px", width: "100%" }}
    >
      <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='</a>
           <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; 
           OpenStreetMap contributors</a>'
        />

{
          statesData.features.map((state) => {
            const coordinates = state.geometry.coordinates[0].map((item) => [item[1], item[0]]);


            return (<Polygon
              pathOptions={{ 
                fillColor: '#FEFEC7',
                fillOpacity: 0.7, 
                weight: 1,
                opacity: 1,
                dashArray: 3,
                color: 'gray' 
              }}
              positions={coordinates}
              eventHandlers={{
                mouseover: (e) => {
                  // const layer = e.target;
                  // layer.setStyle({
                  //   dashArray: "",
                  //   fillColor: "#BD0026",
                  //   fillOpacity: 0.7,
                  //   weight: 2,
                  //   opacity: 1,
                  //   color: "white",
                  // })
                },
                mouseout: (e) => {
                  // const layer = e.target;
                  // layer.setStyle({
                  //   fillOpacity: 0.7,
                  //   weight: 2,
                  //   dashArray: "3",
                  //   color: 'white',
                  //   fillColor: '#FD8D3C'
                  // });
                },
                // mouseover: (e) => {
                //   const layer = e.target;
                //   layer.setStyle({
                //     dashArray: "",
                //     fillColor: "#BD0026",
                //     fillOpacity: 0.7,
                //     weight: 2,
                //     opacity: 1,
                //     color: "white",
                //   })
                // },
                // mouseout: (e) => {
                //   const layer = e.target;
                //   layer.setStyle({
                //     fillOpacity: 0.7,
                //     weight: 2,
                //     dashArray: "3",
                //     color: 'white',
                //     fillColor: '#FD8D3C'
                //   });
                // },
                click: (e) => {

                }
              }}
            />)
          })




        }

{
           props.MapData.aaData!=undefined?
          props.MapData.aaData.map((state) => {



            console.log(state);
            let coordinates =[state.Latitude, state.Longitude];
            let radious=((state.TotalBalance*40)/props.MapData.maxValue);//state.MAX

            if(radious<5) radious=5;

            return (
              <CircleMarker 
              center={coordinates}
              pathOptions={{ "fillColor": "#9071ce",
              "fillOpacity":.7,
              "color": "#744ec2",
              "weight": 2,
              "opacity": .8
            }}
              radius={radious}
              >  
          <Tooltip>{state.District }<br></br><b>{DispensingLanguage[lan][menukey]["Quantity"]}: </b>{new Intl.NumberFormat().format(state.TotalBalance ) } </Tooltip>
        </CircleMarker>




             )




          }


          ):<></>

        }
    </MapContainer>
  );
};

export default MapLeaflet;
