import React, { } from "react";
import { makeStyles, } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";


//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "recipient-group-entry";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const RecipientGroupFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();



  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['CS/Team Group Entry - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['CS/Team Group Entry']} />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          error={errorObject.LangText}
                          helperText={errorObject.LangText}
                          required
                          id="LangText"
                          name="LangText"
                          label={DispensingLanguage[lan][menukey]['CS/Team Group Name']}
                          // value={formData.FirstName || "Mahmudul"}
                          value={formData.LangText}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.RecordId}
                          helperText={errorObject.RecordId}
                          hidden={true}
                          id="RecordId="
                          name="RecordId"
                          value={formData.RecordId}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/recipient_group")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/recipient_group")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default RecipientGroupFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
