import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import NextPrevBtn from "../../../services/NextPrevBtn";
import * as Service from "../../../services/Service.js";


const ProductFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ValidateProductForDispensing,
  handleATCChange,
  ...props
}) => {
  const StockData = JSON.parse(localStorage.getItem("ProductDataNextPrev"));

  const StockDataIndex = JSON.parse(localStorage.getItem("ProductDataIndex"));

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "products";

  const classes = useStyles();

  const GenericList = JSON.parse(localStorage.getItem("GenericList"));
  const Strengthlist = JSON.parse(localStorage.getItem("Strengthlist"));
  const DosageFormlist = JSON.parse(localStorage.getItem("DosageFormlist"));
  const PackSizelist = JSON.parse(localStorage.getItem("PackSizelist"));
  const Unitofmeasurelist = JSON.parse(
    localStorage.getItem("Unitofmeasurelist")
  );
  const RouteOfAdminlist = JSON.parse(localStorage.getItem("RouteOfAdminlist"));
  // const VEN_List = JSON.parse(localStorage.getItem("VEN_List"));
  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
  const VvmType = JSON.parse(localStorage.getItem("VvmType"));
  // const ABC_List = JSON.parse(localStorage.getItem("ABC_List"));
  // const RxLevelList = JSON.parse(localStorage.getItem("RxLevelList"));
  // const ATC_List = JSON.parse(localStorage.getItem("ATC_List"));
  const [ProductSubGroupList, setProductSubGroupListLocal] = useState([]);

  //==== Start Choosen Dropdown========
  const [chosenValues, setChosenValues] = useState({
    genericName: { id: "", name: "" },
    strengthSize: { id: "", name: "" },
    form: { id: "", name: "" },
    routeOfAdmin: { id: "", name: "" },
    packSize: { id: "", name: "" },
    unit: { id: "", name: "" },
    administrationUnit: { id: "", name: "" },
    VvmType: { id: "", name: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData["genericName"] =
      GenericList[
        GenericList.findIndex(
          (GenericList_list) => GenericList_list.id == formData.genericName
        )
      ];
    chosenValuesData["strengthSize"] =
      Strengthlist[
        Strengthlist.findIndex(
          (Strengthlist_list) => Strengthlist_list.id == formData.strengthSize
        )
      ];
    chosenValuesData["form"] =
      DosageFormlist[
        DosageFormlist.findIndex(
          (DosageFormlist_list) => DosageFormlist_list.id == formData.form
        )
      ];
    chosenValuesData["routeOfAdmin"] =
      RouteOfAdminlist[
        RouteOfAdminlist.findIndex(
          (RouteOfAdminlist_list) =>
            RouteOfAdminlist_list.id == formData.routeOfAdmin
        )
      ];
    chosenValuesData["packSize"] =
      PackSizelist[
        PackSizelist.findIndex(
          (PackSizelist_list) => PackSizelist_list.id == formData.packSize
        )
      ];
    chosenValuesData["unit"] =
      Unitofmeasurelist[
        Unitofmeasurelist.findIndex(
          (Unitofmeasurelist_list) => Unitofmeasurelist_list.id == formData.unit
        )
      ];
    chosenValuesData["administrationUnit"] =
      Unitofmeasurelist[
        Unitofmeasurelist.findIndex(
          (Unitofmeasurelist1_list) =>
            Unitofmeasurelist1_list.id == formData.administrationUnit
        )
      ];
    chosenValuesData["VvmType"] =
      VvmType[VvmType.findIndex((VvmId) => VvmId.id == formData.VvmId)];

    setChosenValues(chosenValuesData);
  }, [
    formData.genericName,
    formData.strengthSize,
    formData.form,
    formData.routeOfAdmin,
    formData.packSize,
    formData.unit,
    formData.administrationUnit,
    formData.VvmId,
  ]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //==== End Choosen Dropdown========


  const getPricebesideLevel = (type = null) => {
    let level = "";
    let extraString = "";
    if (type == "shippingPack") {
      extraString = "x ";
    } else {
      extraString = "/ ";
    }

    if (formData.packSize && formData.unit) {
      let packSizes = PackSizelist;
      let selectedPackSize = packSizes.find(
        (packSize) => packSize.id == formData.packSize
      );

      let units = Unitofmeasurelist;
      let selectedUnit = units.find((unit) => unit.id == formData.unit);
      level = extraString + selectedPackSize.name + " " + selectedUnit.name;
    }
    return level;
  };

  const handleIndex = (indx, row) => {
    localStorage.setItem("ProductDataIndex", JSON.stringify(indx));
    let route = `${row.id}`;
    props.history.push(route);
  };

  const handleProductGroupChange = (event) => {
    formData["ItemGroupId"] = event.target.value;
    formData["ProductTypeId"] = 0;
    cProductSubGroupList();
    props.handleChangeProductGroupProps(event.target.value);
  };

  function cProductSubGroupList() {
    let ZoneParam = {
      action: "ProductSubGroupList",
      ItemGroupId: formData["ItemGroupId"],
      menukey: "",
    };
    Service.default
      .postApi("source/combo_generic.php", ZoneParam)
      .then((res) => {
        setProductSubGroupListLocal(res.datalist);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (!addProductForm) {
      cProductSubGroupList();
    }
  }, [formData]);

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Product Entry"]} -{" "}
                {addProductForm
                  ? DispensingLanguage[lan][menukey]["Add"]
                  : DispensingLanguage[lan][menukey]["Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}>
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}>
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/products")}>
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}>
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/products")}>
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {addProductForm ? (
          <></>
        ) : (
          <NextPrevBtn
            dataList={StockData}
            StockDataIndex={StockDataIndex}
            handleReset={handleReset}
            handleIndex={handleIndex}
            {...props}
          />
        )}

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["Product Entry"]}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Product Group"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.ItemGroupId}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="ItemGroupId"
                            disabled={addProductForm ? false : true}
                            value={formData.ItemGroupId}
                            fullWidth
                            onChange={(e) => handleProductGroupChange(e)}>
                            {ProductGroupList.map((item, index) => {
                              return (
                                <MenuItem
                                  key={`product-group-${index + 1}`}
                                  value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.ItemGroupId}>
                            {errorObject.ItemGroupId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.productCode}
                          helperText={errorObject.productCode}
                          required
                          id="productCode"
                          name="productCode"
                          label={
                            DispensingLanguage[lan][menukey]["Product Code"]
                          }
                          value={formData.productCode}
                          fullWidth
                          //disabled={true}
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.productName}
                          helperText={errorObject.productName}
                          required
                          id="productName"
                          name="productName"
                          label={
                            DispensingLanguage[lan][menukey]["Product Name"]
                          }
                          value={formData.productName}
                          fullWidth
                          // disabled={true}
                          autoComplete="given-name"
                          onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          hidden
                          id="ItemShortName"
                          name="ItemShortName"
                          label="Item Short Name"
                          value={formData.ItemShortName}
                          fullWidth
                          disabled={true}
                          autoComplete="given-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.ShortName}
                          helperText={errorObject.ShortName}
                          required
                          id="ShortName"
                          name="ShortName"
                          label={
                            DispensingLanguage[lan][menukey][
                              "Product Short Name"
                            ]
                          }
                          value={formData.ShortName}
                          fullWidth
                          autoComplete="given-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="genericName"
                            options={GenericList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "genericName",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["genericName"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.genericName}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Generic Name"
                                  ] + " *"
                                }
                                variant="standard"
                                error={errorObject.genericName}
                                helperText={errorObject.genericName}
                                id="genericName"
                                name="genericName"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="strengthSize"
                            options={Strengthlist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "strengthSize",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["strengthSize"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.strengthSize}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Strength/Size"
                                  ]
                                }
                                variant="standard"
                                error={errorObject.strengthSize}
                                helperText={errorObject.strengthSize}
                                id="strengthSize"
                                name="strengthSize"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="form"
                            options={DosageFormlist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "form",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["form"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.form}
                                label={DispensingLanguage[lan][menukey]["Form"]}
                                variant="standard"
                                error={errorObject.form}
                                helperText={errorObject.form}
                                id="form"
                                name="form"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="unit"
                            options={Unitofmeasurelist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "unit",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["unit"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.unit}
                                label={DispensingLanguage[lan][menukey]["Unit"]}
                                variant="standard"
                                error={errorObject.unit}
                                helperText={errorObject.unit}
                                id="unit"
                                name="unit"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="packSize"
                            options={PackSizelist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "packSize",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["packSize"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.packSize}
                                label={
                                  DispensingLanguage[lan][menukey]["Pack Size"]
                                }
                                variant="standard"
                                error={errorObject.packSize}
                                helperText={errorObject.packSize}
                                id="packSize"
                                name="packSize"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="routeOfAdmin"
                            options={RouteOfAdminlist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "routeOfAdmin",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["routeOfAdmin"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.routeOfAdmin}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Route of Admin"
                                  ]
                                }
                                variant="standard"
                                error={errorObject.routeOfAdmin}
                                helperText={errorObject.routeOfAdmin}
                                id="routeOfAdmin"
                                name="routeOfAdmin"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="VvmId"
                            options={VvmType}
                            onChange={(event, valueobj) => {
                              handleChangeChoosen(
                                "VvmType",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              );
                            }}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["VvmType"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.VvmId}
                                label={
                                  DispensingLanguage[lan][menukey]["VVM Type"]
                                }
                                variant="standard"
                                error={errorObject.VvmId}
                                helperText={errorObject.VvmId}
                                id="VvmId"
                                name="VvmId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3} className="d-flex" style={{ marginTop: '12px' }}>
                        <FormControl component="fieldset" className="mr-2">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={formData.bKeyItem}
                                onChange={(e) => handleCheck(e)}
                                name="bKeyItem"
                                value="no"
                              />
                            }
                            label={
                              DispensingLanguage[lan][menukey]["Key Product"]
                            }
                          />
                        </FormControl>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={formData.NonPerishable}
                                onChange={(e) => handleCheck(e)}
                                name="NonPerishable"
                                value="no"
                              />
                            }
                            label={
                              DispensingLanguage[lan][menukey]["Non Perishable"]
                            }
                          />
                        </FormControl>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={formData.bCovid}
                                onChange={(e) => handleCheck(e)}
                                name="bCovid"
                                value="no"
                              />
                            }
                            label={DispensingLanguage[lan][menukey]["Is Covid"]}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3} className="dnone">
                      <div className="marginTop10">
                      <FormControl
                          component="fieldset"
                          className={classes.formControl}>
                          <FormGroup className="sw_Checkboxgroup">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={formData.bIncludeTrade}
                                  onChange={(e) => handleCheck(e)}
                                  name="bIncludeTrade"
                                  value="no"
                                />
                              }
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Include Trade name in Description"
                                ]
                              }
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={formData.bExcludeGeneric}
                                  onChange={(e) => handleCheck(e)}
                                  name="bExcludeGeneric"
                                  value="no"
                                />
                              }
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Exclude Generic name from Description"
                                ]
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProductFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: '13px',
  },
});
