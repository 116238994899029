import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@material-ui/core";

import swal from "sweetalert";

import { DeleteOutline, ViewList, Event, Add } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import { useTranslation } from "react-i18next";
import ProductsModal from "../../../services/ProductsModal";
// import SourceModal from "../../../services/SourceModal";
import DatePickerModal from "../../../services/DatePickerModal";
import {
  cellFocusEditor,
  dateEditor,  
  generateBatchId,
} from "../../../services/Common";
import moment from "moment";
import "../common.css";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import { useMutation } from "react-query";
import * as api from "../../../actions/api";

import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";

//manufacturer modal
import ManufacturerModal from "../receive_from_supplier/ManufacturerModal";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useTranslation } from "react-i18next";

let isDirty = false;
let transactionItemID = 0;
let masterDirty = false;

const StockTakeFormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "stock-take";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  let tblRef = null;
  const classes = useStyles();
  const [manyTableData, setManyTableData] = useState([]);
  const [IsCompleted, setIsCompleted] = useState(false);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({ menukey });
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({
    menukey,
  });

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [SoureOpen, setSoureOpen] = useState(false);
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const UsersListByFacility = JSON.parse(
    localStorage.getItem("UsersListByFacility")
  );
  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [currExpiryDate, setCurrExpiryDate] = useState("");
  const [DatePickerOpen, setDatePickerOpen] = useState(false);

  const initFacilityData = JSON.parse(localStorage.getItem("initFacilityData"));

  // const [currFormulation, setCurrFormulation] = useState("");

  const { t, i18n } = useTranslation();

  let ManufacturerList = JSON.parse(localStorage.getItem("ManufacturerList"));
  let ManufacturerListVal = {};
  let ManufacturerListText = {};
  for (let i = 0; i < ManufacturerList.length; i++) {
    ManufacturerListVal[ManufacturerList[i].ManufacturerName]=ManufacturerList[i].ManufacturerName;
    ManufacturerListText[ManufacturerList[i].ManufacturerId]=ManufacturerList[i].ManufacturerName;
     
  }

  const [ManufacturerOpen, setManufacturerOpen] = useState(false);

  const [ManufacturerCode, setManufacturerCode] = useState("");

  /* ====Start of Print Excel Many Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl = EXCEL_EXPORT_URL + "report/esigl_stock_take_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StockTakeId=" +
        props.formData.StockTakeId +
        "&FacilityId=" +
        FacilityId +
        "&IsCompleted=" +
        IsCompleted +
        "&FacilityName=" +
        FacilityName +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl = EXCEL_EXPORT_URL + "report/esigl_stock_take_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StockTakeId=" +
        props.formData.StockTakeId +
        "&FacilityId=" +
        FacilityId +
        "&IsCompleted=" +
        IsCompleted +
        "&FacilityName=" +
        FacilityName +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* ====End of Excel Export Code=== */

  const manycolumns = [
    {
      title: "BatchId",
      field: "BatchId",
      headerSort: false,
      headerHozAlign: "center",
      visible: false,
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      visible: true,
      hozAlign: "center",
      width: 70,
      formatter: reactFormatter(<ActionButton />),
    },
    { title: "StockTakeItemsId", field: "StockTakeItemsId", visible: false },
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 65,
      hozAlign: "center",
      formatter: "rownum",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 125,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      // headerFilter: true,
      width: 175,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 125,
      editor:
        props.IsCompleted == 0
          ? props.isFirstPhysical == 0
            ? cellFocusEditor
            : ""
          : "", //"input"
      cssClass:
        props.isFirstPhysical == 0
          ? "tabluator-column-editable text-field-editable"
          : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();

        let CellOldValue = cell._cell.oldValue;

        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }

        if (CellOldValue != curcellval) {
          currow.getCell("dirty").setValue(1);
          isDirty = true;
          onSetDisable();
        }
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      hozAlign: "left",
      headerHozAlign: "left",
      width: 135,
      cssClass:
        props.isFirstPhysical == 0
          ? "tabluator-column-editable text-field-editable"
          : "",
      // editor: props.IsCompleted == 0 ? (props.isFirstPhysical==0?dateEditor:''): '',
      editor:
        props.IsCompleted == 0
          ? props.isFirstPhysical == 0
            ? cellFocusEditor
            : ""
          : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();

        let CellOldValue = cell._cell.oldValue;

        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }

        if (CellOldValue != curcellval) {
          currow.getCell("dirty").setValue(1);
          isDirty = true;
          onSetDisable();
        }
      },
    },
    {
      title: "",
      headerSort: false,

      visible: props.isFirstPhysical ? false : true,
      field: "custom",
      hozAlign: "center",
      width: 8,
      formatter: reactFormatter(<ShowDatePickerPopup />),
    },

   
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerId",
      visible: false,
      hozAlign: "left",
      headerHozAlign: "left",
      
      cssClass:
        props.isFirstPhysical == 0
          ? "tabluator-column-editable text-field-editable"
          : "",
      width: 190,
      headerSort: false,
       
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      hozAlign: "left",
      headerHozAlign: "left",
      
      cssClass:
        props.isFirstPhysical == 0
          ? "tabluator-column-editable text-field-editable"
          : "",
      width: 190,
      headerSort: false,
      visible: props.isFirstPhysical ? false : true,       
      editor:
        props.IsCompleted == 0
          ? props.isFirstPhysical == 0
            ?'autocomplete'//autoCompleteAjax// 
            : ""
          : "", 
          
        editorParams:{ 
          values:ManufacturerListVal,
          showListOnEmpty:true, //show all values when the list is empty,
          freetext:false, //allow the user to set the value of the cell to a free text entry
          allowEmpty:true, //allow empty string values
         
          searchingPlaceholder:"Filtering ...", //set the search placeholder
         // emptyPlaceholder:"(no matching results found)", //set the empty list placeholder
          listItemFormatter:function(value, title){ 
              return  title; 
          },
          sortValuesList:"asc", //if creating a list of values from values:true then choose how it should be sorted
        defaultValue:"", //set the value that should be selected by default if the cells value is undefined
        elementAttributes:{
            maxlength:"30", //set the maximum character length of the input element to 10 characters
        },
       // mask:"AAA-999",
        verticalNavigation:"hybrid",

        searchFunc:function(term, allItems){ //search for exact matches

        if(term==null) return allItems;
        if(term=='') return allItems;
        console.log("searchFN");
                
        
          let matches={};
          
          for (const [key, value] of Object.entries(ManufacturerListVal)) {
            console.log(key.search(term));
             
            if(key.toLocaleLowerCase().search(term.toLocaleLowerCase())==-1){
             
             
            }else if(key.toLocaleLowerCase().search(term.toLocaleLowerCase()>=0)){
              matches[key]=value;
            }
            
            
            
          } 

          if(Object.keys(matches).length==0)
          matches[''] =DispensingLanguage[lan][menukey]["No Data Found"]; 
          
          return matches;
          },
        },
        cellEdited: function (cell) { 
          const curcellval = cell.getValue();
          const currow = cell.getRow();
          
          let CellOldValue = cell._cell.oldValue;
  
          if (
            CellOldValue == null ||
            CellOldValue == "" ||
            CellOldValue == undefined
          ) {
            CellOldValue = "";
          }
  
          if (CellOldValue != curcellval) {
            currow.getCell("dirty").setValue(1);
            for (const [key, value] of Object.entries(ManufacturerListText)) {
              if(`${value}`==cell.getValue()){
                currow.getCell("ManufacturerId").setValue(`${key}`);                
              } 
            }

            if(curcellval=='')
              currow.getCell("ManufacturerId").setValue('');   
            
            isDirty = true;
            onSetDisable();
          }
        },
      },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass:
        props.isFirstPhysical == 0
          ? "tabluator-column-editable text-field-editable"
          : "",
      width: 190,
      headerSort: false,
      visible: props.isFirstPhysical ? true : false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Stock Quantity"],
      field: "StoreQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Physical Quantity"],
      field: "PhysicalQty",
      width: 140,
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      editor: props.IsCompleted == 0 ? cellFocusEditor : "", //"input"
      validator: ["integer", "min:0"],
      cssClass: props.IsCompleted == 0 ? "tabluator-column-editable" : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        console.log("Old Value", cell._cell.oldValue);
        console.log("New Value", curcellval);

        let CellOldValue = cell._cell.oldValue;
        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }

        //if(cell._cell.oldValue!=curcellval){
        if (CellOldValue != curcellval) {
          const pricecell = currow.getCell("AdjQuantity");
          const totprice =
            curcellval != "" ? curcellval - rowdata.StoreQty : "";
          pricecell.setValue(totprice);
          currow.getCell("dirty").setValue(1);
          isDirty = true;
          onSetDisable();
        }
      },

      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue(); /* Actual Order Quantity */
        // if(!cellValue) return;
        const rowData = cell.getRow().getData();
        const AdjQuantity = rowData.AdjQuantity;
        const StoreQty = rowData.StoreQty;
        const Remarks = rowData.Remarks;

        if (props.IsCompleted == 0) {
          if (props.isFirstPhysical == true || props.isFirstPhysical == 1) {
            if (!hasRemarks(AdjQuantity, cellValue, StoreQty, Remarks)) {
              cell.getRow().getElement().style.backgroundColor = "#ec7063";
            } else {
              cell.getRow().getElement().style.backgroundColor = "#efefef";
            }
          } else {
            cell.getRow().getElement().style.backgroundColor = "#efefef";
          }
        } else {
          cell.getRow().getElement().style.backgroundColor = "#efefef";
        }

        return cellValue;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adj. Quantity"],
      field: "AdjQuantity",
      width: 118,
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
    },

    {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "Remarks",
      //width: 150,
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass:
        props.IsCompleted == 0
          ? "tabluator-column-editable text-field-editable"
          : "",
      editor: props.IsCompleted == 0 ? cellFocusEditor : "",

      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue(); /* Remarks Quantity */
        if (!cellValue) return;
        const rowData = cell.getRow().getData();
        const AdjQuantity = rowData.AdjQuantity;
        const StoreQty = rowData.StoreQty;
        const PhysicalQty = rowData.PhysicalQty;

        if (props.IsCompleted == 0) {
          if (props.isFirstPhysical == true || props.isFirstPhysical == 1) {
            if (!hasRemarks(AdjQuantity, PhysicalQty, StoreQty, cellValue)) {
              cell.getRow().getElement().style.backgroundColor = "#ec7063";
            } else {
              cell.getRow().getElement().style.backgroundColor = "#efefef";
            }
          } else {
            cell.getRow().getElement().style.backgroundColor = "#efefef";
          }
        } else {
          cell.getRow().getElement().style.backgroundColor = "#efefef";
        }

        return cellValue;
      },
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();

        let CellOldValue = cell._cell.oldValue;

        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }

        if (CellOldValue != curcellval) {
          currow.getCell("dirty").setValue(1);
          isDirty = true;
          onSetDisable();
        }
      },
    },
  ];

  const onSetDisable = () => {
    if (isDirty == true) {
      //document.getElementById("is_dirty_message").classList.remove("dnone");
      document.getElementById("master_dirty_message").classList.remove("dnone");
    } else {
      //document.getElementById("is_dirty_message").classList.add("dnone");
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  };

  const onMasterSetDirty = () => {
    if (masterDirty == true) {
      document.getElementById("master_dirty_message").classList.remove("dnone");
    } else {
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  };

  function hasRemarks(AdjQuantity, PhysicalQty, StoreQty, Remarks) {
    const AdjQuantity1 = Number(AdjQuantity);
    const PhysicalQty1 = Number(PhysicalQty);
    const StoreQty1 = Number(StoreQty);
    const Rmkrs = !(Remarks == null || Remarks == "") ? true : false;

    if (
      PhysicalQty1 == null ||
      PhysicalQty1 == "" ||
      StoreQty1 == PhysicalQty
    ) {
      return true;
    } else {
      if (StoreQty1 !== PhysicalQty) {
        if (!Rmkrs) {
          return false;
        }
      }
    }

    return true;
  }

  const [selectedManufacturers, setSelectedManufacturers] = useState({});

  const handleManufacturerChange = (event, newValue, rowData) => {
    setSelectedManufacturers((prevState) => ({
      ...prevState,
      [rowData.SKU]: newValue,
    }));

    //console.log([rowData], rowData.SKU);
    setcombochangevalue(newValue, rowData.SKU);
  };

  //console.log(props.isFirstPhysical);
  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    //console.log(rowData)
    // let fieldName = props.field;
    let fieldType = props.fieldType;
    // let labelName = props.labelName;
    // let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "drop-down-manufacturer":
        return (
          <FormControl className={classes.fullWidth}>
            <Autocomplete
              autoHighlight
              id={`ManufacturerId_${rowData.SKU}`} // Unique identifier for each row
              //disableClearable
              //disabled ={props.isFirstPhysical==0?true:false}
              options={ManufacturerList}
              value={selectedManufacturers[rowData.SKU] || ""}
              onChange={(event, newValue) =>
                handleManufacturerChange(event, newValue, rowData)
              }
              getOptionLabel={(option) => option.ManufacturerName || ""}
              renderOption={(option) => (
                <Typography className="sw_fontSize">
                  {option.ManufacturerName}
                </Typography>
              )}
              //renderInput={(params) => <TextField {...params} variant="standard" />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    // Set initial values when the page is edited
    const initialValues = {};
    manyTableData.forEach((rowData) => {
      initialValues[rowData.SKU] = {
        ManufacturerId: rowData.ManufacturerId || "",
        ManufacturerName: rowData.ManufacturerName || "",
      };
    });
    setSelectedManufacturers(initialValues);
  }, [manyTableData]);

  const onPostBtnClick = () => {
    setIsCompleted(true);
    handlePost();
  };

  const isContainDuplicate = (dataArray) => {
    const mappedData = dataArray.map((el, i) => ({
      ...el,
      BatchId: generateBatchId(el),
    }));
    const removedDuplicate = new Set(mappedData.map((el) => el.BatchId));

    if (mappedData.length !== removedDuplicate.size) {
      return { data: "", status: true };
    } else {
      setManyTableData(mappedData);
      return { data: mappedData, status: false };
    }

    /*
    let rowsdata = [];
    let duplicateRowsdata = []; 
    let duplicateItemNames="";

    dataArray.forEach((row, i) => {
      let rowsdatas = {};
      rowsdatas.BatchId = generateBatchId(row);
      rowsdatas.ItemNo = row.ItemNo;
      rowsdatas.ItemCode = row.ItemCode;

      rowsdatas.GroupName = row.GroupName;
      rowsdatas.ItemName = row.ItemName;
      rowsdatas.StockTakeItemsId = row.StockTakeItemsId;
      rowsdatas.SKU = row.SKU;
      rowsdatas.id = row.id;

      rowsdatas.BatchNo = row.BatchNo;
      rowsdatas.ExpiryDate = row.ExpiryDate;
      rowsdatas.StoreQty = row.StoreQty;
      rowsdatas.PhysicalQty = row.PhysicalQty;
      rowsdatas.AdjQuantity = row.AdjQuantity;
      rowsdatas.Remarks = row.Remarks;
      rowsdatas.ManufacturerId = row.ManufacturerId;
      rowsdatas.ManufacturerName = row.ManufacturerName;

      rowsdata.push(rowsdatas);


      let batch=rowsdatas.BatchId;
      if(duplicateRowsdata.indexOf(batch) !== -1)  
      {  
         duplicateItemNames=duplicateItemNames==""?(row.ItemName):(duplicateItemNames+", "+ row.ItemName);
          
      }   
      else  
      {  
        duplicateRowsdata.push(rowsdatas.BatchId);
        
      }

      
    });*/

    /*
         if(duplicateItemNames!=''){
          return {data:duplicateItemNames,status:true};
         }else{
          setManyTableData(mappedData);
          return {data:rowsdata,status:false};
         }*/
  };

  const onDataValidation = (data) => {
    // console.log(data, '=======')
    let ValidationCount = 0;

    for (let i = 0; i < data.length; i++) {
      let AdjQuantity = data[i].AdjQuantity;
      let PhysicalQty = data[i].PhysicalQty;
      let StoreQty = data[i].StoreQty;
      let Remarks = data[i].Remarks;

      if (PhysicalQty != null && PhysicalQty != "" && PhysicalQty != StoreQty) {
        if (Remarks == null || Remarks == "") {
          ValidationCount++;
        }
      }

      if (ValidationCount > 0) {
        return ValidationCount;
      }
    }
    return ValidationCount;
  };

  const onSaveUpdateBtnClick = () => {
    ///===================Check Non Non Perishable  of any item==========/////////////
    let CheckPerishable = 1;

    if (props.isFirstPhysical == 0) {
      let NonPerishableInfo = {};

      tblRef.table.getData().forEach((row, i) => {
        if (row.NonPerishable == 0) {
          if (
            row.ExpiryDate === null ||
            row.ExpiryDate === "" ||
            row.ExpiryDate === undefined
          ) {
            NonPerishableInfo = row;

            props.openNoticeModal({
              isOpen: true,
              msg: t(
                DispensingLanguage[lan][menukey][
                  "Perishable item - blank expiry date is not allowed"
                ]
              ), // + ' - ' + NonPerishableInfo.ItemName,
              msgtype: 0,
            });
            CheckPerishable = 0;
            return;
          }
        }
      });
    }
    ///===================Check Perishable  of any item==========/////////////

    if (CheckPerishable == 1) {
      let manydata = tblRef.table.getData();

      // checkLotValidation(manydata)

      const isDuplicate = isContainDuplicate(manydata);
      if (isDuplicate.status) {
        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+" - "+isDuplicate.data,
          msgtype: 0,
        });
      } else {
        setUpdatedDetailsGridDataHandler(isDuplicate.data);
      }
    }
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let manydataR = [];
    rows.forEach((row, i) => {
      if (row.dirty == 1) manydataR.push(row);
    });

    let validForm = props.checkValidateForm(manydataR, "edit");
    // masterDirty
    //  console.log('isMasterDirty: ', validForm);
    // return;

    if (validForm) {
      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutate({ ...validForm, masterDirty: masterDirty });
      } else {
        //when update
        if (props.isFirstPhysical) {
          let ValidationCount = onDataValidation(rows);
          if (ValidationCount > 0) {
            props.openNoticeModal({
              isOpen: true,
              msg: DispensingLanguage[lan][menukey][
                "Please, mention reason quantity change"
              ],
              msgtype: 0,
              duration: 5000,
            });
            return;
          }
        }

        mutateUpdate({ ...validForm, masterDirty: masterDirty });
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(
    api.saveStockTakeInv,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          masterDirty = false;
          onMasterSetDirty();
          isDirty = false;
          setDeletedDataSet([]);
          //onSetDisable();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          let route = `edit/${data.StockTakeId}`;
          props.history.push(route);
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.updateStockTakeInv,
    {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) {
          masterDirty = false;
          onMasterSetDirty();
          isDirty = false;
          setDeletedDataSet([]);
          //onSetDisable();
          //  console.log("props: ",props.formData.TransactionId);
          //setbStockUpdate(data.bStockUpdated);
          // props.successSave(data.bStockUpdated);
          if (data.IsCompleted == 1) {
            mutatePost(props.formData.StockTakeId);
          } else {
            // props.refetchInvoice(data.bStockUpdated); //refetch();
            props.refetch(); //refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.refetch(); //refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  /*
  const handlePost11 = async (e) => {
    const isDuplicate = isContainDuplicate(tblRef.table.getData());
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+ " - " + isDuplicate.data,
        msgtype: 0,
      });
    } else {
      let validForm = props.checkValidateFormPost(isDuplicate.data, "post");

      if (validForm) {
        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text: t(
            DispensingLanguage[lan][menukey][
              "Do you really want to post the stock?"
            ]
          ),
          icon: "warning",
          buttons: {
            confirm: {
              text: t(DispensingLanguage[lan][menukey]["Yes"]),
              value: true,
              visible: true,
              className: "sw_confirm_btn",
              closeModal: true,
            },
            cancel: {
              text: t(DispensingLanguage[lan][menukey]["No"]),
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
        }).then((willAction) => {
          if (willAction) {
            let FinalData = {
              ...validForm,
              IsCompleted: 1,
              menukey: menukey,
              lan: lan,
              UserName: UserName,
            };
            if (props.isFirstPhysical) {
              let ValidationCount = onDataValidation(isDuplicate.data);
              if (ValidationCount > 0) {
                props.openNoticeModal({
                  isOpen: true,
                  msg: DispensingLanguage[lan][menukey][
                    "Please, mention reason quantity change"
                  ],
                  msgtype: 0,
                  duration: 10000,
                });
                return;
              }
            }
            mutateUpdate(FinalData);
            //setManyTableData(rows);
          } else {
            props.stockPosted(0);
            let route = `${validForm.StockTakeId}`;
            props.history.push(route);
          }
        });
      }
    }
  };

  */

  const handlePost = async (e) => {
    ///===================Check Non Non Perishable  of any item==========/////////////
    let CheckPerishable = 1;

    if (props.isFirstPhysical == 0) {
      let NonPerishableInfo = {};

      tblRef.table.getData().forEach((row, i) => {
        if (row.NonPerishable == 0) {
          if (
            row.ExpiryDate === null ||
            row.ExpiryDate === "" ||
            row.ExpiryDate === undefined
          ) {
            NonPerishableInfo = row;

            props.openNoticeModal({
              isOpen: true,
              msg: t(
                DispensingLanguage[lan][menukey][
                  "Perishable item - blank expiry date is not allowed"
                ]
              ), // + ' - ' + NonPerishableInfo.ItemName,
              msgtype: 0,
            });
            CheckPerishable = 0;
            return;
          }
        }
      });
    }
    ///===================Check Perishable  of any item==========/////////////

    if (CheckPerishable == 1) {
      const isDuplicate = isContainDuplicate(tblRef.table.getData());
      if (isDuplicate.status) {
        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+ " - " + isDuplicate.data,
          msgtype: 0,
        });
      } else {
        let manydataP = [];
        isDuplicate.data.forEach((row, i) => {
          if (row.dirty == 1) manydataP.push(row);
        });

        let validForm = props.checkValidateFormPost(manydataP, "post");
        //let validForm = props.checkValidateFormPost(isDuplicate.data, "post");

        if (validForm) {
          let FinalData = {
            ...validForm,
            IsCompleted: 1,
            menukey: menukey,
            lan: lan,
            UserName: UserName,
            masterDirty: masterDirty,
          };
          if (props.isFirstPhysical) {
            let ValidationCount = onDataValidation(isDuplicate.data);
            if (ValidationCount > 0) {
              props.openNoticeModal({
                isOpen: true,
                msg: DispensingLanguage[lan][menukey][
                  "Please, mention reason quantity change"
                ],
                msgtype: 0,
                duration: 5000,
              });
              return;
            }
          }

          modalPopupDelete(
            mutateUpdate,
            FinalData,
            { PostQ: "modalQPost" },
            ModalNo,
            validForm
          );
        }
      }
    }
  };

  const ModalNo = (validForm) => {
    props.stockPosted(0);
    let route = `${validForm.StockTakeId}`;
    props.history.push(route);
  };

  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(
    api.postStockTakeInv,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          //props.setbStockUpdate(1);
          //setDeletedDataSet([]);

          if (data.success == 0) {
            props.stockPosted(0);
            props.refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });

            // swal(data.data.message, "", "warning");
          } else {
            localStorage.setItem(
              "initFacilityData",
              JSON.stringify([
                {
                  validStartDate: initFacilityData[0].validStartDate,
                  validEndDate: initFacilityData[0].validEndDate,
                  LMISStartDate: initFacilityData[0].LMISStartDate,
                  LASTLMISPeriod: initFacilityData[0].LASTLMISPeriod,
                  InitComplete: 1,
                },
              ])
            );

            props.stockPosted(1);
            props.refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });

            //swal(data.data.message, "", "success");
          }
        } else {
          props.stockPosted(0);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const addProduct = (NonOrderProductData) => {
    let rows = [];
    let autoId = 0;

    //console.log("ManyTableData addProduct ---------------", manyTableData);
    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.BatchId = row.BatchId;
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.dirty = row.dirty;

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.StockTakeItemsId = row.StockTakeItemsId;
      newRow.SKU = autoId;
      newRow.id = autoId;

      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.StoreQty = row.StoreQty;
      newRow.PhysicalQty = row.PhysicalQty;
      newRow.AdjQuantity = row.AdjQuantity;
      newRow.Remarks = row.Remarks;
      newRow.ManufacturerId = row.ManufacturerId;
      newRow.ManufacturerName = row.ManufacturerName;
      // newRow.SourceId = row.SourceId;
      // newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    let temNonordata = NonOrderProductData;
    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.BatchId = generateBatchId(row);
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.dirty = 1;

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;

      newRow.StockTakeItemsId = -1;

      newRow.SKU = autoId;
      newRow.id = autoId;

      newRow.BatchNo = null;
      newRow.ExpiryDate = null;
      newRow.StoreQty = null;
      newRow.PhysicalQty = null;
      newRow.AdjQuantity = null;
      newRow.Remarks = null;
      newRow.ManufacturerId = null;
      newRow.ManufacturerName = null;
      // newRow.SourceId = null;
      // newRow.SourceName = null;

      rows.push(newRow);

      
    });


    isDirty = true;
    onSetDisable();

    //rows.sort((a, b) => (a.ItemName > b.ItemName) ? 1 : -1)

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
    // handleClose("SourceClose");
  };

  const setcombochangevalue = (newValue, skusl) => {
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU == skusl) {
        if (newValue == null || newValue == "") {
          newRow.ManufacturerId = null;
          newRow.ManufacturerName = null;
        } else {
          newRow.ManufacturerId = newValue.ManufacturerId;
          newRow.ManufacturerName = newValue.ManufacturerName;
        }

        newRow.dirty = 1;
        isDirty=true;
        onSetDisable();

      } else {
        newRow.ManufacturerId = row.ManufacturerId;
        newRow.ManufacturerName = row.ManufacturerName;
        newRow.dirty = row.dirty;

      }
      newRow.BatchId = generateBatchId(row);
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.StockTakeItemsId = row.StockTakeItemsId;
      newRow.SKU = row.SKU;
      newRow.id = row.id;

      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.StoreQty = row.StoreQty;
      newRow.PhysicalQty = row.PhysicalQty;
      newRow.AdjQuantity = row.AdjQuantity;
      newRow.Remarks = row.Remarks;

      rows.push(newRow);
    });

    setManyTableData(rows);
  
  };

  const addSource = (productBatchData) => {
    if (!productBatchData) {
      return;
    }

    let temProductBatchData = productBatchData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU == currRowId) {
        // newRow.SourceId = temProductBatchData.SourceId;
        // newRow.SourceName = temProductBatchData.SourceName;
      } else {
        // newRow.SourceId = row.SourceId;
        // newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.StockTakeItemsId = row.StockTakeItemsId;
      newRow.SKU = row.SKU;

      newRow.id = row.id;

      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;

      newRow.StoreQty = row.StoreQty;
      newRow.PhysicalQty = row.PhysicalQty;
      newRow.AdjQuantity = row.AdjQuantity;
      newRow.Remarks = row.Remarks;
      newRow.ManufacturerId = row.ManufacturerId;
      newRow.ManufacturerName = row.ManufacturerName;
      rows.push(newRow);
    });

    // console.log("rows after link with product: ", rows);

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("SourceClose");
  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.IsCompleted || props.isFirstPhysical) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              //console.log('rowData.SKU: ', rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }

  const addDatePicker = (datePickerData) => {
    if (!datePickerData) {
      return;
    }

    let selectedExpiryDate = moment(datePickerData).format("DD/MM/YYYY");
    // console.log("selectedExpiryDate =========", selectedExpiryDate);
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        newRow.ExpiryDate = selectedExpiryDate;
        newRow.dirty = 1;
        isDirty=true;
        onSetDisable();
      } else {
        newRow.ExpiryDate = row.ExpiryDate;
        newRow.dirty = row.dirty;
      }
      newRow.BatchId = generateBatchId(row);
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.StockTakeItemsId = row.StockTakeItemsId;
      newRow.SourceId = row.SourceId;
      newRow.SourceName = row.SourceName;
      newRow.SKU = row.SKU;
      newRow.id = row.id;

      newRow.BatchNo = row.BatchNo;
      newRow.StoreQty = row.StoreQty;
      newRow.PhysicalQty = row.PhysicalQty;
      newRow.AdjQuantity = row.AdjQuantity;
      newRow.Remarks = row.Remarks;
      newRow.ManufacturerId = row.ManufacturerId;
      newRow.ManufacturerName = row.ManufacturerName;
      rows.push(newRow);
    });

    // console.log("rows after link with product: ", rows);

    setManyTableData(rows);
    props.handleAdd(rows);
    //productAdd(rows);
    handleClose("DatePickerClose");

  };

  function ShowDatePickerPopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    //console.log("InvFrom=================",props.formData.InvFrom);

    if (props.IsCompleted || props.isFirstPhysical) {
      return <></>;
    } else {
      return (
        <>
          <Event
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              setCurrExpiryDate(rowData.ExpiryDate);
              // setCurrExpiryDate(rowData);
              handleClose("DatePickerOpen");
            }}
          />
        </>
      );
    }
  }

  const addManufacturer = (ManufacturerData) => {
    if (ManufacturerData != "") {
      handleClose("ManufacturerModal");
      setTimeout(
        () => setManufacturerCode(ManufacturerData.ManufacturerId),
        500
      );
    }
  };

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      case "DatePickerOpen":
        setDatePickerOpen(true);
        break;

      case "DatePickerClose":
        setDatePickerOpen(false);
        break;

      case "ManufacturerModal":
        setManufacturerOpen(false);
        break;
      case "ManufacturerOpen":
        setManufacturerOpen(true);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    //console.log("ManyTableData :::::::::::::::::::", props.ManyTableData);
    if (tblRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }
    setIsCompleted(props.IsCompleted);

    setDeletedDataSet([]);
  }, [props.ManyTableData, props.IsCompleted]);

  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter((element) => element != data);
    setManyTableData(newarray);
    //alert(data.StockTakeItemsId);
    if ((data.StockTakeItemsId!= -1)) {
      //alert(data.StockTakeItemsId);
      let newDeletedDataSet = [data.StockTakeItemsId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
      props.deleteAdjustmentInv(data);

      isDirty = true;
      onSetDisable();
    }
  };

  function ActionButton(propss) {
    //console.log('propss: ', propss.cell._cell.row);

    const rowData = propss.cell._cell.row.data;

    //console.log('propss: ',rowData.id);
    /*
    if (rowData.StockTakeItemsId == -1) {
      if (tblRef != null) tblRef.table.selectRow(rowData.id);
    }
    */

    // const cellValue = propss.cell._cell.value || 'Edit | Show';
    if (
      props.IsCompleted == 1 ||
      rowData.BatchId == null ||
      props.isFirstPhysical == 1
    )
      return <></>;
    else {
      if (rowData.StockTakeItemsId == -1)
        return (
          <>
            <DeleteOutline
              onClick={() => {
                deleteReceiveInv(rowData);
              }}
            />
          </>
        );
      else
        return (
          <>
            <DeleteOutline
              onClick={() => {
                deleteReceiveInv(rowData);
              }}
            />
          </>
        );
    }
  }

  const backToList = () => {
    console.log("Ms Dirty", masterDirty);
    console.log("Many Dirty", isDirty);

    if (masterDirty == true || isDirty == true) {
      modalPopupBacktoList(backToListCallBack, "/stock-take", {
        PostQ: "modalBackToListQ",
      });
    } else props.history.push("/stock-take");
  };

  const backToListCallBack = (PageUrl) => {
    masterDirty = false;
    isDirty = false;
    setDeletedDataSet([]);
    props.history.push(PageUrl);
  };

  const formOnchange = (e, cases, inputname) => {
    console.log("On form change", inputname);
    //isDirty=true;

    if (cases == "handleChange") props.handleChange(e);
    else if (cases == "handleRDateChange")
      props.handleRDateChange(e, inputname);

    masterDirty = true;
    onMasterSetDirty();
  };

  // const [chosenValues, setChosenValues] = useState({
  //   PreparedBy: { id: "", name: "" },
  //   ApprovedBy: { id: "", name: "" },
  // });

  // useEffect(() => {
  //   let chosenValuesData = { ...chosenValues };
  //   if (props.addProductForm) {
  //     if (props.formData.PreparedBy == undefined) {
  //       chosenValuesData["PreparedBy"] =
  //         UsersListByFacility[
  //           UsersListByFacility.findIndex(
  //             (Users_ListByFacility) => Users_ListByFacility.id == UserId
  //           )
  //         ];
  //       props.formData.PreparedBy = UserId;
  //     }
  //   } else {
  //     chosenValuesData["PreparedBy"] =
  //       UsersListByFacility[
  //         UsersListByFacility.findIndex(
  //           (Users_ListByFacility) =>
  //             Users_ListByFacility.id == props.formData.PreparedBy
  //         )
  //       ];
  //   }
  //   chosenValuesData["ApprovedBy"] =
  //     UsersListByFacility[
  //       UsersListByFacility.findIndex(
  //         (Users_ListByFacility_A) =>
  //           Users_ListByFacility_A.id == props.formData.ApprovedBy
  //       )
  //     ];

  //   //chosenValuesData['ManufacturerId'] = ManufacturerList[ManufacturerList.findIndex(Manufacturer_List => Manufacturer_List.ManufacturerId == props.formData.ManufacturerId)];
  //   setChosenValues(chosenValuesData);
  // }, [props.formData.PreparedBy, props.formData.ApprovedBy]);

  // const handleChangeChoosen = (name, valueobj, value) => {
  //   let chosenValuesData = { ...chosenValues };
  //   chosenValuesData[name] = valueobj;

  //   setChosenValues(chosenValuesData);

  //   props.handleChangeChoosenProps(name, value);

  //   masterDirty = true;
  //   onMasterSetDirty();
  // };

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Stock Take - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {/* Action buttons */}
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    {/* <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]['Reset']}
                      </Button> */}

                    <div className="mr-4 mt-2 float-left">
                      <span
                        id="master_dirty_message"
                        className={"masterredtextcolor dnone"}
                      >
                        {" "}
                        {
                          DispensingLanguage[lan][menukey][
                            "Form has dirty data"
                          ]
                        }
                      </span>
                    </div>

                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      disabled={isLoadingSave}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/stock-take")}
                      onClick={() => backToList()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <div className="mr-4 mt-2 float-left">
                      <span
                        id="master_dirty_message"
                        className={"masterredtextcolor dnone"}
                      >
                        {" "}
                        {
                          DispensingLanguage[lan][menukey][
                            "Form has dirty data"
                          ]
                        }
                      </span>
                    </div>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      //onClick={() => props.handleUpdate()}
                      disabled={
                        props.IsCompleted || isLoadingUpdate || isLoadingPost
                      }
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      disabled={
                        props.IsCompleted ||
                        isLoadingUpdate ||
                        isLoadingPost ||
                        !(manyTableData.length > 0)
                      }
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={event =>  window.location.href='/order'}
                      // onClick={() => props.history.push("/stock-take")}
                      onClick={() => backToList()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
                {/* End Action buttons */}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        required
                        error={props.errorObject.CompleteDate}
                        helperText={props.errorObject.CompleteDate}
                        id="CompleteDate"
                        label={
                          DispensingLanguage[lan][menukey]["Date of Stock Take"]
                        }
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        autoOk={true}
                        name="CompleteDate"
                        fullWidth
                        showTodayButton={true}
                        value={
                          parseInt(initFacilityData[0].InitComplete)
                            ? props.formData.CompleteDate || props.selectedDate
                            : initFacilityData[0].validStartDate
                        }
                        format="dd/MM/yyyy"
                        //onChange={(e) =>props.handleRDateChange(e, "CompleteDate")}
                        onChange={(e) =>
                          formOnchange(e, "handleRDateChange", "CompleteDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={
                          parseInt(initFacilityData[0].InitComplete)
                            ? props.IsCompleted
                            : true
                        }
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={2} sm={2} hidden={true}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        required
                        error={props.errorObject.StockTakeDate}
                        helperText={props.errorObject.StockTakeDate}
                        id="StockTakeDate"
                        label={DispensingLanguage[lan][menukey]["Start Date"]}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        autoOk={true}
                        name="StockTakeDate"
                        fullWidth
                        showTodayButton={true}
                        value={
                          props.isFirstPhysical
                            ? props.formData.CompleteDate || props.selectedDate
                            : initFacilityData[0].validStartDate
                        }
                        format="dd/MM/yyyy"
                        //onChange={(e) =>props.handleRDateChange(e, "StockTakeDate")}
                        onChange={(e) =>
                          formOnchange(e, "handleRDateChange", "StockTakeDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={
                          props.isFirstPhysical ? props.IsCompleted : true
                        }
                        // disabled={props.IsCompleted}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                     disabled={props.IsCompleted}
                      // error={props.errorObject.Remarks}
                      // helperText={props.errorObject.Remarks}
                      // required
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarksmaster"]}
                      value={props.formData.Remarks}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>
                        formOnchange(e, "handleChange", "Remarks")
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={props.IsCompleted || props.bStockUpdate}
                        autoHighlight
                        id="PreparedBy"
                        options={UsersListByFacility}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "PreparedBy",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValues["PreparedBy"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.PreparedBy}
                            label={
                              DispensingLanguage[lan][menukey]["Prepared By"]
                            }
                            variant="standard"
                            error={props.errorObject.PreparedBy}
                            helperText={props.errorObject.PreparedBy}
                            id="PreparedBy"
                            name="PreparedBy"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={props.IsCompleted || props.bStockUpdate}
                        autoHighlight
                        id="ApprovedBy"
                        options={UsersListByFacility}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "ApprovedBy",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValues["ApprovedBy"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ApprovedBy}
                            label={
                              DispensingLanguage[lan][menukey]["Approved By"]
                            }
                            variant="standard"
                            error={props.errorObject.ApprovedBy}
                            helperText={props.errorObject.ApprovedBy}
                            id="ApprovedBy"
                            name="ApprovedBy"
                            fullWidth
                            
                          />
                        )}
                      />
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item xs={6} sm={6}>
                    <FormControl className={classes.fullWidth_2}>
                      <Add
                        className="add_plus"
                        //hidden={manyTableData.length > 0 ? true : props.bStockUpdate}
                        onClick={() => handleClose("ManufacturerOpen")}
                      />
                    </FormControl>

                    <ManufacturerModal
                      handleClose={handleClose}
                      openOrder={ManufacturerOpen}
                      addManufacturer={addManufacturer}
                      {...props}
                    />
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="StockTakeId"
                      name="StockTakeId"
                      label="StockTakeId"
                      value={props.formData.StockTakeId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  {/* new row */}
                  <Grid item xs={12} sm={12}>
                    <Card className="sw_card">
                      <CardContent>
                        <Grid container className="mb-2">
                          <Grid item xs={12} sm={12}>
                            <div className="float-right sw_btn_control">
                              {props.addProductForm ? (
                                <></>
                              ) : (
                                <Grid item xs={12} className="text-center">
                                  {props.isFirstPhysical ? (
                                    <></>
                                  ) : (
                                    <Button
                                      disabled={props.IsCompleted}
                                      className="mr-2"
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        handleClose("ProductsOpen")
                                      }
                                    >
                                      {
                                        DispensingLanguage[lan][menukey][
                                          "SELECT NON-ORDER PRODUCT"
                                        ]
                                      }
                                    </Button>
                                  )}
                                </Grid>
                              )}
                            </div>

                            <ProductsModal
                              handleClose={handleClose}
                              open={open}
                              addProduct={addProduct}
                              {...props}
                            />
                            {/* <SourceModal
                              handleClose={handleClose}
                              open={SoureOpen}
                              addProduct={addSource}
                              {...props}
                            /> */}
                            <DatePickerModal
                              handleClose={handleClose}
                              open={DatePickerOpen}
                              addDatePicker={addDatePicker}
                              reload={0}
                              ExpiryDate={currExpiryDate}
                              {...props}
                            />
                          </Grid>
                        </Grid>

                        <div>
                          <div className="uniqueName">
                            <ReactTabulator
                              ref={(r) => (tblRef = r)}
                              columns={manycolumns}
                              data={manyTableData.sort((a, b) => {
                                const groupComparison =
                                  a.GroupName.localeCompare(b.GroupName);
                                return groupComparison !== 0
                                  ? groupComparison
                                  : a.ItemName.localeCompare(b.ItemName);
                              })}
                              layout={"fitColumns"}
                              height="420px"
                              options={{
                                groupBy: "GroupName",
                              }}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StockTakeFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
