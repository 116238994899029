import React, { useState } from "react";
import { Link } from "react-router-dom";
// services
import * as Service from "../../services/Service.js";

//Css
import "assets/css/custom.css";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
 
  
} from "@material-ui/core";

import {
  Button,
} from "reactstrap";

import { TextFields } from "@material-ui/icons";



import swal from "sweetalert";

// core components

const regex =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

function SignUp(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [loginNameFocus, setLoginNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [designationFocus, setDesignationFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [confirmPasswordFocus, setConfirmPasswordFocus] = React.useState(false);
  const [langCodeFocus, setLangCodeFocus] = React.useState(false);

  const [Language, setLangguage] = React.useState(true);
  const [Facility, setFacility] = React.useState(true);

  const [languageList, setLanguageList] = React.useState([]);
  const [FacilityList, setFacilityList] = React.useState([]);

  const classes = useStyles();

  const [state, setState] = React.useState({
    firstName: "",
    lastName: "",
    loginname: "",
    email: "",
    designation: "",
    password: "",
    confirmPassword: "",
    service: Service,
    LangCode: "fr_FR",
    FacilityId: "",
  });

  if (Language) {
    state.service.default
      .postApi("source/language_combo.php")
      .then((res) => {
        setLanguageList(res);
        setLangguage(false);
      })
      .catch((err) => {});
  }

  if (Facility) {
    state.service.default
      .postApi("source/facility_combo.php")
      .then((res) => {
        setFacilityList(res);
        setFacility(false);
      })
      .catch((err) => {});
  }

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const submit = (e) => {
    e.preventDefault();
    if (
      state.firstName.length > 0 &&
      state.lastName.length > 0 &&
      state.loginname.length > 0 &&
      state.email.length > 0 &&
      state.designation.length > 0 &&
      state.password.length > 0 &&
      state.confirmPassword.length > 0 &&
      state.LangCode.length > 0
    ) {
      if (!regex.test(state.email)) {
        swal("Oops Error!", "Your email is not valid!", "error");
      } else if (state.password !== state.confirmPassword) {
        swal("Oops Error!", "Your Password is not same!", "error");
      } else if (state.loginname.indexOf(" ") >= 0) {
        swal(
          "Oops Error!",
          "White space is not allowed in login name.",
          "error"
        );
      } else {
        // swal("Success!", "", "success");
        const body = {
          name: state.firstName + " " + state.lastName,
          loginname: state.loginname,
          email: state.email,
          password: state.password,
          designation: state.designation,
          role: "User",
          LangCode: state.LangCode,
          FacilityId: state.FacilityId,
        };
        state.service.default
          .postApi("source/register.php", body)
          .then((res) => {
            if (res.success === 1) {
              swal("Success!", `${res.message}`, "success");
              props.history.push("/login");
            } else if (res.success === 0) {
              swal("Error!", `${res.message}`, "error");
            }
          })
          .catch((err) => {
            swal("Error!", `${err}`, "error");
          });
      }
    } else {
      swal("Oops Error!", "Please fill all required fields", "error");
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setconfirmPasswordShown(confirmPasswordShown ? false : true);
  };
  return (
    <>

      <ExamplesNavbar />

      <div
        className="section signup-top-padding"
        style={{
          backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          backgroundSize: "cover",
          minHeight: "700px",
          backgroundPosition: "center center",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
      <Grid container spacing={3}>
        <Grid item xs={4} sm={4}></Grid>
        <Grid item xs={4} sm={4}>
          <div className="SignUpDiv">
          
          

          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            {/* First Name Start */}

            <div className={ "no-border" + (firstFocus ? " input-group-focus" : "")}>
              <div className="sw_TextFieldGroup">
                <span className="sw_TextFieldIcon"><i class="now-ui-icons text_caps-small"></i></span>
                  <TextField 
                  id="firstName"                  
                  name="firstName"
                  label="*First Name..." 
                  variant="outlined"
                  onChange={(evt) => handleChange(evt)}
                  type="text"
                  onFocus={() => setFirstFocus(true)}
                  onBlur={() => setFirstFocus(false)}
                  invalid={state.firstNameValidate}
                  />
                  {state.firstNameValidate ? (
                      <span className="red">
                        *This is a required field
                      </span>
                    ) : null}
              </div>
            </div>

            {/* First Name End */}

            {/* Last Name Start */}

            <div className={ "no-border" + (lastFocus ? " input-group-focus" : "")}>
              <div className="sw_TextFieldGroup">
              <span className="sw_TextFieldIcon"><i class="now-ui-icons text_caps-small"></i></span>
                <TextField 
                id="lastName" 
                name="lastName" 
                label="*Last Name..." 
                variant="outlined"
                type="text"
                onChange={(evt) => handleChange(evt)}
                onFocus={() => setLastFocus(true)}
                onBlur={() => setLastFocus(false)}
                invalid={state.lastNameValidate} 
                />
                {state.lastNameValidate ? (
                    <span className="red">
                      *This is a required field
                    </span>
                  ) : null}
              </div>
            </div>  

            {/* Last Name End */}

            {/* User Name Start */}

            <div className={ "no-border" + (loginNameFocus ? " input-group-focus" : "")}>
              <div className="sw_TextFieldGroup">
              <span className="sw_TextFieldIcon"><i class="now-ui-icons users_circle-08"></i></span>
                <TextField 
                id="loginname" 
                name="loginname" 
                label="*Username..." 
                variant="outlined"
                type="text"
                onChange={(evt) => handleChange(evt)}
                onFocus={() => setLoginNameFocus(true)}
                onBlur={() => setLoginNameFocus(false)}
                invalid={state.loginNameValidate} 
                />
                {state.loginNameValidate ? (
                    <span className="red">
                      *This is a required field
                    </span>
                  ) : null}
              </div>
            </div>  

            {/* User Name End */}

            
            {/* Email Start */}

            <div className={ "no-border" + (emailFocus ? " input-group-focus" : "")}>
              <div className="sw_TextFieldGroup">
              <span className="sw_TextFieldIcon"><i class="now-ui-icons ui-1_email-85"></i></span>
                <TextField 
                id="email" 
                name="email" 
                label="*Email..." 
                variant="outlined"
                type="text"
                onChange={(evt) => handleChange(evt)}
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                invalid={state.emailValidate} 
                />
                {state.emailValidate ? (
                    <span className="red">
                      *This is a required field
                    </span>
                  ) : null}
              </div>
            </div>  

            {/* Email End */}


            {/* Designation Start */}

            <div className={ "no-border" + (designationFocus ? " input-group-focus" : "")}>
              <div className="sw_TextFieldGroup">
              <span className="sw_TextFieldIcon"><i class="now-ui-icons business_briefcase-24"></i></span>
                <TextField 
                id="designation" 
                name="designation" 
                label="*Designation..." 
                variant="outlined"
                type="text"
                onChange={(evt) => handleChange(evt)}
                onFocus={() => setDesignationFocus(true)}
                onBlur={() => setDesignationFocus(false)}
                invalid={state.designationValidate} 
                />
                {state.designationValidate ? (
                    <span className="red">
                      *This is a required field
                    </span>
                  ) : null}
              </div>
            </div>  

            {/* Designation End */}


            {/* Password Start */}

            <div className={ "no-border" + (passwordFocus ? " input-group-focus" : "")}>
              <div className="sw_TextFieldGroup">
              <span className="sw_TextFieldIcon"><i class="now-ui-icons ui-1_lock-circle-open"></i></span>
                <TextField 
            
                name="password" 
                label="*Password..." 
                variant="outlined"
                onChange={(evt) => handleChange(evt)}
                type={passwordShown ? "text" : "password"}
                onFocus={() => setPasswordFocus(true)}
                onBlur={() => setPasswordFocus(false)}
                invalid={state.passwordValidate} 
                />
                <div className="sw_TextFieldRightIcon"> 
                <span className="password-eye"><i
                          onClick={togglePasswordVisiblity}
                          className="fa fa-eye"
                          aria-hidden="true"
                        ></i></span>
                </div>        
                {state.passwordValidate ? (
                    <span className="red">
                      *This is a required field
                    </span>
                  ) : null}
              </div>
            </div>  

            {/* Password End */}


            {/* Confirm Password Start */}

            <div className={ "no-border" + (confirmPasswordFocus ? " input-group-focus" : "")}>
              <div className="sw_TextFieldGroup">
              <span className="sw_TextFieldIcon"><i class="now-ui-icons ui-1_lock-circle-open"></i></span>
                <TextField 
                id="confirmPassword" 
                name="confirmPassword" 
                label="*Confirm Password..." 
                variant="outlined"
                onChange={(evt) => handleChange(evt)}
                type={confirmPasswordShown ? "text" : "password"}
                onFocus={() => setConfirmPasswordFocus(true)}
                onBlur={() => setConfirmPasswordFocus(false)}
                invalid={state.confirmPasswordValidate} 
                />
                <div className="sw_TextFieldRightIcon2"> 
                <span className="password-eye"><i
                          onClick={toggleConfirmPasswordVisiblity}
                          className="fa fa-eye"
                          aria-hidden="true"
                        ></i></span>
                </div>
                {state.confirmPasswordValidate ? (
                    <span className="red">
                      *This is a required field
                    </span>
                  ) : null}
              </div>
            </div>  

            {/* Confirm Password End */}




      {/* <div className={ "no-border" + (langCodeFocus ? " input-group-focus" : "")}>
        
       <div className="sw_TextFieldGroup">
        
        <span className="sw_TextFieldIcon"><i class="now-ui-icons objects_globe"></i></span>
        
        <TextField
          id="LangCode"
          name="LangCode"
          select
          label="Language"
          defaultValue={state.LangCode || "fr_FR"}  
          variant="outlined"
          onChange={(evt) => handleChange(evt)}
          onFocus={() => setLangCodeFocus(true)}
          onBlur={() => setLangCodeFocus(false)}
          invalid={state.langCodeValidate}      
        >
          {languageList.map((option) => (
            <MenuItem key={option.LangCode} value={option.LangCode}>
              {option.LangName}
            </MenuItem>
          ))}
        </TextField>

        {state.langCodeValidate ? (
          <FormHelperText className="red">
            *This is a required field
          </FormHelperText>
        ) : null}

        </div>
        
      </div> */}





            {/* Language Start */}

            <div className={ "no-border" + (langCodeFocus ? " input-group-focus" : "")}>
              <div className="sw_TextFieldGroup">
              <span className="sw_TextFieldIcon"><i class="now-ui-icons objects_globe"></i></span>
              <FormControl className={classes.fullWidth}>
              
                <TextField 
                id="LangCode" 
                select
                label="Language"
                name="LangCode"               
                variant="outlined"
                defaultValue={state.LangCode || "fr_FR"}
                onChange={(evt) => handleChange(evt)}
                onFocus={() => setLangCodeFocus(true)}
                onBlur={() => setLangCodeFocus(false)}
                invalid={state.langCodeValidate} 
                >
                    {languageList.map((item, index) => {
                      return (
                        <MenuItem key={`languageList-${index + 1}`} value={item.LangCode}>{item.LangName}</MenuItem>
                      );
                    })}
                </TextField>

                {state.langCodeValidate ? (
                    <FormHelperText className="red">
                      *This is a required field
                    </FormHelperText>
                  ) : null}

                </FormControl>    
              </div>
            </div>  

            {/* Language End */}




          </Box>

                <div className="text-center">
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={(e) => submit(e)}
                    size="lg"
                  >
                    Create Account
                  </Button>
                  
                    

                </div>

                   <div className="text-center-view">
                      <h6>
                        <a
                          className="link"
                          // href="/signup-page"
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/login");
                          }}
                        >
                          View Login Page
                        </a>
                      </h6>
                    </div>
              
              
          </div>
        </Grid>
        <Grid item xs={4} sm={4}></Grid>
      </Grid>
      </div>
    </>
  );
}

export default SignUp;


const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});
