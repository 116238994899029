import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  FormControl,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "react-query";
import * as api from "../actions/api";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {

  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProductsModal = ({ handleClose, open, addProduct, ...props }) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "receive";

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroupList = [All_Product].concat(Product_Group);

  const [activeProductGroupData, setAcProductGroupData] = useState({
    id: ProductGroupList.length > 0 ? ProductGroupList[0].id : "",
    name: ProductGroupList.length > 0 ? ProductGroupList[0].name : ""
  });


  const handleProductGroupChange = (event, newValue) => {
    setAcProductGroupData(newValue);

  };




  let tblRef = null;

  let params = {
    action: "getProducts",
    menukey: menukey,
    ItemGroupId: activeProductGroupData.id,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { data } = useQuery([params], 
    api.getProductsList,
    {    
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0 
      
      }

  );

  /*  const { isLoading, error, isError, data, refetch } = useQuery(
    "products",
    api.getProductsList
  ); */

  const [selectedProducts, setselectedProducts] = useState([]);

  const ProductColumns = [
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },
    { title: "Unit Name", field: "UnitName", visible: false },
    { title: "Generic Name", field: "GenericName", visible: false },
    { title: "GroupName", field: "GroupName", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 80,
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 140,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
    },
    //{ title: DispensingLanguage[lan][menukey]['Product Price'], field: "UnitPrice", width: 100, hozAlign: "right", headerHozAlign:"right"  },
  ];

  // let selected = [];
  const ProdustDataItems = data?.map((item, index) => {
    return item;
  });

  const [btnDisable, setbtnDisable] = useState(false);
  const SaveSelectedProducts = () => {
    if (tblRef.table.getSelectedData().length > 0) setbtnDisable(true);
    setselectedProducts(tblRef.table.getSelectedData());
    setTimeout(() => setbtnDisable(false), 500);
  };

  useEffect(() => {
    if (tblRef == null) return;
    addProduct(selectedProducts);
  }, [selectedProducts]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose("Products")}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("Products")}
        >
          {DispensingLanguage[lan][menukey]["Product Lists"]}
        </BootstrapDialogTitle>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroupList"
                      disableClearable
                      options={ProductGroupList}
                      value={activeProductGroupData}
                      onChange={(event, newValue) =>
                        handleProductGroupChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}

                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            DispensingLanguage[lan][menukey]["Product Group"]
                          }
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <DialogContent dividers>
          <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={{
              selectableRangeMode: "click",
              groupBy: ["GroupName"],
            }}
            selectable={true}
            columns={ProductColumns}
            data={ProdustDataItems}
            height="300px"
            layout={"fitColumns"}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            disabled={btnDisable}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
            {DispensingLanguage[lan][menukey]["ADD"]}
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose("Products")}
          >
            {DispensingLanguage[lan][menukey]["CANCEL"]}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProductsModal;

const useStyles = makeStyles({
  fullWidth: {
    width: "95%",
  },

});