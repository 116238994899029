import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import RepackingFormData from "./RepackingFormData";
import { useTranslation } from "react-i18next";
import moment from "moment";

const EditRepacking = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "repacking";
  const FacilityId = localStorage.getItem("FacilityId");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    TransactionDate: "",
    AdjTypeId: "",
    TransactionNo: "",
    FacilityId: FacilityId,
    TransactionId: "",
    IssueToFacility: "",
    PreparedBy: "",
    ApprovedBy: "",
    ProductRepackingFrom: "",
    ProductRepackingTo: "",
  });
  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
  );

  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);
  const [isPositive, setIsPositive] = useState(true);
  const [pickListMethod, setPickListMethod] = useState();

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [bFirst, setBFirst] = useState(true);

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  // const queryClient = useQueryClient();

  const { refetch } = useQuery(
    ["receive", id],
    () =>
      api.getRepackingInv(id).then((res) => {
        setTransactionId(id);
        setFormData(res.data);
        setbStockUpdate(res.data.bStockUpdated);
        setManyTableData(res.data.ManyJsonSave); //res.data.ManyJsonSave
        setUpdatedDetailGridData(res.data.ManyJsonSave);
        setGeneratedInvoiceNo(res.data.TransactionNo);
        let IsPositive = res.data.IsPositive == "-1" ? true : false;
        setIsPositive(IsPositive);
        setPickListMethod(res.data.PickListMethod);
        setBFirst(true);
        return res.data;
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  const { mutate: mutateUpdate } = useMutation(api.updateRepackingInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        if (data.bStockUpdated == 1) {
          mutatePost(TransactionId);
        } else {
          refetch();
          setDeletedDataSet([]);

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const { mutate: mutatePost } = useMutation(api.updateAdjStock, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setbStockUpdate(data.bStockUpdated);
        setDeletedDataSet([]);

        if (data.success == 0) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        } else {
          refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
            duration: 10000,
          });
        }
      }
    },
  });

  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };
  const handleChange = (e) => {
    if (e == "IssuedToFacility") {
      setErrorObject({ ...errorObject, ["IssueToFacility"]: null });
    } else {
      const { name, value } = e.target;

      let data = { ...formData };
      data[name] = value;

      setFormData(data);

      setErrorObject({ ...errorObject, [name]: null });
    }
  };

  //==== Start Choosen Dropdown========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
  };
  //==== End Choosen Dropdown========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "",
      FacilityId: FacilityId,
      TransactionId: "",
      PreparedBy: "",
      ApprovedBy: "",
      ProductRepackingFrom: "",
      ProductRepackingTo: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "PreparedBy",
      "PreparedBy",
      "ProductRepackingFrom",
      "ProductRepackingTo",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const deleteRepackingInv = (data) => {
    let newarray = ManyTableData.filter((element) => element != data);

    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if (data.TransactionItemId != null) {
      let newDeletedDataSet = [data.TransactionItemId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
    }
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        deletedDataSet: deletedDataSet,
      };
      mutateUpdate(fDtat);
    }
  };

  const handlePost = async (e) => {
    console.log("jasffff");
    if (validateForm(formData)) {
      // Start Post Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to post the stock?"
          ]
        ),
        icon: "warning",
        buttons: [
          t(DispensingLanguage[lan][menukey]["No"]),
          t(DispensingLanguage[lan][menukey]["Yes"]),
        ],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
          let fDtat = {
            ...formData,
            ManyJsonSave: ManyTableData,
            bStockUpdated: 1,
            deletedDataSet: deletedDataSet,
          };
          mutateUpdate(fDtat);
        } else {
          console.log("Cancel");
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (rows.datatype == "FormData") {
      setFormData(rows.IssueToFacility);
    } else {
      if (validateForm(formData)) {
        let fDtat = {
          ...formData,
          ManyJsonSave: rows.data,
          menukey: "repacking",
          deletedDataSet: deletedDataSet,
        };
        mutateUpdate(fDtat);
      }
      setManyTableData(rows.data);
    }
  };

  const updateIspositive = (isPosVal) => {
    setIsPositive(isPosVal);
  };
  useEffect(() => {}, [isPositive, pickListMethod, deletedDataSet]);

  return (
    <>
      <RepackingFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        handleAdd={handleAdd}
        bStockUpdate={bStockUpdate}
        setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        handlePost={handlePost}
        deleteRepackingInv={deleteRepackingInv}
        generatedInvoiceNo={generatedInvoiceNo}
        DisableAddBtn={false}
        isPositive={isPositive}
        updateIspositive={updateIspositive}
        pickListMethod={pickListMethod}
        bFirst={bFirst}
        {...props}
      />
    </>
  );
};

export default EditRepacking;
