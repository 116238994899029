import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { DeleteOutline, ViewList, Event } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useTranslation } from "react-i18next";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import ProductsModal from "../../../services/ProductsModal";
import ProductsLotModal from "../../../services/ProductsLotModal";
// import SourceModal from "../../../services/SourceModal";
import DatePickerModal from "../../../services/DatePickerModal";
import { cellFocusEditor, generateBatchId } from "../../../services/Common";
import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import { useMutation } from "react-query";
import * as api from "../../../actions/api";

import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";



let isDirty=false;
let masterDirty=false;


const DispenserFormData = (props) => {
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "adjustment";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  let ManufacturerList = JSON.parse(localStorage.getItem("ManufacturerList"));
  let ManufacturerListVal = {};
  let ManufacturerListText = {};
  for (let i = 0; i < ManufacturerList.length; i++) {
    ManufacturerListVal[ManufacturerList[i].ManufacturerName]=ManufacturerList[i].ManufacturerName;
    ManufacturerListText[ManufacturerList[i].ManufacturerId]=ManufacturerList[i].ManufacturerName;
     
  }


  const UsersListByFacility = JSON.parse(
    localStorage.getItem("UsersListByFacility")
  );

  const classes = useStyles();
  let ReceiveDetailsRef = null;
  let adjId = null;
  moment.locale(lan == "en_GB" ? "enGB" : "fr");


  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({menukey});
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({menukey});


  const [open, setOpen] = useState(false);
  const [openLot, setOpenLot] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const [bIsPositive, setbIsPositive] = useState();
  const [IssueToFacility, setIssueToFacility] = useState([]);
  const [IssueToFacilityVal, setIssueToFacilityVal] = useState(false);
  const [firstIssueToFacility, setfirstIssueToFacility] = useState("");

  const [bDisableAddProduct, setDisableAddProduct] = useState(
    props.DisableAddBtn
  );
  const AdjType = JSON.parse(localStorage.getItem("AdjType"));

  const [SoureOpen, setSoureOpen] = useState(false);
  const [DatePickerOpen, setDatePickerOpen] = useState(false);
  const [currRowId, setCurrRowId] = useState(0);
  const [currExpiryDate, setCurrExpiryDate] = useState("");

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroupList = [All_Product].concat(Product_Group);

  const [activeProductGroupData, setAcProductGroupData] = useState({
    id: ProductGroupList.length > 0 ? ProductGroupList[0].id : "",
    name: ProductGroupList.length > 0 ? ProductGroupList[0].name : "",
  });

  const handleProductGroupChange = (event, newValue) => {
    setAcProductGroupData(newValue);
    if (props.addProductForm == false) {
      props.filterFandleChange(newValue.id);
    }
  };

  /* ====Start of Print Excel Many Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_adjustment_invoice_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&TimeStamp=" +
        Date.now() +
        "&isPositive=" +
        props.isPositive
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_adjustment_invoice_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&TimeStamp=" +
        Date.now() +
        "&isPositive=" +
        props.isPositive
    );
  };

  /* ====End of Excel Export Code=== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        setOpenLot(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      case "LotOpen":
        setOpenLot(true);
        break;

      // case "SourceOpen":
      //   setSoureOpen(true);
      //   break;

      // case "SourceClose":
      //   setSoureOpen(false);
      //   break;
      case "DatePickerOpen":
        setDatePickerOpen(true);
        break;

      case "DatePickerClose":
        setDatePickerOpen(false);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {
    if (bIsPositive) {


      let rowsNotInGrid = [];

      NonOrderProductData.map((row, i) => {
        row.TransactionItemId = null;
        row.dirty = 1;
        row.Remarks = '';
        row.Quantity = '';
        row.IsPositive = -1;
        row.SKU =
          i + manyTableData.length + 1 + props.deleteAdjustmentInv.length;
      });

      if (props.ManyTableData.length > 0) {
        rowsNotInGrid = NonOrderProductData.filter((obj1) => {
          return !props.ManyTableData.some(
            (obj2) =>
              obj1.BatchNo == obj2.BatchNo &&
              obj1.ExpiryDate == obj2.ExpiryDate &&
              obj1.ItemCode == obj2.ItemCode
          );
        });
      } else {
        rowsNotInGrid = NonOrderProductData;
      }
      
      isDirty=true;
      onSetDisable();
      let arrObjOne = rowsNotInGrid.concat(props.ManyTableData);

      setManyTableData(arrObjOne);
      props.handleAdd(arrObjOne);
      handleClose("Products");



    } else {
      let rows = [];
      let autoId = 0;
      manyTableData.forEach((row, i) => {
        autoId++;
        let newRow = {};
        newRow.BatchId = row.BatchId;
        newRow.ItemNo = row.ItemNo;
        newRow.ItemCode = row.ItemCode;
        newRow.dirty = row.dirty;
        newRow.NonPerishable = row.NonPerishable;

        newRow.GroupName = row.GroupName;
        newRow.ItemName = row.ItemName;
        newRow.UnitPrice = row.UnitPrice;
        newRow.TransactionItemId = row.TransactionItemId;
        newRow.CurrentStockQty = row.CurrentStockQty;
        newRow.Quantity = row.Quantity;
        newRow.BatchNo = row.BatchNo;
        newRow.ExpiryDate = row.ExpiryDate;
        newRow.Remarks = row.Remarks;

        //  newRow.SourceId = row.SourceId;
        //  newRow.SourceName = row.SourceName;

        newRow.SKU = autoId;
        newRow.id = autoId;
        newRow.LineTotal = row.Quantity * row.UnitPrice;
        newRow.ManufacturerId = row.ManufacturerId;
        newRow.ManufacturerName = row.ManufacturerName;
        rows.push(newRow);
      });

      NonOrderProductData.forEach((row, i) => {
        autoId++;
        let newRow = {};
        newRow.BatchId =generateBatchId(row);
        newRow.ItemNo = row.ItemNo;
        newRow.dirty = 1;
        newRow.ItemCode = row.ItemCode;

        newRow.GroupName = row.GroupName;
        newRow.ItemName = row.ItemName;
        newRow.NonPerishable = row.NonPerishable;
        newRow.UnitPrice = row.UnitPrice;

        newRow.TransactionItemId = null;
        newRow.CurrentStockQty = 0;
        newRow.Quantity = '';
        newRow.BatchNo = null;
        newRow.ExpiryDate = null;
        newRow.Remarks = '';

        // newRow.SourceId = null;
        // newRow.SourceName = null;

        newRow.SKU = autoId;
        newRow.id = autoId;
        newRow.LineTotal = 0;
        newRow.ManufacturerId = null;
        newRow.ManufacturerName = null;
        rows.push(newRow);
      });

      isDirty=true;
      onSetDisable();

      setManyTableData(rows);
      props.handleAdd(rows);
      handleClose("Products");
    }
  };

  

  const addDatePicker = (datePickerData) => {
    if (!datePickerData) {
      return;
    }

    let selectedExpiryDate = moment(datePickerData).format("DD/MM/YYYY");
    console.log("selectedExpiryDate ======", selectedExpiryDate);
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU == currRowId) {
        newRow.ExpiryDate = selectedExpiryDate;
        newRow.dirty = 1;
        isDirty=true;
        onSetDisable();
      } else {
        newRow.ExpiryDate = row.ExpiryDate;
        newRow.dirty = row.dirty;
      }
      
      newRow.BatchId =generateBatchId(row);
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      // newRow.SourceId = row.SourceId;
      // newRow.SourceName = row.SourceName;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;
      newRow.ManufacturerId = row.ManufacturerId;
      newRow.ManufacturerName = row.ManufacturerName;

      newRow.Remarks =  row.Remarks;;

      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    //productAdd(rows);
    handleClose("DatePickerClose");
  };

  function ShowDatePickerPopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    //if ((props.bStockUpdate)) {
    if (props.bStockUpdate || props.isPositive) {
      return <></>;
    } else {
      return (
        <>
          <Event
            onClick={() => {
              
              setCurrRowId(rowData.SKU);
              setCurrExpiryDate(rowData.ExpiryDate);
              handleClose("DatePickerOpen");
            }}
          />
        </>
      );
    }
  }

  const ReceiveDetailsColumns = [
    {
      title: "BatchId",
      field: "BatchId",
      headerSort: false,
      headerHozAlign: "center",
      visible: false,
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    
    { title: "id", field: "SKU", visible: false },
    { title: "id", field: "TransactionItemId", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      //visible: bStockUpdate == 0 ?true:false,
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 130,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      //headerFilter: true,
      width: 180,
    },
    {
      title: DispensingLanguage[lan][menukey]["Current Stock"],
      field: "CurrentStockQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      visible: bIsPositive,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "Quantity",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: [
        "integer",
        "min:0",
        function (cell, value, parameters) {
          if (value == "") return true;
          if (!props.isPositive) return true;
          const balance = cell.getRow().getCell("CurrentStockQty").getValue();
          const validstatus = parseInt(value) <= parseInt(balance);
          return validstatus;
        },
      ],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();

        
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }
        
        
      },
    }, //
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 120,
      //cssClass: "tabluator-column-editable text-field-editable",
      cssClass: props.isPositive ? "": "tabluator-column-editable text-field-editable",
      editor:
        props.bStockUpdate == 0
          ? props.isPositive
            ? false
            : cellFocusEditor
          : "",
      cellEdited: function (cell) {
       const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 130,
      //cssClass: "tabluator-column-editable text-field-editable",
      cssClass:props.isPositive ? "": "tabluator-column-editable text-field-editable",
      //editor: props.bStockUpdate == 0 ? (props.isPositive?false: dateEditor)  : "",
      editor:
        props.bStockUpdate == 0
          ? props.isPositive
            ? false
            : cellFocusEditor
          : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }
      },
    },
    {
      title: "",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 8,
      formatter: reactFormatter(<ShowDatePickerPopup />),
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerId",
      visible: false,
      hozAlign: "left",
      headerHozAlign: "left",
      
      width: 190,
      headerSort: false,
       
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      hozAlign: "left",
      headerHozAlign: "left",
       
      
      cssClass:props.isPositive ? "": "tabluator-column-editable text-field-editable", 
      headerSort: false, 
      visible: props.bStockUpdate || bIsPositive ? false : true,



      editor: props.bStockUpdate == 0
      ? props.isPositive
        ? false
        : 'autocomplete'
      : "",

 
          
        editorParams:{ 
          values:ManufacturerListVal,
          showListOnEmpty:true, //show all values when the list is empty,
          freetext:false, //allow the user to set the value of the cell to a free text entry
          allowEmpty:true, //allow empty string values
         
          searchingPlaceholder:"Filtering ...", //set the search placeholder
         // emptyPlaceholder:"(no matching results found)", //set the empty list placeholder
          listItemFormatter:function(value, title){ 
              return  title; 
          },
          sortValuesList:"asc", //if creating a list of values from values:true then choose how it should be sorted
        defaultValue:"", //set the value that should be selected by default if the cells value is undefined
        elementAttributes:{
            maxlength:"30", //set the maximum character length of the input element to 10 characters
        },
       // mask:"AAA-999",
        verticalNavigation:"hybrid",

        searchFunc:function(term, allItems){ //search for exact matches

        if(term==null) return allItems;
        if(term=='') return allItems;
        console.log("searchFN");
                
        
          let matches={};
          
          for (const [key, value] of Object.entries(ManufacturerListVal)) {
            console.log(key.search(term));
             
            if(key.toLocaleLowerCase().search(term.toLocaleLowerCase())==-1){
             
             
            }else if(key.toLocaleLowerCase().search(term.toLocaleLowerCase()>=0)){
              matches[key]=value;
            }
            
            
            
          } 

          if(Object.keys(matches).length==0)
          matches[''] =DispensingLanguage[lan][menukey]["No Data Found"]; 
          
          return matches;
          },
        },
        cellEdited: function (cell) { 
          const curcellval = cell.getValue();
          const currow = cell.getRow();
          
          let CellOldValue = cell._cell.oldValue;
  
          if (
            CellOldValue == null ||
            CellOldValue == "" ||
            CellOldValue == undefined
          ) {
            CellOldValue = "";
          }
  
          if (CellOldValue != curcellval) {
            currow.getCell("dirty").setValue(1);
            for (const [key, value] of Object.entries(ManufacturerListText)) {
              if(`${value}`==cell.getValue()){
                currow.getCell("ManufacturerId").setValue(`${key}`);                
              } 
            }

            if(curcellval=='')
              currow.getCell("ManufacturerId").setValue('');   
            
            isDirty = true;
            onSetDisable();
          }
        },
      },
    // {
    //   title: DispensingLanguage[lan][menukey]["Manufacturer"],
    //   field: "ManufacturerId",
    //   hozAlign: "left",
    //   headerHozAlign: "left",
    //   cssClass:props.isPositive ? "": "tabluator-column-editable text-field-editable",
    //   //width: 200,
    //   headerSort: false,
    //   // visible: (props.bStockUpdate ?false:true || bIsPositive?true:false ) ,
    //   visible: props.bStockUpdate || bIsPositive ? false : true,
    //   formatter: reactFormatter(
    //     <RowInputData
    //       labelName="Manufacturer"
    //       field="ManufacturerId"
    //       fieldType="drop-down-manufacturer"
    //       dropDownKey="manufacturer"
    //     />
    //   ),
    // },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      hozAlign: "left",
      headerHozAlign: "left",
      //width: 200,
      headerSort: false,
      visible: props.bStockUpdate || bIsPositive ? true : false,
    },
    
    
    {
      title: DispensingLanguage[lan][menukey]["Comment"],
      field: "Remarks",
      //width: 110,
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",

      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue();
        const rowData = cell.getRow().getData();

        if (cellValue == null || cellValue == "") {
          // cell.getRow().getElement().style.backgroundColor = "#ec7063";
        } else {
          cell.getRow().getElement().style.backgroundColor = "transparent";
        }

        

        return cellValue;
      },
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }
      },
    },
  ];

  
  
  const onSetDisable = () => {  
    if(isDirty==true){   
      //document.getElementById("is_dirty_message").classList.remove("dnone"); 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }
    else{  
     //document.getElementById("is_dirty_message").classList.add("dnone"); 
     document.getElementById("master_dirty_message").classList.add("dnone");
    }    
  }


  const onMasterSetDirty = () => {   
    if(masterDirty==true){ 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  }

  const [selectedManufacturers, setSelectedManufacturers] = useState({});

  const handleManufacturerChange = (event, newValue, rowData) => {
    setSelectedManufacturers((prevState) => ({
      ...prevState,
      [rowData.SKU]: newValue,
    }));

    setcombochangevalue(newValue, rowData.SKU);
  };

  function RowInputData(props: any, type = null) {
    console.log(props.cell._cell.row.data);
    const rowData = props.cell._cell.row.data;
    let fieldType = props.fieldType;
    switch (fieldType) {
      case "drop-down-manufacturer":
        return (
          <FormControl className={classes.fullWidth}>
            <Autocomplete
              autoHighlight
              id={`ManufacturerId_${rowData.SKU}`}
              options={ManufacturerList}
              value={selectedManufacturers[rowData.SKU] || ""}
              onChange={(event, newValue) =>
                handleManufacturerChange(event, newValue, rowData)
              }
              getOptionLabel={(option) => option.ManufacturerName || ""}
              renderOption={(option) => (
                <Typography className="sw_fontSize">
                  {option.ManufacturerName}
                </Typography>
              )}
              // renderInput={(params) => <TextField {...params} variant="standard" />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>
        );

      default:
        return null;
    }
  }

  useEffect(() => {
    // Set initial values when the page is edited
    const initialValues = {};
    manyTableData.forEach((rowData) => {
      initialValues[rowData.SKU] = {
        ManufacturerId: rowData.ManufacturerId || "",
        ManufacturerName: rowData.ManufacturerName || "",
      };
    });
    setSelectedManufacturers(initialValues);
  }, [manyTableData]);

  const setcombochangevalue = (newValue, skusl) => {
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.BatchId =generateBatchId(row);;
      if (row.SKU == skusl) {
        if (newValue == null || newValue == "") {
          newRow.ManufacturerId = null;
          newRow.ManufacturerName = null;
        } else {
          newRow.ManufacturerId = newValue.ManufacturerId;
          newRow.ManufacturerName = newValue.ManufacturerName;
        }

        newRow.dirty = 1;
        isDirty=true;
        onSetDisable();

      } else {
        newRow.ManufacturerId = row.ManufacturerId;
        newRow.ManufacturerName = row.ManufacturerName;
        newRow.dirty = row.dirty;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      newRow.Remarks = row.Remarks;

      rows.push(newRow);
    });

    setManyTableData(rows);
  };

  const isContainDuplicate = (dataArray) => {
    const mappedData = dataArray.map((el, i) => ({
      ...el,
      BatchId: generateBatchId(el),
    }));
    const removedDuplicate = new Set(mappedData.map((el) => el.BatchId));

    if (mappedData.length !== removedDuplicate.size) {
      return { data: "", status: true };
    } else {
      setManyTableData(mappedData);
      return { data: mappedData, status: false };
    }
  };



  const isContainDuplicateForExistingData = (dataArray) => {

    
    const removedDuplicate = new Set(dataArray.map((el) => el.BatchId));

    if (dataArray.length !== removedDuplicate.size) {
      return { data: '', status: true };
    } else {
      setManyTableData(dataArray);
      return { data: dataArray, status: false };
    };


  };

  /*
  const onPostBtnClick = () => {


    const isDuplicate = isContainDuplicate(ReceiveDetailsRef.table.getData());
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"],//+" - "+isDuplicate.data,
        msgtype: 0,
      });
       

    }else{

      setbStockUpdate(true);
      props.handlePost({
        datatype: "TableData",
        data: isDuplicate.data,
      });
    }
  };

  const onSaveUpdateBtnClick = () => {
    let manydata = ReceiveDetailsRef.table.getData();

    const isDuplicate = isContainDuplicate(manydata);
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+" - "+isDuplicate.data,
        msgtype: 0,
      });
    } else {
      props.setUpdatedDetailsGridDataHandler({
        datatype: "TableData",
        data: isDuplicate.data,
      });
    }
  };

  */

  const onPostBtnClick = () => {
    
   
    /*
    //const isDuplicate = isContainDuplicate(ReceiveDetailsRef.table.getData());

    let manydata = ReceiveDetailsRef.table.getData();

    const isDuplicate = props.isPositive ? isContainDuplicateForExistingData(manydata) : isContainDuplicate(manydata);
    //console.log("d key",isDuplicate);
   
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"],//+" - "+isDuplicate.data,
        msgtype: 0,
      });
       

    }else{

      setbStockUpdate(true);
      handlePost({
        datatype: "TableData",
        data: isDuplicate.data,
      });

    }

    */
  

    handlePost();

  };

  /*
  const handlePost1111 = async (e) => {

    
    let manydata = ReceiveDetailsRef.table.getData();
    const isDuplicate = props.isPositive ? isContainDuplicateForExistingData(manydata) : isContainDuplicate(manydata);

    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+ " - " + isDuplicate.data,
        msgtype: 0,
      });


    } else {




    let validForm = props.checkValidateForm(isDuplicate.data, "post");
    
    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        
        
      }).then((willAction) => {
        if (willAction) {
          let fDtat = { ...validForm, "bStockUpdated": 1 };
          //console.log('fDtat: ', fDtat);
          mutateUpdate(fDtat);

        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          // let route = `${TransactionId}`;
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert


    }

    }

  };

  */



  const handlePost = async (e) => {


    

    ///===================Check Non Non Perishable  of any item==========/////////////
    
    let CheckPerishable=1;

    if((props.formData.AdjTypeId==1)||(props.formData.AdjTypeId==4)||(props.formData.AdjTypeId==9)
      ||(props.formData.AdjTypeId==10)||(props.formData.AdjTypeId==26)){

      // if(row.NonPerishable==0){

        let NonPerishableInfo = {};
        ReceiveDetailsRef.table.getData().forEach((row, i) => {
          if(row.NonPerishable==0){
            if (row.ExpiryDate === null || row.ExpiryDate === "" || row.ExpiryDate === undefined) {
              NonPerishableInfo = row;

              props.openNoticeModal({
                isOpen: true,
                msg: t(DispensingLanguage[lan][menukey]["Perishable item - blank expiry date is not allowed"]),// + ' - ' + NonPerishableInfo.ItemName,
                msgtype: 0,
              });

              CheckPerishable=0;
              return;

            }
          }
        });

        //if (Object.keys(NonPerishableInfo).length > 0) {
          
        //}

      //}

  }
  
    ///===================Check Perishable  of any item==========/////////////


    ///===================Check qty is blank of any item==========/////////////

    if(CheckPerishable==1){

    if (ReceiveDetailsRef == null) return;

    let blankinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (row.Quantity === null || row.Quantity === "" || row.Quantity === 0) {
        blankinfo = row;
      }
    });

    if (Object.keys(blankinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["Blank quantity is not allowed"]) + ' - ' + blankinfo.ItemName,
        msgtype: 0,
      });
      return;
    }
    ///===================Check qty is blank of any item==========/////////////

    

    let manydata = ReceiveDetailsRef.table.getData();
    const isDuplicate = props.isPositive ? isContainDuplicateForExistingData(manydata) : isContainDuplicate(manydata);

    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+ " - " + isDuplicate.data,
        msgtype: 0,
      });


    } else {


      let manydataP=[]; 
      isDuplicate.data.forEach((row,i)=>{
        if(row.dirty==1)
        manydataP.push(row);
      })


    let validForm = props.checkValidateForm(manydataP, "post");
    
    if (validForm) {

      let fDtat = { ...validForm, "bStockUpdated": 1, "masterDirty":masterDirty };
      modalPopupDelete(mutateUpdate, fDtat, {PostQ:"modalQPost"}, ModalNo,validForm); 

      }

   }

  }

  };

  const ModalNo=(validForm)=>{
       
       props.stockPosted(0);
       let route = `${validForm.TransactionId}`;
       props.history.push(route);

  }

  
  

  const onSaveUpdateBtnClick = () => {


    ///===================Check Non Non Perishable  of any item==========/////////////
    let CheckPerishable=1;

    if((props.formData.AdjTypeId==1)||(props.formData.AdjTypeId==4)||(props.formData.AdjTypeId==9)
      ||(props.formData.AdjTypeId==10)||(props.formData.AdjTypeId==26)){

      // if(row.NonPerishable==0){

        let NonPerishableInfo = {};
        ReceiveDetailsRef.table.getData().forEach((row, i) => {
          if(row.NonPerishable==0){
            if (row.ExpiryDate === null || row.ExpiryDate === "" || row.ExpiryDate === undefined) {
              NonPerishableInfo = row;

              props.openNoticeModal({
                isOpen: true,
                msg: t(DispensingLanguage[lan][menukey]["Perishable item - blank expiry date is not allowed"]),// + ' - ' + NonPerishableInfo.ItemName,
                msgtype: 0,
              });

              CheckPerishable=0;
              return;

            }
          }
        });

        //if (Object.keys(NonPerishableInfo).length > 0) {
          
        //}

      //}

  }
  ///===================Check Perishable  of any item==========/////////////

    if(CheckPerishable==1){

    let manydata = ReceiveDetailsRef.table.getData();

    //alert(props.isPositive);

    
    const isDuplicate = props.isPositive ? isContainDuplicateForExistingData(manydata) : isContainDuplicate(manydata);
    


    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+" - "+isDuplicate.data,
        msgtype: 0,
      });
    } else {
      setUpdatedDetailsGridDataHandler({
        datatype: "TableData",
        data: isDuplicate.data,
      });
    }

    }

    
  };


  /*
  const onSaveUpdateBtnClick = () => {

    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    
  }

  */


  const setUpdatedDetailsGridDataHandler = (rows) => {
   
    if (rows.datatype == "FormData") {
      props.setFormDataForAdjust(rows.IssueToFacility);
    } else {



      let manydataR=[]; 
      rows.data.forEach((row,i)=>{
        if(row.dirty==1)
        manydataR.push(row);
      
      })
      
      let validForm = props.checkValidateForm(manydataR,'edit');
      if (validForm) {
        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutateSave({...validForm,'masterDirty':masterDirty});
        }else{
          //when update
          mutateUpdate({...validForm,'masterDirty':masterDirty}); 
        }
        
      }

    }


  };


  const { isLoading: isLoadingSave, mutate:mutateSave } = useMutation(api.saveAdjustment, {
    onSuccess: (data) => {
      if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `edit/${data.TransactionId}`;
        props.history.push(route);

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateAdjustmentInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) {
        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;
        //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        if (data.bStockUpdated == 1) {
          mutatePost(props.formData.TransactionId);
        }
        else {
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(api.updateAdjStock, {
    onSuccess: (data) => {
      if (data.status == 200) {

        //console.log("Handle , Post", );

        console.log("Handle , Post ...........");
        // setDeletedDataSet([]);

        console.log("Handle , Success..............",data.success);

        if (data.success == 0) {
          //setbStockUpdate(0);
          props.stockPosted(0);
          props.refetchInvoice();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        } else {
          // setbStockUpdate(1);
          props.stockPosted(1);
          props.refetchInvoice();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }

      } else {
        // setbStockUpdate(0);
        props.stockPosted(0);
        props.refetchInvoice();
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  const deleteAdjustmentInv = (data) => {


    let newarray = manyTableData.filter((element) => element != data);
    setManyTableData(newarray);
    props.deleteAdjustmentInv(data);
    isDirty = true;
    onSetDisable();

 
    
  };

  
  const { mutate } = useMutation(api.getWarehouseList, {
    onSuccess: (data) => {
      if (data.status == 200) {
        let firstIssueToFacility =
          data.data.datalist.length == 1 ? data.data.datalist[0].id : "";
        setfirstIssueToFacility(firstIssueToFacility);
        props.formData.IssueToFacility = firstIssueToFacility;

        setUpdatedDetailsGridDataHandler({
          datatype: "FormData",
          IssueToFacility: props.formData,
        });

        setIssueToFacility(data.data.datalist);
      }
    },
  });

  

  const comboChangeEvnt = (e) => {
    props.formData.AdjTypeId = e.target.value;
    AdjType.map((row) => {
      if (row.id == e.target.value) {
        let params = {
          action: "getWarehouseFacility",
          menukey: menukey,
          FacilityId: FacilityId,
          PickListMethod: row.PickListMethod,
        };
        mutate(params);

        if (row.IsPositive == 1) {
          setbIsPositive(false);
          props.updateIspositive(false);
          //setDisableAddProduct(false);
        } else if (row.IsPositive == -1) {
          props.updateIspositive(true);
          //setDisableAddProduct(false);
          setbIsPositive(true);
        } else {
          props.updateIspositive(true);
          setbIsPositive(true);
          //setDisableAddProduct(true);
        }

        return;
      }
    });
    props.handleChange(e);
    masterDirty=true;
    onMasterSetDirty();
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    if (bStockUpdate == 0)
      return (
        <>
          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteAdjustmentInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  let bfalse = true;
  useEffect(() => {
    if (ReceiveDetailsRef == null) return;

    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate == 1 ? true : false);

    setbIsPositive(props.isPositive);
    if (!props.addProductForm)
      if (props.pickListMethod != undefined) {
        //setswipeIssueToFacility
        bfalse = false;
        setIssueToFacilityVal(props.formData.IssueToFacility);
        let params = {
          action: "getWarehouseFacility",
          menukey: menukey,
          FacilityId: FacilityId,
          PickListMethod: props.pickListMethod,
        };
        mutate(params);
      }
  }, [
    props.ManyTableData,
    props.bStockUpdate,
    props.isPositive,
    props.pickListMethod,
  ]);

  /* Start of New Code For To From*/

  const [chosenValues, setChosenValues] = useState({
    IssueToFacility: { id: "", name: "" },
  });

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;

    setChosenValues(chosenValuesData);

    props.handleChangeChoosenProps(name, value);
    masterDirty=true;
    onMasterSetDirty();
  };

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    if (props.addProductForm) {
      if (IssueToFacility.length == 1) {
        chosenValuesData["IssueToFacility"] =
          IssueToFacility[
            IssueToFacility.findIndex(
              (IssueTo_Facility) =>
                IssueTo_Facility.id == props.formData.IssueToFacility
            )
          ];
      }
    } else {
      chosenValuesData["IssueToFacility"] =
        IssueToFacility[
          IssueToFacility.findIndex(
            (IssueTo_Facility) =>
              IssueTo_Facility.id == props.formData.IssueToFacility
          )
        ];
    }

    setChosenValues(chosenValuesData);

    bfalse = true;
  }, [IssueToFacility]);

  /* End of New Code For To From*/

  const handleReset = () => {
    setManyTableData([]);
    props.handleReset();
  };

  const backToList = () => {

    if((masterDirty==true)||(isDirty==true)){
      
      modalPopupBacktoList(backToListCallBack, "/adjustment", {PostQ:"modalBackToListQ"}); 
  
    }else props.history.push("/adjustment")
    
  };

  const backToListCallBack = (PageUrl) => {
      masterDirty=false;
      isDirty=false;
      props.history.push(PageUrl);
  }

  const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

     masterDirty=true;
     onMasterSetDirty();

   };


  useEffect(() => {
    if (props.addProductForm) {
      if (props.formData.PreparedBy == "") {
        props.formData.PreparedBy = UserId;
      }
    }
  }, [props.formData.PreparedBy, UserId, props.addProductForm]);

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["ADJUSTMENT - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">

                    <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                      {/* <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                        // onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button> */}
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => backToList()}
                        
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                    <Button
                      disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick} //() => props.handleUpdate()
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>
                    <Button
                      //disabled={props.bStockUpdate}
                      disabled={props.bStockUpdate || !(manyTableData.length > 0) || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => backToList()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Adj. Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        // showTodayButton={true}
                        value={
                          props.formData.TransactionDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        // onChange={(e) =>
                        //   props.handleRDateChange(e, "TransactionDate")
                        // }
                        onChange={(e) =>formOnchange(e,'handleRDateChange','TransactionDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Adj. Invoice No"]
                      }
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="AdjTypeId-label">
                        {DispensingLanguage[lan][menukey]["Adj. Type"]}
                        <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        disabled={
                          props.bStockUpdate
                            ? props.bStockUpdate
                            : manyTableData.length == 0
                            ? false
                            : true
                        }
                        ref={(AdjTypeId) => (adjId = AdjTypeId)}
                        error={props.errorObject.AdjTypeId}
                        labelId="AdjTypeId-label"
                        id="AdjTypeId"
                        name="AdjTypeId"
                        ispositive=""
                        value={props.formData.AdjTypeId}
                        fullWidth
                        onChange={(e) => comboChangeEvnt(e)}
                      >
                        {AdjType.map((item, index) => {
                          return (
                            <MenuItem
                              key={`AdjType-${index + 1}`}
                              value={item.id}
                              ispositive={item.IsPositive}
                            >
                              {item.AdjType}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.AdjTypeId}>
                        {props.errorObject.AdjTypeId}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        disableClearable
                        disabled={
                          props.bStockUpdate
                            ? props.bStockUpdate
                            : manyTableData.length == 0
                            ? false
                            : true
                        }
                        id="IssueToFacility"
                        options={IssueToFacility}
                        
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "IssueToFacility",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValues["IssueToFacility"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.IssueToFacility}
                            label={DispensingLanguage[lan][menukey]["To_From"]}
                            variant="standard"
                            error={props.errorObject.IssueToFacility}
                            helperText={props.errorObject.IssueToFacility}
                            required
                            id="IssueToFacility"
                            name="IssueToFacility"
                            fullWidth
                          />
                        )}
                      />

                       
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>
                        formOnchange(e, "handleChange", "Remarks")
                      }
                   disabled={props.bStockUpdate}
                    />
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Prepared By"]}{" "}
                        <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.PreparedBy}
                        // helperText={props.errorObject.OrderBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                       // onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','PreparedBy')}
                        disabled={props.bStockUpdate}
                        //disabled={bCompleted==1}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UsersListByFacility-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Approved By"]}
                        <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.ApprovedBy}
                        // helperText={props.errorObject.ApprovedBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="ApprovedBy"
                        name="ApprovedBy"
                        value={props.formData.ApprovedBy}
                        fullWidth
                        onChange={(e) =>formOnchange(e,'handleChange','ApprovedBy')}
                       // onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                        //disabled={bCompleted==1}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UsersListByFacility-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.ApprovedBy}>
                        {props.errorObject.ApprovedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid> */}
                </Grid>
              </Grid>

              <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  {/* new row */}
                  <Grid item xs={12} sm={12}>
                    <Card className="sw_card">
                      <CardContent>
                        <Grid container className="mb-2">
                          {/* <Grid item xs={3} sm={3}>
                            <FormControl className={classes.fullWidth}>
                              <Autocomplete
                                autoHighlight
                                className="sw_chosen_filter"
                                id="ProductGroupList"
                                disableClearable
                                options={ProductGroupList}
                                value={activeProductGroupData}
                                onChange={(event, newValue) =>
                                  handleProductGroupChange(event, newValue)
                                }
                                disabled={
                                  props.addProductForm == true ? true : false
                                }
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(option) => (
                                  <Typography className="sw_fontSize">
                                    {option.name}
                                  </Typography>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={
                                      DispensingLanguage[lan][menukey][
                                      "Product Group"
                                      ]
                                    }
                                    variant="standard"
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid> */}
                          <Grid item xs={12} sm={12}>
                            <div className="float-right sw_btn_control">
                              <Button
                                disabled={
                                  props.bStockUpdate
                                    ? props.bStockUpdate
                                    : bDisableAddProduct
                                }
                                className="mr-2"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  props.isPositive
                                    ? handleClose("LotOpen")
                                    : handleClose("ProductsOpen")
                                }
                              >
                                {
                                  DispensingLanguage[lan][menukey][
                                    "SELECT PRODUCT"
                                  ]
                                }
                              </Button>
                            </div>

                            <ProductsModal
                              handleClose={handleClose}
                              open={open}
                              addProduct={addProduct}
                              {...props}
                            />

                            <ProductsLotModal
                              handleClose={handleClose}
                              open={openLot}
                              addProduct={addProduct}
                              FacilityId={FacilityId}
                              {...props}
                            />

                            {/* <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          /> */}
                            <DatePickerModal
                              handleClose={handleClose}
                              open={DatePickerOpen}
                              addDatePicker={addDatePicker}
                              reload={0}
                              ExpiryDate={currExpiryDate}
                              {...props}
                            />
                          </Grid>
                        </Grid>

                        <ReactTabulator
                          ref={(r) => (ReceiveDetailsRef = r)}
                          columns={ReceiveDetailsColumns}
                          data={manyTableData.sort((a, b) => {
                            const groupComparison = a.GroupName.localeCompare(
                              b.GroupName
                            );
                            return groupComparison !== 0
                              ? groupComparison
                              : a.ItemName.localeCompare(b.ItemName);
                          })}
                          height="430px"
                          layout={"fitColumns"}
                          options={{
                            groupBy: "GroupName",
                            //columnCalcs: "both",
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* new row */}
                </Grid>
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
