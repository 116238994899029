import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
import { useMutation } from "react-query";
// material components
import {
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
// import Notification from "../../../services/Notification";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const LossSummaryReport = (props) => {
  let TempZoneList = useState([]);
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "loss-summary-report";
  const [isLoadingUi, setLoading] = useState(true);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

 //region combo list
 const [RegionList, setRegionListData] = useState([]);
 const [activeRegionData, setAcRegionData] = useState({
   id: RegionList.length > 0 ? RegionList[0].id : 0,
   name: RegionList.length > 0 ? RegionList[0].name : "",
 });

 React.useEffect(() => {
   if (activeRegionData.id == 0) {
     getRegionData();
   }
 }, [activeRegionData.id]);

 const handleDepartmentChange = (event, newValue) => {
   setAcRegionData(newValue);
   getCommuneData(newValue.id);
 };

 const getRegionData = () => {
   let FacilitylevelParam = {
     action: "getDepartmentforreport",
     RoleId: 1,
     UserId: UserId,
     IsAll: 1,
     menukey: menukey,
     lan: lan,
   };
   cRegionList(FacilitylevelParam);
 };

 const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
   onSuccess: (data) => {
     if (data.status == 200) {
       setRegionListData(data.data.datalist);

       setAcRegionData({
         id: data.data.datalist.length ? data.data.datalist[0].id : 0,
         name: data.data.datalist.length ? data.data.datalist[0].name : "",
       });
       getCommuneData(data.data.datalist[0].id);
     }
   },
 });

 ////distict combo list
 const [Communelist, setCommunelistData] = useState([]);
 const [activeCommuneData, setAcCommuneData] = useState({
   id: -1,
   name: Communelist.length > 0 ? Communelist[0].name : "",
 });

 const getCommuneData = (RegionId) => {
   let FacilitylevelParam = {
     action: "getcommuneforreport",
     RoleId: 1,
     UserId: UserId,
     RegionId: RegionId,
     IsAll: 1,
     menukey: menukey,
     lan: lan,
   };
   cgetCommuneList(FacilitylevelParam);
 };

 const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
   onSuccess: (data, params) => {
     if (data.status == 200) {
       setCommunelistData(data.data.datalist);

       setAcCommuneData({
         id: data.data.datalist.length ? data.data.datalist[0].id : 0,
         name: data.data.datalist.length ? data.data.datalist[0].name : "",
       });

       refreshAllData(
        params.RegionId,
        data.data.datalist.length ? data.data.datalist[0].id : -1,
        currProductGroupId,
        acValue.id,
        selectedStartDate,
        selectedEndDate
      );

      //  getFacilityData(
      //    params.RegionId,
      //    data.data.datalist.length ? data.data.datalist[0].id : 0
      //  );
     }
   },
 });

 // Commune Change
 const handleCommuneChange = (event, newValue) => {
   setAcCommuneData(newValue);
   refreshAllData(
    activeRegionData.id,
    newValue.id,
    currProductGroupId,
    acValue.id,
    selectedStartDate,
    selectedEndDate
  );

   //getFacilityData(activeRegionData.id, newValue.id);
 };

 //facility combo load

//  const [Facility_list, setFacilityData] = useState([]);
//  const [activeFacilityData, setAcFacilityData] = useState({
//    id: -1,
//    name: Facility_list.length > 0 ? Facility_list[0].name : "",
//  });

//  const getFacilityData = (regid, communeid) => {
//    let FacilityParam = {
//      action: "FacilitylistforReport",
//      RegionId: regid,
//      DistrictId: communeid,
//      RoleId: RoleId,
//      UserId: UserId,
//      IsAll: 1,
//      menukey: menukey,
//      lan: lan,
//    };
//    cFacilityList(FacilityParam);
//  };

//  const { mutate: cFacilityList } = useMutation(api.getFacility, {
//    onSuccess: (data, params) => {
//      if (data.status == 200) {
//        setFacilityData(data.data.datalist);

//        setAcFacilityData({
//          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
//          name: data.data.datalist.length ? data.data.datalist[0].name : "",
//        });

//        refreshAllData(
//          params.RegionId,
//          params.DistrictId,
//          data.data.datalist.length ? data.data.datalist[0].id : 0,
//          currProductGroupId,
//          acValue.id,
//          selectedStartDate,
//          selectedEndDate
//        );
//      }
//    },
//  });

//  const handleFacilityListChange = (event, newValue) => {
//    refreshAllData(
//      activeRegionData.id,
//      activeCommuneData.id,
//      newValue.id,
//      currProductGroupId,
//      acValue.id,
//      selectedStartDate,
//      selectedEndDate
//    );
//    setAcFacilityData(newValue);
//  };

 const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
 let All_Product_Group = {
   id: 0,
   name: t(DispensingLanguage[lan][menukey]["All"]),
 };
 const ProductGroup = [All_Product_Group].concat(Product_Group);

 const [tmpProductlist, setProductlist] = useState([]);
 let All_Product = {
   id: 0,
   name: t(DispensingLanguage[lan][menukey]["All"]),
 };
 const Productlist = [All_Product].concat(tmpProductlist);

 const [currProductGroupId, setProductGroupId] = useState(0);
 const [ProductGroupName, setProductGroupName] = useState(
   t(DispensingLanguage[lan][menukey]["All"])
 );

 const [acValue, setACValue] = useState({
   id: 0,
   name: Productlist.length > 0 ? Productlist[0].name : "",
 });

 useEffect(() => {
   if (currProductGroupId == 0) {
     let ProductlistParam1 = {
       action: "getProductItem",
       ItemGroupId: 0,
       chechkvalue:-1,
       menukey: "",
     };
     cProductList(ProductlistParam1);
   }
 }, [currProductGroupId]);

 // Product Group Change
 const handleProductGroupChange = (event, newValue) => {
   let rowId = "";
   let rowName = "";
   if (newValue == null) {
     rowId = "";
     rowName = "";
   } else {
     rowId = newValue.id;
     rowName = newValue.name;
   }
   if (rowId !== currProductGroupId) {
     setProductGroupId(rowId);
     setProductGroupName(rowName);
   }

   let ProductlistParam = {
     action: "getProductItem",
     ItemGroupId: newValue.id,
     menukey: "",
   };
   cProductList(ProductlistParam);
 };

 const { mutate: cProductList } = useMutation(api.getProductListData, {
   onSuccess: (data, ProductlistParam) => {
     if (data.status == 200) {
       // console.log("Dta list", data.data.datalist);
       setProductlist(data.data.datalist);
       setACValue({
         id: 0,
         name: t(DispensingLanguage[lan][menukey]["All"]),
       });
       //alert(ProductlistParam.ItemGroupId);
       if (ProductlistParam.chechkvalue != -1) {
         refreshAllData(
           activeRegionData.id,
           activeCommuneData.id,
           ProductlistParam.ItemGroupId,
           0,
           selectedStartDate,
           selectedEndDate
         );
       }
     }
   },
 });

 // product Change
 const handleProductChange = (event, newValue) => {
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     currProductGroupId,
     newValue.id,
     selectedStartDate,
     selectedEndDate
   );
   setACValue(newValue);
 };

 const [selectedStartDate, setSelectedStartDate] = useState(
   moment().subtract(30, "days").format("YYYY-MM-DD")
 );
 //console.log("Selected Date",selectedStartDate);

 const [selectedEndDate, setSelectedEndDate] = useState(
   moment().format("YYYY-MM-DD")
 );

 const handleStartDateChange = (date) => {
   setSelectedStartDate(date);
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     currProductGroupId,
     acValue.id,
     date,
     selectedEndDate
   );
 };

 const handleEndDateChange = (date) => {
   setSelectedEndDate(date);
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     currProductGroupId,
     acValue.id,
     selectedStartDate,
     date
   );
 };


 const [dataItems, setDataItems] = useState([]);

 const refreshAllData = (
   RegionId,
   DistrictId,
   ItemGroupId,
   ProductId,
   StartDate,
   EndDate
 ) => {
   let params1 = {
     menukey: menukey,
     RegionId: RegionId,
     DistrictId: DistrictId,
     StartDate: StartDate,
     EndDate: EndDate,
     ProductGroupId: ItemGroupId,
     ProductId: ProductId,
     lan: lan
   };
   getLossSummaryTable({ queryKey: [{ ...params1, action: "getLossSummaryReportList" }] });
 };

 const { mutate: getLossSummaryTable } = useMutation(api.getLossSummaryReport, {
   onSuccess: (data) => {
    setDataItems(data);
    setLoading(false);
   },
 });


  // let params = {
  //   menukey: menukey,
  //   action: "getLossSummaryReportList",
  //   RegionId: activeRegionData.id,
  //   DistrictId: activeCommuneData.id,
  //  // FacilityId: activeFacilityData.id,
  //   StartDate: selectedStartDate,
  //   EndDate: selectedEndDate,
  //   ProductGroupId: currProductGroupId,
  //   ProductId: currProductId,
  //   lan: lan,
  //   page_limit: JSON.stringify(100),
  //   page_number: JSON.stringify(0 + 1),
  //   search_key: "",
  //   // mode: bEsigl
  // };

  // const { isLoading, error, isError, data, refetch } = useQuery(
  //   [params],
  //   api.getLossSummaryReport,
  //   {
  //     onSuccess: (data) => {
  //       setDataItems(data);
  //       setLoading(false);
  //       //setFacilityCount(data.length);
  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0, //1000 * 60 * 60 * 24,
  //   }
  // );

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 60,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product"],
      field: "ItemName",
      headerSort: true,
      headerFilter: true,
      //width: 200,
    },

    {
      title: DispensingLanguage[lan][menukey]["Loss Quantity"],
      field: "LossQty",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
    
      width: 150,
    },

   
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    window.open(
      finalUrl +
        "?action=getLossSummaryReport" +
        "&reportType=" +
        reportType +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&Districtid=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&ProductGroupId=" +
        currProductGroupId +
        "&ProductGroupName=" +
        ProductGroupName +
        "&ProductId=" +
        acValue.id +
        "&ProductName=" +
        acValue.name +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="section signup-top-padding sw_inner_container">
        <div className="sw_inner_card">
          <div className="d-flex justify-uiLanguage">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Loss Summary Report"]}
                </div>
              </div>
            </Grid>
          </div>
          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={3} sm={2}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            className={classes.fullWidth}
                            //disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label={
                              DispensingLanguage[lan][menukey]["Start date"]
                            }
                            value={selectedStartDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            className={classes.fullWidth}
                            //disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label={DispensingLanguage[lan][menukey]["End date"]}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                    <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroup"
                          disableClearable
                          options={ProductGroup}
                          onChange={(event, newValue) =>
                            handleProductGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            ProductGroup[
                              ProductGroup.findIndex(
                                (ProductGroup) =>
                                  ProductGroup.id == currProductGroupId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Product Group"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                    <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Productlist"
                          disableClearable
                          options={Productlist}
                          value={acValue}
                          onChange={(event, newValue) =>
                            handleProductChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          // defaultValue={Productlist[Productlist.findIndex(Productlist => Productlist.id == currProductId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Product"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* end of filter */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    // options={{
                    //   groupBy: ["FacilityName", "GroupName", "ItemName"],
                    //   columnCalcs: "both",
                    // }}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end of row */}
        </div>
      </div>
    </>
  );
};

export default LossSummaryReport;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});
