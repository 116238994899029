import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

const ProductGroupEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "product-group-entry";

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">
                {
                  DispensingLanguage[lan][menukey][
                  "Product Group Entry Form - Add/Edit"
                  ]
                }
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["Product Group Entry"]}
              />
              <CardContent>
                <Grid container >
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          error={errorObject.GroupName}
                          helperText={errorObject.GroupName}
                          required
                          id="GroupName"
                          name="GroupName"
                          label={
                            DispensingLanguage[lan][menukey][
                            "Product Group Name"
                            ]
                          }
                          value={formData.GroupName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          error={errorObject.ShortName}
                          helperText={errorObject.ShortName}
                          required
                          id="ShortName"
                          name="ShortName"
                          label={DispensingLanguage[lan][menukey]["Short Name"]}
                          value={formData.ShortName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid className="d-flex justify-content-start align-items-center mt-3" item xs={12} md={2}>

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.BMajorGroup}
                              onChange={(e) => handleCheck(e)}
                              name="BMajorGroup"
                              value="no"
                            />
                          }
                          label={
                            DispensingLanguage[lan][menukey]["Major Group"]
                          }
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.isCOVID}
                              onChange={(e) => handleCheck(e)}
                              name="isCOVID"
                              value="no"
                            />
                          }
                          label={DispensingLanguage[lan][menukey]["Is Covid"]}
                        />


                        {/* content */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                color="primary"
                variant="contained"
                className="mr-2"
                type="reset"
                onClick={() => handleReset()}
              >
                {t(DispensingLanguage[lan][menukey]["Reset"])}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mr-2"
                onClick={() => handleSubmit()}
              >
                {t(DispensingLanguage[lan][menukey]["Save"])}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mr-2"
                type="reset"
                onClick={() => props.history.push("/product-group-entry")}
              >
                {t(DispensingLanguage[lan][menukey]["Cancel"])}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                color="primary"
                variant="contained"
                className="mr-2"
                onClick={() => handleUpdate()}
              >
                {t(DispensingLanguage[lan][menukey]["update"])}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mr-2"
                type="reset"
                onClick={() => props.history.push("/product-group-entry")}
              >
                {t(DispensingLanguage[lan][menukey]["Cancel"])}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default ProductGroupEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
