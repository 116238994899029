import React, { useState, useEffect } from "react"; //, useEffect

import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";

import * as api from "../../../actions/api";
import DispenserFormData from "./ReceiveItems.js";
import moment from "moment";
// import Constants from "../../../services/Constants";

const AddReceive = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "receive";
  const FacilityId = localStorage.getItem("FacilityId");
  
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
  );
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [formData, setFormData] = useState({
    TransactionDate: selectedDate,
    SupplierInvDate: selectedDate,
    SupplierInvNo: "",
    TransactionNo: generatedInvoiceNo,
    FacilityId: FacilityId,
    TransactionTypeId: 1,
    ReceiveFrom: "",
    OrderNo: "",
    PreparedBy: UserId,
    //ApprovedBy:"",
    DeliveredBy: "",
    Remarks:""
  });

  const [ManyTableData, setManyTableData] = useState([]);
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  // const queryClient = useQueryClient();

  let params = {
    menukey: "receive",
    FacilityId: FacilityId,
    TransactionTypeId: 1,
    TransactionDate: moment(formData.TransactionDate ).format("DD/MM/YYYY"),
    ChangeTransactionDate:true
  };
  const { data: invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, {
    onSuccess: (data) => {
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData };
      fdata["TransactionNo"] = data;
      setFormData(fdata);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  /*
  const { mutate } = useMutation(api.saveReceiveInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `/${menukey}/edit/${data.TransactionId}`;
        props.history.push(route);
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  */
  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("formData: ", e.target);

    let data = { ...formData };

    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;  
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
  }

  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      SupplierInvDate: selectedDate,
      SupplierInvNo: "",
      TransactionNo: generatedInvoiceNo,
      FacilityId: FacilityId,
      TransactionTypeId: 1,
      ReceiveFrom: "",
      OrderNo: "",
      PreparedBy: "",
      //ApprovedBy:"",
      DeliveredBy: "",
      ItemGroupId: props.match.params.id, //Get the url send id
      Remarks:""
    });
    setManyTableData([]);
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "SupplierInvNo",
      "ReceiveFrom"
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  /*
  const handleSubmit = async (e) => {
    console.log("from submit ");
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        menukey: "receive",
      };
      mutate(fDtat);
    }
  };
  */
  const deleteReceiveInv = (data) => {
    let newarray = ManyTableData.filter((element) => element != data);
    setManyTableData(newarray);
  };

  /*

  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (validateForm(formData)) {
      let fDtat = { ...formData, ManyJsonSave: rows, menukey: "receive", UserName:UserName,lan:lan };
      mutate(fDtat);
    }

    setManyTableData(rows);
  };

  */


  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':[]}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  useEffect(() => {}, []);

  return (
    <>
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        //handleSubmit={handleSubmit}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        deleteReceiveInv={deleteReceiveInv}
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        //bStockUpdate={0}
        handleAdd={handleAdd}
        generatedInvoiceNo={generatedInvoiceNo}
        handleChangeChoosenProps={handleChangeChoosenProps}
        checkValidateForm={checkValidateForm}
        {...props}
      />
    </>
  );
};

export default AddReceive;
