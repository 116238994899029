import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
  Typography,
} from "@material-ui/core";


import * as api from '../../../actions/api';
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { cellFocusEditor,cellFocusNumberEditor } from "../../../services/Common";
import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import "../common.css";

import swal from "sweetalert";
import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
let isDirty=false;
let masterDirty=false;
const OrderApprovalFormData = (props) => {
  const { t, i18n } = useTranslation();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "order-approval";
  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  let tblRef = null;
  const classes = useStyles();

  const [manyTableData, setManyTableData] = useState([]);
  const [bCompleted, setbCompleted] = useState(false);
  const OrderType = JSON.parse(localStorage.getItem("OrderType"));
  const OrderTo = JSON.parse(localStorage.getItem("FacilityList")); //JSON.parse(localStorage.getItem("OrderTo"));
  const UsersListByFacility = JSON.parse(
    localStorage.getItem("UsersListByFacility")
  );
  const OrderListApprovalUser = JSON.parse(
    localStorage.getItem("OrderListApprovalUser")
  );

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({ menukey });
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({menukey});

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroupList = [All_Product].concat(Product_Group);

  const [activeProductGroupData, setAcProductGroupData] = useState({
    id: ProductGroupList.length > 0 ? ProductGroupList[0].id : "",
    name: ProductGroupList.length > 0 ? ProductGroupList[0].name : "",
  });

  const handleProductGroupChange = (event, newValue) => {
    setAcProductGroupData(newValue);
    if (props.addProductForm == false) {
      props.filterFandleChange(newValue.id);
    }
  };
  //console.log("User Idssss",UserId);

  /*
  if(props.formData.ApprovedBy>0){
    props.formData.formData["ApprovedBy"] = props.formData.ApprovedBy;
  }else{
    props.formData.formData["ApprovedBy"] = UserId;
  }
  
  */

  /* ====Start of Print Excel Many Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_order_stock_entry_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&OrderId=" +
        props.formData.OrderId +
        "&ItemGroupId=" +
        activeProductGroupData.id +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_order_stock_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&OrderId=" +
        props.formData.OrderId +
        "&ItemGroupId=" +
        activeProductGroupData.id +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const manycolumns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      hozAlign: "center",
      formatter: "rownum",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 135,
     // headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      //headerFilter: false,
      width: 180,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Min Qty"],
      field: "MinQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Max Qty"],
      field: "MaxQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock on Hand"],
      field: "StockOnHand",
      width: 110,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Calculated Order Quantity"],
      field: "CalcOrderQty",
      width: 165,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Submitted Order Quantity"],
      field: "SubmittedOrderQty",
      width: 165,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Actual Order Quantity"],
      field: "ActualOrderQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 165,
      editor: bCompleted == 0 ? cellFocusNumberEditor : "",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
        //props.setUpdatedDetailsGridDataHandler(currow._row.cells,false);
        //setUpdatedDetailsGridRowHandler(rowdata.id, curcellval, totprice);
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          if(curcellval == '0123456789')
          cell.setValue('');
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }
      },
      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue();
        const rowData = cell.getRow().getData();
        const SubmittedOrderQty = rowData.SubmittedOrderQty;
        const Remarks = rowData.Remarks;

        if (
          SubmittedOrderQty != cellValue &&
          (Remarks == null || Remarks == "")
        ) {
          cell.getRow().getElement().style.backgroundColor = "#ec7063";
        } else {
          cell.getRow().getElement().style.backgroundColor = "transparent";
        }

        return cellValue;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Comment"],
      field: "Remarks",
     // width: 100,
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      //  editor: bCompleted == 0? true : '',

      editor: bCompleted == 0 ? cellFocusEditor : "",

      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue();
        const rowData = cell.getRow().getData();
        const SubmittedOrderQty = rowData.SubmittedOrderQty;
        const ActualOrderQty = rowData.ActualOrderQty;

        if (
          SubmittedOrderQty != ActualOrderQty &&
          (cellValue == null || cellValue == "")
        ) {
          cell.getRow().getElement().style.backgroundColor = "#ec7063";
        } else {
          cell.getRow().getElement().style.backgroundColor = "transparent";
        }

        return cellValue;
      },cellEdited: function (cell) {

        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        //   props.setUpdatedDetailsGridDataHandler(rowdata);
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }

      },
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Unit Price"],
    //   field: "UnitPrice",
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   width: 90,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Line Total"],
    //   field: "LineTotal",
    //   formatter: "money",
    //   hozAlign: "right",
    //   bottomCalc: "sum",
    //   bottomCalcFormatter: "money",
    //   headerHozAlign: "right",
    //   width: 105,
    // },
  ];


  const onSetDisable = () => {  
    if(isDirty==true){   
      //document.getElementById("is_dirty_message").classList.remove("dnone"); 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }
    else{  
     //document.getElementById("is_dirty_message").classList.add("dnone"); 
     document.getElementById("master_dirty_message").classList.add("dnone");
    }    
  }


  const onMasterSetDirty = () => {   
    if(masterDirty==true){ 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  }

  const ManyDataItems = props.ManyTableData?.map((item, index) => {
    item["LineTotal"] = item.ActualOrderQty * item.UnitPrice;
    return item;
  });



  const onSaveUpdateBtnClick = () => {

    let dataArray = [];
    let gridData = tblRef.table.getData();
    
    setUpdatedDetailsGridDataHandler(gridData);
    

  }



  const setUpdatedDetailsGridDataHandler = (rows) => {
    // let validForm = props.checkValidateForm(rows, 'edit');

    let manydataR=[]; 
    rows.forEach((row,i)=>{
      if(row.dirty==1)
      manydataR.push(row);
    })

    let validForm = props.checkValidateForm(manydataR, 'edit');

    if (validForm) {

      if (props.addProductForm) {
        //when save
        //mutate(validForm);
      } else {
        //when update
        let FinalData = { ...validForm, 'ManyJsonSave': rows, 'masterDirty':masterDirty };
        mutateUpdate(FinalData);

      }



    }
  };



  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(api.updateOrderApproval, {
    onSuccess: (data) => {

      if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;

        props.successSave(data.IsCompleted);
        if (data.IsCompleted == 1) {
         // mutatePost(props.formData.OrderId);
         let FinalData={OrderId:props.formData.OrderId,FacilityId:props.FacilityIdForRpt};
         mutatePost(FinalData);
        }
        else {
          props.successSave(data.IsCompleted); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(api.postOrderApproval, {
    onSuccess: (data) => {
      if (data.status == 200) {


        if (data.success == 0) {

          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });


        } else {
          props.stockPosted(1);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          //swal(data.data.message, "", "success");


        }

      } else {
        props.stockPosted(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const onPostBtnClick = () => {

    setbCompleted(true);
   
    let dataArray = [];
    let gridData = tblRef.table.getData();

    handlePost(gridData);
    //props.setUpdatedDetailsGridDataHandler(dataArray,deletedDataSet); 

  }
 

  const handlePost = async (e) => {

    let manydata = tblRef.table.getData();

    let ValidationCount = onDataValidation(manydata);
    if(ValidationCount>0){
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Please, mention reason of approve order quantity change"],
        msgtype: 0,
        duration: 10000,
      });
      return;
    }

    let manydataP=[]; 
    tblRef.table.getData().forEach((row,i)=>{
      if(row.dirty==1)
      manydataP.push(row);
    })

    let validForm = props.checkValidateForm(manydataP, "post");
   // console.log("ggggggggggggggggg",validForm);
    if (validForm) {

      let FinalData={...validForm,"IsCompleted":1,menukey: menukey,lan: lan, "UserName":UserName, "masterDirty":masterDirty};
      modalPopupDelete(mutateUpdate, FinalData, { PostQ: "OrderApprovalQ" }, ModalNo, validForm);

    }

  };

  const ModalNo = (validForm) => { 
    props.stockPosted(0); 
    setbCompleted(false);
    let route = `${validForm.OrderId}|${validForm.FacilityId}`;
    props.history.push(route);

  }

  const onDataValidation = (data) => {
    let ValidationCount = 0;
  
    for (let i = 0; i < data.length; i++) {
      let SubmittedOrderQty = data[i].SubmittedOrderQty;
      let ActualOrderQty = data[i].ActualOrderQty;
      let Remarks = data[i].Remarks;
  
      if((SubmittedOrderQty != ActualOrderQty) && ((Remarks == null) || (Remarks == ""))){
        ValidationCount++;
      }
  
      if(ValidationCount>0){
         return ValidationCount;
      }
    }
    return ValidationCount;
  }




  const handleReject = async (e) => {
    
    let validForm = props.checkValidateForm(tblRef.table.getData(), 'post');

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you really want to reject this order stock?"]),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willAction) => {
      if (willAction) {
        mutateReject({OrderId:validForm.OrderId,FacilityId:props.FacilityIdForRpt});
      }
    });
  
   
  };


  const {  mutate:mutateReject } = useMutation(api.rejectOrderApproval, {
    onSuccess: (data) => { 
      if (data.status == 200) { 
  
       props.history.push("/order-approval");
  
       props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
  
       //swal("Success!", "", "success");
      }else{
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })



  const backToList = () => {

    if((masterDirty==true)||(isDirty==true)){
      
      modalPopupBacktoList(backToListCallBack, "/order-approval", {PostQ:"modalBackToListQ"}); 
  
    }else props.history.push("/order-approval")
    
  };

  const backToListCallBack = (PageUrl) => {
      masterDirty=false;
      isDirty=false;
      props.history.push(PageUrl);
  }


  const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

     masterDirty=true;
     onMasterSetDirty();

   };



  useEffect(() => {
    if (tblRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }
    setbCompleted(props.bCompleted);
  }, [props.ManyTableData, props.bCompleted]);

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Order - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {/* Action buttons */}
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="mr-4 mt-2 float-left">
                        <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => props.handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/order-approval")}
                      onClick={() => backToList()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <div className="mr-4 mt-2 float-left">
                        <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      //onClick={() => props.handleUpdate()}
                      //disabled={bCompleted == 1}
                      onClick={onSaveUpdateBtnClick}               
                      disabled={(bCompleted === 1) || isLoadingUpdate || isLoadingPost}
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      //onClick={() => props.handlePost()}
                      //disabled={bCompleted == 1}
                      onClick={onPostBtnClick}
                      disabled={(bCompleted === 1) || isLoadingUpdate || isLoadingPost}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleReject()}
                      //disabled={bCompleted == 1}
                      disabled={(bCompleted === 1) || isLoadingUpdate || isLoadingPost}
                    >
                      {DispensingLanguage[lan][menukey]["reject"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={event =>  window.location.href='/order'}
                      //onClick={() => props.history.push("/order-approval")}
                      onClick={() => backToList()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                     <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i className="fas fa-print"></i>
                    </Button> 

                     <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
                {/* End Action buttons */}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        required
                        error={props.errorObject.OrderDate}
                        helperText={props.errorObject.OrderDate}
                        id="OrderDate"
                        label={DispensingLanguage[lan][menukey]["Order Date"]}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        autoOk={true}
                        name="OrderDate"
                        fullWidth
                        showTodayButton={true}
                        value={props.formData.OrderDate || props.selectedDate}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "OrderDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={true}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      error={props.errorObject.OrderNo}
                      helperText={props.errorObject.OrderNo}
                      required
                      id="OrderNo"
                      name="OrderNo"
                      label={DispensingLanguage[lan][menukey]["Order No"]}
                      value={props.formData.OrderNo || props.generatedInvoiceNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Order Type"]}
                      </InputLabel>
                      <Select
                        error={props.errorObject.OrderTypeId}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="OrderTypeId"
                        name="OrderTypeId"
                        value={props.formData.OrderTypeId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={true}
                        //disabled={bCompleted==1}
                      >
                        {OrderType.map((item, index) => {
                          return (
                            <MenuItem
                              key={`OrderType-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.OrderTypeId}>
                        {props.errorObject.OrderTypeId}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    {/* 
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                          { DispensingLanguage[lan][menukey]['Order To'] }  
                          </InputLabel>
                          <Select
                            error={props.errorObject.OrderingFrom}
                            // helperText={props.errorObject.OrderingFrom}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="OrderingFrom"
                            name="OrderingFrom"
                            value={props.formData.OrderingFrom}
                            fullWidth
                            onChange={(e) => props.handleChange(e)}
                            disabled={true}
                            //disabled={bCompleted==1}
                          >
                          {
                          OrderTo.map(
                          (item, index) => {
                            return (
                              <MenuItem value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          }
                          )}
                        </Select>

                        <FormHelperText error={props.errorObject.OrderingFrom}>
                          {props.errorObject.OrderingFrom}
                        </FormHelperText>
                        </FormControl> */}

                    <TextField
                      error={props.errorObject.OrderingFrom}
                      helperText={props.errorObject.OrderingFrom}
                      required
                      id="OrderingFrom"
                      name="OrderingFrom"
                      label={DispensingLanguage[lan][menukey]["Order To"]}
                      value={props.formData.OrderingFromText}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Entry By"]}
                      </InputLabel>
                      <Select
                        error={props.errorObject.OrderBy}
                        // helperText={props.errorObject.OrderBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="OrderBy"
                        name="OrderBy"
                        value={props.formData.OrderBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={true}
                        //disabled={bCompleted==1}
                      >
                        {OrderListApprovalUser.map((item, index) => {
                          return (
                            <MenuItem
                              key={`OrderListApprovalUser=${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.OrderBy}>
                        {props.errorObject.OrderBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["InternalApprover"]}
                      </InputLabel>
                      <Select
                        error={props.errorObject.InternalApprover}
                        labelId="demo-simple-select-helper-label"
                        id="InternalApprover"
                        name="InternalApprover"
                        value={props.formData.InternalApprover}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={true}
                      >
                        {OrderListApprovalUser.map((item, index) => {
                          return (
                            <MenuItem
                              key={`OrderListApprovalUser-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText
                        error={props.errorObject.InternalApprover}
                      >
                        {props.errorObject.InternalApprover}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Approved By"]}
                      </InputLabel>
                      <Select
                        error={props.errorObject.ApprovedBy}
                        // helperText={props.errorObject.ApprovedBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="ApprovedBy"
                        name="ApprovedBy"
                        value={
                          props.formData.ApprovedBy > 0
                            ? props.formData.ApprovedBy
                            : UserId
                        }
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','ApprovedBy')}
                        //disabled={true}
                        disabled={bCompleted == 1}
                      >
                        {OrderListApprovalUser.map((item, index) => {
                          return (
                            <MenuItem
                              key={`OrderListApprovalUser-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.ApprovedBy}>
                        {props.errorObject.ApprovedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="OrderId"
                      name="OrderId"
                      label="OrderId"
                      value={props.formData.OrderId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  {/* new row */}
                  <Grid item xs={12} sm={12}>
                    <Card className="sw_card">
                      <CardContent>
                        <div>
                          <Grid container className="mb-2">
                            {/* start of filter */}

                            <Grid item xs={3} sm={3}>
                              <FormControl className={classes.fullWidth}>
                                <Autocomplete
                                  autoHighlight
                                  className="sw_chosen_filter"
                                  id="ProductGroupList"
                                  disableClearable
                                  options={ProductGroupList}
                                  value={activeProductGroupData}
                                  onChange={(event, newValue) =>
                                    handleProductGroupChange(event, newValue)
                                  }
                                  disabled={
                                    props.addProductForm == true ? true : false
                                  }
                                  getOptionLabel={(option) => option.name || ""}
                                  renderOption={(option) => (
                                    <Typography className="sw_fontSize">
                                      {option.name}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        DispensingLanguage[lan][menukey][
                                          "Product Group"
                                        ]
                                      }
                                      variant="standard"
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>

                            {/* end of filter */}
                          </Grid>
                          <div className="uniqueName">
                            <ReactTabulator
                              ref={(r) => (tblRef = r)}
                              columns={manycolumns}
                              data={manyTableData}
                              layout={"fitColumns"}
                              height="340px"
                              options={{
                                groupBy: ["GroupName"]
                              }}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderApprovalFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
