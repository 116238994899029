import React, { useEffect } from "react";//useState, 
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { 
  Grid,
  TextField,
  Button, 
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";  
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OrderModal =({ 
  handleClose, 
  openOrder, 
  addOrder, 
  ItemGroupId, 
  formData, 
  handleChange, 
  handleSubmit, 
  errorObject,
  handleCheck,
  ...props }) => {

const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));

const lan = localStorage.getItem('LangCode');
const menukey = "data-extract-tool";

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('OrderModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('OrderModal')}
        >
         {DispensingLanguage[lan][menukey]['Save Query']}
        </BootstrapDialogTitle>
        <DialogContent dividers> 

        { formData.QueryId !='' ? (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={formData.Overwrite}
                  onChange={(e) => handleCheck(e)}
                  name="Overwrite"
                  value="yes"
                />
              }
              label={DispensingLanguage[lan][menukey]["Overwrite"]}
            />
          </Grid>
          ) : ''}
          
          <Grid item xs={12} sm={12}>
            <TextField
              error={errorObject.QueryName}
              helperText={errorObject.QueryName}
              required
              id="QueryName"
              name="QueryName"
              label=  { DispensingLanguage[lan][menukey]['Query Label'] }
              value={formData.QueryName}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              error={errorObject.Description}
              helperText={errorObject.Description}
              required
              id="Description"
              name="Description"
              label=  { DispensingLanguage[lan][menukey]['Description'] }
              value={formData.Description}
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
          onClick={handleSubmit}
          >
           {DispensingLanguage[lan][menukey]['Save']} 
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('OrderModal')}>
            {DispensingLanguage[lan][menukey]['Cancel']} 
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default OrderModal;
