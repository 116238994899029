import React, { useState, useEffect } from "react";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardContent,
  FormControl,
  Grid,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { Button } from "reactstrap";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { useTranslation } from "react-i18next";
// import {
//   checkLogin,
//   checkUserPermission,
// } from "../../services/CheckUserAccess";
// import moment from "moment";
// import "moment/locale/fr"; // import Moment.js French locale

const A7ReportsView = (props) => {
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t } = useTranslation();
  const menukey = "a7-report-view";

  // moment.locale(lan == "en_GB" ? "enGB" : "fr");

  const classes = useStyles();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const MosTypelist = JSON.parse(localStorage.getItem("MosTypelist"));
  //   ============new start==============
  const [firstLoad, setFirstLoad] = useState(true);
  
//region combo list
const [RegionList, setRegionListData] = useState([]);
const [activeRegionData, setAcRegionData] = useState({
  id: RegionList.length > 0 ? RegionList[0].id : 0,
  name: RegionList.length > 0 ? RegionList[0].name : "",
});

React.useEffect(() => {
  if (activeRegionData.id == 0) {
    getRegionData();
  }
}, [activeRegionData.id]);

const handleDepartmentChange = (event, newValue) => {
  setAcRegionData(newValue);
  getCommuneData(newValue.id);
};

const getRegionData = () => {
  let FacilitylevelParam = {
    action: "getDepartmentforreport",
    RoleId: 1,
    UserId: UserId,
    IsAll: 1,
    menukey: menukey,
    lan: lan,
  };
  cRegionList(FacilitylevelParam);
};

const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
  onSuccess: (data) => {
    if (data.status == 200) {
      setRegionListData(data.data.datalist);

      setAcRegionData({
        id: data.data.datalist.length ? data.data.datalist[0].id : 0,
        name: data.data.datalist.length ? data.data.datalist[0].name : "",
      });
      getCommuneData(data.data.datalist[0].id);
    }
  },
});

////distict combo list
const [Communelist, setCommunelistData] = useState([]);
const [activeCommuneData, setAcCommuneData] = useState({
  id: -1,
  name: Communelist.length > 0 ? Communelist[0].name : "",
});

const getCommuneData = (RegionId) => {
  let FacilitylevelParam = {
    action: "getcommuneforreport",
    RoleId: 1,
    UserId: UserId,
    RegionId: RegionId,
    IsAll: 1,
    menukey: menukey,
    lan: lan,
  };
  cgetCommuneList(FacilitylevelParam);
};

const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
  onSuccess: (data, params) => {
    if (data.status == 200) {
      setCommunelistData(data.data.datalist);

      setAcCommuneData({
        id: data.data.datalist.length ? data.data.datalist[0].id : 0,
        name: data.data.datalist.length ? data.data.datalist[0].name : "",
      });

      getFacilityData(
        params.RegionId,
        data.data.datalist.length ? data.data.datalist[0].id : 0
      );
    }
  },
});

// Commune Change
const handleCommuneChange = (event, newValue) => {
  setAcCommuneData(newValue);
  getFacilityData(activeRegionData.id, newValue.id);
};

//facility combo load

const [Facility_list, setFacilityData] = useState([]);
const [activeFacilityData, setAcFacilityData] = useState({
  id: -1,
  name: Facility_list.length > 0 ? Facility_list[0].name : "",
});

const getFacilityData = (regid, communeid) => {
  let FacilityParam = {
    action: "FacilitylistforReport",
    RegionId: regid,
    DistrictId: communeid,
    RoleId: 1,
    UserId: UserId,
    IsAll: 0,
    menukey: menukey,
    lan: lan,
  };
  cFacilityList(FacilityParam);
};

const { mutate: cFacilityList } = useMutation(api.getFacility, {
  onSuccess: (data, params) => {
    if (data.status == 200) {
      setFacilityData(data.data.datalist);

      setAcFacilityData({
        id: data.data.datalist.length ? data.data.datalist[0].id : 0,
        name: data.data.datalist.length ? data.data.datalist[0].name : "",
      });

      refreshAllData(
        params.RegionId,
        params.DistrictId,
        data.data.datalist.length ? data.data.datalist[0].id : 0,
        currmonthId,
        currYearId
      );
    }
  },
});

const handleFacilityListChange = (event, newValue) => {
  refreshAllData(
    activeRegionData.id,
    activeCommuneData.id,
    newValue.id, 
    currmonthId,
    currYearId
  );
  setAcFacilityData(newValue);
};



  //   ============new start==============

  const currentDate = new Date();
  let previousMonth = currentDate.getMonth() + 1 - 1;
  let previousYear = currentDate.getFullYear();

  if (previousMonth < 0) {
    // If the previous month is negative, subtract 1 from the year and set the month to 11 (December)
    previousMonth = 11;
    previousYear -= 1;
  }

  // Create a new Date object for the first day of the previous month
  const previousMonthDate = new Date(previousYear, previousMonth - 1, 15);

  const [currmonthId, setmonthId] = useState(previousMonthDate.getMonth() + 1);
  const [currYearId, setYearId] = useState(previousMonthDate.getFullYear());
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));

  const YearList = JSON.parse(localStorage.getItem("YearList"));

  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
    }

    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      activeFacilityData.id, 
      newValue.id,
      currYearId
    );
  };

  // Carton GTIN Change
  const handleYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);
    }
    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      activeFacilityData.id, 
      currmonthId,
      newValue.id
    );

  };

  // =================New Combo========================

  const [FacilityLevel, setFacilityLevel] = useState(0);
  const [dataItems, setDataItems] = useState([]);

  const refreshAllData = (
    RegionId,
    DistrictId,
    FacilityId,
    MonthId,
    Year
  ) => {
    let params1 = {
      menukey: menukey,
      RegionId: RegionId,
      ZoneId: DistrictId,
      FacilityId: FacilityId,
      MonthId: MonthId,
      Year: Year,
      lan: lan
    };
    GetShowA7ReportView({ queryKey: [{ ...params1, action: "ShowA7ReportView" }] });
   
  };

  const { mutate: GetShowA7ReportView } = useMutation(api.getA7viewReport, {
    onSuccess: (data) => {
      setDataItems(data.data.datalist);
      setFacilityLevel(data.data.FLevelId);
    },
  });



  // let params1 = {
  //   action: "ShowA7ReportView",
  //   menukey: menukey,
  //   RegionId: activeRegionData.id,
  //   ZoneId: activeCommuneData.id,
  //   FacilityId: activeFacilityData.id,
  //   MonthId: currmonthId,
  //   Year: currYearId,
  //   // FacilityId: activeFacilityData.id,
  // };


  // const { refetch } = useQuery([params1], api.getA7viewReport, {
  //   onSuccess: (data) => {
     
  //     setDataItems(data.data.datalist);
  //     setFacilityLevel(data.data.FLevelId);
  //     //console.log("Data fetched successfully:", data.data.FLevelId);
  //   },
  //   refetchOnWindowFocus: false,
  //   refetchOnmount: false,
  //   refetchOnReconnect: false,
  //   retry: false,
  //   staleTime: 0, //1000 * 60 * 60 * 24,
  // });

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product_Code"],
      field: "ItemCode",
      headerSort: false,
      headerFilter: false,
      width: 100,
      formatter: "textarea",
     
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Designation"],
      field: "ItemName",
      //width: 198,
      headerSort: false, 
      formatter: "textarea",
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Stock"],
      field: "OpB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive"],
      field: "RecB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue"],
      field: "Issue",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Dispense"],
      field: "Consumption",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment"],
      field: "AdjB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       formatter:"textarea",
       width: 100,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["AdjustmentMin"],
      field: "AdjC",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 100,
       headerWordWrap:true,
     
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "Stock",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       headerWordWrap:true,
       width: 150,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Stockout Days"],
      field: "StockoutDays",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 120,
      headerWordWrap:true
     
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 60,
     
    },
   
    {
      title: DispensingLanguage[lan][menukey]["DMM"],
      field: "DMM",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 60,
       visible: FacilityLevel==99?false:true,
       
     
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 70,
      formatter: function (cell, formatterParams) {
        // MosTypelist
        let value = cell.getValue();
        let cellColor = "transparent";
        const rowData = cell.getRow().getData(); 
       // const NonDispensing = DispensingLanguage[lan][menukey]["Dispense"];

        
        if(rowData.COLOR!=null){

          cellColor= rowData.COLOR;
          cell.getElement().style.backgroundColor = cellColor;
  

        } 
          return value;
       

      },

    },
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/A7ReportViewPrintExcel.php";

    window.open(
      finalUrl +
        "?action=A7reportExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&District=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name +
        "&MonthId=" +
        currmonthId +
        "&MonthName=" +
        '' +
        "&Year=" +
        currYearId+
        "&TimeStamp=" +
        Date.now() 
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className="section signup-top-padding sw_inner_container">
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-2">
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["A7 Report View"])}
              </div>
            </div>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid> */}

                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="MonthList"
                          disableClearable
                          options={MonthList}
                          onChange={(event, newValue) =>
                            handleMonthChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            MonthList[
                              MonthList.findIndex(
                                (MonthList) => MonthList.id == currmonthId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Month"]}
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="YearList"
                          disableClearable
                          options={YearList}
                          onChange={(event, newValue) =>
                            handleYearChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            YearList[
                              YearList.findIndex(
                                (YearList) => YearList.id == currYearId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Year"]}
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}>
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}>
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>

              </Grid>
            </CardContent>
          </Card>

          {/* end of filter */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          height="600px"
                          options={{
                            groupBy: "GroupName",
                            columnCalcs: "both",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}
        </div>
      </div>
    </>
  );
};

export default A7ReportsView;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
