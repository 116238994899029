import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar"; 
import Notification from "../../../services/Notification";
import {checkLogin, checkUserPermission} from "../../../services/CheckUserAccess";

// import swal from "sweetalert";
//supplier-form
import Manufacturer from "./Manufacturer";
import AddManufacturerForm from "./AddManufacturerForm";
import EditManufacturerForm from "./EditManufacturerForm"; 


const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "manufacturer-entry-form";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);

/*   const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: "token expired. Please login again",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = process.env.REACT_APP_BASE_NAME + `/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */

  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if(RedirectLogin){
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }
  

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    if(document.getElementById("Snackbarh")!=null)
    document.getElementById("Snackbarh").classList.remove("dnone"); 
    setMsgObj(obj);
    setTimeout(function(){
      if(document.getElementById("Snackbarh")!=null)
      document.getElementById("Snackbarh").classList.add("dnone"); 
     }, (obj.msgtype==1? 3000:8000));
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason == 'clickaway') {
        return;
      } 
      if(document.getElementById("Snackbarh")!=null)
      document.getElementById("Snackbarh").classList.add("dnone"); 
  };


  return (
    hasUserPermission && (
    <div>
      <AfterLoginNavbar {...props} />
      <div
        className={`section signup-top-padding ${classes.dispenserContainer}`}
      >
        <Switch>
          <Route
            path={`${path}/add`}
            render={(props) => (
              <AddManufacturerForm {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
          <Route
            path={`${path}/edit/:id`}
            render={(props) => (
              <EditManufacturerForm {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
          <Route
            path={`${path}/`}
            render={(props) => (
              <Manufacturer {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
        </Switch>
        <Notification
          closeNoticeModal={closeNoticeModal}
          msgObj={msgObj}
          {...props}
        ></Notification>
      </div>
    </div>)
  );
};

export default Index;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
