import React, { useEffect, useState } from "react";//, 
import { useParams } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
let ProfessionModalDirty=false;
const ProfessionModal = ({ handleClose, openOrder, addProfession, ...props }) => {

  const classes = useStyles();

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "aefi";

  const { id } = useParams();
  const queryClient = useQueryClient();

  // const getSeverityList = JSON.parse(localStorage.getItem("getSeverityList"));
  // const getStopTreatmentList = JSON.parse(localStorage.getItem("getStopTreatmentList"));
  // const getReadministrationList = JSON.parse(localStorage.getItem("getReadministrationList"));

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  // const [isSerious, setIsSerious] = useState(true);
  const [errorObject, setErrorObject] = useState({});
  const [ProfessionData, setProfessionData] = useState([]);
  const [btnDisable, setbtnDisable] = useState(false);

  const [formDataForProfession, setFormDataForProfession] = useState({
    ProfessionName: ""
  });

  const handleReset = () => {
    setFormDataForProfession({
      ProfessionName: ""
    });
  };

  const onManySetProfessionDirty = () => {   
    if(ProfessionModalDirty==true){ 
      document.getElementById("profession_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("profession_dirty_message").classList.add("dnone");
    }
  }

  const { mutate } = useMutation(api.saveProfessiontData, {
    onSuccess: (data) => {
      if (data.status == 200) {

        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'getProfessionList'
        };

        api.getLoginAllDropdown(options,'getProfessionList')
        .then((response) => {
          localStorage.setItem(
            "getProfessionList",
            JSON.stringify(response.datalist.getProfessionList)
          );
        });

        setProfessionData(data);
        setbtnDisable(true);
        setTimeout(() => setbtnDisable(false), 500);
        
        /*props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });*/

        
        // handleClose('ProfessionModal');
        handleReset();

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formDataForProfession };
    data[name] = value;

    setFormDataForProfession(data);

    setErrorObject({ ...errorObject, [name]: null });

    ProfessionModalDirty=true;
    onManySetProfessionDirty();
  };

  const validateForm = (formData) => {
    let validateFields = ["ProfessionName"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })

    setErrorObject(errorData);
    return isValid
  }

  const handleSubmit = async (e) => {

    let fDtat = {
      ...formDataForProfession,
      menukey: menukey,
      lan: lan,
      UserName: UserName,
      // AefiMasterId: props.formData.AefiMasterId,
    };

    if (validateForm(formDataForProfession)) {
      mutate(fDtat);
    }
  };

  useEffect(() => {

    if (props.formDataForProfession) {
      setFormDataForProfession(props.formDataForProfession);
    }
  }, [props.formDataForProfession]);

  useEffect(() => { 

    addProfession(ProfessionData);

}, [ProfessionData]); 

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('ProfessionModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('ProfessionModal')}
        >
          {DispensingLanguage[lan][menukey]['Occupation']}
        </BootstrapDialogTitle>
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={10}>
              <Grid item xs={4} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} className="marginBottom20">
                  <TextField
                    error={errorObject.ProfessionName}
                    helperText={errorObject.ProfessionName}
                    required
                    id="ProfessionName"
                    name="ProfessionName"
                    label={DispensingLanguage[lan][menukey]["Occupation"]}
                    value={formDataForProfession.ProfessionName}
                    fullWidth
                    autoComplete="family-name"
                    onChange={(e) => handleChange(e)}
                  />
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <DialogActions>
          <div className="mr-4 mt-2 float-left">
            <span id="profession_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
          </div>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {DispensingLanguage[lan][menukey]['Save']}
          </Button>
          <Button autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose('ProfessionModal')}>
            {DispensingLanguage[lan][menukey]['Cancel']}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProfessionModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});