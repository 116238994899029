import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Card,
  CardContent
} from "@material-ui/core";

import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";


const OrderFormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "role-access-entry";
  // const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  // const UserName = UserInfo==0?'': UserInfo[0].name;

  const classes = useStyles();

  const { useState } = React;

  const [dataItemsShortOrder, setdataItemsShortOrder] = useState([]);

  let tblMenuRef = null;

  let params = {
    menukey: menukey,
    action: "getMenuShortOrderLists",
    //role_id: selectedRoleData,
  };

  const {
    data: menuData,
    // refetch: MenuRefetch,
  } = useQuery([params], api.getMenuShortOrder, {
    onSuccess: (menuData) => {
      setdataItemsShortOrder(menuData);
    },
  });


  const menucolumns = [
    { rowHandle: true, formatter: "handle", headerSort: false, frozen: true, width: 30, minWidth: 30 },
    { title: DispensingLanguage[lan][menukey]["Menu Name"], field: "menuname" },
  ];


  const {
    // isLoadingLang, 
    mutate: MenuOrderUpdate } = useMutation(api.updateMenuShortOrder, {
      onSuccess: (data) => {
        props.history.push("/role-access-entry");

        if (data.data.status == 200) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }


      }
    })

  const SaveSelectedOrder = () => {
    //console.log(tblMenuRef.table.getData());
    let MenuData = tblMenuRef.table.getData();
    MenuOrderUpdate({ MenuData });

  };

  const options = {
    headerSort: false,
    movableRows: true,

  }

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Menu Short Order"]}
              </div>

              <div className="float-right sw_btn_control">
                {/* Action buttons */}
                {
                  <Grid item xs={12} className="text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={SaveSelectedOrder}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/role-access-entry")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                }
                {/* End Action buttons */}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <div>
                        <div className="uniqueName">
                          <ReactTabulator
                            ref={(r) => (tblMenuRef = r)}
                            options={options}
                            columns={menucolumns}
                            data={dataItemsShortOrder}
                            layout={"fitColumns"}
                            height={"500px"}
                            rowMoved={(row) => {

                              console.log("Row: " + row.getData().id + " has been moved")

                            }}
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
