import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  // Container,
  // UncontrolledTooltip,
} from "reactstrap";
import {ImageList,ImageListItem } 
from "@material-ui/core";
function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const itemData = [
     {
       img: require("assets/img/mali_logo.png"),
       title: 'Mali Logo',
     },
   ];


  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 51 ||
        document.body.scrollTop < 51
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <div className="container-fluid">
          <div className="navbar-translate">
            <NavbarBrand href={process.env.REACT_APP_BASE_NAME+"/"} id="navbar-brand">
              <div className="logo">
                <div className="logoFormate">
                    <div className="imge_section">
                        <ImageList sx={{ width: 45, height: 50}} cols={1} rowHeight="auto" gap={2}>
                          {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                              <img
                                src={`${item.img}`}
                                alt={item.title}
                                loading="logo"
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                    </div> 
                    <div className="logo_item">
                      <span href={process.env.REACT_APP_BASE_NAME+"/"}>Mali OSPSANTE eSIGL Vaccins</span> 
                        {/* <span className="sw_sub_title">
                        {" "}
                        {localStorage.getItem("FacilityName")}
                      </span>  */}
                    </div>
                </div>
              </div>
            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>

          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  Home
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>

        </div>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
