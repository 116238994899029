import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

// import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import ManufacturerEntryFormData from "./ManufacturerEntryFormData";




const AddManufacturerForm = (props) => {
  // console.log(props.pieceOfState);

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "manufacturer-entry-form";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const { FacilityId } = useParams();

  const [formData, setFormData] = useState({
    
    ManufacturerName: "",
    ISO3: "",
    Email: "",
    ContactName: "",
    ContactNo: "",
    Fax: "",
    ManufacturerAddress: "",
    Url: ""
  });
  const [errorObject, setErrorObject] = useState({});

  const queryClient = useQueryClient();



  const [open, setOpen] = React.useState(false);
  // const [Massage, setMassage] = React.useState(false);

  // useQuery(
  //   ["getSingleSupplier", id],
  //   () =>
  //     api.getSupplierForm(id).then((res) => {
  //       setFormData(res.data);
  //     }),
  //   {
  //     enabled: Boolean(id),
  //   }
  // );

  const {  mutate } = useMutation(api.saveManufacturerForm, {
    onSuccess: (data) => {

      if (data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("manufacturer-entry-form"); //api page name
        props.history.push("/manufacturer-entry-form"); //
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        // queryClient.getQueriesData("supplier-form"); //api page name
        // props.history.push("/supplier-form"); //
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  // const handleCountryChange = (e) => {
  //   const { name, value } = e.target;

  //   let data = { ...formData };

  //   data[name] = value;

  //   setFormData(data);

  //   setErrorObject({ ...errorObject, [name]: null });
  // }

  const handleCountryChange = (valueObj) => {

    const { name, value } = valueObj;

    let data = { ...formData };

    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  }




  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ManufacturerName: "",
      ISO3: "",
      Email: "",
      ContactName: "",
      ContactNo: "",
      Fax: "",
      ManufacturerAddress: "",
      Url: ""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ManufacturerName","ContactNo","ISO3"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    // console.log("from submit , checking of validation", formData);
        console.log(formData);
    console.log(validateForm(formData));
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  return (
    <>
      <ManufacturerEntryFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleCountryChange={handleCountryChange}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        open={open}
        {...props}
      />
    </>
  );
};

export default AddManufacturerForm;
