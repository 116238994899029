import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Card,
  CardContent,
  Button
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import Autocomplete from "@material-ui/lab/Autocomplete";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { Edit } from "@material-ui/icons";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
const StoreEntry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const lancod = localStorage.getItem("LangCode");
  const menukey = "store-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const FLevelId = localStorage.getItem("FLevelId");
  // const FacilityId = localStorage.getItem("FacilityId");
  // const FacilityName = localStorage.getItem("FacilityName");
  const LanguageList = JSON.parse(localStorage.getItem("LanguageList"));
  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(true);
  // Facility Auto
  //  let Facility_List=JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  //   let All_Facility_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
  //   const FacilityList = [All_Facility_label].concat(Facility_List);

  const [currLangCode, setCurrLangCode] = useState(lan);
  const [currFLevel, setcurrFLevel] = useState("0");

  const FlevelList = JSON.parse(localStorage.getItem("FlevelList"));
  FlevelList.unshift({ 'id': "0", 'name': "All" });

  //====== Start Choosen Dropdown===========
  const [chosenValuesFlevelList, setChosenValuesFlevelList] = useState({
    FlevelList: { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) },
  });
  // Flevel List
  const handleChangeChoosenWarehouse = (name, valueobj, value) => {
    let chosenValuesDataRegion = { ...chosenValuesFlevelList };
    chosenValuesDataRegion[name] = valueobj;
    setChosenValuesFlevelList(chosenValuesDataRegion);

    // setFirstLoad(true);
    setLoading(false);
    setcurrFLevel(value);
  };


  // Langcode

  const handleLanCodeChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }

    if (rowId !== currLangCode) {
      setCurrLangCode(rowId);

      setLoading(true);
    }
  };

  React.useEffect(() => {
    localStorage.setItem("LangCode", currLangCode);
  }, [currLangCode]);

  // End Langcode

  // Store Combo Change
  const handleStoreChange = (event) => {
    if (currFLevel !== event.target.value) {
      setcurrFLevel(event.target.value);
      setLoading(true);
    }
  };

  React.useEffect(() => {
    localStorage.setItem("FLevelId", currFLevel);
  }, [currFLevel]);
  // End Store

  let params = {
    menukey: menukey,
    action: "getDataList",
    lan: currLangCode,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    FacilityLevel: currFLevel,
    UserId: UserId,
  };
  const { refetch } = useQuery([params], api.getStoreEntry, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const { mutate: deleteApi } = useMutation(api.deleteStoreEntry, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        // api.getAllDropdown("PackSizelist").then((response) => {
        //   localStorage.setItem(
        //     "PackSizelist",
        //     JSON.stringify(response.datalist.PackSizelist)
        //   );
        // });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        refetch();
        queryClient.getQueriesData("storeentry");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=getStoreEntryData" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      currLangCode +
      "&FacilityLevel=" +
      currFLevel +
      "&UserId=" +
      UserId +
      +"&TimeStamp=" +
      Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editRecipients(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Store Name"],
      field: "LanguageText",
      headerSort: false,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Level"],
      headerFilter: true,
      field: "FLevelNames",
    },
    {
      title: DispensingLanguage[lan][menukey]["Action"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editRecipients = (data) => {
    let route = `store-entry/edit/${data.id}`;
    props.history.push(route);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        <Edit
          onClick={() => {
            editRecipients(rowData);
          }}
        />
        {/* <DeleteOutline
          onClick={() => {
            deleteRecipients(rowData);
          }}
        /> */}
      </>
    );
  }

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Store Entry Form"])}
              </div>
            </div>
          </Grid>
        </div>

        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="LanguageList"
                    disableClearable
                    options={LanguageList}
                    // disabled={UserName == "admin" ? false : true}
                    onChange={(event, newValue) =>
                      handleLanCodeChange(event, newValue)
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      LanguageList[
                      LanguageList.findIndex(
                        (LanguageList) => LanguageList.id == currLangCode
                      )
                      ]
                    }
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Language"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    id="FlevelList"
                    options={FlevelList}
                    onChange={(event, valueobj) =>
                      handleChangeChoosenWarehouse(
                        "FlevelList",
                        valueobj,
                        valueobj ? valueobj.id : ""
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    value={chosenValuesFlevelList["FlevelList"]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          DispensingLanguage[lan][menukey]["Facility Level"]
                        }
                        variant="standard"
                        id="FlevelList"
                        name="FlevelList"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7} sm={7}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/store-entry/add");
                      // if (currLangCode!="0") {
                      //   props.history.push("/store_entry/add");
                      // } else {
                      //   props.openNoticeModal({
                      //     isOpen: true,
                      //     msgtype: "0",
                      //     msg: DispensingLanguage[lan][menukey]["Please select a facility"],
                      //   });
                      // }
                    }}
                  >
                    {t(DispensingLanguage[lan][menukey]["Add"])}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <div>
          <div className="sw_relative">
            {isLoading && <LoadingSpinnerOpaque />}
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                // height={600}
                layout={"fitColumns"}
                rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreEntry;

const useStyles = makeStyles({
  packSizePageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
