import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

// import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import SupplierFormData from "./SupplierFormData";

const AddSupplier = (props) => {
  // console.log(props.pieceOfState);

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "supplier";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [formData, setFormData] = useState({
    SupplierName: "",
    ISO3: "",
    Email: "",
    ContactName: "",
    ContactNo: "",
    Fax: "",
    SupplierAddress: "",
    Url: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  // const [Massage, setMassage] = React.useState(false);

  useQuery(
    ["getSingleSupplier", id],
    () =>
      api.geABC(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const {  mutate } = useMutation(api.saveSupplier, {
    onSuccess: (data) => {

      if (data.status == 200) {
        // api.getAllDropdown("ABC_List").then((response) => {
        //   localStorage.setItem(
        //     "ABC_List",
        //     JSON.stringify(response.datalist.ABC_List)
        //   );
        // });

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("supplier"); //api page name
        props.history.push("/supplier"); //
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("supplier"); //api page name
        props.history.push("/supplier"); //
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCountryChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };

    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  }


  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      SupplierName: "",
      ISO3: "",
      Email: "",
      ContactName: "",
      ContactNo: "",
      Fax: "",
      SupplierAddress: "",
      Url: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["SupplierName" , "ContactNo", 'ISO3'];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit , checking of validation", formData);
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  return (
    <>
      <SupplierFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleCountryChange={handleCountryChange}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        open={open}
        {...props}
      />
    </>
  );
};

export default AddSupplier;
