import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,

} from "@material-ui/core";
import swal from "sweetalert";
// import * as Service from "../../../services/Service";

const AddProduct = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    productName: "",
    productCode: "",
    productGroup: "",
    price: "",
    key: false,
  });
  // const [groupData, setGroupData] = useState([]);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  // const handleCheck = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData({ ...formData, [name]: checked });
  // };

  const handleReset = () => {
    setFormData({
      productName: "",
      productCode: "",
      productGroup: "",
      price: "",
      key: false,
    });
  };

  const handleSubmit = async (e) => {
    const { productName, productCode, productGroup } = formData;
    if (productName == "" || productCode == "" || productGroup == "") {
      swal("Oops Error!", "Text Fields are Empty!", "error");
    } else {
      swal("Success!", "", "success");
      // const payload = {
      //   product_group: productGroup,
      //   product_code: productCode,
      //   product_name: productName,
      //   product_price: price == "" ? 0 : price,
      //   product_key: key ? "1" : "0",
      // };

      // const response = await Service.default.postAuthApi(
      //   "source/protocol/add.php",
      //   payload
      // );
      // if (response.status == 201) {
      //   swal("Success!", `${response.message}`, "success");
      //   props.history.push("/protocol");
      // } else {
      //   swal("Error!", `${response.message}`, "error");
      // }
    }
  };

  // const fetchGroups = async () => {
  //   const response = await Service.default.getAuthApi(
  //     "source/product/all_product_groups.php"
  //   );
  //   setGroupData(response.Productgroupdata);
  // };

  useEffect(() => {
    // fetchGroups();
  }, []);

  return (
    <div className="sw_topmargin">
      <div className={classes.tableContainer}>
        <Typography variant="h6" gutterBottom className="text-center mb-5">
          Stock Status Entry - Add/Edit
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6} sm={5}></Grid>

          <Grid item xs={12} className="text-center">
            <Button
              className="mr-2"
              variant="contained"
              type="reset"
              onClick={() => handleReset()}
            >
              Reset
            </Button>
            <Button
              className="mr-2"
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
            <Button
              className="mr-2"
              variant="contained"
              type="reset"
              onClick={() => props.history.push("/stock-status")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddProduct;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
