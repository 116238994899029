import React, { useState, useContext } from "react";

// services & auth
import * as Service from "../../services/Service.js";
import * as api from "actions/api";

import { UserContext } from "../../context/user-info-context";

//Import Preloader
import LoadingSpinnerOpaque from "../../services/LoadingSpinnerOpaque";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// import { useForm } from "react-hook-form";

import swal from "sweetalert";

//Css
import "assets/css/custom.css";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
// import { Constants } from "ag-grid-community";

// import Constants from "services/Constants";

const regex =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

function LoginPage(props) {
  const userCtx = useContext(UserContext);

  const { buttonLabel, className } = props;
  // const { register, handleSubmit,  errors } = useForm();
  const [passwordShown, setPasswordShown] = useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [state, setState] = React.useState({
    service: Service,
    email: "",
    password: "",
  });
  const [modal, setModal] = useState(false);

  const [isLoading, setLoading] = useState(false);

  React.useEffect(() => {
    // if(state.service.default.authToken() != null){
    //   swal("Oops!", "Your are already logged in!", "warning");
    //   props.history.push("/home");
    // }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const LoginPage = (data) => {
    if (state.email.length > 0 && state.password.length > 0) {
      //if (!regex.test(state.email)) {
      // swal("Oops Error!", "Your email is not valid!", "error");
      //} else {

      setLoading(true);

      const body = {
        email: state.email,
        password: state.password,
      };
      state.service.default
        .postApi("source/login.php", body)
        .then((res) => {
          if (res.success === 1) {
            sessionStorage.setItem("token", res.token);
            sessionStorage.setItem("User_info", JSON.stringify(res.user_data));

            const auth_token = sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : null;

            //Dispensing Language
            let options = {};
            options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth_token}`,
              },
            };

            api.getLoginDispensingLanguage(options).then((res1) => {
 
              //console.log(res)
              let DispensingLanguageData = res1.datalist;
              localStorage.setItem(
                "DispensingLanguage",
                JSON.stringify(DispensingLanguageData)
              );

              api.getLoginAllDropdown(options, "ALL").then((response) => {
                if (response.success === 1) {


                  localStorage.setItem(
                    "AdjType",
                    JSON.stringify(response.datalist.AdjType)
                  );
                  localStorage.setItem(
                    "VvmType",
                    JSON.stringify(response.datalist.VvmType)
                  );
                  localStorage.setItem(
                    "CountryList",
                    JSON.stringify(response.datalist.CountryList)
                  );

                  localStorage.setItem(
                    "DosageFormlist",
                    JSON.stringify(response.datalist.DosageFormlist)
                  );
                  localStorage.setItem(
                    "ABC_List",
                    JSON.stringify(response.datalist.ABC_List)
                  );
                  localStorage.setItem(
                    "FacilityList",
                    JSON.stringify(response.datalist.FacilityList)
                  );
                  localStorage.setItem(
                    "GenericList",
                    JSON.stringify(response.datalist.GenericList)
                  );

                  localStorage.setItem(
                    "IntervalList",
                    JSON.stringify(response.datalist.IntervalList)
                  );
                  localStorage.setItem(
                    "OrderType",
                    JSON.stringify(response.datalist.OrderType)
                  );
                  localStorage.setItem(
                    "PackSizelist",
                    JSON.stringify(response.datalist.PackSizelist)
                  );
                  localStorage.setItem(
                    "VEN_List",
                    JSON.stringify(response.datalist.VEN_List)
                  );

                  localStorage.setItem(
                    "RouteOfAdminlist",
                    JSON.stringify(response.datalist.RouteOfAdminlist)
                  );
                  localStorage.setItem(
                    "RxLevelList",
                    JSON.stringify(response.datalist.RxLevelList)
                  );

                  localStorage.setItem(
                    "Strengthlist",
                    JSON.stringify(response.datalist.Strengthlist)
                  );


                  localStorage.setItem(
                    "Unitofmeasurelist",
                    JSON.stringify(response.datalist.Unitofmeasurelist)
                  );
                  localStorage.setItem(
                    "ParentMenu",
                    JSON.stringify(response.datalist.ParentMenu)
                  );
                  localStorage.setItem(
                    "MenuUrl",
                    JSON.stringify(response.datalist.MenuUrl)
                  );


                  localStorage.setItem(
                    "language_preference",
                    JSON.stringify(response.datalist.t_language_preference)
                  );



                  localStorage.setItem(
                    "ProductTypeId",
                    JSON.stringify(response.datalist.ProductTypeId)
                  );

                  localStorage.setItem(
                    "ProductGroupList",
                    JSON.stringify(response.datalist.ProductGroupList)
                  );
                  localStorage.setItem(
                    "ProductGroupForDashboard",
                    JSON.stringify(response.datalist.ProductGroupForDashboard)
                  );

                  localStorage.setItem(
                    "IsCovid",
                    JSON.stringify(response.datalist.ProductGroupList[0]['isCOVID'])
                  );



                  localStorage.setItem(
                    "MenuList",
                    JSON.stringify(response.datalist.MenuList)
                  );

                  // localStorage.setItem(
                  //   "UserList",
                  //   JSON.stringify(response.datalist.UserList)
                  // );

                  localStorage.setItem(
                    "UserListEdit",
                    JSON.stringify(response.datalist.UserListEdit)
                  );

                  localStorage.setItem(
                    "YearList",
                    JSON.stringify(response.datalist.YearList)
                  );

                  localStorage.setItem(
                    "MonthList",
                    JSON.stringify(response.datalist.MonthList)
                  );

                  localStorage.setItem(
                    "QuarterList",
                    JSON.stringify(response.datalist.QuarterList)
                  );



                  localStorage.setItem(
                    "RoleList",
                    JSON.stringify(response.datalist.RoleList)
                  );

                  localStorage.setItem(
                    "LanguageList",
                    JSON.stringify(response.datalist.LanguageList)
                  );

                  // localStorage.setItem(
                  //   "MosTypelist",
                  //   JSON.stringify(response.datalist.MosTypelist)
                  // );

                  localStorage.setItem(
                    "FacilityLocation",
                    JSON.stringify(response.datalist.FacilityLocation)
                  );

                  localStorage.setItem(
                    "ATC_List",
                    JSON.stringify(response.datalist.ATC_List)
                  );


                  localStorage.setItem(
                    "Itemlist",
                    JSON.stringify(response.datalist.Itemlist)
                  );
                  /*  localStorage.setItem(
                     "LabItemlist",
                     JSON.stringify(response.datalist.LabItemlist)
                   );
  */
                  localStorage.setItem(
                    "IOItemlist",
                    JSON.stringify(response.datalist.IOItemlist)
                  );



                  localStorage.setItem(
                    "OrderListApprovalUser",
                    JSON.stringify(response.datalist.OrderListApprovalUser)
                  );
                  localStorage.setItem(
                    "SourceList",
                    JSON.stringify(response.datalist.SourceList)
                  );
                  localStorage.setItem(
                    "TransactionType",
                    JSON.stringify(response.datalist.TransactionType)
                  );

                  localStorage.setItem(
                    "Regionlist",
                    JSON.stringify(response.datalist.Regionlist)
                  );

                  localStorage.setItem(
                    "Zonelist",
                    JSON.stringify(response.datalist.Zonelist)
                  );

                  localStorage.setItem(
                    "Communelist",
                    JSON.stringify(response.datalist.Communelist)
                  );

                  localStorage.setItem(
                    "FlevelList",
                    JSON.stringify(response.datalist.FlevelList)
                  );



                  localStorage.setItem(
                    "FTypeList",
                    JSON.stringify(response.datalist.FTypeList)
                  );

                  localStorage.setItem(
                    "Ownertypelist",
                    JSON.stringify(response.datalist.Ownertypelist)
                  );

                  localStorage.setItem(
                    "AllCountryList",
                    JSON.stringify(response.datalist.AllCountryList)
                  );

                  localStorage.setItem(
                    "getMedicineTakenFromList",
                    JSON.stringify(response.datalist.getMedicineTakenFromList)
                  );

                  localStorage.setItem(
                    "getProfessionList",
                    JSON.stringify(response.datalist.getProfessionList)
                  );

                  localStorage.setItem(
                    "getReadministrationList",
                    JSON.stringify(response.datalist.getReadministrationList)
                  );

                  localStorage.setItem(
                    "getSeverityList",
                    JSON.stringify(response.datalist.getSeverityList)
                  );

                  localStorage.setItem(
                    "getStopTreatmentList",
                    JSON.stringify(response.datalist.getStopTreatmentList)
                  );

                  localStorage.setItem(
                    "getShipmentStatusList",
                    JSON.stringify(response.datalist.getShipmentStatusList)
                  );

                  localStorage.setItem(
                    "getFundingSourceList",
                    JSON.stringify(response.datalist.getFundingSourceList)
                  );

                  localStorage.setItem(
                    "Servicearealist",
                    JSON.stringify(response.datalist.Servicearealist)
                  );
                  localStorage.setItem(
                    "CurrencyList",
                    JSON.stringify(response.datalist.CurrencyList)
                  );

                  localStorage.setItem(
                    "FacilityId",
                    res.user_data[0].FacilityId
                  );
                  for (
                    let i = 0;
                    i < response.datalist.FacilityList.length;
                    i++
                  ) {
                    if (
                      response.datalist.FacilityList[i]["id"] == res.user_data[0].FacilityId
                    ) {
                      localStorage.setItem(
                        "FacilityName",
                        response.datalist.FacilityList[i]["name"]
                      );
                      // console.log('response.datalist.FacilityList[i][]: ', response.datalist.FacilityList[i]['name']);
                      break;
                    } else {
                      localStorage.setItem("FacilityName", "");
                      //  console.log('FacilityName: ', 'FacilityName');
                    }
                  }
                  if(response.datalist.FacilityList.length==0)
                    localStorage.setItem("FacilityName", "");

                  localStorage.setItem("LangCode", res.user_data[0].LangCode);
                  localStorage.setItem("bFacilitySelected", res.user_data[0].bFacilitySelected);
                  localStorage.setItem("bOrderApprover", res.user_data[0].bOrderApprover);
                  userCtx.replaceFacilityHandler({
                    FacilityId: res.user_data[0].FacilityId,
                    FacilityName:
                      response.datalist.FacilityList[
                      res.user_data[0].FacilityId
                      ],
                    LangId: res.user_data[0].LangCode,
                  });

                  api
                    .getLoginFacilityListNotEqual99ANDSuppliers(options, "ALL")
                    .then((response) => {
                      localStorage.setItem(
                        "OrderTo",
                        JSON.stringify(response.datalist.OrderTo)
                      );
                      localStorage.setItem(
                        "IssueToGroupList",
                        JSON.stringify(response.datalist.IssueToGroupList)
                      );
                      localStorage.setItem(
                        "IssueToFacility",
                        JSON.stringify(response.datalist.IssueToFacility)
                      );
                      localStorage.setItem(
                        "AdjType",
                        JSON.stringify(response.datalist.AdjType)
                      );
                      localStorage.setItem(
                        "TestToFacility",
                        JSON.stringify(response.datalist.TestToFacility)
                      );
                      localStorage.setItem(
                        "IssuedToGroupItemList",
                        JSON.stringify(response.datalist.IssuedToGroupItemList)
                      );
                      localStorage.setItem(
                        "UsersListByFacility",
                        JSON.stringify(response.datalist.UsersListByFacility)
                      );
                      localStorage.setItem(
                        "IssuedToList",
                        JSON.stringify(response.datalist.IssuedToList)
                      );
                      localStorage.setItem(
                        "MosTypelist",
                        JSON.stringify(response.datalist.MosTypelist)
                      );

                      localStorage.setItem(
                        "SupplierListByFacility",
                        JSON.stringify(response.datalist.SupplierListByFacility)
                      );
                      localStorage.setItem(
                        "ManufacturerList",
                        JSON.stringify(response.datalist.ManufacturerList)
                      );
                      localStorage.setItem(
                        "GenterList",
                        JSON.stringify(response.datalist.GenterList)
                      );
                      localStorage.setItem(
                        "getFacilityListEqual99",
                        JSON.stringify(response.datalist.getFacilityListEqual99)
                      );
                      localStorage.setItem(
                        "FLevelId",
                        JSON.stringify(response.datalist.GetFLevelId[0].FLevelId)
                      );

                      localStorage.setItem(
                        "initFacilityData",
                        JSON.stringify(res.user_data[0].initFacilityData)
                      );
 
                      window.location.href = `${process.env.REACT_APP_BASE_NAME}` + res.user_data[0].roleredirect;

                      //  window.location.href = `${process.env.REACT_APP_BASE_NAME}/dashboard`;
                    });
                }

                //
              });
            });

            setLoading(false);

            //localStorage.setItem('AdjType', []);

            // window.location.href = "/dashboard";
          } else if (res.success === 0) {
            setLoading(false);
            swal("Error!", `${res.message}`, "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          swal("Error!", `${err}`, "error");
        });
      //}
    } else {
      swal("Oops Error!", "Please fill all required fields", "error");
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggle = () => setModal(!modal);

  return (
    <>
      <ExamplesNavbar />
      <div className="page-header sw_relative_login">
        {isLoading && <LoadingSpinnerOpaque />}
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <div className="logo-container"></div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="sw_login_padd">
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="placeholder_color"
                        placeholder="Username..."
                        type="text"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        // autoComplete="username"
                        valid={false}
                        invalid={false}
                        // ref={register({ required: true })}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                      ></Input>
                      {/* {errors.username &&
                        errors.username.type === "required" && (
                          <span>This field is required</span>
                        )} */}
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="sw_login_padd">
                          <i className="now-ui-icons ui-1_lock-circle-open"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="placeholder_color"
                        placeholder="Password..."
                        name="password"
                        onChange={(e) => handleChange(e)}
                        // autoComplete="password"
                        type={passwordShown ? "text" : "password"}
                        // ref={register({ required: true })}
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                      ></Input>
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="password-eye">
                          <i
                            onClick={togglePasswordVisiblity}
                            className="fa fa-eye"
                            aria-hidden="true"
                          ></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      // href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        LoginPage();
                      }}
                      size="lg"
                    >
                      LOGIN
                    </Button>
                    <div className="pull-left">
                      <h6>
                        <a
                          className="link"
                          // href="/signup-page"
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/signup");
                          }}
                        >
                          Create Account
                        </a>
                      </h6>
                    </div>
                    {/* <div className="pull-right">
                      <h6>
                        <a
                          className="link"
                          href="#"
                          onClick={() => toggle()}
                        >
                          Forget Password?
                        </a>
                      </h6>
                    </div> */}
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        <TransparentFooter />
      </div>

      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <ModalHeader toggle={toggle}>Forgot Password Link</ModalHeader>
        <ModalBody className="text-center">
          <a className="" href={"./reset-password"}>
            This is the Link to Change Password
          </a>
        </ModalBody>
        <ModalFooter className="reset-modal-btn">
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault(e);
              props.history.push("./reset-password");
            }}
          >
            Reset Password
          </Button>
          {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default LoginPage;
