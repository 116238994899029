import React, {
  useState,
  useEffect,
  useContext,
  Component,
  useRef,
} from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";
// material components
import {
  Select,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  InputLabel,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { Launch } from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { useTranslation } from "react-i18next";

const StockStatusReport = (props) => {
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "stock-card-report";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  /////facility combo load

  const [Facility_list, setFacilityData] = useState([]);
  const [activeFacilityData, setAcFacilityData] = useState({
    id: Facility_list.length > 0 ? Facility_list[0].id : "",
    name: Facility_list.length > 0 ? Facility_list[0].name : "",
  });

  useEffect(() => {
    if (activeFacilityData.id == "") {
      getFacilityData(0, 0);
    }
  }, [activeFacilityData.id]);

  const getFacilityData = (regid, communeid) => {
    let FacilityParam = {
      action: "FacilitylistforReport",
      RegionId: regid,
      DistrictId: communeid,
      RoleId: RoleId,
      UserId: UserId,
      IsAll: 0,
      menukey: menukey,
      lan:lan,
    };
    cFacilityList(FacilityParam);
  };

  const { mutate: cFacilityList } = useMutation(api.getFacility, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setFacilityData(data.data.datalist);

        setAcFacilityData({
          id: data.data.datalist[0].id,
          name: data.data.datalist[0].name,
        });

        refreshAllData(
          data.data.datalist.length>0?data.data.datalist[0].id:-1,
          currProductGroupId,
          acValue.id,
          selectedStartDate,
          selectedEndDate
        );

      }
    },
  });

  const handleFacilityListChange = (event, newValue) => {
    setAcFacilityData(newValue);
    refreshAllData(
      newValue.id,
      currProductGroupId,
      acValue.id,
      selectedStartDate,
      selectedEndDate
    );
  };

 //product group
  const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Product_Group = {
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroup = [All_Product_Group].concat(Product_Group);

  const [tmpProductlist, setProductlist] = useState([]);
  let All_Product = {
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const Productlist = [All_Product].concat(tmpProductlist);

  const [currProductGroupId, setProductGroupId] = useState(0);
  const [ProductGroupName, setProductGroupName] = useState(
    t(DispensingLanguage[lan][menukey]["All"])
  );

  const [acValue, setACValue] = useState({
    id: 0,
    name: Productlist.length > 0 ? Productlist[0].name : "",
  });

  useEffect(() => {
    if (currProductGroupId == 0) {
      let ProductlistParam1 = {
        action: "getProductItem",
        ItemGroupId: 0,
        chechkvalue:-1,
        menukey: "",
      };
      cProductList(ProductlistParam1);
    }
  }, [currProductGroupId]);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setProductGroupName(rowName);
    }

    let ProductlistParam = {
      action: "getProductItem",
      ItemGroupId: newValue.id,
      menukey: "",
    };
    cProductList(ProductlistParam);
  };

  const { mutate: cProductList } = useMutation(api.getProductListData, {
    onSuccess: (data, ProductlistParam) => {
      if (data.status == 200) {
        // console.log("Dta list", data.data.datalist);
        setProductlist(data.data.datalist);
        setACValue({
          id: 0,
          name: t(DispensingLanguage[lan][menukey]["All"]),
        });
        //alert(ProductlistParam.ItemGroupId);
        if (ProductlistParam.chechkvalue != -1) {
          refreshAllData(
            activeFacilityData.id,
            ProductlistParam.ItemGroupId,
            0,
            selectedStartDate,
            selectedEndDate
          );
        }
      }
    },
  });

  // product Change
  const handleProductChange = (event, newValue) => {
    refreshAllData(
      activeFacilityData.id,
      currProductGroupId,
      newValue.id,
      selectedStartDate,
      selectedEndDate
    );
    setACValue(newValue);
  };

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  //console.log("Selected Date",selectedStartDate);

  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    refreshAllData( 
      activeFacilityData.id,
      currProductGroupId,
      acValue.id,
      date,
      selectedEndDate
    );
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    refreshAllData(
      activeFacilityData.id,
      currProductGroupId,
      acValue.id,
      selectedStartDate,
      date
    );
  };

 
  const [dataItems, setDataItems] = useState([]);

  const refreshAllData = (
    FacilityId,
    ItemGroupId,
    ProductId,
    StartDate,
    EndDate
  ) => {
    let params1 = {
      menukey: menukey,
      FacilityId: FacilityId,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductGroupId: ItemGroupId,
      ItemNo: ProductId,
      lan: lan
    };
    getShowStockCardTable({ queryKey: [{ ...params1, action: "ShowStockCard" }] });
  };

  const { mutate: getShowStockCardTable } = useMutation(api.GeReceivedetailsreport, {
    onSuccess: (data) => {
      setDataItems(data);
    },
  });




  // let params = {
  //   menukey: menukey,
  //   action: "ShowStockCard",
  //   lan: lan,
  //   FacilityId: activeFacilityData.id,
  //   ProductGroupId: currProductGroupId,
  //   ItemNo: currProductId,

  //   StartDate: selectedStartDate,
  //   EndDate: selectedEndDate,
  // };

  // const { data: scanloglist } = useQuery([params], api.GeReceivedetailsreport, {
  //   onSuccess: (data) => {
  //     // console.log("hhhhhhhhh",data);
  //     setDataItems(data);
  //   },
  //   refetchOnWindowFocus: false,
  //   refetchOnmount: false,
  //   refetchOnReconnect: false,
  //   retry: false,
  //   staleTime: 0, //1000 * 60 * 60 * 24,
  // });

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice Date"],
      field: "TransactionDate",
      headerSort: true,
      width: 83,
      // headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Origin_Destination"],
      field: "Receiver",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      field: "Invoice",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 160,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Type"],
      field: "Type",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 180,
      headerFilter: true,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Receive"],
      field: "ReceivedQty",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 105,
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue"],
      field: "IssuedQty",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 105,
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
    {
      title: DispensingLanguage[lan][menukey]["Balance"],
      field: "Balance",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 105,
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "LotNo",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 110,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry_Date"],
      field: "Exp",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 83,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 190,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Observation"],
      field: "LangText",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      //width: 140,
    },

    /*
    {
      title: DispensingLanguage[lan][menukey]["Signature"],
      field: "signature",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 120,
    },
    */
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/StockCard_Print_Excel.php";
    let formatedStartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let formatedEndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=StockCardExcelExport" +
        "&reportType=" +
        reportType +
        "&FacilityId=" +
        activeFacilityData.id +
        "&ProductGroupId=" +
        currProductGroupId +
        "&ItemNo=" +
        acValue.id +
        "&StartDate=" +
        formatedStartDate +
        "&EndDate=" +
        formatedEndDate +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="section signup-top-padding sw_inner_container">
        <div className="sw_inner_card">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Stock_Card"])}
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} style={{marginTop:'4px'}}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} >
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            className={classes.fullWidth}
                            //disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label={
                              DispensingLanguage[lan][menukey]["Start date"]
                            }
                            value={selectedStartDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={2} sm={2} style={{marginTop:'4px'}}>
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={DateFnsUtils} >
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            className={classes.fullWidth}
                            // disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label={t(
                              DispensingLanguage[lan][menukey]["End date"]
                            )}
                            autoOk={true}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                    <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroup"
                          disableClearable
                          options={ProductGroup}
                          onChange={(event, newValue) =>
                            handleProductGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            ProductGroup[
                              ProductGroup.findIndex(
                                (ProductGroup) =>
                                  ProductGroup.id == currProductGroupId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Product Group"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                    <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Productlist"
                          disableClearable
                          options={Productlist}
                          value={acValue}
                          onChange={(event, newValue) =>
                            handleProductChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          // defaultValue={Productlist[Productlist.findIndex(Productlist => Productlist.id == currProductId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Product"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}>
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}>
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <div>
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height={600}
                options={{
                  groupBy: ["FacilityName", "GroupName", "ProductFullName"],

                  columnCalcs: "both",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockStatusReport;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
