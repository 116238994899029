import React, { useState } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
import { useMutation } from "react-query";
// material components
import {
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardContent,
  Button,
  CardHeader,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const ReAdministration = (props) => {
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "re-administration";
  const [isLoadingUi, setLoading] = useState(true);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);

  exporting(Highcharts);

  // Filter Start

 //region combo list
 const [RegionList, setRegionListData] = useState([]);
 const [activeRegionData, setAcRegionData] = useState({
   id: RegionList.length > 0 ? RegionList[0].id : 0,
   name: RegionList.length > 0 ? RegionList[0].name : "",
 });

 React.useEffect(() => {
   if (activeRegionData.id == 0) {
     getRegionData();
   }
 }, [activeRegionData.id]);

 const handleDepartmentChange = (event, newValue) => {
   setAcRegionData(newValue);
   getCommuneData(newValue.id);
 };

 const getRegionData = () => {
   let FacilitylevelParam = {
     action: "getDepartmentforreport",
     RoleId: RoleId,
     UserId: UserId,
     IsAll: 1,
     menukey: menukey,
     lan: lan,
   };
   cRegionList(FacilitylevelParam);
 };

 const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
   onSuccess: (data) => {
     if (data.status == 200) {
       setRegionListData(data.data.datalist);

       setAcRegionData({
         id: data.data.datalist.length ? data.data.datalist[0].id : 0,
         name: data.data.datalist.length ? data.data.datalist[0].name : "",
       });
       getCommuneData(data.data.datalist[0].id);
     }
   },
 });

 ////distict combo list
 const [Communelist, setCommunelistData] = useState([]);
 const [activeCommuneData, setAcCommuneData] = useState({
   id: -1,
   name: Communelist.length > 0 ? Communelist[0].name : "",
 });

 const getCommuneData = (RegionId) => {
   let FacilitylevelParam = {
     action: "getcommuneforreport",
     RoleId: RoleId,
     UserId: UserId,
     RegionId: RegionId,
     IsAll: 1,
     menukey: menukey,
     lan: lan,
   };
   cgetCommuneList(FacilitylevelParam);
 };

 const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
   onSuccess: (data, params) => {
     if (data.status == 200) {
       setCommunelistData(data.data.datalist);

       setAcCommuneData({
         id: data.data.datalist.length ? data.data.datalist[0].id : 0,
         name: data.data.datalist.length ? data.data.datalist[0].name : "",
       });

       getFacilityData(
         params.RegionId,
         data.data.datalist.length ? data.data.datalist[0].id : 0
       );
     }
   },
 });

 // Commune Change
 const handleCommuneChange = (event, newValue) => {
   setAcCommuneData(newValue);
   getFacilityData(activeRegionData.id, newValue.id);
 };

 //facility combo load

 const [Facility_list, setFacilityData] = useState([]);
 const [activeFacilityData, setAcFacilityData] = useState({
   id: -1,
   name: Facility_list.length > 0 ? Facility_list[0].name : "",
 });

 const getFacilityData = (regid, communeid) => {
   let FacilityParam = {
     action: "FacilitylistforReport",
     RegionId: regid,
     DistrictId: communeid,
     RoleId: RoleId,
     UserId: UserId,
     IsAll: 1,
     menukey: menukey,
     lan: lan,
   };
   cFacilityList(FacilityParam);
 };

 const { mutate: cFacilityList } = useMutation(api.getFacility, {
   onSuccess: (data, params) => {
     if (data.status == 200) {
       setFacilityData(data.data.datalist);

       setAcFacilityData({
         id: data.data.datalist.length ? data.data.datalist[0].id : 0,
         name: data.data.datalist.length ? data.data.datalist[0].name : "",
       });

       refreshAllData(
         params.RegionId,
         params.DistrictId,
         data.data.datalist.length ? data.data.datalist[0].id : 0,
         selectedStartDate,
         selectedEndDate
       );
     }
   },
 });

 const handleFacilityListChange = (event, newValue) => {
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     newValue.id, 
     selectedStartDate,
     selectedEndDate
   );
   setAcFacilityData(newValue);
 };

 const [selectedStartDate, setSelectedStartDate] = React.useState(
   moment().subtract(30, "days").format("YYYY-MM-DD")
 );
 //console.log("Selected Date",selectedStartDate);

 const [selectedEndDate, setSelectedEndDate] = React.useState(
   moment().format("YYYY-MM-DD")
 );

 const handleStartDateChange = (date) => {
   setSelectedStartDate(date);
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     activeFacilityData.id,
     date,
     selectedEndDate
   );
 };

 const handleEndDateChange = (date) => {
   setSelectedEndDate(date);
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     activeFacilityData.id,
     selectedStartDate,
     date
   );
 };

  const [dataItems, setDataItems] = useState([]);
  const refreshAllData = (
    RegionId,
    DistrictId,
    FacilityId,
    StartDate,
    EndDate
  ) => {
    let params1 = {
      menukey: menukey,
      RegionId: RegionId,
      DistrictId: DistrictId,
      FacilityId: FacilityId,
      StartDate: StartDate,
      EndDate: EndDate,
      lan: lan
    };
    GetReadministrationTable({ queryKey: [{ ...params1, action: "ReadministrationTable" }] });
    GetReadministrationChart({ queryKey: [{ ...params1, action: "ReadministrationChart" }] });
    
  };

  const { mutate: GetReadministrationTable } = useMutation(api.ReadministrationTable, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
  });


  const [stock_by_expiry_date, setOption_stock_by_expiry_date] = useState([]);

  const { mutate: GetReadministrationChart } = useMutation(api.ReadministrationChart, {
    onSuccess: (data) => {
      setOption_stock_by_expiry_date({
        chart: {
          type: "bar",
          zoomType: "y",
          borderColor: "#C3DDEC",
          //height: 320,
          height: "350px",
          exporting: {
            enabled: true,
          },
        },
        title: {
          text:
            data.StartDate +
            " " +
            t(DispensingLanguage[lan][menukey]["To"]) +
            " " +
            data.EndDate,
        },
        xAxis: {
          categories: data.category,
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: null,
          },
          labels: {
            overflow: "justify",
            //format: "{value}%"
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },

        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
              crop: true,
              formatter: function () {
                return this.y + "%";
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            var rank =
              data.numberofpatient[this.series.data.indexOf(this.point)];
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              t(DispensingLanguage[lan][menukey]["Patients"]) +
              " (" +
              rank +
              ") : " +
              Highcharts.numberFormat(this.y, 2) +
              "%"
            );
          },
        },
        series: [
          {
            data: data.seriesdata, //[69.8, 18.2, 2.3, 2.1, 1.5, 1.5, 1, 0.8, 0.6, 0.6, 2.5],
          },
        ],
      });
    },
  });



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Re-Administration"],
      field: "ReAdministrationName",
      headerSort: true,
      headerFilter: true,
      //width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Count"],
      field: "PatientCount",
      headerSort: true,
      //headerFilter: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 300,
    },
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=ReAdministrationReportExport" +
        "&reportType=" +
        reportType +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&Districtid=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="section signup-top-padding sw_inner_container">
        <div className="sw_inner_card">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Re-Administration"])}
                </div>
              </div>
            </Grid>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2} style={{ marginTop: "4px" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.fullWidth}
                          autoOk={true}
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={DispensingLanguage[lan][menukey]["Start date"]}
                          value={selectedStartDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disableFuture={true}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} sm={2} style={{ marginTop: "4px" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className={classes.fullWidth}
                          autoOk={true}
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={DispensingLanguage[lan][menukey]["End date"]}
                          value={selectedEndDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disableFuture={true}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card" style={{ height: "400px" }}>
                {/* <CardHeader
                  title={
                    DispensingLanguage[lan][menukey]["Patient By Gender Chart"]
                  }
                /> */}
                <CardContent className="sw_filterCardContent">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={stock_by_expiry_date}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end of row */}
          <br></br>
          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
              <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "Re-Administration"
                    ]
                  )}
                  action={
                    <div className="float-right sw_btn_control pt-2">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                  }
                />
                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height={200}
                    // options={{
                    //   groupBy: ["FacilityName", "GroupName", "ItemName"],
                    //   columnCalcs: "both",
                    // }}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end of row */}
        </div>
      </div>
    </>
  );
};

export default ReAdministration;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});
