import React, {
  useState,
  useEffect,
  useContext,
  Component,
  useRef,
} from "react";
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";
import { ReactTabulator } from "react-tabulator";
// material components
import {
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
//import { Button } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";
const OrderSatisfactionRateByLevel = (props) => {
  const [pivotData, setpivotData] = useState([]);
  const [pivotfieldlist, setpivotfieldlist] = useState({});
  const [pivotfields, setpivotfields] = useState({});

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "order-satisfaction-rate-by-level";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const [dataItems, setDataItems] = useState([]);
  const [fieldList, setfieldList] = useState([]);

  //region combo list
  const [RegionList, setRegionListData] = useState([]);
  const [activeRegionData, setAcRegionData] = useState({
    id: RegionList.length > 0 ? RegionList[0].id : 0,
    name: RegionList.length > 0 ? RegionList[0].name : "",
  });

  React.useEffect(() => {
    if (activeRegionData.id == 0) {
      getRegionData();
      getFacilityData(0, 0);
    }
  }, [activeRegionData.id]);

  const getRegionData = () => {
    let FacilitylevelParam = {
      action: "getDepartmentforreport",
      RoleId: 1,
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cRegionList(FacilitylevelParam);
  };

  const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setRegionListData(data.data.datalist);

        setAcRegionData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getCommuneData(data.data.datalist[0].id);
      }
    },
  });

  const handleDepartmentChange = (event, newValue) => {
    setAcRegionData(newValue);
    getCommuneData(newValue.id);
    getFacilityData(newValue.id, 0);
  };

  ////distict combo list
  const [Communelist, setCommunelistData] = useState([]);
  const [activeCommuneData, setAcCommuneData] = useState({
    id: Communelist.length > 0 ? Communelist[0].id : 0,
    name: Communelist.length > 0 ? Communelist[0].name : "",
  });

  const getCommuneData = (RegionId) => {
    let FacilitylevelParam = {
      action: "getcommuneforreport",
      RoleId: 1,
      UserId: UserId,
      RegionId: RegionId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setCommunelistData(data.data.datalist);

        setAcCommuneData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        //getFacilityData(activeRegionData.id, data.data.datalist[0].id);
      }
    },
  });

  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setAcCommuneData(newValue);

    getFacilityData(activeRegionData.id, newValue.id);
  };

  //facility combo load

  const [FacilityLevel, setFacilityLevel] = useState(1);

  const [Facility_list, setFacilityData] = useState([]);
  const [activeFacilityData, setAcFacilityData] = useState({
    id: Facility_list.length > 0 ? Facility_list[0].id : 0,
    name: Facility_list.length > 0 ? Facility_list[0].name : "",
  });

  const getFacilityData = (regid, communeid) => {
    let FacilityParam = {
      action: "FacilitylistforReport",
      RegionId: regid,
      DistrictId: communeid,
      RoleId: 1,
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cFacilityList(FacilityParam);
  };

  const { mutate: cFacilityList } = useMutation(api.getFacility, {
    onSuccess: (data) => {
      if (data.status == 200) {
        //console.log("bbbbbbbbbbbbbb",data.data.datalist);
        setFacilityData(data.data.datalist);
        setFacilityLevel(
          data.data.datalist.length ? data.data.datalist[0].FLevelId : 0
        );
        setAcFacilityData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
          
        });
 
      }
    },
  });

  const handleFacilityListChange = (event, newValue) => {
    setFacilityLevel(newValue.FLevelId);
    setAcFacilityData(newValue);
  };

  const currentDate = new Date();

  const MonthListlocal = JSON.parse(localStorage.getItem("MonthList"));
  let All_Month = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const MonthList = MonthListlocal; //[All_Month].concat();

  const YearListlocal = JSON.parse(localStorage.getItem("YearList"));
  let All_Year = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const YearList = YearListlocal;

  
  


  let previousMonth = currentDate.getMonth()+1 - 1;
  let previousYear = currentDate.getFullYear();
   
  if (previousMonth < 0) {
    // If the previous month is negative, subtract 1 from the year and set the month to 11 (December)
    previousMonth = 11;
    previousYear -= 1;
  }

  // Create a new Date object for the first day of the previous month
  const previousMonthDate = new Date(previousYear, previousMonth-1, 15);


   previousMonth = previousMonthDate.getMonth()+1 - 1;
   previousYear = previousMonthDate.getFullYear(); 
  if (previousMonth < 0) { 
    previousMonth = 11;
    previousYear -= 1;
  }
  const previousYearMonthDate = new Date(previousYear, previousMonth-11, 15);

   
  const [currmonthId, setmonthId] = useState(previousYearMonthDate.getMonth()+1); 
  const [currYearId, setYearId] = useState(previousYearMonthDate.getFullYear());

  const [currEndmonthId, setEndmonthId] = useState(previousMonthDate.getMonth()+1); 
  const [currEndYearId, setEndYearId] = useState(previousMonthDate.getFullYear());



  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
    }
  };

  const handleEndMonthChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currEndmonthId) {
      setEndmonthId(rowId);
    }
  };
  //year
  const handleYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);
    }
  };

  const handleEndYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currEndYearId) {
      setEndYearId(rowId);
    }
  };

  // const [selectedStartDate, setSelectedStartDate] = React.useState(
  //   moment().subtract(360, "days").format("YYYY-MM")
  // );
    /* Get saved filter value from layoutSettings */
// const StartDateFilter = getFilterValue(menukey, "StartDateFilter");
// const EndDateFilter = getFilterValue(menukey, "EndDateFilter");

 
  // ------------------Start & End Date start ------------------------
  const [selectedStartDate, setSelectedStartDate] = React.useState(
     moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
  moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };
 // ------------------Start & End Date start ------------------------
  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroupList"));
  // let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product_Group].concat(Product_Group);

  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);
  const [currProductId, setProductId] = useState();

  //toggole button
  const [toggleButtonValue, setToggleButton] = React.useState("1");



  const [isLoading, setLoading] = useState(false);

  exporting(Highcharts);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }
  };

  let params = {
    menukey: menukey,
    action: "OrderSatisfactionRateTable",
    lan: lan,
    RegionId: activeRegionData.id,
    DistrictId: activeCommuneData.id,
    FacilityId: activeFacilityData.id,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    YearId: currYearId,
    EndMonthId: currEndmonthId,
    EndYearId: currEndYearId,
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    toggleButtonValue: toggleButtonValue,
  };

  const { data } = useQuery([params], api.getOrderSatisfactionRateTableReport, {
    onSuccess: (data) => {
      let tmpfield = [];
      for (let i = 0; i < data.data.data.column.length; i++) {
        let fild = i.toString();
        if (i == 0)
          tmpfield.push({
            title: DispensingLanguage[lan][menukey][data.data.data.column[i]],
            field: data.data.data.column[i],
           // width: 170,
            hozAlign: "left",
            headerHozAlign: "left",
            headerSort: false,
          });
        else
          tmpfield.push({
            title:  DispensingLanguage[lan][menukey]['Order Satisfaction Rate (%)'],//data.data.data.column[i],
            field: data.data.data.column[i],
            width: 250,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
            formatter: function (cell, formatterParams) { 
              let value = cell.getValue(); 

              return value==0?'':value;
      
            },
          });
      }

      setfieldList(tmpfield);
      setDataItems(data.data.data.list);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const [gettablelanguage, settablelanguage] = React.useState(
    t(DispensingLanguage[lan][menukey]["Order satisfaction rate by level"])
  );

 
  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    
    let finalUrl = EXCEL_EXPORT_URL + "report/OrderSatisfactionRatePrint.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    window.open(
      finalUrl +"?menukey=" +menukey+
      
       "&RegionId=" +
       activeRegionData.id +
       "&DistrictId=" +
       activeCommuneData.id +
       "&ItemGroupId=" +
        currProductGroupId + 
        "&FacilityId=" +
        activeFacilityData.id + 
        "&MonthId=" +
        currmonthId + 
        "&YearId=" +
        currYearId + 
        "&EndMonthId=" +
        currEndmonthId + 
        "&EndYearId=" +
        currEndYearId + 
        "&toggleButtonValue=" +
        toggleButtonValue + 
        "&DepartmentName=" +
        activeRegionData.name +
        "&DistrictName=" +
        activeCommuneData.name +
        "&ProductGroup=" +
        currItemGroupname +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
       "&menukey=" +
       menukey +
       "&lan=" +
       localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
  
  const ExcelMany = () => {
    
    let finalUrl = EXCEL_EXPORT_URL + "report/OrderSatisfactionRateExcel.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    window.open(
      finalUrl +"?menukey=" +menukey+
      
       "&RegionId=" +
       activeRegionData.id +
       "&DistrictId=" +
       activeCommuneData.id +
       "&ItemGroupId=" +
        currProductGroupId + 
        "&FacilityId=" +
        activeFacilityData.id + 
        "&MonthId=" +
        currmonthId + 
        "&YearId=" +
        currYearId + 
        "&EndMonthId=" +
        currEndmonthId + 
        "&EndYearId=" +
        currEndYearId + 
        "&toggleButtonValue=" +
        toggleButtonValue + 
        "&DepartmentName=" +
        activeRegionData.name +
        "&DistrictName=" +
        activeCommuneData.name +
        "&ProductGroup=" +
        currItemGroupname +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
       "&menukey=" +
       menukey +
       "&lan=" +
       localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding sw_inner_container`}>
        <div className="sw_inner_card">
          <div className="d-flex justify-uiLanguage">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">{gettablelanguage}</div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={1}>



                  <Grid item xs={3} sm={2} style={{marginTop:'4px'}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          className={classes.fullWidth}
                          //disableToolbar
                          autoOk={true}
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={
                            DispensingLanguage[lan][menukey]["Start date"]
                          }
                          value={selectedStartDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disableFuture={true}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3} sm={2} style={{marginTop:'4px'}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          className={classes.fullWidth}
                          //disableToolbar
                          autoOk={true}
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={DispensingLanguage[lan][menukey]["End date"]}
                          value={selectedEndDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disableFuture={true}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                    {/* <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid> */}

                    {/* <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid> */}
{/*   
                    <Grid item xs={2} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroup"
                          disableClearable
                          options={ProductGroup}
                          //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                          onChange={(event, newValue) =>
                            handleProductGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            ProductGroup[
                              ProductGroup.findIndex(
                                (ProductGroup) =>
                                  ProductGroup.id == currProductGroupId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Product Group"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid> */}
                  </Grid>

                  <Grid container>
                    <Grid container spacing={1}>
                      {/* <Grid item xs={2} sm={2} style={{marginTop:'15px'}}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="MonthList"
                            disableClearable
                            options={MonthList}
                            onChange={(event, newValue) =>
                              handleMonthChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              MonthList[
                                MonthList.findIndex(
                                  (MonthList) => MonthList.id == currmonthId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["Month"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid> */}

                      {/* <Grid item xs={2} sm={2} style={{marginTop:'15px'}}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="YearList"
                            disableClearable
                            options={YearList}
                            onChange={(event, newValue) =>
                              handleYearChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              YearList[
                                YearList.findIndex(
                                  (YearList) => YearList.id == currYearId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={DispensingLanguage[lan][menukey]["Year"]}
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid> */}

                      {/* <Grid item xs={2} sm={2} style={{marginTop:'15px'}}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="MonthList"
                            disableClearable
                            options={MonthList}
                            onChange={(event, newValue) =>
                              handleEndMonthChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              MonthList[
                                MonthList.findIndex(
                                  (MonthList) => MonthList.id == currEndmonthId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["End Month"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid> */}
{/*   
                      <Grid item xs={2} sm={2} style={{marginTop:'15px'}}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="YearList"
                            disableClearable
                            options={YearList}
                            onChange={(event, newValue) =>
                              handleEndYearChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              YearList[
                                YearList.findIndex(
                                  (YearList) => YearList.id == currEndYearId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["End Year"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={gettablelanguage}
                  action={
                    <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div> 
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={fieldList}
                          data={dataItems}
                          layout={"fitColumns"}
                          height={600}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSatisfactionRateByLevel;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});

// const useStyles = makeStyles({
//   dispenserContainer: {
//     backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
//     backgroundSize: "cover",
//     backgroundPosition: "top center",
//     minHeight: "745px",
//   },
//   dispenserPageTitle: {
//     marginTop: "60px",
//     color: "white",
//     background: "whitesmoke",
//     color: "black",
//     borderRadius: "10px",
//     padding: "1rem",
//   },
//   tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
//   fullWidth: {
//     width: "95%",
//   },
//   filterDiv: {
//     width: "80%",
//     display: "flex",
//   },
// });
