import React from "react";

// Auth token & services
import * as Service from "../services/Service.js";



//Css import
import "assets/css/custom.css";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import HomePage from "./screens/HomePage";
import packageJson from "../../package.json";
import swal from "sweetalert";


function Index(props) {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });


  

const clearCacheData = () => {
  caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  });
   
  setTimeout(function(){
    window.location.reload();
 }, 1000);


};


if(localStorage.getItem("sw_Version")==null){
    

  swal({
    title: "",
    text:   'A new version of the application is available.'+', Version-'+`${process.env.REACT_APP_SW_Version}`,
    icon: "warning",
    button: 'Refresh',
    showCancelButton: false,
    showConfirmButton: false
    }).then((willDelete) => {
    if (willDelete) { 
      localStorage.setItem(
        "sw_Version",
        `${process.env.REACT_APP_SW_Version}`
      );
      window.location.href = `${process.env.REACT_APP_BASE_NAME}`;
      clearCacheData(); 
      
    }
  });



 }else if(localStorage.getItem("sw_Version")<`${process.env.REACT_APP_SW_Version}`){ 
  

  swal({
    title: "",
    text:  'A new version of the application is available, press Refresh to update from ['+localStorage.getItem("sw_Version")+'] to ['+`${process.env.REACT_APP_SW_Version}`+']',
    icon: "warning",
    button: 'Refresh',
    showCancelButton: false,
    showConfirmButton: false
    }).then((willDelete) => {
    if (willDelete) { 
      localStorage.setItem(
        "sw_Version",
        `${process.env.REACT_APP_SW_Version}`
      );
      window.location.href = `${process.env.REACT_APP_BASE_NAME}`;
      clearCacheData(); 
    }
  });

 } 
  return (
    <>
      {Service.default.authToken() != null ? (
        <AfterLoginNavbar {...props} />
      ) : (
        <IndexNavbar {...props} />
      )}
      <div className="wrapper">
        <IndexHeader {...props} />
        <div className="main">
          <HomePage {...props}  />
        </div>
        <DarkFooter  {...props}  />
      </div>
    </>
  );
}

export default Index;
