import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

//get DispensingLanguage

const CountryEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  Typography,
  ...props
}) => {
  const classes = useStyles();
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "country-entry";
  const PickListMethodList = JSON.parse(localStorage.getItem("gPickListMethod"));
  const CurrencyList = JSON.parse(localStorage.getItem("CurrencyList"));
  const LinkAdjTypeList = JSON.parse(localStorage.getItem("LinkAdjTypeList"));

  const AStoreList = JSON.parse(localStorage.getItem("getStore"));
  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Country Entry Form - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Country Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.CountryCode}
                          helperText={errorObject.CountryCode}

                          id="CountryCode"
                          name="CountryCode"
                          label={DispensingLanguage[lan][menukey]['Country Code']}
                          value={formData.CountryCode}
                          fullWidth
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.CountryName}
                          helperText={errorObject.CountryName}

                          id="CountryName"
                          name="CountryName"
                          label={DispensingLanguage[lan][menukey]['Country Name']}
                          value={formData.CountryName}
                          fullWidth
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.CenterLat}
                          helperText={errorObject.CenterLat}

                          id="CenterLat"
                          name="CenterLat"
                          label={DispensingLanguage[lan][menukey]['Center Latitude']}
                          value={formData.CenterLat}
                          fullWidth
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.CenterLong}
                          helperText={errorObject.CenterLong}

                          id="CenterLong"
                          name="CenterLong"
                          label={DispensingLanguage[lan][menukey]['Center longitude']}
                          value={formData.CenterLong}
                          fullWidth
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.ZoomLevel}
                          helperText={errorObject.ZoomLevel}

                          id="ZoomLevel"
                          name="ZoomLevel"
                          label={DispensingLanguage[lan][menukey]['Zoom Level']}
                          value={formData.ZoomLevel}
                          fullWidth
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Currency Name"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.MenuKey}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="CurrencyId"
                            value={formData.CurrencyId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            {CurrencyList.map((item, index) => {
                              return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          <FormHelperText error={errorObject.CurrencyId}>
                            {errorObject.CurrencyId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.LMISReportExpectedDate}
                          helperText={errorObject.LMISReportExpectedDate}

                          id="LMISReportExpectedDate"
                          name="LMISReportExpectedDate"
                          label={DispensingLanguage[lan][menukey]['Report Expected Date']}
                          value={formData.LMISReportExpectedDate}
                          fullWidth
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 50 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                    </Grid>
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button> */}
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Submit']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/country-entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/country-entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div >
  );
};

export default CountryEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
