import React, { useEffect, useState } from "react";//, 
import { useParams } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
let adverseModalDirty=false;
const AdverseEventModal = ({ handleClose, openOrder, ...props }) => {

  const classes = useStyles();

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "aefi";

  const { id } = useParams();
  const queryClient = useQueryClient();

  const getSeverityList = JSON.parse(localStorage.getItem("getSeverityList"));
  const getStopTreatmentList = JSON.parse(localStorage.getItem("getStopTreatmentList"));
  const getReadministrationList = JSON.parse(localStorage.getItem("getReadministrationList"));

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [isSerious, setIsSerious] = useState(true);
  const [errorObject, setErrorObject] = useState({});

  const [adverseEventFormData, setAdverseEventFormData] = useState({
    AdverseEvent: "",
    Serious: "",
    SeverityId: "",
    DateOfBeginning: "",
    EndDate: "",
    StopTreatmentId: "",
    ReAdministrationId: "",
    CorrectiveTreatment: "",
  });

  const handleReset = () => {
    setAdverseEventFormData({
      AdverseEvent: "",
      Serious: "",
      SeverityId: "",
      DateOfBeginning: "",
      EndDate: "",
      StopTreatmentId: "",
      ReAdministrationId: "",
      CorrectiveTreatment: "",
    });
  };

  const onManySetAdverseDirty = () => {   
    if(adverseModalDirty==true){ 
      document.getElementById("adverse_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("adverse_dirty_message").classList.add("dnone");
    }
  }

  const { mutate } = useMutation(api.saveEditAdverseEventData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        
        handleClose('AdverseEventModal');
        props.refetchAdverseData(props.formData.AefiMasterId);
        handleReset();

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...adverseEventFormData };
    data[name] = value;

    setAdverseEventFormData(data);

    setErrorObject({ ...errorObject, [name]: null });

    adverseModalDirty=true;
    onManySetAdverseDirty();
  };

  const handleRDateChange = (date, field) => {

    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }

    setAdverseEventFormData({ ...adverseEventFormData, [field]: newDate });
    adverseModalDirty=true;
    onManySetAdverseDirty();
  };

  const validateForm = (formData) => {
    let validateFields = ["AdverseEvent"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })

    setErrorObject(errorData);
    return isValid
  }

  const handleSubmit = async (e) => {

    let fDtat = {
      ...adverseEventFormData,
      menukey: menukey,
      lan: lan,
      UserName: UserName,
      AefiMasterId: props.formData.AefiMasterId,
    };

    if (validateForm(adverseEventFormData)) {
      mutate(fDtat);
    }
  };

  useEffect(() => {

    if (props.formDataForAdverseEvent) {
      setAdverseEventFormData(props.formDataForAdverseEvent);
    }
  }, [props.formDataForAdverseEvent]);

  useEffect(() => {
    if (adverseEventFormData.Serious !='') {
      if(adverseEventFormData.Serious == 1){
        setIsSerious(false);
      }else{
        setIsSerious(true);
        adverseEventFormData.SeverityId = '';
      }
    }else{
      setIsSerious(true);
      adverseEventFormData.SeverityId = '';
    }
  }, [adverseEventFormData.Serious]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('AdverseEventModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('AdverseEventModal')}
        >
          {DispensingLanguage[lan][menukey]['Adverse_Event']}
        </BootstrapDialogTitle>
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={10}>
              <Grid item xs={4} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                  <TextField
                    error={errorObject.AdverseEvent}
                    helperText={errorObject.AdverseEvent}
                    required
                    id="AdverseEvent"
                    name="AdverseEvent"
                    label={DispensingLanguage[lan][menukey]["Adverse_Event"]}
                    value={adverseEventFormData.AdverseEvent}
                    fullWidth
                    autoComplete="family-name"
                    onChange={(e) => handleChange(e)}
                  />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                  <FormControl
                    className="sw_radiogroup"
                    component="fieldset"
                  >
                    <FormLabel component="legend">
                      {DispensingLanguage[lan][menukey]["Serious_yes_no"]}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-label="Serious"
                      name="Serious"
                      value={+adverseEventFormData.Serious}
                      onChange={(e) => handleChange(e)}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={DispensingLanguage[lan][menukey]["yes"]}
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label={DispensingLanguage[lan][menukey]["no"]}
                      />
                    </RadioGroup>
                  </FormControl>
                  </Grid>
                  
                  <Grid item xs={4} sm={4}>
                  {!isSerious ? (
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Severity reason"]}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="SeverityId"
                      name="SeverityId"
                      value={adverseEventFormData.SeverityId}
                      fullWidth
                      onChange={(e) => handleChange(e)}
                    >
                      {getSeverityList.map((item, index) => {
                        return (
                          <MenuItem
                            key={`SeverityId-${index + 1}`}
                            value={item.SeverityId}
                          >
                            {item.SeverityName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  ) : (<></>)}
                  </Grid>

                  <Grid item xs={6} sm={6}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={DateFnsUtils}
                    locale={lan == "en_GB" ? enGB : fr}>
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      variant="inline"
                      id="DateOfBeginning"
                      label={DispensingLanguage[lan][menukey]["Date of beginning"]}
                      autoOk={true}
                      name="DateOfBeginning"
                      fullWidth
                      showTodayButton={true}
                      value={adverseEventFormData.DateOfBeginning || null}
                      format="dd/MM/yyyy"
                      onChange={(e) => handleRDateChange(e, "DateOfBeginning")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={DateFnsUtils}
                    locale={lan == "en_GB" ? enGB : fr}>
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      variant="inline"
                      id="EndDate"
                      label={DispensingLanguage[lan][menukey]["End date"]}
                      autoOk={true}
                      name="EndDate"
                      fullWidth
                      showTodayButton={true}
                      value={adverseEventFormData.EndDate || null}
                      format="dd/MM/yyyy"
                      onChange={(e) => handleRDateChange(e, "EndDate")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} sm={6} className="marginBottom20">
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Evolution_stopp_treatment"]}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="StopTreatmentId"
                      name="StopTreatmentId"
                      value={adverseEventFormData.StopTreatmentId}
                      fullWidth
                      onChange={(e) => handleChange(e)}
                    >
                      {getStopTreatmentList.map((item, index) => {
                        return (
                          <MenuItem
                            key={`StopTreatmentId-${index + 1}`}
                            value={item.StopTreatmentId}
                          >
                            {item.StopTreatmentName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6} className="marginBottom20">
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Evolution_administration"]}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="ReAdministrationId"
                      name="ReAdministrationId"
                      value={adverseEventFormData.ReAdministrationId}
                      fullWidth
                      onChange={(e) => handleChange(e)}
                    >
                      {getReadministrationList.map((item, index) => {
                        return (
                          <MenuItem
                            key={`ReAdministrationId-${index + 1}`}
                            value={item.ReAdministrationId}
                          >
                            {item.ReAdministrationName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6} className="marginBottom20">
                    <TextField
                      id="CorrectiveTreatment"
                      name="CorrectiveTreatment"
                      label={
                        DispensingLanguage[lan][menukey]["Corrective treatment"]
                      }
                      value={adverseEventFormData.CorrectiveTreatment}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <DialogActions>
          <div className="mr-4 mt-2 float-left">
            <span id="adverse_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
          </div>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {DispensingLanguage[lan][menukey]['Save']}
          </Button>
          <Button autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose('AdverseEventModal')}>
            {DispensingLanguage[lan][menukey]['Cancel']}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AdverseEventModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});