import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Constants from "../../../services/Constants";
import { checkLogin, checkUserPermission, getUserActionPermission } from "../../../services/CheckUserAccess";

import swal from "sweetalert";
// Ui Language screen
import MonthClose from "./MonthClose";
//import UiLanguageAdd from "./AddUiLanguage";
//import UiLanguageEdit from "./EditUiLanguage";

import EditMonthClose from "./EditMonthClose";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "month-close";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  /*   const checkLogin = () => {  
      let token = sessionStorage.getItem("token");
      if (!token) {
        swal({
          title: "Oops!",
          text: 'token expired. Please login again',
          icon: "warning",
          buttons: ["No", "Yes"],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) { 
            window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
            sessionStorage.clear();
          }
        });
      }
    }; */

  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");
  //   // let token = Constants.token;
  //   // console.log(Constants.token);
  //   if (!token) {
  //     swal("Oops!", `token expired. Please login again`, "error");
  //     props.history.push("/login");
  //     sessionStorage.clear();
  //   }
  // };

  // const checkAccess = () => {
  //  // let auth = JSON.parse(sessionStorage.getItem("User_info"));
  //   let auth = Constants.USER_INFO;


  //   console.log("auth");
  //   console.log(auth);
  //   if (auth) {
  //     let menu = auth[0].menu;
  //     console.log("menu: "+menu);
  //     if (menu.length > 0) {
  //       var check = menu.filter(name => name.title.includes('Admin'));

  //       console.log("check: "+check);

  //       if(check.length == 0){
  //         // console.log("auth1: "+menu[0].url);
  //        props.history.push(menu[0].url);
  //       }
  //     } else {
  //       swal("Oops!", `You don't have permission to access any page`, "error");
  //       props.history.push("/login");
  //       sessionStorage.clear();
  //     }
  //   }
  // };

  return (

    hasUserPermission && (
      <div>
        <AfterLoginNavbar {...props} />
        <div className="section signup-top-padding sw_inner_container">
          <Switch>
            {/*<Route path={`${path}/add`} component={UiLanguageAdd}></Route>*/}
            {/*<Route path={`${path}/edit/:id`} component={UiLanguageEdit}></Route>*/}
            <Route path={`${path}/edit/:FacilityId/:MonthId/:currYearId`} component={EditMonthClose}></Route>
            <Route path={`${path}/`} component={MonthClose}></Route>
      
            {/* <Route
              path={`${path}/edit/:id`}
              render={(props) => (
                <EditMonthClose {...props} />
              )}
            ></Route> */}
            
          </Switch>
        </div>
      </div>)
  );
};

export default Index;

const useStyles = makeStyles({

});
