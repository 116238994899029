import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";


import { useQuery, useMutation } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


const BootstrapDialogTitle = (props) => {



  const { children, onClose, ...other } = props;



  return (

    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const FacilityGroupMapEditModal = ({ ...props }) => {

  //console.log("FacilityId:===",props.match.params.id);

//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "facility";

const MonthList = JSON.parse(localStorage.getItem("MonthList"));
const YearList = JSON.parse(localStorage.getItem("YearList"));

 
    const classes = useStyles();
    
    //const [GroupList, setGroupList] = useState(props.GroupList);
    const [errorObject, setErrorObject] = useState({});
    const [formData, setFormData] = useState({
      MonthId:"",      
      YearID:"",
      InitialDate:"",
      InitComplete:1
      });  
    const onChange = (event) => {
      
       
        if(event.target.name=='MonthId'){
          if(formData['YearID']!=''){
            setErrorObject({ ...errorObject, [event.target.name]: null,["InitialDate"]: null });
            setFormData({ ...formData, [event.target.name]: event.target.value, ['InitialDate']: '01/'+event.target.value+'/'+formData['YearID'] });         

          }
           else{
            setErrorObject({ ...errorObject, [event.target.name]: null});
            setFormData({ ...formData, [event.target.name]: event.target.value });


           }
          
        }
        if(event.target.name=='YearID'){
          if(formData['MonthId']!=''){
            setErrorObject({ ...errorObject, [event.target.name]: null,["InitialDate"]: null });
            setFormData({ ...formData, [event.target.name]: event.target.value, ['InitialDate']: '01/'+formData['MonthId']+'/'+event.target.value });
        
          }
          else{
            setFormData({ ...formData, [event.target.name]: event.target.value });
            setErrorObject({ ...errorObject, [event.target.name]: null});
          }
          

        }
        

        
        // props.FacilityServiceId.SupplyFrom=event.target.value;
        // setErrorObject({ ...errorObject, [event.target.name]: null });
 
    };

    
    const handleSubmit = async (e) => { 
        if (validateForm(formData)) { 
          props.handleClose('closeInitialDateWithOk',formData)  
        }
    };
    
    const validateForm = (formData) => {
      let validateFields = ["MonthId", "YearID", "InitialDate"];
      let errorData = {};
      let isValid = true;
      validateFields.map((field) => {
        if (!formData[field]) {
          errorData[field] =
            DispensingLanguage[lan][menukey]["field is Required !"];
          isValid = false;
        }
      });
      setErrorObject(errorData);
      return isValid;
    };
  
     
  return (
    <div>
      <BootstrapDialog
        onClose={() => props.handleClose('closeInitialDate',{})}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.handleClose('closeInitialDate',{})}
        >
         {DispensingLanguage[lan][menukey]['Facility Initialization Date']}
          
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            
              
            <Grid item xs={3} sm={3}>


              <FormControl className={classes.fullWidth}>
                
                <InputLabel id="MonthId-label">
                  {DispensingLanguage[lan][menukey]["Start Month"]}<span className="sw_star">*</span>

                </InputLabel>
                <Select
                  error={errorObject.MonthId}
                  helperText={errorObject.MonthId}
                  label={DispensingLanguage[lan][menukey]["Start Date"]}
                  required
                  labelId="MonthId-label"
                  id="MonthId"
                  name="MonthId"
                  value={formData.MonthId}
                  fullWidth
                  onChange={(e) => onChange(e)}
                >
                  <MenuItem value="0">
                    {DispensingLanguage[lan][menukey]["Select Month"]}

                  </MenuItem>

                  {
                    MonthList.map((item, index) => {
                      return (
                        <MenuItem key={`MonthList-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText error={errorObject.MonthId}>
                  {errorObject.MonthId}
                </FormHelperText>
              </FormControl>

              </Grid>


            
            <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="YearID-label">
                    {DispensingLanguage[lan][menukey]["Start Year"]}<span className="sw_star">*</span>


                  </InputLabel>
                  <Select
                    error={errorObject.YearID}
                    helperText={errorObject.YearID}
                    required
                    labelId="YearID-label"
                    id="YearID"
                    name="YearID"
                    value={formData.YearID}
                    fullWidth
                    onChange={(e) => onChange(e)}
                  >
                    <MenuItem value="">
                      {DispensingLanguage[lan][menukey]["Select Year"]}

                    </MenuItem>

                    {YearList.map((item, index) => {
                      return (
                        <MenuItem key={`YearList-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={errorObject.YearID}>
                    {errorObject.YearID}
                  </FormHelperText>
                </FormControl>

              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  hidden={true}
                  error={errorObject.InitialDate}
                  helperText={errorObject.InitialDate}
                  id="InitialDate"
                  name="InitialDate"
                  label={DispensingLanguage[lan][menukey]['LMIS Start Date']}
                  value={formData.InitialDate}
                  fullWidth
                  autoComplete="family-name" 
                />
              </Grid> 
            </Grid> 

            
          

        </DialogContent>
        <DialogActions   style={{ justifyContent: 'center' }}>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {DispensingLanguage[lan][menukey]["OK"]}
            
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => props.handleClose('closeInitialDate',{})}
          >
            {DispensingLanguage[lan][menukey]["CANCEL"]}
            
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default FacilityGroupMapEditModal;


const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  facilitygroupmapTitle: {
    marginTop: "40px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});


