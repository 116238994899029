import { useState } from "react";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import "../../views/screens/common.css";

const useModalPopupDelete = ({ menukey11 }) => {
  const [bDeleteConfirm, setDeleteConfirm] = useState(false);

  // console.log("menukey: ", menukey);
  const { t } = useTranslation();

  const lan = localStorage.getItem("LangCode");
  // console.log("lan: ", lan);

  const langList = JSON.parse(localStorage.getItem("DispensingLanguage"));
  // console.log("langList: ", langList);
  const menukey= "common";

  const modalPopupDelete = (callback, params, langParam, callbackNo, paramsNo) => {
    swal({
      title: t(langList[lan][menukey]["modalQuestionLine1"]),
      text: t(
        langList[lan][menukey][langParam.PostQ]
      ),
      icon: "warning",
      buttons: [
        t(langList[lan][menukey]["No"]),
        t(langList[lan][menukey]["Yes"]),
      ],
      dangerMode: true,
      className: "modalPopupPost",
    }).then((isConfirm) => {
      setDeleteConfirm(isConfirm ?? false);
      if (isConfirm) {
        callback(params);
      } else {
        callbackNo(paramsNo);
      }
    });
  };

  return { bDeleteConfirm, modalPopupDelete };
};

export default useModalPopupDelete;
