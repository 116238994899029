import React, { useState, useEffect } from "react";
import {
  useQuery,
  useMutation,
} from 'react-query'
import { useParams } from 'react-router-dom';
import swal from "sweetalert";
import * as api from '../../../actions/api';
import AdjustmentFormData from './AdjustmentFormData';
import { useTranslation } from "react-i18next";
import moment from "moment";

const EditAdjustment = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "adjustment";
  const FacilityId = localStorage.getItem('FacilityId');

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const [ItemGroupId, setItemGroupId] = useState(0);

  const { t, } = useTranslation();
  const [formData, setFormData] = useState({
    TransactionDate: "",
    AdjTypeId: "",
    TransactionNo: "",
    "FacilityId": FacilityId,
    TransactionId: "",
    IssueToFacility: "",
    //ItemGroupId:"",
    Remarks:"",

  });
  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
  );
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);
  const [isPositive, setIsPositive] = useState(true);
  const [pickListMethod, setPickListMethod] = useState();
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );
  const [DateTransactionChange, setDateTransactionChange] = useState("");
  const [bFirst, setBFirst] = useState(true);

  const handleRDateChange = (date, field) => {

    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }

    if(field=="TransactionDate"){

      if(moment(newDate).format("YYYY")!=moment(DateTransactionChange).format("YYYY")){
        
        //setTimeout(() => function(){
          mutateInv({"queryKey":[{ 
          menukey: "adjustment",
          FacilityId: FacilityId,
          TransactionTypeId: 3,
          TransactionDate: moment(newDate).format("DD/MM/YYYY"),
          ChangeTransactionDate:newDate
           }]});
         //}, 500);
  
      }else{
          setGeneratedInvoiceNo(generatedInvoiceNo);
          setFormData({ ...formData, [field]: newDate, ["TransactionNo"]: generatedInvoiceNo });
      }
  
      }else{
          setFormData({ ...formData, [field]: newDate});
      }

    //setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };


  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();

  const { refetch } = useQuery(['receive', id, ItemGroupId], () =>
    api.getAdjustmentInv({ TransactionId: id, ItemGroupId: ItemGroupId }).then((res) => {

      setTransactionId(id);
      setFormData(res.data);
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo);
      setDateTransactionChange(res.data.TransactionDate);
      let IsPositive = res.data.IsPositive == "-1" ? true : false;
      setIsPositive(IsPositive);
      setPickListMethod(res.data.PickListMethod);
      setBFirst(true);
      return res.data
    }), {
    refetchOnWindowFocus: false,
    enabled: Boolean(id)
  })

  /*
  const { mutate: mutateUpdate } = useMutation(api.updateAdjustmentInv, {
    onSuccess: (data) => {
      if (data.status == 200) {

        if (data.bStockUpdated == 1) {
          mutatePost(TransactionId);

        } else {

          refetch();
          setDeletedDataSet([]);

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { mutate: mutatePost } = useMutation(api.updateAdjStock, {
    onSuccess: (data) => {

      if (data.status == 200) {

        setbStockUpdate(data.bStockUpdated);
        setDeletedDataSet([]);

        if (data.success == 0) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        } else {

          refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
            duration: 10000,
          });
        }
      }
    }
  })
  */

  const {  mutate:mutateInv } = useMutation(api.getInvoiceNo, {
    onSuccess: (data,params) => {
      //moment(params.queryKey[0].TransactionDate).format("YYYY-MM-DD")
      console.log("n paras",params.queryKey[0].ChangeTransactionDate);
      setFormData(
        { ...formData, 
        ["TransactionDate"]: params.queryKey[0].ChangeTransactionDate,
        ["TransactionNo"]:data 
        });
    }
  })

  const handleAdd = (NonOrderProductData) => {

    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);

  };
  const handleChange = (e) => {

    if (e == 'IssuedToFacility') {
      setErrorObject({ ...errorObject, ['IssueToFacility']: null });
    }
    else {

      const { name, value } = e.target;
      let data = { ...formData };
      data[name] = value;
      setFormData(data);
      setErrorObject({ ...errorObject, [name]: null });
    }
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "",
      "FacilityId": FacilityId,
      TransactionId: "",
      Remarks:"",

    });
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "TransactionNo", "IssueToFacility"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const deleteAdjustmentInv = (data) => {
    let newarray = ManyTableData.filter(element => element != data);

    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if (data.TransactionItemId != null) {
      let newDeletedDataSet = [data.TransactionItemId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
    }


  }

  /*
  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat = { ...formData, 'ManyJsonSave': ManyTableData, 'deletedDataSet': deletedDataSet, UserName: UserName };
      mutateUpdate(fDtat);
    }
  };


  const handlePost = async (rows) => {

    console.log("jasffff");
    if (validateForm(formData)) {

      // Start Post Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]),
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
          let fDtat = { ...formData, 'ManyJsonSave': rows.data, "bStockUpdated": 1, 'deletedDataSet': deletedDataSet };
          mutateUpdate(fDtat);

        } else {

          console.log("Cancel");
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert
    }
  };


  const setUpdatedDetailsGridDataHandler = (rows) => {

    if (rows.datatype == "FormData") {
      setFormData(rows.IssueToFacility);
    } else {
      if (validateForm(formData)) {
        let fDtat = {
          ...formData,
          ManyJsonSave: rows.data,
          menukey: "adjustment",
          'deletedDataSet': deletedDataSet,
          UserName: UserName
        };
        mutateUpdate(fDtat);
      }
      setManyTableData(rows.data);
    }

  };
  */

  const updateIspositive = (isPosVal) => {
    setIsPositive(isPosVal);

  };
  useEffect(() => {


  }, [isPositive, pickListMethod, deletedDataSet]);

  const filterFandleChange = (e) => {

    setItemGroupId(e);
  };

  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
  }

  function checkValidateForm(rows, callfrom){

      if(callfrom == "edit"){
        setManyTableData(rows); 
      }
  
      // return validateForm(formData);
      if (validateForm(formData)) {
          let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet, menukey: menukey,lan: lan,"UserName":UserName}; 
          //mutateUpdate(fDtat); 
          return fDtat;
        }else{
          return false;
        }


  };

  function refetchInvoice(){
      refetch();
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };

  function setFormDataForAdjust(val){
    setFormData(val);
  };

  return (
    <>
      <AdjustmentFormData

        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        //handleUpdate={handleUpdate}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        handleAdd={handleAdd}
        bStockUpdate={bStockUpdate}
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        //handlePost={handlePost}
        deleteAdjustmentInv={deleteAdjustmentInv}
        generatedInvoiceNo={generatedInvoiceNo}
        DisableAddBtn={false}
        isPositive={isPositive}
        updateIspositive={updateIspositive}
        pickListMethod={pickListMethod}
        bFirst={bFirst}
        filterFandleChange={filterFandleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}

        checkValidateForm={checkValidateForm}
        refetchInvoice={refetchInvoice}
        stockPosted={stockPosted}
        setFormDataForAdjust={setFormDataForAdjust}
        
        {...props} />
    </>
  );
};

export default EditAdjustment;