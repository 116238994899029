import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  FormControl,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useQuery } from "react-query";
import * as api from "../../../actions/api";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";
//get DispensingLanguage

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OrderDetailsModal = ({ handleClose, open, OrderId ,...props}) => {
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "order";

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroupList = [All_Product].concat(Product_Group);

  const [activeProductGroupData, setAcProductGroupData] = useState({
    id: ProductGroupList.length > 0 ? ProductGroupList[0].id : "",
    name: ProductGroupList.length > 0 ? ProductGroupList[0].name : "",
  });

  const handleProductGroupChange = (event, newValue) => {
    setAcProductGroupData(newValue);
  };

  let tblLotRef = null;

  const [tabledata, setTabledata] = useState([]); 

  let params = {
    action: "ShowPendingOrderDetails",
    menukey: menukey,
    //FacilityId: FacilityId,
    OrderId: OrderId,
    ItemGroupId: activeProductGroupData.id,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getPendingOrderDetails,{
      onSuccess: (data) => {
        //console.log("tttttttttttttt HHHHHHHHHH",data);
        setTabledata(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



 

  const OrderDetailsColumns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      hozAlign: "center",
      formatter: "rownum",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 135,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
      // width: 200
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 80,
    },
    {
      title: DispensingLanguage[lan][menukey]["Min Qty"],
      field: "MinQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Max Qty"],
      field: "MaxQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock on Hand"],
      field: "StockOnHand",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Calculated Order Quantity"],
      field: "CalcOrderQty",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actual Order Quantity"],
      field: "ActualOrderQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Comment"],
      field: "Remarks",
      width: 110,
      hozAlign: "left",
      headerHozAlign: "left",
    },
  ];



  useEffect(() => {
    if (tblLotRef == null) return;
  }, []);
  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose("closeorderdetails")}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("closeorderdetails")}
        >
          {DispensingLanguage[lan][menukey]["Pending Order Details"]}
        </BootstrapDialogTitle>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroupList"
                      disableClearable
                      options={ProductGroupList}
                      value={activeProductGroupData}
                      onChange={(event, newValue) =>
                        handleProductGroupChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            DispensingLanguage[lan][menukey]["Product Group"]
                          }
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <DialogContent dividers>
          <ReactTabulator
            ref={(rf) => (tblLotRef = rf)}
            options={{
              groupBy: ["GroupName"]
            }}
            columns={OrderDetailsColumns}
            data={tabledata}
            height="300px"
            layout={"fitColumns"}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose('closeorderdetails')}
          >
            {DispensingLanguage[lan][menukey]['Cancel']}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default OrderDetailsModal;

const useStyles = makeStyles({
  fullWidth: {
    width: "95%",
  },
});
