import React, { useState} from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
// import swal from "sweetalert";
// import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import UserEntryFormData from "./UserEntryFormData.js";


const UserEntryAdd = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "user-entry";
//const FacilityId = localStorage.getItem("FacilityId");
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    loginname: "",
    password: "",
    designation: "",
    LangCode: "fr_FR",
    role_id: "",
    FacilityId: "",
    IsActive: false,
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();
  // const FacilityList = JSON.parse(localStorage.getItem("FacilityList"));
  useQuery(
    ["userlist", id],
    () =>
      api.getUser(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { isLoading, mutate } = useMutation(api.saveUser, {
  //   onSuccess: (data) => {
  //       api.getAllDropdown('UserList').then((response) => {
  //         localStorage.setItem(
  //           "UserList",
  //           JSON.stringify(response.datalist.UserList)
  //         );
  //       }) 
		
	//     api.getAllDropdown('UserListEdit').then((response) => {
  //         localStorage.setItem(
  //           "UserListEdit",
  //           JSON.stringify(response.datalist.UserListEdit)
  //         );
  //       })   
  //     queryClient.getQueriesData("userlist");
  //     props.history.push("/user-entry");
  //   },
  // });


  const {  mutate } = useMutation(api.saveUser, {
    onSuccess: (data) => {
      console.log("data: ", data);

      if (data.status == 200) {
        
        api.getAllDropdown('UserList').then((response) => {
          localStorage.setItem(
            "UserList",
            JSON.stringify(response.datalist.UserList)
          );
        }) 
		
	    api.getAllDropdown('UserListEdit').then((response) => {
          localStorage.setItem(
            "UserListEdit",
            JSON.stringify(response.datalist.UserListEdit)
          );
        }) 

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("userlist");
        props.history.push("/user-entry");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("userlist");
        props.history.push("/user-entry");
      }
    },
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      name: "",
      email: "",
      loginname: "",
      password: "",
      designation: "",
      LangCode:  "fr_FR",
      role_id: "",
      FacilityId: "",
      IsActive: false,
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "name",
      "email",
      "loginname",
      "password",
      "designation",
      "LangCode",
      "role_id",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }

      if (formData["loginname"].indexOf(" ") >= 0) {
        errorData["loginname"] =
          DispensingLanguage[lan][menukey][
            "White space is not allowed in login name."
          ];
        isValid = false;
      }


       //-----start confirm change password-----
       let cpassword = '';
       let cconfirmChangePassword = '';
 
       if(formData["password"]){
         cpassword = formData["password"].trim();
       }else{
         cpassword = '';
       }
 
       if(formData["confirmChangePassword"]){
         cconfirmChangePassword = formData["confirmChangePassword"].trim();
       }else{
         cconfirmChangePassword = '';
       }
      
       if (cpassword != ''){
 
         //let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'); //with 8 char validation
        /*  let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])');
         let passStrengthCheck = strongPassword.test(cpassword);
         */
         /* if(passStrengthCheck){ */
               if (cconfirmChangePassword == ''){
                   errorData["confirmChangePassword"] = DispensingLanguage[lan][menukey]["Enter Confirm Password"];
                   isValid = false;
               }else if (cpassword != cconfirmChangePassword){
                 errorData["confirmChangePassword"] = DispensingLanguage[lan][menukey]["Password did not match"];
                 isValid = false;
               } else{
                 errorData["confirmChangePassword"] = '';
               }
        /*  }else{
              errorData["password"] = DispensingLanguage[lan][menukey]["Password format check"];
              isValid = false;
         } */
        
 
       } 
 
       //-----end confirm change password-----


    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
     // swal("Success!", "", "success");
    }
  };

  return (
    <>
      <UserEntryFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default UserEntryAdd;
