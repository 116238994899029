import React, { useEffect, useState } from "react"; //useState,
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

import { makeStyles } from "@material-ui/core/styles";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ForecastModal = ({ handleClose,addForecast, openIssue, ...props }) => {
  const classes = useStyles();
  ///console.log('dfdfdfdfdf===',ItemGroupId);
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const FacilityId = localStorage.getItem("FacilityId");
  const lan = localStorage.getItem("LangCode");
  const menukey = "forecasted-consumption-entry";
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const currentDate = new Date();
  let previousMonth = currentDate.getMonth() + 1 - 1;
  let previousYear = currentDate.getFullYear();
  const previousMonthDate = new Date(previousYear, previousMonth - 1, 15);
  const [currYearId, setYearId] = useState(previousMonthDate.getFullYear());

  const { mutate } = useMutation(api.saveForecastConsumption, {
    onSuccess: (data) => {
      if (data.status == 200) {
        //console.log("RRRR", data.message);
        addForecast(data);
        handleClose("ForecastModal");
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }

    
      
    },
  });
  const SaveSelectedIssue = () => {
    mutate({'YearId':currYearId,'CountryId':1});
  };

  // useEffect(() => {
  //   refetch();
  //   if (tblIssueRef == null) return;
  // }, [openIssue]);

  // Carton GTIN Change
  const handleYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose("ForecastModal")}
        aria-labelledby="customized-dialog-title"
        open={openIssue}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("ForecastModal")}
        >
          {DispensingLanguage[lan][menukey]["Year List (Mali)"]}
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent center">
            <Grid container>
              {DispensingLanguage[lan][menukey]["Please, select year for generate forecasted consumption"]}
            </Grid>
            </CardContent>
          </Card>
          <br></br>
          <Grid container > 
            <Grid item xs={12} sm={12} className={classes.centerContainer}>
            
              <Grid item xs={4} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="YearList"
                    disableClearable
                    options={YearList}
                    onChange={(event, newValue) =>
                      handleYearChange(event, newValue)
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      YearList[
                        YearList.findIndex(
                          Yearlst => Yearlst.id == currYearId
                        )
                      ]
                    }
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Year"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
             
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedIssue}
          >
            {DispensingLanguage[lan][menukey]["Done"]}
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose("ForecastModal")}
          >
            {DispensingLanguage[lan][menukey]["CANCEL"]}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ForecastModal;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
