import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  FormLabel,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import swal from "sweetalert";

import { DeleteOutline, Edit, Add, BorderRight } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";

import * as api from '../../../actions/api';
import * as Service from "../../../services/Service.js";

import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import AefiItemsModal from './AefiItemsModal';
import AdverseEventModal from "./AdverseEventModal";
import ProfessionModal from "./ProfessionModal";

import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";


import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";

let timeStamp = "";

let isDirty=false;
let masterDirty=false;

const AefiItems = (props) => {

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "aefi";
  const FacilityId = localStorage.getItem("FacilityId");

  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;
  let AdverseEventDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  const [AdverseEventManyData, setAdverseEventManyData] = useState([]);

  const getMedicineTakenFromList = JSON.parse(localStorage.getItem("getMedicineTakenFromList"));
  const getSeverityList = JSON.parse(localStorage.getItem("getSeverityList"));
  const getStopTreatmentList = JSON.parse(localStorage.getItem("getStopTreatmentList"));
  const getReadministrationList = JSON.parse(localStorage.getItem("getReadministrationList"));

  const [bDisableAddProduct, setDisableAddProduct] = useState(
    props.DisableAddBtn
  );

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({ menukey });
  const { bDeleteConfirm: bDeleteModal, modalPopupDelete: DeleteModal } = useModalPopupDelete({menukey});
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({menukey});

  const [AEFIOpen, setAEFIOpen] = useState(false);
  const [AdverseEventOpen, setAdverseEventOpen] = useState(false);
  const [ProfessionOpen, setProfessionOpen] = useState(false);

  const [isMale, setIsMale] = useState(false);
  const [isPregnancy, setIsPregnancy] = useState(true);
  // const [ProfessionCode, setProfessionCode] = useState(0);
  const [ProfessionCode, setProfessionCode] = useState("");

  // Genter
  let GenterList = JSON.parse(localStorage.getItem("GenterList"));

  const getProfessionList = JSON.parse(localStorage.getItem("getProfessionList"));
  
  const [formDataForAefiItem, setFormDataForAefiItem] = useState({
    TradeDCIDosage: "",
    UnitId: "",
    RouteId: "",
    DosageRoute: "",
    BeginningDate: "",
    StopDate: "",
    BatchNo: "",
    ExpiryDate: "",
    ManufacturerId: "",
    Reason: "",
  });

  const [formDataForAdverseEvent, setFormDataForAdverseEvent] = useState({
    AdverseEvent: "",
    Serious: "",
    SeverityId: "",
    DateOfBeginning: "",
    EndDate: "",
    StopTreatmentId: "",
    ReAdministrationId: "",
    CorrectiveTreatment: "",
  });

  const [formDataForProfession, setFormDataForProfession] = useState({
    ProfessionName: ""
  });

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_issue_invoice_entry_list_print.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TransactionId=" +
      props.formData.TransactionId +
      "&FacilityId=" +
      FacilityId +
      "&TimeStamp=" +
      Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_Issue_invoice_entry_list_excel.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TransactionId=" +
      props.formData.TransactionId +
      "&FacilityId=" +
      FacilityId +
      "&TimeStamp=" +
      Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const handleClose = (modalname) => {
    switch (modalname) {
      
      case "AEFIItemsModal":
        setAEFIOpen(false);
        break;
      case "AEFIOpen":
        setAEFIOpen(true);
        break;

        case "AdverseEventModal":
          setAdverseEventOpen(false);
          break;
        case "AdverseEventOpen":
          setAdverseEventOpen(true);
          break;

        case "ProfessionModal":
          setProfessionOpen(false);
          break;
        case "ProfessionOpen":
          setProfessionOpen(true);
          break;

      default:
        break;
    }
  };

  const onAefiItemsModal = () => {

    setFormDataForAefiItem({
      TradeDCIDosage: "",
      UnitId: "",
      RouteId: "",
      DosageRoute: "",
      BeginningDate: "",
      StopDate: "",
      BatchNo: "",
      ExpiryDate: "",
      ManufacturerId: "",
      Reason: "",
    });

    handleClose("AEFIOpen");
  };

  const onAdverseEventModal = () => {

    setFormDataForAdverseEvent({
      AdverseEvent: "",
      Serious: "",
      SeverityId: "",
      DateOfBeginning: "",
      EndDate: "",
      StopTreatmentId: "",
      ReAdministrationId: "",
      CorrectiveTreatment: "",
    });

    handleClose("AdverseEventOpen");
  };

  const onProfessionModal = () => {

    setFormDataForProfession({
      ProfessionName: ""
    });

    handleClose("ProfessionOpen");
  };

  const ReceiveDetailsColumns = [
    { title: "AefiItemId", field: "AefiItemId", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#."],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(
        <ActionButton />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Trade_name_Dosage"],
      field: "TradeDCIDosage",
      width: 200,
      // headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      // headerFilter: true,
      width: 100,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Route of administration"],
      field: "RouteName",
      // headerFilter: true,
      width: 150,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Dosage"],
      field: "DosageRoute",
      // headerFilter: true,
      width: 120,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Date of beginning"],
      field: "BeginningDate",
      width: 150,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Stop date"],
      field: "StopDate",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Batch number"],
      field: "BatchNo",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiration date"],
      field: "ExpiryDate",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer name"],
      field: "ManufacturerName",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Reason_administration"],
      field: "Reason",
      hozAlign: "left",
      headerHozAlign: "left",
    },
  ];

  const AdverseEventDetailsColumns = [
    { title: "AdverseEventId", field: "AdverseEventId", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#."],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(
        <ActionButtonForAdverseEvent />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Adverse_Event"],
      field: "AdverseEvent",
      width: 200,
      // headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Serious_yes_no"],
      field: "Serious",
      // headerFilter: true,
      width: 100,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        let YesNo = '';
        if(value){
          YesNo = t(DispensingLanguage[lan][menukey]["yes"]);
        }else{
          YesNo = t(DispensingLanguage[lan][menukey]["no"]);
        }

        return YesNo;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Severity reason"],
      field: "SeverityName",
      width: 200,
      hozAlign: "left",
      headerHozAlign: "left",
      formatter:"textarea",
    },
    {
      title: DispensingLanguage[lan][menukey]["Date of beginning"],
      field: "DateOfBeginning",
      hozAlign: "left",
      headerHozAlign: "left",
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["End date"],
      field: "EndDate",
      hozAlign: "left",
      headerHozAlign: "left",
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["Evolution_stopp_treatment"],
      field: "StopTreatmentName",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Evolution_administration"],
      field: "ReAdministrationName",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Corrective treatment"],
      field: "CorrectiveTreatment",
      hozAlign: "left",
      headerHozAlign: "left",
    },
  ];


  
  const onSetDisable = () => {  
    if(isDirty==true){   
      //document.getElementById("is_dirty_message").classList.remove("dnone"); 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }
    else{  
     //document.getElementById("is_dirty_message").classList.add("dnone"); 
     document.getElementById("master_dirty_message").classList.add("dnone");
    }    
  }
  
  const onMasterSetDirty = () => {   
    if(masterDirty==true){ 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  }

  const onPostBtnClick = () => {
    if (ReceiveDetailsRef == null) return;
    handlePost();
  };

  const handlePost = async (e) => {

    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), "post");

    if (validForm) {

      let fDtat = { ...validForm, "bStockUpdated": 1, "masterDirty":masterDirty };
      modalPopupDelete(mutateUpdate, fDtat, { PostQ: "modalQPost" }, ModalNo, validForm);

    }
  };

  const ModalNo = (validForm) => {

    props.stockPosted(0);
    let route = `${validForm.AefiMasterId}`;
    props.history.push(route);

  }

  const onSaveUpdateBtnClick = () => {

    let manydata = ReceiveDetailsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydata);

  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows, 'edit');
    // console.log('isval: ', validForm);
    if (validForm) {

      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutate({...validForm,'masterDirty':masterDirty});
      } else {
        //when update
        mutateUpdate({...validForm,'masterDirty':masterDirty});
      }

    }
  };


  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveAefiMasterData, {
    onSuccess: (data) => {
      if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `edit/${data.AefiMasterId}`;
        props.history.push(route);

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(api.MasterDataUpdate, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;
        //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        if (data.bStockUpdated == 1) {
          mutatePost(props.formData.AefiMasterId);
        }
        else {
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(api.updateStockForAefi, {
    onSuccess: (data) => {
      if (data.status == 200) {

        // setDeletedDataSet([]);

        if (data.data.success == 0) {
          //setbStockUpdate(0);
          props.stockPosted(0);
          props.refetchInvoice();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        } else {
          // setbStockUpdate(1);
          props.stockPosted(1);
          props.refetchInvoice();

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        }

      } else {
        // setbStockUpdate(0);
        props.stockPosted(0);
        props.refetchInvoice();
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  });

  const { mutate: DeleteAEFIItem } = useMutation(api.deleteAefiItemData, {
    onSuccess: (data) => {
      if (data.status == 200) {

        refetchItemData(props.formData.AefiMasterId);
        //props.refetch();

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
          duration: 10000,
        });

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  
  const { mutate: DeleteAdverseEventData } = useMutation(api.deleteAdverseEventData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        //props.refetch();
        refetchAdverseData(props.formData.AefiMasterId);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
          duration: 10000,
        });

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const { mutate: getAefiItemById } = useMutation(api.getAefiItemById, {
    onSuccess: (data) => {
      setFormDataForAefiItem(data);
      handleClose("AEFIOpen");
    },
  });

  const editAefiItem = (data) => {
    let AefiMasterIdAefiItemId = data.AefiMasterId+'|'+data.AefiItemId;
    getAefiItemById(AefiMasterIdAefiItemId);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;

    const cellValue = props.cell._cell.value || "Edit | Show";

    if (bStockUpdate == 0)
      return (
        <>
          <Edit
            onClick={() => {
              editAefiItem(rowData);
            }}
          />

          <DeleteOutline
            onClick={() => {
              //if (!bStockUpdate) deleteaefiItem(rowData);
              if (!bStockUpdate) DeleteModal(DeleteAEFIItem, rowData.AefiItemId);
            }}
          />
        </>
      );
    else return <></>;
  }
  
  const { mutate: getAdverseEventById } = useMutation(api.getAdverseEventById, {
    onSuccess: (data) => {
      setFormDataForAdverseEvent(data);
      handleClose("AdverseEventOpen");
    },
  });

  const editAdverseEventData = (data) => {
    getAdverseEventById(data.AdverseEventId);
  };

  function ActionButtonForAdverseEvent(props) {
    const rowData = props.cell._cell.row.data;

    const cellValue = props.cell._cell.value || "Edit | Show";

    if (bStockUpdate == 0)
      return (
        <>
          <Edit
            onClick={() => {
              editAdverseEventData(rowData);
            }}
          />

          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) DeleteModal(DeleteAdverseEventData, rowData.AdverseEventId);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]);

  useEffect(() => {
    if (AdverseEventDetailsRef == null) return;
    if (props.bFirst) {
      setAdverseEventManyData(props.AdverseEventManyData);
    }

  }, [props.AdverseEventManyData]);

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    setManyTableData([]);
    setAdverseEventManyData([]);
    props.handleReset();
  };


  const backToList = () => {

    if((masterDirty==true)||(isDirty==true)){
      
      modalPopupBacktoList(backToListCallBack, "/aefi", {PostQ:"modalBackToListQ"}); 
  
    }else props.history.push("/aefi")
    
  };

  const backToListCallBack = (PageUrl) => {
      masterDirty=false;
      isDirty=false;
      props.history.push(PageUrl);
  }

  const formOnchange = (e,cases,inputname) => { 

     if(cases=='handleChange'){
      props.handleChange(e);
     }else if(cases=='handleRDateChange'){
      props.handleRDateChange(e,inputname);
     }

     masterDirty=true;
     onMasterSetDirty();

   };

  const [chosenValues, setChosenValues] = useState({
    "ProfessionId": { "ProfessionId": "", "ProfessionName": "" },
    // "ApprovedBy": { "id": "", "name": "" }
  });

  useEffect(() => {

    let chosenValuesData = { ...chosenValues };

    chosenValuesData['ProfessionId'] = getProfessionList[getProfessionList.findIndex(Profession_List => Profession_List.ProfessionId == props.formData.ProfessionId)];

    setChosenValues(chosenValuesData);
    
  }, [props.formData.ProfessionId]);

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };

    chosenValuesData['ProfessionId'] = getProfessionList[getProfessionList.findIndex(Profession_List => Profession_List.ProfessionId == ProfessionCode)];
    setChosenValues(chosenValuesData);

    props.formData["ProfessionId"] = ProfessionCode;

  }, [ProfessionCode]);

  const addProfession = (ProfessionData) => {
    if (ProfessionData != "") {
      handleClose("ProfessionModal");
      setTimeout(() => setProfessionCode(ProfessionData.ProfessionId), 500);

      masterDirty=true;
      onMasterSetDirty();
    }
  };

   const handleChangeChoosen = (name, valueobj, value) => {

    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;

    setChosenValues(chosenValuesData);

    props.handleChangeChoosenProps(name, value);

    masterDirty=true;
    onMasterSetDirty();

  };

  const { mutate: refetchItemData } = useMutation(api.getMasterSingleDataList, {
    onSuccess: (data) => { 
      setManyTableData(data.data.ManyJsonSave); 
    },
  });

  const { mutate: refetchAdverseData } = useMutation(api.getMasterSingleDataList, {
    onSuccess: (data) => { 
 
      setAdverseEventManyData(data.data.AdverseEventDataList); 
    },
  });

  useEffect(() => {
    if (props.formData.GenderTypeId != "") {
      if(props.formData.GenderTypeId == 'F'){
        setIsMale(true);
      }else{
        setIsMale(false);

        props.formData.IfYesPregnancy = '';
        props.formData.TheDDR = '';
      }
    }
  }, [props.formData.GenderTypeId]);

  useEffect(() => {
    if (props.formData.Pregnancy != "") {
      if(props.formData.Pregnancy == 1){
        setIsPregnancy(false);
      }else{
        setIsPregnancy(true);

        props.formData.IfYesPregnancy = '';
        props.formData.TheDDR = '';
      }
    }
  }, [props.formData.Pregnancy]);

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer paddingLR">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["AEFI Entry - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                    <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                      {/* <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      // onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button> */}
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/aefi")}
                        onClick={() => backToList()}
                        >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                    <Button
                      //disabled={props.bStockUpdate}
                      disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>
                    <Button
                      //disabled={props.bStockUpdate}
                      disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}>
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/aefi")}
                      onClick={() => backToList()}
                      >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    {/* <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => PrintMany()}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => ExcelMany()}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button> */}
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              {/* Master section */}
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true}
                      error={props.errorObject.SheetNo}
                      helperText={props.errorObject.SheetNo}
                      required
                      id="SheetNo"
                      name="SheetNo"
                      label={DispensingLanguage[lan][menukey]["Serial_No"]}
                      // value={props.formData.SheetNo}
                      value={props.formData.SheetNo || props.generatedInvoiceNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={7} sm={7}></Grid>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}>
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.DateCNRP}
                        helperText={props.errorObject.DateCNRP}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="DateCNRP"
                        label={
                          DispensingLanguage[lan][menukey][
                          "receipt_at_the_CNRP"
                          ]
                        }
                        autoOk={true}
                        name="DateCNRP"
                        fullWidth
                        showTodayButton={true}
                        // value={props.formData.DateCNRP || props.selectedDate}
                        value={props.formData.DateCNRP || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>formOnchange(e,'handleRDateChange','DateCNRP')}
                       // onChange={(e) => props.handleRDateChange(e, "DateCNRP")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} className="material-border">
                <Grid item xs={12} sm={12}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Patient"]}
                    <span className="sw_star">: </span>
                  </InputLabel>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      disabled={
                        props.bStockUpdate
                      }
                      error={props.errorObject.LastName}
                      helperText={props.errorObject.LastName}
                      required
                      id="LastName"
                      name="LastName"
                      inputProps={{ maxLength: 2 }}
                      label={DispensingLanguage[lan][menukey]["Last_name"]}
                      value={props.formData.LastName}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','LastName')}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      disabled={
                        props.bStockUpdate
                      }
                      error={props.errorObject.FirstName}
                      helperText={props.errorObject.FirstName}
                      required
                      id="FirstName"
                      name="FirstName"
                      inputProps={{ maxLength: 3 }}
                      label={DispensingLanguage[lan][menukey]["First_names"]}
                      value={props.formData.FirstName}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','FirstName')}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {!(props.formData.Year || props.formData.Month) ? ( 
                    <>
                    <Grid item xs={6} sm={2}>
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={DateFnsUtils}
                        locale={lan == "en_GB" ? enGB : fr}>
                        <KeyboardDatePicker
                          disabled={props.bStockUpdate}
                          // required
                          // error={props.errorObject.DateofBirth}
                          // helperText={props.errorObject.DateofBirth}
                          className={classes.fullWidth}
                          //disableToolbar
                          variant="inline"
                          id="DateofBirth"
                          label={
                            DispensingLanguage[lan][menukey]["Date_of_birth"]
                          }
                          autoOk={true}
                          name="DateofBirth"
                          fullWidth
                          showTodayButton={true}
                          value={props.formData.DateofBirth || null}
                          format="dd/MM/yyyy"
                          onChange={(e) =>formOnchange(e,'handleRDateChange','DateofBirth')} 

                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disableFuture={true}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    </>
                  ) : (<></>)}

                  {!props.formData.DateofBirth ? (
                    <>
                    <Grid item xs={1} sm={1}>
                      <TextField
                        disabled={props.bStockUpdate}
                        id="Year"
                        name="Year"
                        inputProps={{ maxLength: 3 }}
                        label={DispensingLanguage[lan][menukey]["Year"]}
                        value={props.formData.Year}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) =>formOnchange(e,'handleChange','Year')}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <TextField
                        disabled={props.bStockUpdate }
                        id="Month"
                        name="Month"
                        inputProps={{ maxLength: 2 }}
                        label={DispensingLanguage[lan][menukey]["Month"]}
                        value={props.formData.Month}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) =>formOnchange(e,'handleChange','Month')}
                      />
                    </Grid>
                    </>
                   ) : (<></>)}
                  
                  <Grid item xs={3} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Sex"]}<span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.GenderTypeId}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="GenderTypeId"
                        name="GenderTypeId"
                        value={props.formData.GenderTypeId}
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','GenderTypeId')}
                        disabled={props.bStockUpdate}
                      >
                        {GenterList.map((item, index) => {
                          return (
                            <MenuItem
                              key={`GenderTypeId-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.GenderTypeId}>
                        {props.errorObject.GenderTypeId}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid container item xs={3} sm={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={7}>
                        <TextField
                          disabled={
                            props.bStockUpdate
                          }
                          // error={props.errorObject.Weight}
                          // helperText={props.errorObject.Weight}
                          // required
                          id="Weight"
                          name="Weight"
                          label={DispensingLanguage[lan][menukey]["Weight"]}
                          value={props.formData.Weight}
                          fullWidth
                          autoComplete="family-name"
                          //onChange={(e) => props.handleChange(e)}
                          onChange={(e) =>formOnchange(e,'handleChange','Weight')}
                        />
                      </Grid>
                      <Grid item xs={6} sm={5} style={{ marginTop: "20px" }}>
                        {DispensingLanguage[lan][menukey]["Kg"]}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={3} sm={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={7}>
                        <TextField
                          disabled={
                            props.bStockUpdate
                          }
                          // error={props.errorObject.Height}
                          // helperText={props.errorObject.Height}
                          // required
                          id="Height"
                          name="Height"
                          label={DispensingLanguage[lan][menukey]["Height"]}
                          value={props.formData.Height}
                          fullWidth
                          autoComplete="family-name"
                          //onChange={(e) => props.handleChange(e)}
                          onChange={(e) =>formOnchange(e,'handleChange','Height')}
                        />
                      </Grid>
                      <Grid item xs={6} sm={5} style={{ marginTop: "20px" }}>
                        {DispensingLanguage[lan][menukey]["cm"]}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                
                {isMale ? (
                <Grid container spacing={2}>          
                  <Grid item xs={12} sm={2}>        
                    <FormControl
                      className="sw_radiogroup"
                      component="fieldset"
                    >
                      <FormLabel component="legend">
                        {DispensingLanguage[lan][menukey]["Pregnancy"]}
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-label="Pregnancy"
                        name="Pregnancy"
                        value={ +props.formData.Pregnancy }
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','Pregnancy')}
                      >
                        <FormControlLabel
                          disabled={props.bStockUpdate}
                          value={1}
                          control={<Radio />}
                          label={DispensingLanguage[lan][menukey]["yes"]}
                        />
                        <FormControlLabel
                          disabled={props.bStockUpdate}
                          value={0}
                          control={<Radio />}
                          label={DispensingLanguage[lan][menukey]["no"]}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      hidden={isPregnancy}
                      id="IfYesPregnancy"
                      name="IfYesPregnancy"
                      label={
                        DispensingLanguage[lan][menukey]["if_yes_pregnancy"]
                      }
                      value={props.formData.IfYesPregnancy}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','IfYesPregnancy')}
                    />
                  </Grid>

                  <Grid item xs={6} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}>
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        hidden={isPregnancy}
                        className={classes.fullWidth}
                        variant="inline"
                        id="TheDDR"
                        label={DispensingLanguage[lan][menukey]["the DDR"]}
                        autoOk={true}
                        name="TheDDR"
                        fullWidth
                        showTodayButton={true}
                        // value={props.formData.TheDDR || props.selectedDate}
                        value={props.formData.TheDDR || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>formOnchange(e,'handleRDateChange','TheDDR')} 
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                ) : (<></>)}

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={5}>
                    <TextField
                      disabled={
                        props.bStockUpdate
                      }
                      // error={props.errorObject.Address}
                      // helperText={props.errorObject.Address}
                      // required
                      id="Address"
                      name="Address"
                      label={DispensingLanguage[lan][menukey]["Address"]}
                      value={props.formData.Address}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','Address')}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <TextField
                      disabled={
                        props.bStockUpdate || props.formData.InvFrom == 2
                      }
                      // error={props.errorObject.Telephone}
                      // helperText={props.errorObject.Telephone}
                      //required
                      id="Telephone"
                      name="Telephone"
                      label={DispensingLanguage[lan][menukey]["Tel"]}
                      value={props.formData.Telephone}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','Telephone')}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={7}>
                    <TextField
                      disabled={
                        props.bStockUpdate || props.formData.InvFrom == 2
                      }
                      // error={props.errorObject.HistoryFactors}
                      // helperText={props.errorObject.HistoryFactors}
                      // required
                      multiline
                      rows={3}
                      id="HistoryFactors"
                      name="HistoryFactors"
                      label={
                        DispensingLanguage[lan][menukey]["History_factors"]
                      }
                      value={props.formData.HistoryFactors}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','HistoryFactors')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* End Master section */}

              {/* Main gride section */}
              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card marginTop20">
                    <CardHeader
                      title={
                        DispensingLanguage[lan][menukey]["Identification_of_the_medicine_More"]
                      }
                      action={
                        <div className="float-right sw_btn_control marginTop5">
                          <Button
                            disabled={
                              props.bStockUpdate
                                ? props.bStockUpdate
                                : bDisableAddProduct
                            }
                            className="mr-2"
                            variant="contained"
                            color="primary"
                            // onClick={() => handleClose("AEFIOpen")}
                            onClick={() => onAefiItemsModal()}
                          >
                            +
                          </Button>
                        </div>
                      }
                    />

                    <AefiItemsModal
                      handleClose={handleClose}
                      openOrder={AEFIOpen}
                      refetch={props.refetch}
                      refetchItemData={refetchItemData}
                      formDataForAefiItem={formDataForAefiItem}
                      {...props}
                    />

                    <CardContent>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="200px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                {/* new row */}

                {/* Method_obtaining_products */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardHeader
                      title={
                        DispensingLanguage[lan][menukey]["Method_obtaining_products"]
                      }
                    />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={3} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Method Obtaining Product"]}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="MedicineTakenFromId"
                            name="MedicineTakenFromId"
                            value={props.formData.MedicineTakenFromId}
                            fullWidth
                            onChange={(e) =>formOnchange(e,'handleChange','MedicineTakenFromId')}
                            disabled={props.bStockUpdate}
                          >
                            {getMedicineTakenFromList.map((item, index) => {
                              return (
                                <MenuItem
                                  key={`MedicineTakenFromId-${index + 1}`}
                                  value={item.MedicineTakenFromId}
                                >
                                  {item.MedicineTakenFrom}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        </Grid>
                        {/* <Grid item xs={9} sm={9}></Grid> */}

                        <Grid item xs={9} sm={9}>
                          <TextField
                            disabled={
                              props.bStockUpdate || props.formData.InvFrom == 2
                            }
                            // error={props.errorObject.OriginSpecify}
                            // helperText={props.errorObject.OriginSpecify}
                            //required
                            id="OriginSpecify"
                            name="OriginSpecify"
                            label={DispensingLanguage[lan][menukey]["Origin_specify"]}
                            value={props.formData.OriginSpecify}
                            fullWidth
                            autoComplete="family-name"
                            //onChange={(e) => props.handleChange(e)}
                            onChange={(e) =>formOnchange(e,'handleChange','OriginSpecify')}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            disabled={
                              props.bStockUpdate || props.formData.InvFrom == 2
                            }
                            // error={props.errorObject.IfMedicinalName}
                            // helperText={props.errorObject.IfMedicinalName}
                            // required
                            id="IfMedicinalName"
                            name="IfMedicinalName"
                            label={
                              DispensingLanguage[lan][menukey]["If_medicinal_Name"]
                            }
                            value={props.formData.IfMedicinalName}
                            fullWidth
                            autoComplete="family-name"
                            //onChange={(e) => props.handleChange(e)}
                            onChange={(e) =>formOnchange(e,'handleChange','IfMedicinalName')}
                          />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                          <TextField
                            disabled={
                              props.bStockUpdate || props.formData.InvFrom == 2
                            }
                            // error={props.errorObject.PartUsed}
                            // helperText={props.errorObject.PartUsed}
                            // required
                            id="PartUsed"
                            name="PartUsed"
                            label={DispensingLanguage[lan][menukey]["Part used"]}
                            value={props.formData.PartUsed}
                            fullWidth
                            autoComplete="family-name"
                            //onChange={(e) => props.handleChange(e)}
                            onChange={(e) =>formOnchange(e,'handleChange','PartUsed')}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <TextField
                            disabled={
                              props.bStockUpdate || props.formData.InvFrom == 2
                            }
                            // error={props.errorObject.QuantityUsed}
                            // helperText={props.errorObject.QuantityUsed}
                            // required
                            id="QuantityUsed"
                            name="QuantityUsed"
                            label={DispensingLanguage[lan][menukey]["Quantity used"]}
                            value={props.formData.QuantityUsed}
                            fullWidth
                            autoComplete="family-name"
                            //onChange={(e) => props.handleChange(e)}
                            onChange={(e) =>formOnchange(e,'handleChange','QuantityUsed')}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} className="marginBottom10">
                          <TextField
                            disabled={
                              props.bStockUpdate || props.formData.InvFrom == 2
                            }
                            // error={props.errorObject.MethodOfPreparation}
                            // helperText={props.errorObject.MethodOfPreparation}
                            // required
                            id="MethodOfPreparation"
                            name="MethodOfPreparation"
                            label={
                              DispensingLanguage[lan][menukey][
                              "Method of preparation"
                              ]
                            }
                            value={props.formData.MethodOfPreparation}
                            fullWidth
                            autoComplete="family-name"
                            //onChange={(e) => props.handleChange(e)}
                            onChange={(e) =>formOnchange(e,'handleChange','MethodOfPreparation')}
                            
                          />
                        </Grid>

                        <Grid item xs={6} sm={6} className="marginBottom20">
                          <TextField
                            disabled={
                              props.bStockUpdate || props.formData.InvFrom == 2
                            }
                            // error={props.errorObject.RouteOfAdministration}
                            // helperText={props.errorObject.RouteOfAdministration}
                            // required
                            id="RouteOfAdministration"
                            name="RouteOfAdministration"
                            label={
                              DispensingLanguage[lan][menukey][
                              "Route of administration"
                              ]
                            }
                            value={props.formData.RouteOfAdministration}
                            fullWidth
                            autoComplete="family-name"
                            //onChange={(e) => props.handleChange(e)}
                            onChange={(e) =>formOnchange(e,'handleChange','RouteOfAdministration')}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* End of Method_obtaining_products */}

                {/* Adverse Event */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardHeader
                      title={
                        DispensingLanguage[lan][menukey]["Adverse_Event"]
                      }

                      action={
                        <div className="float-right sw_btn_control marginTop5">
                          <Button
                            disabled={
                              props.bStockUpdate
                                ? props.bStockUpdate
                                : bDisableAddProduct
                            }
                            className="mr-2"
                            variant="contained"
                            color="primary"
                            // onClick={() => handleClose("AEFIOpen")}
                            onClick={() => onAdverseEventModal()}
                          >
                            +
                          </Button>
                        </div>
                      }
                    />

                    <AdverseEventModal
                      handleClose={handleClose}
                      openOrder={AdverseEventOpen}
                      refetch={props.refetch} 
                      refetchAdverseData={refetchAdverseData}
                      formDataForAdverseEvent={formDataForAdverseEvent}
                      {...props}
                    />

                    <CardContent>
                      <ReactTabulator
                        ref={(r) => (AdverseEventDetailsRef = r)}
                        columns={AdverseEventDetailsColumns}
                        data={AdverseEventManyData}
                        height="200px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                {/* End of Adverse Event */}

                {/* Notifier */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardHeader
                      title={
                        DispensingLanguage[lan][menukey]["Notifier"]
                      }
                    />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={7} className="footerBorderRight">
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                disabled={
                                  props.bStockUpdate ||
                                  props.formData.InvFrom == 2
                                }
                                // error={props.errorObject.NotifierFullName}
                                // helperText={props.errorObject.NotifierFullName}
                                // required
                                id="NotifierFullName"
                                name="NotifierFullName"
                                label={
                                  DispensingLanguage[lan][menukey][
                                  "First and last name"
                                  ]
                                }
                                value={props.formData.NotifierFullName}
                                fullWidth
                                autoComplete="family-name"
                                //onChange={(e) => props.handleChange(e)}
                                onChange={(e) =>formOnchange(e,'handleChange','NotifierFullName')}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                              <FormControl className={classes.fullWidth_1}>
                              <Autocomplete
                                disabled={props.bStockUpdate}
                                autoHighlight
                                id="ProfessionId"
                                options={getProfessionList}
                                onChange={(event, valueobj) => handleChangeChoosen('ProfessionId', valueobj, valueobj ? valueobj.ProfessionId : "")}
                                getOptionLabel={(option) => option.ProfessionName}
                                value={chosenValues['ProfessionId']}
                                renderOption={(option) => (
                                  <Typography className="sw_fontSize">{option.ProfessionName}</Typography>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={props.formData.ProfessionId}
                                    label={DispensingLanguage[lan][menukey]["Occupation"]}
                                    variant="standard"
                                    // error={props.errorObject.ProfessionId}
                                    // helperText={props.errorObject.ProfessionId}
                                    id="ProfessionId"
                                    name="ProfessionId"
                                    fullWidth
                                    // required
                                  />
                                )}
                              />
                              
                                {/* <InputLabel id="demo-simple-select-helper-label">
                                  {DispensingLanguage[lan][menukey]["Occupation"]}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-helper-label"
                                  id="ProfessionId"
                                  name="ProfessionId"
                                  value={props.formData.ProfessionId}
                                  fullWidth
                                  onChange={(e) =>formOnchange(e,'handleChange','ProfessionId')}
                                  disabled={props.bStockUpdate}
                                >
                                  {getProfessionList.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={`ProfessionId-${index + 1}`}
                                        value={item.ProfessionId}
                                      >
                                        {item.ProfessionName}
                                      </MenuItem>
                                    );
                                  })}
                                </Select> */}

                              </FormControl>

                              <FormControl className={classes.fullWidth_2}>
                              <Add
                                className="add_plus"
                                hidden={props.bStockUpdate}
                                onClick={() => onProfessionModal()}
                              />
                            </FormControl>

                            <ProfessionModal
                              handleClose={handleClose}
                              openOrder={ProfessionOpen}
                              refetch={props.refetch} 
                              // refetchAdverseData={refetchAdverseData}
                              formDataForProfession={formDataForProfession}
                              addProfession={addProfession}
                              {...props}
                            />

                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                disabled={
                                  props.bStockUpdate ||
                                  props.formData.InvFrom == 2
                                }
                                // error={props.errorObject.HealthInstitution}
                                // helperText={props.errorObject.HealthInstitution}
                                // required
                                id="HealthInstitution"
                                name="HealthInstitution"
                                label={
                                  DispensingLanguage[lan][menukey][
                                  "Health institution"
                                  ]
                                }
                                value={props.formData.HealthInstitution}
                                fullWidth
                                autoComplete="family-name"
                                //onChange={(e) => props.handleChange(e)}
                                onChange={(e) =>formOnchange(e,'handleChange','HealthInstitution')}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                disabled={
                                  props.bStockUpdate ||
                                  props.formData.InvFrom == 2
                                }
                                // error={props.errorObject.PhoneOrEmail}
                                // helperText={props.errorObject.PhoneOrEmail}
                                // required
                                id="PhoneOrEmail"
                                name="PhoneOrEmail"
                                label={
                                  DispensingLanguage[lan][menukey][
                                  "Phone_email"
                                  ]
                                }
                                value={props.formData.PhoneOrEmail}
                                fullWidth
                                autoComplete="family-name"
                                //onChange={(e) => props.handleChange(e)}
                                onChange={(e) =>formOnchange(e,'handleChange','PhoneOrEmail')}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={6} sm={5}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <MuiPickersUtilsProvider
                                libInstance={moment}
                                utils={DateFnsUtils}
                                locale={lan == "en_GB" ? enGB : fr}>
                                <KeyboardDatePicker
                                  disabled={props.bStockUpdate}
                                  // required
                                  // error={props.errorObject.DateOfNotification}
                                  // helperText={props.errorObject.DateOfNotification}
                                  className={classes.fullWidth}
                                  variant="inline"
                                  id="DateOfNotification"
                                  label={DispensingLanguage[lan][menukey]["Date of notification"]}
                                  autoOk={true}
                                  name="DateOfNotification"
                                  fullWidth
                                  showTodayButton={true}
                                  // value={props.formData.DateOfNotification || props.selectedDate}
                                  value={props.formData.DateOfNotification || null}
                                  format="dd/MM/yyyy"
                                  onChange={(e) =>formOnchange(e,'handleRDateChange','DateOfNotification')} 
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* End of Notifier */}

                <Grid item xs={12} sm={12}></Grid>

              </Grid>
            </Grid>
          </Grid>
          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default AefiItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
});
