import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import * as Service from "../../../services/Service.js";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ZSFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "zone-entry";
  const [Regionlist, setRegionlist] = useState([]);
  const [Region, setRegion] = React.useState(true);

  if (Region) {
    setRegion(false);

    let ParamList = { action: "Regionlist" };
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setRegionlist(res.datalist);
      })
      .catch((err) => { });
  }

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    RegionId: { id: "", name: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData["RegionId"] =
      Regionlist[
      Regionlist.findIndex((Regionlist) => Regionlist.id == formData.RegionId)
      ];
    setChosenValues(chosenValuesData);
  }, [formData.RegionId]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========

  const classes = useStyles();

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">
                {DispensingLanguage[lan][menukey]["ZS - Add/Edit"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["Zone Entry Form"]}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]["Department Name"]}*
                        </InputLabel>
                        <Select
                          error={errorObject.MenuKey}
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          name="RegionId"
                          value={formData.RegionId}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                        >
                          {Regionlist.map((item, index) => {
                            return <MenuItem value={item.id} key={index}>{item.name}</MenuItem>;
                          })}
                        </Select>
                        <FormHelperText error={errorObject.RegionId}>
                            {errorObject.RegionId}
                        </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                            //disableClearable
                            disabled={props.permissionType}
                            id="RegionId"
                            options={Regionlist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "RegionId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["RegionId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.RegionId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                  "Department Name"
                                  ]
                                }
                                variant="standard"
                                error={errorObject.RegionId}
                                helperText={errorObject.RegionId}
                                id="RegionId"
                                name="RegionId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errorObject.ZoneName}
                          helperText={errorObject.ZoneName}
                          required
                          id="ZoneName"
                          name="ZoneName"
                          label={DispensingLanguage[lan][menukey]["ZS Name"]}
                          value={formData.ZoneName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 250 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]["Save"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/zone-entry")}
              >
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]["update"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/zone-entry")}
              >
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default ZSFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
