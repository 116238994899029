import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../../actions/api";
import ProductSubgroupFormData from "./ProductSubgroupFormData.js";


const AddProductSubgroup = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "product-subgroup-entry";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;
const ItemGroupId = localStorage.getItem("ItemGroupId");
  const [formData, setFormData] = useState({
    ItemGroupId:ItemGroupId,
    ProductSubGroupName: "",
    ProductSubGroupId:""
    
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  // useQuery(
  //   ["supplier", id],
  //   () =>
  //     api.getSupplierData(id).then((res) => {
  //       setFormData(res.data);
  //     }),
  //   {
  //     enabled: Boolean(id),
  //   }
  // );

  const { mutate } = useMutation(
    api.saveProductSubGroup,
     {
    onSuccess: (data) => {

      if(data.status==200) {

        api.getAllDropdown("ProductSubGroupList").then((response) => {
          localStorage.setItem(
            "ProductSubGroupList",
            JSON.stringify(response.datalist.ProductSubGroupList)
          );
        });

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("productsubgroup");
      props.history.push("/product-subgroup-entry");

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }

    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ItemGroupId:ItemGroupId,
    ProductSubGroupName: "",
    ProductSubGroupId:""
   
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ItemGroupId","ProductSubGroupName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //swal("Success!", "", "success");
    }
  };

  return (
    <>
      <ProductSubgroupFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddProductSubgroup;
