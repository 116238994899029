import React, { } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  TextField,
  Grid,
  FormControl,
  Typography,
  Card,
  CardContent,
  Button
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';

const MOSTypeForFacility = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "mos-type-for-facility-entry";
  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;

  const classes = useStyles();
  // const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  const FlevelList = JSON.parse(localStorage.getItem("FlevelList"));
  const [currFlevelId, setCurrFlevelId] = useState(FlevelList[0].id);
  const [currFlevelName, setFlevelName] = useState([FlevelList[0].name]);

  // const FTypeList_List = JSON.parse(localStorage.getItem("FTypeList"));
  // let All_FTypeList_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
  // const FTypeList = [All_FTypeList_label].concat(FTypeList_List); 
  // const [currCurrFTypeListId, setCurrFTypeListId] = useState(0);





  let params = {
    menukey: menukey,
    action: "getDataList",
    // FTypeId: currCurrFTypeListId,
    FLevelId: currFlevelId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };
  const { refetch } = useQuery(
    [params],
    api.getMOSTypeForFacility,
    {
      onSuccess: (data) => {
        /* localStorage.setItem(
          "ProductDataNextPrev",
          JSON.stringify(data)
        ); */
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  /* const { isLoading, error, isError, data, refetch } = useQuery(
    "MOSTypeForFacility",
    api.getMOSTypeForFacility,
    {
      onSuccess: (data) => {
        setDataItems(data); 
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  ); */

  const { mutate: deleteApi } = useMutation(api.deleteMOSTypeForFacility, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        refetch();
        queryClient.getQueriesData("MOSTypeForFacility");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });


      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

      }
    },
  });


  const handleFlevelListChange = (event, newValue) => {
    let rowId = '';
    let rowname = "";
    if (newValue == null) {
      rowId = '';
      rowname = "";
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }

    setCurrFlevelId(rowId);
    setFlevelName(rowname);

  };

  //   const handleFtypeListChange = (event, newValue) => {
  //     let rowId = '';
  //     if(newValue == null){
  //       rowId = '';
  //     }else{
  //       rowId = newValue.id;
  //     }

  //     setCurrFTypeListId(rowId);

  //  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    window.open(
      finalUrl +
      "?action=MOSTypeForFacilityExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      // "&FTypeId=" +
      // currCurrFTypeListId +
      "&FLevelId=" +
      currFlevelId +
      "&FlevelName=" +
      currFlevelName +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editMOSRange(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'], hozAlign: 'center', width: 120, formatter: "rownum", headerHozAlign: "center", headerSort: false },
    { title: DispensingLanguage[lan][menukey]['MOS Type'], headerFilter: true, field: "MosTypeName" },
    { title: DispensingLanguage[lan][menukey]['Minimum MOS'], hozAlign: "center", headerHozAlign: "center", width: 200, field: "MinMos" },
    { title: DispensingLanguage[lan][menukey]['Maximum MOS'], hozAlign: "center", headerHozAlign: "center", width: 200, field: "MaxMos" },
    { title: DispensingLanguage[lan][menukey]['Color Code'], width: 200, field: "ColorCode", formatter: "color" },
    { title: DispensingLanguage[lan][menukey]['MOS Label'], width: 200, field: "MosLabel" },

    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editMOSRange = (data) => {
    let route = `mos-type-for-facility-entry/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteMOSTypeForFacility = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //       "Once deleted, you will not be able to recover this Record!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: [t(DispensingLanguage[lan][menukey]["No"]),
  //     t(DispensingLanguage[lan][menukey]["Yes"])],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       deleteApi({ MosTypeId: data.id, "menukey": menukey, "lan": lan, "UserName": UserName, "action": "dataDalete" });

  //     }
  //   });
  // };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editMOSRange(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            // deleteMOSTypeForFacility(rowData);
            modalPopupDelete(deleteApi, rowData.id);
          }}
        />
      </>
    );
  }


  return (
    <>
      <div className={classes.mosrangePageTitle}>
        <div className="d-flex justify-MOSTypeForFacility mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["MOS Type for Facility"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

              <Grid item xs={12} sm={8}>
                <Grid container spacing={1}>

                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="FlevelList"
                        disableClearable
                        //disabled={userId==1?false:true}
                        options={FlevelList}
                        onChange={(event, newValue) => handleFlevelListChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={FlevelList[FlevelList.findIndex(flevelList => flevelList.id == currFlevelId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Facility Level"]}
                            variant="standard"
                            hiddenLabel
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>


                  {/* <Grid item xs={4} sm={4}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FTypeList"
                          disableClearable
                          //disabled={userId==1?false:true}
                          options={FTypeList}
                          onChange={(event, newValue) => handleFtypeListChange(event, newValue)}
                          getOptionLabel={(option) => option.name}
                          defaultValue={FTypeList[FTypeList.findIndex(fTypeList => fTypeList.id == currCurrFTypeListId)]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Facility Type"]}
                            variant="standard"
                            hiddenLabel
                          />
                          )}
                        />

                      </FormControl>
                    </Grid> */}
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Grid container spacing={1}>


                  {/* <Grid item xs={6} sm={6}>
                        <div className="float-right marginTop10">
                          <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total Product'] + ": " + ProductCount}</h4>
                        </div>
                  </Grid>  */}


                  <Grid item xs={12} sm={12}>
                    <div className="float-right sw_btn_control">
                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2"
                        onClick={() => {
                          props.history.push("/mos-type-for-facility-entry/add");
                        }}
                      >
                        {DispensingLanguage[lan][menukey]["Add MOS Type for Facility"]}
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i className="fas fa-print"></i>
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  </Grid>

                </Grid>

              </Grid>





            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">

          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height={450}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
              options={{
                groupBy: "FTypeName",
                columnCalcs: "both",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );

  /*  return (
     <>
       <div className={classes.mosrangePageTitle}>
         <div className="d-flex justify-MOSTypeForFacility mb-2">
           <Grid item xs={12} sm={12}>
             <div className="sw_page_heading">
               <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['MOS Type for Facility']}  </div>
               <div className="float-right sw_btn_control">
                 <Button
                   color="info"
                   className="mr-2"
                   onClick={() => {
                     props.history.push("/mos-type-for-facility-entry/add");
                   }}
                 >
                 {DispensingLanguage[lan][menukey]['Add MOS Type for Facility']}
                 </Button>
                 <Button
                   color="info"
                   className="mr-2 sw_icons"
                   onClick={() => PrintPDFExcelExportFunction("print")}
                 >
                   <i class="fas fa-print"></i>
                 </Button>
 
                 <Button
                   color="info"
                   className="mr-2 sw_icons"
                   onClick={() => PrintPDFExcelExportFunction("excel")}
                 >
                   <i className="far fa-file-excel"></i>
                 </Button>
               </div>
             </div>
           </Grid>
         </div>
 
         <div>
           <div className="uniqueName">
             <ReactTabulator columns={columns}
              data={dataItems}
              //height={600}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
               />
           </div>
         </div>
       </div>
     </>
   ); */
};

export default MOSTypeForFacility;

const useStyles = makeStyles({
  mosrangePageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
