import React, {
  useState,
  useEffect,
  useContext,
  Component,
  useRef,
} from "react";
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";
import { ReactTabulator } from "react-tabulator";
// material components
import {
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
// import { Button } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";
const GapAnalysisReport = (props) => {
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "gap-analysis";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const [dataItems, setDataItems] = useState([]);
  const [fieldList, setfieldList] = useState([]);

  const currentDate = new Date();

  const YearList = JSON.parse(localStorage.getItem("YearList"));

  let previousMonth = currentDate.getMonth() + 1 - 1;
  let previousYear = currentDate.getFullYear();

  if (previousMonth < 0) {
    // If the previous month is negative, subtract 1 from the year and set the month to 11 (December)
    previousMonth = 11;
    previousYear -= 1;
  }

  // Create a new Date object for the first day of the previous month
  const previousMonthDate = new Date(previousYear, previousMonth - 1, 15);

  previousMonth = previousMonthDate.getMonth() + 1 - 1;
  previousYear = previousMonthDate.getFullYear();
  if (previousMonth < 0) {
    previousMonth = 11;
    previousYear -= 1;
  }
  const previousYearMonthDate = new Date(previousYear, previousMonth - 11, 15);

  const [currYearId, setYearId] = useState(previousMonthDate.getFullYear());

  //year
  const handleYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);
    }
  };

  exporting(Highcharts);

  //owner combo list
  const OwnerTypelist = JSON.parse(localStorage.getItem("Ownertypelist"));




  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroupList"));
  // let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product_Group].concat(Product_Group);

  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }
  };

  let params = {
    menukey: menukey,
    action: "ShowRportFundingStatusReport",
    lan: lan,
    ItemGroupId: currProductGroupId,
    YearId: currYearId
   
  };

  const { data } = useQuery([params], api.getRportFundingStatusReport, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },

    // {
    //   title: DispensingLanguage[lan][menukey]["Groups"],
    //   field: "GroupName",
    //   headerSort: true,
    //   width: 170,
    //   headerFilter: true,
    // },

    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
      headerSort: true,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Requirements (US$)"],
      field: "Y1",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 200,
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Committed (US$)"],
      field: "Total",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 240,
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Gap/Surplus"],
      field: "GapSurplus",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 240,
      headerFilter: false,
    },
  ];

  // Custom footer formatter function

  // ============ISSUE DISPENSE TREND START=============

  // START OF Issue Dispense Line Chart

  const [receive_details, setOption_receive_details] = useState(
   []
  );

  //END OF Issue Dispense Line Chart

  let params1 = {
    menukey: menukey,
    action: "ReportFundingStatusChart",
    lan: lan,
    ItemGroupId: currProductGroupId,
    YearId: currYearId
  };

  const getReportFundingStatusChart = useQuery(
    [params1],
    api.getReportFundingStatusChart,
    {
      onSuccess: (data) => {
       
        if (data.status == 200) {
          //console.log(data.datalist.dataSeries.length);
          // let Ndata = [];
         
          // for (let i = 0; i < data.datalist.dataSeries.length; i++) {
          //   let newdata = {};
          //   newdata.y = data.datalist.dataSeries[i].data;
          //   newdata.color = data.datalist.dataSeries[i].color;
   
          //   Ndata.push(newdata);
          // }
   
          
   
          setOption_receive_details( {
            chart: {
              type: "column",
              borderColor: "#C3DDEC",
              height: 430,
              exporting: {
                enabled: true,
              } 
            },
            title: {
              text: t(
                DispensingLanguage[lan][menukey]["Gap Analysis"]
              ),
            },
            xAxis: {
              categories: data.datalist.categories,
            },
            yAxis: {
              min: 0,
             // max: 100000,
              title: {
                text: t(DispensingLanguage[lan][menukey]["Quantity"]),
                align: "middle",
              },
              labels: {
                overflow: "justify",
              },
            },
            legend: {
              reversed: true,
            },
            plotOptions: {
              // series: {
              //   stacking: "normal",
              // },
              // pointPadding: 0,
              // borderWidth: 0,
      
              bar: {
                dataLabels: {
                  enabled: true,
                  crop: true,
                  format: "<b>{point.y:.1f}",
                  formatter: function () {
                    return this.y > 0 ? this.y : "";
                  },
                  style: {
                    textShadow: false,
                    textOutline: false 
                  }
                },
              },
            },
            credits: {
              enabled: false,
            },
            tooltip: {
              valuePrefix: t(
                DispensingLanguage[lan][menukey]["Quantity"]
              ),
              pointFormat: "{series.name}: {point.y:.1f} </br>",
              shared: true,
            },
            series: data.datalist.dataSeries,
          }); 


        }
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    }
  );

  
  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/GapAnalysisRePrint.php";
    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&ItemGroupId=" +
        currProductGroupId +
        "&GroupName=" +
        currItemGroupname +
        "&YearId=" +
        currYearId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */
  /* =====Start of Excel Export Code==== */

  const ExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/GapAnalysisExcel.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&ItemGroupId=" +
      currProductGroupId +
      "&GroupName=" +
      currItemGroupname +
      "&YearId=" +
      currYearId +
      "&TimeStamp=" +
      Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding sw_inner_container`}>
        <div className="sw_inner_card">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Gap Analysis"])}
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container>
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="ProductGroup"
                            disableClearable
                            options={ProductGroup}
                            onChange={(event, newValue) =>
                              handleProductGroupChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              ProductGroup[
                                ProductGroup.findIndex(
                                  (ProductGroup) =>
                                    ProductGroup.id == currProductGroupId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["Groups"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    

                      <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="YearList"
                            disableClearable
                            options={YearList}
                            onChange={(event, newValue) =>
                              handleYearChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              YearList[
                                YearList.findIndex(
                                  (YearList) => YearList.id == currYearId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["End Year"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <div className="row">
            <div className="col-md-12 mb-3">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Gap Analysis"]}
                />
                <CardContent>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={receive_details}
                    height={430}
                  />
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(DispensingLanguage[lan][menukey]["Gap Analysis"])}
                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}>
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelExportFunction("excel")}>
                      <i className="far fa-file-excel"></i>
                    </Button>
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          height={542}
                          options={{
                            groupBy: ["GroupName"],
                            columnCalcs: "both",
                            rowFormatter: (row) => {
                              const rows = row.getTable().getRows();
                              if (row === rows[rows.length - 1]) {
                                row.getElement().classList.add(classes.lastRow);
                              }
                            }
                            
                          }}
                        />
                        {/* <div className="float-right">
                          <span>{currItemGroupname} Total: {52632}</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GapAnalysisReport;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
  lastRow: {
    backgroundColor: '#50ABED !important',
  },
});

