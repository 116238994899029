import React, { useEffect, useState } from "react";//, 
import { useParams } from "react-router-dom";
import {makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";  
import { useQuery, useMutation,useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SupplierModal =({ handleClose, openOrder, addSupplier, ...props }) => {

const classes = useStyles(); 

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "receive-from-supplier";

let AllCountryList = JSON.parse(localStorage.getItem("AllCountryList"));

const { id } = useParams();
const queryClient = useQueryClient();

const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

const [formData, setFormData] = useState({
    SupplierName: "",
    SupplierAddress: "",
    ContactNo: "",
    Fax: "",
    Email: "",
    ISO3: "",
    ContactName:"",
    Url: "",
  });
  const [errorObject, setErrorObject] = useState({});

  const { isLoading, mutate } = useMutation(api.saveSupplierForReceive, {
    onSuccess: (data) => {

      if (data.status == 200) {
        
        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'SupplierListByFacility'
        };

        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
        .then((response) => {
          localStorage.setItem(
            "SupplierListByFacility",
            JSON.stringify(response.datalist.SupplierListByFacility)
          );
        });

        setSupplierData(data);
        setbtnDisable(true);
        setTimeout(() => setbtnDisable(false), 500);
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

const validateForm = (formData) => {
    let validateFields = [
      "SupplierName", 
      "ISO3",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
};

const handleSubmit = async (e) => {

    if(formData.SupplierName==""){
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Supplier name is required."],
        msgtype: 0,
      });
      return;
    }
    
    if(formData.ISO3==""){
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Country name is required."],
        msgtype: 0,
      });
      return;
    }

    if(formData.ContactNo==""){
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Contact no is required."],
        msgtype: 0,
      });
      return;
    }

    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  const [SupplierData, setSupplierData] = useState([]);

  const [btnDisable, setbtnDisable] = useState(false);
  const SaveSelectedProducts = () => {

      setbtnDisable(true);
      setTimeout(() => setbtnDisable(false), 500);
  }; 

  useEffect(() => { 

    addSupplier(SupplierData);

}, [SupplierData]); 


const [chosenValues, setChosenValues] = useState({
  "ISO3": {"ISO3": "","CountryName": ""}
});


const handleChangeChoosen = (name, valueobj, value) => {

  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj; 

  setChosenValues(chosenValuesData);

  let data = { ...formData };
  data[name] = value;  
  setFormData(data); 
  setErrorObject({ ...errorObject, [name]: "" });
};

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('SupplierModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('SupplierModal')}
        >
         {DispensingLanguage[lan][menukey]['Add Supplier']}
      </BootstrapDialogTitle>
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                          required
                          disabled={props.permissionType}
                          id="SupplierName"
                          name="SupplierName"
                          label= {DispensingLanguage[lan][menukey]['Supplier Name'] }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                            disabled={props.IsCompleted || props.bStockUpdate}
                            autoHighlight
                            id="ISO3"
                            options={AllCountryList}
                            onChange={(event, valueobj) => handleChangeChoosen('ISO3', valueobj, valueobj ? valueobj.ISO3:"")}
                            getOptionLabel={(option) => option.CountryName}
                            value={chosenValues['ISO3']}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.CountryName}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={props.formData.ISO3}
                                label={DispensingLanguage[lan][menukey]["Country"]}
                                variant="standard"
                                error={props.errorObject.ISO3}
                                helperText={props.errorObject.ISO3}
                                id="ISO3"
                                name="ISO3"
                                fullWidth
                                required
                              />
                            )}
                          />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                          id="Email"
                          name="Email"
                          label= {DispensingLanguage[lan][menukey]['Email'] }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                          id="ContactName"
                          name="ContactName"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Contact Name'] }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <TextField
                          required
                          id="ContactNo"
                          name="ContactNo"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Contact No'] }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="Fax"
                          name="Fax"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Fax'] }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} className="marginBottom20">
                        <TextField
                          id="SupplierAddress"
                          name="SupplierAddress"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Supplier Address'] }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={6} sm={6} className="marginBottom20">
                        <TextField
                          id="Url"
                          name="Url"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['URL'] }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid> 
                </Grid>
            </Grid>
          </CardContent>
        </Card>
       
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
          onClick={() => handleSubmit()}
          >
           {DispensingLanguage[lan][menukey]['Save']} 
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('SupplierModal')}>
            {DispensingLanguage[lan][menukey]['CANCEL']} 
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SupplierModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});