import React, { useState, useEffect } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import { addDays, subDays } from "date-fns";

import { useQuery } from "react-query";
import * as api from "../../actions/api";
import { useMutation } from "react-query";
import ChartStates from "./ChartStates";
import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Button,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  //  Link,
  //  AddToHomeScreen,
  Launch,
} from "@material-ui/icons";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

// import DefaultFooter from "components/Footers/DefaultFooter";
import { getDefaultMonthYear } from "../../services/Common";
import { useTranslation } from "react-i18next";
import {
  checkLogin,
  checkUserPermission,
} from "../../services/CheckUserAccess";
import { ReactTabulator } from "react-tabulator";

// import CountryProfileMap from "./Leaft_Map/CountryProfileMap.js";
import statesData from "../../services/mapjsonfile/mali.json";

const CountryProfileReport = (props) => {
  const [MapInfo, setMapInfo] = useState([]);

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t } = useTranslation();
  const menukey = "country-profile";
  const FacilityId = localStorage.getItem("FacilityId");

  const bFacilitySelected = localStorage.getItem("bFacilitySelected");
  const [activeProductfre, setProductRefec] = useState("");
  exporting(Highcharts);
  const classes = useStyles();

  const [geoJson, setGeoJson] = useState(statesData);
  let TempZoneList = useState([]);
  const [Product_list, setProductData] = useState(TempZoneList);

  const [currProductId, setProductId] = useState(
    Product_list.length > Product_list ? Product_list[0].id : "0"
  );
  //Map api include
  const [dataItemsMap, setDataItemsMap] = useState([]);
  // ----------------------New Combo Start----------------------
  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroupList"));
  // let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product_Group].concat(Product_Group);

  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }
    // getProductData(rowId);
  };

  // ----------------------New Combo End----------------------

  // const [isLoadingDashboard, setLoading] = useState(true);
  const [fieldList, setfieldList] = useState([]);

  const currentDate = new Date();
  const YearListlocal = JSON.parse(localStorage.getItem("YearList"));

  const YearList = YearListlocal;
  let All_Year = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  let previousMonth = currentDate.getMonth() + 1 - 1;
  let previousYear = currentDate.getFullYear();

  if (previousMonth < 0) {
    // If the previous month is negative, subtract 1 from the year and set the month to 11 (December)
    previousMonth = 11;
    previousYear -= 1;
  }

  // Create a new Date object for the first day of the previous month
  const previousMonthDate = new Date(previousYear, previousMonth - 1, 15);

  previousMonth = previousMonthDate.getMonth() + 1 - 1;
  previousYear = previousMonthDate.getFullYear();
  if (previousMonth < 0) {
    previousMonth = 11;
    previousYear -= 1;
  }
  const previousYearMonthDate = new Date(previousYear, previousMonth - 11, 15);

  const [currmonthId, setmonthId] = useState(
    previousYearMonthDate.getMonth() + 1
  );
  const [currYearId, setYearId] = useState(previousYearMonthDate.getFullYear());

  const [currEndmonthId, setEndmonthId] = useState(
    previousMonthDate.getMonth() + 1
  );
  const [currEndYearId, setEndYearId] = useState(
    previousMonthDate.getFullYear()
  );

  //year
  const handleYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currEndYearId) {
      setEndYearId(rowId);
    }
  };

  // Get Map


  let paramspie = {
    menukey: menukey,
    action: "getCountryMapData",
    lan: lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    // mode: bEsigl
  };

  const { isLoading: Loadngpie, refetch: refacepie } = useQuery(
    [paramspie],
    api.getCountryLeaftMapData,
    {
      onSuccess: (data) => {
        console.log(data);
        setDataItemsMap(data);
        // setDataItems(data.MapData.aaDatatable);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const [RedirectLogin, setRedirectLogin] = React.useState(true);

  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey)); // To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  // END OF RECEIVE DETAILS DONUT
  // =======================================Parameter List API Start======================================
  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "getParameterList",
    lan: lan,
    productGroupId: currProductGroupId,
    MonthId: currmonthId,
    YearId: currEndYearId,
    ItemNo: currProductId,
    EndMonthId: currEndmonthId,
    // EndYearId: currEndYearId,
  };

  const { data } = useQuery([params], api.getParameterList, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });
  // =======================================Parameter List API End======================================

  const Parametercolumns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 60,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Parameter"],
      field: "ParamName",
      headerSort: false,
      width: 260,
    },
    {
      title: DispensingLanguage[lan][menukey]["Value"],
      field: "YCValue",
      headerSort: false,
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Data Source"],
      field: "DataSource",
      headerSort: false,
      // hozAlign: "right",
      // headerHozAlign: "right",
    },
  ];
  // =======================================Quantity Required API Start======================================
  const [dataItemsQ, setDataItemsQ] = useState([]);
  let params1 = {
    menukey: menukey,
    action: "getFundingSource",
    lan: lan,
    productGroupId: currProductGroupId,
    MonthId: currmonthId,
    YearId: currEndYearId,
    ItemNo: currProductId,
    EndMonthId: currEndmonthId,
    // EndYearId: currEndYearId,
  };

  const { dataQ } = useQuery([params1], api.getQuantityRequired, {
    onSuccess: (data) => {
      console.log("==========data lid========", data);
      setDataItemsQ(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });
  // =======================================Quantity Required API End======================================
  const QuantityRequiredcolumns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 60,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Products"],
      field: "ItemName",
      headerSort: false,
      // width: 185,
    },
    {
      title: params1.YearId,
      field: "Y1",
      headerSort: false,
      width: 160,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Total"],
      field: "TotalRequirements",
      headerSort: false,
      width: 160,
      hozAlign: "right",
      headerHozAlign: "right",
    },
  ];

  // =======================================Gap Analysis API Start======================================
  const [dataItemsGap, setItemsGap] = useState([]);
  let params2 = {
    menukey: menukey,
    action: "getPledgedFundingList",
    lan: lan,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    YearId: currEndYearId,
    ItemNo: currProductId,
    EndMonthId: currEndmonthId,
    // EndYearId: currEndYearId,
  };

  const { dataG } = useQuery([params2], api.getPledgedFunding, {
    onSuccess: (data) => {
      let tmpfield = [];
      for (let i = 0; i < data.data.collist.length; i++) {
       // console.log("gg", data.data.datalist);
       
        tmpfield.push({
          title: data.data.collist[i].title,
          field: data.data.collist[i].field,
          width: data.data.collist[i].width,
          hozAlign: data.data.collist[i].hozAlign,
          headerHozAlign: data.data.collist[i].headerHozAlign,
          headerSort: false,
          // cssClass: data.data.collist[i].cssClass,
          // editable: data.data.collist[i].editable,
          // editor: data.data.collist[i].editor,  
        });
      }
      setfieldList(tmpfield);
      setItemsGap(data.data.datalist);
      // setLoading(false);
    },
 
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  // =======================================Gap Analysis API End======================================
  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";

    window.open(
      finalUrl +
        "?action=getPerameterListReport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now() +
        "&productGroupId=" +
        currProductGroupId +
        "&productGroupName=" +
        currItemGroupname +
        "&Year=" +
        currEndYearId +
        "&productId=" +
        currProductId +
        "&ProductGroup=" +
        activeProductfre.name
    );
  };

  /* =====End of Excel Export Code==== */
  /* =====Start of Excel Export Code Quantity Required==== */
  const PrintPDFExcelExportQuantityRequiredFunction = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/CountryProfileQuantityReportPrint.php";

    window.open(
      finalUrl +
        "?action=getQuantityRequiredReport" +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now() +
        "&productGroupId=" +
        currProductGroupId +
        "&productGroupName=" +
        currItemGroupname +
        "&Year=" +
        currEndYearId +
        "&productId=" +
        currProductId +
        "&ProductGroup=" +
        activeProductfre.name
    );
  };
  
  const PrintPDFExcelExportQuantityRequiredExcelFunction = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/CountryProfileQuantityReportExcel.php";

    window.open(
      finalUrl +
        "?action=getQuantityRequiredReportExcelExport" +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now() +
        "&productGroupId=" +
        currProductGroupId +
        "&productGroupName=" +
        currItemGroupname +
        "&Year=" +
        currEndYearId +
        "&productId=" +
        currProductId +
        "&ProductGroup=" +
        activeProductfre.name
    );
  };

  /* =====End of Excel Export Code Quantity Required==== */
  /* =====Start of Excel Export Code Gap Anlaysis==== */
  const PrintPDFExcelExportGapAnalysisFunction = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/CountryProfileGapAnalysisPrint.php";

    window.open(
      finalUrl +
        "?action=getGapAnalysisReport" +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now() +
        "&ItemGroupId=" +
        currProductGroupId +
        "&productGroupName=" +
        currItemGroupname +
        "&YearId=" +
        currEndYearId +
        "&productId=" +
        currProductId +
        "&ProductGroup=" +
        activeProductfre.name
    );
  };
  const PrintPDFExcelExportGapAnalysisExcelFunction = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/CountryProfileGapAnalysisExcel.php";

    window.open(
      finalUrl +
        "?action=getGapAnalysisExcelReport" +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now() +
        "&ItemGroupId=" +
        currProductGroupId +
        "&productGroupName=" +
        currItemGroupname +
        "&YearId=" +
        currEndYearId +
        "&productId=" +
        currProductId +
        "&ProductGroup=" +
        activeProductfre.name
    );
  };
  /* =====End of Excel Export Code Gap Anlaysis==== */
  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div className="section signup-top-padding sw_inner_container">
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Country Profile"])}
                </div>
              </div>
            </div>
            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>
                  <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="YearList"
                        disableClearable
                        options={YearList}
                        onChange={(event, newValue) =>
                          handleYearChange(event, newValue)
                        }
                        getOptionLabel={(option) => option.name}
                        defaultValue={
                          YearList[
                            YearList.findIndex(
                              (YearList) => YearList.id == currEndYearId
                            )
                          ]
                        }
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["End Year"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="ProductGroup"
                        disableClearable
                        options={ProductGroup}
                        onChange={(event, newValue) =>
                          handleProductGroupChange(event, newValue)
                        }
                        getOptionLabel={(option) => option.name}
                        defaultValue={
                          ProductGroup[
                            ProductGroup.findIndex(
                              (ProductGroup) =>
                                ProductGroup.id == currProductGroupId
                            )
                          ]
                        }
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Groups"]}
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}

            {/* end row */}

            {/* ==============================Parameter List Start============================ */}
            <div className="row mb-3">
              <div className="col-md-6">
                <Card className="sw_card">
                  <CardHeader
                    title={DispensingLanguage[lan][menukey]["Parameter List"]}
                    action={
                      <div className="float-right sw_btn_control">
                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 mt-1 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("print")}
                        >
                          <i class="fas fa-print"></i>
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 mt-1   sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    }
                  />
                  <CardContent>
                    <ReactTabulator
                      height={380}
                      columns={Parametercolumns}
                      data={dataItems}
                      layout={"fitColumns"}
                    />
                  </CardContent>
                </Card>
              </div>
              {/* ================================Parameter List End========================= */}
               {/*================================Quantity Required (Expressed in US$) Start ===============================*/}
              <div className="col-md-6">
              <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey][
                        "Quantity Required (Expressed in US$)"
                      ]
                    }
                    action={
                      <div className="float-right sw_btn_control">
                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 mt-1 sw_icons"
                          onClick={() => PrintPDFExcelExportQuantityRequiredFunction("print")}
                        >
                          <i class="fas fa-print"></i>
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 mt-1 sw_icons"
                          onClick={() => PrintPDFExcelExportQuantityRequiredExcelFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    }
                  />
                  <CardContent>
                    <ReactTabulator
                      height={380}
                      columns={QuantityRequiredcolumns}
                      data={dataItemsQ}
                      options={{
                        groupBy: ["GroupName"],
                      }}
                      layout={"fitColumns"}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
          
            {/*================================Quantity Required (Expressed in US$) Start ===============================*/}

            {/* <div className="row mb-3">
              <div className="col-md-6">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey][
                        "Quantity Required (Expressed in US$)"
                      ]
                    }
                    action={
                      <div className="float-right sw_btn_control">
                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 mt-1 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("print")}
                        >
                          <i class="fas fa-print"></i>
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 mt-1 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    }
                  />
                  <CardContent>
                    <ReactTabulator
                      height={380}
                      columns={QuantityRequiredcolumns}
                      data={dataItemsQ}
                      options={{
                        groupBy: ["GroupName"],
                      }}
                      layout={"fitColumns"}
                    />
                  </CardContent>
                </Card>
              </div>
            </div> */}

            {/* =========================== Quantity Required (Expressed in US$) End ================================*/}
            {/*============================  Gap Analysis (Expressed in US$) Start=====================================*/}

            <div className="row mb-3">
              <div className="col-md-12">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey][
                        "Gap Analysis (Expressed in US$)"
                      ]
                    }
                    action={
                      <div className="float-right sw_btn_control">
                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 mt-1 sw_icons"
                          onClick={() =>
                            PrintPDFExcelExportGapAnalysisFunction("print")
                          }
                        >
                          <i class="fas fa-print"></i>
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 mt-1 sw_icons"
                          onClick={() =>
                            PrintPDFExcelExportGapAnalysisExcelFunction("excel")
                          }
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    }
                  />
                  <CardContent>
                    <ReactTabulator
                      height={480}
                      columns={fieldList}
                      data={dataItemsGap}
                      layout={"fitColumns"}
                      options={{
                        rowFormatter: (row) => {
                          const rows = row.getTable().getRows();
                          if (row === rows[rows.length - 1]) {
                            row.getElement().classList.add(classes.lastRow);
                          }
                        }
                        
                      }}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>

            {/*=========================================Gap Analysis (Expressed in US$) End=================================*/}
          </div>
        </div>
      </>
    )
  );
};

export default CountryProfileReport;

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
  lastRow: {
    backgroundColor: '#50ABED !important',
  },
});
