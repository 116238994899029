import React, { useEffect, useState } from "react";//, 
import { useParams } from "react-router-dom";
import {makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";  
import { useQuery, useMutation,useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const IssuedToGroupModal =({ handleClose, openOrder, addIssuedToGroup, ...props }) => {

const classes = useStyles(); 

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "issue";

const { id } = useParams();
const queryClient = useQueryClient();

const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

const [formData, setFormData] = useState({
    IssuedToGroup: ""
  });
  const [errorObject, setErrorObject] = useState({});

  const { isLoading, mutate } = useMutation(api.saveIssueToGroup, {
    onSuccess: (data) => {

      if (data.status == 200) {
        
        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'IssueToGroupList'
        };

        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
        .then((response) => {
          localStorage.setItem(
            "IssueToGroupList",
            JSON.stringify(response.datalist.IssueToGroupList)
          );
        });

        setIssueToGroupData(data);
        setbtnDisable(true);
        setTimeout(() => setbtnDisable(false), 500);
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

const validateForm = (formData) => {
    let validateFields = [
      "IssuedToGroup", 
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
};

const handleSubmit = async (e) => {

    if(formData.IssuedToGroup==""){
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Issued To Group is required."],
        msgtype: 0,
      });
      return;
    }
    
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  const [IssueToGroupData, setIssueToGroupData] = useState([]);

  const [btnDisable, setbtnDisable] = useState(false);
  const SaveSelectedProducts = () => {
      setbtnDisable(true);
      setTimeout(() => setbtnDisable(false), 500);
  }; 

  useEffect(() => {

    addIssuedToGroup(IssueToGroupData);

}, [IssueToGroupData]); 

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('IssuedToGroupModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="sm"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('IssuedToGroupModal')}
        >
         {DispensingLanguage[lan][menukey]['Add Issue to Group']}
      </BootstrapDialogTitle>
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} className="marginBottom20">
                        <TextField
                          required
                          disabled={props.permissionType}
                          id="IssuedToGroup"
                          name="IssuedToGroup"
                          label= {DispensingLanguage[lan][menukey]['Issue to Group'] }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                    </Grid>
                    </Grid> 
                </Grid>
            </Grid>
          </CardContent>
        </Card>
       
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
          onClick={() => handleSubmit()}
          >
           {DispensingLanguage[lan][menukey]['Save']} 
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('IssuedToGroupModal')}>
            {DispensingLanguage[lan][menukey]['CANCEL']} 
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default IssuedToGroupModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});