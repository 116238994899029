import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Typography,
} from "@material-ui/core";
import * as Service from "../../../services/Service.js";

import { useTranslation } from "react-i18next";
import { Autocomplete } from "@material-ui/lab";
import { useCallback } from "react";
import * as api from "../../../actions/api";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useParams } from "react-router-dom";

let tblMenuRefScrollTop = 0;
let tblMenuRef = null;
const ForecastedconsumptionFormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "forecasted-consumption-entry";
  const classes = useStyles();
  const { t } = useTranslation();
  const countryList = JSON.parse(localStorage.getItem("AllCountryList"));
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [dataItems, setDataItems] = useState([]);
  const [manyTableData, setManyTableData] = useState([]);

  const [currProductGroupId, setProductGroupId] = useState();
  const [currItemId, setItemId] = useState();
  const [getselectId, setselectId] = useState(0);

  const [selectedrow, setSelectedrow] = useState(false);

  const [isLoading, setLoading] = useState(true);

  let params = {
    menukey: menukey,
    action: "getForecastedSingleLeftTable",
    YearId: id,
  };

  const { refetch } = useQuery([params], api.getForcastleftData, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
      setSelectedrow(true);
      setTimeout(function () {
        tblMenuRef.table.rowManager.element.scrollTop = tblMenuRefScrollTop;
      }, 10);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  // const { refetch } = useQuery(
  //   ["forecasted-consumption-entry", id],
  //   () =>
  //     api.getForcastleftData(id).then((res) => {
  //       setDataItems(res.data.datalist);
  //     }
  //   ),
  //   {
  //     enabled: Boolean(id),
  //   }
  // );

  const columnsMaster = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Year"],
      field: "Year",
      headerFilter: false,
      width: 75,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "GroupName",
      headerFilter: false,
      headerFilter: true,
      width: 155,
    },

    {
      title: DispensingLanguage[lan][menukey]["Product"],
      field: "ItemName",
      headerFilter: true,
      //width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["#Months"],
      field: "MonthNo",
      hozAlign: "right",
      headerHozAlign: "right",
      //headerSort: false,
      width: 90,
    },
  ];

  useEffect(() => {
    if (selectedrow == true) {
      const row = tblMenuRef.table.getRowFromPosition(0, true);
      if (row) {
        const ItemGroupId = row.getData()["ItemGroupId"];
        const ItemNo = row.getData()["ItemNo"];
        const id = row.getData()["id"];
        tblMenuRef.table.selectRow(id);
        setProductGroupId(ItemGroupId);
        setItemId(ItemNo);
        refreshAllData(ItemGroupId, ItemNo);
      }
    }
  }, [selectedrow]);
  const rowClick = (e, row) => {
    //if (tblMenuRef.table!=null)
    //console.log("tblMenuRef.table;", e.y);
    //tblMenuRefScrollTop=e.y;
    // console.log("tblMenuRef.table;",tblMenuRefScrollTop);
    tblMenuRefScrollTop = tblMenuRef.table.rowManager.element.scrollTop;
    let rowData = row._row.data;
    setProductGroupId(rowData.ItemGroupId);
    setItemId(rowData.ItemNo);
    setselectId(rowData.id);
    setLoading(true);
    refreshAllData(rowData.ItemGroupId, rowData.ItemNo);
  };

  useEffect(() => {
    if (dataItems != undefined)
      if (tblMenuRef.table != null) {
        tblMenuRef.table.selectRow(getselectId);
      }
  }, [dataItems]);

  const refreshAllData = (pItemGroupId, pItemId) => {
    let params = {
      menukey: menukey,
      lan: lan,
      CountryId: 1,
      ItemGroupId: pItemGroupId,
      ItemNo: pItemId,
      YearId: id,
    };
    GetForecastconTable({
      queryKey: [{ ...params, action: "getForecastedSingleRightTable" }],
    });
  };

  const { mutate: GetForecastconTable } = useMutation(
    api.getSingleForecastData,
    {
      onSuccess: (data) => {
        setManyTableData(data.data.datalist);
        setLoading(false);
        setTimeout(function () {
          tblMenuRef.table.rowManager.element.scrollTop = tblMenuRefScrollTop;
        }, 10);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const Columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Month Name"],
      field: "MonthName",
      //width: 130,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Consumption"],
      field: "StockQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 170,
      cssClass: "tabluator-column-editable",
      //headerFilter: true,
      validator: ["integer", "min:0"],
      editable: true,
      editor: "input",
      cellEdited: function (cell) {
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const id = rowdata.ForecastCId;
        const newValue = cell.getValue().trim();

        let CellOldValue = cell._cell.oldValue;
        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }
        if (CellOldValue != newValue) {
          cellDataUpdate(id, newValue);
        }
      },
    },
  ];

  const { mutate: mutatedUpdate } = useMutation(api.updateForecastConsumption, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        //console.log("RRRR", data);
        setLoading(true);
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        refetch();
        tblMenuRef.table.selectRow(currItemId);
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const cellDataUpdate = (id, cell) => {
    let qtyval = cell;
    mutatedUpdate({ id, qtyval });
  };

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getForecastConsumptions" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&CountryId=" +
        1 +
        "&YearId=" +
        id +
        "&ItemGroupId=" +
        currProductGroupId +
        "&ItemNo=" +
        currItemId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {
                  DispensingLanguage[lan][menukey][
                    "Forecasted Consumption - Edit"
                  ]
                }
              </div>

              <div className="float-right sw_btn_control">
                <Grid item xs={12} className="text-center">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() =>
                      props.history.push("/forecasted-consumption-entry")
                    }
                    //onClick={() => backToList()}
                  >
                    {DispensingLanguage[lan][menukey]["Back to List"]}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </Grid>
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}

        {/* <Grid container spacing={3}> */}
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                {/* new row */}
                <Grid item xs={5} sm={5}>
                  <Card className="sw_card">
                    <CardContent>
                      <ReactTabulator
                        ref={(r) => (tblMenuRef = r)}
                        id="tblMenuRef"
                        options={{
                          selectable: 1,
                          selectableRangeMode: "click",
                          //  maxHeight:"100%",
                          //  height:"100%",
                        }}
                        columns={columnsMaster}
                        data={dataItems}
                        layout={"fitColumns"}
                        height={"540px"}
                        maxHeight={"100%"}
                        rowClick={(e, row) => rowClick(e, row)}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
                <div className="sw_relative">
                  <div className="uniqueName">
                    {isLoading && <LoadingSpinnerOpaque />}
                  </div>
                </div>
             
                {/* new row */}
                <Grid item xs={7} sm={7}>
                  <Card className="sw_card">
                    <CardContent>
                      <ReactTabulator
                        //ref={(r) => (ReceiveDetailsRef = r)}
                        columns={Columns}
                        data={manyTableData}
                        height="540px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>
          </Grid>
        {/* </Grid> */}
      </div>
    </div>
  );
};

export default ForecastedconsumptionFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
});
