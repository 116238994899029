import React, { useState } from "react";

import {
  useQuery,
  useMutation,
} from 'react-query'
import moment from "moment";
import * as api from '../../../actions/api';
import OrderToSupplierFormData from './OrderToSupplierFormData.js';

const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const roleObj = UserInfo == 0 ? "" : UserInfo[0].role;
const UserRoleId = UserInfo == 0 ? 0 : roleObj[0].id;
const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
const UserName = UserInfo === 0 ? "" : UserInfo[0].name;

const AddOrder = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "order-to-supplier";
  const FacilityId = localStorage.getItem('FacilityId');
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;

  const [selectedDate, setSelectedDate] = useState(
    // new Date()
    moment().format("YYYY-MM-DD")
  );

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState('');
  const [bCompleted, setbCompleted] = useState("-1");

  const [formData, setFormData] = useState({
    OrderDate: selectedDate,
    OrderNo: generatedInvoiceNo,
    SupplierId: "",
    //OrderTypeId: "",
    bCompleted: -1,
    ManyJsonSave: "",
    "FacilityId": FacilityId,
    Remarks:"",
    //OrderBy: "",
    //InternalApprover: "",
    //ApprovedBy:""
  });

  const [ManyTableData, setManyTableData] = useState([]);


  const handleRDateChange = (date, field) => {

    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});


  let params = { 
    "menukey": "order", 
    "FacilityId": FacilityId, 
    OrderDate: moment(formData.OrderDate).format("DD/MM/YYYY"),
    ChangeTransactionDate:true 
  };
  const { data: invoiceNo } = useQuery([params], api.getOrdInvoiceNo,
    {
      onSuccess: (data) => {

        setGeneratedInvoiceNo(data);
        let fdata = { ...formData };
        fdata["OrderNo"] = data;
        setFormData(fdata);

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log('formData: ', formData);
    data[name] = value;
    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;  
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
  }

  const handleReset = () => {
    setFormData({
      OrderNo: generatedInvoiceNo,
      OrderDate: selectedDate,
      SupplierId: "",
      //OrderTypeId: "",
      ManyJsonSave: "",
      FacilityId: FacilityId,
      Remarks:"",
      //OrderBy: "",
      //InternalApprover: "",
      //ApprovedBy:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["OrderDate", "OrderNo", "SupplierId"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  function checkValidateForm(rows, callfrom) {

    if (callfrom == "edit") {
      setManyTableData(rows);
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
      let fDtat = { ...formData, 'ManyJsonSave': rows, "menukey": menukey, "lan": lan, "UserName": UserName };
      //mutateUpdate(fDtat); 
      return fDtat;
    } else {
      return false;
    }
  };

  return (
    <>
      <OrderToSupplierFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleReset={handleReset}
        //handleSubmit={handleSubmit}
        ManyTableData={ManyTableData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        generatedInvoiceNo={generatedInvoiceNo}
        bCompleted={bCompleted}
        DisableAddBtn={true}
        checkValidateForm={checkValidateForm}
        handleChangeChoosenProps={handleChangeChoosenProps}
        {...props} />
    </>
  );
};

export default AddOrder;