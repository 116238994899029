import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card,CardContent,Button,Grid,Checkbox} from "@material-ui/core";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
const FundingSource = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "country-profile-entry";
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [manyTableData, setManyTableData] = useState([]);

  useEffect(() => {
    refreshAllData(props.ItemGroupId, props.YearId);
  }, [props.ItemGroupId,props.YearId]);

  const refreshAllData = (pItemGroupId, pyearid) => {
    let params = {
      menukey: menukey,
      lan: lan,
      ItemGroupId: pItemGroupId,
      YearId: pyearid,
    };
    GetfundingsourceTable({
      queryKey: [{ ...params, action: "getYcProfileFundingSourceAssign" }],
    });
  };

  const { mutate: GetfundingsourceTable } = useMutation(
    api.getFundingScourceData,
    {
      onSuccess: (data) => {
        setManyTableData(data.data.datalist);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const columns = [
   // { title: "Id", field: "id", width: 50, visible: false },
    {
      title: "",
      field: "chkValue",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="chkValue"
          fieldType="check-box"
        />
      ),
      cellClick: function (e, cell) {
        cell.setValue(!cell.getValue());
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const YearlyFundingSrcId = rowdata.YearlyFundingSrcId;
        const FundingSourceId = rowdata.FundingSourceId;

        cellDataUpdatePop(YearlyFundingSrcId,FundingSourceId, cell);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Funding Source Name"],
      field: "FundingSourceName",
      headerSort: false,
      headerFilter: true,
     
    },
  ];

  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    // let labelName = props.labelName;
    // let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }


  const cellDataUpdatePop = (YearlyFundingSrcId,FundingSourceId,cell) => {
    let checkVal = cell.getValue();

    let ItemGroupId = props.ItemGroupId;
    let YearId = props.YearId;
    bCheckedUpdate({ YearlyFundingSrcId, FundingSourceId, checkVal,ItemGroupId,YearId});

  };

  const { mutate: bCheckedUpdate } = useMutation(
    api.FundingSourceInsertUpdate,
    {
      onSuccess: (data) => {

        if (data.data.status == 200) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });


        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }

      },
    }
  );

  return (
    <>
    
        <div className="sw_makeStyles_tableContainer">
          <div className="d-flex justify-product mb-1">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Funding Source"]}
                </div>

               
              </div>
            </Grid>
          </div>

          {/* New row */}

          {/* <Grid container spacing={3}> */}
          <div className="sw_relative">
          <div className="uniqueName">
            <Grid item xs={12} sm={12}>
              <Card className="sw_card">
                <CardContent>
                  {isLoading && <LoadingSpinnerOpaque />}
                  <ReactTabulator
                    //ref={(r) => (ReceiveDetailsRef = r)}
                    columns={columns}
                    data={manyTableData}
                    height="400px"
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card>
            </Grid>
          </div>
        </div>
        </div>

      {/* </Box> */}
    </>
  );
};

export default FundingSource;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
});

