import React, { useState } from "react";
import swal from "sweetalert";

const layoutSettings = JSON.parse(localStorage.getItem("LayoutSettings")) || {};

const LayoutSettings = ({ ...props }) => {
  return <></>;
};

export const saveLayoutSettings = (menukey, singleLayoutSettingsParam) => {
  // Merge the existingSavedSettings with the new singleLayoutSettings
  layoutSettings[menukey] = {
    ...layoutSettings[menukey],
    ...singleLayoutSettingsParam,
  };

  // Save the updatedLayoutSettings back to localStorage
  localStorage.setItem("LayoutSettings", JSON.stringify(layoutSettings));
};

export const clearLayoutSettings = (menukey) => {
  if (layoutSettings && layoutSettings[menukey]) {
    // Set the "menukey" property to an empty object
    layoutSettings[menukey] = {};
    localStorage.setItem("LayoutSettings", JSON.stringify(layoutSettings));
  }
};

export const getFilterValue = (menukey, key) => {
  if (
    layoutSettings &&
    layoutSettings[menukey] &&
    layoutSettings[menukey].filter &&
    layoutSettings[menukey].filter[key]
  ) {
    return layoutSettings[menukey].filter[key];
  } else {
    return null;
  }
};
export const getGridLayout = (menukey, key) => {
  if (
    layoutSettings &&
    layoutSettings[menukey] &&
    layoutSettings[menukey].gridLayout &&
    layoutSettings[menukey].gridLayout[key]
  ) {
    return layoutSettings[menukey].gridLayout[key];
  } else {
    return [];
  }
};

export default LayoutSettings;
