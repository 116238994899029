import React, { useState } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
import { useMutation } from "react-query";
// material components
import {
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardContent,
  Button,
  CardHeader,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const PercentageofFacilitiesStockedout = (props) => {
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "percentage-of-facilities-stocked-out";
  const [isLoadingUi, setLoading] = useState(true);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);

  exporting(Highcharts);

  // Filter Start

  
  //region combo list
  const [RegionList, setRegionListData] = useState([]);
  const [activeRegionData, setAcRegionData] = useState({
    id: RegionList.length > 0 ? RegionList[0].id : 0,
    name: RegionList.length > 0 ? RegionList[0].name : "",
  });

  React.useEffect(() => {
    if (activeRegionData.id == 0) {
      getRegionData();
      //getFacilityData(0, 0);
    }
  }, [activeRegionData.id]);

  const handleDepartmentChange = (event, newValue) => { 

    setAcRegionData(newValue);
    getCommuneData(newValue.id); 
  };
  
  const getRegionData = () => {
    let FacilitylevelParam = {
      action: "getDepartmentforreport",
      RoleId: 1, 
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cRegionList(FacilitylevelParam);
  };

  const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setRegionListData(data.data.datalist);

        setAcRegionData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getCommuneData(data.data.datalist[0].id);
      }
    },
  });
 
  ////distict combo list
  const [Communelist, setCommunelistData] = useState([]);
  const [activeCommuneData, setAcCommuneData] = useState({
    id: -1,
    name: Communelist.length > 0 ? Communelist[0].name : "",
  });
 
  const getCommuneData = (RegionId) => {
    let FacilitylevelParam = {
      action: "getcommuneforreport",
      RoleId: 1,
      UserId: UserId,
      RegionId: RegionId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
    onSuccess: (data,params) => {
 
      if (data.status == 200) {
        setCommunelistData(data.data.datalist);

        setAcCommuneData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        
        refreshAllData(params.RegionId,data.data.datalist.length ? data.data.datalist[0].id : 0,currGroupId);
    
      
      }
    },
  });

  // Commune Change
  const handleCommuneChange = (event, newValue) => {

     
    refreshAllData(activeRegionData.id,newValue.id,currGroupId);
    setAcCommuneData(newValue);

    // getFacilityData(activeRegionData.id, newValue.id);
  };

  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Item_label = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const GroupList = [All_Item_label].concat(ProductGroupList);

  const [currGroupId, setcurrGroupId] = useState(0);
  const [GroupName, setgroupName] = useState(
    t(DispensingLanguage[lan][menukey]["All"])
  );

  const handleGroupChange = (event, newValue) => {
    let rowId = "";
    let rowname = "";
    if (newValue == null) {
      rowId = "";
      rowname = "";
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }
 
    refreshAllData(activeRegionData.id,activeCommuneData.id,rowId);

    setcurrGroupId(rowId);
    setgroupName(rowname);
  };

  const [dataItems, setDataItems] = useState([]);

  const refreshAllData = ( RegionId,DistrictId, ItemGroupId) => {
    
    let params = {
      menukey: menukey, 
      lan: lan,
      RegionId: RegionId,
      DistrictId: DistrictId, 
      ItemGroupId: ItemGroupId,
      lan: lan,
    }; 
    GetPercentageOfFacilityStockoutTable({"queryKey":[{...params,action:"PercentageOfFacilityStockoutTable"}]}); 
    GetPercentageOfFacilityStockoutChart({"queryKey":[{...params,action:"PercentageOfFacilityStockoutChart"}]}); 
  };


  const { mutate: GetPercentageOfFacilityStockoutTable } = useMutation(api.PercentageoffacilityStockOutTable, {
    onSuccess: (data) => {

      setDataItems(data.data.datalist);

    },
  });

  const [nationalStockCategory, setNationalStockCategory] = useState([]);
  const [nationalStockData, setNationalStockData] = useState([]);
  // const [nationalStockDateRange, setnationalStockDateRange] = useState([]);
  const option_National_Stock_Summary = () => {
    let National_Stock_Summary = {
      chart: {
        type: "bar",
        borderColor: "#C3DDEC",
        height: 600,
        exporting: {
          enabled: true,
        },
        //   borderWidth: 1,
        //   plotBorderWidth: 1,
        margin: [50, 50, 50, 300],
      },
      title: {
        text: t(
          DispensingLanguage[lan][menukey][
            "Percentage of Facilities Stocked Out"
          ]
        ),
      },
      xAxis: {
        categories: nationalStockCategory,
      },
      yAxis: {
        min: 0,
        max: 36,
        title: {
          text: t(DispensingLanguage[lan][menukey]["Stocked out (%)"]),
          align: "middle",
        },
        labels: {
          overflow: "justify",
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: t(DispensingLanguage[lan][menukey]["Stocked out (%)"]),
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            crop: true,
            formatter: function () {
              return this.y > 0 ? this.y : "";
            },
          },
        },
      },
      series: [
        {
          name: "",
          data: nationalStockData,
        },
      ],
    };

    return National_Stock_Summary;
  };


  const { mutate: GetPercentageOfFacilityStockoutChart } = useMutation(api.PercentageoffacilityStockOutChart, {
    onSuccess: (data) => {

      setNationalStockCategory(data.data.NationalStockCategory.category);

        let Ndata = [];

        for (let i = 0; i < data.data.NationalStockCategory.data.length; i++) {
          let newdata = {};
          newdata.y = data.data.NationalStockCategory.data[i].y;
          newdata.color = data.data.NationalStockCategory.data[i].color;

          Ndata.push(newdata);
        }
        setNationalStockData(Ndata);

    },
  });

 
  
  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "ProductGroup",
      headerSort: false,
      headerFilter: true,
      width: 200,
    },


    {
      title: DispensingLanguage[lan][menukey]["Products"],
      field: "ProductName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,


    },
    {
      title: DispensingLanguage[lan][menukey]["Number of Reporting Facility"],
      field: "FacilityCount",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 205,
    },
    {
      title: DispensingLanguage[lan][menukey]["Number of Facilities Stocked out"],
      field: "StockedIn",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 220,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facilities Stocked out Percent"],
      field: "FacilitiesStockedIn",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 200,
      formatter: "money",
    },


  ];


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ReceiveDetailsReport" +
        "&reportType=" +
        reportType +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&Districtid=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="section signup-top-padding sw_inner_container">
        <div className="sw_inner_card">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(
                    DispensingLanguage[lan][menukey][
                      "Percentage of Facilities Stocked Out"
                    ]
                  )}
                </div>
              </div>
            </Grid>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid> */}

                    <Grid item xs={3} sm={2}>
                    <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="GroupList"
                      disableClearable
                      options={GroupList}
                      onChange={(event, newValue) =>
                        handleGroupChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name}
                      defaultValue={
                        GroupList[
                          GroupList.findIndex(
                            (GroupList) => GroupList.id == currGroupId
                          )
                        ]
                      }
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            DispensingLanguage[lan][menukey]["Groups"]
                          }
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  {/* <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                {/* <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "National Stock Status Report"
                    ]
                  )}
                /> */}
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={option_National_Stock_Summary()}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}

          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "Percentage of Facilities Stocked Out Table"
                    ]
                  )}
                  action={
                    <div className="float-right sw_btn_control">
                      {/* <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button> */}

                      {/* <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button> */}
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PercentageofFacilitiesStockedout;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});
