import React, { useState, useEffect, } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import {  subDays } from "date-fns";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, } from "react-tabulator";

import { useQuery } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Grid,

} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Button } from "reactstrap";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";

import { getDefaultMonthYear } from "../../services/Common";

import { useTranslation } from "react-i18next";


import { checkLogin, checkUserPermission, } from "../../services/CheckUserAccess";

const ReportPage = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, } = useTranslation();
  const menukey = "lmis-report";
  const FacilityId = localStorage.getItem("FacilityId");
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const FacilityList = JSON.parse(localStorage.getItem("FacilityList"));

  //let All_Facility_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All Facility"])};
  //let All_Facility_label = {id:"0", name: "All"};
  //const FacilityList = [All_Facility_label].concat(Facility_List); 
  // const MosTypelist = JSON.parse(localStorage.getItem("MosTypelist"));
  //const bFacilitySelected = localStorage.getItem("bFacilitySelected");

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);
  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");
  //   if (!token) {
  //     swal({
  //       title: "Oops!",
  //       text: 'token expired. Please login again',
  //       icon: "warning",
  //       buttons: ["No", "Yes"],
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) { 
  //         window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
  //         sessionStorage.clear();
  //       }
  //     });
  //   }
  // };

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }


  exporting(Highcharts);
  const classes = useStyles();

  // const [pickerData, setPickerData] = useState([{
  //     startDate: subDays(new Date(), 29),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  // const [isLoadingDashboard, setLoading] = useState(true);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );


  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
  };


  // const [nationalStockCategory, setNationalStockCategory] = useState([]);
  // const [nationalStockData, setNationalStockData] = useState([]);
  // const [nationalStockDateRange, setnationalStockDateRange] = useState([]);
  // const bFacilitySelected = localStorage.getItem("bFacilitySelected");
  const [currFacilityId, setCurrFacilityId] = useState(FacilityId);

  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    setCurrFacilityId(rowId);
  };


  let params1 = {
    action: "getLMISReportTable",
    menukey: menukey,
    FacilityId: currFacilityId,
    Month: currMonthId,
    Year: currYearId,
  };
  const [dataItems, setDataItems] = useState([]);
  const { refetch } = useQuery([params1], api.getLMISReportTable, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    if (currYearId > 0 && currMonthId > 0) {
      // getConsumptionTrendsTopFiveProducts.refetch();
    }
  }, [currYearId, currMonthId, currFacilityId]);




  React.useEffect(() => {
    // checkLogin();
  }, []);

  // const handleDatePicker = (e) => {};

  const columns = [
    /*  {
       title: DispensingLanguage[lan][menukey]["Sl#."],
       formatter: "rownum",
       width: 40,
       hozAlign: "center",
       headerSort: false,
       headerHozAlign: "center",
     }, */
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Designation"],
      field: "ItemName",
      //width: 250,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      headerFilter: true,
      width: 85,
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Stock"],
      field: "OpB",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false }
    },

    //Mouvements du mois group column start

    {
      title: DispensingLanguage[lan][menukey]["Mouvements du mois"],
      hozAlign: "center",
      headerHozAlign: "center",
      columns: [
        {
          title: DispensingLanguage[lan][menukey]["Receive"],
          field: "RecB",
          hozAlign: "right",
          headerHozAlign: "right",
          headerFilter: false,
          width: "100",

        },
        {
          title: DispensingLanguage[lan][menukey]["Quantity Dispensed"],
          field: "Consumption",
          hozAlign: "right",
          headerHozAlign: "right",
          headerFilter: false,
          width: "100",


        },

        {
          title: DispensingLanguage[lan][menukey]["Adjustment"],
          field: "AdjB",
          hozAlign: "right",
          headerHozAlign: "right",
          headerFilter: false,
          width: "100",
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : value;
          },

        },


      ],
    },
    //Mouvements du mois group column end

    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "Stock",
      width: 95,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false }
    },

    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      width: 75,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false }
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock minimum"],
      field: "StockMin",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      //formatterParams:{ thousand:",",  precision:false }

      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["Stock maximum"],
      field: "StockMax",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      //formatterParams:{ thousand:",",  precision:false }
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Stockout Days"],
      field: "StockoutDays",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      //formatterParams:{ thousand:",",  precision:false }
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      width: 75,
      hozAlign: "right",
      headerHozAlign: "right",
      //formatter: "money",
      //formatterParams:{ thousand:",",  precision:false }

      formatter: function (cell, formatterParams) {
        var value = cell.getValue();
        if (value || (value == 0)) {
          return value.toFixed(1);
        } else {
          return "";
        }
      },

    },
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=LMISReportExcelExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" + Date.now() +
      "&Month=" + currMonthId +
      "&Year=" + currYearId +
      "&FacilityId=" + currFacilityId
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div className="section signup-top-padding sw_inner_container">
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["LMIS Report"])}
                </div>
              </div>
            </div>

            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={1}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="YearList"
                        name="YearList"
                        value={currYearId}
                        onChange={handleYearChange}
                        fullWidth
                      >
                        {YearList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="MonthList"
                        name="MonthList"
                        value={currMonthId}
                        onChange={handleMonthChange}
                        fullWidth
                      >
                        {MonthList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="FacilityList"
                        disableClearable
                        options={FacilityList}
                        onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label={DispensingLanguage[lan][menukey]["All Facility"]}
                            variant="standard"
                            hiddenLabel
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}>

                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i className="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>



                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12">
                <Card className="sw_card">
                  {/*  <CardHeader
                  
                /> */}

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="uniqueName">
                          <ReactTabulator
                            columns={columns}
                            data={dataItems}
                            layout={"fitColumns"}
                            height="600px"
                            options={{
                              //groupBy: ["GroupName", "ItemName"],
                              groupBy: "GroupName",
                              columnCalcs: "both",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}
          </div>
        </div>
      </>)
  );
};

export default ReportPage;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
