import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  DeleteOutline,
  FileCopy
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//react-tabulator
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter } from 'react-tabulator';

import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";

import ProductsLotModal from "../../../services/ProductsLotModal";

import { cellFocusEditor, dateEditor,generateBatchId } from "../../../services/Common";

import moment from "moment";

import { fr, enGB } from 'date-fns/locale'; // import French and English locale
import 'moment/locale/fr'; // import Moment.js French locale

import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";


let timeStamp = '';
let isDirty=false;
let masterDirty=false;
const IssueVoucherPushItems = (props) => {
  // console.log("props==",props);

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "issue-voucher-push";
  const TransactionTypeId = 9;
  const FacilityId = localStorage.getItem('FacilityId');
  moment.locale(lan == 'en_GB' ? 'enGB' : 'fr');

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);
  
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({menukey});
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({menukey});

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  // const [SoureOpen, setSoureOpen] = useState(false); 
  // const [DatePickerOpen, setDatePickerOpen] = useState(false); 

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  let IssueToList = JSON.parse(localStorage.getItem("IssueToFacility"));

  const UsersListByFacility = JSON.parse(localStorage.getItem("UsersListByFacility"));

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  // const [currExpiryDate, setCurrExpiryDate] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);

  const [bDisableAddProduct, setDisableAddProduct] = useState(
    props.DisableAddBtn
  );

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {

    let finalUrl = EXCEL_EXPORT_URL + "report/esigl_issue_invoice_entry_list_print.php";

    window.open(
      finalUrl + "?menukey=" + menukey
      + "&lan=" + localStorage.getItem("LangCode")
      + "&TransactionId=" + props.formData.TransactionId
      + "&FacilityId=" + FacilityId
      + "&TimeStamp=" + Date.now()
    );
  };

  const ExcelMany = () => {

    let finalUrl = EXCEL_EXPORT_URL + "report/esigl_Issue_invoice_entry_list_excel.php";

    window.open(
      finalUrl + "?menukey=" + menukey
      + "&lan=" + localStorage.getItem("LangCode")
      + "&TransactionId=" + props.formData.TransactionId
      + "&FacilityId=" + FacilityId
      + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      default:
        break;
    }
  }

  const addProduct = (NonOrderProductData) => {


    let rows = [];
    let autoId = 0;
    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
     
      newRow.BatchId = row.BatchId;
      newRow.ItemNo = row.ItemNo;
      newRow.dirty = row.dirty;
      newRow.ItemCode = row.ItemCode;
      newRow.ManufacturerId = row.ManufacturerId
      newRow.ManufacturerName = row.ManufacturerName
      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.OrderId = row.OrderId;
      newRow.OrderItemId = row.OrderItemId;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      rows.push(newRow);
    });

    let temNonordata = NonOrderProductData;
    temNonordata.forEach((row, i) => {
      autoId++
      let newRow = {};
      newRow.BatchId = row.BatchId;
      newRow.ItemNo = row.ItemNo;

      newRow.ItemCode = row.ItemCode;
      newRow.dirty = 1;
      newRow.ManufacturerId = row.ManufacturerId
      newRow.ManufacturerName = row.ManufacturerName
      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = null;

      manyTableData.forEach((rowssss, ij) => {
        if (rowssss.ItemNo == row.ItemNo) {
          newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
          return;
        }
      });

      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = '';
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;

      newRow.OrderId = null;
      newRow.OrderItemId = null;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);

      

    });


    isDirty=true;
    onSetDisable();



    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose('Products');
  };

  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "BatchId", field: "BatchId", visible: false },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    
    { title: "id", field: "id", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'], width: 70, formatter: "rownum", hozAlign: "center", headerHozAlign: "center", headerSort: false },

    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: 'custom',
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: 'center',
      width: 80,
      formatter: reactFormatter(<ActionButton
     /* insertBatchInUpdatedDetailGridData={props.insertBatchInUpdatedDetailGridData} */ />)
    },

    {
      title: DispensingLanguage[lan][menukey]['Product Code'],
      field: "ItemCode",
      width: 130,
    //  headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]['Product Name'], 
      field: "ItemName", 
    //  headerFilter: true,
      width: 200,
      // },{
      //   title: DispensingLanguage[lan][menukey]['Quantity Ordered'], field: "OrderRemainingQty",
      //   width: 100, hozAlign: "right", headerHozAlign: "right"
    },
    {
      title: DispensingLanguage[lan][menukey]['Current Stock'], field: "CurrentStockQty",
      width: 160, hozAlign: "right", headerHozAlign: "right"
    },

    {
      title: DispensingLanguage[lan][menukey]['Quantity Issued'],
      field: "Quantity",
      width: 160,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0", function (cell, value, parameters) {
        if (value == "") return true;
        // if (!props.isPositive) return true;
        const balance = cell.getRow().getCell('CurrentStockQty').getValue();
        const validstatus = parseInt(value) <= parseInt(balance);
        return validstatus;
      }],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : '',
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);

      
        /*
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.UnitPrice * curcellval;
        pricecell.setValue(totprice);
        */

        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }

      }
    },//
    {
      title: DispensingLanguage[lan][menukey]['Lot No'],
      field: "BatchNo",
      width: 160,
      // cssClass: "tabluator-column-editable text-field-editable",
      // editor: props.bStockUpdate == 0 ? cellFocusEditor: '',      
      // cellEdited: function (cell) {
      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData(); 
      // }
    },
    {
      title: DispensingLanguage[lan][menukey]['Expiry Date'],
      field: "ExpiryDate",
      width: 150,
      // cssClass: "tabluator-column-editable text-field-editable",
      // editor: props.bStockUpdate == 0 ? cellFocusEditor: '',    
      // cellEdited: function (cell) {

      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData(); 
      // }
    },

    {
      title: DispensingLanguage[lan][menukey]['Manufacturer'],
      field: "ManufacturerName",
      //width: 250,
    },
    // {
    //   title: "",
    //   headerSort: false,
    //   field: "custom",
    //   hozAlign: "center",
    //   width: 8,
    //   formatter: reactFormatter(<ShowDatePickerPopup />),
    // },



    // {
    //   title: "",
    //   headerSort: false,
    //   field: "custom",
    //   hozAlign: "center",
    //   width: 8,
    //   formatter: reactFormatter(<ShowSourcePopup />),
    // },

    // { title: DispensingLanguage[lan][menukey]['Unit Price (CFA)'], field: "UnitPrice" , width: 100, hozAlign: "right", headerHozAlign:"right", 

    // },
    // { title: DispensingLanguage[lan][menukey]['Line Total (CFA)'], field: "LineTotal" , width: "10%",
    //   formatter: "money", hozAlign: "right", headerHozAlign:"right",
    //   bottomCalc: "sum",
    //   bottomCalcFormatter: "money"

    // }

  ];



  const onSetDisable = () => {  
    if(isDirty==true){   
      //document.getElementById("is_dirty_message").classList.remove("dnone"); 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }
    else{  
     //document.getElementById("is_dirty_message").classList.add("dnone"); 
     document.getElementById("master_dirty_message").classList.add("dnone");
    }    
  }


  const onMasterSetDirty = () => {   
    if(masterDirty==true){ 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  }

  /*
  const onPostBtnClick = () => {
    setbStockUpdate(true);
    props.handlePost();

  }

  */


  const onPostBtnClick = () => {
    if (ReceiveDetailsRef == null) return;


    ///===================Check qty is blank of any item==========/////////////

    let blankinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (row.Quantity === null || row.Quantity === "" || row.Quantity === 0) {
        blankinfo = row;
      }
    });

    if (Object.keys(blankinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["Blank quantity is not allowed"]) + ' - ' + blankinfo.ItemName,
        msgtype: 0,
      });
      return;
    }
    ///===================Check qty is blank of any item==========/////////////




    ///===================Check duplicate lots exist in invoice==========/////////////
    // if (ReceiveDetailsRef == null) return;

    /*
    
    let invoiceLotIds = [];
    let duplicateinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if(invoiceLotIds.includes(row.ItemListLotId)){
        duplicateinfo = row;
      }else{
        invoiceLotIds.push(row.ItemListLotId);
      }
    });

    // console.log('invoiceLotIds: ', invoiceLotIds);
    // console.log('duplicateinfo: ', duplicateinfo);
    
    if(Object.keys(duplicateinfo).length>0){
      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["Duplicate Lot available"]) + ' - ' + duplicateinfo.ItemName,
        msgtype: false,
      });
      return;
    }

    */
    ///===================Check duplicate lots exist in invoice==========/////////////


    //setbStockUpdate(true);
    // props.handlePost();
    handlePost();
  };

  /*
  const handlePost1112 = async (e) => {

    const isDuplicate = isContainDuplicateForExistingData(ReceiveDetailsRef.table.getData());
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+ " - " + isDuplicate.data,
        msgtype: 0,
      });


    } else {

    let validForm = props.checkValidateForm(isDuplicate.data, 'post');
    
    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        
        
      }).then((willAction) => {
        if (willAction) {
          let fDtat = { ...validForm, "bStockUpdated": 1 };
          //console.log('fDtat: ', fDtat);
          mutateUpdate(fDtat);

        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          // let route = `${TransactionId}`;
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert


    }

  }
  };

  */


  const handlePost = async (e) => {

    const isDuplicate = isContainDuplicateForExistingData(ReceiveDetailsRef.table.getData());
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+ " - " + isDuplicate.data,
        msgtype: 0,
      });


    } else {


    let manydataP=[]; 
    isDuplicate.data.forEach((row,i)=>{
      if(row.dirty==1)
      manydataP.push(row);
    
    })

    let validForm = props.checkValidateForm(manydataP, 'post');
    
    if (validForm) {

      let fDtat = { ...validForm, "bStockUpdated": 1, "masterDirty":masterDirty };
      modalPopupDelete(mutateUpdate, fDtat, {PostQ:"modalQPost"}, ModalNo,validForm); 

      }

   }

  };

  const ModalNo=(validForm)=>{
       
    props.stockPosted(0);
    let route = `${validForm.TransactionId}`;
    props.history.push(route);

  }

  const isContainDuplicateForExistingData = (dataArray) => {

    
    const removedDuplicate = new Set(dataArray.map((el) => el.BatchId));

    if (dataArray.length !== removedDuplicate.size) {
      return { data: '', status: true };
    } else {
      setManyTableData(dataArray);
      return { data: dataArray, status: false };
    };


  };


  const onSaveUpdateBtnClick = () => {
    
    let manydata = ReceiveDetailsRef.table.getData();
    const isDuplicate = isContainDuplicateForExistingData(manydata);
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"], //+" - "+isDuplicate.data,
        msgtype: 0,
      });
    } else {
      setUpdatedDetailsGridDataHandler(isDuplicate.data);
    }

    
  };


  /*
  const onSaveUpdateBtnClick = () => {

    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    
  }

  */


  const setUpdatedDetailsGridDataHandler = (rows) => {

    let manydataR=[]; 
    rows.forEach((row,i)=>{
      if(row.dirty==1)
      manydataR.push(row);
    
    })

    let validForm = props.checkValidateForm(manydataR, 'edit');
    // console.log('isval: ', validForm);
    if (validForm) {

      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutate({...validForm,'masterDirty':masterDirty});
      } else {
        //when update
        mutateUpdate({...validForm,'masterDirty':masterDirty});
      }



    }
  };


  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveIssueVoucerPushInv, {
    onSuccess: (data) => {
      if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `edit/${data.TransactionId}`;
        props.history.push(route);

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(api.updateIssueVoucerPushInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;

        //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        if (data.bStockUpdated == 1) {
          mutatePost(props.formData.TransactionId);
        }
        else {
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(api.IssuePushPost, {
    onSuccess: (data) => {
      if (data.status == 200) {


        // setDeletedDataSet([]);

        if (data.data.success == 0) {
          //setbStockUpdate(0);
          props.stockPosted(0);
          props.refetchInvoice();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        } else {
          // setbStockUpdate(1);
          props.stockPosted(1);
          props.refetchInvoice();

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        }

      } else {
        // setbStockUpdate(0);
        props.stockPosted(0);
        props.refetchInvoice();
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  });

  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter(element => element !== data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
    isDirty = true;
    onSetDisable();
  };

  const CopyBatch = () => {

    props.handleAdd(manyTableData);

  };

  function ActionButton(props) {

    const rowData = props.cell._cell.row.data;
    // const goToDetails = () => {

    //   let rowobj = props.cell._cell.row.data;
    //   console.log("I copied this line", rowobj);
    //   timeStamp = Math.round(new Date().getTime() / 100);

    //   setManyTableData((prevRows) => {

    //     const tmpPrevRows = [...prevRows];

    //     let setRposition = tmpPrevRows.findIndex((o) => o.SKU ==rowobj.SKU);
    //     console.log("Rposition",setRposition);

    //     const obj = { ...rowobj };

    //     obj.id = timeStamp;
    //     obj.TransactionItemId = "";
    //     obj.ExpiryDate = "";
    //     obj.BatchNo = "";
    //     obj.Quantity = 0;
    //     obj.SKU = Date.now();

    //     console.log("new object added in grid:", obj);
    //     tmpPrevRows.splice(setRposition+1, 0, obj)

    //     return tmpPrevRows;
    //   });

    //   CopyBatch();
    //   setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);


    // };


    const cellValue = props.cell._cell.value || 'Edit | Show';
    // console.log('props props props',rowData.OrderItemId);
    if (bStockUpdate == 0 && !(rowData.OrderItemId))
      return (
        <>

          {/* <FileCopy
          onClick={() => {
            if (!bStockUpdate) goToDetails(rowData);
          }}
        /> */}

          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />

        </>
      );
    else return (
      <>

      </>
    );
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //


  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != '') {

      // console.log("Many table",ReceiveDetailsRef.table);
      // console.log("Many table 12222222222");

      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);

    }
  }, [newBatchRowAdded])

  const handleReset = () => {
    setManyTableData([]);
    props.handleReset();
  };


  const backToList = () => {

    if((masterDirty==true)||(isDirty==true)){
      
      modalPopupBacktoList(backToListCallBack, "/issue-voucher-push", {PostQ:"modalBackToListQ"}); 
  
    }else props.history.push("/issue-voucher-push")
    
  };

  const backToListCallBack = (PageUrl) => {
      masterDirty=false;
      isDirty=false;
      props.history.push(PageUrl);
  }


  const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

     masterDirty=true;
     onMasterSetDirty();

   };

  const [issuedToFacilityValue, setIssuedToFacilityValue] = useState({ id: '', name: "" });

  useEffect(() => {
    setIssuedToFacilityValue(IssueToList[IssueToList.findIndex(IssueTo_List => IssueTo_List.id == props.formData.IssuedToFacility)]);
  }, [props.formData.IssuedToFacility]);

  const changeissuedToFacilityValue = (val) => {

    setIssuedToFacilityValue(val);

    if (val != null) {
      props.formData.IssuedToFacility = val.id;
      //setDisableAddProduct(false);

    } else {
      props.formData.IssuedToFacility = "";
    }

    props.handleChangeissuedToFacilityProps(val);

    masterDirty=true;
    onMasterSetDirty();

  };

  /*
  useEffect(() => {
    if (props.addProductForm) {
      if (props.formData.PreparedBy == "") {
        props.formData.PreparedBy = UserId;
      }
    }
  }, [props.formData.PreparedBy, UserId, props.addProductForm]);

  */

  const [chosenValues, setChosenValues] = useState({
    "PreparedBy": { "id": "", "name": "" },
    "ApprovedBy": { "id": "", "name": "" }
  });

  useEffect(() => {

    let chosenValuesData = { ...chosenValues };

    chosenValuesData['PreparedBy'] = UsersListByFacility[UsersListByFacility.findIndex(Users_ListByFacility => Users_ListByFacility.id == props.formData.PreparedBy)];
    chosenValuesData['ApprovedBy'] = UsersListByFacility[UsersListByFacility.findIndex(Users_ListByFacility_A => Users_ListByFacility_A.id == props.formData.ApprovedBy)];
    setChosenValues(chosenValuesData);

  }, [props.formData.PreparedBy, props.formData.ApprovedBy]);


  const handleChangeChoosen = (name, valueobj, value) => {

    console.log("a 1",name);
    console.log("a 2",valueobj);
    console.log("a 3",value);
    console.log("Form old value",props.formData.PreparedBy);

    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;

    setChosenValues(chosenValuesData);

    props.handleChangeChoosenProps(name, value);

    masterDirty=true;
    onMasterSetDirty();
  };

  useEffect(() => {

    let chosenValuesData = { ...chosenValues };

    if (props.addProductForm) {
      if (props.formData.PreparedBy == '') {
        chosenValuesData['PreparedBy'] = UsersListByFacility[UsersListByFacility.findIndex(Users_ListByFacility => Users_ListByFacility.id == UserId)];
        props.formData.PreparedBy = UserId;
      }
    }
    setChosenValues(chosenValuesData);

  }, [UserId]);
  
    /*
  let validForm={};
  const handlePost = async (e) => {

    validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');
    // console.log('isval: ', validForm);
    // console.log('props from post: ', props);

    // if (validateForm(formData)) {
    if (validForm) {
      let fDtat = { ...validForm, "bStockUpdated": 1 };

      modalPopupDelete(mutateUpdate, fDtat, {PostQ:"modalQPost"}, ModalNo,validForm); 


//  // setbStockUpdate(0);
//  props.stockPosted(0);
//  // let route = `${TransactionId}`;
//  let route = `${validForm.TransactionId}`;
//  props.history.push(route);
       


    }


  };

  const ModalNo=(validForm)=>{


    console.log("ModalNo",validForm)
     setbStockUpdate(0);
  props.stockPosted(0);
//  // let route = `${TransactionId}`;
  let route = `${validForm.TransactionId}`;
  props.history.push(route);
  }

  */

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>

            <div className="sw_page_heading">

              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Issue Voucher Push - Add/Edit']}</div>

              <div className="float-right sw_btn_control">

                {
                  props.addProductForm ?
                    <Grid item xs={12} className="text-center">
                      <div className="float-right sw_btn_control">

                      <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                        {/* <Button
                          className="mr-2"
                          disabled={props.bStockUpdate || isLoadingSave}
                          variant="contained"
                          type="reset"
                          onClick={() => handleReset()}
                        // onClick={() => props.handleReset()}
                        >
                          {DispensingLanguage[lan][menukey]['Reset']}
                        </Button> */}
                        <Button
                          className="mr-2"
                          disabled={props.bStockUpdate || isLoadingSave}
                          variant="contained"
                          color="primary"
                          onClick={onSaveUpdateBtnClick}//() => props.handleSubmit()
                        >
                          {DispensingLanguage[lan][menukey]['Save']}
                        </Button>
                        <Button
                          className="mr-2"
                          variant="contained"
                          type="reset"
                          //onClick={() => props.history.push("/issue-voucher-push")}
                          onClick={() => backToList()}
                        >
                          {DispensingLanguage[lan][menukey]['Back to List']}
                        </Button>
                      </div>
                    </Grid>
                    :
                    <Grid item xs={12} className="text-center">
                      <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                      <Button
                        disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}

                      >
                        {DispensingLanguage[lan][menukey]['update']}
                      </Button>
                      <Button
                        //disabled={props.bStockUpdate}
                        disabled={props.bStockUpdate || !(manyTableData.length > 0) || isLoadingUpdate || isLoadingPost}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onPostBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]['post']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/issue-voucher-push")}
                        onClick={() => backToList()}
                      >
                        {DispensingLanguage[lan][menukey]['Back to List']}
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => PrintMany()}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        onClick={() => ExcelMany()}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </Grid>
                }
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == 'en_GB' ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]['Issue Date']}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        value={props.formData.TransactionDate || props.selectedDate}
                        format="dd/MM/yyyy"
                        //onChange={(e) => props.handleRDateChange(e, "TransactionDate")}
                        onChange={(e) =>formOnchange(e,'handleRDateChange','TransactionDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true/*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={DispensingLanguage[lan][menukey]['Issue Invoice No']}
                      // value={formData.FirstName || "Mahmudul"}
                      value={props.formData.TransactionNo || props.generatedInvoiceNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        // disabled={demanderactive}
                        disabled={props.bStockUpdate}
                        autoHighlight
                        id="IssuedToFacility"
                        options={IssueToList}
                        onChange={(event, value) => changeissuedToFacilityValue(value)}
                        getOptionLabel={(option) => option.name}
                        value={issuedToFacilityValue}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.IssuedToFacility}
                            label={DispensingLanguage[lan][menukey]["Recipient"]}
                            variant="standard"
                            error={props.errorObject.IssuedToFacility}
                            helperText={props.errorObject.IssuedToFacility}
                            required
                            id="IssuedToFacility"
                            name="IssuedToFacility"
                            fullWidth
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">

                        {DispensingLanguage[lan][menukey]["Prepared By"]} <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.PreparedBy}
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.IsCompleted || props.bStockUpdate}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  
                  

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">

                        {DispensingLanguage[lan][menukey]["Approved By"]} <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.ApprovedBy}

                        labelId="demo-simple-select-helper-label"
                        id="ApprovedBy"
                        name="ApprovedBy"
                        value={props.formData.ApprovedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.IsCompleted || props.bStockUpdate}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.ApprovedBy}>
                        {props.errorObject.ApprovedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}


                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={props.IsCompleted || props.bStockUpdate}
                        autoHighlight
                        id="PreparedBy"
                        options={UsersListByFacility}
                        onChange={(event, valueobj) => handleChangeChoosen('PreparedBy', valueobj, valueobj ? valueobj.id : "")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['PreparedBy']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.PreparedBy}
                            label={DispensingLanguage[lan][menukey]["Prepared By"]}
                            variant="standard"
                            error={props.errorObject.PreparedBy}
                            helperText={props.errorObject.PreparedBy}
                            id="PreparedBy"
                            name="PreparedBy"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={props.IsCompleted || props.bStockUpdate}
                        autoHighlight
                        id="ApprovedBy"
                        options={UsersListByFacility}
                        onChange={(event, valueobj) => handleChangeChoosen('ApprovedBy', valueobj, valueobj ? valueobj.id : "")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['ApprovedBy']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ApprovedBy}
                            label={DispensingLanguage[lan][menukey]["Approved By"]}
                            variant="standard"
                            error={props.errorObject.ApprovedBy}
                            helperText={props.errorObject.ApprovedBy}
                            id="ApprovedBy"
                            name="ApprovedBy"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  

                  {/* <Grid item xs={3} sm={3}>
                      <TextField
                        disabled={props.bStockUpdate}
                        error={props.errorObject.SupplierInvNo}
                        helperText={props.errorObject.SupplierInvNo}
                        required
                        id="SupplierInvNo"
                        name="SupplierInvNo"
                        label={DispensingLanguage[lan][menukey]['Supplier Invoice No'] } 
                        value={props.formData.SupplierInvNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) =>props.handleChange(e)}
                      />
                    </Grid>   */}

                  {/* 
                    <Grid item xs={3} sm={3}>
                      <TextField
                        disabled={props.bStockUpdate}
                        id="DeliveredBy"
                        name="DeliveredBy"
                        label={DispensingLanguage[lan][menukey]['Delivered By']} 
                        value={props.formData.DeliveredBy}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => props.handleChange(e)}
                      />
                    </Grid> */}

                  <Grid item xs={3} sm={3}>

                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>

                </Grid>
              </Grid>


              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                            <Button
                              // disabled={props.bStockUpdate}
                              disabled={
                                props.bStockUpdate
                                  ? props.bStockUpdate
                                  : bDisableAddProduct
                              }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("ProductsOpen")}
                            >
                              {DispensingLanguage[lan][menukey]['SELECT PRODUCT']}
                            </Button>
                          </div>
                          <ProductsLotModal handleClose={handleClose} open={open} FacilityId={FacilityId} addProduct={addProduct} {...props} />

                        </Grid>
                      </Grid>
                      <ReactTabulator

                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData.sort((a, b) => {
                          const groupComparison = a.GroupName.localeCompare(b.GroupName);
                          return groupComparison !== 0 ? groupComparison : a.ItemName.localeCompare(b.ItemName);
                        })}
                        height="350px"
                        layout={"fitColumns"}
                        options={{
                          groupBy: "GroupName",
                          columnCalcs: "both",
                        }}
                      />

                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>



            {/* </CardContent>
          </Card> */}
          </Grid>


          {/* Action buttons */}

        </Grid>
      </div>
    </div>
  );
};

export default IssueVoucherPushItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
