import React, { useState, useEffect } from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import AefiItems from './AefiItems.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";
import moment from "moment";

const EditAefi = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "aefi";
  const FacilityId = localStorage.getItem('FacilityId');

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    SheetNo: '',
    DateCNRP: "",
    LastName: "",
    FirstName: "",
    DateofBirth: "",
    Year: "",
    Month: "",
    GenderTypeId: "",
    Weight: "",
    Height: "",
    IfYesPregnancy: "",
    TheDDR: "",
    Address: "",
    Telephone: "",
    HistoryFactors: "",
    Pregnancy: 0,
    FacilityId: FacilityId,
    MedicineTakenFromId: '',
    OriginSpecify: '',
    IfMedicinalName: '',
    PartUsed: '',
    QuantityUsed: '',
    MethodOfPreparation: '',
    RouteOfAdministration: '',
    NotifierFullName: '',
    // Occupation: '',
    ProfessionId: '',
    HealthInstitution: '',
    PhoneOrEmail: '',
    DateOfNotification: '',
  });

  const [selectedDate, setSelectedDate] = useState(
    // new Date()
    moment().format("YYYY-MM-DD")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [AdverseEventManyData, setAdverseEventManyData] = useState([]);
  const [UpdatedAdverseEventGridData, setUpdatedAdverseEventGridData] = useState([]);

  const [StockPost, setStockPost] = useState(false);

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState('');
  const [DateTransactionChange, setDateTransactionChange] = useState("");

  const [bFirst, setBFirst] = useState(true);

  const handleRDateChange = (date, field) => {

    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }

    //setFormData({ ...formData, [field]: newDate });
    if(field=="DateCNRP"){

      if(moment(newDate).format("YYYY")!=moment(DateTransactionChange).format("YYYY")){
        
        //setTimeout(() => function(){
          mutateInv({"queryKey":[{ 
          menukey: "aefi",
          FacilityId: FacilityId,
          DateCNRP: moment(newDate).format("DD/MM/YYYY"),
          ChangeTransactionDate:newDate
           }]});
         //}, 500);
  
      }else{
          setGeneratedInvoiceNo(generatedInvoiceNo);
          setFormData({ ...formData, [field]: newDate, ["SheetNo"]: generatedInvoiceNo });
      }
  
      }else{
          setFormData({ ...formData, [field]: newDate});
      }

    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data, refetch } = useQuery(['aefi', id], () =>
    api.getMasterSingleDataList(id).then((res) => {

      setTransactionId(id);
      setFormData(res.data);
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);

      setAdverseEventManyData(res.data.AdverseEventDataList);//Adverse Event Data
      setUpdatedAdverseEventGridData(res.data.AdverseEventDataList);

      setGeneratedInvoiceNo(res.data.SheetNo);
      setDateTransactionChange(res.data.DateCNRP);
      setDeletedDataSet([]);
      setBFirst(true);

      return res.data
    }), {
    refetchOnWindowFocus: false,
    enabled: Boolean(id)
  })

  const {  mutate:mutateInv } = useMutation(api.getSheetNo, {
    onSuccess: (data,params) => {
      //moment(params.queryKey[0].TransactionDate).format("YYYY-MM-DD")
      setFormData(
        { ...formData, 
        ["DateCNRP"]: params.queryKey[0].ChangeTransactionDate,
        ["SheetNo"]:data 
        });
    }
  })

  const handleAdd = (NonOrderProductData) => {

    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;  
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
  }

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      SheetNo: '',
      DateCNRP: "",
      LastName: "",
      FirstName: "",
      DateofBirth: "",
      Year: "",
      Month: "",
      GenderTypeId: "",
      Weight: "",
      Height: "",
      IfYesPregnancy: "",
      TheDDR: "",
      Address: "",
      Telephone: "",
      HistoryFactors: "",
      Pregnancy: 0,
      FacilityId: FacilityId,
      MedicineTakenFromId: '',
      OriginSpecify: '',
      IfMedicinalName: '',
      PartUsed: '',
      QuantityUsed: '',
      MethodOfPreparation: '',
      RouteOfAdministration: '',
      NotifierFullName: '',
      // Occupation: '',
      ProfessionId: '',
      HealthInstitution: '',
      PhoneOrEmail: '',
      DateOfNotification: '',
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["SheetNo", "DateCNRP", "LastName", "FirstName",  "GenderTypeId"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const deleteReceiveInv = (data) => {
    let newarray = ManyTableData.filter(element => element !== data);

    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if (data.TransactionItemId != null) {
      let newDeletedDataSet = [data.TransactionItemId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
    }
  }

  const handleChangeissuedToFacilityProps = (e) => {
    if (e !== 'null') {
      setErrorObject({ ...errorObject, ['IssuedToFacility']: null });
    }
  };

  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function refetchInvoice(){
      refetch();
  };

  function stockPosted(val){
    setbStockUpdate(val);
  };

  return (
    <>
      <AefiItems
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        // handleUpdate={handleUpdate}
        ManyTableData={updatedDetailGridData}
        AdverseEventManyData={UpdatedAdverseEventGridData}
        handleRDateChange={handleRDateChange}
        handleChangeissuedToFacilityProps={handleChangeissuedToFacilityProps}
        selectedDate={selectedDate}
        handleAdd={handleAdd}
        bStockUpdate={bStockUpdate}
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        //handlePost={handlePost}
        deleteReceiveInv={deleteReceiveInv}
        generatedInvoiceNo={generatedInvoiceNo}
        DisableAddBtn={false}
        bFirst={bFirst}
        refetch={refetch}


        checkValidateForm={checkValidateForm}
        refetchInvoice={refetchInvoice}
        stockPosted={stockPosted}
        handleChangeChoosenProps={handleChangeChoosenProps}
        
        {...props} />
    </>
  );
};

export default EditAefi;