import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
import { checkLogin, checkUserPermission, getUserActionPermission } from "../../../services/CheckUserAccess";

import swal from "sweetalert";
// Products screen
import FieldDispense from "./FieldDispense";
import AddFieldDispense from "./AddFieldDispense"
import EditFieldDispense from "./EditFieldDispense";

const Index = (props) => {

  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "field-dispense";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);

  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {

  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });

  const openNoticeModal = (obj) => {
    if(document.getElementById("Snackbarh")!=null)
    document.getElementById("Snackbarh").classList.remove("dnone");
    let obj1 = obj;
     obj1 = { ...obj, duration: 0};
    setMsgObj(obj1);
    setTimeout(function(){
      document.getElementById("Snackbarh").classList.add("dnone"); 
     }, 5000);
  };

  const closeNoticeModal = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgObj({ isOpen: false });
  };

  return (
    hasUserPermission && (
      <div>
        <AfterLoginNavbar {...props} />
        <div className="section signup-top-padding sw_inner_container">

          <Switch>
            <Route
              path={`${path}/add`}
              render={(props) => (
                <AddFieldDispense {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
            <Route
              path={`${path}/edit/:id`}
              render={(props) => (
                <EditFieldDispense {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
            <Route
              path={`${path}/`}
              render={(props) => (
                <FieldDispense {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
          </Switch>

          <Notification
            closeNoticeModal={closeNoticeModal}
            msgObj={msgObj}
            {...props}
          ></Notification>

        </div>
      </div>)
  );
};

export default Index;

const useStyles = makeStyles({

});
