import React, { useState } from "react";

import {
  useQuery,
  useMutation,
} from 'react-query'
import { useParams } from 'react-router-dom';

import swal from "sweetalert";
import * as api from '../../../actions/api';
import OrderFormData from './OrderFormData.js';
import { useTranslation } from "react-i18next";
import moment from "moment";

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const roleObj = UserInfo == 0 ? "" : UserInfo[0].role;
const UserRoleId = UserInfo == 0 ? 0 : roleObj[0].id;
const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
const UserName = UserInfo === 0 ? "" : UserInfo[0].name;

const EditOrder = (props) => {

  const FacilityId = localStorage.getItem('FacilityId');
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const menukey = "order";
  const lan = localStorage.getItem("LangCode");

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    OrderDate: "",
    OrderNo: "",
    OrderingFrom: "",
    OrderTypeId: "",
    ManyJsonSave: "",
    "FacilityId": FacilityId,
    OrderBy: UserId,
    Remarks:""
    //InternalApprover: "",

  });


  const [selectedDate, setSelectedDate] = useState(
    // new Date()
    moment().format("YYYY-MM-DD")
  );

  const [bCompleted, setbCompleted] = useState("-1");
  const [ManyTableData, setManyTableData] = useState([]);
  const [OrderId, setOrderId] = useState("0");
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState('');
  const [DateTransactionChange, setDateTransactionChange] = useState("");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [bFirst, setBFirst] = useState(true);
  const [ItemGroupId, setItemGroupId] = useState(0);


  // =============== ONLY ORDERED ITEMS START ===============
  const [checked, setChecked] = useState(false);
  const [OnlyOrderedItems, setOnlyOrderedItems] = useState(false);

  const handleOnlyOrderdItems = (event) => {
    setChecked(event.target.checked);
    setOnlyOrderedItems(event.target.checked);
  };

  // =============== ONLY ORDERED ITEMS END ===============

  const handleDataTableChange = (e) => { };

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  // const queryClient = useQueryClient();


  const handleRDateChange = (date, field) => {

    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    //setFormData({ ...formData, [field]: newDate });
    if(field=="OrderDate"){

      if(moment(newDate).format("YYYY")!=moment(DateTransactionChange).format("YYYY")){
  
        //setTimeout(() => function(){
          mutateInv({"queryKey":[{ 
          menukey: "order",
          FacilityId: FacilityId,
          OrderDate: moment(newDate).format("DD/MM/YYYY"),
          ChangeTransactionDate:newDate
           }]});
         //}, 500);
  
      }else{
          setGeneratedInvoiceNo(generatedInvoiceNo);
          setFormData({ ...formData, [field]: newDate, ["OrderNo"]: generatedInvoiceNo });
      }
  
      }else{
          setFormData({ ...formData, [field]: newDate});
      }

    setErrorObject({ ...errorObject, [field]: null });
  };


  const { data, refetch: refetchOrderInv } = useQuery(['order', id, ItemGroupId, OnlyOrderedItems], () =>
    api.getOrderInv({ OrderId: id, ItemGroupId: ItemGroupId, OnlyOrderedItems: OnlyOrderedItems }).then((res) => {
      setOrderId(id);
      setFormData(res.data);
      setbCompleted(res.data.bCompleted);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.OrderNo);
      setDateTransactionChange(res.data.OrderDate);
      setBFirst(true);
      return res.data

    }), {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
      enabled: Boolean(id)
  })

  /*
  const { mutate: mutateUpdate } = useMutation(api.updateOrderInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        if (data.data.IsCompleted == 0) {
          let FinalData = { OrderId, ItemGroupId: ItemGroupId };
          mutatePost(FinalData);
        } else {
          let route = `${data.data.data.OrderId}`;
          setbCompleted(data.data.data.bCompleted);
          setManyTableData(data.data.data.ManyJsonSave);
          props.history.push(route);

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      } else {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })

  const { mutate: mutatePost } = useMutation(api.postOrderInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        let route = `${OrderId}`;
        setbCompleted(data.data.data.bCompleted);
        setManyTableData(data.data.data.ManyJsonSave);

        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      } else {

        refetchOrderInv();

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })

  const setUpdatedDetailsGridDataHandler = (rows) => {

    if (validateForm(formData)) {
      let fDtat = { ...formData, 'ManyJsonSave': rows, ItemGroupId: ItemGroupId };
      mutateUpdate(fDtat);
    }
    setManyTableData(rows);

  }
  */

  const {  mutate:mutateInv } = useMutation(api.getOrdInvoiceNo, {
    onSuccess: (data,params) => {

      //moment(params.queryKey[0].TransactionDate).format("YYYY-MM-DD")
      console.log("n paras",params.queryKey[0].ChangeTransactionDate);
      setFormData(
        { ...formData, 
        ["OrderDate"]: params.queryKey[0].ChangeTransactionDate,
        ["OrderNo"]:data 
        });

    }
  })

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;
    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };


  const handleReset = () => {
    setFormData({
      OrderDate: "",
      OrderNo: "",
      Remarks:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["OrderDate", "OrderNo", "OrderingFrom", "OrderTypeId"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = "field is Required !"
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }
  /*
  const handleUpdate = async (e) => {

    let ValidationCount = onDataValidation(ManyTableData);
    if (ValidationCount > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Please, mention reason of actual order quantity change"],
        msgtype: 0,
        duration: 10000,
      });
      return;
    }

    if (validateForm(formData)) {
      let FinalData = { ...formData, 'ManyJsonSave': ManyTableData, ItemGroupId: ItemGroupId };
      mutateUpdate(FinalData);
    }
  };

  const handlePost = async (e) => {

    let ValidationCount = onDataValidation(ManyTableData);
    if (ValidationCount > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Please, mention reason of actual order quantity change"],
        msgtype: 0,
        duration: 10000,
      });
      return;
    }

    if (validateForm(formData)) {
      let FinalData = { ...formData, 'ManyJsonSave': ManyTableData, "IsCompleted": 0, ItemGroupId: ItemGroupId };

      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to complete this order stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]),
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {

          mutateUpdate(FinalData);
        }
      });

    }

  };

  const onDataValidation = (data) => {
    // console.log(data, '=======')
    let ValidationCount = 0;

    for (let i = 0; i < data.length; i++) {
      let CalcOrderQty = data[i].CalcOrderQty;
      let ActualOrderQty = data[i].ActualOrderQty;
      let Remarks = data[i].Remarks;

      if (ActualOrderQty != null && ActualOrderQty != '' && ActualOrderQty != CalcOrderQty) {
        if ((Remarks == null) || (Remarks == "")) {
          ValidationCount++;
        }
      }

      // if(ActualOrderQty == '') {
      //   ValidationCount++;
      // }
 

      // if ((ActualOrderQty != CalcOrderQty) || ((Remarks == null) || (Remarks == ""))) {
      //   ValidationCount++;
      // }

      if (ValidationCount > 0) {
        return ValidationCount;
      }
    }
    return ValidationCount;
  }

  */

  const filterFandleChange = (e) => {

    setItemGroupId(e);
  };

  /*
  function checkValidateForm(rows, callfrom) {

    if (callfrom == "edit") {
      setManyTableData(rows);
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
      let fDtat = { ...formData, 'ManyJsonSave': rows, ItemGroupId: ItemGroupId, menukey: menukey, lan: lan, "UserName": UserName };
      //mutateUpdate(fDtat); 
      return fDtat;
    } else {
      return false;
    }
  };
  */

  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }
  
    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows, ItemGroupId: ItemGroupId, menukey: menukey,lan: lan,"UserName":UserName}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }

  };
  
  function successSave(bStockUpdated){
    if(bStockUpdated !==0 ){
      refetchOrderInv();
    }
  };
  
  function stockPosted(val){
    setbCompleted(val);
  };

  return (
    <>
      <OrderFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleReset={handleReset}
        //handleUpdate={handleUpdate}
        ManyTableData={ManyTableData}
        handleDataTableChange={handleDataTableChange}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        bCompleted={bCompleted}
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        //handlePost={handlePost}
        generatedInvoiceNo={generatedInvoiceNo}
        bFirst={bFirst}
        filterFandleChange={filterFandleChange}
        checkValidateForm={checkValidateForm}
        checked={checked}
        handleOnlyOrderdItems={handleOnlyOrderdItems}
        refetch={refetchOrderInv}
        successSave={successSave}
        stockPosted={stockPosted}
        {...props} />
    </>
  );
};

export default EditOrder;