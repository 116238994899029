import React, { useState, useEffect, } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import {  subDays } from "date-fns";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, } from "react-tabulator";

import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  InputLabel,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Grid,

} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Button } from "reactstrap";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";


import { getDefaultMonthYear } from "../../services/Common";

import { useTranslation } from "react-i18next";
import { checkLogin, checkUserPermission, } from "../../services/CheckUserAccess";

import moment from "moment";
import { fr, enGB } from 'date-fns/locale'; // import French and English locale
import 'moment/locale/fr'; // import Moment.js French locale

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";


const ReportPage = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, } = useTranslation();
  const menukey = "lab-products-nearing-expiry-report";
  // const FacilityId = localStorage.getItem("FacilityId");
  //const YearList = JSON.parse(localStorage.getItem("YearList"));
  //const MonthList = JSON.parse(localStorage.getItem("MonthList"));

  const Item_List = JSON.parse(localStorage.getItem("Itemlist"));
  const Itemlist_Lab = Item_List.filter(item => item.ItemGroupId == 12);

  let All_Item_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Product"]) };
  const Itemlist = [All_Item_label].concat(Itemlist_Lab);
  const [currPorductId, setcurrPorductId] = useState(0);

  /* start new filter */

  moment.locale(lan == 'en_GB' ? 'enGB' : 'fr');

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
    //moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().add(3, "M").format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  const Regionlist = JSON.parse(localStorage.getItem("Regionlist"));

  const [currRegionId, setcurrRegionId] = useState(0);
  const [currZoneId, setcurrZoneId] = useState(0);
  const [currCommuneId, setcurrCommuneId] = useState(0);
  let TempZoneList = JSON.parse(localStorage.getItem("Zonelist"));
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);

  let TempCommuneList = JSON.parse(localStorage.getItem("Communelist"));
  const [Communelist, setCommunelist] = useState(TempCommuneList);

  let TempFacilitylist = JSON.parse(localStorage.getItem("FacilityList"));
  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"]) };
  const Facility_List = [All_Facility_label].concat(TempFacilitylist);
  const [Facilitylist, setFacilitylist] = useState(Facility_List);
  const [currFacilityId, setCurrFacilityId] = useState(0);


  const { mutate: cZoneList } = useMutation(

    api.gZonelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setZoneListLocal(data.data.datalist);

          // console.log("Dta list", data.data.RegionId);

          let CParam = { action: "gCommunelist", RegionId: data.data.RegionId, ZoneId: 0, menukey: "" }
          cCommuneList(CParam);


        }

      },
    }
  );


  const { mutate: cCommuneList } = useMutation(

    api.gCommunelist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          setCommunelist(data.data.datalist);

          let FParam = { action: "gFacilitylist", RegionId: data.data.RegionId, ZoneId: data.data.ZoneId, CommuneId: 0, menukey: "" }
          cFacilityList(FParam);


        }

      },
    }
  );

  const { mutate: cFacilityList } = useMutation(

    api.gFacilitylist,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"]) };
          const Facility_List = [All_Facility_label].concat(data.data.datalist);
          setACValue({ id: '0', name: t(DispensingLanguage[lan][menukey]["All Facility"]) });
          setFacilitylist(Facility_List);
          setCurrFacilityId(0);


        }

      },
    }
  );


  const handleRegionChange = (event) => {
    setcurrZoneId(0);
    setcurrCommuneId(0);
    setCurrFacilityId(0);
    let ZoneParam = { action: "gZonelist", RegionId: event.target.value, menukey: "" }
    cZoneList(ZoneParam);
    setcurrRegionId(event.target.value);
  };

  const handleZoneChange = (event) => {
    setcurrCommuneId(0);
    setCurrFacilityId(0);
    let CommuneParam = { action: "gCommunelist", RegionId: currRegionId, ZoneId: event.target.value, menukey: "" }
    cCommuneList(CommuneParam);
    setcurrZoneId(event.target.value);
  };

  const handleCommuneChange = (event) => {

    setCurrFacilityId(0);
    let FacilityParam = { action: "gFacilitylist", RegionId: currRegionId, ZoneId: currZoneId, CommuneId: event.target.value, menukey: "" }
    cFacilityList(FacilityParam);


    setcurrCommuneId(event.target.value);
  };


  /*  const handleFacilityChange = (event) => {
 
     setCurrFacilityId(event.target.value);
   }; */


  const [acValue, setACValue] = useState({ id: '0', name: t(DispensingLanguage[lan][menukey]["All Facility"]) });

  const handleFacilityChange = (val) => {
    setACValue(val);
    if (val != null) {
      //formData.IssuedTo = val.id;
      setCurrFacilityId(val.id);


    }


  };



  /* const handleFacilityChange = (event, newValue) => {
      let rowId = '';
      if(newValue == null){
        rowId = '';
      }else{
        rowId = newValue.id;
      }
  
      setCurrFacilityId(rowId);
   };  */


  /* end new filter */

  //exporting(Highcharts); 
  const classes = useStyles();

  // const [pickerData, setPickerData] = useState([{
  //     startDate: subDays(new Date(), 29),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  const [isLoadingDashboard, setLoading] = useState(true);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );


  // const handleYearChange = (event) => {
  //   setCurrYearId(event.target.value);
  // };

  // const handleMonthChange = (event) => {
  //   setCurrMonthId(event.target.value);
  // };





  const handleProductChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    setcurrPorductId(rowId);
  };


  let params1 = {
    action: "getLabProductsNearingExpiryReportTable",
    menukey: menukey,
    RegionId: currRegionId,
    ZoneId: currZoneId,
    CommuneId: currCommuneId,
    FacilityId: currFacilityId,
    ItemNo: currPorductId,
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    // Month: currMonthId,
    //Year: currYearId,
  };
  const [dataItems, setDataItems] = useState([]);
  const { refetch } = useQuery([params1], api.getLabProductsNearingExpiryReportTable, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    if (currYearId > 0 && currMonthId > 0) {
      // getConsumptionTrendsTopFiveProducts.refetch();
    }
  }, [currYearId, currMonthId, currFacilityId]);

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  /* const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
  }, []);

  // const handleDatePicker = (e) => {};

  const columns = [
    /*  {
       title: DispensingLanguage[lan][menukey]["Sl#."],
       formatter: "rownum",
       width: 40,
       hozAlign: "center",
       headerSort: false,
       headerHozAlign: "center",
     }, */
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "DepartmentName",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["ZS"],
      field: "ZoneName",
      width: 180,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Commune"],
      field: "CommuneName",
      headerFilter: true,
      width: 155,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      headerFilter: true,
      width: 140,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product"],
      field: "ItemName",
      headerFilter: true,
      //width: 140,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      headerFilter: true,
      width: 100,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Expiry Date"]),
      width: 120,
      field: "ExpiryDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "LotQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false }
    },


  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
  let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=LabProductsNearingExpiryReportExcelExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" + Date.now() +
      "&RegionId=" + currRegionId +
      "&ZoneId=" + currZoneId +
      "&CommuneId=" + currCommuneId +
      "&StartDate=" + StartDate +
      "&EndDate=" + EndDate +
      "&ItemNo=" + currPorductId +
      "&FacilityId=" + currFacilityId
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div className="section signup-top-padding sw_inner_container">
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Lab Products Nearing Expiry Report"])}
                </div>
              </div>
            </div>

            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>


                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Department"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="RegionId"
                        name="RegionId"
                        value={currRegionId}
                        onChange={handleRegionChange}
                        fullWidth
                      >
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Department"]}</MenuItem>

                        {Regionlist.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                          );
                        })}


                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["ZS"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="ZoneId"
                        name="ZoneId"
                        value={currZoneId}
                        onChange={handleZoneChange}
                        fullWidth
                      >
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All ZS"]}</MenuItem>

                        {Zonelist.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}


                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Commune"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="DistrictId"
                        name="DistrictId"
                        value={currCommuneId}
                        onChange={handleCommuneChange}
                        fullWidth
                      >
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All Commune"]}</MenuItem>

                        {Communelist.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}


                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>

                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>


                  </Grid>


                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        //disabled={props.addProductForm==true?false: props.formData.InvFrom!=''?true: (manyTableData.length>0? true:props.bStockUpdate)}

                        autoHighlight={true}
                        id="Facilitylist"
                        options={Facilitylist}
                        disableClearable
                        onChange={(event, value) => handleFacilityChange(value)}
                        getOptionLabel={(option) => option.name}
                        value={acValue}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //value={formData.Facilitylist}
                            label={DispensingLanguage[lan][menukey]["Facility"]}
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />


                    </FormControl>

                  </Grid>


                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>

                      <Autocomplete
                        autoHighlight
                        className="sw_fontSize"
                        id="Itemlist"
                        disableClearable
                        options={Itemlist}
                        onChange={(event, newValue) => handleProductChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={Itemlist[Itemlist.findIndex(itemlist => itemlist.id == currPorductId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Product"]}
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>


                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == 'en_GB' ? enGB : fr}
                    >
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          className={classes.fullWidth}
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={t(DispensingLanguage[lan][menukey]["From"])}
                          autoOk={true}
                          value={selectedStartDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == 'en_GB' ? enGB : fr}
                    >
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          className={classes.fullWidth}
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={t(DispensingLanguage[lan][menukey]["To"])}
                          autoOk={true}
                          value={selectedEndDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>




                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12">
                <Card className="sw_card">
                  {/*  <CardHeader
                      
                    /> */}

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="uniqueName">
                          <ReactTabulator
                            columns={columns}
                            data={dataItems}
                            layout={"fitColumns"}
                            height="600px"
                            options={{
                              //groupBy: ["FacilityName", "GroupName"],
                              groupBy: "FacilityName",
                              columnCalcs: "both",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}
          </div>
        </div>
      </>)
  );
};

export default ReportPage;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
