import React, { useState } from "react";
import { styled } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useQuery, useMutation } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme








const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


const BootstrapDialogTitle = (props) => {



  const { children, onClose, ...other } = props;



  return (

    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const FacilityGroupMapModal = ({ ...props }) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo === 0 ? '' : UserInfo[0].name;
  const menukey = "facility";
  //get dropdown list
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));

  const classes = useStyles();

  //const [GroupList, setGroupList] = useState(props.GroupList);
  const [errorObject, setErrorObject] = useState({});
  const [formData, setFormData] = useState({
    ItemGroupId: "",
    // MonthId: "",
    // YearID: "",
    Supply_From: ""
  });

  const [datasup, setDatasupp] = useState([]);
  const [checkedState, setCheckedState] = useState(
    new Array(props.GroupList.length + 1).fill(false)
  );


  const { data, refetch: supplierRefresh } = useQuery(
    ['getSupplier'], () =>
    api.getSupplyFromList({ FacilityId: props.match.params.id }).then((res) => {
      //console.log(res);
      setDatasupp(res);  //

    }), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0
  }
  );

  const onChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setErrorObject({ ...errorObject, [event.target.name]: null });

  };

  const handleOnChange = (position, name) => {



    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );


    //  setTimeout(function(){
    //   console.log(updatedCheckedState);console.log(checkedState);


    // },1000);

    //console.log(updatedCheckedState);
    // console.log(checkedState);
    //  setCheckedState(updatedCheckedState);
    if (name === 'bKeyItemAll') {

      if ((updatedCheckedState[position] === true) || (updatedCheckedState[position] === undefined)) {
        setCheckedState(new Array(checkedState.length + 1).fill(true));
      } else
        setCheckedState(new Array(props.GroupList.length + 1).fill(false));

    }

    if (name === 'bKeyItem') {

      let updatedCheckedState;

      if (name === 'bKeyItem') {
        updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : false
        );
      } else {
        updatedCheckedState = checkedState.map(() => true);
      }

      setCheckedState(updatedCheckedState);

    }
  };
  const handleSubmit = async (e) => {

    if (validateForm(formData)) {

      const glist = checkedState.map((item, index) =>
        ((props.GroupList.length > index) && item === true) ? props.GroupList[index]['id'] : 0
      );

      let grouplist = glist.filter(element => element > 0);

      let fDtat = { ...formData, "menukey": menukey, "lan": lan, "UserName": UserName, "GroupList": grouplist, FacilityId: props.match.params.id };
      AssignGroupFacility(fDtat);


    }
  };

  const { mutate: AssignGroupFacility } = useMutation(api.AssignGroupFacility, {
    onSuccess: (data) => {
      if (data.data.status === 200) {
        props.handleClose('Products');
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

        //  queryClient.getQueriesData("facility");
        // props.history.push("/facility");

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });



  const validateForm = (formData) => {
    let validateFields = ["Supply_From"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  React.useEffect(() => {
    setCheckedState(
      new Array(props.GroupList.length + 1).fill(false)
    );

  }, [props.GroupList]);

  React.useEffect(() => {
  }, [checkedState]);



  return (
    <div>
      <BootstrapDialog
        onClose={() => props.handleClose('Products')}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => props.handleClose('Products')}
          className="mb-3"
        >
          {DispensingLanguage[lan][menukey]['Assign groups']}

        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>

            <Grid item xs={5} sm={5}>

              <Grid item xs={12}>



                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={checkedState[props.GroupList.length]}
                      name="bKeyItemAll"
                      value={checkedState[props.GroupList.length]}
                      id={"bKeyItem_" + props.GroupList.length}
                      onChange={() => handleOnChange(props.GroupList.length, 'bKeyItemAll')}
                    />
                  }
                  label={DispensingLanguage[lan][menukey]["All Product Group"]}

                />
              </Grid>

              {props.GroupList.map((item, index) => {
                return (
                  <Grid key={`GroupList-${index + 1}`} item xs={12} className="marginTop10">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={checkedState[index]}
                          name="bKeyItem"
                          id={"bKeyItem_" + item.id}
                          value={formData.ItemGroupId}
                          onChange={() => handleOnChange(index, 'bKeyItem')}

                        />
                      }
                      label={item.name}
                    />
                  </Grid>
                );
              })}


            </Grid>

            <Grid item xs={7} sm={5}>

              {/* <Grid item xs={12} sm={12}>


                <FormControl className={classes.fullWidth}>
                  <InputLabel id="MonthId-label">
                    {DispensingLanguage[lan][menukey]["Start Month"]}*

                  </InputLabel>
                  <Select
                    error={errorObject.MonthId}
                    helperText={errorObject.MonthId}
                    required
                    labelId="MonthId-label"
                    id="MonthId"
                    name="MonthId"
                    value={formData.MonthId}
                    fullWidth
                    onChange={(e) => onChange(e)}
                  >
                    <MenuItem value="0">
                      {DispensingLanguage[lan][menukey]["Select Month"]}

                    </MenuItem>

                    {
                      MonthList.map((item, index) => {
                        return (
                          <MenuItem key={`MonthList-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText error={errorObject.MonthId}>
                    {errorObject.MonthId}
                  </FormHelperText>
                </FormControl>

              </Grid>


              <Grid item xs={12} sm={12}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="YearID-label">
                    {DispensingLanguage[lan][menukey]["Start Year"]}*

                  </InputLabel>
                  <Select
                    error={errorObject.YearID}
                    helperText={errorObject.YearID}
                    required
                    labelId="YearID-label"
                    id="YearID"
                    name="YearID"
                    value={formData.YearID}
                    fullWidth
                    onChange={(e) => onChange(e)}
                  >
                    <MenuItem value="">
                      {DispensingLanguage[lan][menukey]["Select Year"]}

                    </MenuItem>

                    {YearList.map((item, index) => {
                      return (
                        <MenuItem key={`YearList-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText error={errorObject.YearID}>
                    {errorObject.YearID}
                  </FormHelperText>
                </FormControl>

              </Grid> */}
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="FacilityId-label">
                    {DispensingLanguage[lan][menukey]["Supply From"]}*

                  </InputLabel>
                  <Select
                    error={errorObject.FacilityId}
                    helperText={errorObject.FacilityId}
                    required
                    labelId="FacilityId-label"
                    id="FacilityId"
                    name="Supply_From"
                    value={formData.Supply_From}
                    fullWidth
                    onChange={(e) => onChange(e)}
                  >

                    {datasup.map((item, index) => {
                      return (
                        <MenuItem key={`datasup-${index + 1}`} value={item.FacilityId}>{item.FacilityName}</MenuItem>
                      );
                    })
                    }
                  </Select>
                  <FormHelperText error={errorObject.FacilityId}>
                    {errorObject.FacilityId}
                  </FormHelperText>
                </FormControl>

              </Grid>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {DispensingLanguage[lan][menukey]["ADD"]}

          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => props.handleClose('Products')}
          >
            {DispensingLanguage[lan][menukey]["CANCEL"]}

          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default FacilityGroupMapModal;


const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  facilitygroupmapTitle: {
    marginTop: "40px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});


