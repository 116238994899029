import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  Checkbox,
  TextField,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";

import { ViewList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import ProductSelectInPrescriptionModal from "../../../services/AuditLogModal";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import moment from "moment";

let tblMenuRef = null;

const Auditlog = (props) => {
  const { useState } = React;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const FacilityId = localStorage.getItem("FacilityId");
  const lan = localStorage.getItem("LangCode");
  const menukey = "audit-log";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const Users_List = JSON.parse(localStorage.getItem("UserListEdit"));

  let All_UserList_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const UserList = [All_UserList_label].concat(Users_List);

  const Facility_List = JSON.parse(localStorage.getItem("FacilityList"));
  let All_Facility_label = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const FacilityList = [All_Facility_label].concat(Facility_List);

  const [currFacilityId, setCurrFacilityId] = useState(0);

  const UserRole = UserInfo == 0 ? "" : UserInfo[0].RoleId;

  const classes = useStyles();
  const tableRef = useRef();

  const [dataItems, setDataItems] = useState([]);
  const [dataItems1, setDataItems1] = useState([]);

  const [selectedRoleData, setSelectedRole] = useState(0);
  const [bFirst, setBFirst] = useState(true);

  const [isLoadingll, setLoading] = useState(true);

  const [selectedrow, setSelectedrow] = useState(false);

  const [currRequestType, setcurrRequestType] = useState(0);
  const [currDatabaseTables, setcurrDatabaseTables] = useState(0);
  const [currUserListId, setcurrUserListId] = useState(0);

  const [DbName, setDbName] = useState();
  const [TableName, setTableName] = useState([{ id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) }]);

  const [currRowId, setCurrRowId] = useState(0);
  const [openSqlLog, setopenSqlLog] = useState(false);

  const [LogDataArray, setLogDataArray] = useState([]);

  const handleClose = (modalname) => {
    switch (modalname) {
      case "ProductBatch":
        setopenSqlLog(false);
        break;
      case "ProductBatchOpen":
        setopenSqlLog(true);
        break;

      default:
        break;
    }
  };

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
    setSelectedRole(0);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
    setSelectedRole(0);
  };

  let params0 = {
    menukey: menukey,
    action: "getDataList",
    logStartDate: selectedStartDate,
    logEndDate: selectedEndDate,
    RequestType: currRequestType,
    cboDatabaseTables: currDatabaseTables,
    UserList: currUserListId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    FacilityId: currFacilityId,
  };

  const { isLoading, error, isError, data, refetch } = useQuery(
    [params0],
    api.getAuditLogData,
    {
      onSuccess: (data) => {
        setDataItems(data);

        //setSelectedrow(true);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  let params = {
    menukey: menukey,
    action: "getAuditLogList",
    log_id: selectedRoleData,
  };

  const {
    isLoading1,
    error1,
    isError1,
    data: logData,
    refetch: MenuRefetch,
  } = useQuery([params], api.getLogSingleRow, {
    onSuccess: (logData) => {
      setDataItems1(logData);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  let params2 = {
    menukey: menukey,
    action: "getDataBaseInfo",
  };

  const {
    isLoading2,
    error2,
    isError2,
    data: logData2,
    refetch: MenuRefetch2,
  } = useQuery([params2], api.gDataBaseInfo, {
    onSuccess: (logData2) => {
      setDbName(logData2.DBName);
      //console.log("DB Name", logData2.DBName);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  let params3 = {
    menukey: menukey,
    action: "getTableInfo",
    DataBaseName: DbName,
  };

  const {
    isLoading3,
    error3,
    isError3,
    data: logData3,
    refetch: MenuRefetch3,
  } = useQuery([params3], api.gTableInfo, {
    onSuccess: (logData3) => {
      let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
      const TableList = [All_Facility_label].concat(logData3);
      setcurrDatabaseTables(0);
      setTableName(TableList);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const rowClick = (e: any, row: any) => {
    let rowData = row._row.data;
    console.log(rowData.id);
    setSelectedRole(rowData.id);
    //MenuRefetch();
  };

  const handleRequestTypeChange = (event) => {
    setcurrRequestType(event.target.value);
    setSelectedRole(0);
  };

  // const handleDatabaseTablesChange = (event) => {
  //   setcurrDatabaseTables(event.target.value);
  //   setSelectedRole(0);
  // };

  const handleDatabaseTablesChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    setcurrDatabaseTables(rowId);
    setSelectedRole(0);
  };

  // const handleUserListIdChange = (event) => {
  //   setcurrUserListId(event.target.value);
  //   setSelectedRole(0);
  // };
  const handleUserListIdChange = (event, newValue) => {

    let rowId = '';
    let Fname = '';
    if (newValue == null) {
      rowId = '';
      Fname = '';
    } else {
      //rowId = newValue.id;
      if (newValue.id == 0) {
        rowId = "0";
      } else {
        rowId = newValue.name;
      }

      Fname = newValue.name;
    }


    //if(rowId!==currFacilityId){
    setcurrUserListId(rowId);
    setSelectedRole(0);
    //}


  };

  useEffect(() => {
    //handleClose("ProductBatchOpen");
  }, [LogDataArray]);

  useEffect(() => {
    console.log("useEffect calling for refresh");
    if (selectedRoleData > 0) {
      MenuRefetch();
    }
  }, [selectedRoleData]);

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      formatter: "rownum",
      width: 60,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Date"],
      field: "LogDate",
      headerFilter: true,
      width: 132,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Id"],
      field: "FacilityId",
      headerFilter: true,
      width: 97,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["User"],
      field: "userName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Remote IP"],
      field: "RemoteIP",
      headerFilter: true,
      width: 102,
    },
    {
      title: DispensingLanguage[lan][menukey]["Query Type"],
      field: "QueryType",
      headerFilter: true,
      width: 70,
    },
    {
      title: DispensingLanguage[lan][menukey]["Table Name"],
      field: "TableName",
      headerFilter: true,
    },
    {
      title: "",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 8,
      formatter: reactFormatter(<ShowSqlLogPopup />),
    },
  ];

  const columns1 = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      formatter: "rownum",
      width: 30,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Field Name"],
      field: "FieldName",
      width: 110,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Old Value"],
      field: "OldValue",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["New Value"],
      field: "NewValue",
      headerSort: false,
    },
  ];

  useEffect(() => {
    // if(selectedrow == true){
    //   tblMenuRef.table.selectRow(1);
    // }
  }, [selectedrow]);

  function ShowSqlLogPopup(props: any) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <ViewList
          onClick={() => {
            setCurrRowId(rowData.id);
            setLogDataArray([rowData.SqlText, rowData.SqlParams]);
            //console.log("gggggggggggggg",rowData);
            handleClose("ProductBatchOpen");
          }}
        />
      </>
    );
  }

  // Facility Change
  const handleFacilityChange = (event, newValue) => {
    let rowId = "";
    let Fname = "";
    if (newValue == null) {
      rowId = "";
      Fname = "";
    } else {
      rowId = newValue.id;
      Fname = newValue.name;
    }

    if (rowId !== currFacilityId) {
      setCurrFacilityId(rowId);
    }
  };

  // End Facility Change
  // useEffect(() => {
  //   if (tblMenuRef == null) return;
  //   if(bFirst){
  //     setDataItems(data);
  //   }

  //  }, [dataItems]);

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {" "}
                {DispensingLanguage[lan][menukey]["Audit Log"]}
              </div>
            </div>
          </Grid>
        </div>

        <ProductSelectInPrescriptionModal
          handleClose={handleClose}
          open={openSqlLog}
          LogDataArray={LogDataArray}
          {...props}
        />

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={1} sm={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Log Start Date"]}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={1} sm={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Log End Date"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Request Type"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="RequestType"
                    name="RequestType"
                    value={currRequestType}
                    onChange={handleRequestTypeChange}
                    fullWidth
                  >
                    <MenuItem value="0">
                      {DispensingLanguage[lan][menukey]["All"]}
                    </MenuItem>
                    <MenuItem value="INSERT">INSERT</MenuItem>
                    <MenuItem value="UPDATE">UPDATE</MenuItem>
                    <MenuItem value="DELETE">DELETE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={2} sm={2}>

              <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="DatabaseTables"
                    disableClearable
                    options={TableName}
                    //disabled={UserName=="admin"?false:true}
                    onChange={(event, newValue) => handleDatabaseTablesChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={TableName[TableName.findIndex(Table_Name => Table_Name.id == currDatabaseTables)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Table Name"]}
                        variant="standard"

                      />
                    )}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={3} sm={3}>
              <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="UserListId"
                    disableClearable
                    options={UserList}
                    onChange={(event, newValue) => handleUserListIdChange(event, newValue)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={UserList[UserList.findIndex(User_List => User_List.id == currUserListId)]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.name}</Typography>
                    )}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["User List"]}
                        variant="standard"

                      />
                    )}
                  />
                </FormControl>



                {/* <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["User List"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="UserListId"
                    name="UserListId"
                    value={currUserListId}
                    onChange={handleUserListIdChange}
                    fullWidth
                  >
                    <MenuItem value="0">
                      {" "}
                      {DispensingLanguage[lan][menukey]["All"]}
                    </MenuItem>

                    {UserList.map((item, index) => {
                      return <MenuItem value={item.name}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl> */}
              </Grid>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="FacilityList"
                    disableClearable
                    options={FacilityList}
                    onChange={(event, newValue) =>
                      handleFacilityChange(event, newValue)
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      FacilityList[
                        FacilityList.findIndex(
                          (facilitylist) => facilitylist.id == currFacilityId
                        )
                      ]
                    }
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <div className="sw_relative">
                <div className="uniqueName">
                  <ReactTabulator
                    ref={(r) => (tblMenuRef = r)}
                    options={{
                      selectable: 1,
                      selectableRangeMode: "click",
                    }}
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height={"500px"}
                    // tooltips={true}
                    // dataTree={true}

                    rowClick={(e, row) => rowClick(e, row)}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>
              <div className="sw_relative">
                <div className="uniqueName">
                  <ReactTabulator
                    columns={columns1}
                    data={dataItems1}
                    layout={"fitColumns"}
                    height={"500px"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Auditlog;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
