import React, { useState, useEffect ,useRef} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
//import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';


//get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "user-entry";


const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
// const userId = UserInfo==0?'': UserInfo[0].id;

const DispenserFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();

  // const { t, i18n } = useTranslation();

  const RoleList = JSON.parse(localStorage.getItem("RoleList"));
  const LanguageList = JSON.parse(localStorage.getItem("LanguageList"));
  const FacilityList = JSON.parse(localStorage.getItem("FacilityList"));

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    "role_id": { "id": "", "name": "" },
    "FacilityId": { "id": "", "name": "" }
  });


  useEffect(() => {

    let chosenValuesData = { ...chosenValues };
    chosenValuesData['role_id'] = RoleList[RoleList.findIndex(Role_List => Role_List.id == formData.role_id)];
    chosenValuesData['FacilityId'] = FacilityList[FacilityList.findIndex(Facility_List => Facility_List.id == formData.FacilityId)];
    setChosenValues(chosenValuesData);

  }, [formData.role_id, formData.FacilityId]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  
  const inputRef = useRef(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute('autoComplete', 'off');
    }
  }, []);


  //====== End Choosen Dropdown===========


  // const getRxLevel = (type = null) => {
  //   let level = ""; 
  //   if (formData.rxLevel) {
  //     level = formData.rxLevel;
  //   }
  //   return level;
  // };

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["User - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/user-entry")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/user-entry")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.name}
                      helperText={errorObject.name}
                      required
                      id="name"
                      name="name"
                      label={DispensingLanguage[lan][menukey]["Name"]}
                      value={formData.name}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.email}
                      helperText={errorObject.email}
                      required
                      id="email"
                      name="email"
                      label={DispensingLanguage[lan][menukey]["Email"]}
                      value={formData.email}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.loginname}
                      helperText={errorObject.loginname}
                      required
                      id="loginname"
                      name="loginname"
                      label={DispensingLanguage[lan][menukey]["Login Name"]}
                      value={formData.loginname}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                  <TextField
                          error={errorObject.password}
                          helperText={errorObject.password}
                          style={{ display: 'none' }}
                          inputRef={inputRef}
                          label={DispensingLanguage[lan][menukey]["Change Password"]} 
                        />
                     
                    <TextField
                      error={errorObject.password}
                      helperText={errorObject.password}
                      required
                      id="password"
                      name="password"
                      type="password"
                      label={DispensingLanguage[lan][menukey]["Password"]}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                      error={errorObject.confirmChangePassword}
                      helperText={errorObject.confirmChangePassword}
                      id="confirmChangePassword"
                      name="confirmChangePassword"
                      type="password"
                      label={DispensingLanguage[lan][menukey]["Confirm Password"]}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.designation}
                      helperText={errorObject.designation}
                      required
                      id="designation"
                      name="designation"
                      label={DispensingLanguage[lan][menukey]["Designation"]}
                      value={formData.designation}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Language"]}
                        <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={errorObject.LangCode}
                        labelId="demo-simple-select-helper-label"
                        id="LangCode"
                        name="LangCode"
                        value={formData.LangCode || 'fr_FR'}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {LanguageList.map((item, index) => {
                          return (
                            <MenuItem key={`LanguageList-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.LangCode}>
                        {errorObject.LangCode}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Role"]}*
                      </InputLabel>
                      <Select
                        disabled={formData.id==1?true:false}
                        error={errorObject.role_id}
                        labelId="demo-simple-select-helper-label"
                        id="role_id"
                        name="role_id"
                        value={formData.role_id}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {RoleList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.role_id}>
                        {errorObject.role_id}
                      </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        id="role_id"
                        options={RoleList}
                        onChange={(event, valueobj) => handleChangeChoosen('role_id', valueobj, valueobj ? valueobj.id : "")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['role_id']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.role_id}
                            label={DispensingLanguage[lan][menukey]["Role"] }
                            variant="standard"
                            error={errorObject.role_id}
                            helperText={errorObject.role_id}
                            required
                            id="role_id"
                            name="role_id"
                            fullWidth
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label">
                     
                      {DispensingLanguage[lan][menukey]["Facility Name"]}
                      </InputLabel>
                      <Select
                        error={errorObject.FacilityId}
                        labelId="demo-simple-select-helper-label"
                        id="FacilityId"
                        name="FacilityId"
                        value={formData.FacilityId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                      <MenuItem value="">
                        {DispensingLanguage[lan][menukey]["All Facility"]}
                      </MenuItem>

                        {FacilityList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.FacilityId}>
                        {errorObject.FacilityId}
                      </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        id="FacilityId"
                        options={FacilityList}
                        onChange={(event, valueobj) => handleChangeChoosen('FacilityId', valueobj, valueobj ? valueobj.id : "")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['FacilityId']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.FacilityId}
                            label={DispensingLanguage[lan][menukey]["Facility Name"]}
                            variant="standard"
                            error={errorObject.FacilityId}
                            helperText={errorObject.FacilityId}
                            id="FacilityId"
                            name="FacilityId"
                            fullWidth
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>


                  <Grid item xs={4} sm={4} className="sw_active_patient">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={formData.id == 1 ? true : false}
                          checked={formData.IsActive}
                          onChange={(e) => handleCheck(e)}
                          name="IsActive"
                          value="no"
                        />
                      }
                      label="Active"
                    />
                  </Grid>

                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
