import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import * as Service from "../../../services/Service.js";

const CommuneFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "commune-entry";
  const [Regionlist, setRegionlist] = useState([]);
  let TempZoneList = useState([]);
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);
  const [Region, setRegion] = React.useState(true);
  const [currRegionId, setRegionIdvalue] = useState(0);

  if (Region) {
    setRegion(false);

    let ParamList = { action: "Regionlist" };
    Service.default
      .postApi("source/combo_generic.php", ParamList)
      .then((res) => {
        setRegionlist(res.datalist);
      })
      .catch((err) => { });
  }

  React.useEffect(() => {
    cZoneList();
  }, [Region]);

  const cZoneList = () => {
    let ZoneParam = { action: "Zonelist", RegionId: currRegionId, menukey: "" };

    Service.default
      .postApi("source/combo_generic.php", ZoneParam)
      .then((res) => {
        setZoneListLocal(res.datalist);
      })
      .catch((err) => { });
  };

  const classes = useStyles();

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    RegionId: { id: "", name: "" },
  });
  const [chosenValue, setChosenValue] = useState({
    ZoneId: { id: "", name: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData["RegionId"] =
      Regionlist[
      Regionlist.findIndex((Regionlist) => Regionlist.id == formData.RegionId)
      ];
    setChosenValues(chosenValuesData);
  }, [formData.RegionId]);

  useEffect(() => {
    let chosenValuesData = { ...chosenValue };
    chosenValuesData["ZoneId"] =
      Zonelist[
      Zonelist.findIndex((Zonelist) => Zonelist.id == formData.ZoneId)
      ];
    setChosenValue(chosenValuesData);
  }, [formData.ZoneId]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">
                {DispensingLanguage[lan][menukey]["Commune - Add/Edit"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["Commune Entry Form"]}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            //disableClearable
                            disabled={props.permissionType}
                            id="RegionId"
                            options={Regionlist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "RegionId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["RegionId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.RegionId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                  "Department Name"
                                  ]
                                }
                                variant="standard"
                                error={errorObject.RegionId}
                                helperText={errorObject.RegionId}
                                id="RegionId"
                                name="RegionId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="ZoneId"
                            options={Zonelist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "ZoneId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValue["ZoneId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["ZS Name"]
                                }
                                variant="standard"
                                id="ZoneId"
                                name="ZoneId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid> */}

                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.DistrictName}
                          helperText={errorObject.DistrictName}
                          required
                          id="DistrictName"
                          name="DistrictName"
                          label={
                            DispensingLanguage[lan][menukey]["Commune Name"]
                          }
                          value={formData.DistrictName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 250 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]["Save"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/commune-entry")}
              >
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]["update"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/commune-entry")}
              >
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default CommuneFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
