import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Card,
  CardContent,
  Button
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import Autocomplete from "@material-ui/lab/Autocomplete";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const UserName = UserInfo === 0 ? "" : UserInfo[0].name;

const CSTeam = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "cs-team";

  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [RegionIdValue, setRegionIdvalue] = useState(0);
  const [currRegionText, setRegionText] = useState("");
  const [isLoadingUi, setLoading] = useState(true);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  // const [Regionlist, setRegionlist] = useState([]);
  const [Region, setRegion] = React.useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const IssueToFacilityList = JSON.parse(
    localStorage.getItem("FacilityList")
  );
  let All_Item_label = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const IssueToFaList = [All_Item_label].concat(IssueToFacilityList);
  const [currIssueToFaId, setIssueToFaId] = useState(0);
  const [IssueToFaclityName, setIssueName] = useState([]);

  const handleIssueToFaListChange = (event, newValue) => {
    let rowId = "";
    let IssueName = "";
    if (newValue == null) {
      rowId = "";
      IssueName = "";
    } else {
      rowId = newValue.id;
      IssueName = newValue.name;
    }
    if (rowId !== currIssueToFaId) {
      setIssueToFaId(rowId);
      setIssueName(IssueName);
    }
  };

  let params = {
    menukey: menukey,
    action: "getDataList",
    FacilityId: currIssueToFaId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { refetch } = useQuery([params], api.getCSTeam, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });
  const { mutate: deleteApi } = useMutation(api.deleteCSTeam, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        //  api.getAllDropdown("ZSlist").then((response) => {
        //   localStorage.setItem(
        //     "ZSlist",
        //    JSON.stringify(response.datalist.ZSlist)
        //    );
        //  });

        refetch();
        queryClient.getQueriesData("IssuedToEntryForm");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=CsteamExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" +
      Date.now() +
      "&FacilityId=" +
      currIssueToFaId +
      "&FacilityName=" +
      IssueToFaclityName
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editZS(rowData);
  };

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl."],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],

      headerFilter: true,
      field: "FacilityName",
    },

    {
      title: DispensingLanguage[lan][menukey]["Issued To Group"],
      headerFilter: true,

      field: "IssuedToGroup",
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued To Name"],
      headerFilter: true,
      field: "IssuedToName",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 100,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editZS = (data) => {
    // console.log(data);
    let route = `cs-team/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteCSTeam = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //       "Once deleted, you will not be able to recover this CSTeam!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       deleteApi({
  //         IssuedToId: data.id,
  //         menukey: menukey,
  //         lan: lan,
  //         action: "dataDalete",
  //         UserName: UserName,
  //       });
  //     }
  //   });
  // };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <Edit
          onClick={() => {
            editZS(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            //deleteCSTeam(rowData);
            modalPopupDelete(deleteApi, rowData.id);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.zsPageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Issued To Entry Form"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="IssueToFaList"
                    disableClearable
                    options={IssueToFaList}
                    onChange={(event, newValue) =>
                      handleIssueToFaListChange(event, newValue)
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      IssueToFaList[
                      IssueToFaList.findIndex(
                        (IssueToFaList) => IssueToFaList.id == currIssueToFaId
                      )
                      ]
                    }
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          DispensingLanguage[lan][menukey]["Facility Name"]
                        }
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={10} sm={10}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/cs-team/add");
                    }}>
                    {DispensingLanguage[lan][menukey]["Add"]}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}>
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}>
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CSTeam;

const useStyles = makeStyles({
  zsPageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
