import React, { useState } from "react";

import {
  useQuery,
  useMutation,
  // useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
 
import * as api from '../../../actions/api';
import OrderApprovalFormData from './OrderApprovalFormData.js';
import { useTranslation } from "react-i18next";

const EditApproveOrder = (props) => {

  const FacilityId=localStorage.getItem('FacilityId');
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  ); 
  const menukey = "order-approval";
  const lan = localStorage.getItem("LangCode");

  const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;  
const UserId = UserInfo==0?'': UserInfo[0].id;
const UserName = UserInfo == 0 ? '' : UserInfo[0].name;

//console.log("User INffffffffffff",UserInfo);

  const { 
    t, 
    // i18n 
  } = useTranslation();

  const [formData, setFormData] = useState({
    OrderDate: "",
    OrderNo: "",
    OrderingFromText:"",
    OrderTypeId:"",
    ManyJsonSave:"",
    "FacilityId":FacilityId,
    OrderBy: "",
    InternalApprover:"",
    ApprovedBy: UserId
  });


const [selectedDate, setSelectedDate] = useState(
  new Date()
);

const [bCompleted, setbCompleted] = useState("0");
const [ManyTableData, setManyTableData] = useState([]);
const [OrderId, setOrderId] = useState("0");
const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState('');
const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
const [bFirst, setBFirst] = useState(true); 

const handleDataTableChange = (e) => {};

const [errorObject, setErrorObject] = useState({});
let {id} = useParams();
//const queryClient = useQueryClient();

const paramsList = id.split("|");
  //console.log('useParams paramsList: ', paramsList);
id = paramsList[0];
const FacilityIdForRpt = paramsList[1];
// const queryClient = useQueryClient();
const [ItemGroupId, setItemGroupId] = useState(0);

const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};


//const { data } = useQuery(['order', id,ItemGroupId, false], () =>
  //api.getOrderInv({OrderId:id,ItemGroupId:ItemGroupId, OnlyOrderedItems:false}).then((res) => {
    const { data, refetch } = useQuery(['order', id, ItemGroupId, false,FacilityIdForRpt], () =>
    api.getOrderInvApprover({ OrderId: id, ItemGroupId: ItemGroupId, OnlyOrderedItems: false,FacilityId:FacilityIdForRpt }).then((res) => {
    setOrderId(id);
    res.data.ApprovedBy=res.data.ApprovedBy>0?res.data.ApprovedBy:UserId;
    setFormData(res.data); 
    
    setbCompleted(res.data.bCompleted);
    setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
    setUpdatedDetailGridData(res.data.ManyJsonSave);
    setGeneratedInvoiceNo(res.data.OrderNo);
    setBFirst(true);
    return res.data

  }), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)
}) 

const {  mutate:mutateUpdate } = useMutation(api.updateOrderApproval, {
  onSuccess: (data) => {
    if (data.status == 200) {  
      if(data.data.IsCompleted==1){
        //console.log("Is commmmmmmmmm",OrderId);
        let FinalData={OrderId,ItemGroupId:ItemGroupId};
        mutatePost(FinalData);
      }else{
      let route = `${data.data.data.OrderId}`;
      setbCompleted(data.data.data.bCompleted); 
      setManyTableData(data.data.data.ManyJsonSave);
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

        //swal("Success!", "", "success");
      }
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
    }
  }
})

const {  mutate:mutatePost } = useMutation(api.postOrderApproval, {
  onSuccess: (data) => { 
    if (data.status == 200) { 
     let route = `${OrderId}`;
     setbCompleted(data.data.data.bCompleted); 
     setManyTableData(data.data.data.ManyJsonSave);
     
     props.history.push(route);

     props.openNoticeModal({
      isOpen: true,
      msg: data.data.message,
      msgtype: data.data.success,
    });

     //swal("Success!", "", "success");
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
    }
  }
})


const {  mutate:mutateReject } = useMutation(api.rejectOrderApproval, {
  onSuccess: (data) => { 
    if (data.status == 200) { 

     // console.log("data ======== ",data);
     //let route = `${OrderId}`;
     //setbCompleted(data.data.bCompleted);

     //setbCompleted(data.data.data.bCompleted); 
     //setManyTableData(data.data.data.ManyJsonSave);
     
     //props.history.push(route);

     props.history.push("/order-approval");

     props.openNoticeModal({
      isOpen: true,
      msg: data.data.message,
      msgtype: data.data.success,
    });

     //swal("Success!", "", "success");
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
    }
  }
})

const setUpdatedDetailsGridDataHandler = (rows) => {

  if (validateForm(formData)) {
    let fDtat={...formData,'ManyJsonSave':rows,ItemGroupId:ItemGroupId}; 
    mutateUpdate(fDtat); 
    }
    setManyTableData(rows);  
  
}

const handleChange = (e) => {
  const { name, value } = e.target;
  
    let data = { ...formData };
    data[name] = value;            
    setFormData(data);

  setErrorObject({ ...errorObject, [name]: null });
};


const handleReset = () => {
  setFormData({
    OrderDate: "",
    OrderNo: ""  
  });
};

const validateForm = (formData) => {
  let validateFields = [ "OrderDate", "OrderNo","OrderingFromText","OrderTypeId", "OrderBy", "ApprovedBy"]
  let errorData = {}
  let isValid = true
  validateFields.map((field) => {
    if (!formData[field]) {
      errorData[field] = "field is Required !"
      isValid = false
    }
  })
  setErrorObject(errorData);
  return isValid
}

/*
const handleUpdate = async (e) => {
  let ValidationCount = onDataValidation(ManyTableData);
  if(ValidationCount>0){
    props.openNoticeModal({
      isOpen: true,
      msg: DispensingLanguage[lan][menukey]["Please, mention reason of approve order quantity change"],
      msgtype: 0,
      duration: 10000,
    });
    return;
  }

  if (validateForm(formData)) {
  let FinalData={...formData,'ManyJsonSave':ManyTableData,ItemGroupId:ItemGroupId}; 
  mutateUpdate(FinalData); 
  }
};


const handlePost = async (e) => {  

  let ValidationCount = onDataValidation(ManyTableData);
  if(ValidationCount>0){
    props.openNoticeModal({
      isOpen: true,
      msg: DispensingLanguage[lan][menukey]["Please, mention reason of approve order quantity change"],
      msgtype: 0,
      duration: 10000,
    });
    return;
  }

  if (validateForm(formData)) {
    let FinalData={...formData,'ManyJsonSave':ManyTableData,"IsCompleted":1,ItemGroupId:ItemGroupId}; 
   
   swal({
    title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    text: t(DispensingLanguage[lan][menukey]["Do you really want to complete this order stock?"]),
    icon: "warning",
    buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    t(DispensingLanguage[lan][menukey]["Yes"])],
    dangerMode: true,
  }).then((willAction) => {
    if (willAction) {

     mutateUpdate(FinalData);
    }
  });
    
  }
 
};

const handleReject = async (e) => {  

  swal({
    title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    text: t(DispensingLanguage[lan][menukey]["Do you really want to reject this order stock?"]),
    icon: "warning",
    buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    t(DispensingLanguage[lan][menukey]["Yes"])],
    dangerMode: true,
  }).then((willAction) => {
    if (willAction) {
      mutateReject(OrderId);
    }
  });


  
  
 
};

*/

const onDataValidation = (data) => {
  let ValidationCount = 0;

  for (let i = 0; i < data.length; i++) {
    let SubmittedOrderQty = data[i].SubmittedOrderQty;
    let ActualOrderQty = data[i].ActualOrderQty;
    let Remarks = data[i].Remarks;

    if((SubmittedOrderQty != ActualOrderQty) && ((Remarks == null) || (Remarks == ""))){
      ValidationCount++;
    }

    if(ValidationCount>0){
       return ValidationCount;
    }
  }
  return ValidationCount;
}

const filterFandleChange = (e) => {

  setItemGroupId(e) ;
};

function checkValidateForm(rows, callfrom){

  if(callfrom == "edit"){
    setManyTableData(rows); 
  }

  // return validateForm(formData);
  if (validateForm(formData,1)) {
      let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,lan: lan,"UserName":UserName}; 
      //mutateUpdate(fDtat); 
      return fDtat;
    }else{
      return false;
    }
};

function successSave(bStockUpdated){
  if(bStockUpdated !==1 ){
    refetch();
  }
};

function stockPosted(val){
  setbCompleted(val);
};

  return (
    <>
      <OrderApprovalFormData 
      errorObject={errorObject} 
      addProductForm={false} 
      formData={formData} 
      handleChange={handleChange} 
      handleReset={handleReset} 
      //handleUpdate={handleUpdate}  
      ManyTableData={ManyTableData} 
      handleDataTableChange={handleDataTableChange} 
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate}
      bCompleted={bCompleted} 
      //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      //handlePost={handlePost}
      //handleReject={handleReject}
      generatedInvoiceNo={generatedInvoiceNo} 
      bFirst={bFirst}
      filterFandleChange={filterFandleChange}
      
      refetch={refetch}
      checkValidateForm={checkValidateForm}
      successSave={successSave}
      stockPosted={stockPosted}
      FacilityIdForRpt={FacilityIdForRpt}
      
      {...props} />
    </>
  );
};

export default EditApproveOrder;