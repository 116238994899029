import React, { useEffect } from "react"; //useState,
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  DialogTitle,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OrderModal = ({ handleClose, openOrder, addOrder, ...props }) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const FacilityId = localStorage.getItem("FacilityId");
  const lan = localStorage.getItem("LangCode");
  const menukey = "issue";

  let tblOrderRef = null;

  let params = {
    //action: 'getProducts',
    menukey: menukey,
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { data,refetch } = useQuery([params], api.getNonIssuedReceivedOrderList);

  const OrderModalColumns = [
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 50,
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Order No"],
      field: "OrderNo",
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Order Date"],
      field: "OrderDate",
      width: 175,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Order Type"],
      field: "OrderType",
      width: 165,
    },
    {
      title: DispensingLanguage[lan][menukey]["Order From"],
      field: "OrderFrom",
    },
  ];
  const OrderModaltDataItems = data?.map((item, index) => {
    return item;
  });
  const { mutate } = useMutation(api.saveNonIssuedOrder, {
    onSuccess: (data) => {
      addOrder(data);
      //queryClient.getQueriesData('receive');
    },
  });
  const SaveSelectedOrder = () => {
    if (tblOrderRef.table.getSelectedData().length > 0)
      mutate(tblOrderRef.table.getSelectedData()[0].OrderId);
  };

  useEffect(() => {
    refetch();
    if (tblOrderRef == null) return;
  }, [openOrder]);


  

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose("OrderModal")}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("OrderModal")}
        >
          {DispensingLanguage[lan][menukey]["Order Lists"]}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ReactTabulator
            ref={(r) => (tblOrderRef = r)}
            selectable={1}
            columns={OrderModalColumns}
            data={OrderModaltDataItems}
            height="300px"
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedOrder}
          >
            {DispensingLanguage[lan][menukey]["ADD"]}
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose("OrderModal")}
          >
            {DispensingLanguage[lan][menukey]["CANCEL"]}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default OrderModal;
