import React, { useState, useEffect } from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import IssueVoucherPushItems from './IssueVoucherPushItems.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";
import moment from "moment";

const EditIssueVoucerPush = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "issue-voucher-push";
  const TransactionTypeId = 9;
  const FacilityId = localStorage.getItem('FacilityId');


  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionDate: "",
    SupplierInvDate: "",
    SupplierInvNo: "",
    TransactionNo: "",
    "FacilityId": FacilityId,
    TransactionTypeId: TransactionTypeId,
    IssuedToFacility: "",
    PreparedBy: "",
    ApprovedBy: "",

  });
  const [selectedDate, setSelectedDate] = useState(
    // new Date()
    moment().format("YYYY-MM-DD")
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );

  const [DateTransactionChange, setDateTransactionChange] = useState("");

  const [bFirst, setBFirst] = useState(true);

  const handleRDateChange = (date, field) => {

    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }


    if(field=="TransactionDate"){

      if(moment(newDate).format("YYYY")!=moment(DateTransactionChange).format("YYYY")){
        
        //setTimeout(() => function(){
          mutateInv({"queryKey":[{ 
          menukey: "issue-voucher-push",
          FacilityId: FacilityId,
          TransactionTypeId: TransactionTypeId,
          TransactionDate: moment(newDate).format("DD/MM/YYYY"),
          ChangeTransactionDate:newDate
           }]});
         //}, 500);
  
      }else{
          setGeneratedInvoiceNo(generatedInvoiceNo);
          setFormData({ ...formData, [field]: newDate, ["TransactionNo"]: generatedInvoiceNo });
      }
  
      }else{
          setFormData({ ...formData, [field]: newDate});
      }

    //setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };


  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data, refetch } = useQuery(['issue', id], () =>
    api.getIssueVoucerPushInv(id).then((res) => {

      setTransactionId(id);
      setFormData(res.data);
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo);
      setDateTransactionChange(res.data.TransactionDate);
      setDeletedDataSet([]);
      setBFirst(true);


      return res.data
    }), {
    refetchOnWindowFocus: false,
    enabled: Boolean(id)
  })

  /*
  const { isLoading, mutate: mutateUpdate } = useMutation(api.updateIssueVoucerPushInv, {
    onSuccess: (data) => {
      if (data.status == 200) {

        setbStockUpdate(data.bStockUpdated);
        if (data.bStockUpdated == 1) {

          mutatePost(TransactionId);

        } else {
          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          //swal("Success!", "", "success");
        }


      } else {

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoading1, mutate: mutatePost } = useMutation(api.updateStock, {
    onSuccess: (data) => {
      if (data.status == 200) {

        setbStockUpdate(1);
        setDeletedDataSet([]);

        if (data.data.success == 0) {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          // swal(data.data.message, "", "warning"); 

        } else {

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          //swal(data.data.message, "", "success");


        }

      } else {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })


  */


  const {  mutate:mutateInv } = useMutation(api.getInvoiceNo, {
    onSuccess: (data,params) => {
      //moment(params.queryKey[0].TransactionDate).format("YYYY-MM-DD")
      console.log("n paras",params.queryKey[0].ChangeTransactionDate);
      setFormData(
        { ...formData, 
        ["TransactionDate"]: params.queryKey[0].ChangeTransactionDate,
        ["TransactionNo"]:data 
        });
    }
  })



  const handleAdd = (NonOrderProductData) => {

    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);


  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "",
      "FacilityId": FacilityId,
      TransactionTypeId: TransactionTypeId,
      TransactionId: "",
      IssuedToFacility: "",
      PreparedBy: "",
      ApprovedBy: "",

    });
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "TransactionNo", "IssuedToFacility", "PreparedBy", "ApprovedBy"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const deleteReceiveInv = (data) => {
    //console.log(ManyTableData);
    let newarray = ManyTableData.filter(element => element !== data);

    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if (data.TransactionItemId != null) {
      let newDeletedDataSet = [data.TransactionItemId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
    }


  }

  /*

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat = { ...formData, 'ManyJsonSave': ManyTableData, 'deletedDataSet': deletedDataSet };
      mutateUpdate(fDtat);
    }
  };

  
  const handlePost = async (e) => {


    if (validateForm(formData)) {



      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]),
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
          let fDtat = { ...formData, 'ManyJsonSave': ManyTableData, "bStockUpdated": 1, 'deletedDataSet': deletedDataSet };
          mutateUpdate(fDtat);

        } else {
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert


    }


  };

  */

/*
  const setUpdatedDetailsGridDataHandler = (rows) => {


    if (validateForm(formData)) {
      let fDtat = { ...formData, 'ManyJsonSave': rows, 'deletedDataSet': deletedDataSet };
      mutateUpdate(fDtat);
    }


    setManyTableData(rows);
  };
  */


  const handleChangeissuedToFacilityProps = (e) => {

    if (e !== 'null') {
      setErrorObject({ ...errorObject, ['IssuedToFacility']: null });
    }

  };


  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;  
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
  }


  // useEffect(() => { 




  // }, []); 


  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function refetchInvoice(){
      refetch();
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };


  return (
    <>
      <IssueVoucherPushItems
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        //handleUpdate={handleUpdate}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        handleChangeissuedToFacilityProps={handleChangeissuedToFacilityProps}
        selectedDate={selectedDate}
        handleAdd={handleAdd}
        bStockUpdate={bStockUpdate}
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        //handlePost={handlePost}
        deleteReceiveInv={deleteReceiveInv}
        generatedInvoiceNo={generatedInvoiceNo}
        DisableAddBtn={false}
        bFirst={bFirst}

        checkValidateForm={checkValidateForm}
        refetchInvoice={refetchInvoice}
        stockPosted={stockPosted}
        handleChangeChoosenProps={handleChangeChoosenProps}

        {...props} />
    </>
  );
};

export default EditIssueVoucerPush;