/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a href="#" target="_blank">
                Mali OSPSANTE eSIGL Vaccins
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
           <div className="versionText">
             v{localStorage.getItem("sw_Version")}  last updated {process.env.REACT_APP_VERSION_APP} 
            </div> 
          {/* © {new Date().getFullYear()},{" "}
          <a
          // href=""
          // target="_blank"
          >
            Supported by GHSC-TA Francophone TO, Mali Vaccine
          </a> */}
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
