import React, { useState } from "react";

import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";

import swal from "sweetalert";
import * as api from "../../../actions/api";
import DispenserFormData from "./ReceiveItems.js";

import { useTranslation } from "react-i18next";
import moment from "moment";

//let mApiUrl = api.updateReceiveInv;

const EditDispenser = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "receive";
  const FacilityId = localStorage.getItem("FacilityId");
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionDate: "",
    SupplierInvDate: "",
    SupplierInvNo: "",
    TransactionNo: "",
    FacilityId: FacilityId,
    TransactionTypeId: 1,
    OrderNo: "",
    ReceiveFrom: "",
    PreparedBy: UserId,
    //ApprovedBy:"",
    DeliveredBy: "",
    Remarks:""
  });
  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
  );

  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  // const [StockPost, setStockPost] = useState(false);

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [DateTransactionChange, setDateTransactionChange] = useState("");

  const [bFirst, setBFirst] = useState(true);

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    //setFormData({ ...formData, [field]: newDate });
    if(field=="TransactionDate"){

      if(moment(newDate).format("YYYY")!=moment(DateTransactionChange).format("YYYY")){
        
        //setTimeout(() => function(){
          mutateInv({"queryKey":[{ 
          menukey: "receive",
          FacilityId: FacilityId,
          TransactionTypeId: 1,
          TransactionDate: moment(newDate).format("DD/MM/YYYY"),
          ChangeTransactionDate:newDate
           }]});
         //}, 500);
  
      }else{
          setGeneratedInvoiceNo(generatedInvoiceNo);
          setFormData({ ...formData, [field]: newDate, ["TransactionNo"]: generatedInvoiceNo });
      }
  
      }else{
          setFormData({ ...formData, [field]: newDate});
      }
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  // const queryClient = useQueryClient();

  const { refetch } = useQuery(
    ["receive", id],
    () =>
      api.getReceiveInv(id).then((res) => {
        setTransactionId(id);
        setFormData(res.data);
        setbStockUpdate(res.data.bStockUpdated);
        setManyTableData(res.data.ManyJsonSave); //res.data.ManyJsonSave
        setUpdatedDetailGridData(res.data.ManyJsonSave);
        setGeneratedInvoiceNo(res.data.TransactionNo);
        setDateTransactionChange(res.data.TransactionDate);
        setDeletedDataSet([]);
        setBFirst(true);

        return res.data;
      }),
    {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)
    }
  );

  /*
  const { mutate: mutateUpdate } = useMutation(api.updateReceiveInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setbStockUpdate(data.bStockUpdated);
        if (data.bStockUpdated == 1) {
          mutatePost(TransactionId);
        } else {
          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          //swal("Success!", "", "success");
        }
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const { mutate: mutatePost } = useMutation(api.updateStock, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setbStockUpdate(1);
        setDeletedDataSet([]);

        if (data.data.success == 0) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          // swal(data.data.message, "", "warning");
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          //swal(data.data.message, "", "success");
        }
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  */


  const {  mutate:mutateInv } = useMutation(api.getInvoiceNo, {
    onSuccess: (data,params) => {
      //moment(params.queryKey[0].TransactionDate).format("YYYY-MM-DD")
      console.log("n paras",params.queryKey[0].ChangeTransactionDate);
      setFormData(
        { ...formData, 
        ["TransactionDate"]: params.queryKey[0].ChangeTransactionDate,
        ["TransactionNo"]:data 
        });
    }
  })

  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;  
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
  }

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "",
      FacilityId: FacilityId,
      TransactionTypeId: 1,
      TransactionId: "",
      ReceiveFrom: "",
      OrderNo: "",
      PreparedBy: UserId,
      DeliveredBy: "",
      Remarks:""
      //ApprovedBy:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "SupplierInvNo",
      "ReceiveFrom"
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const deleteReceiveInv = (data) => {
    console.log(ManyTableData);
    let newarray = ManyTableData.filter((element) => element != data);

    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if (data.TransactionItemId != null) {
      let newDeletedDataSet = [data.TransactionItemId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
    }
  };
  /*
  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        deletedDataSet: deletedDataSet,
      };
      mutateUpdate(fDtat);
    }
  };

  const handlePost = async (e) => {
    if (validateForm(formData)) {
      // Start Post Alert

      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to post the stock?"
          ]
        ),
        icon: "warning",
        buttons: [
          t(DispensingLanguage[lan][menukey]["No"]),
          t(DispensingLanguage[lan][menukey]["Yes"]),
        ],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);
          let fDtat = {
            ...formData,
            ManyJsonSave: ManyTableData,
            bStockUpdated: 1,
            deletedDataSet: deletedDataSet,
          };
          mutateUpdate(fDtat);
        } else {
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: rows,
        deletedDataSet: deletedDataSet,
      };
      mutateUpdate(fDtat);
    }

    setManyTableData(rows);
  };

  */

  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function refetchInvoice(){
      refetch();
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };

  return (
    <>
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        //handleUpdate={handleUpdate}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        handleAdd={handleAdd}
        bStockUpdate={bStockUpdate}
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        //handlePost={handlePost}
        deleteReceiveInv={deleteReceiveInv}
        generatedInvoiceNo={generatedInvoiceNo}
        bFirst={bFirst}
        handleChangeChoosenProps={handleChangeChoosenProps}

        checkValidateForm={checkValidateForm}
        refetchInvoice={refetchInvoice}
        stockPosted={stockPosted}
        {...props}
      />
    </>
  );
};

export default EditDispenser;
