import axios from "axios";
import Facility from "views/screens/facility/Facility";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "source/api/",
  // baseURL: "http://localhost/DISPENSINGMAIN/dispensing-mock/backend/source/api/",
});

export const body = {
  page_limit: JSON.stringify(100),
  page_number: JSON.stringify(0 + 1),
  search_key: "",
};
const FacilityId = localStorage.getItem("FacilityId");

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const UserName = UserInfo === 0 ? "" : UserInfo[0].name;
const RoleItemGroupId = UserInfo === 0 ? "" : UserInfo[0].ItemGroupId;
//const lan = localStorage.getItem("LangCode");
const UserId = UserInfo === 0 ? "" : UserInfo[0].id;
const RoleId = UserInfo === 0 ? "" : UserInfo[0].role[0].id;

const auth_token = sessionStorage.getItem("token")
  ? sessionStorage.getItem("token")
  : null;

let options = {};
options = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${auth_token}`,
  },
};

//Products Api
/* export const getProducts = () =>
  api
    .post(
      "productEntry",
      { ...body, action: "getDataList", menukey: "products" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    }); */

export const getProducts = (params) =>
  api.post("productEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getProduct = (id) =>
  api.post(
    `productEntry`,
    { ...body, action: "getProduct", menukey: "products", ItemNo: id },
    options
  );

export const updateProduct = ({ id, ...updatedProduct }) =>
  api
    .put(
      `productEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "products",
        ItemNo: id,
        UserName: UserName,
        ...updatedProduct,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveProduct = (productData) =>
  api
    .post(
      `productEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "products",
        UserName: UserName,
        ...productData,
      },
      options
    )
    .then((res) => res.data);
   
export const deleteProduct = (id) =>
  api
    .post(
      `productEntry`,
      {
        ...body,
        action: "dataDalete",
        menukey: "products",
        ItemNo: id,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => res.data);



/* Start Nita Apu API */
// Hello Nita Api
// hello
/* Start Nita Apu API */

export const getWarehouseList = (amcData) => {
  return api.post("comboscript", { ...amcData }, options).then((res) => {
    return res;
  });
};

//Stock Status api
/* export const getstockstatus = (params) =>  api.post('StockStatus', {params }, options).then((res) =>{ 
  return res.data.datalist;
}) */
export const getstockstatus = (params) =>
  api
    .post("StockStatus", { params, RoleItemGroupId: RoleItemGroupId }, options)
    .then((res) => {
      return res.data;
    });

export const getLotDataView = (id) =>
  api
    .post(
      "StockStatus",
      {
        ...body,
        action: "getLotDataView",
        menukey: "stock-status",
        ItemNo: id,
        UserName: UserName,
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getStockCardDataView = (params) =>
  api.post("StockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getChartDataView = (params) =>
  api.post("StockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Modal

/* export const getProductsList = () =>
  api.post("products", { ...body, action: "getProducts", menukey: "receive", "RoleItemGroupId":RoleItemGroupId }, options).then((res) => {
    return res.data.productlist;
  }); */

export const getProductsList = (params) =>
  api.post("products", { params }, options).then((res) => {
    return res.data.productlist;
  });
export const getProductsLot = (params) =>
  api.post("products", { params }, options).then((res) => {
    return res.data.productlist;
  });

export const getProtocolListModal = () =>
  api
    .post(
      "protocolModal",
      { ...body, action: "getProtocolListModal", menukey: "protocol" },
      options
    )
    .then((res) => {
      return res.data.protocollist;
    });

export const getProductSelectInPrescriptionModal = (params) =>
  api
    .post(
      "protocolModal",
      {
        ...body,
        action: "getProductBatchModal",
        menukey: "protocol",
        FacilityId: FacilityId,
        formulation: params.queryKey[1],
      },
      options
    )
    .then((res) => {
      // console.log("getProductSelectInPrescriptionModal api.js: ",res);
      // console.log("formulation api.js: ",params);
      // console.log("formulation api.js: ",params.queryKey[1]);
      return res.data.productlist;
    });

export const getProtocolItemListModal = () =>
  api
    .post(
      "protocolModal",
      { ...body, action: "getProtocolItemListModal", menukey: "protocol" },
      options
    )
    .then((res) => {
      return res.data.protocolitemlist;
    });

export const getSourceList = () =>
  api
    .post(
      "sourceoffund",
      { ...body, action: "getSources", menukey: "receive" },
      options
    )
    .then((res) => {
      return res.data.sourcelist;
    });

///Receive

export const getReceiveInvs = (params) =>
  api.post("receiveinvoicelist", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });
export const saveReceiveInv = (dispenserData) =>
  api.post(`receiveSave`, dispenserData, options).then((res) => res.data);

export const updateReceiveInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `receiveUpdate`,
      {
        ...body,
        menukey: "receive",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getOrderTypedetails = (params) => {
  return api.post("CommoditiesStockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });
};

export const getReceiveInv = (id) =>
  api.post(
    `receiveListSingle`,
    {
      ...body,
      menukey: "receive",
      TransactionId: id,
      FacilityId: FacilityId,
      UserName: UserName,
    },
    options
  );
export const deleteReceiveInv = (id) =>
  api
    .post(
      `receiveDelete`,
      {
        ...body,
        menukey: "receive",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });
/* export const getNonReceivedOrderList = () =>
  api
    .post(
      "receivedNonOrderList",
      { ...body, menukey: "receive", "FacilityId": FacilityId },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    }); */

export const getNonReceivedOrderList = (params) =>
  api.post("receivedNonOrderList", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

export const saveNonReceivedOrder = (id) =>
  api
    .post(
      `receivedNonReceiveOrderProducts`,
      {
        ...body,
        menukey: "receive",
        FacilityId: FacilityId,
        OrderId: id,
        UserName: UserName,
      },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });

export const getInvoiceNo = (params) =>
  api.post("InvoiceNo", { ...body, params }, options).then((res) => {
    return res.data;
  });

export const getInvoiceNoForField = (params) =>
  api.post("InvoiceNoForField", { ...body, params }, options).then((res) => {
    return res.data;
  });

export const getSheetNo = (params) =>
  api.post("InvoiceNoForAEFI", { ...body, params }, options).then((res) => {
    return res.data;
  });

export const getOrdInvoiceNo = (params) =>
  api.post("OrdInvoiceNo", { ...body, params }, options).then((res) => {
    return res.data;
  });

export const updateStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "receive",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 1,
        UserName: UserName,
        UserId: UserId,
      },
      options
    )
    .then((res) => {
      return res;
    });


export const IssuePushPost = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "issue-voucher-push",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 9,
        UserName: UserName,
        UserId: UserId,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const IssueAgainstIndentPost = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "issue",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 2,
        UserName: UserName,
        UserId: UserId,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const updateAdjStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "adjustment",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 3,
        UserName: UserName,
        UserId: UserId,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

/* Start Hasem API */
// Hello Hasem

export const getAdjustmentTypeapi = (params) => {
  return api.post("CommoditiesStockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });
};

export const getCSTeam = (params) =>
  api.post("IssuedToEntryForm", { params }, options).then((res) => {
    //console.log(res.data.datalist);
    return res.data.datalist;
  });

export const getCSData = (id) =>
  api.post(
    `IssuedToEntryForm`,
    {
      ...body,
      action: "getCSTeamData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "zs",
      IssuedToId: id,
    },
    options
  );

export const saveCSTeam = (CSData) =>
  api
    .post(
      `IssuedToEntryForm`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "zs",
        ...CSData,
      },
      options
    )
    .then((res) => res.data);

export const updateCSTeam = ({ id, ...updatedCSTeam }) =>
  api
    .put(
      `IssuedToEntryForm`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "zs",
        IssuedToId: id,
        ...updatedCSTeam,
      },
      options
    )
    .then((res) => {
      return res;
    });

// export const deleteCSTeam = (id) => api.post(`IssuedToEntryForm`, id, options);

export const deleteCSTeam = (id) =>
api
  .post(
    `IssuedToEntryForm`,
    {
      ...body,
      action: "dataDalete",
      menukey: "cs-team",
      IssuedToId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const getMonthCloseViewReport = (params) =>
  api.post("monthclose", { params }, options).then((res) => {
    return res.data.datalist;
  });


/* End Hasem API */
// ORDER
export const getOrderInvs = (params) =>
  api.post("orderInvoiceList", { params }, options).then((res) => {
    return res.data.orderinvdata;
  });

  export const getOrderToSupplierInvs = (params) =>
  api.post("orderToSupplierInvoiceList", { params }, options).then((res) => {
    return res.data.orderinvdata;
  });

export const getOrderInvApproval = (params) =>
  api.post("orderInvoiceListApproval", { params }, options).then((res) => {
    return res.data.orderinvdata;
  });

export const saveOrderInv = (dispenserData) =>
  api
    .post(
      `orderSave`,
      {
        ...body,
        menukey: "order",
        ...dispenserData,
        UserName: UserName,
        UserId: UserId,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => res.data);

    export const saveOrderToSupplierInv = (dispenserData) =>
    api
      .post(
        `orderToSupplierSave`,
        {
          ...body,
          menukey: "order-to-supplier",
          ...dispenserData,
          UserName: UserName,
          UserId: UserId,
          lan: localStorage.getItem("LangCode"),
        },
        options
      )
      .then((res) => res.data);    

export const updateOrderInv = ({ id, ...updatedOrder }) =>
  api
    .put(
      `orderUpdate`,
      {
        ...body,
        menukey: "order",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        ...updatedOrder,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

    export const updateOrderToSupplierInv = ({ id, ...updatedOrder }) =>
    api
      .put(
        `orderToSupplierUpdate`,
        {
          ...body,
          menukey: "order-to-supplier",
          UserName: UserName,
          UserId: UserId,
          lan: localStorage.getItem("LangCode"),
          ...updatedOrder,
        },
        options
      )
      .then((res) => {
        return res.data;
      }); 

export const updateOrderApproval = ({ id, ...updatedOrder }) =>
  api
    .put(
      `orderUpdateApproval`,
      {
        ...body,
        menukey: "order",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        ...updatedOrder,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getOrderInv = (sParams) =>
  api.post(
    `orderListSingle`,
    { ...body, menukey: "order", ...sParams, FacilityId: FacilityId },
    options
  );

  export const getOrderToSupplierInv = (sParams) =>
  api.post(
    `orderToSupplierListSingle`,
    { ...body, menukey: "order-to-supplier", ...sParams, FacilityId: FacilityId },
    options
  );

  export const getOrderInvApprover = (sParams) =>
  api.post(
    `orderListSingle`,
    { ...body, menukey: "order", ...sParams},
    options
  );

export const deleteOrderInv = (id) =>
  api.post(
    `orderDelete`,
    {
      ...body,
      menukey: "order",
      OrderId: id,
      FacilityId: FacilityId,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  );

  export const deleteOrderToSupplierInv = (id) =>
  api.post(
    `orderToSupplierDelete`,
    {
      ...body,
      menukey: "order-to-supplier",
      OrderId: id,
      FacilityId: FacilityId,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  );  

export const postOrderInv = ({ id, ...updatedOrder }) =>
  api
    .post(
      `orderPost`,
      {
        ...body,
        menukey: "order",
        FacilityId: FacilityId,
        OrderId: id,
        ...updatedOrder,
        UserName: UserName,
        UserId:UserId
      },
      options
    )
    .then((res) => {
      return res;
    });

    export const orderToSupplierPostInv = ({ id, ...updatedOrder }) =>
      api
        .post(
          `orderToSupplierPost`,
          {
            ...body,
            menukey: "order-to-supplier",
            FacilityId: FacilityId,
            OrderId: id,
            ...updatedOrder,
            UserName: UserName,
            UserId:UserId
          },
          options
        )
        .then((res) => {
          return res;
        });

export const postOrderApproval = ({ id, ...updatedOrder }) =>
  api
    .post(
      `orderPostApproval`,
      {
        ...body,
        menukey: "order",
        FacilityId: FacilityId,
        OrderId: id,
        ...updatedOrder,
        UserName: UserName,
        UserId:UserId,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const rejectOrderApproval = (params) =>
  api
    .post(
      `orderRejectApproval`,
      {
        ...body,
        menukey: "order-approval",
        ...params,
        UserName: UserName,
        UserId:UserId,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res;
    });

/* Start Farid API */


export const updateQuantityRequiredData = (params) =>
  //console.log(params.id);
  api
    .put(
      `CountryProfile`,
      {
        ...body,
        action: "updateFundingRequirementData",
        menukey: "country-profile-entry",
        FundingReqId: params.id,
        YCValue: params.YCValue,
        UserName: UserName,
        FacilityId: FacilityId,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res;
    });

export const SaveYcProfileData = (getSource1) =>
  api
    .post(
      `CountryProfile`,
      { ...body, action: "AddYcProfileData", menukey: "source", ...getSource1 },
      options
    )
    .then((res) => res.data);

    export const DeleteCountryProfile = (getSource1) =>
      api
        .post(
          `CountryProfile`,
          { ...body, action: "DeleteCountryProfileData", menukey: "source", ...getSource1 },
          options
        )
        .then((res) => res.data);    


export const aProductList = (productdata) => {
  //console.log("dddd",zoneData);
  return api
    .post(
      "comboscript",
      { ...productdata },
      options
    )
    .then((res) => {

      return res;

    });
};

export const getProductLotStockReportMap = (params) =>
  api.post("eLMISSummaryReports", { params }, options).then((res) => {
    return res.data.datalist;
});

export const getNationalBalance = (ProductGroupId, cItemId = 0) => {
  return api
    .post(
      "Dashboard",
      {
        ...body,
        action: "getNationalStockBalance",
        menukey: "dashboard",
        ProductGroupId: ProductGroupId,
        ItemNo: cItemId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const getSingleProductLots = (manyTableCurrentRow) =>
  api
    .post(
      "products",
      {
        ...body,
        action: "getSingleProductLots",
        menukey: "products",
        manyTableCurrentRow: manyTableCurrentRow,
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      //console.log("id from lot modal: ", id);
      return res.data.productlist;
    });

export const getLeaftMapData = (ProductGroupId, cItemId = 0) => {
  return api
    .post(
      "Dashboard",
      {
        ...body,
        action: "getMapData",
        menukey: "dashboard",
        ProductGroupId: ProductGroupId,
        ItemNo: cItemId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


export const getStockOutSite = (ProductGroupId, cItemId = 0) => {
  return api
    .post(
      "Dashboard",
      {
        ...body,
        action: "getStockOutSites",
        menukey: "dashboard",
        ProductGroupId: ProductGroupId,
        ItemNo: cItemId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getProductListForDashboard = (getProductList) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...getProductList }, options).then((res) => {
    return res;
  });
};


export const getNationalStockkReport = (params) =>
  api.post("eLMISSummaryReports", { params }, options).then((res) => {
    return res;
});



/* End Farid API */

// Stock Take
export const getStockTakeInvs = (params) =>
  api.post("stocktake", { params }, options).then((res) => {
    return res.data.gstocktakelist;
  });

export const saveStockTakeInv = (stockTakeData) =>
  api
    .post(
      `stocktake`,
      {
        ...body,
        menukey: "stock-take",
        action: "saveStockTake",
        ...stockTakeData,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => res.data);

export const updateStockTakeInv = ({ id, ...updatedStockTake }) =>
  api
    .put(
      `stocktake`,
      {
        ...body,
        menukey: "stock-take",
        action: "updateStockTake",
        ...updatedStockTake,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });
export const getStockTakeInv = (id, ItemGroupId) =>
  api.post(
    `stocktake`,
    {
      ...body,
      menukey: "stock-take",
      StockTakeId: id,
      FacilityId: FacilityId,
      ItemGroupId: ItemGroupId,
      action: "stockTakeListSingle",
    },
    options
  );
export const deleteStockTakeInv = (id) =>
  api
    .post(
      `stocktake`,
      {
        ...body,
        menukey: "stock-take",
        StockTakeId: id,
        FacilityId: FacilityId,
        action: "stockTakeDelete",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const postStockTakeInv = (id) =>
  api
    .post(
      `StockTakePosted`,
      {
        ...body,
        menukey: "stock-take",
        FacilityId: FacilityId,
        StockTakeId: id,
        UserName: UserName,
        UserId: UserId,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

/* export const getunitofmeasurelist1 = (params) =>  api.post('unit_of_measure', {params }, options).then((res) =>{ 
  return res.data.datalist;
}) */

export const getLogisticDashboard = (params) => {
  return api.post("LogisticsDashboard", { params }, options).then((res) => {
    return res;
  });
};

export const getConsumptionTrendsTopFiveProducts = (
  pYearId,
  pMonthId,
  pFacilityId = 0
) => {
  return api
    .post(
      "LogisticsDashboard",
      {
        ...body,
        action: "getConsumptionTrendsTopFiveProducts",
        menukey: "Logistics Dashboard",
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      // console.log("Res data",res);
      //console.log("data list",res.data.datalist);
      return res.data.datalist;
    });
};
export const getAMCTrendsTopFiveProducts = (
  pYearId,
  pMonthId,
  pFacilityId = 0
) => {
  return api
    .post(
      "LogisticsDashboard",
      {
        ...body,
        action: "getAMCTrendsTopFiveProducts",
        menukey: "Logistics Dashboard",
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getVaccinationTrendData = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  pVaccineMonthId = 0,
  pProductId = 0,
  pProductGroupId = 0
) => {
  return api
    .post(
      "Dashboard",
      {
        ...body,
        action: "getVaccinationTrendData",
        menukey: "dashboard",
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
        VaccineMonthId: pVaccineMonthId,
        ItemNo: pProductId,
        ProductGroupId: pProductGroupId
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getPercentageOfFacilitiesWithStockoutOfTracerCommodities = (
  pYearId,
  pMonthId,
  pFacilityId = 0
) => {
  return api
    .post(
      "LogisticsDashboard",
      {
        ...body,
        action: "getPercentageOfFacilitiesWithStockoutOfTracerCommodities",
        menukey: "Logistics Dashboard",
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getCD4WeightChart = (id) => {
  return api
    .put(
      "patients",
      {
        menukey: "patients",
        PatientId: id,
        FacilityId: FacilityId,
        action: "getCD4WeightChart",
      },
      options
    )
    .then((res) => {
      res.data = res.data.list;
      return res;
    });
};

/* Patient Dashboard */

export const getCurrentMonthPatients = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  pGenderTypeId = 0,
  pAgeGroupId = 0
) => {
  console.log(
    "pYearId: ",
    pYearId,
    ", pMonthId: ",
    pMonthId,
    "pFacilityId: ",
    pFacilityId
  );

  return api
    .post(
      "patientdashboard",
      {
        ...body,
        action: "getCurrentMonthPatients",
        menukey: "Patient Dashboard",
        FacilityId: pFacilityId,
        GenderTypeId: pGenderTypeId,
        AgeGroupId: pAgeGroupId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      // console.log("patientdashboard patient count: ", res.data.datalist);

      console.log("patientdashboard patient count: ", res.data.datalist);
      return res.data.datalist;
    });
};

export const getProtocolItems = (ProtocolId) => {
  console.log("getProtocolItems api.js. ProtocolId:", ProtocolId);

  return api
    .post(
      "prescriptions",
      {
        ...body,
        action: "getProtocolItems",
        menukey: "prescriptions",
        ProtocolId: ProtocolId,
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      console.log("Protocol Items by ProtocolId: ", res.data.datalist);
      return res.data.datalist;
    });
};

/* Start Nazim API */

/*Start of Aefi List APi*/
export const getMasterDataList = (params) =>
  api.post("AEFI_Entry_Server", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const deleteAefiMasterData = (id) =>
  api
    .post(
      `AEFI_Entry_Server`,
      {
        ...body,
        menukey: "aefi",
        action: "deleteAefiMasterData",
        AefiMasterId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveAefiMasterData = (paramsData) =>
  api
    .post(
      `AEFI_Entry_Server`,
      {
        ...body,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "aefi",
        action: "MasterDataInsert",
        ...paramsData,
      },
      options
    )
    .then((res) => res.data);

export const MasterDataUpdate = ({ id, ...paramsData }) =>
  api
    .put(
      `AEFI_Entry_Server`,
      {
        ...body,
        menukey: "aefi",
        ...paramsData,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        action: "MasterDataUpdate",
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getMasterSingleDataList = (id) =>
  api.post(
    `AEFI_Entry_Server`,
    {
      ...body,
      menukey: "aefi",
      AefiMasterId: id,
      FacilityId: FacilityId,
      action: 'getMasterSingleDataList',
    },
    options
  );


  export const getMasterSingleDataListRpt = (params) =>
  api.post(
    `AEFI_Entry_Server`,
    {
      ...body,
      menukey: "aefi",
      AefiMasterId: params[0],
      FacilityId: params[1],
      action: 'getMasterSingleDataListRpt'
      
    },
    options
  );

export const saveAEFIItemsData = (params) =>
  api
    .post(
      `AEFI_Entry_Server`,
      {
        ...body,
        action: "InsertAEFIItemsData",
        menukey: "aefi",
        FacilityId: FacilityId,
        UserName: UserName,
        ...params,
      },
      options
    )
    .then((res) => res.data);

export const getAefiItemById = (id) =>
  api
    .post(
      `AEFI_Entry_Server`,
      {
        ...body,
        menukey: "aefi",
        action: "getAefiItemById",
        AefiMasterId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const deleteAefiItemData = (id) =>
  api
    .post(
      `AEFI_Entry_Server`,
      {
        ...body,
        menukey: "aefi",
        action: "deleteAefiItemData",
        AefiItemId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveEditAdverseEventData = (params) =>
  api
    .post(
      `AEFI_Entry_Server`,
      {
        ...body,
        action: "InsertUpdateAdverseEventData",
        menukey: "aefi",
        FacilityId: FacilityId,
        UserName: UserName,
        ...params,
      },
      options
    )
    .then((res) => res.data);

export const deleteAdverseEventData = (id) =>
  api
    .post(
      `AEFI_Entry_Server`,
      {
        ...body,
        menukey: "aefi",
        action: "deleteAdverseEventData",
        AdverseEventId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getAdverseEventById = (id) =>
  api
    .post(
      `AEFI_Entry_Server`,
      {
        ...body,
        menukey: "aefi",
        action: "getAdverseEventById",
        AdverseEventId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

  export const saveProfessiontData = (params) =>
    api
      .post(
        `AEFI_Entry_Server`,
        {
          ...body,
          action: "InsertProfessionData",
          menukey: "aefi",
          FacilityId: FacilityId,
          UserName: UserName,
          ...params,
        },
        options
      )
      .then((res) => res.data);

export const updateStockForAefi = (id) =>
  api
    .post(
      `POSTAefiForm`,
      {
        ...body,
        menukey: "aefi",
        FacilityId: FacilityId,
        AefiMasterId: id,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res;
    });

/*End of Aefi List APi*/

export const saveIssueToGroup = (params) =>
  api
    .post(
      `IssueToGroupAndIssuedTo`,
      {
        ...body,
        action: "dataInsertIssueToGroup",
        menukey: "issue",
        FacilityId: FacilityId,
        UserName: UserName,
        ...params,
      },
      options
    )
    .then((res) => res.data);

export const saveIssueTo = (params) =>
  api
    .post(
      `IssueToGroupAndIssuedTo`,
      {
        ...body,
        action: "dataInsertIssueTo",
        menukey: "issue",
        FacilityId: FacilityId,
        UserName: UserName,
        ...params,
      },
      options
    )
    .then((res) => res.data);

//Issue to Field
export const getIssueToFieldInvs = (params) =>
  api.post("IssueToFieldList", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

export const deleteIssueToFieldInv = (id) =>
  api
    .post(
      `IssueToFieldDelete`,
      {
        ...body,
        menukey: "issue-to-field",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveIssueToFieldListInv = (dispenserData) =>
  api
    .post(
      `IssueToFieldSave`,
      {
        ...body,
        UserName: UserName,
        UserId:UserId,
        lan: localStorage.getItem("LangCode"),
        menukey: "issue-to-field",
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const updateIssueToFieldInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `IssueToFieldUpdate`,
      {
        ...body,
        menukey: "issue-to-field",
        ...updatedDispenser,
        UserName: UserName,
        UserId: UserId,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getIssueToFieldInv = (id) =>
  api.post(
    `IssueToFieldListSingle`,
    {
      ...body,
      menukey: "issue-to-field",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const updateStockForIssueToField = (id) =>
  api
    .post(
      `POSTIssuedToFieldPost`,
      {
        ...body,
        menukey: "issue-to-field",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 9,
        UserName: UserName,
        UserId:UserId,
        lan:localStorage.getItem("LangCode")
      },
      options
    )
    .then((res) => {
      return res;
    });

//End of Issue to Field


//Field Dispense
export const getFieldDispenseInvs = (params) =>
  api.post("FieldDispenseList", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

export const deleteFieldDispenseInv = (id) =>
  api
    .post(
      `FieldDispenseDelete`,
      {
        ...body,
        menukey: "field-dispense",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveFieldDispenseListInv = (dispenserData) =>
  api
    .post(
      `FieldDispenseSave`,
      {
        ...body,
        UserName: UserName,
        UserId: UserId,
        lan: localStorage.getItem("LangCode"),
        menukey: "field-dispense",
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const updateFieldDispenseInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `FieldDispenseUpdate`,
      {
        ...body,
        menukey: "field-dispense",
        ...updatedDispenser,
        UserName: UserName,
        UserId: UserId,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getFieldDispenseInv = (id) =>
  api.post(
    `FieldDispenseListSingle`,
    {
      ...body,
      menukey: "field-dispense",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const updateStockForFieldDispense = (id) =>
  api
    .post(
      `POSTIssuedToFieldPost`,
      {
        ...body,
        menukey: "field-dispense",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 4,
        UserName: UserName,
        UserId:UserId,
        lan:localStorage.getItem("LangCode")
      },
      options
    )
    .then((res) => {
      return res;
    });

//End of Field Dispense


/* End Nazim API */

//combo api
//Local Storage from Login Page
export const getLoginAllDropdown = async (options, task) =>
  api
    .post(
      "comboscript",
      { action: "getAllDropdownList", task: task, menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });
//Local Storage from meta or user entry page
export const getAllDropdown = (task) =>
  api
    .post(
      "comboscript",
      { action: "getAllDropdownList", task: task, menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });

//Local Storage from Login Page
export const getLoginFacilityListNotEqual99ANDSuppliers = async (
  options,
  task
) =>
  api
    .post(
      `comboscript`,
      {
        action: "getFacilityListNotEqual99ANDSupplier",
        task: task,
        menukey: "",
        FacilityId: localStorage.getItem("FacilityId"),
        FLevelId: localStorage.getItem("FLevelId"),
        UserId: UserId,
        RoleId: RoleId,
      },
      options
    )
    .then((res) => {
      return res.data;
    });
//Local Storage from meta or user entry page
export const getFacilityListNotEqual99ANDSuppliers = (task, FacilityId) =>
  api
    .post(
      `comboscript`,
      {
        action: "getFacilityListNotEqual99ANDSupplier",
        task: task,
        menukey: "",
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const UpdateAMC = (amcData) => {
  return api.post("StockStatus", { ...amcData }, options).then((res) => {
    return res;
  });
};

//Admin Forms
export const getTotal = (params) =>
  api.post("unit_of_measure", { params }, options).then((res) => {
    return res.data.datalist;
  });
//Admin Forms
export const getunitofmeasurelist1 = (params) =>
  api.post("unit_of_measure", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveunitofmeasure = (getunitofmeasure1) =>
  api
    .post(
      `unit_of_measure`,
      {
        ...body,
        action: "dataInsert",
        menukey: "Unitofmeasureentry",
        ...getunitofmeasure1,
        UserName: UserName,
      },
      options
    )
    .then((res) => res.data);
// export const deleteunitofmeasure = (id) =>
//   api.post(`unit_of_measure`, id, options);
export const deleteunitofmeasure = (id) =>
api
  .post(
    `unit_of_measure`,
    {
      ...body,
      action: "dataDalete",
      menukey: "unit-of-measure-entry-form",
      UnitId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const getunitofmeasure = (id) =>
  api.post(
    `unit_of_measure`,
    {
      ...body,
      action: "getunit_measure",
      menukey: "Unitofmeasureentry",
      UnitId: id,
    },
    options
  );
export const updateunitof = ({ id, ...updatedunitof }) =>
  api
    .put(
      `unit_of_measure`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "Unitofmeasureentry",
        UnitId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Interval
export const geIntevals = (id) =>
  api.post(
    `interval`,
    { ...body, action: "gedirection", menukey: "interval", IntervalId: id },
    options
  );

//LabTest
export const getLabTest22 = () =>
  api
    .post(
      "labtest",
      { ...body, action: "getLabTestList", menukey: "labtest" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const geLabTests = (id) =>
  api.post(
    `labtest`,
    { ...body, action: "gedLebTest", menukey: "labtest", LabTestId: id },
    options
  );
export const saveLabTest = (getlabtest1) =>
  api
    .post(
      `labtest`,
      { ...body, action: "dataInsert", menukey: "labtest", ...getlabtest1 },
      options
    )
    .then((res) => res.data);

//export const deleteLabTest = (id) =>  api.post(`labtest`,{...body, "action": "dataDalete", "menukey": "labtest", "LabTestId":id}, options)
export const deleteLabTest = (id) => api.post(`labtest`, id, options);

export const updateLabTest = ({ id, ...updatelabtests }) =>
  api
    .put(
      `labtest`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "labtest",
        LabTestId: id,
        ...updatelabtests,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Route of admin
export const getRouteofadmin33 = () =>
  api
    .post(
      "routeofadmin",
      { ...body, action: "getRouteOfAdminList", menukey: "routeofadmin" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const geRouteofadmins = (id) =>
  api.post(
    `routeofadmin`,
    {
      ...body,
      action: "gedRouteofAdmin",
      menukey: "routeofadmin",
      RouteId: id,
    },
    options
  );
export const saveRouteofadmin = (getrouteofadmin1) =>
  api
    .post(
      `routeofadmin`,
      {
        ...body,
        action: "dataInsert",
        menukey: "routeofadmin",
        ...getrouteofadmin1,
      },
      options
    )
    .then((res) => res.data);

//export const deleteRouteofadmin = (id) =>  api.post(`routeofadmin`,{...body, "action": "dataDalete", "menukey": "routeofadmin", "RouteId":id}, options)
//export const deleteRouteofadmin = (id) => api.post(`routeofadmin`, id, options);
export const deleteRouteofadmin = (id) =>
api
  .post(
    `routeofadmin`,
    {
      ...body,
      action: "dataDalete",
      menukey: "route-of-admin-entry",
      RouteId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const updateRouteofadmin = ({ id, ...updateRouteofadmins }) =>
  api
    .put(
      `routeofadmin`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "routeofadmin",
        RouteId: id,
        ...updateRouteofadmins,
      },
      options
    )
    .then((res) => {
      return res;
    });
// Product Group Entry
export const getProductGroupEntry33 = () =>
  api
    .post(
      "productGroupEntry",
      {
        ...body,
        action: "getProductGroupEntryList",
        menukey: "productGroupEntry",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getProductGroup = (id) =>
  api.post(
    `productGroupEntry`,
    {
      ...body,
      action: "getProductGroupEntry",
      menukey: "productGroupEntry",
      ItemGroupId: id,
    },
    options
  );
export const saveProductGroup = (getProductGroup1) =>
  api
    .post(
      `productGroupEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "productGroupEntry",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// export const deleteProductGroup = (id) =>  api.post(`productGroupEntry`,{...body, "action": "dataDalete", "menukey": "productGroupEntry", "ItemGroupId":id}, options)
/*
export const deleteProductGroup = (id) =>
  api.post(`productGroupEntry`, id, options);

  */


  export const deleteProductGroup = (id) =>
  api
    .post(
      `productGroupEntry`,
      {
        ...body,
        action: "dataDalete",
        menukey: "product-group-entry",
        ItemGroupId: id,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => res);


export const updateProductGroupEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `productGroupEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "productGroupEntry",
        ItemGroupId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });
// National MOS Type Entry
export const getNationalMosTypeEntry = () =>
  api
    .post(
      "nationalMosTypeEntry",
      {
        ...body,
        action: "getNationalMOsTypeEntryList",
        menukey: "nationalMosTypeEntry",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getNationalMOSId = (id) =>
  api.post(
    `nationalMosTypeEntry`,
    {
      ...body,
      action: "getNationalMOSEntry",
      menukey: "nationalMosTypeEntry",
      MosTypeId: id,
    },
    options
  );
export const saveNationalMOSgroup = (getProductGroup1) =>
  api
    .post(
      `nationalMosTypeEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "nationalMosTypeEntry",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// export const deleteProductGroup = (id) =>  api.post(`productGroupEntry`,{...body, "action": "dataDalete", "menukey": "productGroupEntry", "ItemGroupId":id}, options)
//  export const deleteNationalMosType = (id) => api.post(`nationalMosTypeEntry`, id, options)
export const updateNationalMOSTypeEntry = ({
  id,
  ...updateProductGroupEntry
}) =>
  api
    .put(
      `nationalMosTypeEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "nationalMosTypeEntry",
        MosTypeId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Depatmnet
export const getdepartmententry = () =>
  api
    .post(
      "department",
      { ...body, action: "getDataList", menukey: "department" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const savedepartment = (departmentData) =>
  api
    .post(
      `department`,
      {
        ...body,
        action: "dataInsert",
        menukey: "department",
        ...departmentData,
      },
      options
    )
    .then((res) => res.data);
export const getdepartmentData = (id) =>
  api.post(
    `department`,
    {
      ...body,
      action: "getDepartmentData",
      menukey: "department",
      RegionId: id,
    },
    options
  );
// export const deletedepartment = (id) => api.post(`department`, id, options);

export const deletedepartment = (id) =>
api
  .post(
    `department`,
    {
      ...body,
      action: "dataDalete",
      menukey: "department-entry",
      RegionId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const updatedepartment = ({ id, ...updatedepartments }) =>
  api
    .put(
      `department`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "direction",
        RegionId: id,
        ...updatedepartments,
      },
      options
    )
    .then((res) => {
      return res;
    });

// Owner Type Entry
export const getOwnerTypeEntry = () =>
  api
    .post(
      "ownerTypeEntry",
      { ...body, action: "getOwnerEntryList", menukey: "ownerTypeEntry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getOwnerEntry = (id) =>
  api.post(
    `ownerTypeEntry`,
    {
      ...body,
      action: "getOwnerGroupEntry",
      menukey: "ownerTypeEntry",
      OwnerTypeId: id,
    },
    options
  );
export const saveOwnerTypeEntry = (getProductGroup1) =>
  api
    .post(
      `ownerTypeEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "ownerTypeEntry",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// export const deleteProductGroup = (id) =>  api.post(`productGroupEntry`,{...body, "action": "dataDalete", "menukey": "productGroupEntry", "ItemGroupId":id}, options)
// export const deleteOwnerType = (id) => api.post(`ownerTypeEntry`, id, options);

export const deleteOwnerType = (id) =>
api
  .post(
    `ownerTypeEntry`,
    {
      ...body,
      action: "dataDalete",
      menukey: "owner-type-entry",
      OwnerTypeId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const updateOwnerTypeEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `ownerTypeEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "ownerTypeEntry",
        OwnerTypeId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });

//country entry api

export const getCountryEntery = (params) =>
  api.post("CountryEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveCountryEntry = (CountryListData) =>
  api
    .post(
      `CountryEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "country-entry",
        ...CountryListData,
      },
      options
    )
    .then((res) => res.data);

export const getCountryEntryData = (id) =>
  api.post(
    `CountryEntry`,
    {
      ...body,
      action: "getData",
      menukey: "country-entry",
      CountryId: id,
      lan: localStorage.getItem("LangCode"),
    },
    options
  );

export const updateCountryEntry = ({ id, ...updatedCountryEntry }) =>
  api
    .put(
      `CountryEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "country-entry",
        CountryId: id,
        ...updatedCountryEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });

// Commune Api

export const getCommunes = (params) =>
  api.post("commune", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getCommuneData = (id) =>
  api.post(
    `commune`,
    {
      ...body,
      action: "getCommuneData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "commune",
      DistrictId: id,
    },
    options
  );

  export const ConsumptionTrendbyProductdReport = (params) =>
    api.post("ConsumptionTrendbyProduct", { params }, options).then((res) => {
      return res;
    });
/* Start Sumon API */

// get cstem data
export const getcsteamlist = (getcsteamdata) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...getcsteamdata }, options).then((res) => {
    return res;
  });
};

// facility level list
export const getFacilityLevelforReport = (getfacilityLevedata) => {
  //console.log("dddd",zoneData);
  return api
    .post("comboscript", { ...getfacilityLevedata }, options)
    .then((res) => {
      return res;
    });
};

// communue list
export const getCommuneforReport = (getcommunedata) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...getcommunedata }, options).then((res) => {
    return res;
  });
};

// communue list
export const getRegionforReport = (getRegiondata) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...getRegiondata }, options).then((res) => {
    return res;
  });
};
// communue list
export const getProductGroupReport = (getRegiondata) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...getRegiondata }, options).then((res) => {
    return res;
  });
};
// communue list
export const getFundingSourceforReport = (getFundingSourceData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...getFundingSourceData }, options).then((res) => {
    return res;
  });
};

//Issue Voucer Push
export const getIssueVoucerPushInvs = (params) =>
  api.post("issueinvoicepushlist", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

export const deleteIssueVoucerPushInv = (id) =>
  api
    .post(
      `issuevoicerpushDelete`,
      {
        ...body,
        menukey: "issue-voucher-push",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getIssueVoucerPushInv = (id) =>
  api.post(
    `issueinvoicepushListSingle`,
    {
      ...body,
      menukey: "issue-voucher-push",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const updateIssueInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `issueUpdate`,
      {
        ...body,
        menukey: "issue-voucher-push",
        ...updatedDispenser,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveIssueVoucerPushInv = (dispenserData) =>
  api
    .post(
      `issuevoucerpushSave`,
      {
        ...body,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "issue-voucher-push",
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const saveNonIssuedvoucerpushOrder = (id) =>
  api
    .post(
      `issuedNonReceiveOrderProducts`,
      {
        ...body,
        menukey: "issue-voucher-push",
        FacilityId: FacilityId,
        OrderId: id,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        UserId: UserId
      },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });

export const getNonIssuedvoucerpushReceivedOrderList = (params) =>
  api.post("issuedReceivedNonOrderList", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

export const saveManufacturerReceive = (params) =>
  api
    .post(
      `NationalSupplier`,
      {
        ...body,
        action: "dataInsertManufacturer",
        menukey: "receive-from-supplier",
        FacilityId: FacilityId,
        UserName: UserName,
        ...params,
      },
      options
    )
    .then((res) => res.data);

    // Loss report
export const getLossSummaryReport = (params) =>
api
  .post("LossSummaryReport", { params }, options)
  .then((res) => {
    return res.data.datalist;
  });

  export const getLossSummaryByFacilityReport = (params) =>
api
  .post("LossSummaryByFacilityReport", { params }, options)
  .then((res) => {
    return res.data.datalist;
  });

  export const getFacilityeLMISActivityStatusReport = (params) =>
  api.post("FacilityeLMISActivityStatusReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

  export const getShipmentReport = (params) =>
    api
      .post("ShipmentReport", { params }, options)
      .then((res) => {
        return res.data.datalist;
      });  


      export const getForecastedConsumption = () =>
        api
          .post(
            "ForecastedConsumption",
            {
              ...body,
              action: "getForecastedConsumption",
              menukey: "forecasted-consumption-entry",
              lan:localStorage.getItem("LangCode")
            },
            options
          )
          .then((res) => {
            return res.data.datalist;
          });
   
      export const saveForecastConsumption = (Param) =>
        api
          .post(
            `ForecastedConsumption`,
            {
              ...body,
              action: "getAllProductInsert",
              menukey: "forecasted-consumption-entry",
              UserName:UserName,
              UserId:UserId,
              FacilityId:FacilityId,
              lan: localStorage.getItem("LangCode"),
              ...Param
             
            },
            options
          )
          .then((res) => res.data);

          export const getSingleForecastData = (params) =>
            api.post("ForecastedConsumption", { params }, options).then((res) => {
              return res;
          });
         
          export const getForcastleftData = (params) =>
            api.post("ForecastedConsumption", { params }, options).then((res) => {
              //console.log(res.data.datalist);
              return res.data.datalist;
            });
          // export const getForcastleftData = (id) =>
          //   api.post(
          //     `ForecastedConsumption`,
          //     {
          //       ...body,
          //       action: "getForecastedSingleLeftTable",
          //       menukey: "forecasted-consumption-entry",
          //       YearId: id,
          //     },
          //     options
          //   );
              export const updateForecastConsumption = (params) =>
                //console.log(params.id);
                api
                  .put(
                    `ForecastedConsumption`,
                    {
                      ...body,
                      action: "getForeConDataUpdate",
                      menukey: "forecasted-consumption-entry",
                      ForecastCId: params.id,
                      StockQty: params.qtyval,
                      UserName: UserName,
                      lan: localStorage.getItem("LangCode"),
                    },
                    options
                  )
                  .then((res) => {
                    return res;
                  });

                  export const deleteforecast = (id) =>
                    api
                      .post(
                        `ForecastedConsumption`,
                        {
                          
                          ...body,
                          action: "deleteForecastedConsumptionData",
                          menukey: "forecasted-consumption-entry",
                          YearId:id,
                          CountryId: 1,
                          UserName: UserName,
                          lan: localStorage.getItem("LangCode"),
                        },
                        options
                      )
                      .then((res) => res.data);

                      export const getAdjShortOrder = (params) =>
                        api.post("adjustmentType", { params }, options).then((res) => {
                          return res.data.datalist;
                        });

                        export const updateAdjtypeShortOrder = (params) =>
                          //console.log(params.id);
                          api
                            .put(
                              `adjustmentType`,
                              {
                                ...body,
                                action: "dataUpdateSortOrder",
                                UserName: UserName,
                                lan: localStorage.getItem("LangCode"),
                                menukey: "adjustment-type",
                                AdjTypeData: params.AdjTypeData,
                              },
                              options
                            )
                            .then((res) => {
                              return res;
                            });

                            export const getConsumptionTrendProductChart = (params) =>
                              api.post("ConsumptionTrendbyProduct", { params }, options).then((res) => {
                                return res.data;
                              });
                              export const getFundingScourceData = (params) =>
                                api.post("CountryProfile", { params }, options).then((res) => {
                                  return res;
                              });
                              export const getSingleBasicInfoData = (params) =>
                                api.post("CountryProfile", { params }, options).then((res) => {
                                  return res;
                              });

                              export const FundingSourceInsertUpdate = (params) =>
                                //console.log(params.id);
                                api
                                  .post(
                                    `CountryProfile`,
                                    {
                                      ...body,
                                      action: "updateYcProfileMultipleData",
                                      UserName: UserName,
                                      FacilityId: FacilityId,
                                      lan: localStorage.getItem("LangCode"),
                                      menukey: "country-profile-entry",
                                      YearlyFundingSrcId: params.YearlyFundingSrcId,
                                      FundingSourceId: params.FundingSourceId,
                                      checkVal: params.checkVal,
                                      ItemGroupId: params.ItemGroupId,
                                      YearId: params.YearId
                                    },
                                    options
                                  )
                                  .then((res) => {
                                    return res;
                                  });

                                  export const updateBasicInfoData = (params) =>
                                    //console.log(params.id);
                                    api
                                      .put(
                                        `CountryProfile`,
                                        {
                                          ...body,
                                          action: "updateYcProfileData",
                                          menukey: "country-profile-entry",
                                          YCProfileId: params.id,
                                          YCValue: params.YCValue,
                                          DataSource: params.DataSource,
                                          UserName: UserName,
                                          FacilityId: FacilityId,
                                          lan: localStorage.getItem("LangCode"),
                                        },
                                        options
                                      )
                                      .then((res) => {
                                        return res;
                                      });

                                      export const getGapAnalysicsData = (params) =>
                                        api.post("CountryProfile", { params }, options).then((res) => {
                                          return res;
                                      });

                                      export const updategapData = (params) =>
                                        //console.log(params.id);
                                        api
                                          .put(
                                            `CountryProfile`,
                                            {
                                              ...body,
                                              action: "updatePledgedFundingData",
                                              menukey: "country-profile-entry",
                                              PledgedFundingId: params.PledgedFundingId,
                                              newval: params.newval, 
                                              UserName: UserName,
                                              FacilityId: FacilityId,
                                              lan: localStorage.getItem("LangCode"),
                                            },
                                            options
                                          )
                                          .then((res) => {
                                            return res;
                                          });
       
/* End Sumon API */

export const saveCommune = (communeData) =>
  api
    .post(
      `commune`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "commune",
        ...communeData,
      },
      options
    )
    .then((res) => res.data);

export const updateCommune = ({ id, ...updatedCommune }) =>
  api
    .put(
      `commune`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "commune",
        DistrictId: id,
        ...updatedCommune,
      },
      options
    )
    .then((res) => {
      return res;
    });

//export const deleteCommune = (id) => api.post(`commune`, id, options);
export const deleteCommune = (id) =>
api
  .post(
    `commune`,
    {
      ...body,
      action: "dataDalete",
      menukey: "commune-entry",
      DistrictId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);


//Product SubGroup admin form
export const getProductSubGroupList = (params) =>
  api.post("productsubgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getProductSubGroupData = (id) =>
  api.post(
    `productsubgroup`,
    {
      ...body,
      action: "getData",
      menukey: "product-subgroup-entry",
      ProductSubGroupId: id,
    },
    options
  );

export const saveProductSubGroup = (productsubgroupData) =>
  api
    .post(
      `productsubgroup`,
      {
        ...body,
        action: "dataInsert",
        menukey: "productsubgroup",
        ...productsubgroupData,
      },
      options
    )
    .then((res) => res.data);

export const updateProductSubGroup = ({ id, ...updatedRecipients }) =>
  api
    .put(
      `productsubgroup`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "product-subgroup-entry",
        ProductSubGroupId: id,
        ...updatedRecipients,
      },
      options
    )
    .then((res) => {
      return res;
    });

// export const deleteProductSubGroup = (id) =>
//   api.post(`productsubgroup`, id, options);
export const deleteProductSubGroup = (id) =>
api
  .post(
    `productsubgroup`,
    {
      ...body,
      action: "dataDalete",
      menukey: "product-subgroup-entry",
      ProductSubGroupId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);


// CS/Team Group Entry
export const getRecipentEntry = (params) =>
  api.post("csTeamGroupEntry", { params }, options).then((res) => {
    return res.data.datalist;
  });

// export const getStoreEntryData = (id) =>
// api.post(
//   `storeentry`,
//   {
//     ...body,
//     action: "getData",
//     menukey: "store-entry",
//     StoreId: id,
//     lan: localStorage.getItem("LangCode"),
//     FacilityLevel: localStorage.getItem("FLevelId"),
//   },
//   options
// );

export const saveCsTeamGroupEntry = (RecipientsFormData) =>
  api
    .post(
      `csTeamGroupEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "cs/team-group-entry",
        ...RecipientsFormData,
      },
      options
    )
    .then((res) => res.data);

// export const deleteStoreEntry = (id) => api.post(`csTeamGroupEntry`, id, options);

//   export const updateStoreEntry = ({ id, ...updatedRecipients }) =>
//   api
//     .put(
//       `csTeamGroupEntry`,
//       {
//         ...body,
//         action: "dataUpdate",
//         menukey: "store-entry",
//         StoreId: id,
//         ...updatedRecipients,
//       },
//       options
//     )
//     .then((res) => {
//       return res;
//     });

// ZS Api

export const getZSs = (params) =>
  api.post("zs", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getZSData = (id) =>
  api.post(
    `zs`,
    {
      ...body,
      action: "getZSData",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "zs",
      ZoneId: id,
    },
    options
  );

export const updateZS = ({ id, ...updatedZS }) =>
  api
    .put(
      `zs`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "zs",
        ZoneId: id,
        ...updatedZS,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveZS = (zsData) =>
  api
    .post(
      `zs`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "zs",
        ...zsData,
      },
      options
    )
    .then((res) => res.data);

export const deleteZS = (id) => api.post(`zs`, id, options);

// export const deleteZS = (data) =>
//   api.post(
//     `zs`,
//     {
//       ...body,
//       // action: "dataDalete",
//       UserName: UserName,
//       // lan: localStorage.getItem("LangCode"),
//       // menukey: "zs",
//       ZoneId: data.id,
//       ...data
//     },
//     options
//   );

/* Start Bilkis API */
// export const getParameterList = (currProductGroupId) => {
//   return api
//     .post(
//       "CountryProfileReport",
//       {
//         ...body,
//         action: "getParameterList",
//         menukey: "country-profile",
//         productGroupId: currProductGroupId,
      
//       },
//       options
//     )
//     .then((res) => {
//       return res.data.datalist;
//     });
// };
// Country Profile Report Start 
export const getParameterList = (params) =>
api
  .post("CountryProfileReport", { params }, options)
  .then((res) => {
    return res.data.datalist;
  });



export const getCountryLeaftMapData = (params) =>
  api.post("CountryProfileReport", { params }, options).then((res) => {
    return res.data.datalist;
});
export const getQuantityRequired = (params) =>
api
  .post("CountryProfileReport", { params }, options)
  .then((res) => {
    return res.data.datalist;
  });
// export const getPledgedFunding = (params) =>
//   api
//     .post("CountryProfileReport", { params }, options)
//     .then((res) => {
//       console.log('API Response:', res.data); // Debugging line
//       return res.data;
//     });
export const getPledgedFunding = (params) =>
  api.post("CountryProfileReport", { params }, options).then((res) => {
    // console.log('API Response:', res.data);
    return res;
});
// Country Profile Report End 

// Number of Orders Placed Per Year and Per Level 
export const getOrderPerYearTableTableReport = (params) =>
api.post("OrderPerYear", { params }, options).then((res) => {
  return res;
});
// Number Of Deliveries Made Per Month and Per Level Table
export const getOrderDeliveriesPerMonthTableReport = (params) =>
api.post("OrderDeliveriesPerMonth", { params }, options).then((res) => {
  return res;
});
// Order Satisfaction Rate Table
export const getOrderSatisfactionRateTableReport = (params) =>
api.post("OrderSatisfactionRate", { params }, options).then((res) => {
  return res;
});
// Vaccine Availability Rate Typeof Facility Table
export const getVaccineAvailabilityRateTypeofFacilityTableReport = (params) =>
api.post("VaccineAvailabilityRateTypeofFacility", { params }, options).then((res) => {
  return res;
});
// Average Number Stocks Out Days By Product Table
export const getAverageNumberStocksOutDaysByProductTableReport = (params) =>
api.post("AverageNumberStocksOutDaysByProduct", { params }, options).then((res) => {
  return res;
});

// Commodities Stock status by Lot Expiry
export const getProductLotStockReport = (params) =>
  api
    .post("CommoditiesStockstatusbyLotExpiry", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });
// Gap Analysis Report Api
export const getRportFundingStatusReport = (params) =>
  api
    .post("RportFundingStatus", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });
  export const getReportFundingStatusChart = (params) =>
    api.post("RportFundingStatus", { params }, options).then((res) => {
      return res.data;
    });

// Dispense report
export const getDispenseReport = (params) =>
  api
    .post("DispenseReport", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });

// Loss report
export const getLossReport = (params) =>
  api
    .post("LossReport", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });

// Stock take details reports
export const GetStockTakeDetailsReport = (params) =>
  api.post("Stocktakedetailsreports", { params }, options).then((res) => {
    return res.data.datalist;
  });

//A7 Report View
export const getA7viewReport = (params) => {
  return api.post("A7viewReport", { params }, options).then((res) => {
    return res;
  });
};

export const getConsumptionTrendReport = (params) =>
api.post("ConsumptionTrend", { params }, options).then((res) => {
  return res;
});
export const ConsumptionTrendbyFacilitydReport = (params) =>
api.post("ConsumptionTrendbyFacility", { params }, options).then((res) => {
  return res;
});

// Facility dashboard details
export const getFacilityDetails = (params) =>
  api.post("FacilityDashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });

// TOTAL ITEMS IN PROGRAM
export const getTotalitems = () => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "getTotalItem",
        menukey: "facility-dashboard",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

// NUMBER OF PRODUCTS AVAILABLE IN THIS CENTER
export const getTotalNonzeroProducts = (activeFacilityData) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "ShowTotalNonzeroProducts",
        menukey: "facility-dashboard",
        FacilityId: activeFacilityData,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

//Today date 
export const getTodayDate = (lang) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "getDateToday",
        menukey: "facility-dashboard",
        lan: lang,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};
//Last LMIS 
export const getLastLMIS = (FacilityId, lang) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "getLastLMISDate",
        menukey: "facility-dashboard",
        lan: lang,
        FacilityId: FacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

//order Details for facility dashboard
export const getPendingOrderDetails = (params) =>
  api.post("FacilityDashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });
//order Details for facility dashboard
export const getPendingReceiveDetails = (params) =>
  api.post("FacilityDashboard", { params }, options).then((res) => {
    return res.data.datalist;
  });


//Last Activity

export const getLastActivity = (specificDate, activeFacilityData, lang) => {



  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "getLastActivitys",
        menukey: "facility-dashboard",
        lan: lang,
        specificDate,
        FacilityId: activeFacilityData,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

// BalanceOfMajorCommodities
export const getBalanceOfMajorCommodities = (
  RegionId,
  DistrictId,
  FacilityId
) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "ShowBalanceOfMajorCommodities",
        menukey: "facility-dashboard",
        RegionId: RegionId,
        DistrictId: DistrictId,
        FacilityId: FacilityId,


      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

// 

export const getIssueDespenseChartData = (FacilityId) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "showIssueDespenseChartData",
        menukey: "facility-dashboard",
        lan: localStorage.getItem("LangCode"),
        FacilityId: FacilityId
      },
      options
    )
    .then((res) => {
      return res.data;
    });
};


// Activities Not Completed Month
export const getActivitiesNotCompletedMonth = (
  activeFacilityData,langu
) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "ShowActivitiesNotCompletedMonth",
        menukey: "facility-dashboard",
        lan:langu,
        FacilityId: activeFacilityData,


      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

// Activities Completed Month
export const getActivitiesCompletedMonth = (
  activeFacilityData,lang
) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "ShowActivitiesCompletedMonth",
        menukey: "facility-dashboard",
        lan:lang,
        FacilityId: activeFacilityData,

      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};
// Activities Completed Month
export const getPendingOrder = (activeFacility) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "ShowPendingOrder",
        menukey: "facility-dashboard",
        FacilityId: activeFacility,

      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};
export const getPendingReceive = (activeFacilityData,) => {
  return api
    .post(
      "FacilityDashboard",
      {
        ...body,
        action: "ShowPendingReceive",
        menukey: "facility-dashboard",
        lan: localStorage.getItem("LangCode"),
        FacilityId: activeFacilityData,

      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};


// CS/Team Api or Recipients
export const getRecipients = (params) =>
  api.post("recipients", { params }, options).then((res) => {
    console.log("supplierdata", res.data);
    return res.data.datalist;
  });

export const deleteRecipients = (id) => api.post(`recipients`, id, options);

// Manufacturer FORM
export const getManufacturerFormList = (params) =>
  api.post("manufacturer", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getManufacturerForm = (id) =>
  api.post(
    `manufacturer`,
    {
      ...body,
      action: "getManufacturerForm",
      menukey: "manufacturer-entry-form",
      ManufacturerId: id,
    },
    options
  );
export const saveManufacturerForm = (getManufacturerData) =>
  api
    .post(
      `manufacturer`,
      {
        ...body,
        action: "dataInsert",
        menukey: "manufacturer-entry-form",
        UserName: UserName,
        ...getManufacturerData,
      },
      options
    )
    .then((res) => res.data);

// export const deleteManufacturerForm = (id) =>
//   api.post(`manufacturer`, id, options);

export const deleteManufacturerForm = (id) =>
api
  .post(
    `manufacturer`,
    {
      ...body,
      action: "dataDalete",
      menukey: "manufacturer-entry-form",
      ManufacturerId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const updateManufacturerForm = ({ id, ...updateSupplier }) =>
  api
    .put(
      `manufacturer`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "manufacturer-entry-form",
        ManufacturerId: id,
        UserName: UserName,
        ...updateSupplier,
      },
      options
    )
    .then((res) => {
      return res;
    });
/* =======Start of Shipment Entry====== */

export const getShipments = (params) =>
  api.post("ShipmentEntry", { params }, options).then((res) => {
  return res.data.datalist;
});
export const getShipment = (id) =>
  api.post(
    `ShipmentEntry`,
    { ...body, action: "getShipment", menukey: "shipment", AgencyShipmentId: id },
    options
  );
export const saveShipment = (formData) =>
  api
    .post(
      `ShipmentEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "shipment",
        UserName: UserName,
        ...formData,
      },
      options
    )
    .then((res) => res.data);
    export const updateShipment = ({ id, ...updatedProduct }) =>
    api
      .put(
        `ShipmentEntry`,
        {
          ...body,
          action: "dataUpdate",
          menukey: "shipment",
          AgencyShipmentId: id,
          UserName: UserName,
          ...updatedProduct,
        },
        options
      )
      .then((res) => {
        return res.data;
      });
export const deleteShipment = (id) =>
  api
    .post(
      `ShipmentEntry`,
      {
        ...body,
        action: "dataDalete",
        menukey: "shipment",
        AgencyShipmentId: id,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => res.data);

// VVM Type Entry
export const getVVMTypeEntry = () =>
  api
    .post(
      "vvmTypeEntry",
      { ...body, action: "getvvmTypeEntryList", menukey: "vvmTypeEntry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getVVMEntry = (id) =>
  api.post(
    `vvmTypeEntry`,
    {
      ...body,
      action: "getVVMGroupEntry",
      menukey: "vvmTypeEntry",
      VvmId: id,
    },
    options
  );
export const saveVVMTypeEntry = (getProductGroup1) =>
  api
    .post(
      `vvmTypeEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "vvmTypeEntry",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// export const deleteProductGroup = (id) =>  api.post(`productGroupEntry`,{...body, "action": "dataDalete", "menukey": "productGroupEntry", "ItemGroupId":id}, options)
// export const deleteVVMType = (id) => api.post(`vvmTypeEntry`, id, options);
export const deleteVVMType = (id) =>
api
  .post(
    `vvmTypeEntry`,
    {
      ...body,
      action: "dataDalete",
      menukey: "vvm-type",
      VvmId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const updateVVMTypeEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `vvmTypeEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "vvmTypeEntry",
        VvmId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });
// Shipment Status Entry
export const getShipmentStatus = () =>
  api
    .post(
      "shipmentStatusForm",
      { ...body, action: "getShipmentStatusFormList", menukey: "shipmentStatusForm" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
    // Function to get VVM Status entry by ID
export const getShipmentStatusSingleEntry = (id) =>
  api.post(
    `shipmentStatusForm`,
    {
      ...body,
      action: "getShipmentStatusSingleEntry",
      menukey: "shipmentStatusForm",
      ShipmentStatusId: id,
    },
    options
  );

      // Function to save VVM Status entry
export const saveShipmentStatusForm = (getProductGroup1) =>
  api
    .post(
      `shipmentStatusForm`,
      {
        ...body,
        action: "dataInsert",
        menukey: "shipmentStatusForm",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

    export const updateShipmentEntry = ({ id, ...updateProductGroupEntry }) =>
      api
        .put(
          `shipmentStatusForm`,
          {
            ...body,
            action: "dataUpdate",
            menukey: "shipmentStatusForm",
            ShipmentStatusId: id,
            ...updateProductGroupEntry,
          },
          options
        )
        .then((res) => {
          return res;
        });
        export const deleteShipmentStatus = (id) =>
          api
            .post(
              `shipmentStatusForm`,
              {
                ...body,
                action: "dataDalete",
                menukey: "shipment-status",
                ShipmentStatusId: id,
                UserName: UserName,
                lan: localStorage.getItem("LangCode"),
              },
              options
            )
            .then((res) => res);
          

// VVM Status Entry

// Function to retrieve VVM Status entry list
export const getVVMStatusEntry = () =>
  api
    .post(
      "vvmStatusEntry",
      { ...body, action: "getVvmStatusEntryList", menukey: "vvmStatusEntry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });


// Function to get VVM Status entry by ID
export const getVVMStatusSingleEntry = (id) =>
  api.post(
    `vvmStatusEntry`,
    {
      ...body,
      action: "getVVMStatusSingleEntry",
      menukey: "vvmStatusEntry",
      VvmStatusId: id,
    },
    options
  );

// Function to save VVM Status entry
export const saveVVMStatusEntry = (getProductGroup1) =>
  api
    .post(
      `vvmStatusEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "vvmStatusEntry",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// export const deleteProductGroup = (id) =>  api.post(`productGroupEntry`,{...body, "action": "dataDalete", "menukey": "productGroupEntry", "ItemGroupId":id}, options)
// export const deleteVVMStatus = (id) => api.post(`vvmStatusEntry`, id, options);
export const deleteVVMStatus = (id) =>
api
  .post(
    `vvmStatusEntry`,
    {
      ...body,
      action: "dataDalete",
      menukey: "vvm-status",
      VvmStatusId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const updateVVMStatusEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `vvmStatusEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "vvmStatusEntry",
        VvmStatusId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });


// AEFI GENDER TYPE 

// Function to retrieve AEFI Gender Type entry list
export const getAEFIGenderTypeEntry = () =>
  api
    .post(
      "aefiGenderType",
      { ...body, action: "getAEFIGenderTypeEntryList", menukey: "aefiGenderType" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

// Function to get AEFI Gender Type entry by ID
export const getAEFIGenderTypeSingleEntry = (id) =>
  api.post(
    `aefiGenderType`,
    {
      ...body,
      action: "getAEFIGenderTypeSingleEntry",
      menukey: "aefiGenderType",
      GenderTypeId: id,
    },
    options
  );

// Function to save AEFI Gender Type entry
export const saveAEFIGenderTypeEntry = (getProductGroup1) =>
  api
    .post(
      `aefiGenderType`,
      {
        ...body,
        action: "dataInsert",
        menukey: "aefiGenderType",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// Function to update AEFI Gender Type entry
export const updateAEFIGenderTypeEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `aefiGenderType`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "aefiGenderType",
        GenderTypeId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });

// Function to delete AEFI Gender Type entry by ID
// export const deleteAEFIGenderTypeEntry = (id) => api.post(`aefiGenderType`, id, options);
export const deleteAEFIGenderTypeEntry = (id) =>
api
  .post(
    `aefiGenderType`,
    {
      ...body,
      action: "dataDalete",
      menukey: "aefi-gender-type",
      GenderTypeId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
// AEFI Professions
// ProfessionId
// Function to retrieve AEFI Severity entry list
export const getAEFIProfessionsEntry = () =>
  api
    .post(
      "aefiProfession",
      { ...body, action: "getAEFIProfessionsEntryList", menukey: "aefiProfession" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });


// Function to save AEFI Severity entry
export const saveAEFIProfessionsEntry = (getProductGroup1) =>
  api
    .post(
      `aefiProfession`,
      {
        ...body,
        action: "dataInsert",
        menukey: "aefiProfession",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);
    // Function to get AEFI Severity entry by ID
export const getAEFIProfession1Entry = (id) =>
api.post(
  `aefiProfession`,
  {
    ...body,
    action: "getAEFIProfession1Entry",
    menukey: "aefiProfession",
    ProfessionId: id,
  },
  options
);
// Function to update AEFI Severity entry
export const updateAEFIProfessionEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `aefiProfession`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "aefiProfession",
        ProfessionId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });
// Function to delete AEFI Severity entry by ID
// export const deleteAEFIseverityEntry = (id) => api.post(`aefiSeverity`, id, options);

export const deleteAEFIProfessionEntry = (id) =>
api
  .post(
    `aefiProfession`,
    {
      ...body,
      action: "dataDalete",
      menukey: "professions",
      ProfessionId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
// AEFI Severity 

// Function to retrieve AEFI Severity entry list
export const getAEFIseverityEntry = () =>
  api
    .post(
      "aefiSeverity",
      { ...body, action: "getAEFIseverityEntryList", menukey: "aefiSeverity" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

// Function to save AEFI Severity entry
export const saveAEFIseverityEntry = (getProductGroup1) =>
  api
    .post(
      `aefiSeverity`,
      {
        ...body,
        action: "dataInsert",
        menukey: "aefiSeverity",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// Function to get AEFI Severity entry by ID
export const getAEFIseverityEntry1 = (id) =>
  api.post(
    `aefiSeverity`,
    {
      ...body,
      action: "getAEFIseverity1Entry",
      menukey: "aefiSeverity",
      SeverityId: id,
    },
    options
  );

// Function to update AEFI Severity entry
export const updateAEFIseverityEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `aefiSeverity`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "aefiSeverity",
        SeverityId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });


// Function to delete AEFI Severity entry by ID
// export const deleteAEFIseverityEntry = (id) => api.post(`aefiSeverity`, id, options);

export const deleteAEFIseverityEntry = (id) =>
api
  .post(
    `aefiSeverity`,
    {
      ...body,
      action: "dataDalete",
      menukey: "aefi-severity",
      SeverityId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

// AEFI Stop Treatment API 

// Function to retrieve AEFI Stop Treatment entry list
export const getAEFIstopTreatmentEntry = () =>
  api
    .post(
      "aefiStopTreament",
      { ...body, action: "getAEFIstopTreatmentEntryList", menukey: "aefiStopTreament" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

// Function to save AEFI Stop Treatment entry
export const saveAEFIstopTreatmentEntry = (getProductGroup1) =>
  api
    .post(
      `aefiStopTreament`,
      {
        ...body,
        action: "dataInsert",
        menukey: "aefiStopTreament",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// Function to get AEFI Stop Treatment entry by ID
export const getAEFIstopTreatmentEntry1 = (id) =>
  api.post(
    `aefiStopTreament`,
    {
      ...body,
      action: "getAEFIstopTreatment11Entry",
      menukey: "aefiStopTreament",
      StopTreatmentId: id,
    },
    options
  );
// Function to update AEFI Stop Treatment entry
export const updateAEFIstopTreatmentEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `aefiStopTreament`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "aefiStopTreament",
        StopTreatmentId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });

// Function to delete AEFI Stop Treatment entry by ID
// export const deleteAEFIstopTreatmentEntry = (id) => api.post(`aefiStopTreament`, id, options);
export const deleteAEFIstopTreatmentEntry = (id) =>
api
  .post(
    `aefiStopTreament`,
    {
      ...body,
      action: "dataDalete",
      menukey: "aefi-stop-treatment",
      StopTreatmentId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

// AEFI Readministration API 

// Function to retrieve AEFI Readministration entry list
export const getAEFIReadministrationEntry = () =>
  api
    .post(
      "aefiReadministration",
      { ...body, action: "getAEFIReadministrationEntryList", menukey: "aefiReadministration" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

// Function to save AEFI Readministration entry
export const saveAEFIReadministrationEntry = (getProductGroup1) =>
  api
    .post(
      `aefiReadministration`,
      {
        ...body,
        action: "dataInsert",
        menukey: "aefiReadministration",
        ...getProductGroup1,
      },
      options
    )
    .then((res) => res.data);

// Function to get AEFI Readministration entry by ID
export const getAEFIReadministrationEntry1 = (id) =>
  api.post(
    `aefiReadministration`,
    {
      ...body,
      action: "getAEFIReadministration11Entry",
      menukey: "aefiReadministration",
      ReAdministrationId: id,
    },
    options
  );

// Function to update AEFI Readministration entry
export const updateAEFIReadministrationEntry = ({ id, ...updateProductGroupEntry }) =>
  api
    .put(
      `aefiReadministration`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "aefiReadministration",
        ReAdministrationId: id,
        ...updateProductGroupEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });

// Function to delete AEFI Readministration entry by ID
// export const deleteAEFIReadministrationEntry = (id) => api.post(`aefiReadministration`, id, options);

export const deleteAEFIReadministrationEntry = (id) =>
api
  .post(
    `aefiReadministration`,
    {
      ...body,
      action: "dataDalete",
      menukey: "aefi-readministration",
      ReAdministrationId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
/* End Bilkis API */

//Recipient Group admin form

export const getTotalRecipientGroup = (params) =>
  api.post("recipientgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getRecipientGroupList = (params) =>
  api.post("recipientgroup", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveRecipientGroup = (getunitofmeasure1) =>
  api
    .post(
      `recipientgroup`,
      {
        ...body,
        action: "dataInsert",
        menukey: "funding-source-entry",
        ...getunitofmeasure1,
      },
      options
    )
    .then((res) => res.data);
// export const deleteRecipientGroup = (id) =>
//   api.post(`recipientgroup`, id, options);
export const deleteRecipientGroup = (id) =>
api
  .post(
    `recipientgroup`,
    {
      ...body,
      action: "dataDalete",
      menukey: "recipient-group-entry",
      RecordId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const getRecipientGroup = (id) =>
  api.post(
    `recipientgroup`,
    {
      ...body,
      action: "getData",
      menukey: "funding-source-entry",
      TranslationId: id,
    },
    options
  );
export const updateRecipientGroup = ({ id, ...updatedunitof }) =>
  api
    .put(
      `recipientgroup`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "funding-source-entry",
        TranslationId: id,
        ...updatedunitof,
      },
      options
    )
    .then((res) => {
      return res;
    });

//End Recipient Group admin form

// Start store Type

export const getStoreEntry = (params) =>
  api.post("storeentry", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getStoreEntryData = (id) =>
  api.post(
    `storeentry`,
    {
      ...body,
      action: "getData",
      menukey: "store-entry",
      StoreId: id,
      lan: localStorage.getItem("LangCode"),
      FacilityLevel: localStorage.getItem("FLevelId"),
    },
    options
  );

export const saveStoreEntry = (RecipientsFormData) =>
  api
    .post(
      `storeentry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "store-entry",
        ...RecipientsFormData,
      },
      options
    )
    .then((res) => res.data);

export const deleteStoreEntry = (id) => api.post(`storeentry`, id, options);

export const updateStoreEntry = ({ id, ...updatedRecipients }) =>
  api
    .put(
      `storeentry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "store-entry",
        StoreId: id,
        ...updatedRecipients,
      },
      options
    )
    .then((res) => {
      return res;
    });

// Facility Level Entry
export const getFacilityLevelEntry33 = () =>
  api
    .post(
      "facilityLevelEntry",
      {
        ...body,
        action: "getFacilityEntryList",
        menukey: "facilityLevelEntry",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getFacilityLevel = (id) =>
  api.post(
    `facilityLevelEntry`,
    {
      ...body,
      action: "getFacilityLevel",
      menukey: "facilityLevelEntry",
      FLevelId: id,
    },
    options
  );
export const saveFacilityLevel = (getFacilityLevel1) =>
  api
    .post(
      `facilityLevelEntry`,
      {
        ...body,
        action: "dataInsert",
        menukey: "facilityLevelEntry",
        ...getFacilityLevel1,
      },
      options
    )
    .then((res) => res.data);

// export const deleteProductGroup = (id) =>  api.post(`facilityLevelEntry`,{...body, "action": "dataDalete", "menukey": "productGroupEntry", "ItemGroupId":id}, options)
// export const deleteFacilityLevel = (id) =>
//   api.post(`facilityLevelEntry`, id, options);
export const deleteFacilityLevel = (id) =>
api
  .post(
    `facilityLevelEntry`,
    {
      ...body,
      action: "dataDalete",
      menukey: "facility-level-entry",
      FLevelId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
  

export const updateFacilityLevelEntry = ({ id, ...updateFacilityLevelEntry }) =>
  api
    .put(
      `facilityLevelEntry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "facilityLevelEntry",
        FLevelId: id,
        ...updateFacilityLevelEntry,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Order type
export const getOrderType24 = () =>
  api
    .post(
      "ordertype",
      { ...body, action: "getOrderTypeList", menukey: "routeofadmin" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const geOrderTypes = (id) =>
  api.post(
    `ordertype`,
    {
      ...body,
      action: "gedOrderType",
      menukey: "routeofadmin",
      OrderTypeId: id,
    },
    options
  );
export const saveOrderType = (getordetype1) =>
  api
    .post(
      `ordertype`,
      {
        ...body,
        action: "dataInsert",
        menukey: "routeofadmin",
        ...getordetype1,
      },
      options
    )
    .then((res) => res.data);

//export const deleteOrderType = (id) =>  api.post(`ordertype`,{...body, "action": "dataDalete", "menukey": "routeofadmin", "OrderTypeId":id}, options)
// export const deleteOrderType = (id) => api.post(`ordertype`, id, options);
export const deleteOrderType = (id) =>
api
  .post(
    `ordertype`,
    {
      ...body,
      action: "dataDalete",
      menukey: "order-type-list-entry",
      OrderTypeId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);


export const updateOrderType = ({ id, ...updateOrderTypes }) =>
  api
    .put(
      `ordertype`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "routeofadmin",
        OrderTypeId: id,
        ...updateOrderTypes,
      },
      options
    )
    .then((res) => {
      return res;
    });

//ABC List
export const getABClist77 = () =>
  api
    .post("abc", { ...body, action: "getABCList", menukey: "abc" }, options)
    .then((res) => {
      return res.data.datalist;
    });
export const geABC = (id) =>
  api.post(
    `abc`,
    { ...body, action: "geABC", menukey: "abc", AbcId: id },
    options
  );
export const saveABC = (getabc1) =>
  api
    .post(
      `abc`,
      { ...body, action: "dataInsert", menukey: "abc", ...getabc1 },
      options
    )
    .then((res) => res.data);

//export const deleteABC = (id) =>  api.post(`abc`,{...body, "action": "dataDalete", "menukey": "abc", "AbcId":id}, options)
export const deleteABC = (id) => api.post(`abc`, id, options);

export const updateABC = ({ id, ...updateabcsd }) =>
  api
    .put(
      `abc`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "abc",
        AbcId: id,
        ...updateabcsd,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Specialisation List Api
export const getSpecialisation = () =>
  api
    .post(
      "comboscript",
      { ...body, action: "getSpecialisationList", menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });

// Adjustment Type Api
export const getAdjustmentTypes = () =>
  api
    .post(
      "adjustmentType",
      { ...body, action: "getDataList", menukey: "adjustment-type" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getAdjustmentType = (id) =>
  api.post(
    `adjustmentType`,
    {
      ...body,
      action: "getAdjustmentType",
      menukey: "adjustment-type",
      AdjTypeId: id,
    },
    options
  );

export const updateAdjustmentType = ({ id, ...updatedAdjustmentType }) =>
  api
    .put(
      `adjustmentType`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "adjustment-type",
        AdjTypeId: id,
        ...updatedAdjustmentType,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveAdjustmentType = (adjustmentTypeData) =>
  api
    .post(
      `adjustmentType`,
      {
        ...body,
        action: "dataInsert",
        menukey: "adjustment-type",
        ...adjustmentTypeData,
      },
      options
    )
    .then((res) => res.data);

//export const deleteAdjustmentType = (id)=>  api.post(`adjustmentType`,{...body, "action": "dataDalete", "menukey": "adjustment-type", "AdjTypeId":id}, options)
// export const deleteAdjustmentType = (id) =>
//   api.post(`adjustmentType`, id, options);

export const deleteAdjustmentType = (id) =>
api
  .post(
    `adjustmentType`,
    {
      ...body,
      action: "dataDalete",
      menukey: "adjustment-type",
      AdjTypeId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
// Pack Size Api
export const getPackSizes = () =>
  api
    .post(
      "packSize",
      { ...body, action: "getDataList", menukey: "pack-size" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getPackSizeData = (id) =>
  api.post(
    `packSize`,
    {
      ...body,
      action: "getPackSizeData",
      menukey: "pack-size",
      PacksizeId: id,
    },
    options
  );

export const updatePackSize = ({ id, ...updatedPackSize }) =>
  api
    .put(
      `packSize`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "pack-size",
        PacksizeId: id,
        ...updatedPackSize,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const savePackSize = (packSizeData) =>
  api
    .post(
      `packSize`,
      { ...body, action: "dataInsert", menukey: "pack-size", ...packSizeData },
      options
    )
    .then((res) => res.data);

//export const deletePackSize = (id)=>  api.post(`packSize`,{...body, "action": "dataDalete", "menukey": "pack-size", "PacksizeId":id}, options)
//export const deletePackSize = (id) => api.post(`packSize`, id, options);

export const deletePackSize = (id) =>
api
  .post(
    `packSize`,
    {
      ...body,
      action: "dataDalete",
      menukey: "pack-size",
      PacksizeId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

// Form Api
export const getForms = () =>
  api
    .post("form", { ...body, action: "getDataList", menukey: "form" }, options)
    .then((res) => {
      return res.data.datalist;
    });

export const getFormData = (id) =>
  api.post(
    `form`,
    { ...body, action: "getFormData", menukey: "form", DosageFormId: id },
    options
  );

export const updateForm = ({ id, ...updatedForm }) =>
  api
    .put(
      `form`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "form",
        DosageFormId: id,
        ...updatedForm,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveForm = (formData) =>
  api
    .post(
      `form`,
      { ...body, action: "dataInsert", menukey: "form", ...formData },
      options
    )
    .then((res) => res.data);

//export const deleteForm = (id)=>  api.post(`form`,{...body, "action": "dataDalete", "menukey": "form", "DosageFormId":id}, options)
//export const deleteForm = (id) => api.post(`form`, id, options);

export const deleteForm = (id) =>
api
  .post(
    `form`,
    {
      ...body,
      action: "dataDalete",
      menukey: "form",
      DosageFormId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
// Strength Api
export const getStrengths = () =>
  api
    .post(
      "strength",
      { ...body, action: "getDataList", menukey: "strength" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getStrengthData = (id) =>
  api.post(
    `strength`,
    { ...body, action: "getStrengthData", menukey: "strength", StrengthId: id },
    options
  );

export const updateStrength = ({ id, ...updatedStrength }) =>
  api
    .put(
      `strength`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "strength",
        StrengthId: id,
        ...updatedStrength,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveStrength = (strengthData) =>
  api
    .post(
      `strength`,
      { ...body, action: "dataInsert", menukey: "strength", ...strengthData },
      options
    )
    .then((res) => res.data);

//export const deleteStrength = (id)=>  api.post(`strength`,{...body, "action": "dataDalete", "menukey": "strength", "StrengthId":id}, options)
//export const deleteStrength = (id) => api.post(`strength`, id, options);

export const deleteStrength = (id) =>
api
  .post(
    `strength`,
    {
      ...body,
      action: "dataDalete",
      menukey: "strength",
      StrengthId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
//Dispensing Language List Api
export const getLoginDispensingLanguage = async (options) =>
  api
    .post(
      "comboscript",
      { action: "getDispensingLanguageList", menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });
export const getDispensingLanguage = () =>
  api
    .post(
      "comboscript",
      { action: "getDispensingLanguageList", menukey: "" },
      options
    )
    .then((res) => {
      return res.data;
    });

// Ui Language Api

export const updateUiLanguage = (params) =>
  //console.log(params.id);
  api
    .put(
      `uiLanguage`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "ui-language",
        LangLabelId: params.id,
        LangText: params.LangText,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getUiLanguages = (params) =>
  api.post("uiLanguage", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getUiLanguageData = (id) =>
  api.post(
    `uiLanguage`,
    {
      ...body,
      action: "getUiLanguageData",
      menukey: "ui-language",
      LangLabelId: id,
    },
    options
  );

export const saveUiLanguage = (uiLanguageData) =>
  api
    .post(
      `uiLanguage`,
      {
        ...body,
        action: "dataInsert",
        menukey: "ui-language",
        ...uiLanguageData,
      },
      options
    )
    .then((res) => res.data);

// Facility Api api.js

export const updateFacility = (params) =>
  //console.log(params.id);
  api
    .put(
      `facility`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "facility",
        FacilityId: params.id,
        bDispense: params.bDispenseVal,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getFacilityCode = (params) =>
  api.post("facility", { ...body, params }, options).then((res) => {
    return res.data.datalist;
  });

// export const deleteaFacility = (id) => api.post(`facility`, id, options);
export const deleteaFacility = (id) =>
api
  .post(
    `facility`,
    {
      ...body,
      action: "deleteaFacility",
      menukey: "facility",
      FacilityId: id,
      bDispense: 0,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const getFacilities = (params) =>
  api.post("facility", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const saveFacility = (saveFacilityData) =>
  api
    .post(
      `facility`,
      {
        ...body,
        action: "dataInsert",
        menukey: "facility",
        ...saveFacilityData,
      },
      options
    )
    .then((res) => res.data);

export const updateSingleFacility = ({ id, ...updatedFacility }) =>
  api
    .put(
      `facility`,
      {
        ...body,
        action: "UpdateFacility",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "facility",
        FacilityId: id,
        ...updatedFacility,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getFacilitiesList = (params) =>
  api.post("facility_list", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getSingleListView = (id) =>
  api.post(
    `facility_list_single_view`,
    {
      ...body,
      action: "getFacilitySingleView",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "facility-list",
      FacilityId: id,
    },
    options
  );

export const getFacilitySingleView = (id) =>
  api.post(
    `facility`,
    {
      ...body,
      action: "getFacilitySingleView",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "facility",
      FacilityId: id,
    },
    options
  );

export const gZonelist = (zoneData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...zoneData }, options).then((res) => {
    return res;
  });
};

export const gCommunelist = (communeData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...communeData }, options).then((res) => {
    return res;
  });
};

export const deleteAllRecreateOrderInv = ({ id, ...updatedOrder }) =>
  api
    .post(
      `DeleteAllRecreateOrder`,
      { ...body, menukey: "order", ...updatedOrder },
      options
    )
    .then((res) => {
      return res.data;
    });

/* Start Madani API */

export const getStockByExpiryDateChartData = (
  currProductGroupId,
  currProductId
) => {
  return api
    .post(
      "Dashboard",
      {
        ...body,
        action: "getStockByExpiryDate",
        menukey: "dashboard",
        productGroupId: currProductGroupId,
        productId: currProductId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getDistrictWiseBalance = (currProductGroupId, currProductId) => {
  return api
    .post(
      "Dashboard",
      {
        ...body,
        action: "getDistrictWiseBalance",
        menukey: "dashboard",
        productGroupId: currProductGroupId,
        productId: currProductId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getReceiveDetailsChartData = (
  currProductGroupId,
  currProductId
) => {
  return api
    .post(
      "Dashboard",
      {
        ...body,
        action: "getReceiveDetails",
        menukey: "dashboard",
        productGroupId: currProductGroupId,
        productId: currProductId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const UpdateLocation = (locationData) => {
  return api.post("StockStatus", { ...locationData }, options).then((res) => {
    return res;
  });
};

/* End Madani API */

//Role Entry from
export const getRole = () =>
  api
    .post("roles", { ...body, action: "getRoleList", menukey: "role" }, options)
    .then((res) => {
      return res.data.datalist;
    });
export const geRoles = (id) =>
  api.post(
    `roles`,
    { ...body, action: "geRole", menukey: "role", id: id },
    options
  );
export const saveRole = (getrole1) =>
  api
    .post(
      `roles`,
      { ...body, action: "dataInsert", menukey: "role", ...getrole1 },
      options
    )
    .then((res) => res.data);

//export const deleteRole = (id) =>  api.post(`roles`,{...body, "action": "dataDalete", "menukey": "role", "id":id}, options)
// export const deleteRole = (id) => api.post(`roles`, id, options);
export const deleteRole = (id) =>
api
  .post(
    `roles`,
    {
      ...body,
      action: "dataDalete",
      menukey: "role-entry",
      id: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
export const updateRole = ({ id, ...updateRoles }) =>
  api
    .put(
      `roles`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "role",
        id: id,
        ...updateRoles,
      },
      options
    )
    .then((res) => {
      return res;
    });

// User Entry Api
export const getUserList = () =>
  api
    .post(
      "userlist",
      { ...body, action: "getUserList", menukey: "user-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getUser = (id) =>
  api.post(
    `userlist`,
    { ...body, action: "getUser", menukey: "user-entry", id: id },
    options
  );

export const updateUser = ({ id, ...updatedUser }) =>
  api
    .put(
      `userlist`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "user-entry",
        user_id: id,
        ...updatedUser,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveUser = (userData) =>
  api
    .post(
      `userlist`,
      { ...body, action: "dataInsert", menukey: "user-entry", ...userData },
      options
    )
    .then((res) => res.data);
// export const userDelete = (id) => api.post(`userlist`, id, options);
export const userDelete = (id) =>
api
  .post(
    `userlist`,
    {
      ...body,
      action: "userDelete",
      menukey: "user-entry",
      user_id: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

// export const userDelete = (id) =>
//   api.post(
//     `userlist`,
//     { ...body, action: "userDelete", menukey: "user-entry", user_id: id },
//     options
//   );

//role permission

export const getRoleAccessEntry = () =>
  api
    .post(
      "roleaccess",
      { ...body, action: "getRoleList", menukey: "roleaccess" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getMenuPerEntry = (params) =>
  api.post("roleaccess", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const rolesToMenuInsert = (params) =>
  //console.log(params.id);
  api
    .post(
      `roleaccess`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "role-access-entry",
        role_id: params.selectedRoleData,
        menu_id: params.menu_id,
        bChecked: params.bCheckedVal,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Fetch Month Close Data api
export const getMonthCloseData = (params) =>
  api.post("monthclose", { params }, options).then((res) => {
    // console.log("Get data for table api");
    return res.data.datalist;
  });

//Month Close Api Generate Data
export const monthCloseByFacility = (params) =>  
  api
    .post(
      `monthclose`,
      {
        ...body,
        action: "dataInsert",
        menukey: "month-close",
        currYearId: params.currYearId,
        currMonthId: params.MonthId,
        currFacilityId: params.FacilityId,
        StatusId: params.StatusId,
        UserId: params.UserId,
        UserName:UserName
      },
      options
    )
    .then((res) => {
      return res;
    }); 

//Month Close Delete Api
export const monthCloseDeleteByFacility = (params) => api
    .post(
      `monthclose`,
      {
        ...body,
        action: "dataDeleteByFacility",
        menukey: "month-close",
        currYearId: params.currYearId,
        currMonthId: params.MonthId,
        currFacilityId: params.FacilityId,
        StatusId: params.StatusId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        UserId:UserId
      },
      options
    )
    .then((res) => {
      return res;
    });


export const getMenuShortOrder = (params) =>
  api.post("roleaccess", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const updateMenuShortOrder = (params) =>
  //console.log(params.id);
  api
    .put(
      `roleaccess`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "role-access-entry",
        MenuData: params.MenuData,
      },
      options
    )
    .then((res) => {
      return res;
    });

// Generics Api
export const getGenericss = () =>
  api
    .post(
      "generics",
      { ...body, action: "getDataList", menukey: "generics" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getGenericsData = (id) =>
  api.post(
    `generics`,
    { ...body, action: "getGenericsData", menukey: "generics", GenericId: id },
    options
  );

export const updateGenerics = ({ id, ...updatedGenerics }) =>
  api
    .put(
      `generics`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "generics",
        GenericId: id,
        ...updatedGenerics,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveGenerics = (genericsData) =>
  api
    .post(
      `generics`,
      { ...body, action: "dataInsert", menukey: "generics", ...genericsData },
      options
    )
    .then((res) => res.data);

//export const deleteGenerics = (id)=>  api.post(`generics`,{...body, "action": "dataDalete", "menukey": "generics", "GenericId":id}, options)
// export const deleteGenerics = (id) => api.post(`generics`, id, options);
export const deleteGenerics = (id) =>
api
  .post(
    `generics`,
    {
      ...body,
      action: "dataDalete",
      menukey: "generics",
      GenericId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

//Patient Import
export const savePatientImport = (PatientImportData) =>
  api
    .post(
      `patient_import`,
      {
        action: "PatientImportDataInsert",
        menukey: "patient-import",
        ...PatientImportData,
      },
      options
    )
    .then((res) => res.data);

// MOS Range Entry Api
export const getMOSRanges = () =>
  api
    .post(
      "mosrange",
      { ...body, action: "getDataList", menukey: "mos-range" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const getMOSRange = (id) =>
  api.post(
    `mosrange`,
    { ...body, action: "getMOSRange", menukey: "mos-range", MosTypeId: id },
    options
  );

export const updateMOSRange = ({ id, ...updatedMOSRange }) =>
  api
    .put(
      `mosrange`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "mos-range",
        MosTypeId: id,
        ...updatedMOSRange,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const saveMOSRange = (mosrangeData) =>
  api
    .post(
      `mosrange`,
      { ...body, action: "dataInsert", menukey: "mos-range", ...mosrangeData },
      options
    )
    .then((res) => res.data);

//export const deleteMOSRange = (id)=>  api.post(`mosrange`,{...body, "action": "dataDalete", "menukey": "mos-range", "MosTypeId":id}, options)
//export const deleteMOSRange = (id) => api.post(`mosrange`, id, options);
export const deleteMOSRange = (id) =>
api
  .post(
    `mosrange`,
    {
      ...body,
      action: "dataDalete",
      menukey: "mos-range",
      MosTypeId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

//menu Insert
export const getmenuEntry = () =>
  api
    .post(
      "menuentry",
      { ...body, action: "getMenuList", menukey: "menuentry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const saveMenuentry = (userData) =>
  api
    .post(
      `menuentry`,
      { ...body, action: "dataInsert", menukey: "menuentry", ...userData },
      options
    )
    .then((res) => res.data);

export const updateMenuentry = ({ id, ...updatedMenu }) =>
  api
    .put(
      `menuentry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "menuentry",
        id: id,
        ...updatedMenu,
      },
      options
    )
    .then((res) => {
      return res;
    });

// export const deletemenu = (id) => api.post(`menuentry`, id, options);
export const deletemenu = (id) =>
api
  .post(
    `menuentry`,
    {
      ...body,
      action: "dataDalete",
      menukey: "menu-entry",
      id: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const geImenuentrys = (id) =>
  api.post(
    `menuentry`,
    { ...body, action: "gemenuEntry", menukey: "menuentry", id: id },
    options
  );

//ATC List
export const getatcEntry = () =>
  api
    .post("atc", { ...body, action: "getATCList", menukey: "atc" }, options)
    .then((res) => {
      return res.data.datalist;
    });
export const geAtc = (id) =>
  api.post(
    `atc`,
    { ...body, action: "geATC", menukey: "atc", ATC: id },
    options
  );
export const saveATC = (getatc1) =>
  api
    .post(
      `atc`,
      { ...body, action: "dataInsert", menukey: "atc", ...getatc1 },
      options
    )
    .then((res) => res.data);

//export const deleteABC = (id) =>  api.post(`abc`,{...body, "action": "dataDalete", "menukey": "abc", "AbcId":id}, options)
export const deleteATC = (id) => api.post(`atc`, id, options);

export const updateATC = ({ id, ...updateATCed }) =>
  api
    .put(
      `atc`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "atc",
        ATC: id,
        ...updateATCed,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Source Entry
export const getsourceEntry = () =>
  api
    .post(
      "source",
      { ...body, action: "getSourceList", menukey: "source" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const geSource = (id) =>
  api.post(
    `source`,
    { ...body, action: "geSource", menukey: "source", SourceId: id },
    options
  );
export const saveSource = (getSource1) =>
  api
    .post(
      `source`,
      { ...body, action: "dataInsert", menukey: "source", ...getSource1 },
      options
    )
    .then((res) => res.data);

export const deletesource = (id) => api.post(`source`, id, options);

export const updatesource = ({ id, ...updatesourceed }) =>
  api
    .put(
      `source`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "source",
        SourceId: id,
        ...updatesourceed,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getStockSummaryChart = (params) => {
  return api.post("StockSummary", { params }, options).then((res) => {
    return res;
  });
};

export const getStockSummaryTable = (params) => {
  return api.post("StockSummary", { params }, options).then((res) => {
    return res.data.datalist;
  });
};

export const getConsumptionTrendsForTheTopFiveProductsChart = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  menukey
) => {
  return api
    .post(
      "ConsumptionTrendsForTheTopFiveProducts",
      {
        ...body,
        action: "getConsumptionTrendsForTheTopFiveProductsChart",
        menukey: menukey,
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getConsumptionTrendsForTheTopFiveProductsTable = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  menukey
) => {
  return api
    .post(
      "ConsumptionTrendsForTheTopFiveProducts",
      {
        ...body,
        action: "getConsumptionTrendsForTheTopFiveProductsTable",
        menukey: menukey,
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getStockStatusDetailsChart = (params) => {
  return api.post("StockStatusDetails", { params }, options).then((res) => {
    return res;
  });
};

export const getAMCTrendsforTheTopFiveProductsChart = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  menukey
) => {
  return api
    .post(
      "AMCTrendsforTheTopFiveProducts",
      {
        ...body,
        action: "getAMCTrendsforTheTopFiveProductsChart",
        menukey: menukey,
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getAMCTrendsforTheTopFiveProductsTable = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  menukey
) => {
  return api
    .post(
      "AMCTrendsforTheTopFiveProducts",
      {
        ...body,
        action: "getAMCTrendsforTheTopFiveProductsTable",
        menukey: menukey,
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getPercentageOfFacilitiesWithAStockoutOfTracerCommoditiesChart = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  menukey
) => {
  return api
    .post(
      "PercentageOfFacilitiesWithAStockoutOfTracerCommodities",
      {
        ...body,
        action:
          "getPercentageOfFacilitiesWithAStockoutOfTracerCommoditiesChart",
        menukey: menukey,
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getPercentageOfFacilitiesWithAStockoutOfTracerCommoditiesTable = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  menukey
) => {
  return api
    .post(
      "PercentageOfFacilitiesWithAStockoutOfTracerCommodities",
      {
        ...body,
        action:
          "getPercentageOfFacilitiesWithAStockoutOfTracerCommoditiesTable",
        menukey: menukey,
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getAvailabilityOfCommoditiesChart = (params) => {
  return api
    .post("AvailabilityOfCommodities", { params }, options)
    .then((res) => {
      return res;
    });
};

/* Start Rubel API */

// Hello Rubel

/* End Rubel API */

//User profile

export const UserProfileupdate = ({ id, ...updatedUser }) =>
  api
    .put(
      `Myprofile`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "user-entry",
        user_id: id,
        ...updatedUser,
        UserName: UserName,
        lan: localStorage.getItem("LangCode")
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getUserProfile = (id) =>
  api.post(
    `Myprofile`,
    { ...body, action: "getUser", menukey: "user-entry", id: id },
    options
  );

export const getAvailabilityOfCommoditiesTable = (params) => {
  return api
    .post("AvailabilityOfCommodities", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });
};

export const getStockStatusDetailsTable = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  menukey
) => {
  return api
    .post(
      "StockStatusDetails",
      {
        ...body,
        action: "getStockStatusDetailsTable",
        menukey: menukey,
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
      },
      options
    )
    .then((res) => {
      // return res.data.datalist;
      return res.data.datalist.data;
    });
};

//LMIS Report
export const getLMISReportTable = (params) => {
  return api.post("LMISReport", { params }, options).then((res) => {
    return res.data.datalist;
  });
};

//Products Nearing Expiry Report
export const getProductsNearingExpiryReportTable = (params) => {
  return api
    .post("ProductsNearingExpiryReport", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });
};

export const gFacilitylist = (facilityData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...facilityData }, options).then((res) => {
    return res;
  });
};

// Year entry
export const getYearEntry = () =>
  api
    .post(
      "yearentry",
      { ...body, action: "getDataList", menukey: "year-entry" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const saveYearEntry = (ServiceData) =>
  api
    .post(
      `yearentry`,
      { ...body, action: "dataInsert", menukey: "year-entry", ...ServiceData },
      options
    )
    .then((res) => res.data);
export const updateYearEntry = ({ id, ...updategetService }) =>
  api
    .put(
      `yearentry`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "year-entry",
        YearID: id,
        ...updategetService,
      },
      options
    )
    .then((res) => {
      return res;
    });
export const getYearEntryData = (id) =>
  api.post(
    `yearentry`,
    { ...body, action: "getData", menukey: "year-entry", YearID: id },
    options
  );
//export const deleteYearEntry = (id) => api.post(`yearentry`, id, options);
export const deleteYearEntry = (id) =>
api
  .post(
    `yearentry`,
    {
      ...body,
      action: "dataDalete",
      menukey: "year-entry",
      YearID: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

// end Year entry

///Adjustment
export const getAdjustmentInvs = (params) =>
  api.post("adjustmentinvoicelist", { params }, options).then((res) => {
    return res.data.adjustmentinvdata;
  });

export const saveAdjustment = (adjustmentData) =>
  api
    .post(
      `adjustmentSave`,
      { ...body, ...adjustmentData,UserName: UserName,UserId: UserId, menukey: "adjustment" },
      options
    )
    .then((res) => res.data);

export const updateAdjustmentInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `adjustmentUpdate`,
      {
        ...body,
        menukey: "receive",
        lan: localStorage.getItem("LangCode"),
        UserName: UserName,
        UserId: UserId,
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getAdjustmentInv = (sParam) =>
  api.post(
    `adjustmentListSingle`,
    { ...body, menukey: "receive", ...sParam, FacilityId: FacilityId },
    options
  );

export const deleteAdjustmentInv = (id) =>
  api
    .post(
      `adjustmentDelete`,
      {
        ...body,
        menukey: "receive",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

// Error Log
export const getErrorLogData = (params) =>
  api.post("errorlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

// Audit Log

export const getAuditLogData = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });
export const getScanLog = (params) =>
  api.post("scanlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getLogSingleRow = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const gDataBaseInfo = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const gTableInfo = (params) =>
  api.post("auditlog", { params }, options).then((res) => {
    return res.data.datalist;
  });

//Prescription Item modal
export const getPrescriptionItemListModal = () =>
  api
    .post(
      "prescriptionModal",
      { ...body, action: "getPrescriptionItemListModal", menukey: "protocol" },
      options
    )
    .then((res) => {
      return res.data.prescriptionitemlist;
    });

///Repacking
export const getRepackingInvs = (params) =>
  api.post("repackinginvoicelist", { params }, options).then((res) => {
    return res.data.adjustmentinvdata;
  });

export const saveRepacking = (repackingData) =>
  api
    .post(
      `repackingSave`,
      { ...body, ...repackingData, menukey: "repacking" },
      options
    )
    .then((res) => res.data);

export const updateRepackingInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `repackingUpdate`,
      { ...body, menukey: "receive", ...updatedDispenser },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getRepackingInv = (id) =>
  api.post(
    `repackingListSingle`,
    { ...body, menukey: "receive", TransactionId: id, FacilityId: FacilityId },
    options
  );

export const deleteRepackingInv = (id) =>
  api
    .post(
      `repackingDelete`,
      {
        ...body,
        menukey: "receive",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getRepackingProductsLot = (id, ProductRepackingToVal) =>
  api
    .post(
      "repackingproducts",
      {
        ...body,
        action: "getRepackingLot",
        menukey: "repacking",
        FacilityId: id,
        ProductRepackingToVal: ProductRepackingToVal,
      },
      options
    )
    .then((res) => {
      //console.log("ProductRepackingToVal ",ProductRepackingToVal)
      return res.data.repackingproductlist;
    });

// Start Facility Group Map Api

export const getFacilityGroupMap = (params) => {
  const FacilityId = params.queryKey[0].FacilityId;
  return api
    .post(
      "facilityGroupMap",
      {
        ...body,
        FacilityId,
        action: "getDataList",
        menukey: "facilityGroupMap",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const saveFacilityGroupMap = (facilityGroupMapData) =>
  api
    .post(
      `facilityGroupMap`,
      {
        ...body,
        action: "dataInsert",
        menukey: "facilityGroupMap",
        UserName: UserName,
        ...facilityGroupMapData,
      },
      options
    )
    .then((res) => res.data);

export const updateFacilityGroupMap = ({ id, ...updateFacilityGroupMap }) =>
  api
    .put(
      `facilityGroupMap`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "facility",
        FacilityServiceId: id,
        lan: localStorage.getItem("LangCode"),
        UserName: UserName,
        ...updateFacilityGroupMap,
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getFacilityGroupMapById = (id) =>
  api.post(
    `facilityGroupMap`,
    {
      ...body,
      action: "getDataById",
      menukey: "facilityGroupMap",
      FacilityServiceId: id,
    },
    options
  );

export const deleteFacilityGroupMap = (params) =>
  api.post(
    `facilityGroupMap`,
    {
      ...body,
      action: "dataDalete",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "facility",
      ...params,
      UserId: UserName,
    },
    options
  );

export const getSupplyFromList = (params) =>
  api
    .post(
      "facilityGroupMap",
      {
        ...body,
        ...params,
        action: "getFacilityWarehouseList",
        menukey: "facilityGr",
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });

export const AssignGroupFacility = (params) =>
  api
    .put(
      `facility`,
      {
        ...body,
        ...params,
        action: "insertUpdateFacilityGroupMapPopup",
        menukey: "facility",
        UserId: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res;
    });

export const getProductGroupMapPopup = (params) =>
  api
    .put(
      `facility`,
      {
        ...body,
        ...params,
        action: "getProductGroupMapPopup",
        menukey: "facility",
        UserId: UserName,
      },
      options
    )
    .then((res) => {
      return res;
    });
// End Facility Group Map Api

//Issue
export const getIssueInvs = (params) =>
  api.post("issueinvoicelist", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

export const deleteIssueInv = (id) =>
  api
    .post(
      `issueDelete`,
      {
        ...body,
        menukey: "issue",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getIssueInv = (id) =>
  api.post(
    `issueListSingle`,
    { ...body, menukey: "issue", TransactionId: id, FacilityId: FacilityId },
    options
  );

export const getIssueInvoice = (id) =>
  api.post(
    `issueListSingleEntryPage`,
    { ...body, menukey: "issue", TransactionId: id, FacilityId: FacilityId },
    options
  );

export const updateIssueVoucerPushInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `issuevoucerpushUpdate`,
      {
        ...body,
        menukey: "issue",
        ...updatedDispenser,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const saveIssueInv = (dispenserData) =>
  api
    .post(
      `issueSave`,
      {
        ...body,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "issue",
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const saveNonIssuedOrder = (id) =>
  api
    .post(
      `issuedNonReceiveOrderProducts`,
      {
        ...body,
        menukey: "issue",
        FacilityId: FacilityId,
        OrderId: id,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        UserId: UserId
      },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });

export const getNonIssuedReceivedOrderList = (params) =>
  api.post("issuedReceivedNonOrderList", { params }, options).then((res) => {
    return res.data.receiveinvdata;
  });

///Dispense Start
export const getDispenseInvs = (params) =>
  api.post("dispenseinvoicelist", { params }, options).then((res) => {
    return res.data.dispenseinvdata;
  });
export const saveDispenseInv = (dispensedData) =>
  api.post(`dispenseSave`, dispensedData, options).then((res) => res.data);

export const updateDispenseInv = ({ id, ...updatedDispenserd }) =>
  api
    .put(
      `dispenseUpdate`,
      { ...body, menukey: "dispense", ...updatedDispenserd },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getDispenseInv = (id) =>
  api.post(
    `dispenseListSingle`,
    { ...body, menukey: "dispense", TransactionId: id, FacilityId: FacilityId },
    options
  );
export const deleteDispenseInv = (id) =>
  api
    .post(
      `dispenseDelete`,
      {
        ...body,
        menukey: "dispense",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const updateDispenseStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "dispense",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 4,
        UserName: UserName,
        UserId: UserId,
      },
      options
    )
    .then((res) => {
      return res;
    });
///Dispense End

///Lab LMIS Report End

///Lab Quarter Colose End

/*==Data Extract Tool==*/
export const getDataBaseTableInfo = (params) =>
  api.post("my_dynamicsreports_server", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getColumnsList = (params) =>
  api
    .post(`my_dynamicsreports_server`, { ...body, ...params }, options)
    .then((res) => res.data.datalist);

export const getDataForCustomQueryTableColumns = (params) =>
  api
    .post(`my_dynamicsreports_server`, { ...body, ...params }, options)
    .then((res) => res.data);

export const getDataForCustomQueryTable = (params) =>
  api
    .post(`my_dynamicsreports_server`, { ...body, ...params }, options)
    .then((res) => res.data);

export const getSavedQueriesa = (params) =>
  api
    .post(`my_dynamicsreports_server`, { ...body, ...params }, options)
    .then((res) => res.data);

export const onSaveQuery = (params) =>
  api
    .post(`my_dynamicsreports_server`, { ...body, ...params }, options)
    .then((res) => res.data);

export const deleteSavedQueriesData = (id) =>
  api.post(`my_dynamicsreports_server`, id, options);

export const getQueryDataById = (params) =>
  api
    .post(`my_dynamicsreports_server`, { ...body, ...params }, options)
    .then((res) => res.data);

/*==Data Extract Tool==*/

//Receive Issue Invoice Pick Start

export const getReceivePickIssueInvoiceList = (params) =>
  api
    .post("receive_pick_issue_invoice_list_server", { params }, options)
    .then((res) => {
      return res.data.issueinvdata;
    });

export const saveReceivePickIssueInvoice = (id) =>
  api
    .post(
      `receive_pick_issue_invoice_save_server`,
      {
        ...body,
        menukey: "receive",
        FacilityId: FacilityId,
        TransactionId: id,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        UserId: UserId
      },
      options
    )
    .then((res) => {
      return res.data.receiveinvdata;
    });

//Receive Issue Invoice Pick End

export const getDashboardStockStatusTableData = (
  pYearId,
  pMonthId,
  pFacilityId = 0,
  gItemNo
) => {
  return api
    .post(
      "dashboardpage",
      {
        ...body,
        action: "getDashboardStockStatusTableData",
        menukey: "dashboard",
        FacilityId: pFacilityId,
        YearId: pYearId,
        MonthId: pMonthId,
        ItemNo: gItemNo,
      },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
};

export const getLabTestModalList = (id, ItemGroupId, TestToFacilityId) =>
  api
    .post(
      "LabTestModal",
      {
        ...body,
        action: "getLabTests",
        menukey:
          "entry-of-the-results-of-the-various-assessments-for-the-patients",
        FacilityId: id,
        ItemGroupId: ItemGroupId,
        TestToFacilityId: TestToFacilityId,
      },
      options
    )
    .then((res) => {
      return res.data.labtestlist;
    });

///Lab LMIS Report End

export const getPatientModalList = (id) =>
  api
    .post(
      "PatientModal",
      {
        ...body,
        action: "getPatients",
        menukey:
          "entry-of-the-results-of-the-various-assessments-for-the-patients",
        FacilityId: id,
      },
      options
    )
    .then((res) => {
      return res.data.patientlist;
    });

// export const getStockRecortdWithItemGroup = (params) =>
// api.post("facilityGroupMap", { params }, options).then((res) => {
//   return res.data.stockdatalist;
// });

// MOS Type for Facility Entry
//export const getMOSTypeForFacility = () =>  api.post('MOSTypeForFacility', {...body, "action": "getDataList", "menukey": "mos-type-for-facility-entry"}, options).then((res) =>{return res.data.datalist;})
export const getMOSTypeForFacility = (params) =>
  api.post("MOSTypeForFacility", { params }, options).then((res) => {
    return res.data.datalist;
  });
export const getMOSTypeForFacilitySingle = (id) =>
  api.post(
    `MOSTypeForFacility`,
    {
      ...body,
      action: "getMOSTypeForFacilitySingle",
      menukey: "mos-type-for-facility-entry",
      MosTypeId: id,
    },
    options
  );
export const updateMOSTypeForFacility = ({ id, ...updatedMOSRange }) =>
  api
    .put(
      `MOSTypeForFacility`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "mos-type-for-facility-entry",
        MosTypeId: id,
        ...updatedMOSRange,
      },
      options
    )
    .then((res) => {
      return res;
    });
export const saveMOSTypeForFacility = (mosrangeData) =>
  api
    .post(
      `MOSTypeForFacility`,
      {
        ...body,
        action: "dataInsert",
        menukey: "mos-type-for-facility-entry",
        ...mosrangeData,
      },
      options
    )
    .then((res) => res.data);
// export const deleteMOSTypeForFacility = (id) =>
//   api.post(`MOSTypeForFacility`, id, options);

export const deleteMOSTypeForFacility = (id) =>
api
  .post(
    `MOSTypeForFacility`,
    {
      ...body,
      action: "dataDalete",
      menukey: "mos-type-for-facility-entry",
      MosTypeId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);
//Lab Products Nearing Expiry Report
export const getLabProductsNearingExpiryReportTable = (params) => {
  return api
    .post("LabProductsNearingExpiryReport", { params }, options)
    .then((res) => {
      return res.data.datalist;
    });
};

export const getSupplierList = () =>
  api
    .post(
      "supplier",
      { ...body, action: "getSupplierList", menukey: "supplier" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getSupplier = (id) =>
  api.post(
    `supplier`,
    { ...body, action: "getSupplier", menukey: "supplier", SupplierId: id },
    options
  );
export const saveSupplier = (getSupplierData) => {
  return api
    .post(
      `supplier`,
      {
        ...body,
        action: "dataInsert",
        menukey: "supplier",
        ...getSupplierData,
        FacilityId: FacilityId,
        UserName: UserName,
      },
      options
    )
    .then((res) => res.data);
};

export const deleteSupplier = (id) => api.post(`supplier`, id, options);

export const updateSupplier = ({ id, ...updateSupplier }) => {
  return api
    .put(
      `supplier`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "supplier",
        SupplierId: id,
        ...updateSupplier,
        UserName: UserName,
      },
      options
    )
    .then((res) => {
      return res;
    });
};

// Facility Type
export const getFacilityTypeList = () =>
  api
    .post(
      "facilityType",
      { ...body, action: "getFacilityTypeList", menukey: "facility-type" },
      options
    )
    .then((res) => {
      return res.data.datalist;
    });
export const getFacilityTypeSingle = (id) =>
  api.post(
    `facilityType`,
    {
      ...body,
      action: "getFacilityTypeSingle",
      menukey: "facility-type",
      FTypeId: id,
    },
    options
  );
export const saveFacilityType = (getFpType) =>
  api
    .post(
      `facilityType`,
      { ...body, action: "dataInsert", menukey: "facility", ...getFpType },
      options
    )
    .then((res) => res.data);

export const deleteFacilityType = (id) => api.post(`facilityType`, id, options);

export const updateFacilityType = ({ id, ...updateFtype }) =>
  api
    .put(
      `facilityType`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "facility-type",
        FTypeId: id,
        ...updateFtype,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Receive Details Report
export const GeReceivedetailsreport = (params) =>
  api.post("ReceiveDetailsReport", { params }, options).then((res) => {
    return res.data.datalist;
  });

  //Age group distribution

  export const AgeGroupDistributionpie = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});

export const AgeGroupDistribution = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});
export const AEFIList = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});
export const PatientByGenderChart = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});

export const PatientByGenderTable = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});

export const SeverityPatientChart = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});
export const SeverityPatientTable = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});

export const StoppingTreatmentChart = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});
export const StoppingTreatmentTable = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});
export const ReadministrationChart = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});
export const ReadministrationTable = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});

export const AefiReportingMap = (params) =>
api.post("AefiDashboard", { params }, options).then((res) => {
  return res.data.datalist;
});

export const StockStatusDifferentChart = (params) =>
api.post("eLMISSummaryReports", { params }, options).then((res) => {
  return res;
});
export const StockStatusDifferentTable = (params) =>
api.post("eLMISSummaryReports", { params }, options).then((res) => {
  return res;
});

export const PercentageoffacilitymosscaleChart = (params) =>
api.post("eLMISSummaryReports", { params }, options).then((res) => {
  return res;
});
export const PercentageoffacilitymosscaleTable = (params) =>
api.post("eLMISSummaryReports", { params }, options).then((res) => {
  return res;
});

export const PercentageoffacilityStockOutChart = (params) =>
api.post("eLMISSummaryReports", { params }, options).then((res) => {
  return res;
});
export const PercentageoffacilityStockOutTable = (params) =>
api.post("eLMISSummaryReports", { params }, options).then((res) => {
  return res;
});


// facility list
export const getFacility = (getfacilitydata) => {
  //console.log("dddd",zoneData);
  return api
    .post("comboscript", { ...getfacilitydata }, options)
    .then((res) => {
      return res;
    });
};
// product Group list
export const getProductListData = (getProductGroupData) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...getProductGroupData }, options).then((res) => {
    return res;
  });
};

//Products Nearing Expiry Report
export const getCommoditiesStockStatusTable = (params) => {
  return api.post("CommoditiesStockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });
};

export const getProductExpiredapi = (params) => {
  return api.post("CommoditiesStockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });
};

// SUPPLIER FORM
export const getSupplierFormList = (params) =>
  api.post("supplierForm", { params }, options).then((res) => {
    return res.data.datalist;
  });

export const getSupplierForm = (id) =>
  api.post(
    `supplierForm`,
    {
      ...body,
      action: "getSupplier",
      menukey: "supplier-form",
      SupplierId: id,
    },
    options
  );
export const saveSupplierForm = (getSupplierData) =>
  api
    .post(
      `supplierForm`,
      {
        ...body,
        action: "dataInsert",
        menukey: "supplier-form",
        UserName: UserName,
        ...getSupplierData,
      },
      options
    )
    .then((res) => res.data);
// export const deleteSupplierForm = (id) => api.post(`supplierForm`, id, options);
export const deleteSupplierForm = (id) =>
api
  .post(
    `supplierForm`,
    {
      ...body,
      action: "dataDalete",
      menukey: "supplier-form",
      SupplierId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);

export const updateSupplierForm = ({ id, ...updateSupplier }) =>
  api
    .put(
      `supplierForm`,
      {
        ...body,
        action: "dataUpdate",
        menukey: "supplier-form",
        SupplierId: id,
        UserName: UserName,
        ...updateSupplier,
      },
      options
    )
    .then((res) => {
      return res;
    });

//Receive From Supplier
export const getReceiveFromSupplier = (params) =>
  api.post("ReceiveFromSupplierList", { params }, options).then((res) => {
    return res.data.datalist;
  });

/*
export const saveReceiveFromSupplierInv = (dispenserData) =>
api
  .post(`ReceiveFromSupplierSave`, dispenserData, options)
  .then((res) => res.data);

  */

export const saveReceiveFromSupplierInv = (dispenserData) =>
  api
    .post(
      `ReceiveFromSupplierSave`,
      {
        ...body,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "receive-from-supplier",
        ...dispenserData,
      },
      options
    )
    .then((res) => res.data);

export const deleteReceiveFromSupplierInv = (id) =>
  api
    .post(
      `ReceiveFromSupplierDelete`,
      {
        ...body,
        menukey: "receive-from-supplier",
        TransactionId: id,
        FacilityId: FacilityId,
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const getReceiveFromSupplierInv = (id) =>
  api.post(
    `ReceiveFromSupplierListSingle`,
    {
      ...body,
      menukey: "receive-from-supplier",
      TransactionId: id,
      FacilityId: FacilityId,
    },
    options
  );

export const updateReceiveFromSupplierInv = ({ id, ...updatedDispenser }) =>
  api
    .put(
      `ReceiveFromSupplierUpdate`,
      {
        ...body,
        menukey: "receive-from-supplier",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        ...updatedDispenser,
      },
      options
    )
    .then((res) => {
      return res.data;
    });

export const updateReceiveFromSupplierStock = (id) =>
  api
    .post(
      `POSTStockForm`,
      {
        ...body,
        menukey: "receive-from-supplier",
        FacilityId: FacilityId,
        TransactionId: id,
        TransactionTypeId: 0,
        UserName: UserName,
        UserId: UserId,
      },
      options
    )
    .then((res) => {
      return res;
    });
export const saveSupplierForReceive = (params) =>
  api
    .post(
      `NationalSupplier`,
      {
        ...body,
        action: "dataInsertSupplier",
        menukey: "receive-from-supplier",
        FacilityId: FacilityId,
        UserName: UserName,
        ...params,
      },
      options
    )
    .then((res) => res.data);
//End Of Receive From Supplier

// export const GetAdjustmentReport = (params) =>
// api.post("AdjustmentsummaryReport", { params }, options).then((res) => {
//   return res.data.datalist;
// });

// export const GetAdjustmentDetailsReport = (params) =>
// api.post("EsiglProductStockReport", { params }, options).then((res) => {
//   return res.data.datalist;
// });

// export const GetIssueDetailsReport1 = (params) =>
// api.post("MasterProductList", { params }, options).then((res) => {
//   return res.data.datalist;
// });

// export const ProductGroup = (params) =>
// api.post("MasterProductList", { params }, options).then((res) => {
//   return res.data.datalist;
// });

export const StoreListForReport = (storedata) => {
  //console.log("dddd",zoneData);
  return api.post("comboscript", { ...storedata }, options).then((res) => {
    return res;
  });
};

export const getIssueDetailsReport = (params) => {
  return api.post("CommoditiesStockStatus", { params }, options).then((res) => {
    return res.data.datalist;
  });
};



export const getfundingsource = (params) =>
  api.post("FundingSourceEntryForm", { params }, options).then((res) => {
    //console.log(res.data.datalist);
    return res.data.datalist;
  });


  
export const getFundingData = (id) =>
  api.post(
    `FundingSourceEntryForm`,
    {
      ...body,
      action: "getFundingSingle",
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
      menukey: "funding-source-entry",
      FundingSourceId: id,
    },
    options
  );

export const saveFundingSource = (FundingData) =>
  api
    .post(
      `FundingSourceEntryForm`,
      {
        ...body,
        action: "dataInsert",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "funding-source-entry",
        ...FundingData,
      },
      options
    )
    .then((res) => res.data);

export const updateFundingSource = ({ id, ...updatedFunding }) =>
  api
    .put(
      `FundingSourceEntryForm`,
      {
        ...body,
        action: "dataUpdate",
        UserName: UserName,
        lan: localStorage.getItem("LangCode"),
        menukey: "funding-source-entry",
        FundingSourceId: id,
        ...updatedFunding,
      },
      options
    )
    .then((res) => {
      return res;
    });


export const deletefunding = (id) =>
api
  .post(
    `FundingSourceEntryForm`,
    {
      ...body,
      action: "dataDalete",
      menukey: "funding-source-entry",
      FundingSourceId: id,
      UserName: UserName,
      lan: localStorage.getItem("LangCode"),
    },
    options
  )
  .then((res) => res);


  //Profile parameter Insert

    export const getProfileParameters = (params) =>
      api.post("profileParameters", { params }, options).then((res) => {
        //console.log(res.data.datalist);
        return res.data.datalist;
      });

      export const getprofilelist = (id) =>
        api.post(
          `profileParameters`,
          { ...body, action: "getProfilesingle", menukey: "ProfilePara", id: id },
          options
        );

        export const saveProfileParamiter = (userProfile) =>
          api
            .post(
              `profileParameters`,
              { ...body, action: "dataInsert", menukey: "profile-parameters", ...userProfile },
              options
            )
            .then((res) => res.data);

  export const updateProfileParamiter = ({ id, ...updateProfile }) =>
              api
                .put(
                  `profileParameters`,
                  {
                    ...body,
                    action: "dataUpdate",
                    menukey: "profile_parameters",
                    ParamId: id,
                    ...updateProfile,
                  },
                  options
                )
                .then((res) => {
                  return res;
                });
  export const deleteProfile = (id) =>
        api
          .post(
            `profileParameters`,
            {
              ...body,
              action: "dataDalete",
              menukey: "profile-parameters",
              id: id,
              UserName: UserName,
              lan: localStorage.getItem("LangCode"),
            },
            options
          )
          .then((res) => res);           

  export const getProfileShortOrder = (params) =>
    api.post("profileParameters", { params }, options).then((res) => {
      return res.data.datalist;
    });

  export const updateProfileShortOrder = (params) =>
      //console.log(params.id);
      api
        .put(
          `profileParameters`,
          {
            ...body,
            action: "dataProfileOrderUpdate",
            UserName: UserName,
            lan: localStorage.getItem("LangCode"),
            menukey: "profile-parameters",
            MenuData: params.MenuData,
          },
          options
        )
        .then((res) => {
          return res;
        });
   
        


        export const getfundingreqsource = (params) =>
          api.post("FundingRequirementsSource", { params }, options).then((res) => {
            //console.log(res.data.datalist);
            return res.data.datalist;
          });
        
        
          
        export const getFundingreqData = (id) =>
          api.post(
            `FundingRequirementsSource`,
            {
              ...body,
              action: "getFundingSingle",
              UserName: UserName,
              lan: localStorage.getItem("LangCode"),
              menukey: "funding-source-entry",
              FundingReqSourceId: id,
            },
            options
          );
        
        export const saveFundingreqSource = (FundingData) =>
          api
            .post(
              `FundingRequirementsSource`,
              {
                ...body,
                action: "dataInsert",
                UserName: UserName,
                lan: localStorage.getItem("LangCode"),
                menukey: "funding-source-entry",
                ...FundingData,
              },
              options
            )
            .then((res) => res.data);
        
        export const updateFundingreqSource = ({ id, ...updatedFunding }) =>
          api
            .put(
              `FundingRequirementsSource`,
              {
                ...body,
                action: "dataUpdate",
                UserName: UserName,
                lan: localStorage.getItem("LangCode"),
                menukey: "funding-source-entry",
                FundingReqSourceId: id,
                ...updatedFunding,
              },
              options
            )
            .then((res) => {
              return res;
            });
        
        
        export const deletereqfunding = (id) =>
        api
          .post(
            `FundingRequirementsSource`,
            {
              ...body,
              action: "dataDalete",
              menukey: "funding-source-entry",
              FundingReqSourceId: id,
              UserName: UserName,
              lan: localStorage.getItem("LangCode"),
            },
            options
          )
          .then((res) => res);
        
//ServiceArea
export const getServiceArea = () => api.post("Service", { ...body, action: "getDataList", menukey: "Service" }, options).then((res) => {
  return res.data.datalist;
});
export const saveServiceArea = (ServiceData) => api.post(`Service`, { ...body, action: "dataInsert", menukey: "Service", ...ServiceData }, options)
  .then((res) => res.data);
export const updateServiceArea = ({ id, ...updategetService }) => api.put(`Service`, { ...body, "action": "dataUpdate", "menukey": "Service", "ServiceAreaId": id, ...updategetService }, options).then((res) => {
  return res;
})
export const getServiceAreaData = (id) => api.post(`Service`, { ...body, action: "getServiceAreaData", menukey: "Service", ServiceAreaId: id, }, options);
export const deleteServiceArea = (id) => api.post(`Service`, id, options)
