import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControl,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import * as Service from "../../../services/Service.js";
import Autocomplete from "@material-ui/lab/Autocomplete";

const RecipientFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "recipient";

  const FacilityId = JSON.parse(localStorage.getItem("FacilityId"));
  const Facilitylist = JSON.parse(localStorage.getItem("FacilityList"));
  //let All_Item_label = {id: "0",name: DispensingLanguage[lan][menukey]["All"]};

  const IssueToFaList = JSON.parse(
    localStorage.getItem("IssuedToGroupItemList")
  );
  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    FacilityId: { id: FacilityId, name: Facilitylist[Facilitylist.findIndex((Facilitylist) => Facilitylist.id == FacilityId)].name },
    IssuedToGroupId: { id: "", name: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };

    chosenValuesData["IssuedToGroupId"] =
      IssueToFaList[
      IssueToFaList.findIndex(
        (IssueToFa_list) => IssueToFa_list.id == formData.IssuedToGroupId
      )
      ];

    chosenValuesData["FacilityId"] =
      Facilitylist[
      Facilitylist.findIndex(
        (Facilitylist) => Facilitylist.id == formData.FacilityId
      )
      ];
    setChosenValues(chosenValuesData);
  }, [formData.FacilityId]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========

  const classes = useStyles();

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">
                {
                  DispensingLanguage[lan][menukey][
                  "Issued To Entry Form Add/Edit"
                  ]
                }
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["Issued To Entry Form"]}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                          disabled={true}
                            autoHighlight
                            //disableClearable
                            //disabled={props.permissionType}
                            id="FacilityId"
                            options={Facilitylist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "FacilityId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["FacilityId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.FacilityId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                  "Facility Name"
                                  ]
                                }
                                variant="standard"
                                error={errorObject.FacilityId}
                                helperText={errorObject.FacilityId}
                                id="FacilityId"
                                name="FacilityId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="IssuedToGroupId"
                            options={IssueToFaList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "IssuedToGroupId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["IssuedToGroupId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.IssuedToGroupId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                  "Issued To Group"
                                  ]
                                }
                                variant="standard"
                                error={errorObject.IssuedToGroupId}
                                helperText={errorObject.IssuedToGroupId}
                                id="IssuedToGroupId"
                                name="IssuedToGroupId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          error={errorObject.IssuedToName}
                          helperText={errorObject.IssuedToName}
                          required
                          id="IssuedToName"
                          name="IssuedToName"
                          label={
                            DispensingLanguage[lan][menukey]["Issued To Name"]
                          }
                          value={formData.IssuedToName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 250 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}>
                {DispensingLanguage[lan][menukey]["Save"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/recipient")}>
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}>
                {DispensingLanguage[lan][menukey]["update"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/recipient")}>
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default RecipientFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
