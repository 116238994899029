import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";


//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

const Menuentry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "menu-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo === 0 ? "" : UserInfo[0].name;

  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  const { data, refetch } = useQuery("menuentry", api.getmenuEntry, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });
  const { mutate: deleteApi } = useMutation(api.deletemenu, {
    onSuccess: (data) => {
      if (data.data.status === 200) {
        api.getDispensingLanguage().then((response) => {
          localStorage.setItem(
            "DispensingLanguage",
            JSON.stringify(response.datalist)
          );
        });

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        refetch();
        queryClient.getQueriesData("menuentry");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  /* =====Start of Excel Export Code==== */
  // const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  // const PrintPDFExcelExportFunction = (reportType) => {
  //   let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  //   window.open(
  //     finalUrl +
  //       "?action=PacksizeExport" +
  //       "&reportType=" +
  //       reportType +
  //       "&menukey=" +
  //       menukey +
  //       "&lan=" +
  //       localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
  //   );
  // };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editmenu(rowData);
  };

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 80,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Menu Name"],
      headerFilter: true,
      field: "menuname",
    },
    {
      title: DispensingLanguage[lan][menukey]["Menu Url"],
      headerFilter: true,
      field: "url",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Menu Position"],
      headerFilter: true,
      field: "position",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Menu MenuLevel"],
      headerFilter: true,
      field: "MenuLevel",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 133,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editmenu = (data) => {
    let route = `menu-entry/edit/${data.id}`;
    props.history.push(route);
  };

  // const deletemenu = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //         "Once deleted, you will not be able to recover this Record!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: [
  //       t(DispensingLanguage[lan][menukey]["No"]),
  //       t(DispensingLanguage[lan][menukey]["Yes"]),
  //     ],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       deleteApi({
  //         id: data.id,
  //         menukey: menukey,
  //         lan: lan,
  //         UserName: UserName,
  //         action: "dataDalete",
  //       });
  //     }
  //   });
  // };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editmenu(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            modalPopupDelete(deleteApi, rowData.id);
           // deletemenu(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-packSize">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Menu Entry Form"]}
              </div>
              <div className="float-right sw_btn_control">
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/menu-entry/shortorder");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Change Order"]}
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/menu-entry/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add Menu"]}
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={600}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Menuentry;

const useStyles = makeStyles({
  packSizePageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
