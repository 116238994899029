import React, { useState, useEffect } from "react"; //useState,
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  DialogTitle,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { makeStyles } from "@material-ui/core/styles";

//Import Preloader
import LoaderForGlobal from "services/LoaderForGlobal";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const FilterModal = ({
  handleClose,
  openFilterModal,
  executeFnSqlCommand,
  handleChangeSetParams,
  customParamsForControlShowHide,
  isLoading,
  ...props
}) => {
  const classes = useStyles();
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  const lan = localStorage.getItem("LangCode");
  const menukey = "data-extract-tool";

  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));

  // function executesql(){
  //   executeFnSqlCommand();
  // }

  // console.log(
  //   "customParamsForControlShowHide list: ",
  //   customParamsForControlShowHide
  // );

  // console.log(
  //   "customParamsForControlShowHide keys",
  //   Object.keys(customParamsForControlShowHide)
  // );
  
  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose("FilterModalClose")}
        aria-labelledby="customized-dialog-title"
        open={openFilterModal}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("FilterModalClose")}
        >
          {DispensingLanguage[lan][menukey]["Report parameter(s)"]}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            {customParamsForControlShowHide.hasOwnProperty("year") && (
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="YearList-label">{DispensingLanguage[lan][menukey]["Year"]}</InputLabel>
                  <Select
                    labelId="YearList-label"
                    id="year"
                    name="year"
                    // value={currYearId}
                    onChange={handleChangeSetParams}
                    fullWidth
                  >
                    {YearList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {customParamsForControlShowHide.hasOwnProperty("monthid") && (
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="MonthList-label">{DispensingLanguage[lan][menukey]["Month"]}</InputLabel>
                  <Select
                    labelId="MonthList-label"
                    id="monthid"
                    name="monthid"
                    // value={currMonthId}
                    onChange={handleChangeSetParams}
                    fullWidth
                  >
                    {MonthList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {Object.keys(customParamsForControlShowHide).map((item, index) => {

              if(item === "year" || item === "monthid"){
                /* when year or monthid then show drop-down above*/
                return <></>;
              }else{
                return (

                  <Grid item xs={12} sm={12}>
                    <TextField
                      id={item}
                      name={item}
                      label={item.charAt(0).toUpperCase() + item.slice(1)}
                      fullWidth
                      onChange={handleChangeSetParams}
                      autoComplete="family-name"
                    />
                  </Grid>
                );
  
              }
             



              
            })}

            {/* <Grid item xs={12} sm={12}>
              <TextField
                id="name"
                name="name"
                label="Text 1"
                fullWidth
                autoComplete="family-name"
                // onChange={(e) => handleChange(e)}
              />
            </Grid> */}
            {/* 
            <Grid item xs={12} sm={12}>
              <TextField
                id="name"
                name="name"
                label="Text 2"
                fullWidth
                autoComplete="family-name"
                // onChange={(e) => handleChange(e)}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
        <div className="filter_loader sw_relative">
          {isLoading && <LoaderForGlobal />}
          </div>
          <Button
            disabled={isLoading}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={executeFnSqlCommand}
          >
            {DispensingLanguage[lan][menukey]["Run Query"]}
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose("FilterModalClose")}
          >
            {DispensingLanguage[lan][menukey]["Cancel"]}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default FilterModal;

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },

  "tabulator-cell": {
    padding: "1px",
  },
});
