import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Card,
  CardContent,
  Typography,
  
} from "@material-ui/core";

import { Add,DeleteOutline } from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { cellFocusEditor,generateBatchId } from "../../../services/Common";
import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import "../common.css";

import swal from "sweetalert";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMutation } from "react-query";
import * as api from "../../../actions/api";
import SupplierModal from "./SupplierModal";
import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";
import ProductsModal from "../../../services/ProductsModal";


let isDirty=false;
let masterDirty=false;

const OrderToSupplierFormData = (props) => {
  //get DispensingLanguage
  const { t, i18n } = useTranslation();
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "order-to-supplier";
  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  let tblRef = null;
  const classes = useStyles();

  const [manyTableData, setManyTableData] = useState([]);
  const [bCompleted, setbCompleted] = useState(false);
  const OrderType = JSON.parse(localStorage.getItem("OrderType"));
  let OrderTo = JSON.parse(localStorage.getItem("OrderTo"));
  const [open, setOpen] = useState(false);
  const [deletedDataSet, setDeletedDataSet] = useState([]);
  // const OrderTo = OrderToList.hasOwnProperty(props.formData.ItemGroupId)?OrderToList[props.formData.ItemGroupId]:[];
  const UsersListByFacility = JSON.parse(
    localStorage.getItem("UsersListByFacility")
  );

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const roleObj = UserInfo == 0 ? "" : UserInfo[0].role;
  const UserRoleId = UserInfo == 0 ? 0 : roleObj[0].id;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo === 0 ? "" : UserInfo[0].name;

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroupList = [All_Product].concat(Product_Group);

  const [activeProductGroupData, setAcProductGroupData] = useState({
    id: ProductGroupList.length > 0 ? ProductGroupList[0].id : "",
    name: ProductGroupList.length > 0 ? ProductGroupList[0].name : "",
  });

  const [SupplierOpen, setSupplierOpen] = useState(false);
  const [supplierCode, setSupplierCode] = useState("");
  let SupplierListByFacility = JSON.parse(localStorage.getItem("SupplierListByFacility"));

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({ menukey });
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({menukey});

  const handleProductGroupChange = (event, newValue) => {
    setAcProductGroupData(newValue);
    if (props.addProductForm == false) {
      //props.filterFandleChange(newValue.id);
    }
  };

  const [bDisableAddProduct, setDisableAddProduct] = useState(
    props.DisableAddBtn
  );

  // Add Item

  const addProduct = (NonOrderProductData) => {

    /*
    let rows = [];
    let autoId = 0;

    
    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.BatchId = row.BatchId;
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.dirty = row.dirty;

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.OrderItemId = row.OrderItemId;
      newRow.SKU = autoId;
      newRow.id = autoId;

      newRow.ActualOrderQty = row.ActualOrderQty;
      newRow.Remarks = row.Remarks;
      
      

      rows.push(newRow);
    });

    let temNonordata = NonOrderProductData;
    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.dirty = 1;

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      

      newRow.OrderItemId = -1;

      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.ActualOrderQty = null;
      newRow.Remarks = null;
      

      rows.push(newRow);

      
    });


    isDirty = true;
    onSetDisable();

    

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");

    */

    let rowsNotInGrid = [];

      if (manyTableData.length > 0) {
        rowsNotInGrid = NonOrderProductData.filter((obj1) => {
          return !manyTableData.some(
            (obj2) =>
              obj1.ItemNo == obj2.ItemNo &&           
              obj1.ItemCode == obj2.ItemCode
          );
        });
      } else {
        rowsNotInGrid = NonOrderProductData;
      }


      let temNonordata = rowsNotInGrid;
    let rows = [];

    let autoId = 0;
    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.dirty = 1;
      newRow.ItemCode = row.ItemCode;
      
      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
    
      newRow.OrderItemId = null;
      

      manyTableData.forEach((rowssss, ij) => {
        if (rowssss.ItemNo == row.ItemNo) {
          newRow.ActualOrderQty = rowssss.ActualOrderQty;
         
         
          return;
        }
      });
      
      
      newRow.ActualOrderQty = null;
      newRow.Remarks = null;
      
      newRow.OrderItemId = -1;
      newRow.SKU = autoId;
      newRow.id = autoId;
      
      rows.push(newRow);


    });

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.dirty = row.dirty;
      newRow.ItemCode = row.ItemCode;
      
      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
    
      newRow.ActualOrderQty = row.ActualOrderQty;
      newRow.OrderItemId = row.OrderItemId;
      newRow.Remarks = row.Remarks;
      newRow.SKU = autoId;
      newRow.id = autoId;
    
      rows.push(newRow);
    });

    isDirty=true;
    onSetDisable();

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
      
      /*
      isDirty=true;
      onSetDisable();
      let arrObjOne = rowsNotInGrid.concat(manyTableData);

      setManyTableData(arrObjOne);
      props.handleAdd(arrObjOne);
      handleClose("Products");

      */
    
  };

  // End Add Item

  /* ====Start of Print Excel Many Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_order_supplier_entry_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&OrderId=" +
        props.formData.OrderId +
        "&OnlyOrderedItems=" +
        OnlyOrderedItems +
        "&ItemGroupId=" +
        activeProductGroupData.id +
        "&Facilityid=" +
        props.formData.FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_order_supplier_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&OrderId=" +
        props.formData.OrderId +
        "&OnlyOrderedItems=" +
        OnlyOrderedItems +
        "&ItemGroupId=" +
        activeProductGroupData.id +
        "&Facilityid=" +
        props.formData.FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */


  const handleClose = (modalname) => {
    switch (modalname) {

      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      case "SupplierModal":
        setSupplierOpen(false);
        break;
      case "SupplierOpen":
        setSupplierOpen(true);
        break;

      default:
        break;
    }
  };

  const manycolumns = [
    { title: "Id", field: "id", visible: false },
    { title: "OrderItemId", field: "OrderItemId", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      hozAlign: "center",
      formatter: "rownum",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      visible: true,
      hozAlign: "center",
      width: 70,
      formatter: reactFormatter(<ActionButton />),
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
    },
    /*
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Min Qty"],
      field: "MinQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Max Qty"],
      field: "MaxQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock on Hand"],
      field: "StockOnHand",
      width: 110,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Calculated Order Quantity"],
      field: "CalcOrderQty",
      width: 165,
      hozAlign: "right",
      headerHozAlign: "right",
    },

    */

    {
      title: DispensingLanguage[lan][menukey]["Actual Order Quantity"],
      field: "ActualOrderQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 220,
      editor: bCompleted == -1 ? cellFocusEditor : "",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }

      },
      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue(); /* Actual Order Quantity */
        // if(!cellValue) return;
        const rowData = cell.getRow().getData();
        const CalcOrderQty = rowData.CalcOrderQty;
        const Remarks = rowData.Remarks;

       
        

        // if (CalcOrderQty !== cellValue && (Remarks == null || Remarks == "")) {
        //   cell.getRow().getElement().style.backgroundColor = "#ec7063";
        // } else {
        //   cell.getRow().getElement().style.backgroundColor = "transparent";
        // }

        return cellValue;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Comment"],
      field: "Remarks",
      width: 250,
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      // editor: bCompleted == -1? true : '',
      editor: bCompleted == -1 ? cellFocusEditor : "",

      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue();
        if (!cellValue) return;
        const rowData = cell.getRow().getData();
        

       

        
        return cellValue;
      },cellEdited: function (cell) {

        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        //   props.setUpdatedDetailsGridDataHandler(rowdata);
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }

      },

    },
    
    
  ];

  
  
  const onSetDisable = () => {  
    if(isDirty==true){   
      //document.getElementById("is_dirty_message").classList.remove("dnone"); 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }
    else{  
     //document.getElementById("is_dirty_message").classList.add("dnone"); 
     document.getElementById("master_dirty_message").classList.add("dnone");
    }    
  }


  const onMasterSetDirty = () => {   
    if(masterDirty==true){ 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  }

  /* Start Save Post In Same Page */

  const onPostBtnClick = () => {

    if (tblRef == null) return;
    handlePost(); 

  }


  
  

  const handlePost = async (e) => {

    /*
    let manydata = tblRef.table.getData();

    let ValidationCount = onDataValidation(manydata);
    if (ValidationCount > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Please, mention reason of actual order quantity change"],
        msgtype: 0,
        duration: 5000,
      });
      return;
    }

    let manydataP=[]; 
    tblRef.table.getData().forEach((row,i)=>{
      if(row.dirty==1)
      manydataP.push(row);
    })

    let validForm = props.checkValidateForm(manydataP, "post");

    if (validForm) {

      let FinalData={...validForm,"IsCompleted":0,menukey: menukey,lan: lan, "UserName":UserName,"masterDirty":masterDirty};
      modalPopupDelete(mutateUpdate, FinalData, { PostQ: "OrderPost" }, ModalNo, validForm);

    }

    */


    const isDuplicate = isContainDuplicateForExistingData(tblRef.table.getData());
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate product available"], //+ " - " + isDuplicate.data,
        msgtype: 0,
      });


    } else {


    let manydataP=[]; 
    isDuplicate.data.forEach((row,i)=>{
      if(row.dirty==1)
      manydataP.push(row);
    
    })

    let validForm = props.checkValidateForm(manydataP, 'post');
    
    if (validForm) {

      let FinalData={...validForm,"IsCompleted":0,menukey: menukey,lan: lan, "UserName":UserName,"masterDirty":masterDirty};
      modalPopupDelete(mutateUpdate, FinalData, { PostQ: "OrderPost" }, ModalNo, validForm);

      }

   }

  };

  const ModalNo = (validForm) => {

    props.stockPosted(-1);
    let route = `${validForm.OrderId}`;
    props.history.push(route);

  }

  
  const isContainDuplicateForExistingData = (dataArray) => {

    
    const removedDuplicate = new Set(dataArray.map((el) => el.ItemNo));

    if (dataArray.length !== removedDuplicate.size) {
      return { data: '', status: true };
    } else {
      setManyTableData(dataArray);
      return { data: dataArray, status: false };
    };


  };


  const onSaveUpdateBtnClick = () => {

    /*

    let manydata = tblRef.table.getData();

    let ValidationCount = onDataValidation(manydata);
    if (ValidationCount > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Please, mention reason of actual order quantity change"],
        msgtype: 0,
        duration: 5000,
      });
      return;
    }

    setUpdatedDetailsGridDataHandler(manydata);

    */

    let manydata = tblRef.table.getData();
    const isDuplicate = isContainDuplicateForExistingData(manydata);
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate product available"], //+" - "+isDuplicate.data,
        msgtype: 0,
      });
    } else {
      setUpdatedDetailsGridDataHandler(isDuplicate.data);
    }


  }


  

  const setUpdatedDetailsGridDataHandler = (rows) => {

    let manydataR=[]; 
    rows.forEach((row,i)=>{
      if(row.dirty==1)
      manydataR.push(row);
    })

    let validForm = props.checkValidateForm(manydataR,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutate({...validForm,'masterDirty':masterDirty});
        }else{
          //when update
          mutateUpdate({...validForm,'masterDirty':masterDirty}); 
        }
        

        
      }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveOrderToSupplierInv, {
    onSuccess: (data) => {
      if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `edit/${data.OrderId}`;
        props.history.push(route);

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(api.updateOrderToSupplierInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) {
        
        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;

        props.successSave(data.IsCompleted);
        if(data.IsCompleted==0){
         mutatePost({OrderId:props.formData.OrderId , FacilityId:props.formData.FacilityId, lan:lan, UserName:UserName});
        }
        else{
          props.successSave(data.IsCompleted); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.orderToSupplierPostInv, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

        if(data.data.success==0){ 
          
          props.stockPosted(-1);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         // swal(data.data.message, "", "warning"); 
         
         }else{
          props.stockPosted(0);
          props.refetch();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          //swal(data.data.message, "", "success");
          
  
         } 

      }else{
        props.stockPosted(-1);
        props.refetch();
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })

  /* End Save Post In Same Page*/


  const backToList = () => {

    if((masterDirty==true)||(isDirty==true)){
      
      modalPopupBacktoList(backToListCallBack, "/order-to-supplier", {PostQ:"modalBackToListQ"}); 
  
    }else props.history.push("/order-to-supplier")
    
  };

  const backToListCallBack = (PageUrl) => {
      masterDirty=false;
      isDirty=false;
      props.history.push(PageUrl);
  }




  const deleteReceiveInv = (data) => {

    let newarray = manyTableData.filter(element => element !== data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
    isDirty = true;
    onSetDisable();

  };

  function ActionButton(propss) {
    

    const rowData = propss.cell._cell.row.data;

   
    if (
      props.bCompleted == 1 
    )
      return <></>;
    else {
      
        return (
          <>
            <DeleteOutline
              onClick={() => {
                deleteReceiveInv(rowData);
              }}
            />
          </>
        );
    }
  }


  const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

     masterDirty=true;
     onMasterSetDirty();

   };


  useEffect(() => {
    if (tblRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }
    setbCompleted(props.bCompleted);
  }, [props.ManyTableData, props.bCompleted]);

  useEffect(() => {
    if (props.addProductForm) {
      //if (props.formData.OrderBy == "") {
       // props.formData.OrderBy = UserId;
      //}
    }
  }, [UserId, props.addProductForm]);

  
  const [chosenValues, setChosenValues] = useState({
    "SupplierId": { "SupplierId": "", "SupplierName": "" },
    //"ManufacturerId": { "ManufacturerId": "", "ManufacturerName": "" },
    "OrderBy": { "id": "", "name": "" },
    "ApprovedBy": { "id": "", "name": "" }
  });

  useEffect(() => {

    let chosenValuesData = { ...chosenValues };

    chosenValuesData['SupplierId'] = SupplierListByFacility[SupplierListByFacility.findIndex(Supplier_List => Supplier_List.SupplierId == props.formData.SupplierId)];

    chosenValuesData['OrderBy'] = UsersListByFacility[UsersListByFacility.findIndex(Users_ListByFacility => Users_ListByFacility.id == props.formData.OrderBy)];

    chosenValuesData['ApprovedBy'] = UsersListByFacility[UsersListByFacility.findIndex(Users_ListByFacility_A => Users_ListByFacility_A.id == props.formData.ApprovedBy)];

    setChosenValues(chosenValuesData);
  }, [props.formData.SupplierId, props.formData.OrderBy, props.formData.ApprovedBy]);


  useEffect(() => {
    let chosenValuesData = { ...chosenValues };

    chosenValuesData['SupplierId'] = SupplierListByFacility[SupplierListByFacility.findIndex(Supplier_List => Supplier_List.SupplierId == supplierCode)];
    setChosenValues(chosenValuesData);

    props.formData["SupplierId"] = supplierCode;

  }, [supplierCode]);

  const addSupplier = (SupplierData) => {
    if (SupplierData != "") {

      handleClose("SupplierModal");
      setTimeout(() => setSupplierCode(SupplierData.SupplierId), 500);
    }
  };

  const handleChangeChoosen = (name, valueobj, value) => {

    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;

    setChosenValues(chosenValuesData);

    props.handleChangeChoosenProps(name, value);

    masterDirty=true;
    onMasterSetDirty();
  };

  useEffect(() => {
    if (props.addProductForm) {
      let OrderingFromId = OrderTo.length == 1 ? OrderTo[0].id : "";
      if (OrderingFromId != "") {
        props.formData.OrderingFrom = OrderingFromId;
      }
    }
  }, [props.formData.OrderingFrom, OrderTo, props.addProductForm]);

  const [checked, setChecked] = useState(false);
  const [OnlyOrderedItems, setOnlyOrderedItems] = useState(false);
  const handleOnlyOrderdItems = (event) => {
    setChecked(event.target.checked);
    setOnlyOrderedItems(event.target.checked);
  };

   //console.log("kkkk",activeProductGroupData.id);
  const manyTableDataFilter = manyTableData.filter(item =>(OnlyOrderedItems==true? item.ActualOrderQty>0:0==0) && (item.ItemGroupId == activeProductGroupData.id || activeProductGroupData.id==0));

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
        
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Order - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <div className="mr-4 mt-2 float-left">
                        <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                      </div>
                      {/* <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button> */}
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        //onClick={() => props.handleSubmit()}
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/order")}
                        onClick={() => backToList()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <div className="mr-4 mt-2 float-left">
                        <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                    <Button
                      disabled={(props.bCompleted == -1 ? false : true) || isLoadingUpdate || isLoadingPost }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      //onClick={() => props.handleUpdate()}
                      onClick={onSaveUpdateBtnClick}
                      //disabled={bCompleted == -1 ? false : true}
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>

                    

                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      //onClick={() => props.handlePost()}
                     // disabled={bCompleted == -1 ? false : true}
                     onClick={onPostBtnClick}
                     disabled={(props.bCompleted == -1 ? false : true) || isLoadingUpdate || isLoadingPost}

                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/order")}
                      onClick={() => backToList()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    
                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        required
                        error={props.errorObject.OrderDate}
                        helperText={props.errorObject.OrderDate}
                        id="OrderDate"
                        label={DispensingLanguage[lan][menukey]["Order Date"]}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        autoOk={true}
                        name="OrderDate"
                        fullWidth
                        showTodayButton={true}
                        value={props.formData.OrderDate || props.selectedDate}
                        format="dd/MM/yyyy"
                        //onChange={(e) => props.handleRDateChange(e, "OrderDate")}
                        onChange={(e) =>formOnchange(e,'handleRDateChange','OrderDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={bCompleted == -1 ? false : true}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      error={props.errorObject.OrderNo}
                      helperText={props.errorObject.OrderNo}
                      required
                      id="OrderNo"
                      name="OrderNo"
                      label={DispensingLanguage[lan][menukey]["Order No"]}
                      value={props.formData.OrderNo || props.generatedInvoiceNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                      disabled={true}
                    />
                  </Grid>

                 

                  {/*      
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Order Type"]}
                        <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.OrderTypeId}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="OrderTypeId"
                        name="OrderTypeId"
                        value={props.formData.OrderTypeId}
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','OrderTypeId')}
                        disabled={bCompleted == -1 ? false : true}
                      >
                        {OrderType.map((item, index) => {
                          return (
                            <MenuItem
                              key={`OrderType-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.OrderTypeId}>
                        {props.errorObject.OrderTypeId}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  */}

                  
                  





                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth_1}>
                      <Autocomplete
                        disabled={manyTableData.length > 0 ? true : props.bStockUpdate}
                        autoHighlight
                        id="SupplierId"
                        options={SupplierListByFacility}
                        onChange={(event, valueobj) => handleChangeChoosen('SupplierId', valueobj, valueobj ? valueobj.SupplierId : "")}
                        getOptionLabel={(option) => option.SupplierName}
                        value={chosenValues['SupplierId']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.SupplierName}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.SupplierId}
                            label={DispensingLanguage[lan][menukey]["Order To"]}
                            variant="standard"
                            error={props.errorObject.SupplierId}
                            helperText={props.errorObject.SupplierId}
                            id="SupplierId"
                            name="SupplierId"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl className={classes.fullWidth_2}>
                      <Add
                        className="add_plus"
                        hidden={manyTableData.length > 0 ? true : props.bStockUpdate}
                        onClick={() => handleClose("SupplierOpen")}
                      />
                    </FormControl>

                    <SupplierModal
                      handleClose={handleClose}
                      openOrder={SupplierOpen}
                      addSupplier={addSupplier}
                      {...props}
                    />
                  </Grid>
                  
                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>
                        formOnchange(e, "handleChange", "Remarks")
                      }
                      disabled={bCompleted == -1 ? false : true}
                    />
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={bCompleted == -1 ? false : true}
                        autoHighlight
                        id="OrderBy"
                        options={UsersListByFacility}
                        onChange={(event, valueobj) => handleChangeChoosen('OrderBy', valueobj, valueobj ? valueobj.id : "")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['OrderBy']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.OrderBy}
                            label={DispensingLanguage[lan][menukey]["Prepared By"]}
                            variant="standard"
                            error={props.errorObject.OrderBy}
                            helperText={props.errorObject.OrderBy}
                            id="OrderBy"
                            name="OrderBy"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={bCompleted == -1 ? false : true}
                        autoHighlight
                        id="ApprovedBy"
                        options={UsersListByFacility}
                        onChange={(event, valueobj) => handleChangeChoosen('ApprovedBy', valueobj, valueobj ? valueobj.id : "")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['ApprovedBy']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ApprovedBy}
                            label={DispensingLanguage[lan][menukey]["Approved By"]}
                            variant="standard"
                            error={props.errorObject.ApprovedBy}
                            helperText={props.errorObject.ApprovedBy}
                            id="ApprovedBy"
                            name="ApprovedBy"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Grid> */}


                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="OrderId"
                      name="OrderId"
                      label="OrderId"
                      value={props.formData.OrderId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>

                </Grid>
              </Grid>
             
                  
                  


                
              
              
                  {/* new row */}
                  <Grid item xs={12} sm={12}>
                    <Card className="sw_card">
                      <CardContent>
                        <div className="">
                          <div className={`${classes.fullWidth} d-flex mb-2`}>
                            <div className={classes.fullWidth}>
                              <FormControl
                                style={{ maxWidth: "360px" }}
                                className={classes.fullWidth}
                              >
                                <Autocomplete
                                  autoHighlight
                                  className="sw_chosen_filter"
                                  id="ProductGroupList"
                                  disableClearable
                                  options={ProductGroupList}
                                  value={activeProductGroupData}
                                  onChange={(event, newValue) =>
                                    handleProductGroupChange(event, newValue)
                                  }
                                  disabled={
                                    props.addProductForm == true ? true : false
                                  }
                                  getOptionLabel={(option) => option.name || ""}
                                  renderOption={(option) => (
                                    <Typography className="sw_fontSize">
                                      {option.name}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        DispensingLanguage[lan][menukey][
                                          "Product Group"
                                        ]
                                      }
                                      variant="standard"
                                    />
                                  )}
                                />
                              </FormControl>
                            </div>


                            <div className={`${classes.fullWidth} d-flex align-items-center justify-content-end`}>
                              <div>
                              {props.addProductForm ? (
                                <></>
                              ) : (
                                
                                  
                                    <Button
                                      disabled={bCompleted == -1 ? false : true}
                                      className="mr-2"
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        handleClose("ProductsOpen")
                                      }
                                    >
                                      {
                                        DispensingLanguage[lan][menukey][
                                          "SELECT PRODUCTS"
                                        ]
                                      }
                                    </Button>
                                  
                                
                              )}
                              </div>
                            </div>

                            <ProductsModal
                              handleClose={handleClose}
                              open={open}
                              addProduct={addProduct}
                              {...props}
                            />


                            



                          </div>

                          
                          
                          <div className="uniqueName">
                            <ReactTabulator
                              ref={(r) => (tblRef = r)}
                              columns={manycolumns}
                              data={manyTableData.sort((a, b) => {
                                const groupComparison = a.GroupName.localeCompare(b.GroupName);
                                return groupComparison !== 0 ? groupComparison : a.ItemName.localeCompare(b.ItemName);
                              })}
                              //data={manyTableDataFilter}
                              layout={"fitColumns"}
                              height="375px"
                              options={{
                                groupBy: ["GroupName"],

                                columnCalcs: "both",
                              }}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>


                
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderToSupplierFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
});
