import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import swal from "sweetalert";
import Reports from "./Reports";

const Index = (props) => {
  const { path } = useRouteMatch();

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = process.env.REACT_APP_BASE_NAME + `/login`;
          sessionStorage.clear();
        }
      });
    }
  };

  if (RedirectLogin) {
    checkLogin();
    setRedirectLogin(false);
  }
  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");

  //   if (!token) {
  //     swal("Oops!", `token expired. Please login again`, "error");
  //     props.history.push("/login");
  //   }
  // };


  // const checkAccess = () => {
  //   let auth = JSON.parse(sessionStorage.getItem("User_info"));

  //   if (auth) {
  //     let menu = auth[0].menu;
  //     if (menu.length > 0) {
  //       var check = menu.filter((name) => name.title.includes("Receive"));
  //       if (check.length == 0) {
  //         props.history.push(menu[0].url);
  //       }
  //     } else {
  //       swal("Oops!", `You don't have permission to access any page`, "error");
  //       props.history.push("/login");
  //       sessionStorage.clear();
  //     }
  //   }
  // };

  return (
    <div>
      <AfterLoginNavbar {...props} />
      <div className="section signup-top-padding sw_inner_container">
        <Switch>
          <Route path={`${path}/`} component={Reports}></Route>
        </Switch>
      </div>
    </div>
  );
};

export default Index;
