import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import { Grid, Card, CardContent } from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

const Products = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const menukey = "repacking";
  const FacilityName = localStorage.getItem("FacilityName");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  // const tableRef = useRef();
  const { useState } = React;

  const [dataItems, setDataItems] = useState([]);

  const [currProductGroupId, setCurrProductGroupId] = useState(3);

  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    TransactionTypeId: 3,
    AdjTypeId: 7,
    ItemGroupId: currProductGroupId,
  };
  const { refetch } = useQuery([params], api.getRepackingInvs, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const { mutate } = useMutation(api.deleteRepackingInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        refetch();
        queryClient.getQueriesData("repacking");

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
          duration: 10000,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
      "?action=getRepacking" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FacilityId=" +
      FacilityId +
      "&FacilityName=" +
      FacilityName +
      "&StartDate=" +
      StartDate +
      "&EndDate=" +
      EndDate +
      "&ItemGroupId=" +
      currProductGroupId +
      "&TimeStamp=" +
      Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editRecord(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Adj. Date"],
      width: 150,
      field: "TransactionDate",
      headerFilter: true,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adj. Invoice No"],
      field: "TransactionNo",
      headerFilter: true,
      width: 210,
    },
    {
      title: DispensingLanguage[lan][menukey]["Adj. Type"],
      field: "AdjType",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["To_From"],
      field: "to_from",
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Amount (CFA)"],
      field: "Amount",
      headerFilter: true,
      //width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      visible: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Status"],
      width: 85,
      headerHozAlign: "center",
      field: "bStockUpdated",
      hozAlign: "center",
      formatter: "tickCross",
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editRecord = (data) => {
    let route = `repacking/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteRecord = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this record!"
        ]
      ),
      icon: "warning",
      buttons: [
        t(DispensingLanguage[lan][menukey]["No"]),
        t(DispensingLanguage[lan][menukey]["Yes"]),
      ],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";

    if (!rowData.bStockUpdated)
      return (
        <>
          <Edit
            onClick={() => {
              editRecord(rowData);
            }}
          />
          <DeleteOutline
            onClick={() => {
              deleteRecord(rowData);
            }}
          />
        </>
      );
    else
      return (
        <>
          <ViewList
            onClick={() => {
              editRecord(rowData);
            }}
          />
        </>
      );
  }

  return (
    <>
      <div className="sw_topmargin">
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Repacking"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                  locale={lan == "en_GB" ? enGB : fr}
                >
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      showTodayButton={true}
                      autoOk={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["From"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                  locale={lan == "en_GB" ? enGB : fr}
                >
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["To"]}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={8} sm={8}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push(
                        "/repacking/add/" + currProductGroupId
                      );
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add Repacking"]}
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
