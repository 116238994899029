import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const Manufacturer = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "manufacturer-entry-form";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const { t } = useTranslation();

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  // ====================== TEST CODE START ======================

  // Local Storage Data
  // const Facility_List = JSON.parse(localStorage.getItem("getFacilityListEqual99"));
  // let ALL_Facility_label = { FacilityId: "", FacilityName: t(DispensingLanguage[lan][menukey]["All"]) };
  // const FacilityList = [ALL_Facility_label].concat(Facility_List);

  // STATES
  // const [currFacility, setCurrFacility] = useState(ALL_Facility_label);
  const [errorObject, setErrorObject] = useState({});

  // HANDLERS
  // const handleFacilityChange = (event, newValue) => {
  //   setCurrFacility(newValue);
  //   if(newValue.FacilityId !== '') {
  //     setErrorObject({});
  //   }
  // };

  // const handleAddSupplier =() => {
  //   if (currFacility.FacilityId === '') {
  //     props.openNoticeModal({
  //       isOpen: true,
  //       msgtype:0,
  //       msg: DispensingLanguage[lan][menukey]["Please Select a Facility"],
  //     });
  //   } else {
  //     props.history.push(`/supplier-form/add/${currFacility.FacilityId}`);
  //   }
  // }
  // FacilityId: DispensingLanguage[lan][menukey]["field is Required !"]

  // ====================== TEST CODE END ======================

  let params = {
    menukey: menukey,
    action: "getManufacturerFormList",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const queryClient = useQueryClient();

  const { refetch } = useQuery([params], api.getManufacturerFormList, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  // const { refetch } = useQuery("supplier-form", api.getSupplierFormList, {
  //   onSuccess: (data) => {
  //     setDataItems(data);
  //   },
  //   refetchOnWindowFocus: false,
  //   refetchOnmount: false,
  //   refetchOnReconnect: false,
  //   retry: false,
  //   staleTime: 0,//1000 * 60 * 60 * 24,
  // });

  const { mutate: deleteApi } = useMutation(api.deleteManufacturerForm, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        refetch();
        queryClient.getQueriesData("manufacturer-entry-form");

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ManufacturerFormExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editunit(rowData);
  };

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#."],
      formatter: "rownum",
      width: 80,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer Name"],
      headerFilter: true,
      field: "ManufacturerName",
      width: 250,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer Address"],
      headerFilter: true,
      field: "ManufacturerAddress",
      width: 250,
    },
    {
      title: DispensingLanguage[lan][menukey]["Country"],
      headerFilter: true,
      field: "CountryName",
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["Contact Name"],
      headerFilter: true,
      field: "ContactName",
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["Contact No"],
      headerFilter: true,
      field: "ContactNo",
      width: 150,
    },
    {
      title: DispensingLanguage[lan][menukey]["Fax"],
      headerFilter: true,
      field: "Fax",
    },
    {
      title: DispensingLanguage[lan][menukey]["Email"],
      headerFilter: true,
      field: "Email",
    },
    {
      title: DispensingLanguage[lan][menukey]["URL"],
      headerFilter: true,
      field: "Url",
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 80,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editunit = (data) => {
    // let route = `/supplier-form/edit/${data.id}?FacilityId=${data.FacilityId}`;
    let route = `/manufacturer-entry-form/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteunit = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //         "Once deleted, you will not be able to recover this Record!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: [
  //       t(DispensingLanguage[lan][menukey]["No"]),
  //       t(DispensingLanguage[lan][menukey]["Yes"]),
  //     ],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       deleteApi({
  //         ManufacturerId: data.id,
  //         menukey: menukey,
  //         lan: lan,
  //         UserName: UserName,
  //         action: "dataDalete",
  //       });
  //     }
  //   });
  // };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            modalPopupDelete(deleteApi, rowData.id);
           // deleteunit(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
    <div className="sw_inner_card">
      <div className="d-flex justify-dispenser mb-2">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Manufacturer Entry Form"]}
            </div>
            <div className="float-right sw_btn_control">
              <Button
                color="primary"
                variant="contained"
                className="mr-2"
                onClick={() => {
                  props.history.push("/manufacturer-entry-form/add");
                }}
              >
                {DispensingLanguage[lan][menukey]["Add"]}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mr-2 sw_icons"
                onClick={() => PrintPDFExcelExportFunction("print")}
              >
                <i className="fas fa-print"></i>
              </Button>

              <Button
                color="primary"
                variant="contained"
                className="mr-2 sw_icons"
                onClick={() => PrintPDFExcelExportFunction("excel")}
              >
                <i className="far fa-file-excel"></i>
              </Button>
            </div>
          </div>
        </Grid>
      </div>

      <div>
        <div className="uniqueName">
          <ReactTabulator
            columns={columns}
            data={dataItems}
            layout={"fitColumns"}
            height={"450px"}
            rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
          />
        </div>
      </div>
    </div>
  </>
  );
};

export default Manufacturer;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
