import React, { useState, useEffect } from "react"; //forwardRef,
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
// materialui table
// import MaterialTable from "material-table";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  FormControl,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import ForecastModal from "./ForecastModal";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
// import MomentUtils from "@date-io/moment";
import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import {
  saveLayoutSettings,
  clearLayoutSettings,
  getFilterValue,
} from "../../../services/LayoutSettings";

const Forecastedconsumption = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "forecasted-consumption-entry";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  /* Get saved filter value from layoutSettings */
  const StartDateFilter = getFilterValue(menukey, "StartDateFilter");
  const EndDateFilter = getFilterValue(menukey, "EndDateFilter");

  /* Get saved filter value from layoutSettings */

  const [openOrder, setOpenOrder] = useState(false);
  const [openIssue, setOpenIssue] = useState(false);

  const classes = useStyles();

  const [dataItems, setDataItems] = useState([]);
  //const [query, setQuery] = useState("");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const userId = UserInfo == 0 ? "" : UserInfo[0].id;



  // const [selectedStartDate, setSelectedStartDate] = React.useState(
  //   StartDateFilter || moment().subtract(90, "days").format("YYYY-MM-DD")
  // );

  // const handleStartDateChange = (date) => {
  //   setSelectedStartDate(date);
  //   setLoading(true);
  // };

  // const [selectedEndDate, setSelectedEndDate] = React.useState(
  //   EndDateFilter || moment().format("YYYY-MM-DD")
  // );

  // const handleEndDateChange = (date) => {
  //   setSelectedEndDate(date);
  //   setLoading(true);
  // };

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});

  const [isLoading, setLoading] = useState(true);

  const { refetch } = useQuery(
    "forecasted-consumption-entry",
    api.getForecastedConsumption,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const { mutate } = useMutation(api.deleteforecast, {
    onSuccess: (data) => {
      if (data.status == 200) {
      console.log(props);
        refetch();
        queryClient.getQueriesData("forecasted-consumption-entry");
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
          duration: 3000,
        });
       
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
   
    window.open(
      finalUrl +
        "?action=getReceipts" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&TransactionTypeId=" +
        1 +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editReceiveInv(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Forecasted Year"],
      field: "Year",
      headerFilter: true
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editReceiveInv = (data) => {
    let route = `/forecasted-consumption-entry/edit/${data.id}`;
    props.history.push(route);
  };

 

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
      return (
        <>
          <Edit
            onClick={() => {
              editReceiveInv(rowData);
            }}
          />
          <DeleteOutline
            onClick={() => {
              modalPopupDelete(mutate, rowData.id);
              // deleteReceiveInv(rowData);
            }}
          />
        </>
      );
   
  }


  const addForecast = (data) => {
      refetch();
    //  let route = `/forecasted-consumption-entry`;
    //  props.history.push(route);
  };

  const handleModal = (modalname) => {
    switch (modalname) {

      case "ForecastModal":
        setOpenIssue(false);
        break;
      case "ForecastOpen":
        setOpenIssue(true);
        break;

      default:
        break;
    }
  };

  // useEffect(() => {
  //   refetch();
  // }, [selectedStartDate, selectedEndDate]);

  

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Forecasted Consumption Entry"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              {/* <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                  locale={lan == "en_GB" ? enGB : fr}
                >
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["From"]}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                  locale={lan == "en_GB" ? enGB : fr}
                >
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["To"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid> */}

              <Grid item xs={12} sm={12}>
                <div className="float-right sw_btn_control">
                 
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2"
                    onClick={() => handleModal("ForecastOpen")}
                  >
                    {DispensingLanguage[lan][menukey]["Generate"]}
                  </Button>
                
                  {/* <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

       

      

        <ForecastModal
          handleClose={handleModal}
          openIssue={openIssue}
          addForecast = {addForecast}
          {...props}
        />

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Forecastedconsumption;

const useStyles = makeStyles({
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
