import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import "assets/css/custom.css";
// pages for this kit

import Index from "views/Index.js";
import LoginPage from "views/screens/LoginPage.js";
import SignUpPage from "views/screens/SignUp.js";
import ResetPassword from "views/screens/ResetPasswordPage";
import LogisticsDashboard from "views/screens/LogisticsDashboard";

/* Start Nita Apu */
// Hello Nita
// hello
/* Start Nita Apu */

import Dashboard from "views/screens/Dashboard";
import Products from "views/screens/products/index.js";
// import Receives from "views/screens/receive/index.js";
import Reports from "views/screens/report/index.js";
import StockStatus from "views/screens/stock_status/index.js";
import Order from "views/screens/order/index.js";

/* Start Farid */
// Hello Farid
import NationalStockStatusReport from "views/screens/NationalStockStatusReport.js";
import PercentageofFacilityStockoutbyProducts from "views/screens/PercentageofFacilityStockoutbyProducts.js";
import FacilityStockStatusbyProductbyMap from "views/screens/FacilityStockStatusbyProduct.js";
import OrderToSupplier from "views/screens/order_to_supplier/index.js";
/* Start Farid */

import OrderApproval from "views/screens/order_approval/index.js";
import Receive from "views/screens/receive/index.js"; 
import Issue from "views/screens/issue/index.js";
import Adjustment from "views/screens/adjustment/index.js";
import Repacking from "views/screens/repacking/index.js";
import StockTake from "views/screens/stock_take/index.js";

/* Start Nazim */
import DataExtractTool from "views/screens/data_extract_tool/index.js";
import IssueToField from "views/screens/issue_to_field/index.js";
import FieldDispense from "views/screens/field_dispense/index.js";
/* Start Nazim */

import Uom from "views/screens/uom/index.js";
//Interval
import Labtests from "views/screens/labtest/index.js";
//Rute of admin
import Routeofadmin from "views/screens/routeofadmin/index.js";
//Order type list
import Ordertypelists from "views/screens/ordertypelist/index.js";
//ABC list
import Abcs from "views/screens/abc/index.js";
import Role from "views/screens/roleentryfrom/index.js";
import Menuentry from "views/screens/menuentry/index.js";
import ProfileParameters from "views/screens/profileparameters/index.js";
import Roleaccessentry from "views/screens/roleaccessentry/index.js";
import AdjustmentType from "views/screens/adjustment_type/index.js";
import PackSize from "views/screens/pack_size/index.js";
import Form from "views/screens/form/index.js";

/* Start Sumon */
import IssueVoucherpush from "views/screens/issue_voucher_push/index.js";
import Recipient from "views/screens/recipient/index.js";
import AgeGroupDistribution from "views/screens/AgeGroupDistribution.js";
import AEFIList from "views/screens/aefi_report/index.js";
import PatientByGender from "views/screens/PatientByGender.js";
import SeverityPatient from "views/screens/SeverityPatient.js";
import StoppingTreatment from "views/screens/StoppingTreatment.js";
import ReAdministration from "views/screens/ReAdministration.js";
import AEFIReportingMap from "views/screens/AEFIReportingMap.js";
import StockStatusDifferentLevel from "views/screens/StockStatusDifferentLevel.js";
import PercentageoffacilitybyMOSscale from "views/screens/PercentageoffacilitybyMOSscale.js";
import PercentageofFacilitiesStockedout from "views/screens/PercentageofFacilitiesStockedout.js";
import ConsumptionTrendbyFacility from "views/screens/ConsumptionTrendbyFacility.js";
import LossSummaryReport from "views/screens/LossSummaryReport.js";
import LossSummaryByFacilityReport from "views/screens/LossSummaryByFacilityReport.js";
import FacilityeLMISActivityStatusReport from "views/screens/FacilityeLMISActivityStatusReport.js";
import ShipmentReport from "views/screens/ShipmentReport.js"
import Forecastedconsumption from "views/screens/forecasted-consumption-entry/index.js";
import ConsumptionTrendbyProduct from "views/screens/ConsumptionTrendbyProduct.js";
import CountryProfile from "views/screens/country-profile/index.js";
/* Start Sumon */

import Strength from "views/screens/strength/index.js";
import UiLanguage from "views/screens/ui_language/index.js";
import Facility from "views/screens/facility/index.js";
import UserEntry from "views/screens/user_entry/index.js";
import MonthClose from "views/screens/month_close/index.js";
import PatientImport from "views/screens/patient_import/index.js";
import Generics from "views/screens/generics/index.js";
import MOSRange from "views/screens/mos_range/index.js";
import MOSTypeForFacility from "views/screens/mos_type_for_facility_entry/index.js";
import ATC from "views/screens/atc/index.js";
import Source from "views/screens/source/index.js";
import StockSummary from "views/screens/StockSummary";
import LMISReport from "views/screens/LMISReport";
import ReceiveDetailsReport from "views/screens/Receivedetailsreport.js";
import StockCardReport from "views/screens/StockCardReport.js";
import LotExpirein3MonthsReport from "views/screens/LotExpirein_3MonthsReport.js";

/* Start Hasem */
// Hello Hasem 
import AdjustmentDetailsReport from "views/screens/AdjustmentDetailsReport";
/* Start Hasem */

import ProductsNearingExpiryReport from "views/screens/ProductsNearingExpiryReport";
import LabProductsNearingExpiryReport from "views/screens/LabProductsNearingExpiryReport";
import ConsumptionTrendsForTheTopFiveProducts from "views/screens/ConsumptionTrendsForTheTopFiveProducts";
import StockStatusDetails from "views/screens/StockStatusDetails";
import AMCTrendsforTheTopFiveProducts from "views/screens/AMCTrendsforTheTopFiveProducts";
import PercentageOfFacilitiesWithAStockoutOfTracerCommodities from "views/screens/PercentageOfFacilitiesWithAStockoutOfTracerCommodities";
import AvailabilityOfCommodities from "views/screens/AvailabilityOfCommodities";
import MyProfile from "views/screens/myprofile/index.js";
import TldStockstatus from "views/screens/TldStockstatus";
import YearEntry from "views/screens/year_entry/index.js";
import Errorlog from "views/screens/error-log/index.js";
import Aefi from "views/screens/aefi/index.js";
/* Start Bilkis */
// Hello Bilkis 
import DispenseReport from "views/screens/DispenseReport";
import CountryProfileReport from "views/screens/CountryProfileReport";
import LossReport from "views/screens/LossReport";
import Stocktakedetailsreports from "views/screens/Stocktakedetailsreports";
import CommoditiesStockstatusbyLotExpiry from "views/screens/CommoditiesStockstatusbyLotExpiry";
import GapAnalysisReport from "views/screens/GapAnalysisReport";
import A7ReportsView from "views/screens/A7ReportsView";
import ConsumptionTrend from "views/screens/ConsumptionTrend";
import NumberOfOrdersPlacedPerYearandPerLevel from "views/screens/NumberOfOrdersPlacedPerYearandPerLevel";
import NumberOfDeliveriesMadePerMonthandPerLevel from "views/screens/NumberOfDeliveriesMadePerMonthandPerLevel";
import OrderSatisfactionRateByLevel from "views/screens/OrderSatisfactionRateByLevel";
import VaccineAvailabilityRateByTypeofFacilityandProduct from "views/screens/VaccineAvailabilityRateByTypeofFacilityandProduct";
import AverageNumberStocksOutDaysByProduct from "views/screens/AverageNumberStocksOutDaysByProduct";
import FacilityDashboard from "views/screens/facility_dashboard/FacilityDashboard";
import CSTeam from "views/screens/CSTeam/index.js";
import Manufacturer from "views/screens/manufacturer_entry_form/index.js";
import VvmType from "views/screens/vvm_type/index.js";
import VVMstatus from "views/screens/vvm_status/index.js";
import FundingSource from "views/screens/fundingSource/index.js";
import FundingReqSourceEntry from "views/screens/fundingReqSource/index.js";
import AEFIGenderType from "views/screens/aefi-gender-type/index.js";
import AEFIseverity from "views/screens/aefi-severity/index.js";
import AEFIProfessions from "views/screens/aefi-professions/index.js";
import AEFIstopTreatment from "views/screens/aefi-stop-treatment/index.js";
import AEFIReadminitration from "views/screens/aefi-readministration/index.js";
import ShipmentStatus from "views/screens/shipment_status/index.js";
/* Start Bilkis */

import Auditlog from "views/screens/audit-log/index.js";
import ProductGroupEntry from "views/screens/product_group_entry/index.js";
import ProductSubgroup from "views/screens/product_subgroup/index.js";
import FacilityLevel from "views/screens/facility_level_entry/index.js";
import UserContextProvider from './context/user-info-context';
import CheckPermission from "views/screens/CheckPermission.js";
import Supplier from "views/screens/supplier/index.js";
import StoreEntry from "views/screens/store_entry/index.js";
import FacilityType from "views/screens/facility-type/index.js";
import Servicearea from "views/screens/servicearea/index.js";
import NationalMosTypeEntry from "views/screens/national_mos_type_entry/index.js";
import Department from "views/screens/Department/index.js";
import CountryEntry from "views/screens/countryEntry/index.js";
import Commune from "views/screens/commune/index.js";
import RecipientGroup from "views/screens/recipient_group/index.js";
import OwnerFormData from "views/screens/owner_type_entry/index.js";
import ZS from "views/screens/zs/index.js";
import ReceiveFromSupplier from "views/screens/receive_from_supplier/index.js";
import OrderReport from "views/screens/OrderReport";

import Shipment from "views/screens/shipment/index.js";

// add page 
import IssueDetailsReport from "views/screens/IssueDetailsReport";
import CommoditiesStockStatus from "views/screens/CommoditiesStockStatus";
import ProductExpiredReport from "views/screens/ProductExpiredReport";
import SupplierForm from "views/screens/supplier_form/index.js";
import packageJson from "../package.json";
import swal from "sweetalert";

import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient()
// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// );

let userInfo = null;
//if (selFacility != null) {
userInfo = {
  FacilityId: 0,
  FacilityName: 'CHUZ-SURU-LERE',
  LangId: 'fr_FR'
};


//////////////////////////////////////////////////////////



const clearCacheData = () => {
  caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  });
   
  setTimeout(function(){
    window.location.reload();
 }, 1000);


};


if(localStorage.getItem("sw_Version")==null){
 
  swal({
    title: "",
    text:   'A new version of the application is available.'+', Version-'+`${process.env.REACT_APP_SW_Version}`,
    icon: "warning",
    button: 'Refresh',
    showCancelButton: false,
    showConfirmButton: false
    }).then((willDelete) => {
    if (willDelete) { 
      localStorage.setItem(
        "sw_Version",
        `${process.env.REACT_APP_SW_Version}`
      );
      window.location.href = `${process.env.REACT_APP_BASE_NAME}`;
      clearCacheData(); 
      
    }
  });



 }else if(localStorage.getItem("sw_Version")<`${process.env.REACT_APP_SW_Version}`){

  

  swal({
    title: "",
    text:  'A new version of the application is available, press Refresh to update from ['+localStorage.getItem("sw_Version")+'] to ['+`${process.env.REACT_APP_SW_Version}`+']',
    icon: "warning",
    button: 'Refresh',
    showCancelButton: false,
    showConfirmButton: false
    }).then((willDelete) => {
    if (willDelete) { 
      localStorage.setItem(
        "sw_Version",
        `${process.env.REACT_APP_SW_Version}`
      );
      window.location.href = `${process.env.REACT_APP_BASE_NAME}`;
      clearCacheData(); 
    }
  });

 } 

























/////////////////////////////////////
/*
const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });

  setTimeout(function () {
    window.location.reload();
  }, 1000);


};


if (localStorage.getItem("Version") == null) {

  localStorage.setItem(
    "Version",
    packageJson.version
  );
  window.location.href = `${process.env.REACT_APP_BASE_NAME}`;
  clearCacheData();
 



} else if (localStorage.getItem("Version") != packageJson.version) {



  swal({
    title: "",
    text: 'A new version is available',
    icon: "warning",
    button: 'Refresh',
    showCancelButton: false,
    showConfirmButton: false
  }).then((willDelete) => {
    if (willDelete) {
      localStorage.setItem(
        "Version",
        packageJson.version
      );
      window.location.href = `${process.env.REACT_APP_BASE_NAME}`;
      clearCacheData();
    }
  });

}
*/
ReactDOM.render(
  <UserContextProvider userInfo={userInfo}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <Suspense>
          <Switch>
            {/* <Route path="/home" render={(props) => <Index {...props} />} /> */}
            <Route path="/login" render={(props) => <LoginPage {...props} />} />
            <Route path="/signup" render={(props) => <SignUpPage {...props} />} />
            <Route path="/reset-password" render={(props) => <ResetPassword {...props} />} />
            <Route path="/logistics-dashboard" render={(props) => <LogisticsDashboard {...props} />} />

            {/* Start Nita Apu */}



            {/* Start Nita Apu */}

            <Route path="/dashboard" render={(props) => <Dashboard {...props} />} />
            <Route path="/products" render={(props) => <Products {...props} />} />
            <Route path="/report" render={(props) => <Reports {...props} />} />
            <Route path="/stock-status" render={(props) => <StockStatus {...props} />} />
            <Route path="/order" render={(props) => <Order {...props} />} />

            {/* Start Farid */}
            <Route path="/national-stock-status-report" render={(props) => <NationalStockStatusReport {...props} />} />
            <Route path="/percentage-of-facility-stockout-by-products" render={(props) => <PercentageofFacilityStockoutbyProducts {...props} />} />
            <Route path="/facility-stock-status-by-product-map" render={(props) => <FacilityStockStatusbyProductbyMap {...props} />} />
            <Route path="/order-to-supplier" render={(props) => <OrderToSupplier {...props} />} />
            {/* Start Farid */}

            <Route path="/order-approval" render={(props) => <OrderApproval {...props} />} />
            <Route path="/receive" render={(props) => <Receive {...props} />} /> 
            <Route path="/issue" render={(props) => <Issue {...props} />} />
            <Route path="/adjustment" render={(props) => <Adjustment {...props} />} />
            <Route path="/repacking" render={(props) => <Repacking {...props} />} />
            <Route path="/stock-take" render={(props) => <StockTake {...props} />} />

            {/* Start Nazim */}

            <Route path="/data-extract-tool" render={(props) => <DataExtractTool {...props} />} />
            <Route path="/issue-to-field" render={(props) => <IssueToField {...props} />} />
            <Route path="/field-dispense" render={(props) => <FieldDispense {...props} />} />
            <Route path="/shipment" render={(props) => <Shipment {...props} />} />
            {/* Start Nazim */}

            <Route path="/unit-of-measure-entry-form" render={(props) => <Uom {...props} />} />
            <Route path="/labtest-entry" render={(props) => <Labtests {...props} />} />
            <Route path="/route-of-admin-entry" render={(props) => <Routeofadmin {...props} />} />
            <Route path="/order-type-list-entry" render={(props) => <Ordertypelists {...props} />} />
            <Route path="/abc-entry" render={(props) => <Abcs {...props} />} />
            <Route path="/role-entry" render={(props) => <Role {...props} />} />
            <Route path="/menu-entry" render={(props) => <Menuentry {...props} />} />
            <Route path="/profile-parameters" render={(props) => <ProfileParameters {...props} />} />
            <Route path="/role-access-entry" render={(props) => <Roleaccessentry {...props} />} />
            <Route path="/adjustment-type" render={(props) => <AdjustmentType {...props} />} />
            <Route path="/pack-size" render={(props) => <PackSize {...props} />} />
            <Route path="/form" render={(props) => <Form {...props} />} />

            {/* Start Sumon */}
            <Route path="/issue-voucher-push" render={(props) => <IssueVoucherpush {...props} />} />
            <Route path="/recipient" render={(props) => <Recipient {...props} />} />
            <Route path="/age-group-distribution" render={(props) => <AgeGroupDistribution {...props} />} />
            <Route path="/aefi-list" render={(props) => <AEFIList {...props} />} />
            <Route path="/patient-by-gender" render={(props) => <PatientByGender {...props} />} />
            <Route path="/severity-patient" render={(props) => <SeverityPatient {...props} />} />
            <Route path="/stopping-treatment" render={(props) => <StoppingTreatment {...props} />} />
            <Route path="/re-administration" render={(props) => <ReAdministration {...props} />} />
            <Route path="/aefi-reporting-map" render={(props) => <AEFIReportingMap {...props} />} />

            <Route path="/stock-status-at-different-level" render={(props) => <StockStatusDifferentLevel {...props} />} />
            <Route path="/percentage-of-facility-by-mos-scale" render={(props) => <PercentageoffacilitybyMOSscale {...props} />} />
            <Route path="/percentage-of-facilities-stocked-out" render={(props) => <PercentageofFacilitiesStockedout {...props} />} />
            <Route path="/consumption-trend-by-facility" render={(props) => <ConsumptionTrendbyFacility {...props} />} />
            <Route path="/loss-summary-report" render={(props) => <LossSummaryReport {...props} />} />
            <Route path="/loss-summary-report-by-facility" render={(props) => <LossSummaryByFacilityReport {...props} />} />
            <Route path="/facility-elmis-activity-status" render={(props) => <FacilityeLMISActivityStatusReport {...props} />} />
            <Route path="/shipment-report" render={(props) => <ShipmentReport {...props} />} />
            <Route path="/forecasted-consumption-entry" render={(props) => <Forecastedconsumption {...props} />} />
            <Route path="/national-consumption-trend-by-product" render={(props) => <ConsumptionTrendbyProduct {...props} />} />
            <Route path="/Country-Profile-Entry" render={(props) => <CountryProfile {...props} />} />
            
            {/* Start Sumon */}

            <Route path="/strength" render={(props) => <Strength {...props} />} />
            <Route path="/ui-language" render={(props) => <UiLanguage {...props} />} />
            <Route path="/facility" render={(props) => <Facility {...props} />} />
            <Route path="/user-entry" render={(props) => <UserEntry {...props} />} />
            <Route path="/lmis-report" render={(props) => <MonthClose {...props} />} />
            <Route path="/patient-import" render={(props) => <PatientImport {...props} />} />
            <Route path="/generics" render={(props) => <Generics {...props} />} />
            <Route path="/mos-range" render={(props) => <MOSRange {...props} />} />
            <Route path="/mos-type-for-facility-entry" render={(props) => <MOSTypeForFacility {...props} />} />
            <Route path="/atc-entry" render={(props) => <ATC {...props} />} />
            <Route path="/source-entry" render={(props) => <Source {...props} />} />
            <Route path="/stock-summary" render={(props) => <StockSummary {...props} />} />
            
            <Route path="/receive-details-report" render={(props) => <ReceiveDetailsReport {...props} />} />
            <Route path="/stock-card-report" render={(props) => <StockCardReport {...props} />} />
            <Route path="/lot-expire-in-months-report" render={(props) => <LotExpirein3MonthsReport {...props} />} />

            {/* Start Hasem */}

            <Route path="/adjustment-details-report" render={(props) => <AdjustmentDetailsReport {...props} />} />

            {/* Start Hasem */}

            <Route path="/products-nearing-expiry-report" render={(props) => <ProductsNearingExpiryReport {...props} />} />
            <Route path="/lab-products-nearing-expiry-report" render={(props) => <LabProductsNearingExpiryReport {...props} />} />
            <Route path="/consumption-trends-for-the-top-five-products" render={(props) => <ConsumptionTrendsForTheTopFiveProducts {...props} />} />
            <Route path="/stock-status-details" render={(props) => <StockStatusDetails {...props} />} />
            <Route path="/amc-trends-for-the-top-five-products" render={(props) => <AMCTrendsforTheTopFiveProducts {...props} />} />
            <Route path="/percentage-of-facilities-with-a-stockout-of-tracer-commodities" render={(props) => <PercentageOfFacilitiesWithAStockoutOfTracerCommodities {...props} />} />
            <Route path="/availability-of-commodities" render={(props) => <AvailabilityOfCommodities {...props} />} />
            <Route path="/my-profile" render={(props) => <MyProfile {...props} />} />
            <Route path="/tld-stock-status" render={(props) => <TldStockstatus {...props} />} />
            <Route path="/year_entry" render={(props) => <YearEntry {...props} />} />
            <Route path="/aefi" render={(props) => <Aefi {...props} />} />
            <Route path="/error-log" render={(props) => <Errorlog {...props} />} />

            {/* Start Bilkis */}
            <Route path="/dispense-report" render={(props) => <DispenseReport {...props} />} />
            <Route path="/country-profile" render={(props) => <CountryProfileReport {...props} />} />
            <Route path="/loss-report" render={(props) => <LossReport {...props} />} />
            <Route path="/stock-take-details-reports" render={(props) => <Stocktakedetailsreports {...props} />} />
            <Route path="/commodities-stock-status-by-lot-expiry" render={(props) => <CommoditiesStockstatusbyLotExpiry {...props} />} />
            <Route path="/gap-analysis" render={(props) => <GapAnalysisReport {...props} />} />
            <Route path="/a7-report-view" render={(props) => <A7ReportsView {...props} />} />
            <Route path="/consumption-trend" render={(props) => <ConsumptionTrend {...props} />} />
            <Route path="/number-of-orders-placed-per-year-and-per-level" render={(props) => <NumberOfOrdersPlacedPerYearandPerLevel {...props} />} />
            <Route path="/number-of-deliveries-made-per-month-and-per-level" render={(props) => <NumberOfDeliveriesMadePerMonthandPerLevel {...props} />} />
            <Route path="/order-satisfaction-rate-by-level" render={(props) => <OrderSatisfactionRateByLevel {...props} />} />
            <Route path="/vaccine-availability-rate-by-type-of-facility-and-by-product" render={(props) => <VaccineAvailabilityRateByTypeofFacilityandProduct {...props} />} />
            <Route path="/average-number-of-stocks-out-days-by-product" render={(props) => <AverageNumberStocksOutDaysByProduct {...props} />} />
            <Route path="/facility-dashboard" render={(props) => <FacilityDashboard {...props} />} />
            <Route path="/cs-team" render={(props) => <CSTeam {...props} />} />
            <Route path="/manufacturer-entry-form" render={(props) => <Manufacturer {...props} />} />
            <Route path="/vvm-type" render={(props) => <VvmType {...props} />} />
            <Route path="/vvm-status" render={(props) => <VVMstatus {...props} />} />
            <Route path="/funding-source-entry" render={(props) => <FundingSource {...props} />} />
            <Route path="/funding-requirements-source" render={(props) => <FundingReqSourceEntry {...props} />} />
            <Route path="/aefi-gender-type" render={(props) => <AEFIGenderType {...props} />} />
            <Route path="/aefi-severity" render={(props) => <AEFIseverity {...props} />} />
            <Route path="/aefi-stop-treatment" render={(props) => <AEFIstopTreatment {...props} />} />
            <Route path="/aefi-readministration" render={(props) => <AEFIReadminitration {...props} />} />
            <Route path="/shipment-status" render={(props) => <ShipmentStatus {...props} />} />
            {/* Start Bilkis */}

            <Route path="/audit-log" render={(props) => <Auditlog {...props} />} />
            <Route path="/product-group-entry" render={(props) => <ProductGroupEntry {...props} />} />
            <Route path="/product-subgroup-entry" render={(props) => <ProductSubgroup {...props} />} />
            <Route path="/store-entry" render={(props) => <StoreEntry {...props} />} />
            <Route path="/facility-level-entry" render={(props) => <FacilityLevel {...props} />} />
            <Route path="/owner-type-entry" render={(props) => <OwnerFormData {...props} />} />
            <Route path="/national-mos-type-entry" render={(props) => <NationalMosTypeEntry {...props} />} />
            <Route path="/department-entry" render={(props) => <Department {...props} />} />
            <Route path="/check-permission" render={(props) => <CheckPermission {...props} />} />
            <Route path="/supplier" render={(props) => <Supplier {...props} />} />
            <Route path="/commune-entry" render={(props) => <Commune {...props} />} />
            <Route path="/recipient_group" render={(props) => <RecipientGroup {...props} />} />
            <Route path="/facility-type" render={(props) => <FacilityType {...props} />} />
            <Route path="/service-area" render={(props) => <Servicearea {...props} />} />
            <Route path="/country-entry" render={(props) => <CountryEntry {...props} />} />
            <Route path="/zone-entry" render={(props) => <ZS {...props} />} />
            <Route path="/receive-from-supplier" render={(props) => <ReceiveFromSupplier {...props} />} />
            <Route path="/commodities-stock-status" render={(props) => <CommoditiesStockStatus {...props} />} />
            <Route path="/product-expired-report" render={(props) => <ProductExpiredReport {...props} />} />
            <Route path="/order-report" render={(props) => <OrderReport {...props} />} />
            <Route path="/professions" render={(props) => <AEFIProfessions {...props} />} />
            {/* Start Madani */}


            {/* Start Madani */}

            <Route path="/supplier-form" render={(props) => <SupplierForm {...props} />} />
            <Route path="/issue-details-report" render={(props) => <IssueDetailsReport {...props} />} />
            <Route path="/" render={(props) => <Index {...props} />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </UserContextProvider>,
  document.getElementById("root1")
);
