import React, { useState } from "react"; 
import { 
  Button , 

} from "@material-ui/core";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator,reactFormatter} from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

import FacilityGroupMapModal from "../../../services/FacilityGroupMapModal";
import FacilityGroupMapEditModal from "services/FacilityGroupMapEditModal";


import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
const lan = localStorage.getItem('LangCode');
const AssignGroup = ({...props}) => {  
  
  const [SupplyFromData, setDataItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [FacilityServiceId, setFacilityServiceId] = useState(0);
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const menukey = "facility";
  const [GroupList, setGroupList] = useState([]);

  //////////////////////////////

  const columns = [
    { title: "FacilityServiceId", field: "FacilityServiceId", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      headerFilter: true,
      field: "GroupName",
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Start Month"],
    //   headerFilter: true,
    //   field: "MonthName",
    // }
    // ,
    // {
    //   title: DispensingLanguage[lan][menukey]["Start Year"],
    //   headerFilter: true,
    //   field: "StartYearId",
    // }
    // ,
    {
      title: DispensingLanguage[lan][menukey]["Supply From"],
      headerFilter: true,
      field: "Supplier",
    }
    ,
    {
      title: DispensingLanguage[lan][menukey]["Action"],
      field: "custom",
      hozAlign: "center",
      width: 60,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
      frozen:true,
    }
    


  ];

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    // console.log(rowData);
    // console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh"+rowData);
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            setFacilityServiceId({
              FacilityServiceId:rowData.FacilityServiceId,
              SupplyFrom:rowData.SupplyFrom,
              GroupName:rowData.GroupName
            });
            
            handleClose('openEditModal');
            //editFacility(rowData);
          }}
        />

        <DeleteOutline
          onClick={() => {
            deleteApi({FacilityServiceId:rowData.FacilityServiceId}); 
          }}
        />
        
      </>
    );
  }
  const handleClose = (modalname) => {
    
    switch (modalname) {
      case "Products":
        groupmaprefetch();
        setOpen(false); 
        break;
      case "ProductsOpen":
      //  groupmaprefetch();
        let fDtat={"lan":lan,  FacilityId:props.match.params.id};  
        getProductGroupMapPopup(fDtat);
        
        break; 
      case "openEditModal": 
        
     //   getProductGroupMapPopup({"lan":lan,  FacilityId:props.match.params.id});
        setOpenEditModal(true);
        break; 

        case "closeEditModal":          
          setOpenEditModal(false);  
          groupmaprefetch();
          break;
       
      default:
        break;
    }
  };
 
  const { mutate:getProductGroupMapPopup } = useMutation(api.getProductGroupMapPopup, {
    onSuccess: (data) => {
      if (data.data.status == 200) {  
        setGroupList(data.data.datalist);
      }else{   
      }
    },
  });

  
  const { refetch:groupmaprefetch } = useQuery([{FacilityId:props.match.params.id}],
    api.getFacilityGroupMap,
    {
      onSuccess: (data) => {
       
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    },
   
  );
  const { mutate:deleteApi } = useMutation(api.deleteFacilityGroupMap, {
    onSuccess: (data) => {
      if (data.data.status == 200) { 
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        groupmaprefetch();
        
        
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          }

    },
  });

  React.useEffect(() => {
    if(GroupList.length>0)
    setOpen(true);
  }, [GroupList]);
  
  return (
    <div >
    <Button
      className="mr-2"
      variant="contained"
      color="primary"
      onClick={() => handleClose("ProductsOpen")}
       >
      {DispensingLanguage[lan][menukey]['Assign groups']}
      
    </Button>

     
      <div className="uniqueName">
        <ReactTabulator
          columns={columns}
          data={SupplyFromData}
          height={300}
          layout={"fitColumns"}
        />
      </div>
     

    <FacilityGroupMapModal
      handleClose={handleClose} 
      open={open} 
      GroupList={GroupList}
      {...props}

    />

   <FacilityGroupMapEditModal
      handleClose={handleClose} 
      open={openEditModal} 
      FacilityServiceId={FacilityServiceId}
      {...props}

    />
   

  </div>
  );
}; 
export default AssignGroup;
 