import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";

// materialui table
// import MaterialTable from "material-table";
import {
  TextField,
  Grid,
  Checkbox,
  FormControl,
  Typography,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
// import MomentUtils from "@date-io/moment";
import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale
import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { DeleteOutline, Edit } from "@material-ui/icons";

import {
  saveLayoutSettings,
  clearLayoutSettings,
  getFilterValue,
} from "../../../services/LayoutSettings";

//const ExcelFile = ReactExport.ExcelFile;
//const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Shipment = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "shipment";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  // const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  // const tableRef = useRef();
  const { useState } = React;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoadingUi, setLoading] = useState(true);
  /* Get saved filter value from layoutSettings */
  const ItemGroupIdFilter = getFilterValue(menukey, "ItemGroupIdFilter");
  const fundingSourceFilter = getFilterValue(menukey, "fundingSourceFilter");
  const ownerTypeFilter = getFilterValue(menukey, "ownerTypeFilter");
  const shipmentStatusFilter = getFilterValue(menukey, "shipmentStatusFilter");

  /* Get saved filter value from layoutSettings */

  const [dataItems, setDataItems] = useState([]);

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({ menukey });
  console.log("bModalConfirm: ", bDeleteConfirm);

  const userId = UserInfo == 0 ? "" : UserInfo[0].id;

  // Filter Start
  const Owner_type_list = JSON.parse(localStorage.getItem("Ownertypelist"));
  const [OwnerTypeName, setOwnerTypeName] = useState(
    t(DispensingLanguage[lan][menukey]["All Owner Type"])
  );
  let All_Owner_Type_list = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All Owner Type"]),
  };

  const getOwnerTypeList = [All_Owner_Type_list].concat(Owner_type_list);

  const [currOwnerTypeId, setCurrOwnerTypeId] = useState(0);
  const handleOwnerListChange = (event, newValue) => {
    let rowId = "";
    let rowname = "";
    if (newValue == null) {
      rowId = "";
      rowname = "";
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }

    refreshAllData(
      activeProductGroupData.id,
      activeFundingSourceData.id,
      rowId
    );

    setCurrOwnerTypeId(rowId);
    setOwnerTypeName(rowname);
  };

  const get_Shipment_Status_List = JSON.parse(
    localStorage.getItem("getShipmentStatusList")
  );
  const [ShipmentStatusName, setShipmentStatusName] = useState(
    t(DispensingLanguage[lan][menukey]["All Status"])
  );

  let All_Shipment_Status_list = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All Status"]),
  };
  const getShipmentStatusList = [All_Shipment_Status_list].concat(
    get_Shipment_Status_List
  );
  const [currShipmentStatusId, setCurrShipmentStatusId] = useState(0);

  const handleShipmentStatusListChange = (event, newValue) => {
    let rowId = "";
    let rowname = "";
    if (newValue == null) {
      rowId = "";
      rowname = "";
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }

    refreshAllData(
      activeProductGroupData.id,
      activeFundingSourceData.id,
      currOwnerTypeId,
      rowId
    );
    // refreshAllData(activeFundingSourceData.id,rowId);
    setCurrShipmentStatusId(rowId);
    setShipmentStatusName(rowname);
  };
  // const [dataItems, setDataItems] = useState([]);

  const refreshAllData = (
    ItemGroupId,
    FundingSourceId,
    OwnerTypeId,
    ShipmentStatusId
  ) => {
    console.log(
      "Refreshing data with:",
      ItemGroupId,
      FundingSourceId,
      OwnerTypeId,
      ShipmentStatusId
    );
    let params = {
      menukey: menukey,
      ItemGroupId: ItemGroupId,
      FundingSourceId: FundingSourceId,
      OwnerTypeId: OwnerTypeId,
      ShipmentStatusId: ShipmentStatusId,
      lan: lan,
    };
    getShipmentsTable({ queryKey: [{ ...params, action: "getDataList" }] });
  };

  const { mutate: getShipmentsTable } = useMutation(api.getShipments, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
  });

 

  // --------------For Funding Source & Product Group Dependency Start------------------------

  //Product Group combo list
  const [ProductGroupList, setProductGroupListData] = useState([]);
  const [activeProductGroupData, setProductGroupData] = useState({
    id: ProductGroupList.length > 0 ? ProductGroupList[0].id : 0,
    name: ProductGroupList.length > 0 ? ProductGroupList[0].name : "",
  });

  React.useEffect(() => {
    if (activeProductGroupData.id == 0) {
      getProductGroupData();
    }
  }, [activeProductGroupData.id]);

  const handleProductGroupChange = (event, newValue) => {
    setProductGroupData(newValue);
    getFundingSourceData(newValue.id);
  };

  const getProductGroupData = () => {
    let FacilitylevelParam = {
      action: "getProductGroupforreportList",
      RoleId: 1,
      //  UserId: UserId,
      IsAll: 0,
      menukey: menukey,
      lan: lan,
    };
    cProductList(FacilitylevelParam);
  };

  const { mutate: cProductList } = useMutation(api.getProductListData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setProductGroupListData(data.data.datalist);

        setProductGroupData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getFundingSourceData(data.data.datalist[0].id);
      }
    },
  });

  ////Funding Source combo list
  const [FundingSourceList, setFundingSourceListData] = useState([]);
  const [activeFundingSourceData, setFundingSourceData] = useState({
    id: -1,
    name: FundingSourceList.length > 0 ? FundingSourceList[0].name : "",
  });

  const getFundingSourceData = (ItemGroupId) => {
    let FacilitylevelParam = {
      action: "getFundingSourceforReport",
      RoleId: 1,
      //  UserId: UserId,
      ItemGroupId: ItemGroupId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(
    api.getFundingSourceforReport,
    {
      onSuccess: (data, params) => {
        if (data.status == 200) {
          setFundingSourceListData(data.data.datalist);

          setFundingSourceData({
            id: data.data.datalist.length ? data.data.datalist[0].id : 0,
            name: data.data.datalist.length ? data.data.datalist[0].name : "",
          });

          refreshAllData(
            params.ItemGroupId,
            data.data.datalist.length ? data.data.datalist[0].id : -1,
            currOwnerTypeId,
            currShipmentStatusId
          );

          //  getFacilityData(
          //    params.ItemGroupId,
          //    data.data.datalist.length ? data.data.datalist[0].id : 0
          //  );
        }
      },
    }
  );

  // Funding Change
  const handleFundingSourceChange = (event, newValue) => {
    setFundingSourceData(newValue);
    refreshAllData(
      activeProductGroupData.id,
      newValue.id,
      currOwnerTypeId,
      currShipmentStatusId
    );

    //getFacilityData(activeProductGroupData.id, newValue.id);
  };
  let params = {
    menukey: menukey,
    action: "getDataList",
    ItemGroupId:  activeProductGroupData?.id,
    FundingSourceId: activeFundingSourceData?.id,
    OwnerTypeId: currOwnerTypeId,
    ShipmentStatusId: currShipmentStatusId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };
  const { refetch } = useQuery([params], api.getShipments, {
    onSuccess: (data) => {
      // localStorage.setItem("ProductDataNextPrev", JSON.stringify(data));

      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const { mutate } = useMutation(api.deleteShipment, {
    onSuccess: (data) => {
      if (data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        refetch();
        queryClient.getQueriesData("shipment");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });
  // --------------For Funding Source & Product Group Dependency End------------------------

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ShipmentListExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&ItemGroupId=" +
        activeProductGroupData.id +
        "&FundingSourceId=" +
        activeFundingSourceData.id +
        "&OwnerTypeId=" +
        currOwnerTypeId +
        "&ShipmentStatusId=" +
        currShipmentStatusId +
        "&ProductGroupName=" +
        activeProductGroupData.name +
        "&FundingSourceName=" +
        activeFundingSourceData.name +
        "&ShipmentStatusName=" +
        ShipmentStatusName +
        "&OwnerTypeName=" +
        OwnerTypeName +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;

    row._row.parent.rows.forEach((rowss, i) => {
      if (row._row.data.id == rowss.data.id) {
        localStorage.setItem("ProductDataIndex", JSON.stringify(i));

        editProduct(rowData);
        return;
      }
    });
  };

  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bKeyItem" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "5%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "GroupName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Funding Source"],
      width: 200,
      field: "FundingSourceName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Shipment Status"],
      field: "ShipmentStatusDesc",
      headerFilter: true,
      width: 160,
    },
    {
      title: DispensingLanguage[lan][menukey]["Shipment Date"],
      field: "ShipmentDate",
      headerFilter: true,
      width: 210,
      formatter: function (cell) {
        let value = cell.getValue();
        if (value != null) return moment(value).format("DD/MM/YYYY");
        else return "";
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Owner Type"],
      field: "OwnerTypeName",
      headerFilter: true,
      width: 160,
    },
    {
      title: DispensingLanguage[lan][menukey]["Shipment Quantity"],
      field: "Qty",
      width: 180,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      width: 100,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editProduct = (data) => {
    let route = `shipment/edit/${data.id}`;
    props.history.push(route);
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            dataItems.forEach((row, i) => {
              if (rowData.id == row.id) {
                localStorage.setItem("ProductDataIndex", JSON.stringify(i));
                editProduct(rowData);
                return;
              }
            });
          }}
        />
        <DeleteOutline
          onClick={() => {
            modalPopupDelete(mutate, rowData.id);
          }}
        />
      </>
    );
  }

  /* start save/clear setting layout handler */
  const saveLayoutSettingsHandler = () => {
    const singleLayoutSettingsParam = {
      filter: {
        // ItemGroupIdFilter: currProductGroupId,
        // fundingSourceFilter: currFundingSourceId,
        ownerTypeFilter: currOwnerTypeId,
        shipmentStatusFilter: currShipmentStatusId,
      },
    };

    saveLayoutSettings(menukey, singleLayoutSettingsParam);

    props.openNoticeModal({
      isOpen: true,
      msg: t(DispensingLanguage[lan][menukey]["Layout saved successfully"]),
      msgtype: 1,
    });
  };

  const clearLayoutSettingsHandler = () => {
    clearLayoutSettings(menukey);

    props.openNoticeModal({
      isOpen: true,
      msg: t(DispensingLanguage[lan][menukey]["Layout cleared successfully"]),
      msgtype: 1,
    });
  };

  /* end save/clear setting layout handler */

  return (
    <>
      <div className="sw_topmargin">
        <div className="sw_makeStyles_tableContainer">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Shipment"]}
                </div>
              </div>
            </Grid>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={12} sm={9}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroupList"
                          disableClearable
                          options={ProductGroupList}
                          value={activeProductGroupData}
                          onChange={(event, newValue) =>
                            handleProductGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Product Group"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FundingSourceList"
                          disableClearable
                          options={FundingSourceList}
                          value={activeFundingSourceData}
                          onChange={(event, newValue) =>
                            handleFundingSourceChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Funding Source"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="getOwnerTypeList"
                          disableClearable
                          // disabled={userId == 1 ? false : true}
                          options={getOwnerTypeList}
                          onChange={(event, newValue) =>
                            handleOwnerListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            getOwnerTypeList[
                              getOwnerTypeList.findIndex(
                                (getOwnerTypeList) =>
                                  getOwnerTypeList.id == currOwnerTypeId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Owner Type"]
                              }
                              variant="standard"
                              hiddenLabel
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="getShipmentStatusList"
                          disableClearable
                          // disabled={userId == 1 ? false : true}
                          options={getShipmentStatusList}
                          onChange={(event, newValue) =>
                            handleShipmentStatusListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            getShipmentStatusList[
                              getShipmentStatusList.findIndex(
                                (getShipmentStatusList) =>
                                  getShipmentStatusList.id ==
                                  currShipmentStatusId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Shipment Status"
                                ]
                              }
                              variant="standard"
                              hiddenLabel
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {/* </Grid>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="getShipmentStatusList"
                          disableClearable
                          disabled={userId == 1 ? false : true}
                          options={getShipmentStatusList}
                          onChange={(event, newValue) =>
                            handleShipmentStatusListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            getShipmentStatusList[
                              getShipmentStatusList.findIndex(
                                (getShipmentStatusList) =>
                                getShipmentStatusList.id == currShipmentStatusId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Shipment Status"
                                ]
                              }
                              variant="standard"
                              hiddenLabel
                            />
                          )}
                        />
                      </FormControl>
                    </Grid> */}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="d-flex justify-content-end align-items-center sw_btn_control">
                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2"
                          onClick={() => {
                            props.history.push("/shipment/add");
                          }}
                        >
                          {DispensingLanguage[lan][menukey]["Add Shipment"]}
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("print")}
                        >
                          <i className="fas fa-print"></i>
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          <div className="layoutsettings-container">
            <div className="text-right icon-container">
              <i
                onClick={saveLayoutSettingsHandler}
                style={{ cursor: "pointer" }}
                className="far fa-save"
                aria-hidden="true"
                title={t(
                  DispensingLanguage[lan][menukey]["Save Layout Settings"]
                )}
              ></i>

              <i
                onClick={clearLayoutSettingsHandler}
                style={{ cursor: "pointer" }}
                className="far fa-stop-circle"
                aria-hidden="true"
                title={t(
                  DispensingLanguage[lan][menukey]["Clear Layout Settings"]
                )}
              ></i>
            </div>
          </div>

          <div className="sw_relative">
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height={450}
                rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
                options={{
                  // groupBy: "FundingSourceName",
                  columnCalcs: "both",
                }}
                //initialSort={[{ column: "GroupName", dir: "desc" }]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipment;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
