import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";

import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import * as api from "../../../actions/api";
// react-tabulator
import "react-tabulator/lib/styles.css"; 
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator } from "react-tabulator";
import moment from "moment";
import "moment/locale/fr"; 
import { useTranslation } from "react-i18next";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const userId = UserInfo == 0 ? "" : UserInfo[0].id;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;



const EditMonthClose = (props) => {
// console.log(props)
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, i18n } = useTranslation()

  // const FacilityName = localStorage.getItem("FacilityName");
  // const SourceList = JSON.parse(localStorage.getItem("SourceList"));
  const menukey = "month-close";

  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  let tblRef = null;
  const classes = useStyles();
  // const { id } = useParams();
  const { FacilityId, MonthId,currYearId } = useParams();

  console.log('FacilityId:', FacilityId);
  console.log('MonthId:', MonthId);
  console.log('currYearId:', currYearId);



  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "MonthCloseReportList",
    lan: lan,
    FacilityId: FacilityId,
    MonthId: MonthId,
    Year: currYearId,
  
  };

  const { data: scanloglist } = useQuery(
    [params],
    api.getMonthCloseViewReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const MosTypelist = JSON.parse(localStorage.getItem("MosTypelist"));

  const hasLeastOneValue = (valueOne, valueTwo) => {
    const hasValueOne = valueOne === 0 ? true : Boolean(valueOne)
    const hasvalueTwo = valueTwo === 0 ? true : Boolean(valueTwo)
  
    if (hasValueOne || hasvalueTwo) {
      return true
    }
    return false
  
  }

const hasBothValues = (valueOne, valueTwo) => {
  const hasValueOne = valueOne === 0 ? true : Boolean(valueOne)
  const hasValueTwo = valueTwo === 0 ? true : Boolean(valueTwo)

  if (hasValueOne && hasValueTwo) {
    return true
  }
  return false

}

  // ===================== TEST END =====================

  /* ====Start of Excel Export Code=== */
  // const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  // const PrintPDFExcelExportFunction = (reportType) => {
  //   let formatedStartDate = moment(selectedStartDate).format("YYYY-MM-DD");
  //   let formatedEndDate = moment(selectedEndDate).format("YYYY-MM-DD");
  //   let finalUrl =
  //     EXCEL_EXPORT_URL +
  //     "report/stock_status_details_print_pdf_excel_server.php";

  //   window.open(
  //     finalUrl +
  //       "?action=StockCardExcelExport" +
  //       "&reportType=" +
  //       reportType +
  //       "&menukey=" +
  //       menukey +
  //       "&lan=" +
  //       localStorage.getItem("LangCode") +
  //       "&startDate=" +
  //       formatedStartDate +
  //       "&endDate=" +
  //       formatedEndDate +
  //       "&FacilityId=" +
  //       FacilityId +
  //       "&ItemNo=" +
  //       id +
  //       "&FacilityName=" +
  //       FacilityName
  //   );
  // };
  /* ====End of Excel Export Code=== */

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product_Code"],
      field: "ItemCode",
      headerSort: false,
       headerFilter: true,
        formatter: "textarea",
     
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Product Group"],
    //   field: "GroupName",
    //   headerSort: false,
    //   hozAlign: "left",
    //   headerHozAlign: "left",
    //   headerFilter: true,
    //   formatter: "textarea",
    //  // width: 100,
    // },
    {
      title: DispensingLanguage[lan][menukey]["Designation"],
      field: "ItemName",
      headerSort: false,
      headerFilter: true,
      formatter: "textarea",
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Stock"],
      field: "OpB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive"],
      field: "RecB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 100,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue"],
      field: "Issue",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 100,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Dispense"],
      field: "Consumption",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 100,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment"],
      field: "AdjB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
     
    },
    {
      title: DispensingLanguage[lan][menukey]["AdjustmentMin"],
      field: "AdjC",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
     
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "Stock",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
     
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Risk of Expiry (less than 3 months)"],
    //   field: "ExpiryQty",
    //   headerSort: false,
    //   width: 100,
    //    hozAlign: "right",
    //    headerHozAlign: "right",
     
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Expiry_Date"],
    //   field: "ExpiryDate",
    //   headerSort: false,
    //   hozAlign: "left",
    //   headerHozAlign: "left",
     
    // },
    {
      title: DispensingLanguage[lan][menukey]["Stockout Days"],
      field: "StockoutDays",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 120,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 80,
      formatter: function (cell, formatterParams) {
        // MosTypelist
        let value = cell.getValue();
        let cellColor = "transparent";
        const rowData = cell.getRow().getData();

        

        const hasMaxOrMinQty = hasLeastOneValue(rowData["AdjB"], rowData["AdjC"] );
        const hasAmcAndQty = hasBothValues(rowData["AMC"], rowData["Stock"]);
        const NonDispensing = DispensingLanguage[lan][menukey]["Dispense"];

        if ((value == "" || value == null)&&hasAmcAndQty=='') {
         return value = '';
        } 
 

        MosTypelist.forEach((row, i) => {
          if ((value >= row["MinMos"]) && (value <= row["MaxMos"]) ) {
            if (!hasAmcAndQty) {
              cellColor = '#a2a09d'; 
            } else {
              cellColor = row["ColorCode"];
            }
          }
        });


        cell.getElement().style.backgroundColor = cellColor;

       //// if (hasMaxOrMinQty) {
          return value;
       // }*/

      },

    },
   
    
  ];




 

  // useEffect(() => {
  //   if (tblRef == null) return;
  //   setLotData2(lotData);
  // }, [lotData]);



  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">{t(DispensingLanguage[lan][menukey]["LMIS Month Close Report"])}</div>
              <div className="float-right sw_btn_control">
                <Button
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/month-close")}>
                  {DispensingLanguage[lan][menukey]["RETURN TO LIST"]}
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        {/* data grid*/}
           {/* start of legend */}
           <Card className="sw_card sw_filter_card">
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div className="mylegend_area">
                    {MosTypelist.map((item, index) => {
                      return (
                        <div key={index} className="my_legend">
                          <div
                            className="mylegend_color"
                            style={{ background: item.ColorCode }}
                          ></div>
                          <div className="mylegend_label">{item.name}</div>
                          <div className="mylegend_val">
                            {item.MosLabel}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        <Grid container spacing={3}>
        
          {/* end of legend */}
          {/* new row */}
          <Grid item xs={12} sm={12}>
            <div className={classes.root}>
              <ReactTabulator
                ref={(r) => (tblRef = r)}
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height={600}
                options={{
                  groupBy: ["GroupName"],

                  columnCalcs: "both",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EditMonthClose;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  th: {
    "border-left": "1px solid red",
  },
});
