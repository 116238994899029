import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../../actions/api";
import ProfileParametersEntryFormData from "./ProfileParametersEntryFormData.js";


const AddProfileParameters = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "profile-parameters";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo===0?'': UserInfo[0].name;

  const [formData, setFormData] = useState({
    ItemGroupId: "",
    ParamName: "",
    ParamNameFrench: "",
    BShow : false,
    
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["ProfilePara", id],
    () =>
      api.getprofilelist(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.saveProfileParamiter, {
    onSuccess: (data) => {

      if(data.status===200) {

         api.getDispensingLanguage().then((response) => {
           localStorage.setItem(
             "DispensingLanguage",
             JSON.stringify(response.datalist)
           );
         })

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("profile-parameters");
      props.history.push("/profile-parameters");

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }

    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ItemGroupId: "",
      ParamName: "",
      ParamNameFrench: "",
      BShow : false,
     
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ItemGroupId","ParamName","ParamNameFrench"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
        // if (formData[field] != 0) {
        //   errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        //   isValid = false;
        //   }
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //swal("Success!", "", "success");
    }
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;
      setFormData(data);
      setErrorObject({ ...errorObject, [name]: "" });
      // api.dirtyModeEnable();
    };
    //====== End Choosen Dropdown===========

  return (
    <>
      <ProfileParametersEntryFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        handleChangeChoosenProps={handleChangeChoosenProps}
        {...props}
      />
    </>
  );
};

export default AddProfileParameters;
