import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { DeleteOutline } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { cellFocusEditor } from "../../../services/Common";

// import MomentUtils from "@date-io/moment";
import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import { useMutation } from "react-query";
import * as api from "../../../actions/api";

const DispenserFormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "repacking";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const UsersListByFacility = JSON.parse(
    localStorage.getItem("UsersListByFacility")
  );

  const classes = useStyles();

  let ReceiveDetailsRef = null;
  // let adjId = null;
  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const [bIsPositive, setbIsPositive] = useState();
  const [IssueToFacility, setIssueToFacility] = useState([]);
  const [IssueToFacilityVal, setIssueToFacilityVal] = useState(false);
  const [firstIssueToFacility, setfirstIssueToFacility] = useState("");

  const Itemlist = JSON.parse(localStorage.getItem("Itemlist"));

  const [currProductRepackingTo, setcurrProductRepackingTo] = useState("");

  const [FromProductQty, setFromProductQty] = useState(0);
  const [ToProductQty, setToProductQty] = useState(0);

  //==== Start Choosen Dropdown========
  const [chosenValues, setChosenValues] = useState({
    ProductRepackingTo: { id: "", name: "" },
    ProductRepackingFrom: { id: "", name: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData["ProductRepackingTo"] =
      Itemlist[
      Itemlist.findIndex(
        (Itemlist_List) =>
          Itemlist_List.id == props.formData.ProductRepackingTo
      )
      ];
    chosenValuesData["ProductRepackingFrom"] =
      Itemlist[
      Itemlist.findIndex(
        (Itemlist_List) =>
          Itemlist_List.id == props.formData.ProductRepackingFrom
      )
      ];
    setChosenValues(chosenValuesData);
  }, [props.formData.ProductRepackingTo, props.formData.ProductRepackingFrom]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    setcurrProductRepackingTo(value);
    props.handleChangeChoosenProps(name, value);
  };

  //==== End Choosen Dropdown========

  /* ====Start of Print Excel Many Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl = EXCEL_EXPORT_URL + "report/repacking_invoice_list_print.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TransactionId=" +
      props.formData.TransactionId +
      "&FacilityId=" +
      FacilityId +
      "&FacilityName=" +
      FacilityName +
      "&TimeStamp=" +
      Date.now() +
      "&isPositive=" +
      props.isPositive
    );
  };

  const ExcelMany = () => {
    let finalUrl = EXCEL_EXPORT_URL + "report/repacking_invoice_list_excel.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TransactionId=" +
      props.formData.TransactionId +
      "&FacilityId=" +
      FacilityId +
      "&FacilityName=" +
      FacilityName +
      "&TimeStamp=" +
      Date.now() +
      "&isPositive=" +
      props.isPositive
    );
  };

  /* ====End of Excel Export Code=== */

  const ReceiveDetailsColumns = [
    { title: "id", field: "SKU", visible: false },
    { title: "id", field: "TransactionItemId", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Current Stock"],
      field: "CurrentStockQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      visible: bIsPositive,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "Quantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: [
        "integer",
        "min:0",
        function (cell, value, parameters) {
          if (value == "") return true;
          if (!props.isPositive) return true;
          const balance = cell.getRow().getCell("CurrentStockQty").getValue();
          const validstatus = parseInt(value) <= parseInt(balance);
          return validstatus;
        },
      ],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.UnitPrice * curcellval;
        pricecell.setValue(totprice);
        calculateFromAndToTotalQty();
      },
    }, //
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 100,
    },

    {
      title: DispensingLanguage[lan][menukey]["Unit Price (CFA)"],
      field: "UnitPrice",
      width: 100,
      visible: false,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["float", "min:0"],

      editor: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Line Total (CFA)"],
      field: "LineTotal",
      width: 110,
      formatter: "money",
      visible: false,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
    },
    {
      title: DispensingLanguage[lan][menukey]["Comment"],
      field: "Remarks",
      width: 110,
      hozAlign: "left",
      visible: false,
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",

      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue();
        const rowData = cell.getRow().getData();

        if (cellValue == null || cellValue == "") {
        } else {
          cell.getRow().getElement().style.backgroundColor = "transparent";
        }

        return cellValue;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      visible: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const calculateFromAndToTotalQty = () => {
    let fromQty = 0;
    let toQty = 0;

    let fromPacksize = 1;
    let toPacksize = 1;
    // console.log("manyTableData:", manyTableData);

    manyTableData.forEach((row, i) => {
      if (row.Quantity != "" && row.Quantity != null && row.IsPositive == -1) {
        fromQty += parseInt(row.Quantity);
      }

      if (
        row.PacksizeName != "" &&
        row.PacksizeName != null &&
        row.IsPositive == -1
      ) {
        fromPacksize = parseInt(row.PacksizeName);
      }

      if (row.PacksizeNameTo != "" && row.PacksizeNameTo != null) {
        toPacksize = parseInt(row.PacksizeNameTo);
      }
    });
    // console.log("fromPacksize: ", fromPacksize);
    // console.log("fromQty: ", fromQty);

    // console.log("toPacksize: ", toPacksize);
    toQty = parseInt((fromQty * fromPacksize) / toPacksize);
    // console.log("toQty: ", toQty);

    setFromProductQty(fromQty);
    setToProductQty(toQty);
  };

  const onPostBtnClick = () => {
    setbStockUpdate(true);
    props.handlePost();
  };

  const onSaveUpdateBtnClick = () => {
    props.setUpdatedDetailsGridDataHandler({
      datatype: "TableData",
      data: ReceiveDetailsRef.table.getData(),
    });
    //  props.handleUpdate();
  };

  const deleteRepackingInv = (data) => {
    let newarray = manyTableData.filter((element) => element != data);
    setManyTableData(newarray);
    props.deleteRepackingInv(data);
  };

  // const [pickListMethod, setPickListMethod] = useState();

  const { mutate } = useMutation(api.getWarehouseList, {
    onSuccess: (data) => {
      if (data.status == 200) {
        let firstIssueToFacility =
          data.data.datalist.length == 1 ? data.data.datalist[0].id : "";
        setfirstIssueToFacility(firstIssueToFacility);
        props.formData.IssueToFacility = firstIssueToFacility;

        props.setUpdatedDetailsGridDataHandler({
          datatype: "FormData",
          IssueToFacility: props.formData,
        });

        setIssueToFacility(data.data.datalist);
      }
    },
  });

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;

    // const cellValue = props.cell._cell.value || "Edit | Show";
    if (bStockUpdate == 0)
      return (
        <>
          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteRepackingInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }
  let bfalse = true;
  useEffect(() => {
    if (ReceiveDetailsRef == null) return;

    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
      calculateFromAndToTotalQty();
    }

    setbStockUpdate(props.bStockUpdate == 1 ? true : false);

    setbIsPositive(props.isPositive);
    if (!props.addProductForm)
      if (props.pickListMethod != undefined) {
        //setswipeIssueToFacility
        bfalse = false;
        setIssueToFacilityVal(props.formData.IssueToFacility);
        let params = {
          action: "getWarehouseFacility",
          menukey: menukey,
          FacilityId: FacilityId,
          PickListMethod: props.pickListMethod,
        };
        mutate(params);
      }
  }, [
    props.ManyTableData,
    props.bStockUpdate,
    props.isPositive,
    props.pickListMethod,
  ]); //

  useEffect(() => {
    //  setIssueToFacilityVal(true);
    console.log(IssueToFacility);
    //  console.log("gjsdfhhfksdfhdgfhfdsfshjdf=="+props.formData.IssueToFacility+'lkp'+IssueToFacilityVal);
    props.formData.IssueToFacility = IssueToFacilityVal;
    bfalse = true;
  }, [IssueToFacility]); //

  const handleReset = () => {
    setManyTableData([]);
    props.handleReset();
  };

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Repacking - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      // onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/repacking")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick} //() => props.handleUpdate()
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/repacking")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Adj. Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        // showTodayButton={true}
                        value={
                          props.formData.TransactionDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Adj. Invoice No"]
                      }
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Prepared By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.PreparedBy}
                        // helperText={props.errorObject.OrderBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      //disabled={bCompleted==1}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UsersListByFacility-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Approved By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.ApprovedBy}
                        // helperText={props.errorObject.ApprovedBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="ApprovedBy"
                        name="ApprovedBy"
                        value={props.formData.ApprovedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      //disabled={bCompleted==1}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UsersListByFacility-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.ApprovedBy}>
                        {props.errorObject.ApprovedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={5} sm={5}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        //disableClearable
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                        id="ProductRepackingFrom"
                        options={Itemlist}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "ProductRepackingFrom",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValues["ProductRepackingFrom"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ProductRepackingFrom}
                            label={
                              DispensingLanguage[lan][menukey][
                              "Product Repacking From"
                              ] + " *"
                            }
                            variant="standard"
                            error={props.errorObject.ProductRepackingFrom}
                            helperText={props.errorObject.ProductRepackingFrom}
                            id="ProductRepackingFrom"
                            name="ProductRepackingFrom"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={5} sm={5}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        //disableClearable
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                        id="ProductRepackingTo"
                        options={Itemlist}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "ProductRepackingTo",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValues["ProductRepackingTo"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ProductRepackingTo}
                            label={
                              DispensingLanguage[lan][menukey][
                              "Product Repacking To"
                              ] + " *"
                            }
                            variant="standard"
                            error={props.errorObject.ProductRepackingTo}
                            helperText={props.errorObject.ProductRepackingTo}
                            id="ProductRepackingTo"
                            name="ProductRepackingTo"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid item xs={12} sm={12}>
                        <Grid container className="mb-2">
                          <Grid item xs={4} sm={4}>
                            {" "}
                          </Grid>

                          <Grid item xs={3} sm={3}>
                            <div>
                              <span>
                                {"From product quantity"}
                                {": "}
                                <b>{FromProductQty}</b>
                              </span>
                            </div>
                          </Grid>

                          <Grid item xs={3} sm={3}>
                            <span>
                              {"To product quantity"}
                              {": "}
                              <b>{ToProductQty}</b>
                            </span>
                          </Grid>

                          <Grid item xs={3} sm={3}></Grid>
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
