import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { 
  Grid, 
  Button,
  FormControl,
  TextField,
  Typography,
  Card,
  CardContent
 } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";


//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
const ProfileParameters = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "profile-parameters";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo === 0 ? "" : UserInfo[0].name;

  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});


// start Group id filter

  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Item_label = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All Product Group"]),
  };
  const GroupProductList = [All_Item_label].concat(ProductGroup);
  const [currGroupId, setGroupId] = useState('0');

  const [currProductName, setProductName] = useState([]);

  const handleGroupListChange = (event, newValue) => {
    let rowId = "";
    let IssueName = "";
    if (newValue == null) {
      rowId = "";
      IssueName = "";
    } else {
      rowId = newValue.id;
      IssueName = newValue.name;
    }
    if (rowId !== currGroupId) {
      setGroupId(rowId);
      setProductName(IssueName);
    }
  };


let params = {
  menukey: menukey,
  action: "getProfileList",
  ItemGroupId: currGroupId,
  page_limit: JSON.stringify(100),
  page_number: JSON.stringify(0 + 1),
  search_key: "",
};

const { refetch } = useQuery([params], api.getProfileParameters, {
  onSuccess: (data) => {
    setDataItems(data);
    //setLoading(false);
  },
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 0, //1000 * 60 * 60 * 24,
});

  const { mutate: deleteApi } = useMutation(api.deleteProfile, {
    onSuccess: (data) => {
      if (data.data.status === 200) {
        api.getDispensingLanguage().then((response) => {
          localStorage.setItem(
            "DispensingLanguage",
            JSON.stringify(response.datalist)
          );
        });

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        refetch();
        queryClient.getQueriesData("ProfileParamiter");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=ProfileParamiterExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" +
      Date.now() +
      "&ItemGroupId=" +
      currGroupId +
      "&GroupName=" +
      currProductName
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editmenu(rowData);
  };

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 80,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Product Group"],
    //   headerFilter: true,
    //   field: "GroupName",
    //   width:180
    // },
    {
      title: DispensingLanguage[lan][menukey]["Parameter Name"],
      headerFilter: true,
      field: "ParamName",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Parameter Name_French"],
      headerFilter: true,
      field: "ParamNameFrench",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 133,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editmenu = (data) => {
    let route = `profile-parameters/edit/${data.id}`;
    props.history.push(route);
  };



  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editmenu(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            modalPopupDelete(deleteApi, rowData.id);
           // deletemenu(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-packSize">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Profile Parameters Entry"]}
              </div>
            </div>
          </Grid>
        </div>


        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="GroupProductList"
                    disableClearable
                    options={GroupProductList}
                    onChange={(event, newValue) =>
                      handleGroupListChange(event, newValue)
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      GroupProductList[
                        GroupProductList.findIndex(
                        (GroupProductList) => GroupProductList.id === currGroupId
                      )
                      ]
                    }
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          DispensingLanguage[lan][menukey]["Product Group"]
                        }
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={10} sm={10}>
                <div className="float-right sw_btn_control">
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/profile-parameters/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add Profile"]}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/profile-parameters/shortorder");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Change Order"]}
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i className="fas fa-print"></i>
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}


        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={600}
              options={{
                groupBy: ["GroupName"],
                columnCalcs: "both",
              }}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileParameters;

const useStyles = makeStyles({
  packSizePageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
