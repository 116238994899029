import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, Button, Paper, } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// Highcharts component
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

// import swal from "sweetalert";
// import * as Service from "../../../services/Service";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { useParams } from "react-router-dom";

//import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// import SwipeableViews from "react-swipeable-views";
// import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

// import { useTranslation } from "react-i18next";

import { useMutation, useQuery, } from "react-query";

import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, } from "react-tabulator";
// import { DeleteOutline, Edit } from "@material-ui/icons";

// import MomentUtils from "@date-io/moment";
import moment from "moment";

import { fr, enGB } from 'date-fns/locale'; // import French and English locale
import 'moment/locale/fr'; // import Moment.js French locale

import NextPrevBtn from '../../../services/NextPrevBtn';
import { cellFocusEditor } from "services/Common";

const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const userId = UserInfo == 0 ? '' : UserInfo[0].id;
const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

let isDirty=false;
const EditProduct = (props) => {

  const StockData = JSON.parse(
    localStorage.getItem("StockData")
  );

  const StockDataIndex = JSON.parse(
    localStorage.getItem("StockDataIndex")
  );



  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  // const StartDate = localStorage.getItem("StartDate");
  const FacilityName = localStorage.getItem('FacilityName');
  const SourceList = JSON.parse(localStorage.getItem("SourceList"));
  const menukey = "stock-status";

  //console.log('props: ', props);

  moment.locale(lan == 'en_GB' ? 'enGB' : 'fr');

  let tblRef = null;
 

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  // const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // const { t, i18n } = useTranslation();

  const { id } = useParams();
  // const queryClient = useQueryClient();

  const [MasterData, setMasterData] = useState({});

  const [lotData, setLotData] = useState([]);

  const [lotData2, setLotData2] = useState([]);
  const [option_linechart, setOption_linechart] = useState([]);


  const { data: data ,refetch:LotsData} = useQuery(
    ["stock-status", id],
    () =>
      api.getLotDataView(id).then((res) => {
        console.log(res, '======')

        setMasterData(res.MasterData[0]);
        setLotData(res.LotData);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const [stockCardData, setStockCardData] = useState([]);

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format('YYYY-MM-DD')
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };
  const handleStartDateChangeChart = (date) => {
    setSelectedStartDateChart(date);
  };


  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );



  const [selectedStartDateChart, setSelectedStartDateChart] = React.useState(
    moment().subtract(30, "days").format('YYYY-MM-DD')
  );


  const [selectedEndDateChart, setSelectedEndDateChart] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  let params1 = {
    "menukey": menukey, "FacilityId": FacilityId,
    action: 'getStockCardDataView', "ItemNo": id,
    "startDate": selectedStartDate, "endDate": selectedEndDate
  };
  const { data: scData, refetch } = useQuery([params1],
    api.getStockCardDataView,
    {
      onSuccess: (data) => {

        setStockCardData(data);


      }
    }, {
    enabled: Boolean(id),
  }
  );


  // ===================== TEST START =====================
  const { mutate: SaveLocation } = useMutation(api.UpdateLocation, {
    onSuccess: (data) => {
      isDirty=false;
      onSetDisable();
      if (data.status == 200) {
        LotsData();

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        //swal("Success!", "", "success");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });


  const handleSave = async (e) => {
    let FinalData = {
      action: "ProductLocationUpdate",
      ManyJsonSave: tblRef.table.getData(),
      menukey: menukey,
      FacilityId: FacilityId,
      ItemNo: id,
      lan: lan,
      UserName: UserName
    };
    console.log(FinalData.ManyJsonSave, '======')
    SaveLocation(FinalData);
  };

    // ===================== TEST END =====================

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedStartDate = moment(selectedStartDate).format('YYYY-MM-DD');
    let formatedEndDate = moment(selectedEndDate).format('YYYY-MM-DD');
    let finalUrl = EXCEL_EXPORT_URL + "report/stock_status_details_print_pdf_excel_server.php";

    window.open(
      finalUrl + "?action=StockCardExcelExport" + "&reportType=" + reportType + "&menukey=" + menukey
      + "&lan=" + localStorage.getItem("LangCode")
      + "&startDate=" + formatedStartDate
      + "&endDate=" + formatedEndDate
      + "&FacilityId=" + FacilityId
      + "&ItemNo=" + id
      + "&FacilityName=" + FacilityName

    );
  };
  /* ====End of Excel Export Code=== */


  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "sl",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["lot No"],
      field: "LotNo",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpDate",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Location"],
      field: "Location",
      hozAlign: "left",
      headerHozAlign: "left",
      // validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable text-field-editable",
      editor: cellFocusEditor,

      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();

        // Start Dirty
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }
        // End Dirty

       return curcellval
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "LotQty",
      hozAlign: "right",
      headerHozAlign: "right",
      //  hozAlign: "left",
      // headerHozAlign: "left",
    },
  ];

  const onSetDisable = () => {  
    if(isDirty==true){   
      //document.getElementById("is_dirty_message").classList.remove("dnone"); 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }
    else{  
     //document.getElementById("is_dirty_message").classList.add("dnone"); 
     document.getElementById("master_dirty_message").classList.add("dnone");
    }    
  }

  const columns1 = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "sl",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["TransactionDate"],
      width: 130,
      field: "TransactionDate", formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return (value == null ? '' : moment(value).format("DD/MM/YYYY"));
      }
    },
    {
      title: DispensingLanguage[lan][menukey]["Origin/Destination"],
      field: "Receiver",
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      field: "Invoice",
    },
    {
      title: DispensingLanguage[lan][menukey]["Type"],
      field: "Type",
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive"],
      field: "ReceivedQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue"],
      field: "IssuedQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Balance"],
      field: "Balance",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["lot No"],
      field: "LotNo",
      hozAlign: "left",
      headerHozAlign: "left",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "Exp",
      hozAlign: "left",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer Name"],
      field: "ManufacturerName",
      hozAlign: "left",
      headerHozAlign: "left",
    },

  ];





  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };
  const handleEndDateChangeChart = (date) => {
    setSelectedEndDateChart(date);
  };

  let params2 = {
    "menukey": menukey, "FacilityId": FacilityId,
    action: 'getChartDataView', "ItemNo": id,
    "startDate": selectedStartDateChart, "endDate": selectedEndDateChart
  };
  const { data: getChartDataView } = useQuery([params2],
    api.getChartDataView,
    {
      onSuccess: (data) => {

        let option_linechart = {
          chart: {
            //type: "spline",
            type: "line",
          },
          title: {
            text: "",
          },
          yAxis: {
            title: {
              text: "",
            },
          },
          xAxis: {
            categories: data?.item_name,
          },
          credits: {
            enabled: false,
          },
          //   tooltip: {
          //     split: true,
          //     distance: 30,
          //     padding: 5
          // },
          tooltip: {
            shared: true
          },
          series: [
            {
              name: "MIN",
              data: data?.dataValue[0],//[150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150],
              color: "#b60024",
            },
            {
              name: "MAX",
              data: data?.dataValue[1],//[300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300],
              color: "#6c6463",
            },
            {
              name: "Stock",
              data: data?.dataValue[2],//[400, 150, 250, 360, 401, 258, 415, 362, 456, 145, 258, 180],
              color: "#002f6c",
            },
          ],
        };

        setOption_linechart(option_linechart);

      }
    }, {
    enabled: Boolean(id),
  }
  );

  // const [formData, setFormData] = useState({
  //   productName: "",
  //   productCode: "",
  //   productGroup: "",
  //   price: "",
  //   key: false,
  //   productId: "",
  // });
  // const [groupData, setGroupData] = useState([]);


  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  // const handleCheck = (e) => {
  //   const { name, checked } = e.target;
  //   setFormData({ ...formData, [name]: checked });
  // };

  // const handleReset = () => {
  //   setFormData({
  //     productName: "",
  //     productCode: "",
  //     productGroup: "",
  //     price: "",
  //     key: false,
  //   });
  // };

  // const handleSubmit = async (e) => {
  //   swal("Success!", "", "success");
  // };

  useEffect(() => {
    if (tblRef == null) return;
    setLotData2(lotData);
  }, [lotData]);


  const [all, setAll] = React.useState('0');



  const handleIndex = (indx, row) => {
    localStorage.setItem(
      "StockDataIndex",
      JSON.stringify(indx)
    );
    let route = `${row.ItemNo}`;
    props.history.push(route);
  };

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-1">


          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">Stock Status
              </div>

              <div className="float-right sw_btn_control">
              
              <div className="mr-4 mt-2 float-left">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
              </div>

              {value === 0 &&
                  <Button
                    className="mr-2"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSave()}
                  >
                    {DispensingLanguage[lan][menukey]["SAVE LOCATION"]}
                  </Button>
                }
                <Button
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/stock-status")}
                >
                  {DispensingLanguage[lan][menukey]["RETURN TO LIST"]}
                </Button>
              </div>
            </div>
          </Grid>


        </div>


        <NextPrevBtn dataList={StockData} StockDataIndex={StockDataIndex} handleReset={''} handleIndex={handleIndex}    {...props} />

        {/* start of form*/}
        <Card className="sw_card mb-2">
          <CardContent>
            <Grid container>

              <Grid item xs={12} sm={12}>
                <ul className="sw_item_list">
                  <li>
                    <strong>
                      {" "}
                      {DispensingLanguage[lan][menukey]["Product Code"]}:
                    </strong>{" "}
                    {MasterData.ProductCode}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Product Name"]}:
                    </strong>{" "}
                    {MasterData.ProductName}
                  </li>
                  <li>
                    <strong>{DispensingLanguage[lan][menukey]["AMC"]}:</strong>{" "}
                    {MasterData.AMC}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Minimum Quantity"]}:
                    </strong>{" "}
                    {MasterData.MinimumQuantity}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Maximum Quantity"]}:
                    </strong>{" "}
                    {MasterData.MaximumQuantity}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Quantity"]}:
                    </strong>{" "}
                    {MasterData.Quantity}
                  </li>
                  {/* <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Unit Price"]}:
                    </strong>{" "}
                    {MasterData.UnitPrice}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Line Total"]}:
                    </strong>{" "}
                    {MasterData.LineTotal}
                  </li> */}
                </ul>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of form*/}

        {/* data grid*/}
        <Grid container spacing={3}>
          {/* new row */}
          <Grid item xs={12} sm={12}>
            <div className={classes.root}>
              <Card className="sw_card">
                <CardContent>
                  <Box sx={{ width: "100%" }}>
                    <AppBar position="static" color="default" className="mb-2">
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleTabChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={DispensingLanguage[lan][menukey]["Lots"]}
                          // {...a11yProps(0)}
                          />
                          <Tab
                            label={
                              DispensingLanguage[lan][menukey]["Stock Card"]
                            }
                          //{...a11yProps(1)}
                          />
                          <Tab
                            label={
                              DispensingLanguage[lan][menukey]["Stock Chart"]
                            }
                          // {...a11yProps(2)}
                          />
                        </Tabs>
                      </Box>
                    </AppBar>

                    <TabPanel value={value} index={0}>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <ReactTabulator
                              ref={(r) => (tblRef = r)}
                              columns={columns}
                              data={lotData2}
                              layout={"fitColumns"}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Grid container spacing={3} className="mb-2">
                        <Grid item xs={2} sm={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lan == 'en_GB' ? enGB : fr}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["Start Date"]
                                }
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lan == 'en_GB' ? enGB : fr}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["End Date"]
                                }
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>



                        <Grid item xs={8} sm={8}>
                          <div className="float-right sw_btn_control">
                            <Button
                              variant="contained"
                              color="primary"
                              className="mr-2 sw_icons"
                              onClick={() => PrintPDFExcelExportFunction("print")}
                            >
                              <i className="fas fa-print"></i>
                            </Button>

                            <Button
                              variant="contained"
                              color="primary"
                              className="mr-2 sw_icons"
                              onClick={() => PrintPDFExcelExportFunction("excel")}
                            >
                              <i className="far fa-file-excel"></i>
                            </Button>
                          </div>


                        </Grid>
                      </Grid>

                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <ReactTabulator
                              columns={columns1}
                              layout={"fitColumns"}
                              data={stockCardData}
                              height="450px"
                              options={{
                                groupBy: "ProductFullName",
                                columnCalcs: "both",
                              }}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Grid container spacing={3} className="mb-2">
                        <Grid item xs={2} sm={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lan == 'en_GB' ? enGB : fr}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                // disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["Start Date"]
                                }
                                value={selectedStartDateChart}
                                onChange={handleStartDateChangeChart}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lan == 'en_GB' ? enGB : fr}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                // disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["End Date"]
                                }
                                value={selectedEndDateChart}
                                onChange={handleEndDateChangeChart}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={8} sm={8}></Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={option_linechart}
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Box>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EditProduct;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  th: {
    "border-left": "1px solid red",
  },
});
