import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormHelperText,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale
import { useTranslation } from "react-i18next";
import NextPrevBtn from "../../../services/NextPrevBtn";
import * as Service from "../../../services/Service.js";

const ShipmentFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ValidateProductForDispensing,
  handleATCChange,
  handleRDateChange,
  selectedDate,
  ...props
}) => {
  const StockData = JSON.parse(localStorage.getItem("ProductDataNextPrev"));

  const StockDataIndex = JSON.parse(localStorage.getItem("ProductDataIndex"));

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "shipment";
  const { t } = useTranslation();
  const classes = useStyles();

  const CountryList = JSON.parse(localStorage.getItem("CountryList"));

  const Ownertypelist = JSON.parse(localStorage.getItem("Ownertypelist"));
  const getShipmentStatusList = JSON.parse(
    localStorage.getItem("getShipmentStatusList")
  );
  const [getProductItemList, setProductItemList] = useState([]);
  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
  // const getFundingSourceList = JSON.parse(
  //   localStorage.getItem("getFundingSourceList")
  // );
  const [getFundingSourceList, setFundingSourceList] = useState([]);

  //==== Start Choosen Dropdown========
  const [chosenValues, setChosenValues] = useState({
    // CountryId: { id: "", name: "" },
    OwnerTypeId: { id: "", name: "" },
    ShipmentStatusId: { id: "", name: "" },
    ItemGroupId: { id: "", name: "" },
    ItemNo: { id: "", name: "" },
    FundingSourceId: { id: "", name: "" },
    routeOfAdmin: { id: "", name: "" },
    unit: { id: "", name: "" },
    administrationUnit: { id: "", name: "" },
  });
  const select_product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["Select Product Group"]),
  };
  const allLabel = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["Select Funding Source"]),
  };
  const select_owner = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["Select Owner Type"]),
  };
  const select_shipment = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["Select Shipment Status"]),
  };
  const defaultOption = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["Select Product"]),
  };
  useEffect(() => {
    let chosenValuesData = { ...chosenValues };

    // ----------------------------------Select text start--------------------------------

    if (ProductGroupList && ProductGroupList.length > 0) {
      const itemGroupIndex = ProductGroupList.findIndex(
        (ProductGroupList_list) =>
          ProductGroupList_list.id == formData.ItemGroupId
      );
      chosenValuesData["ItemGroupId"] =
        itemGroupIndex !== -1
          ? ProductGroupList[itemGroupIndex]
          : select_product;
    }
    // chosenValuesData["ItemNo"] = getProductItemList.find(
    //   (ProductSubGroupList_list) =>
    //     ProductSubGroupList_list.id == formData.ItemNo
    // );

    // For ItemNo
    if (getProductItemList && getProductItemList.length > 0) {
      const itemNoIndex = getProductItemList.findIndex(
        (ProductSubGroupList_list) =>
          ProductSubGroupList_list.id == formData.ItemNo
      );
      const defaultOption = {
        id: "0",
        name: t(DispensingLanguage[lan][menukey]["Select Product"]),
      };
      chosenValuesData["ItemNo"] =
        itemNoIndex !== -1 ? getProductItemList[itemNoIndex] : defaultOption;
    }
    if (getFundingSourceList && getFundingSourceList.length > 0) {
      const fundingIndex = getFundingSourceList.findIndex(
        (getFundingSourceList_list) =>
          getFundingSourceList_list.id == formData.FundingSourceId
      );
      const allLabel = {
        id: "0",
        name: t(DispensingLanguage[lan][menukey]["Select Funding Source"]),
      };
      chosenValuesData["FundingSourceId"] =
      fundingIndex !== -1 ? getFundingSourceList[fundingIndex] : allLabel;
    }

    if (Ownertypelist && Ownertypelist.length > 0) {
      const ownerTypeIndex = Ownertypelist.findIndex(
        (Ownertypelist_list) => Ownertypelist_list.id == formData.OwnerTypeId
      );
      chosenValuesData["OwnerTypeId"] =
        ownerTypeIndex !== -1 ? Ownertypelist[ownerTypeIndex] : select_owner;
    }
    // For ShipmentStatusId
    if (getShipmentStatusList && getShipmentStatusList.length > 0) {
      const shipmentStatusIndex = getShipmentStatusList.findIndex(
        (getShipmentStatusList_list) =>
          getShipmentStatusList_list.id == formData.ShipmentStatusId
      );
      chosenValuesData["ShipmentStatusId"] =
        shipmentStatusIndex !== -1
          ? getShipmentStatusList[shipmentStatusIndex]
          : select_shipment;
    }

    // ------------------------------Select text End-----------------------------
    // chosenValuesData["ItemGroupId"] = ProductGroupList.find(
    //   (ProductGroupList_list) =>
    //     ProductGroupList_list.id == formData.ItemGroupId
    // );

    // chosenValuesData["FundingSourceId"] = getFundingSourceList.find((ProductfundingList_list) => ProductfundingList_list.id == formData.FundingSourceId);

    // chosenValuesData["FundingSourceId"] =
    //   getFundingSourceList[
    //     getFundingSourceList.findIndex(
    //       (getFundingSourceList_list) =>
    //         getFundingSourceList_list.id == formData.FundingSourceId
    //     )
    //   ];

    // chosenValuesData["OwnerTypeId"] =
    //   Ownertypelist[
    //     Ownertypelist.findIndex(
    //       (Ownertypelist_list) => Ownertypelist_list.id == formData.OwnerTypeId
    //     )
    //   ];

    // chosenValuesData["ShipmentStatusId"] =
    //   getShipmentStatusList[
    //     getShipmentStatusList.findIndex(
    //       (getShipmentStatusList_list) =>
    //         getShipmentStatusList_list.id == formData.ShipmentStatusId
    //     )
    //   ];

    setChosenValues(chosenValuesData);
  }, [
    // formData.CountryId,
    formData.FundingSourceId,
    formData.OwnerTypeId,
    formData.ShipmentStatusId,
    formData.ItemGroupId,
    formData.ItemNo,
  ]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };
  //==== End Choosen Dropdown========
  const handleIndex = (indx, row) => {
    localStorage.setItem("ProductDataIndex", JSON.stringify(indx));
    let route = `${row.id}`;
    props.history.push(route);
  };
  const handleProductGroupChange = (event, valueobj) => {
    formData["ItemGroupId"] = valueobj ? valueobj.id : "";
    // formData["FundingSourceId"] = valueobj ? valueobj.id : "";
    // formData["ItemNo"] = valueobj ? valueobj.id : "";
    formData["ItemNo"] = "";
    formData["FundingSourceId"] = "";
    fetchProductSubGroupList(valueobj ? valueobj.id : "");
    fetchFundingSourceList(valueobj ? valueobj.id : "");
    props.handleChangeProductGroupProps(valueobj ? valueobj.id : "");
  };

  const fetchProductSubGroupList = (itemGroupId) => {
    let productParam = {
      action: "getProductItemList",
      ItemGroupId: itemGroupId,
      menukey: "",
    };
    Service.default
      .postApi("source/combo_generic.php", productParam)
      .then((res) => {
        setProductItemList(res.datalist);
      })
      .catch((err) => {});
  };
  const fetchFundingSourceList = (itemGroupId) => {
    let ZonesParam = {
      action: "getFundingSourceList",
      ItemGroupId: itemGroupId,
      menukey: "",
    };
    Service.default
      .postApi("source/combo_generic.php", ZonesParam)
      .then((res) => {
        setFundingSourceList(res.datalist);
      })
      .catch((err) => {});
  };

  const formOnchange = (e, cases, inputname) => {
    handleRDateChange(e, inputname);
  };

  useEffect(() => {
    if (!addProductForm) {
      fetchProductSubGroupList(formData["ItemGroupId"]);
      fetchFundingSourceList(formData["ItemGroupId"]);
    }
  }, [formData]);

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product">
          {/* <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title text-center">
                {DispensingLanguage[lan][menukey]["Shipment Entry"]}
              </div>
            </div>
          </Grid> */}


          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Shipment Entry - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["Shipment Entry"]}
              />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>
                      {/* <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="CountryId"
                            options={CountryList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "CountryId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["CountryId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.CountryId}
                                label={
                                  DispensingLanguage[lan][menukey]["Country"] +
                                  " *"
                                }
                                variant="standard"
                                error={errorObject.CountryId}
                                helperText={errorObject.CountryId}
                                id="CountryId"
                                name="CountryId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid> */}

                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="ItemGroupId"
                            options={ProductGroupList}
                            onChange={(event, valueobj) =>
                              handleProductGroupChange(
                                "ItemGroupId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["ItemGroupId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.ItemGroupId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Product Group"
                                  ]
                                }
                                variant="standard"
                                required
                                error={errorObject.ItemGroupId}
                                helperText={errorObject.ItemGroupId}
                                id="ItemGroupId"
                                name="ItemGroupId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="ItemNo"
                            options={getProductItemList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "ItemNo",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["ItemNo"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.ItemNo}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Product Name"
                                  ]
                                }
                                variant="standard"
                                // error={errorObject.ItemNo}
                                required
                                error={!!errorObject.ItemNo}
                                helperText={errorObject.ItemNo}
                                id="ItemNo"
                                name="ItemNo"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="FundingSourceId"
                            options={getFundingSourceList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "FundingSourceId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["FundingSourceId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.FundingSourceId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Funding Source"
                                  ]
                                }
                                variant="standard"
                                // error={errorObject.FundingSourceId}
                                required
                                error={!!errorObject.FundingSourceId}
                                helperText={errorObject.FundingSourceId}
                                id="FundingSourceId"
                                name="FundingSourceId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="FundingSourceId"
                            options={getFundingSourceList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "FundingSourceId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["FundingSourceId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.FundingSourceId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Funding Source"
                                  ]
                                }
                                variant="standard"
                                required
                                error={errorObject.FundingSourceId}
                                helperText={errorObject.FundingSourceId}
                                id="FundingSourceId"
                                name="FundingSourceId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="OwnerTypeId"
                            options={Ownertypelist}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "OwnerTypeId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["OwnerTypeId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.OwnerTypeId}
                                label={
                                  DispensingLanguage[lan][menukey]["Owner Type"]
                                }
                                variant="standard"
                                required
                                error={errorObject.OwnerTypeId}
                                helperText={errorObject.OwnerTypeId}
                                id="OwnerTypeId"
                                name="OwnerTypeId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="ShipmentStatusId"
                            options={getShipmentStatusList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "ShipmentStatusId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["ShipmentStatusId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.ShipmentStatusId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Shipment Status"
                                  ]
                                }
                                variant="standard"
                                required
                                error={errorObject.ShipmentStatusId}
                                helperText={errorObject.ShipmentStatusId}
                                id="ShipmentStatusId"
                                name="ShipmentStatusId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={DateFnsUtils}
                          locale={lan === "en_GB" ? enGB : fr}
                        >
                          <KeyboardDatePicker
                            required
                            error={errorObject.ShipmentDate}
                            helperText={errorObject.ShipmentDate}
                            className={classes.fullWidth}
                            variant="inline"
                            id="ShipmentDate"
                            label={
                              DispensingLanguage[lan][menukey]["Shipment Date"]
                            }
                            autoOk={true}
                            name="ShipmentDate"
                            fullWidth
                            showTodayButton={true}
                            value={formData.ShipmentDate || null}
                            format="dd/MM/yyyy"
                            minDate={
                              new Date(
                                new Date().setDate(new Date().getDate() + 1)
                              )
                            } // Set to tomorrow
                            onChange={(date) =>
                              formOnchange(
                                date,
                                "handleRDateChange",
                                "ShipmentDate"
                              )
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <TextField
                          error={errorObject.Qty}
                          helperText={errorObject.Qty}
                          required
                          id="Qty"
                          name="Qty"
                          label={
                            DispensingLanguage[lan][menukey][
                              "Shipment Quantity"
                            ]
                          }
                          value={formData.Qty}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                          InputProps={{
                            classes: { input: classes.rightAlign },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]["Submit"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/shipment")}
              >
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]["Update"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/shipment")}
              >
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default ShipmentFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: "13px",
  },
  rightAlign: {
    textAlign: "right",
  },
});
