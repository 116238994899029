import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  FormControl,
  Typography,
  TextField,
  Card,
  CardContent,
  Button
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
//import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import Autocomplete from "@material-ui/lab/Autocomplete";


//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import * as Service from "../../../services/Service.js";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
const UserName = UserInfo === 0 ? '' : UserInfo[0].name;

const Commune = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "commune-entry";

  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [currRegionId, setRegionIdvalue] = useState(0);
  const [currRegionText, setRegionText] = useState("");
  const [currZoneText, setZoneText] = useState("");
  const [currZoneId, setcurrZoneId] = useState(0);
  const [isLoadingUi, setLoading] = useState(true);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  const [Region, setRegion] = React.useState(true);


  const Region_list = JSON.parse(localStorage.getItem("Regionlist"));
  let All_Regionallist = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const Regionlist = [All_Regionallist].concat(Region_list);



  let Zone_list = JSON.parse(localStorage.getItem("Zonelist"));
  let All_Zonelist = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const Zonelist = [All_Zonelist].concat(Zone_list);


  let params = {
    menukey: menukey,
    action: "getDataList",
    RegionId: currRegionId,
    // ZoneId: currZoneId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { refetch } = useQuery([params], api.getCommunes, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });
  const { mutate:deleteApi  } = useMutation(api.deleteCommune, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        //  api.getAllDropdown("ZSlist").then((response) => {
        //   localStorage.setItem(
        //     "ZSlist",
        //    JSON.stringify(response.datalist.ZSlist)
        //    );
        //  });

        refetch();
        queryClient.getQueriesData("commune");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  if (Region) {
    setRegion(false);

    let ParamList = { action: "Regionlist" };
    // Service.default
    //   .postApi("source/combo_generic.php", ParamList)
    //   .then((res) => {
    //     let All_Region_label = {
    //       id: "0",
    //       name: t(DispensingLanguage[lan][menukey]["All"]),
    //     };
    //     const Region_List = [All_Region_label].concat(res.datalist);
    //     setRegionlist(Region_List);
    //   })
    //   .catch((err) => {});
  }

  const cZoneList = () => {
    let ZoneParam = { action: "Zonelist", RegionId: currRegionId, menukey: "" };

    // Service.default
    //   .postApi("source/combo_generic.php", ZoneParam)
    //   .then((res) => {
    //     let All_Zone_label = {
    //       id: "0",
    //       name: t(DispensingLanguage[lan][menukey]["All"]),
    //     };
    //     const Zone_List = [All_Zone_label].concat(res.datalist);
    //     setZoneListLocal(Zone_List);
    //   })
    //   .catch((err) => {});
  };

  // const handleRegionChange = (event, index) => {
  //   //console.log(index.props.children);
  //   //setAll(event.target.value);
  //   setLoading(true);
  //   //console.log(e.target.value);
  //   setRegionIdvalue(event.target.value);
  //   setRegionText(index.props.children);
  // };

  const handleZoneChange = (event, index) => {
    params = {
      menukey: menukey,
      action: "getDataList",
      RegionId: currRegionId,
      ZoneId: event.target.value,
      page_limit: JSON.stringify(100),
      page_number: JSON.stringify(0 + 1),
      search_key: "",
    };
    setLoading(true);
    setcurrZoneId(event.target.value);
    setZoneText(index.props.children);
    //getFacilityData();
  };

  React.useEffect(() => {
    setcurrZoneId(0);
    cZoneList();
  }, [currRegionId]);

  //====== Start Choosen Dropdown===========
  const [chosenValuesRegionlist, setChosenValuesRegionlist] = useState({
    Regionlist: { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) },
  });
  // const [chosenValuesZonelist, setChosenValuesZonelist] = useState({
  //   Zonelist: { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) },
  // });

  // Region List
  const handleChangeChoosenWarehouse = (name, valueobj, value) => {
    let chosenValuesDataRegion = { ...chosenValuesRegionlist };
    chosenValuesDataRegion[name] = valueobj;
    setChosenValuesRegionlist(chosenValuesDataRegion);

    // setFirstLoad(true);
    setLoading(false);
    setRegionIdvalue(value);
    setRegionText(valueobj.name);
   
    // console.log(valueobj.id);
  };

  // const RegionhandleChange = (event) => {
  //   // setFirstLoad(true);
  //   setLoading(false);
  //   setRegionIdvalue(event.target.value);
  //   //console.log('warehouse Data',event.target.value);
  // };

  // Zone List
  // const handleChangeChoosenZonelist = (name, valueobj, value) => {
  //   let chosenValuesDatazone = { ...chosenValuesZonelist };
  //   chosenValuesDatazone[name] = valueobj;
  //   setChosenValuesZonelist(chosenValuesDatazone);

  //   // setFirstLoad(true);
  //   setLoading(false);
  //   setcurrZoneId(value);
  //   setZoneText(valueobj.name);
  
  // };

  const ZoneHandleChange = (event) => {
    // setFirstLoad(true);
    setLoading(false);
    setcurrZoneId(event.target.value);
    //console.log('warehouse Data',event.target.value);
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=CommuneExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&RegionId=" +
        currRegionId +
        // "&ZoneId=" +
        // currZoneId +
        "&RegionText=" +
        currRegionText +
        // "&ZoneText=" +
        // currZoneText +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editCommune(rowData);
  };

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl."],
      hozAlign: "center",
      width: 100,
      formatter: "rownum",
      headerHozAlign: "center",
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department Name"],
    //  width: "20%",
      headerFilter: true,
      field: "RegionName",
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["ZS Name"],
    //   // width: "35%",
    //   headerFilter: true,
    //   field: "ZoneName",
    // },
    {
      title: DispensingLanguage[lan][menukey]["Commune Name"],
      // width: "28%",
      headerFilter: true,
      field: "DistrictName",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
       width: 100,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editCommune = (data) => {
    let route = `commune-entry/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteCommune = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //         "Once deleted, you will not be able to recover this Commune!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       deleteApi({DistrictId:data.id,"menukey": menukey,"lan":lan,"action": "dataDalete",UserName: UserName});
  //     }
  //   });
  // };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editCommune(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
           // deleteCommune(rowData);
           modalPopupDelete(deleteApi, rowData.id);
          }}
        />
      </>
    );
  }

  //const [all, setAll] = React.useState('0');

  return (
    <>
      <div className={classes.communePageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Commune Entry"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    id="Regionlist"
                    options={Regionlist}
                    onChange={(event, valueobj) =>
                      handleChangeChoosenWarehouse(
                        "Regionlist",
                        valueobj,
                        valueobj ? valueobj.id : ""
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    value={chosenValuesRegionlist["Regionlist"]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          DispensingLanguage[lan][menukey]["Department Name"]
                        }
                        variant="standard"
                        id="Regionlist"
                        name="Regionlist"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    id="Zonelist"
                    options={Zonelist}
                    onChange={(event, valueobj) =>
                      handleChangeChoosenZonelist(
                        "Zonelist",
                        valueobj,
                        valueobj ? valueobj.id : ""
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    value={chosenValuesZonelist["Zonelist"]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["ZS Name"]}
                        variant="standard"
                        id="Zonelist"
                        name="Zonelist"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid> */}

              <Grid item xs={10} sm={10}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/commune-entry/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add"]}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Commune;

const useStyles = makeStyles({
  communePageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
