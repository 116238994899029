import React, { useState, useRef, useEffect } from "react"; //forwardRef,
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
// materialui table
// import MaterialTable from "material-table";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import { Grid, Card, CardContent, Button, TextField, FormControl,Typography } from "@material-ui/core";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
// import MomentUtils from "@date-io/moment";
import moment from "moment";

import { fr, enGB } from 'date-fns/locale'; // import French and English locale
import 'moment/locale/fr'; // import Moment.js French locale

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

const Aefi = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "aefi-list";
  const TransactionTypeId = 9;
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  moment.locale(lan == 'en_GB' ? 'enGB' : 'fr');

  const [openOrder, setOpenOrder] = useState(false);

  const classes = useStyles();
  // const tableRef = useRef();

  // const [firstLoad, setFirstLoad] = useState(true);
  // const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  // const [totalRow, setTotalRow] = useState(0);
  // const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? '' : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      activeFacilityData.id,
      date,
      selectedEndDate
    );
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);

    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      activeFacilityData.id,
      selectedStartDate,
      date
    );
    setLoading(true);
  };

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  const [isLoading, setLoading] = useState(true);

//region combo list
const [RegionList, setRegionListData] = useState([]);
const [activeRegionData, setAcRegionData] = useState({
  id: RegionList.length > 0 ? RegionList[0].id :0,
  name: RegionList.length > 0 ? RegionList[0].name : "",
});

React.useEffect(() => {
  if (activeRegionData.id == 0) {
    getRegionData();
  }
}, [activeRegionData.id]);



const handleDepartmentChange = (event, newValue) => {
  setAcRegionData(newValue);
  getCommuneData(newValue.id);
};

const getRegionData = () => {
  let FacilitylevelParam = {
    action: "getDepartmentforreport",
    RoleId: RoleId,
    UserId: UserId,
    IsAll: 1,
    menukey: menukey,
    lan: lan,
  };
  cRegionList(FacilitylevelParam);
};

const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
  onSuccess: (data) => {
    if (data.status == 200) {
      setRegionListData(data.data.datalist);

      setAcRegionData({
        id: data.data.datalist.length ? data.data.datalist[0].id : 0,
        name: data.data.datalist.length ? data.data.datalist[0].name : "",
      });
      getCommuneData(data.data.datalist[0].id);
    }
  },
});

////distict combo list
const [Communelist, setCommunelistData] = useState([]);
const [activeCommuneData, setAcCommuneData] = useState({
  id: -1,
  name: Communelist.length > 0 ? Communelist[0].name : "",
});



const getCommuneData = (RegionId) => {
  let FacilitylevelParam = {
    action: "getcommuneforreport",
    RoleId: RoleId,
    UserId: UserId,
    RegionId: RegionId,
    IsAll: 1,
    menukey: menukey,
    lan: lan,
  };
  cgetCommuneList(FacilitylevelParam);
};

const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
  onSuccess: (data, params) => {
    if (data.status == 200) {
      setCommunelistData(data.data.datalist);

      setAcCommuneData({
        id: data.data.datalist.length ? data.data.datalist[0].id : 0,
        name: data.data.datalist.length ? data.data.datalist[0].name : "",
      });

      getFacilityData(
        params.RegionId,
        data.data.datalist.length ? data.data.datalist[0].id : 0
      );
     
    }
  },
});

// Commune Change
const handleCommuneChange = (event, newValue) => {
  setAcCommuneData(newValue);
  getFacilityData(activeRegionData.id, newValue.id);
};

//facility combo load

const [Facility_list, setFacilityData] = useState([]);
const [activeFacilityData, setAcFacilityData] = useState({
  id: -1,
  name: Facility_list.length > 0 ? Facility_list[0].name : "",
});



const getFacilityData = (regid, communeid) => {
  let FacilityParam = {
    action: "FacilitylistforReport",
    RegionId: regid,
    DistrictId: communeid,
    RoleId: RoleId,
    UserId: UserId,
    IsAll: 1,
    menukey: menukey,
    lan: lan,
  };
  cFacilityList(FacilityParam);
};

const { mutate: cFacilityList } = useMutation(api.getFacility, {
  onSuccess: (data, params) => {
    if (data.status == 200) {
      setFacilityData(data.data.datalist);

      setAcFacilityData({
        id: data.data.datalist.length ? data.data.datalist[0].id : 0,
        name: data.data.datalist.length ? data.data.datalist[0].name : "",
      });

      refreshAllData(
        params.RegionId,
        params.DistrictId,
        data.data.datalist.length ? data.data.datalist[0].id : 0,
        selectedStartDate,
        selectedEndDate
        
      );
    }
  },
});

const handleFacilityListChange = (event, newValue) => {
  refreshAllData(
    activeRegionData.id,
    activeCommuneData.id,
    newValue.id,
    selectedStartDate,
    selectedEndDate
  );
  setAcFacilityData(newValue);
};


const refreshAllData = (RegionId,DistrictId,FacilityId,StartDate,EndDate) => {
  let params1 = {
  menukey: menukey,
  RegionId: RegionId,
  DistrictId: DistrictId,
  FacilityId: FacilityId,
  StartDate: StartDate,
  EndDate: EndDate,
  lan: lan,
  };
  getMasterDataListTable({queryKey: [{ ...params1, action: "AEFIListData" }],});
};

const { mutate: getMasterDataListTable } = useMutation(
  api.AEFIList,
  {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
  }
);


  // let params = {
  //   menukey: menukey,
  //   FacilityId: FacilityId,
  //   page_limit: JSON.stringify(100),
  //   page_number: JSON.stringify(0 + 1),
  //   search_key: "",
  //   StartDate: selectedStartDate,
  //   EndDate: selectedEndDate,
  //   action: "getMasterDataList",
  // };

  // const { error, isError, data, refetch } = useQuery(
  //   [params],
  //   api.getMasterDataList,
  //   {
  //     onSuccess: (data) => {
  //       setDataItems(data);
  //       setLoading(false);
  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0,//1000 * 60 * 60 * 24,
  //   }
  // );

  const { mutate } = useMutation(api.deleteAefiMasterData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        //refetch();

        queryClient.getQueriesData("aefi");

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
          duration: 10000,
        });

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

      }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
      "?action=getAefiViewDataExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name +
      "&DepartmentName=" +
      activeRegionData.name +
      "&ZoneId=" +
      activeCommuneData.id +
      "&DistrictName=" +
      activeCommuneData.name +
      "&StartDate=" +
      StartDate +
      "&EndDate=" +
      EndDate +
      "&TransactionTypeId=" + TransactionTypeId +
      "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editAefi(rowData);
  };

  const columns = [
    {
      title: "Id", field: "id", visible: false
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#."],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "RegionName",
      width: 130,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["District"],
      field: "DistrictName",
      width: 130,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      width: 180,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Serial_No"],
      field: "SheetNo",
      width: 190,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["receipt_at_the_CNRP"],

      field: "DateCNRP",
      width: 220,
      headerFilter: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Date_of_birth"],
      field: "DateofBirth",
      width: 150,
      headerSort: true,
      headerFilter: false,
      hozAlign: "left",
      headerHozAlign: "left",
  
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Gender"],
      field: "GenderType",
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  // const handleChangePage = (data) => {
  //   setPageNumber(data);
  //   setFirstLoad(false);
  // };
  // const handleChangeRowPerPage = (data) => {
  //   setNumberRowPerPage(data);
  // };

  const editAefi = (data) => {
    let route = '/aefi-list/edit/'+data.id+'|'+data.FacilityId;
    props.history.push(route);
  };

  // const deleteaefi = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //       "Once deleted, you will not be able to Aefi"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: [t(DispensingLanguage[lan][menukey]["no"]),
  //     t(DispensingLanguage[lan][menukey]["yes"])],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       mutate(data.id);
  //     }
  //   });
  // };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
      return (
        <>
          <ViewList
            onClick={() => {
              editAefi(rowData);
            }}
          />
        </>
      );
  }

  const addOrder = (OrderProductData) => {
    editAefi(OrderProductData);
  };

  // useEffect(() => {
  //   refetch();
  // }, [selectedEndDate, selectedEndDate]);

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["AEFI View"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

            <Grid item xs={2} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                  locale={lan == 'en_GB' ? enGB : fr}
                >
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["From"]}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                  locale={lan == 'en_GB' ? enGB : fr}
                >
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["To"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">
                     <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>

                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Aefi;

const useStyles = makeStyles({
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
