import React,{ useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

const ProfileParametersEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "profile-parameters";
  const { t } = useTranslation();
  const IssueToFaList = JSON.parse(localStorage.getItem("ProductGroupList"));
  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    ItemGroupId: { id: "", name: "" },
  });
  const select_product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["Select Product Group"]),
  };

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };

    if (IssueToFaList && IssueToFaList.length > 0) {
      const itemGroupIndex = IssueToFaList.findIndex(
        (ProductGroupList_list) =>
          ProductGroupList_list.id == formData.ItemGroupId
      );
      chosenValuesData["ItemGroupId"] =
        itemGroupIndex !== -1
          ? IssueToFaList[itemGroupIndex]
          : select_product;
    }


    setChosenValues(chosenValuesData);
  }, [formData.ItemGroupId]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========





  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Profile Parameter - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Profile Parameters Form']} />
              <CardContent>
                <Grid container spacing={3}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>

                      <Grid item xs={6} sm={2}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="ItemGroupId"
                            options={IssueToFaList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "ItemGroupId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["ItemGroupId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.ItemGroupId}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Product Group"
                                  ]
                                }
                                variant="standard"
                                required
                                error={errorObject.ItemGroupId}
                                helperText={errorObject.ItemGroupId}
                                id="ItemGroupId"
                                name="ItemGroupId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          error={errorObject.ParamName}
                          helperText={errorObject.ParamName}
                          required
                          id="ParamName"
                          name="ParamName"
                          label={
                            DispensingLanguage[lan][menukey]["Parameter Name"]
                          }
                          value={formData.ParamName}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 250 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          error={errorObject.ParamNameFrench}
                          helperText={errorObject.ParamNameFrench}
                          required
                          id="ParamNameFrench"
                          name="ParamNameFrench"
                          label={
                            DispensingLanguage[lan][menukey]["Parameter Name_French"]
                          }
                          value={formData.ParamNameFrench}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{ maxLength: 250 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.BShow}
                              onChange={(e) => handleCheck(e)}
                              name="BShow"
                              value="no"
                            />
                          }
                          label={DispensingLanguage[lan][menukey]["Show"]}
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/profile-parameters")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/profile-parameters")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div>
  );
};

export default ProfileParametersEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
