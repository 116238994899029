import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
import swal from "sweetalert";
// CSTeam screen
import FundingReqSourceEntry from "./FundingReqSourceEntry";
import AddFundingReqSourceEntry from "./AddFundingReqSourceEntry";
import EditFundingReqSourceEntry from "./EditFundingReqSourceEntry";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = process.env.REACT_APP_BASE_NAME + `/login`;
          sessionStorage.clear();
        }
      });
    }
  };

  if (RedirectLogin) {
    checkLogin();
    setRedirectLogin(false);
  }


  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);


  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {   
    if(document.getElementById("Snackbarh")!=null)
      document.getElementById("Snackbarh").classList.remove("dnone"); 
      setMsgObj(obj);
      setTimeout(function(){
        if(document.getElementById("Snackbarh")!=null)
        document.getElementById("Snackbarh").classList.add("dnone"); 
       }, (obj.msgtype==1? 3000:8000)); 

   };
  const closeNoticeModal = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if(document.getElementById("Snackbarh")!=null)
      document.getElementById("Snackbarh").classList.add("dnone"); 
  };

  return (

    <div>
      <AfterLoginNavbar {...props} />
      <div className="section signup-top-padding sw_inner_container">
        <Switch>
          <Route
            path={`${path}/add`}
            render={(props) => (
              <AddFundingReqSourceEntry {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
          <Route
            path={`${path}/edit/:id`}
            render={(props) => (
              <EditFundingReqSourceEntry {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
          <Route
            path={`${path}/`}
            render={(props) => (
              <FundingReqSourceEntry {...props} openNoticeModal={openNoticeModal} />
            )}
          ></Route>
        </Switch>
        <Notification
          closeNoticeModal={closeNoticeModal}
          msgObj={msgObj}
          {...props}
        ></Notification>
      </div>
    </div>


  );
};

export default Index;

const useStyles = makeStyles({

});
