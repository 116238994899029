import React, { useState, useEffect } from "react"; //, useEffect
import { useQuery, useMutation, } from "react-query";
import * as api from "../../../actions/api";
import DispenserFormData from "./AdjustmentFormData";
import moment from "moment";

const AddAdjustment = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "adjustment";
  const FacilityId = localStorage.getItem("FacilityId");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
  );
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [formData, setFormData] = useState({
    TransactionDate: selectedDate,
    AdjTypeId: "",
    TransactionNo: generatedInvoiceNo,
    FacilityId: FacilityId,
    TransactionTypeId: 3,
    Remarks:"",
  });

  const [ManyTableData, setManyTableData] = useState([]);
  const [isPositive, setIsPositive] = useState(true);
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [IssueToFacility, setIssueToFacility] = useState("");

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});

  let params = {
    menukey: "adjustment",
    FacilityId: FacilityId,
    TransactionTypeId: 3,
    TransactionDate: moment(formData.TransactionDate).format("DD/MM/YYYY"),
    ChangeTransactionDate:true
  };
  const { data: invoiceNo } = useQuery([params], api.getInvoiceNo, {
    onSuccess: (data) => {
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData };
      fdata["TransactionNo"] = data;
      setFormData(fdata);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  /*
  const { mutate } = useMutation(api.saveAdjustment, {
    onSuccess: (data) => {
      if (data.status == 200) {

        let route = `/${menukey}/edit/${data.TransactionId}`;
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });
  */
 
  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };
  const updateIspositive = (isPosVal) => {
    setIsPositive(isPosVal);
  };

  const handleChange = (e) => {
    if (e == 'IssuedToFacility') {
      setErrorObject({ ...errorObject, ['IssueToFacility']: null });
    }
    else {

      const { name, value } = e.target;
      if (name == "AdjTypeId") {
        console.log("e.target");
        console.log(e.target);
      }

      let data = { ...formData };
      data[name] = value;
      setFormData(data);
      setErrorObject({ ...errorObject, [name]: null });
    }
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      AdjTypeId: "",
      TransactionNo: generatedInvoiceNo,
      FacilityId: FacilityId,
      TransactionTypeId: 3,
      IssueToFacility: IssueToFacility,
      Remarks:"",
    });

    setManyTableData([]);

  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "AdjTypeId",
      "IssueToFacility"
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };
  /*
  const handleSubmit = async (e) => {
    console.log("from submit ");
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        menukey: "adjustment",
        lan: lan,
        UserName: UserName,
      };

      mutate(fDtat);
    }
  };
  */

  const deleteAdjustmentInv = (data) => {
    let newarray = ManyTableData.filter((element) => element != data);
    setManyTableData(newarray);
  };

  /*
  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (rows.datatype == "FormData") {
      setFormData(rows.IssueToFacility);
    } else {
      if (validateForm(formData)) {
        let fDtat = {
          ...formData,
          ManyJsonSave: rows.data,
          menukey: "adjustment",
          lan: lan,
          UserName: UserName,
        };
        mutate(fDtat);
      }
      setManyTableData(rows.data);
    }
  };

  */

  useEffect(() => { }, [isPositive]);

  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
  }

  function checkValidateForm(rows, callfrom){

    //console.log("ddddddd",rows.data);

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':[],"menukey":menukey,"lan":lan,"UserName":UserName,'deletedDataSet':[]}; 
        //mutateUpdate(fDtat); 
        return fDtat;
    }else{
      return false;
    }

    

  };

  function setFormDataForAdjust(val){
    setFormData(val);
  };

  return (
    <>
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        //handleSubmit={handleSubmit}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        deleteAdjustmentInv={deleteAdjustmentInv}
        //setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        //bStockUpdate={0}
        handleAdd={handleAdd}
        generatedInvoiceNo={generatedInvoiceNo}
        DisableAddBtn={true}
        isPositive={isPositive}
        updateIspositive={updateIspositive}
        handleChangeChoosenProps={handleChangeChoosenProps}
        checkValidateForm={checkValidateForm}
        setFormDataForAdjust={setFormDataForAdjust}
        {...props}
      />
    </>
  );
};

export default AddAdjustment;
