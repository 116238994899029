import React, { useState } from "react";
import { Typography } from "@material-ui/core";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
// import swal from "sweetalert";
// import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import ShipmentFormData from "./ShipmentFormData.js";
// import Constants from "../../../services/Constants";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AddShipment = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "shipment";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [formData, setFormData] = useState({
    // CountryId: "",
    ItemGroupId: "",
    ItemNo: "",
    FundingSourceId: "",
    OwnerTypeId: "",
    ShipmentStatusId: "",
    ShipmentDate: "",
    Qty: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["ShipmentEntry", id],
    () =>
      api.getShipment(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.saveShipment, {
    onSuccess: (data) => {
      if (data.status == 200) {
        
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("shipment");
        props.history.push("/shipment");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const checkNumberValidation = (name, value) => {
    if (name == "price") {
      let newValue = "";
      if (
        value.split(".") &&
        value.split(".")[1] &&
        value.split(".")[1].length > 2
      ) {
        newValue = parseFloat(value).toFixed(2);
      } else {
        newValue = value;
      }
      setFormData({ ...formData, [name]: newValue });
    }

  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "price") {
      checkNumberValidation(name, value);

    } else {
      let data = { ...formData };
      data[name] = value;

      setFormData(data);
    }
    setErrorObject({ ...errorObject, [name]: null });
  };

  //==== Start Choosen Dropdown========
  const handleChangeChoosenProps = (name, value) => {

    if (name == "price") {
      checkNumberValidation(name, value);

    } else {
      let data = { ...formData };
      data[name] = value;

      setFormData(data);
    }
    setErrorObject({ ...errorObject, [name]: null });
  }
  //==== End Choosen Dropdown========

  const handleChangeProductGroupProps = (e) => {
    if (e > 0) {
      setErrorObject({ ...errorObject, ['ItemGroupId']: null });
    }
  };

  const handleATCChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    let data = { ...formData };
    data['atc'] = rowId;
    setFormData(data);
  };

  const handleRDateChange = (date, field) => {
 
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }

    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });

  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      // CountryId: "",
      ItemGroupId: "",
      ItemNo: "",
      FundingSourceId: "",
      OwnerTypeId: "",
      ShipmentStatusId: "",
      ShipmentDate: "",
      Qty: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      // "CountryId",
      "ItemGroupId",
      "ItemNo",
      "FundingSourceId",
      "OwnerTypeId",
      "ShipmentStatusId",
      "ShipmentDate",
      "Qty"
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  const ValidateProductForDispensing = async (e) => {
    return;
  };

  return (
    <>
      <ShipmentFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        handleChangeChoosenProps={handleChangeChoosenProps}
        ValidateProductForDispensing={ValidateProductForDispensing}
        handleATCChange={handleATCChange}
        handleChangeProductGroupProps={handleChangeProductGroupProps}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        {...props}
      />
    </>
  );
};

export default AddShipment;
