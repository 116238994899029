import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Button } from "@material-ui/core";

// import { Button } from "reactstrap";
import { useQuery } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

const CountryEntry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const lancod = localStorage.getItem("LangCode");
  const menukey = "country-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  const LanguageList = JSON.parse(localStorage.getItem("LanguageList"));
  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [currLangCode, setCurrLangCode] = useState(lan);

  let params = {
    menukey: menukey,
    action: "getDataList",
    lan: currLangCode,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    UserId: UserId,
  };
  const { data, refetch } = useQuery([params], api.getCountryEntery, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=CountryEntryExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        currLangCode +
        "&UserId=" +
        UserId +
        +"&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editRecipients(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Country Code"],
      field: "CountryCode",
      headerSort: false,
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Country Name"],
      field: "CountryName",
      headerSort: false,
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Center"],
      field: "centerData",
      headerSort: false,
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Zoom Level"],
      field: "ZoomLevel",
      headerSort: false,
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Currency Name"],
      field: "CurrencyName",
      headerSort: false,
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Report Expected Date"],
      field: "LMISReportExpectedDate",
      headerSort: false,
      headerFilter: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Action"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editRecipients = (data) => {
    let route = `country-entry/edit/${data.id}`;
    props.history.push(route);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        <Edit
          onClick={() => {
            editRecipients(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-dci mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Country Entry Form"]}
              </div>
              <div className="float-right sw_btn_control">
                {/* <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/country-entry/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add"]}
                </Button> */}
                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i className="fas fa-print"></i>
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="sw_relative">
            {isLoading && <LoadingSpinnerOpaque />}
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                // height={600}
                layout={"fitColumns"}
                rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryEntry;

const useStyles = makeStyles({
  packSizePageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
