import React, { useState, useEffect } from "react"; //, useEffect

import { useQuery, useMutation } from "react-query";

import * as api from "../../../actions/api";
import DispenserFormData from "./RepackingFormData";
import moment from "moment";
// import Constants from "../../../services/Constants";

const AddRepacking = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "repacking";
  const FacilityId = localStorage.getItem("FacilityId");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
  );
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const RepackingAdjTypeId = 7;

  const [formData, setFormData] = useState({
    TransactionDate: selectedDate,
    AdjTypeId: RepackingAdjTypeId,
    TransactionNo: generatedInvoiceNo,
    FacilityId: FacilityId,
    IssueToFacility: FacilityId,
    TransactionTypeId: 3,
    PreparedBy: "",
    ApprovedBy: "",
    ProductRepackingFrom: "",
    ProductRepackingTo: "",
  });

  const [ManyTableData, setManyTableData] = useState([]);
  const [isPositive, setIsPositive] = useState(true);
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});

  let params = {
    menukey: "receive",
    FacilityId: FacilityId,
    TransactionTypeId: 3,
    TransactionDate: "",
  };
  const { data: invoiceNo } = useQuery([params], api.getInvoiceNo, {
    onSuccess: (data) => {
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData };
      fdata["TransactionNo"] = data;
      setFormData(fdata);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const { mutate } = useMutation(api.saveRepacking, {
    onSuccess: (data) => {
      if (data.status == 200) {
        //let route = `edit/${data.TransactionId}`;
        let route = `/${menukey}/edit/${data.TransactionId}`;
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });
  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };
  const updateIspositive = (isPosVal) => {
    setIsPositive(isPosVal);
  };

  const handleChange = (e) => {
    if (e == "IssuedToFacility") {
      setErrorObject({ ...errorObject, ["IssueToFacility"]: null });
    } else {
      const { name, value } = e.target;
      if (name == "AdjTypeId") {
        console.log("e.target");
        console.log(e.target);
      }

      let data = { ...formData };
      data[name] = value;
      setFormData(data);
      setErrorObject({ ...errorObject, [name]: null });
    }
  };

  //==== Start Choosen Dropdown========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
  };
  //==== End Choosen Dropdown========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      AdjTypeId: RepackingAdjTypeId,
      TransactionNo: generatedInvoiceNo,
      FacilityId: FacilityId,
      TransactionTypeId: 3,
      IssueToFacility: FacilityId,
      PreparedBy: "",
      ApprovedBy: "",
      ProductRepackingFrom: "",
      ProductRepackingTo: "",
      ItemGroupId: props.match.params.id, //Get the url send id
    });

    setManyTableData([]);
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "PreparedBy",
      "ApprovedBy",
      "ProductRepackingFrom",
      "ProductRepackingTo",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ");
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        menukey: "repacking",
        lan: lan,
        UserName: UserName,
      };

      mutate(fDtat);
    }
  };

  const deleteRepackingInv = (data) => {
    let newarray = ManyTableData.filter((element) => element != data);
    setManyTableData(newarray);
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (rows.datatype == "FormData") {
      setFormData(rows.IssueToFacility);
    } else {
      if (validateForm(formData)) {
        let fDtat = {
          ...formData,
          ManyJsonSave: rows.data,
          menukey: "repacking",
          lan: lan,
          UserName: UserName,
        };
        mutate(fDtat);
      }
      setManyTableData(rows.data);
    }
  };

  useEffect(() => {}, [isPositive]);

  return (
    <>
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        deleteRepackingInv={deleteRepackingInv}
        setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        bStockUpdate={0}
        handleAdd={handleAdd}
        generatedInvoiceNo={generatedInvoiceNo}
        DisableAddBtn={true}
        isPositive={isPositive}
        updateIspositive={updateIspositive}
        {...props}
      />
    </>
  );
};

export default AddRepacking;
