import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CardActions

} from "@material-ui/core";

import AssignGroup from "./AssignGroup"; 
import GMap from "../../../services/GMap";
import * as Service from "../../../services/Service.js";
import FacilityInitialDate from "../../../services/FacilityInitialDate";

const FacilityFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "facility";

  const Regionlist = JSON.parse(localStorage.getItem("Regionlist"));
  // const Zonelist = JSON.parse(localStorage.getItem("Zonelist"));
  let TempZoneList = JSON.parse(localStorage.getItem("Zonelist"));
  const [Zonelist, setZoneListLocal] = useState(TempZoneList);


  let TempCommuneList = JSON.parse(localStorage.getItem("Communelist"));
  const [Communelist, setCommunelist] = useState(TempCommuneList);

  //const Communelist = JSON.parse(localStorage.getItem("Communelist"));

  const FlevelList = JSON.parse(localStorage.getItem("FlevelList"));
  const FTypeList = JSON.parse(localStorage.getItem("FTypeList"));
  const Ownertypelist = JSON.parse(localStorage.getItem("Ownertypelist"));

  const Servicearealist = JSON.parse(localStorage.getItem("Servicearealist"));

  const [currRegionId, setcurrRegionId] = useState(0);
  // const [currZoneId, setcurrZoneId] = useState(0);
  const [currCommuneId, setcurrCommuneId] = useState(0);
  const [latlng, setLatlng] = useState([0, 0]);
  const [latitudelongitude, setlatitudelongitude] = useState();
  // const [currZoneToggle, setZoneToggle] = useState(false);
  const [openInitialDate, setopenInitialDate] = useState(false);
  const [isCheckboxDisable, setIsCheckboxDisable] = useState(false);


  
  const openInitialDateClose = (modalname,event) => {
    
    switch (modalname) {
      case "openInitialDate":
        
         setopenInitialDate(true); 
        break;
        case "closeInitialDate": 
          setIsCheckboxDisable(false);
          setopenInitialDate(false); 
          props.handleCheckOnInitialDateCancel(event);
          
          break;
          case "closeInitialDateWithOk":
            setIsCheckboxDisable(true);
            props.handleCheckOnInitialDate(event);
            setopenInitialDate(false); 
            break;
          
      default:
        break;
    }
  };


  React.useEffect(() => {
    setIsCheckboxDisable(props.isCheckboxDisable);

  }, [props.isCheckboxDisable]);


  const handleCheck1 = (event) => { 
    //setIsCheckboxDisable(true);
    const { name, checked } = event.target;
    let bDispenseVal = checked;
    console.log(bDispenseVal)
    if(bDispenseVal)
    openInitialDateClose("openInitialDate",event);
    else{
      handleCheck(event);
    }
    
    
    
  }
  
  const classes = useStyles();


  // function cZoneList() {  

  //   let ZoneParam = {action:"Zonelist",RegionId:formData["RegionId"],menukey:""}
  //   Service.default
  //       .postApi("source/combo_generic.php",ZoneParam)
  //       .then((res) => { 
  //          setZoneListLocal(res.datalist); 
  //          cCommuneList();
  //       })
  //       .catch((err) => {});


  //   }


  function cCommuneList() {
    // console.log('cCommuneList - formData["RegionId"]:', formData["RegionId"], ', formData["ZoneId"]: ', formData["ZoneId"]);
    let CommuneParam = { action: "Communelist", RegionId: formData["RegionId"], menukey: "" }
    Service.default
      .postApi("source/combo_generic.php", CommuneParam)
      .then((res) => {

        setCommunelist(res.datalist);
        //  if(addProductForm ){
        //   formData["DistrictId"] = 0;
        // }


      })
      .catch((err) => { });

  }


  const handleRegionChange = (event) => {
    setcurrRegionId(event.target.value);
    //  if(formData["ZoneId"]==0){
    //   setZoneToggle(!currZoneToggle);
    //  }

    // setcurrZoneId(0);
    setcurrCommuneId(0);
    formData["RegionId"] = event.target.value;
    //formData["ZoneId"]=0;
    formData["DistrictId"] = 0;
    cCommuneList();
  };


  //  const handleZoneChange = (event) => {

  //   formData["ZoneId"]=event.target.value;
  //   formData["DistrictId"]=0;
  //    cCommuneList();
  //  };



  const handleMapChange = (event) => {

    setLatlng([13.025965926333539, -6.96533203125]);

  }

  const updateLatLang = (lat, lang) => {
    // formData["location"]=currRegionId;
    formData.Latitude = lat;
    formData.Longitude = lang;

    formData.location = lat + ',' + lang;
    setlatitudelongitude(lat + ',' + lang);
    setLatlng([lat, lang]);
  }

   
 
  React.useEffect(() => {
    // console.log('formData--------', formData);
    if (!addProductForm) {
      setcurrRegionId(formData.RegionId);
      //cZoneList();
    }

  }, [formData]);



  

  return (

    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>

            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Facility - Add/Edit']}</div>

              <div className="float-right sw_btn_control">

                {/* Action buttons */}
                {
                  addProductForm ?
                    <Grid item xs={12} className="text-center">
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]['Reset']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]['Save']}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/facility")}
                      >
                        {DispensingLanguage[lan][menukey]['Back to List']}
                      </Button>
                    </Grid>
                    :
                    <Grid item xs={12} className="text-center">


                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleUpdate()}
                      >
                        {(DispensingLanguage[lan][menukey]['update'])}
                      </Button>


                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/facility")}
                      >
                        {DispensingLanguage[lan][menukey]['Back to List']}
                      </Button>


                    </Grid>
                }
                {/* End Action buttons */}

              </div>
            </div>
          </Grid>
        </div>


        {/* New row */}
        <Grid container spacing={3}>


          <Grid item xs={6} sm={6}>

            <Card>



              {/* <CardHeader  title="fdsgfdgds"  /> */}

              <CardActions>
                <Grid item xs={6} sm={6}>
                  {DispensingLanguage[lan][menukey]['Facility Detail Form']}
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Button
                    className="mr-2 sw_btn_control float-right"
                    variant="contained"
                    color="primary"
                    onClick={() => handleMapChange()}
                  >
                    {DispensingLanguage[lan][menukey]['Add new Location']}
                  </Button>
                </Grid>





              </CardActions>

              <CardContent>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3}>



                      <Grid item xs={6} sm={6}>

                        <TextField
                          error={errorObject.FacilityCode}
                          helperText={errorObject.FacilityCode}
                          required
                          id="FacilityCode"
                          name="FacilityCode"
                          label={DispensingLanguage[lan][menukey]['Facility Code']}
                          value={formData.FacilityCode}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}

                        />

                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.FacilityName}
                          helperText={errorObject.FacilityName}
                          required
                          id="FacilityName"
                          name="FacilityName"
                          label={DispensingLanguage[lan][menukey]['Facility Name']}
                          value={formData.FacilityName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>



                      <Grid item xs={6} sm={6}>


                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">

                            {DispensingLanguage[lan][menukey]["Department Name"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.RegionId}
                            labelId="demo-simple-select-helper-label"
                            id="RegionId"
                            name="RegionId"
                            value={formData.RegionId}
                            // value={formData.RegionId}
                            fullWidth
                            onChange={(e) => handleRegionChange(e)}
                          >
                            <MenuItem value="0">
                              {DispensingLanguage[lan][menukey]["Select Department"]}
                            </MenuItem>

                            {Regionlist.map((item, index) => {
                              return (
                                <MenuItem key={`Regionlist-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.RegionId}>
                            {errorObject.RegionId}
                          </FormHelperText>
                        </FormControl>

                      </Grid>


                      {/* <Grid item xs={6} sm={6}>
                        

                        <FormControl className={classes.fullWidth}>
                            <InputLabel id="demo-simple-select-helper-label">
                          
                            {DispensingLanguage[lan][menukey]["ZS Name"]}*
                            </InputLabel>
                            <Select
                              error={errorObject.ZoneId}
                              labelId="demo-simple-select-helper-label"
                              id="ZoneId"
                              name="ZoneId"
                             // value={formData.ZoneId}
                              value={formData.ZoneId}
                              fullWidth
                              onChange={(e) => handleZoneChange(e)}
                            >
                            <MenuItem value="0">
                              {DispensingLanguage[lan][menukey]["Select ZS"]}
                            </MenuItem>

                              {Zonelist.map((item, index) => {
                                return (
                                  <MenuItem key={`Zonelist-${index+1}`} value={item.id}>{item.name}</MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText error={errorObject.ZoneId}>
                              {errorObject.ZoneId}
                            </FormHelperText>
                          </FormControl>

                     </Grid> */}

                      <Grid item xs={6} sm={6}>


                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">

                            {DispensingLanguage[lan][menukey]["Commune Name"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.DistrictId}
                            labelId="demo-simple-select-helper-label"
                            id="DistrictId"
                            name="DistrictId"
                            value={formData.DistrictId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="0">
                              {DispensingLanguage[lan][menukey]["Select Commune"]}
                            </MenuItem>

                            {Communelist.map((item, index) => {
                              return (
                                <MenuItem key={`Communelist-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.DistrictId}>
                            {errorObject.DistrictId}
                          </FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={6} sm={6}>


                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">

                            {DispensingLanguage[lan][menukey]["Facility Level"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.FLevelId}
                            labelId="demo-simple-select-helper-label"
                            id="FLevelId"
                            name="FLevelId"
                            value={formData.FLevelId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="">
                              {DispensingLanguage[lan][menukey]["Facility Level"]}
                            </MenuItem>

                            {FlevelList.map((item, index) => {
                              return (
                                <MenuItem key={`FlevelList-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.FLevelId}>
                            {errorObject.FLevelId}
                          </FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={6} sm={6}>


                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">

                            {DispensingLanguage[lan][menukey]["Facility Type"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.FTypeId}
                            labelId="demo-simple-select-helper-label"
                            id="FTypeId"
                            name="FTypeId"
                            value={formData.FTypeId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="">
                              {DispensingLanguage[lan][menukey]["Facility Type"]}
                            </MenuItem>

                            {FTypeList.map((item, index) => {
                              return (
                                <MenuItem key={`FTypeList-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.FTypeId}>
                            {errorObject.FTypeId}
                          </FormHelperText>
                        </FormControl>

                      </Grid>



                      <Grid item xs={6} sm={6}>

                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">

                            {DispensingLanguage[lan][menukey]["Owner Type"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.OwnerTypeId}
                            labelId="demo-simple-select-helper-label"
                            id="OwnerTypeId"
                            name="OwnerTypeId"
                            value={formData.OwnerTypeId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="">
                              {DispensingLanguage[lan][menukey]["Owner Type"]}
                            </MenuItem>

                            {Ownertypelist.map((item, index) => {
                              return (
                                <MenuItem key={`Ownertypelist-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.OwnerTypeId}>
                            {errorObject.OwnerTypeId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6}>

                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">

                            {DispensingLanguage[lan][menukey]["Service Area"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.ServiceAreaId}
                            labelId="demo-simple-select-helper-label"
                            id="ServiceAreaId"
                            name="ServiceAreaId"
                            value={formData.ServiceAreaId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="">
                              {DispensingLanguage[lan][menukey]["Service Area"]}
                            </MenuItem>

                            {Servicearealist.map((item, index) => {
                              return (
                                <MenuItem key={`Servicearealist-${index + 1}`} value={item.id}>{item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.ServiceAreaId}>
                            {errorObject.ServiceAreaId}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12}>

                        <TextField
                          error={errorObject.FacilityIdDHIS2}
                          helperText={errorObject.FacilityIdDHIS2}
                          id="FacilityIdDHIS2"
                          name="FacilityIdDHIS2"
                          label={DispensingLanguage[lan][menukey]['DHIS2 Facility Uid']}
                          value={formData.FacilityIdDHIS2}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />


                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          error={errorObject.FacilityAddress}
                          helperText={errorObject.FacilityAddress}
                          id="FacilityAddress"
                          name="FacilityAddress"
                          label={DispensingLanguage[lan][menukey]['Facility Address']}
                          value={formData.FacilityAddress}
                          fullWidth
                          autoComplete="family-name"

                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.FacilityInCharge}
                          helperText={errorObject.FacilityInCharge}
                          id="FacilityInCharge"
                          name="FacilityInCharge"
                          label={DispensingLanguage[lan][menukey]['Facility In-Charge']}
                          value={formData.FacilityInCharge}
                          fullWidth
                          autoComplete="family-name"

                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>



                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.FacilityPhone}
                          helperText={errorObject.FacilityPhone}
                          id="FacilityPhone"
                          name="FacilityPhone"
                          label={DispensingLanguage[lan][menukey]['Facility Phone']}
                          value={formData.FacilityPhone}
                          fullWidth
                          autoComplete="family-name"

                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.FacilityEmail}
                          helperText={errorObject.FacilityEmail}
                          id="FacilityEmail"
                          name="FacilityEmail"
                          label={DispensingLanguage[lan][menukey]['Facility Email']}
                          value={formData.FacilityEmail}
                          fullWidth
                          autoComplete="family-name"

                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.location}
                          helperText={errorObject.location}
                          id="location"
                          name="location"
                          label={DispensingLanguage[lan][menukey]['Placement of Location']}
                          value={formData.location}

                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <TextField
                          id="SOBAPSCode"
                          name="SOBAPSCode"
                          label={DispensingLanguage[lan][menukey]['SOBAPS Client Code']}
                          value={formData.SOBAPSCode}
                          fullWidth
                          autoComplete="family-name"

                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isCheckboxDisable}
                              color="primary"
                              checked={formData.bDispense}
                              onChange={(e) => handleCheck1(e)}
                              name="bDispense"
                              value="no"
                            />
                          }
                          label={DispensingLanguage[lan][menukey]['bDispensing'] +" ("+ formData.initmothYear+")"}
                        />
                        
                      </Grid>




                    </Grid>
                  </Grid>
                </Grid>

              </CardContent>
            </Card>

          </Grid>


          <Grid item xs={6} sm={6}>


            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]["Map"]} />
              <CardContent className="map_body">

                <GMap
                  updateLatLang={updateLatLang}
                  addProductForm={addProductForm}
                  latlng={latlng}
                  formData={formData}
                  {...props}
                >

                </GMap>

              </CardContent>
            </Card>


          </Grid>



        </Grid>


        {
          !addProductForm ?
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Card className="sw_card">
                  <CardContent>
                    <AssignGroup {...props}></AssignGroup>
                  </CardContent>
                </Card>
              </Grid>
            </Grid> : ""
        }

<FacilityInitialDate
      handleClose={openInitialDateClose} 
      open={openInitialDate} 
      FacilityServiceId=''
      {...props}

    />
      </div>
    </div>


  );
};

export default FacilityFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },

  facilitygroupmapTitle: {
    marginTop: "40px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },

});
