import React, { useState, useEffect, } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import { subDays } from "date-fns";


// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, } from "react-tabulator";



import { useQuery } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Grid,

} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

// import DefaultFooter from "components/Footers/DefaultFooter";
import { getDefaultMonthYear } from "../../services/Common";

import { useTranslation } from "react-i18next";
import { checkLogin, checkUserPermission, } from "../../services/CheckUserAccess";


import { Button } from "reactstrap";

const DashboardPage = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, } = useTranslation();
  const menukey = "Logistics Dashboard";
  const FacilityId = localStorage.getItem("FacilityId");
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const Facility_List = JSON.parse(localStorage.getItem("FacilityList"));

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);
  const bFacilitySelected = localStorage.getItem("bFacilitySelected");


  exporting(Highcharts);
  const classes = useStyles();

  // const [pickerData, setPickerData] = useState([
  //   {
  //     startDate: subDays(new Date(), 29),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );

  const [currFacilityId, setCurrFacilityId] = useState(bFacilitySelected == 1 ? FacilityId : 0);

  const handleYearChange = (event) => {

    setCurrYearId(event.target.value);


  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
  };

  /*   const handleFacilityChange = (event) => {
      setCurrFacilityId(event.target.value);
    }; */

  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    setCurrFacilityId(rowId);
  };


  const [availability, setAvailability] = useState([]);
  const [availabilityCategories, setAvailabilityCategories] = useState([]);

  const option_Availability_of_commodities = () => {
    let Availability_of_commodities = {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: availabilityCategories,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      legend: {
        reversed: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: availability,
    };

    return Availability_of_commodities;
  };


  let params = {
    action: "getAvailabilityOfCommoditiesChart",
    menukey: menukey,
    FacilityId: currFacilityId,
    Month: currMonthId,
    Year: currYearId,
  };
  const { data: CommoditiesChart } = useQuery([params], api.getAvailabilityOfCommoditiesChart, {
    onSuccess: (data) => {
      // console.log("Datalist", data);

      if (data.data.Availability.level_name.length == 0) {

        setAvailability([]);

        setAvailabilityCategories([]);

      }
      else {
        setAvailability([
          {
            name: data.data.Availability.level_name[0],
            data: data.data.Availability.dataValue[0],
            color: data.data.Availability.barcolor[0],
          },
          {
            name: data.data.Availability.level_name[1],
            data: data.data.Availability.dataValue[1],
            color: data.data.Availability.barcolor[1],
          },
        ]);

        setAvailabilityCategories(data.data.Availability.item_name);
      }
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });


  let params1 = {
    action: "getAvailabilityOfCommoditiesTable",
    menukey: menukey,
    FacilityId: currFacilityId,
    Month: currMonthId,
    Year: currYearId,
  };
  const [dataItems, setDataItems] = useState([]);
  const { refetch } = useQuery([params1], api.getAvailabilityOfCommoditiesTable, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=AvailabilityOfCommodities" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FacilityId=" +
      currFacilityId +
      "&Month=" +
      currMonthId +
      "&Year=" +
      currYearId + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  useEffect(() => {

    if (currYearId > 0 && currMonthId > 0) {
      // getConsumptionTrendsTopFiveProducts.refetch();
    }
  }, [currYearId, currMonthId, currFacilityId]);

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  /* const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
  }, []);



  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 40,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Availability"],
      field: "Availability",
      width: 150,
      // width: 150,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false }
    },
    {
      title: DispensingLanguage[lan][menukey]["Total Balance"],
      field: "total",
      width: 150,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false }
    }

  ];

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div
          className="section signup-top-padding sw_inner_container"
        >
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Availability of commodities"])}
                </div>
              </div>
            </div>
            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={1}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="YearList"
                        name="YearList"
                        value={currYearId}
                        onChange={handleYearChange}
                        fullWidth
                      >
                        {YearList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="MonthList"
                        name="MonthList"
                        value={currMonthId}
                        onChange={handleMonthChange}
                        fullWidth
                      >
                        {MonthList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      {/*  <Select
                          labelId="demo-simple-select-helper-label"
                          id="FacilityList"
                          name="FacilityList"
                          value={currFacilityId}
                          onChange={handleFacilityChange}
                          fullWidth
                        >
                          <MenuItem value="0">
                            {t(DispensingLanguage[lan][menukey]["All Facility"])}
                          </MenuItem>

                          {FacilityList.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select> */}

                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="FacilityList"
                        disableClearable
                        options={FacilityList}
                        onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label={DispensingLanguage[lan][menukey]["All Facility"]}
                            variant="standard"
                            hiddenLabel
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}></Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey][
                      "Availability of commodities"
                      ]
                    )}
                  />
                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={option_Availability_of_commodities()}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}
            {/* new row */}
            <div className="row">
              <div className="col-md-12">
                <Card className="sw_card">
                  <CardHeader
                    title={t(DispensingLanguage[lan][menukey]["Availability of commodities"])}

                    action={
                      <div className="float-right sw_btn_control">
                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("print")}
                        >
                          <i className="fas fa-print"></i>
                        </Button>

                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    }
                  />
                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="uniqueName">
                          <ReactTabulator
                            columns={columns}
                            data={dataItems}
                            height="500px"
                            layout={"fitColumns"}
                          />
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}
          </div>
        </div>
      </>)
  );
};

export default DashboardPage;

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
