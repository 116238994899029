import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import swal from "sweetalert";

import {
  TextField,
  FormControl,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

//import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
// import MomentUtils from "@date-io/moment";
import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import {
  saveLayoutSettings,
  clearLayoutSettings,
  getFilterValue,
} from "../../../services/LayoutSettings";

const StockTake = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "stock-take";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const userId = UserInfo == 0 ? "" : UserInfo[0].id;

  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  const classes = useStyles();
  // const tableRef = useRef();
  const { useState } = React;

  // const [firstLoad, setFirstLoad] = useState(true);
  // const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  // const [totalRow, setTotalRow] = useState(0);
  // const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);
  //const [query, setQuery] = useState("");

  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));

  /* Get saved filter value from layoutSettings */
  const StartDateFilter = getFilterValue(menukey, "StartDateFilter");
  const EndDateFilter = getFilterValue(menukey, "EndDateFilter");

  /* Get saved filter value from layoutSettings */

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    //  moment(testDate).format('MM/DD/YYYY');
    StartDateFilter || moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    EndDateFilter || moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({ menukey });
  const [isLoading, setLoading] = useState(true);

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getStockTakeDataList",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
  };

  const { data, refetch } = useQuery([params], api.getStockTakeInvs, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const { mutate } = useMutation(api.deleteStockTakeInv, {
    onSuccess: (data) => {
      if (data.success == 1) {
        refetch();
        queryClient.getQueriesData("stock-take");

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=getStockTake" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editStockTakeInvs(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: t(DispensingLanguage[lan][menukey]["Sl#"]),
      hozAlign: "center",
      width: 100,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    // {
    //   title: t(DispensingLanguage[lan][menukey]['Start Date']),
    //   field: 'StockTakeDate',
    //   width: 170,
    //    headerFilter: true, formatter: function (cell, formatterParams) {
    //     let value = cell.getValue();
    //     return moment(value).format("DD/MM/YYYY");
    //   }
    // },
    {
      title: t(DispensingLanguage[lan][menukey]["Date of Stock Take"]),
      field: "CompleteDate",
      
      //headerFilter: true,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    /*
    {
      title: DispensingLanguage[lan][menukey]["Prepared By"],
      field: "PreparedBy",
      //headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Approved By"],
      field: "ApprovedBy",
      //headerFilter: true,
      headerSort: false,
    },
    */
    {
      title: t(DispensingLanguage[lan][menukey]["Status"]),
      field: "IsCompleted",
      hozAlign: "center",
      width: 150,
      //formatter: "tickCross",
      headerHozAlign: "center",
      formatter: function (cell) {
        let value = cell.getValue();
        let bCompleted = cell.getRow(cell).getData().IsCompleted;

        if (bCompleted == 1)
          return "<span class='statusCompleted'></span>"; //+ value green;
        else return "<span class='statusDraft'></span> "; //+ value red;
      },
      tooltip: function (cell) {
        if (cell.getValue() == 1) {
          return t(DispensingLanguage[lan][menukey]["Posted"]);
        } else {
          return t(DispensingLanguage[lan][menukey]["Draft"]);
        }
      },
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Actions"]),
      field: "custom",
      headerHozAlign: "center",
      headerSort: false,
      hozAlign: "center",
      width: 130,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  // const handleChangePage = (data) => {
  //   setPageNumber(data);
  //   setFirstLoad(false);
  // };
  // const handleChangeRowPerPage = (data) => {
  //   setNumberRowPerPage(data);
  // };

  const editStockTakeInvs = (data) => {
    let route = `stock-take/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteStockTakeInvs = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]['Are you sure?']),
  //     text: t(DispensingLanguage[lan][menukey]['Once deleted, you will not be able to recover this stock take!']),
  //     icon: "warning",
  //     buttons: [t(DispensingLanguage[lan][menukey]["No"]),
  //     t(DispensingLanguage[lan][menukey]["Yes"])],
  //     dangerMode: true,
  //   }).then((willDelete) => {

  //     if (willDelete) {
  //       mutate(data.id);

  //     }
  //   });
  // };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || 'Edit | Show';

    if (rowData.IsCompleted == 1)
      return (
        <>
          <ViewList
            onClick={() => {
              editStockTakeInvs(rowData);
            }}
          />
        </>
      );
    else
      return (
        <>
          <Edit
            onClick={() => {
              editStockTakeInvs(rowData);
            }}
          />

          <DeleteOutline
            onClick={() => {
              //deleteStockTakeInvs(rowData);
              modalPopupDelete(mutate, rowData.id);
            }}
          />
        </>
      );
  }

  useEffect(() => {
    refetch();
  }, [selectedStartDate, selectedEndDate]);
  /* start save/clear setting layout handler */
  const saveLayoutSettingsHandler = () => {
    const singleLayoutSettingsParam = {
      filter: {
        StartDateFilter: selectedStartDate,
        EndDateFilter: selectedEndDate,
      },
    };

    saveLayoutSettings(menukey, singleLayoutSettingsParam);

    props.openNoticeModal({
      isOpen: true,
      msg: t(DispensingLanguage[lan][menukey]["Layout saved successfully"]),
      msgtype: 1,
    });
  };

  const clearLayoutSettingsHandler = () => {
    clearLayoutSettings(menukey);

    props.openNoticeModal({
      isOpen: true,
      msg: t(DispensingLanguage[lan][menukey]["Layout cleared successfully"]),
      msgtype: 1,
    });
  };

  /* end save/clear setting layout handler */

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Stock Take"])}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                  locale={lan == "en_GB" ? enGB : fr}
                >
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t(DispensingLanguage[lan][menukey]["From"])}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={DateFnsUtils}
                  locale={lan == "en_GB" ? enGB : fr}
                >
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      // disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={t(DispensingLanguage[lan][menukey]["To"])}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture={true}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={8} sm={8}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/stock-take/add");
                    }}
                  >
                    {t(DispensingLanguage[lan][menukey]["Add Stock Take"])}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="layoutsettings-container">
          <div className="text-right icon-container">
            <i
              onClick={saveLayoutSettingsHandler}
              style={{ cursor: "pointer" }}
              className="far fa-save"
              aria-hidden="true"
              title={t(
                DispensingLanguage[lan][menukey]["Save Layout Settings"]
              )}
            ></i>

            <i
              onClick={clearLayoutSettingsHandler}
              style={{ cursor: "pointer" }}
              className="far fa-stop-circle"
              aria-hidden="true"
              title={t(
                DispensingLanguage[lan][menukey]["Clear Layout Settings"]
              )}
            ></i>
          </div>
        </div>

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StockTake;

const useStyles = makeStyles({
  OrderPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
