import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
// import MomentUtils from "@date-io/moment";
import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import {
  saveLayoutSettings,
  clearLayoutSettings,
  getFilterValue,
} from "../../../services/LayoutSettings";

const OrderInv = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "order";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const { t } = useTranslation();


  /* Get saved filter value from layoutSettings */
  const StartDateFilter = getFilterValue(menukey, "StartDateFilter");
  const EndDateFilter = getFilterValue(menukey, "EndDateFilter");

  /* Get saved filter value from layoutSettings */

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  const classes = useStyles();
  // const tableRef = useRef();
  const { useState } = React;

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;



  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  const StatusList = [
    {
      id: 1,
      name: t(DispensingLanguage[lan][menukey]["Draft"]),
      ColorCode: "#ff0000",
    // },
    // {
    //   id: 2,
    //   name: t(DispensingLanguage[lan][menukey]["Complete"]),
    //   ColorCode: "#ffa500",
    },
    {
      id: 2,
      name: t(DispensingLanguage[lan][menukey]["Complete"]),
      ColorCode: "#008000",
    },
    {
      id: 3,
      name: t(DispensingLanguage[lan][menukey]["Receive"]),
      ColorCode: "#0000ff",
    },
  ];

  const [dataItems, setDataItems] = useState([]);

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    StartDateFilter || moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    EndDateFilter || moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
  };
  const { data, refetch } = useQuery([params], api.getOrderInvs, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const { mutate } = useMutation(api.deleteOrderInv, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        refetch();
        queryClient.getQueriesData("order");

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
          duration: 10000,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
      "?action=getExportOrdersBook" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FacilityId=" +
      FacilityId +
      "&FacilityName=" +
      FacilityName +
      "&StartDate=" +
      StartDate +
      "&EndDate=" +
      EndDate +
      "&TimeStamp=" +
      Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editOrderInvs(rowData);
  };

  var StatusIcon = function (cell, formatterParams) {
    //plain text value
    const CellValueForOrder = cell._cell.row.data;

    //bReceived
    //console.log("bbbbb",CellValueForOrder);
    //console.log("sssss",CellValueForOrder.bCompleted);

    if (CellValueForOrder.bReceived == 1) {
      return "<i className='fa fa-check' style='color:blue'></i>";
    } else {
      if (CellValueForOrder.bCompleted == 1) {
        return "<i className='fa fa-check' style='color:green'></i>";
      } else if (CellValueForOrder.bCompleted == 0) {
        return "<i className='fa fa-check' style='color:orange'></i>";
      } else if (CellValueForOrder.bCompleted == -1) {
        return "<i className='fa fa-times' style='color:red'></i>";
      }
    }
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: t(DispensingLanguage[lan][menukey]["Sl#"]),
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order Date"]),
      width: 130,
      field: "OrderDate",
      headerFilter: true,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order No"]),
      field: "OrderNo",
      width: 240,
      headerFilter: true,
    },
    {
      title: t(DispensingLanguage[lan][menukey]["Order To"]),
      field: "OrderTo",
      headerFilter: true,
    },
    // {
    //   title: t(DispensingLanguage[lan][menukey]["Entry By"]),
    //   field: "EntryBy",
    //   headerFilter: true,
    // },
    {
      title: t(DispensingLanguage[lan][menukey]["Status"]),
      field: "bCompleted",
      hozAlign: "center",
      width: 100,
      //formatter: "tickCross",
      headerHozAlign: "center",
      formatter: function (cell) {
        let value = cell.getValue();
        let bCompleted = cell.getRow(cell).getData().bCompleted;

        if (bCompleted == 1)
        return "<span class='statusCompleted'></span>"; //+ value green;
        else if (bCompleted == 0)
        return "<span class='statusNotApproved'></span> "; //+ value yallow;
        else
        return "<span class='statusDraft'></span> "; //+ value red;
        
      },
      tooltip: function(cell) {
        if (cell.getValue() == 1) {
          return t(DispensingLanguage[lan][menukey]["Approved"]);
        } else if(cell.getValue() == 0) {
          return t(DispensingLanguage[lan][menukey]["To be approved"]);
        } else {
          return t(DispensingLanguage[lan][menukey]["Draft"]);
        }
      },



    },
    {
      title: t(DispensingLanguage[lan][menukey]["Actions"]),
      field: "custom",
      headerHozAlign: "center",
      headerSort: false,
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editOrderInvs = (data) => {
    let route = `order/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteOrderInvs = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //       "Once deleted, you will not be able to recover this order!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: [
  //       t(DispensingLanguage[lan][menukey]["No"]),
  //       t(DispensingLanguage[lan][menukey]["Yes"]),
  //     ],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       mutate(data.id);
  //     }
  //   });
  // };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";

    if (rowData.bCompleted == 1 || rowData.bCompleted == 0)
      return (
        <>
          <ViewList
            onClick={() => {
              editOrderInvs(rowData);
            }}
          />
        </>
      );
    else
      return (
        <>
          <Edit
            onClick={() => {
              editOrderInvs(rowData);
            }}
          />

          <DeleteOutline
            onClick={() => {
              modalPopupDelete(mutate, rowData.id);
              //deleteOrderInvs(rowData);
            }}
          />
        </>
      );
  }

  useEffect(() => {
    refetch();
  }, [selectedStartDate, selectedEndDate]);
/* start save/clear setting layout handler */
const saveLayoutSettingsHandler = () => {
  const singleLayoutSettingsParam = {
    filter: {
      StartDateFilter: selectedStartDate,
      EndDateFilter: selectedEndDate,
    },
  };

  saveLayoutSettings(menukey, singleLayoutSettingsParam);

  props.openNoticeModal({
    isOpen: true,
    msg: t(DispensingLanguage[lan][menukey]['Layout saved successfully']),
    msgtype: 1,
  });
};

const clearLayoutSettingsHandler = () => {
  clearLayoutSettings(menukey);

  props.openNoticeModal({
    isOpen: true,
    msg: t(DispensingLanguage[lan][menukey]['Layout cleared successfully']),
    msgtype: 1,
  });
};

/* end save/clear setting layout handler */

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Order"])}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={12} md={3} >
                <Grid container spacing={1}>
                  <Grid item xs={6} md={6} lg={6}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          className={classes.fullWidth}
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={t(DispensingLanguage[lan][menukey]["From"])}
                          autoOk={true}
                          value={selectedStartDate}
                          onChange={handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} md={6} lg={6}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          className={classes.fullWidth}
                          variant="inline"
                          format="dd/MM/yyyy"
                          id="date-picker-inline"
                          label={t(DispensingLanguage[lan][menukey]["To"])}
                          autoOk={true}
                          value={selectedEndDate}
                          onChange={handleEndDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disableFuture={true}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} xl={8}>

                    <div className="mylegend_area OrderApprovalCheck">
                      <span className="float-left marginleft">
                        {DispensingLanguage[lan][menukey]["Status"] + ":  "}{" "}
                        &nbsp;
                      </span>

                      {StatusList.map((item, index) => {
                        return (
                          <div
                            key={`StatusList-${index + 1}`}
                            className="my_legendorder"
                          >
                            <div
                              className="mylegend_color"
                              style={{ background: item.ColorCode }}
                            ></div>
                            <div className="mylegend_label"> {item.name}</div>
                          </div>
                        );
                      })}
                    </div>
                  </Grid>

                  <Grid item xs={12} md={4} xl={4}>
                    <div className="d-flex justify-content-end align-items-center sw_btn_control">
                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2"
                        onClick={() => {
                          props.history.push(
                            "/order/add"
                          );
                        }}
                      >
                        {t(DispensingLanguage[lan][menukey]["Add Order"])}
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i className="fas fa-print"></i>
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="layoutsettings-container">
          <div className="text-right icon-container">
            <i
              onClick={saveLayoutSettingsHandler}
              style={{ cursor: "pointer" }}
              className="far fa-save"
              aria-hidden="true"
              title = {t(DispensingLanguage[lan][menukey]['Save Layout Settings'])}
            ></i>

            <i
              onClick={clearLayoutSettingsHandler}
              style={{ cursor: "pointer" }}
              className="far fa-stop-circle"
              aria-hidden="true"
              title={t(DispensingLanguage[lan][menukey]['Clear Layout Settings'])}
            ></i>
          </div>
        </div>

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderInv;

const useStyles = makeStyles({
  OrderPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
