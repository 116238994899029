import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { useQuery } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

//let tblMenuRef = null;

const Errorlog = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "error-log";
  // const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;
  // const UserName = UserInfo===0?'': UserInfo[0].name;
  // const UserList = JSON.parse(localStorage.getItem("UserList"));

  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  //const { t } = useTranslation();

  let params0 = {
    menukey: menukey,
    action: "getDataList",
  };

  const { data } = useQuery([params0], api.getErrorLogData, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      formatter: "rownum",
      width: 70,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Remote IP"],
      width: 110,
      field: "RemoteIP",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["User"],
      width: 90,
      field: "userName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Date"],
      width: 140,
      field: "logDate",
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Query Type"],
      width: 70,
      field: "queryType",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Query"],
      field: "query",
      headerFilter: true,
      formatter:"textarea"
    },
    {
      title: DispensingLanguage[lan][menukey]["Error No"],
      width: 80,
      field: "errorNo",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Error Message"],
      width: 320,
      field: "errorMsg",
      headerFilter: true,
      formatter:"textarea"
    },
  ];

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {" "}
                {DispensingLanguage[lan][menukey]["Error Log"]}
              </div>
            </div>
          </Grid>
        </div>

        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <div className="sw_relative">
                <div className="uniqueName">
                  <ReactTabulator
                    // ref={(r) => (tblMenuRef = r)}

                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height={"500px"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Errorlog;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
