import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";

// materialui table
// import MaterialTable from "material-table";
import {
  TextField,
  Grid,
  Checkbox,
  FormControl,
  Typography,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { DeleteOutline, Edit } from "@material-ui/icons";

import {
  saveLayoutSettings,
  clearLayoutSettings,
  getFilterValue,
} from "../../../services/LayoutSettings";

//const ExcelFile = ReactExport.ExcelFile;
//const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const Products = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "products";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  // const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const classes = useStyles();
  // const tableRef = useRef();
  const { useState } = React;
  // const [selectedRow, setSelectedRow] = useState(null);
  // const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  // const [totalRow, setTotalRow] = useState(0);
  // const [pageNumber, setPageNumber] = useState(0);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /* Get saved filter value from layoutSettings */
  const ItemGroupIdFilter = getFilterValue(menukey, "ItemGroupIdFilter");

  /* Get saved filter value from layoutSettings */

  const [dataItems, setDataItems] = useState([]);

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({ menukey });
  console.log("bModalConfirm: ", bDeleteConfirm);

  const userId = UserInfo == 0 ? "" : UserInfo[0].id;
  // const RoleItemGroupId = UserInfo==0?'': UserInfo[0].ItemGroupId;
  /*   const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
    const [currProductGroupId, setCurrProductGroupId] = useState(RoleItemGroupId);
   */

  const ProductGroup_List = JSON.parse(
    localStorage.getItem("ProductGroupList")
  );
  let All_Group_label = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroupList = [All_Group_label].concat(ProductGroup_List);
  const [currProductGroupId, setCurrProductGroupId] = useState(
    ItemGroupIdFilter || 0
  );
  const [ProductCount, setProductCount] = useState(0);

  const handleProductGroupChange = (event, newValue) => {
    let rowId = "";
    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }

    setCurrProductGroupId(rowId);
  };

  let params = {
    menukey: menukey,
    action: "getDataList",
    ItemGroupId: currProductGroupId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };
  const { refetch } = useQuery([params], api.getProducts, {
    onSuccess: (data) => {
      localStorage.setItem("ProductDataNextPrev", JSON.stringify(data));

      setDataItems(data);
      setProductCount(data.length);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  /*   const { isLoading, error, isError, data, refetch } = useQuery(
      "products",
      api.getProducts,
      {
        onSuccess: (data) => {

          localStorage.setItem(
            "ProductDataNextPrev",
            JSON.stringify(data)
          );

          setDataItems(data);
        },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0,//1000 * 60 * 60 * 24,
      }
    ); */

  const { mutate } = useMutation(api.deleteProduct, {
    onSuccess: (data) => {
      if (data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        refetch();
        queryClient.getQueriesData("products");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ProductsListExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&ItemGroupId=" +
        currProductGroupId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;

    row._row.parent.rows.forEach((rowss, i) => {
      if (row._row.data.id == rowss.data.id) {
        localStorage.setItem("ProductDataIndex", JSON.stringify(i));

        editProduct(rowData);
        return;
      }
    });

    // editProduct(rowData);
  };

  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    // let labelName = props.labelName;
    // let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bKeyItem" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "5%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "productCode",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "productName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Short Name"],
      field: "ShortName",
      headerFilter: true,
      width: 220,
    },

    {
      title: DispensingLanguage[lan][menukey]["Key Product"],
      field: "bKeyItem",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 150,
      headerSort: true,
      formatter: reactFormatter(
        <RowInputData
          labelName="bKeyItem"
          field="bKeyItem"
          fieldType="check-box"
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Non Perishable"],
      field: "NonPerishable",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 150,
      headerSort: true,
      formatter: reactFormatter(
        <RowInputData
          labelName="NonPerishable"
          field="NonPerishable"
          fieldType="check-box"
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Is Covid"],
      field: "bCovid,",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 150,
      headerSort: true,
      formatter: reactFormatter(
        <RowInputData labelName="bCovid" field="bCovid" fieldType="check-box" />
      ),
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      width: 100,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editProduct = (data) => {
    let route = `products/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteProduct = (data) => {
    // swal({
    //   title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    //   text: t(
    //     DispensingLanguage[lan][menukey][
    //     "Once deleted, you will not be able to recover this Product!"
    //     ]
    //   ),
    //   icon: "warning",
    //   buttons: [t(DispensingLanguage[lan][menukey]["No"]),
    //   t(DispensingLanguage[lan][menukey]["Yes"])],
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     mutate(data.id);
    //   }
    // });
    // modalPopupDelete(mutate, data.id);
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            dataItems.forEach((row, i) => {
              if (rowData.id == row.id) {
                localStorage.setItem("ProductDataIndex", JSON.stringify(i));
                editProduct(rowData);
                return;
              }
            });

            // editProduct(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            // deleteProduct(rowData);
            modalPopupDelete(mutate, rowData.id);
          }}
        />
      </>
    );
  }

  /* start save/clear setting layout handler */
  const saveLayoutSettingsHandler = () => {
    const singleLayoutSettingsParam = {
      filter: {
        ItemGroupIdFilter: currProductGroupId,
      },
    };

    saveLayoutSettings(menukey, singleLayoutSettingsParam);
    //console.log("hhhhhhhhhhhhhhhhh",singleLayoutSettingsParam);

    props.openNoticeModal({
      isOpen: true,
      msg: t(DispensingLanguage[lan][menukey]["Layout saved successfully"]),
      msgtype: 1,
    });
  };

  const clearLayoutSettingsHandler = () => {
    clearLayoutSettings(menukey);

    props.openNoticeModal({
      isOpen: true,
      msg: t(DispensingLanguage[lan][menukey]["Layout cleared successfully"]),
      msgtype: 1,
    });
  };

  /* end save/clear setting layout handler */

  return (
    <>
      <div className="sw_topmargin">
        <div className="sw_makeStyles_tableContainer">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Product"]}
                </div>
              </div>
            </Grid>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={12} sm={7}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} sm={4}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="ProductGroupList"
                          disableClearable
                          disabled={userId == 1 ? false : true}
                          options={ProductGroupList}
                          onChange={(event, newValue) =>
                            handleProductGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            ProductGroupList[
                              ProductGroupList.findIndex(
                                (productGroupList) =>
                                  productGroupList.id == currProductGroupId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Product Group"
                                ]
                              }
                              variant="standard"
                              hiddenLabel
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={5}>
                      <div className="float-right text-right">
                        <h4 className="FontStyle">
                          {DispensingLanguage[lan][menukey]["Total Product"] +
                            ": " +
                            ProductCount}
                        </h4>
                      </div>
                    </Grid>

                    <Grid item xs={6} sm={6} md={7}>
                      <div className="d-flex justify-content-end align-items-center sw_btn_control">
                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2"
                          onClick={() => {
                            props.history.push("/products/add");
                          }}
                        >
                          {DispensingLanguage[lan][menukey]["Add Product"]}
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("print")}
                        >
                          <i className="fas fa-print"></i>
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          <div className="layoutsettings-container">
            <div className="text-right icon-container">
              <i
                onClick={saveLayoutSettingsHandler}
                style={{ cursor: "pointer" }}
                className="far fa-save"
                aria-hidden="true"
                title={t(
                  DispensingLanguage[lan][menukey]["Save Layout Settings"]
                )}
              ></i>

              <i
                onClick={clearLayoutSettingsHandler}
                style={{ cursor: "pointer" }}
                className="far fa-stop-circle"
                aria-hidden="true"
                title={t(
                  DispensingLanguage[lan][menukey]["Clear Layout Settings"]
                )}
              ></i>
            </div>
          </div>

          <div className="sw_relative">
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height={450}
                rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
                options={{
                  groupBy: "GroupName",
                  columnCalcs: "both",
                }}
                //initialSort={[{ column: "GroupName", dir: "desc" }]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
