import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import "../common.css";

import swal from "sweetalert";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMutation } from "react-query";
import * as api from "../../../actions/api";

import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";

function hasRemarks(CalcOrderQty, ActualOrderQty, Remarks) {
  // console.log(CalcOrderQty, ActualOrderQty, Remarks, '=========');
  const CalcQty = Number(CalcOrderQty);
  const ActualQty = Number(ActualOrderQty);
  const Rmkrs = !(Remarks == null || Remarks == "") ? true : false;

  if (CalcQty !== ActualQty) {
    if (!Rmkrs) {
      return false;
    }
  }
  return true;
}

let isDirty = false;
let masterDirty = false;

const OrderFormData = (props) => {
  //get DispensingLanguage
  const { t, i18n } = useTranslation();
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "order";
  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  let tblRef = null;
  const classes = useStyles();

  const [manyTableData, setManyTableData] = useState([]);
  const [bCompleted, setbCompleted] = useState(false);
  const OrderType = JSON.parse(localStorage.getItem("OrderType"));
  let OrderTo = JSON.parse(localStorage.getItem("OrderTo"));
  // const OrderTo = OrderToList.hasOwnProperty(props.formData.ItemGroupId)?OrderToList[props.formData.ItemGroupId]:[];
  const UsersListByFacility = JSON.parse(
    localStorage.getItem("UsersListByFacility")
  );

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const roleObj = UserInfo == 0 ? "" : UserInfo[0].role;
  const UserRoleId = UserInfo == 0 ? 0 : roleObj[0].id;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo === 0 ? "" : UserInfo[0].name;

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroupList = [All_Product].concat(Product_Group);

  const [activeProductGroupData, setAcProductGroupData] = useState({
    id: ProductGroupList.length > 0 ? ProductGroupList[0].id : "",
    name: ProductGroupList.length > 0 ? ProductGroupList[0].name : "",
  });

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({ menukey });
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({
    menukey,
  });

  const handleProductGroupChange = (event, newValue) => {
    setAcProductGroupData(newValue);
    if (props.addProductForm == false) {
      //props.filterFandleChange(newValue.id);
    }
  };

  // ================ DELETE ALL AND RECREATE ORDER START ================

  const handleDeleteAllRecreateOrder = async (e) => {
    let validForm = props.checkValidateForm(e, "del");

    if (validForm) {
      let FinalData = {
        OrderId: props.formData.OrderId,
        FacilityId: props.formData.FacilityId,
        lan: lan,
        UserId: UserId,
        UserName: UserName,
      };

      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to delete all and recreate?"
          ]
        ),
        icon: "warning",
        buttons: [
          t(DispensingLanguage[lan][menukey]["No"]),
          t(DispensingLanguage[lan][menukey]["Yes"]),
        ],
        dangerMode: true,
      }).then((willAction) => {
        if (willAction) {
          mutateDeleteAllReOrder(FinalData);
        }
      });
    }
  };

  const { isLoading: isLoadingRecreate, mutate: mutateDeleteAllReOrder } =
    useMutation(api.deleteAllRecreateOrderInv, {
      onSuccess: (data) => {
        if (data.status == 200) {
          props.openNoticeModal({
            isOpen: true,

            msg: data.message,
            msgtype: data.success,
          });

          props.refetch();
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      },
    });

  // ================ DELETE ALL AND RECREATE ORDER END ================

  /* ====Start of Print Excel Many Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/order_stock_entry_list_print_Main.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&OrderId=" +
        props.formData.OrderId +
        "&OnlyOrderedItems=" +
        OnlyOrderedItems +
        "&ItemGroupId=" +
        activeProductGroupData.id +
        "&Facilityid=" +
        props.formData.FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/order_stock_entry_list_excel_main.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&OrderId=" +
        props.formData.OrderId +
        "&OnlyOrderedItems=" +
        OnlyOrderedItems +
        "&ItemGroupId=" +
        activeProductGroupData.id +
        "&Facilityid=" +
        props.formData.FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const manycolumns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      hozAlign: "center",
      formatter: "rownum",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 135,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      // headerFilter: true,
      width: 180,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Min Qty"],
      field: "MinQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Max Qty"],
      field: "MaxQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock on Hand"],
      field: "StockOnHand",
      width: 110,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Calculated Order Quantity"],
      field: "CalcOrderQty",
      width: 165,
      hozAlign: "right",
      headerHozAlign: "right",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actual Order Quantity"],
      field: "ActualOrderQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 165,
      editor: bCompleted == -1 ? cellFocusEditor : "",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
        let CellOldValue = cell._cell.oldValue;
        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }
        if (CellOldValue != curcellval) {
          currow.getCell("dirty").setValue(1);
          isDirty = true;
          onSetDisable();
        }
      },
      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue(); /* Actual Order Quantity */
        // if(!cellValue) return;
        const rowData = cell.getRow().getData();
        const CalcOrderQty = rowData.CalcOrderQty;
        const Remarks = rowData.Remarks;

        if (!hasRemarks(CalcOrderQty, cellValue, Remarks)) {
          cell.getRow().getElement().style.backgroundColor = "#ec7063";
        } else {
          cell.getRow().getElement().style.backgroundColor = "transparent";
        }

        // if (CalcOrderQty !== cellValue && (Remarks == null || Remarks == "")) {
        //   cell.getRow().getElement().style.backgroundColor = "#ec7063";
        // } else {
        //   cell.getRow().getElement().style.backgroundColor = "transparent";
        // }

        return cellValue;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Comment"],
      field: "Remarks",
      // width: 110,
      hozAlign: "left",
      headerHozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      // editor: bCompleted == -1? true : '',
      editor: bCompleted == -1 ? cellFocusEditor : "",

      formatter: function (cell, formatterParams) {
        const cellValue = cell.getValue();
        if (!cellValue) return;
        const rowData = cell.getRow().getData();
        const CalcOrderQty = rowData.CalcOrderQty;
        const ActualOrderQty = rowData.ActualOrderQty;
        const Remarks = rowData.Remarks;

        if (!hasRemarks(CalcOrderQty, ActualOrderQty, Remarks)) {
          cell.getRow().getElement().style.backgroundColor = "#ec7063";
        } else {
          cell.getRow().getElement().style.backgroundColor = "transparent";
        }

        // if (CalcOrderQty < ActualOrderQty && (Remarks == null || Remarks == "")) {
        //   cell.getRow().getElement().style.backgroundColor = "#ec7063";
        // } else {
        //   cell.getRow().getElement().style.backgroundColor = "transparent";
        // }

        return cellValue;
      },
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        //   props.setUpdatedDetailsGridDataHandler(rowdata);
        let CellOldValue = cell._cell.oldValue;
        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }
        if (CellOldValue != curcellval) {
          currow.getCell("dirty").setValue(1);
          isDirty = true;
          onSetDisable();
        }
      },
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Unit Price"],
    //   field: "UnitPrice",
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   width: 90,
    //   visible: false,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Line Total"],
    //   field: "LineTotal",
    //   formatter: "money",
    //   hozAlign: "right",
    //   bottomCalc: "sum",
    //   bottomCalcFormatter: "money",
    //   headerHozAlign: "right",
    //   width: 105,
    //   visible: false,
    // },
  ];

  // const ManyDataItems = props.ManyTableData?.map((item, index) => {
  // item["LineTotal"] = item.ActualOrderQty*item.UnitPrice;
  // return item
  // })

  const onSetDisable = () => {
    if (isDirty == true) {
      //document.getElementById("is_dirty_message").classList.remove("dnone");
      document.getElementById("master_dirty_message").classList.remove("dnone");
    } else {
      //document.getElementById("is_dirty_message").classList.add("dnone");
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  };

  const onMasterSetDirty = () => {
    if (masterDirty == true) {
      document.getElementById("master_dirty_message").classList.remove("dnone");
    } else {
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  };

  /* Start Save Post In Same Page */

  const onPostBtnClick = () => {
    if (tblRef == null) return;
    handlePost();
  };

  const onDataValidation = (data) => {
    // console.log(data, '=======')
    let ValidationCount = 0;

    for (let i = 0; i < data.length; i++) {
      let CalcOrderQty = data[i].CalcOrderQty;
      let ActualOrderQty = data[i].ActualOrderQty;
      let Remarks = data[i].Remarks;

      if (
        ActualOrderQty != null &&
        ActualOrderQty != "" &&
        ActualOrderQty != CalcOrderQty
      ) {
        if (Remarks == null || Remarks == "") {
          ValidationCount++;
        }
      }

      if (ValidationCount > 0) {
        return ValidationCount;
      }
    }
    return ValidationCount;
  };

  const handlePost = async (e) => {
    let manydata = tblRef.table.getData();

    let ValidationCount = onDataValidation(manydata);
    if (ValidationCount > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey][
          "Please, mention reason of actual order quantity change"
        ],
        msgtype: 0,
        duration: 5000,
      });
      return;
    }

    let manydataP = [];
    tblRef.table.getData().forEach((row, i) => {
      if (row.dirty == 1) manydataP.push(row);
    });

    let validForm = props.checkValidateForm(manydataP, "post");

    if (validForm) {
      let FinalData = {
        ...validForm,
        IsCompleted: 1,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
        masterDirty: masterDirty,
      };
      modalPopupDelete(
        mutateUpdate,
        FinalData,
        { PostQ: "OrderPost" },
        ModalNo,
        validForm
      );
    }
  };

  const ModalNo = (validForm) => {
    props.stockPosted(-1);
    let route = `${validForm.OrderId}`;
    props.history.push(route);
  };

  const handlePost11111 = async (rows) => {
    let validForm = props.checkValidateForm(tblRef.table.getData(), "post");

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to complete this order stock?"
          ]
        ),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]),
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "sw_confirm_btn",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          //setStockPost(true);

          let FinalData = {
            ...validForm,
            ManyJsonSave: rows,
            IsCompleted: 1,
            menukey: menukey,
            lan: lan,
            UserName: UserName,
          };
          mutateUpdate(FinalData);
          setManyTableData(rows);
        } else {
          //setbCompleted(false);
          props.stockPosted(-1);
          let route = `${validForm.OrderId}`;
          props.history.push(route);
        }
      });
    }
  };

  const onSaveUpdateBtnClick = () => {
    let manydata = tblRef.table.getData();

    let ValidationCount = onDataValidation(manydata);
    if (ValidationCount > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey][
          "Please, mention reason of actual order quantity change"
        ],
        msgtype: 0,
        duration: 5000,
      });
      return;
    }

    setUpdatedDetailsGridDataHandler(manydata);
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let manydataR = [];
    rows.forEach((row, i) => {
      if (row.dirty == 1) manydataR.push(row);
    });

    let validForm = props.checkValidateForm(manydataR, "edit");
    // console.log('isval: ', validForm);
    if (validForm) {
      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutate({ ...validForm, masterDirty: masterDirty });
      } else {
        //when update
        mutateUpdate({ ...validForm, masterDirty: masterDirty });
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveOrderInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        masterDirty = false;
        onMasterSetDirty();
        isDirty = false;

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `edit/${data.id}`;
        props.history.push(route);
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.updateOrderInv,
    {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) {
          masterDirty = false;
          onMasterSetDirty();
          isDirty = false;

          props.successSave(data.IsCompleted);
          if (data.IsCompleted == 1) {
            mutatePost({
              OrderId: props.formData.OrderId,
              FacilityId: props.formData.FacilityId,
              lan: lan,
              UserName: UserName,
            });
          } else {
            props.successSave(data.IsCompleted); //refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(
    api.postOrderInv,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          if (data.data.success == 0) {
            props.stockPosted(-1);
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });

            // swal(data.data.message, "", "warning");
          } else {
            props.stockPosted(0);
            props.refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });

            //swal(data.data.message, "", "success");
          }
        } else {
          props.stockPosted(-1);
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      },
    }
  );

  /* End Save Post In Same Page*/

  const backToList = () => {
    if (masterDirty == true || isDirty == true) {
      modalPopupBacktoList(backToListCallBack, "/order", {
        PostQ: "modalBackToListQ",
      });
    } else props.history.push("/order");
  };

  const backToListCallBack = (PageUrl) => {
    masterDirty = false;
    isDirty = false;
    props.history.push(PageUrl);
  };

  const formOnchange = (e, cases, inputname) => {
    console.log("On form change", inputname);
    //isDirty=true;

    if (cases == "handleChange") props.handleChange(e);
    else if (cases == "handleRDateChange")
      props.handleRDateChange(e, inputname);

    masterDirty = true;
    onMasterSetDirty();
  };

  useEffect(() => {
    if (tblRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }
    setbCompleted(props.bCompleted);
  }, [props.ManyTableData, props.bCompleted]);

  useEffect(() => {
    if (props.addProductForm) {
      if (props.formData.OrderBy == "") {
        props.formData.OrderBy = UserId;
      }
    }
  }, [props.formData.OrderBy, UserId, props.addProductForm]);

  useEffect(() => {
    if (props.addProductForm) {
      let OrderingFromId = OrderTo.length == 1 ? OrderTo[0].id : "";
      if (OrderingFromId != "") {
        props.formData.OrderingFrom = OrderingFromId;
      }
    }
  }, [props.formData.OrderingFrom, OrderTo, props.addProductForm]);

  const [checked, setChecked] = useState(false);
  const [OnlyOrderedItems, setOnlyOrderedItems] = useState(false);
  const handleOnlyOrderdItems = (event) => {
    setChecked(event.target.checked);
    setOnlyOrderedItems(event.target.checked);
  };

  //console.log("kkkk",activeProductGroupData.id);
  const manyTableDataFilter = manyTableData.filter(
    (item) =>
      (OnlyOrderedItems == true ? item.ActualOrderQty > 0 : 0 == 0) &&
      (item.ItemGroupId == activeProductGroupData.id ||
        activeProductGroupData.id == 0)
  );

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          {/* <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Order - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                
                {props.addProductForm ? (
                  <Grid item xs={12} className="mt-4 text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => props.handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/order")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => props.handleUpdate()}
                      disabled={bCompleted == -1 ? false : true}
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>

                    <Button
                     
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => props.handlePost()}
                      disabled={bCompleted == -1 ? false : true}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                     
                      onClick={() => props.history.push("/order")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
               
              </div>
            </div>
          </Grid> */}

          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Order - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <div className="mr-4 mt-2 float-left">
                        <span
                          id="master_dirty_message"
                          className={"masterredtextcolor dnone"}
                        >
                          {" "}
                          {
                            DispensingLanguage[lan][menukey][
                              "Form has dirty data"
                            ]
                          }
                        </span>
                      </div>
                      {/* <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button> */}
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        //onClick={() => props.handleSubmit()}
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/order")}
                        onClick={() => backToList()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <div className="mr-4 mt-2 float-left">
                      <span
                        id="master_dirty_message"
                        className={"masterredtextcolor dnone"}
                      >
                        {" "}
                        {
                          DispensingLanguage[lan][menukey][
                            "Form has dirty data"
                          ]
                        }
                      </span>
                    </div>
                    <Button
                      disabled={
                        (props.bCompleted == -1 ? false : true) ||
                        isLoadingUpdate ||
                        isLoadingPost ||
                        isLoadingRecreate
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      //onClick={() => props.handleUpdate()}
                      onClick={onSaveUpdateBtnClick}
                      //disabled={bCompleted == -1 ? false : true}
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>

                    {/* <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleDeleteAllRecreateOrder()}
                      disabled={
                        (props.bCompleted == -1 ? false : true) ||
                        isLoadingRecreate
                      }
                    >
                      {
                        DispensingLanguage[lan][menukey][
                          "DELETE ALL and RECREATE"
                        ]
                      }
                    </Button> */}

                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      //onClick={() => props.handlePost()}
                      // disabled={bCompleted == -1 ? false : true}
                      onClick={onPostBtnClick}
                      disabled={
                        (props.bCompleted == -1 ? false : true) ||
                        isLoadingUpdate ||
                        isLoadingPost ||
                        isLoadingRecreate
                      }
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/order")}
                      onClick={() => backToList()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        required
                        error={props.errorObject.OrderDate}
                        helperText={props.errorObject.OrderDate}
                        id="OrderDate"
                        label={DispensingLanguage[lan][menukey]["Order Date"]}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        autoOk={true}
                        name="OrderDate"
                        fullWidth
                        showTodayButton={true}
                        value={props.formData.OrderDate || props.selectedDate}
                        format="dd/MM/yyyy"
                        //onChange={(e) => props.handleRDateChange(e, "OrderDate")}
                        onChange={(e) =>
                          formOnchange(e, "handleRDateChange", "OrderDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disabled={bCompleted == -1 ? false : true}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      error={props.errorObject.OrderNo}
                      helperText={props.errorObject.OrderNo}
                      required
                      id="OrderNo"
                      name="OrderNo"
                      label={DispensingLanguage[lan][menukey]["Order No"]}
                      value={props.formData.OrderNo || props.generatedInvoiceNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Order Type"]}
                        <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.OrderTypeId}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="OrderTypeId"
                        name="OrderTypeId"
                        value={props.formData.OrderTypeId}
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>
                          formOnchange(e, "handleChange", "OrderTypeId")
                        }
                        disabled={bCompleted == -1 ? false : true}
                      >
                        {OrderType.map((item, index) => {
                          return (
                            <MenuItem
                              key={`OrderType-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.OrderTypeId}>
                        {props.errorObject.OrderTypeId}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Order To"]}
                        <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.OrderingFrom}
                        // helperText={props.errorObject.OrderingFrom}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="OrderingFrom"
                        name="OrderingFrom"
                        value={props.formData.OrderingFrom}
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>
                          formOnchange(e, "handleChange", "OrderingFrom")
                        }
                        disabled={bCompleted == -1 ? false : true}
                      >
                        {OrderTo.map((item, index) => {
                          return (
                            <MenuItem
                              key={`OrderTo-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.OrderingFrom}>
                        {props.errorObject.OrderingFrom}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                     disabled={bCompleted == -1 ? false : true}
                      // error={props.errorObject.Remarks}
                      // helperText={props.errorObject.Remarks}
                      // required
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>
                        formOnchange(e, "handleChange", "Remarks")
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Entry By"]}
                        <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.OrderBy}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="OrderBy"
                        name="OrderBy"
                        value={props.formData.OrderBy}
                        fullWidth
                       
                        onChange={(e) =>formOnchange(e,'handleChange','OrderBy')}
                        disabled={bCompleted == -1 ? false : true}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UsersListByFacility-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.OrderBy}>
                        {props.errorObject.OrderBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["InternalApprover"]}
                      </InputLabel>
                      <Select
                        error={props.errorObject.InternalApprover}
                        labelId="demo-simple-select-helper-label"
                        id="InternalApprover"
                        name="InternalApprover"
                        value={props.formData.InternalApprover}
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','InternalApprover')}
                        disabled={bCompleted == -1 ? false : true}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UsersListByFacility-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText
                        error={props.errorObject.InternalApprover}
                      >
                        {props.errorObject.InternalApprover}
                      </FormHelperText>
                    </FormControl>
                  </Grid>*/}
                </Grid>
              </Grid>
              <TextField
                id="FacilityId"
                name="FacilityId"
                label="FacilityId"
                value={props.formData.FacilityId}
                fullWidth
                hidden
                autoComplete="family-name"
              />
              <TextField
                id="OrderId"
                name="OrderId"
                label="OrderId"
                value={props.formData.OrderId}
                fullWidth
                hidden
                autoComplete="family-name"
              />

              {/* <Grid item xs={12} sm={12}></Grid> */}
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  {/* new row */}
                  <Grid item xs={12} sm={12}>
                    <Card className="sw_card">
                      <CardContent>
                        <div className="">
                          <div className={`${classes.fullWidth} d-flex mb-2`}>
                            <div className={classes.fullWidth}>
                              <FormControl
                                style={{ maxWidth: "360px" }}
                                className={classes.fullWidth}
                              >
                                <Autocomplete
                                  autoHighlight
                                  className="sw_chosen_filter"
                                  id="ProductGroupList"
                                  disableClearable
                                  options={ProductGroupList}
                                  value={activeProductGroupData}
                                  onChange={(event, newValue) =>
                                    handleProductGroupChange(event, newValue)
                                  }
                                  disabled={
                                    props.addProductForm == true ? true : false
                                  }
                                  getOptionLabel={(option) => option.name || ""}
                                  renderOption={(option) => (
                                    <Typography className="sw_fontSize">
                                      {option.name}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        DispensingLanguage[lan][menukey][
                                          "Product Group"
                                        ]
                                      }
                                      variant="standard"
                                    />
                                  )}
                                />
                              </FormControl>
                            </div>
                            <div
                              className={`${classes.fullWidth} d-flex align-items-center justify-content-end`}
                            >
                              <FormControlLabel
                                className="OrderApprovalCheck"
                                control={
                                  <Checkbox
                                    color="primary"
                                    name="bDispensingFilter"
                                    // checked={props.checked}
                                    checked={checked}
                                    //onChange={props.handleOnlyOrderdItems}
                                    onChange={handleOnlyOrderdItems}
                                    disabled={
                                      props.addProductForm == true
                                        ? true
                                        : false
                                    }
                                    //value="no"
                                  />
                                }
                                // label={DispensingLanguage[lan][menukey]["Only Ordered Items"]}
                                label={
                                  <span
                                    onClick={(e) => e.preventDefault()}
                                    style={{ cursor: "default" }}
                                  >
                                    {
                                      DispensingLanguage[lan][menukey][
                                        "Only Ordered Items"
                                      ]
                                    }
                                  </span>
                                }
                              />

                              <div>
                                <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleDeleteAllRecreateOrder()}
                                  // disabled={bCompleted == -1 ? false : true} /* OLDER COMMNET */
                                  // disabled={(props.bCompleted == -1 ? false : true) || isLoadingUpdate || isLoadingPost || isLoadingRecreate}
                                  disabled={
                                    props.addProductForm == true
                                      ? true
                                      : (props.bCompleted == -1
                                          ? false
                                          : true) || isLoadingRecreate
                                  }
                                >
                                  {
                                    DispensingLanguage[lan][menukey][
                                      "DELETE ALL and RECREATE"
                                    ]
                                  }
                                </Button>
                              </div>
                            </div>
                          </div>

                          {/* <Grid container className="mb-2">

                            <Grid item xs={6} sm={manycolumnsup3}>
                              <FormControl className={classes.fullWidth}>
                                <Autocomplete
                                  autoHighlight
                                  className="sw_chosen_filter"
                                  id="ProductGroupList"
                                  disableClearable
                                  options={ProductGroupList}
                                  value={activeProductGroupData}
                                  onChange={(event, newValue) =>
                                    handleProductGroupChange(event, newValue)
                                  }
                                  disabled={
                                    props.addProductForm == true ? true : false
                                  }
                                  getOptionLabel={(option) => option.name || ""}
                                  renderOption={(option) => (
                                    <Typography className="sw_fontSize">
                                      {option.name}
                                    </Typography>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={
                                        DispensingLanguage[lan][menukey][
                                        "Product Group"
                                        ]
                                      }
                                      variant="standard"
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={6} sm={3}>
                              <FormControlLabel className="OrderApprovalCheck d-flex justify-content-end"
                                control={
                                  <Checkbox
                                    color="primary"
                                    name="bDispensingFilter"
                                    checked={props.checked}
                                    onChange={props.handleOnlyOrderdItems}
                                    disabled={
                                      props.addProductForm == true ? true : false
                                    }
                                  //value="no"
                                  />
                                }
                                label={DispensingLanguage[lan][menukey]["Only Ordered Items"]}
                              />
                            </Grid>
                          </Grid> */}

                          <div className="uniqueName">
                            <ReactTabulator
                              ref={(r) => (tblRef = r)}
                              columns={manycolumns}
                              data={manyTableDataFilter}
                              layout={"fitColumns"}
                              height="320px"
                              options={{
                                groupBy: ["GroupName"],

                                columnCalcs: "both",
                              }}
                            />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OrderFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
