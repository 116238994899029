import React, { useState } from "react";
import swal from "sweetalert";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
import { useMutation } from "react-query";
// material components
import {
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardContent,
  Button,
  CardHeader,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"; 

const StockStatusDifferentLevel = (props) => {
  const [pivotData, setpivotData] = useState([]);
  const [pivotfieldlist, setpivotfieldlist] = useState({});
  const [pivotfields, setpivotfields] = useState({});

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "stock-status-at-different-level";
  const [isLoadingUi, setLoading] = useState(true);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);

  exporting(Highcharts);

  // Filter Start

  //region combo list
  const [RegionList, setRegionListData] = useState([]);
  const [activeRegionData, setAcRegionData] = useState({
    id: RegionList.length > 0 ? RegionList[0].id : 0,
    name: RegionList.length > 0 ? RegionList[0].name : "",
  });

  React.useEffect(() => {
    if (activeRegionData.id == 0) {
      getRegionData();
      //getFacilityData(0, 0);
    }
  }, [activeRegionData.id]);

  const getRegionData = () => {
    let FacilitylevelParam = {
      action: "getDepartmentforreport",
      RoleId: 1, 
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cRegionList(FacilitylevelParam);
  };

  const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setRegionListData(data.data.datalist);

        setAcRegionData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getCommuneData(data.data.datalist[0].id);
      }
    },
  });

  const handleDepartmentChange = (event, newValue) => {
    setAcRegionData(newValue);
    getCommuneData(newValue.id);
    //getFacilityData(newValue.id, 0);
  };

  ////distict combo list
  const [Communelist, setCommunelistData] = useState([]);
  const [activeCommuneData, setAcCommuneData] = useState({
    id: Communelist.length > 0 ? Communelist[0].id : 0,
    name: Communelist.length > 0 ? Communelist[0].name : "",
  });

  const getCommuneData = (RegionId) => {
    let FacilitylevelParam = {
      action: "getcommuneforreport",
      RoleId: 1, 
      UserId: UserId,
      RegionId: RegionId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
    onSuccess: (data,params) => {
      if (data.status == 200) {
        setCommunelistData(data.data.datalist);

        setAcCommuneData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        refreshAllData(params.RegionId,data.data.datalist.length ? data.data.datalist[0].id : 0,currGroupId);
    
      }
    },
  });

  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setAcCommuneData(newValue);

    refreshAllData(activeRegionData.id,newValue.id,currGroupId);
    
  };
 

  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Item_label = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const GroupList = [All_Item_label].concat(ProductGroupList);

  const [currGroupId, setcurrGroupId] = useState(0);
  const [GroupName, setgroupName] = useState(t(DispensingLanguage[lan][menukey]["All"]));
  // console.log(GroupName);
  const handleGroupChange = (event, newValue) => {
    let rowId = "";
    let rowname = "";
    if (newValue == null) {
      rowId = "";
      rowname = "";
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }
    refreshAllData(activeRegionData.id,activeCommuneData.id,rowId);
    setcurrGroupId(rowId);
    setgroupName(rowname);
  };

  const [fieldList, setfieldList] = useState([]);
  const [dataItems, setDataItems] = useState([]);

  let params = {
    menukey: menukey,
    action: "StockStatusatDifferentLevelTable",
    // FLevelId: activeFlevelData.id,
    RegionId: activeRegionData.id,
    DistrictId: activeCommuneData.id,
    // FacilityId: activeFacilityData.id,
    ItemGroupId: currGroupId,
    lan: lan,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    // mode: bEsigl
  };
  
  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.StockStatusDifferentTable,
    {
      onSuccess: (data) => {

        if(data.data.data!=undefined){

          let tmpfield1 = [];
        let tmpfield2 = [];
        
        let j=0;
        for (let i = 0; i < data.data.data.column.length; i++) {

          if(i==0)
            
          tmpfield1.push({
           
            title: t(DispensingLanguage[lan][menukey][data.data.data.column[i]]),
            field:  data.data.data.column[i],
          //  width: data.data.collist[i].width,
           // hozAlign: data.data.collist[i].hozAlign,
           // headerHozAlign: data.data.collist[i].headerHozAlign,
            headerSort: false,
            
          });
         
          else {

             j++;
             tmpfield2.push({
              title: t(DispensingLanguage[lan][menukey][data.data.data.column[i].substring(-1,3)]),
              field:  data.data.data.column[i], 
              hozAlign: "right",
              headerHozAlign: "right",
              headerSort: false, 
              formatter:"money", 
              formatterParams:{
                decimal:".",
                thousand:",", 
               // symbolAfter:"p",
               // negativeSign:true,
                precision:j==3?true:false,
            }
            });

            if(j==3){
             
              tmpfield1.push({
                title: data.data.data.column[i].substring(4),
                hozAlign: "center",
                headerHozAlign: "center",
                headerSort: false,
                columns: tmpfield2 
              });
              tmpfield2 = [];
              j=0;

            }
           



          }
          
         
        }
        setfieldList(tmpfield1);
        setDataItems(data.data.data.list);


        }
        

        //   
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    }
  );

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintMany = () => {
    
    let finalUrl = EXCEL_EXPORT_URL + "report/StockStatusDifferentLevelPrint.php";
  
    window.open(
      finalUrl +"?menukey=" +menukey
       + "&lan=" + localStorage.getItem("LangCode")+
       "&RegionId=" +
       activeRegionData.id +
       "&DistrictId=" +
       activeCommuneData.id +
       "&ItemGroupId=" +
        currGroupId + 
        "&DepartmentName=" +
        activeRegionData.name +
        "&DistrictName=" +
        activeCommuneData.name +
        "&ProductGroup=" +
        GroupName +
       "&menukey=" +
       menukey +
       "&lan=" +
       localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
  
  const ExcelMany = () => {
    
    let finalUrl = EXCEL_EXPORT_URL + "report/PercentageOfFacilityMosScale_Excel.php";
  
    window.open(
      finalUrl +"?menukey=" +menukey
       + "&lan=" + localStorage.getItem("LangCode")+
       "&RegionId=" +
       activeRegionData.id +
       "&DistrictId=" +
       activeCommuneData.id +
       "&ItemGroupId=" +
        currGroupId + 
        "&DepartmentName=" +
        activeRegionData.name +
        "&DistrictName=" +
        activeCommuneData.name +
        "&ProductGroup=" +
        GroupName +
       "&menukey=" +
       menukey +
       "&lan=" +
       
       localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
   

  /* =====End of Excel Export Code==== */
  const [receive_details, setOption_receive_details] = useState(
    {
      chart: {
        type: "bar",
        borderColor: "#C3DDEC",
        height: 600,
        exporting: {
          enabled: true,
        },
        //   borderWidth: 1,
        //   plotBorderWidth: 1,
        //  margin: [50, 50, 50, 300],
      },
      title: {
        text: t(
          DispensingLanguage[lan][menukey]["Stock Status at Different Level"]
        ),
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        min: 0,
        max: 10,
        title: {
          text: t(DispensingLanguage[lan][menukey]["Month of Stock (MOS)"]),
          align: "middle",
        },
        labels: {
          overflow: "justify",
        },
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
        pointPadding: 0,
				borderWidth: 0,

        bar: {
          dataLabels: {
            enabled: true,
            crop: true,
            format: "<b>{point.y:.1f}",
            formatter: function () {
              return this.y > 0 ? this.y : "";
            },
            style: {
              textShadow: false,
              textOutline: false 
            }
          },
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valuePrefix: t(
          DispensingLanguage[lan][menukey]["Month of Stock (MOS)"]
        ),
        pointFormat: "{series.name}: {point.y:.1f} </br>",
        shared: true,
      },
      series: [],
    }
  );
   

  
  const { mutate: NationalStockStatusChart } = useMutation(api.StockStatusDifferentChart, {
    onSuccess: (data) => {
      if (data.status == 200) { 

       let Ndata = [];

       for (let i = 0; i < data.data.NationalStockCategory.data.length; i++) {
         let newdata = {};
         newdata.y = data.data.NationalStockCategory.data[i].data;
         newdata.color = data.data.NationalStockCategory.data[i].color;

         Ndata.push(newdata);
       }

       

       setOption_receive_details( {
         chart: {
           type: "bar",
           borderColor: "#C3DDEC",
           height: 600,
           exporting: {
             enabled: true,
           } 
         },
         title: {
           text: t(
             DispensingLanguage[lan][menukey]["Stock Status at Different Level"]
           ),
         },
         xAxis: {
           categories: data.data.NationalStockCategory.category,
         },
         yAxis: {
           min: 0,
           max: 10,
           title: {
             text: t(DispensingLanguage[lan][menukey]["Month of Stock (MOS)"]),
             align: "middle",
           },
           labels: {
             overflow: "justify",
           },
         },
         legend: {
           reversed: true,
         },
         plotOptions: {
           series: {
             stacking: "normal",
           },
           pointPadding: 0,
           borderWidth: 0,
   
           bar: {
             dataLabels: {
               enabled: true,
               crop: true,
               format: "<b>{point.y:.1f}",
               formatter: function () {
                 return this.y > 0 ? this.y : "";
               },
               style: {
                 textShadow: false,
                 textOutline: false 
               }
             },
           },
         },
         credits: {
           enabled: false,
         },
         tooltip: {
           valuePrefix: t(
             DispensingLanguage[lan][menukey]["Month of Stock (MOS)"]
           ),
           pointFormat: "{series.name}: {point.y:.1f} </br>",
           shared: true,
         },
         series: data.data.NationalStockCategory.data,
       }); 

      }
    },
  });
  
  const refreshAllData = ( RegionId,DistrictId, ItemGroupId) => {
    
    let params = {
      menukey: menukey, 
      lan: lan,
      RegionId: RegionId,
      DistrictId: DistrictId, 
      ItemGroupId: ItemGroupId,
      lan: lan,
    }; 
    //NationalStockStatusTable({"queryKey":[{...params,action:"StockStatusatDifferentLevelTable"}]}); 
    NationalStockStatusChart({"queryKey":[{...params,action:"StockStatusDifferentLevelChart"}]}); 
  };


  // End Stock By Expiry Date Pie

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="section signup-top-padding sw_inner_container">
        <div className="sw_inner_card">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(
                    DispensingLanguage[lan][menukey][
                      "Stock Status at Different Level"
                    ]
                  )}
                </div>
              </div>
            </Grid>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card mb-3">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid> */}

                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="GroupList"
                          disableClearable
                          options={GroupList}
                          onChange={(event, newValue) =>
                            handleGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            GroupList[
                              GroupList.findIndex(
                                (GroupList) => GroupList.id == currGroupId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Groups"]}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  {/* <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12 mb-3">
              <Card className="sw_card">
                {/* <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "National Stock Status Report"
                    ]
                  )}
                /> */}
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={receive_details}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}

          <div className="row">
            <div className="col-md-12 mb-3">
              <Card className="sw_card">
                <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "Stock Status at Different Level"
                    ]
                  )}
                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    {/* <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={fieldList}
                          data={dataItems}
                          // options={{
                          //   layout: 'fitColumns',
                          //   movableColumns: true,
                          //   resizableRows: true,
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
};

export default StockStatusDifferentLevel;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});
