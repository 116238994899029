import React, { useState, Component, useEffect, useContext } from "react"; 
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
//import { Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Select,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import {
  Launch
} from "@material-ui/icons";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { useTranslation } from "react-i18next";
//import * as Service from "../../../services/Service.js";
//import Constants from "services/Constants.js";

//import { UserContext } from "../../../context/user-info-context";
//import { getDefaultMonthYear } from "../../../services/Common";
//Import Facility Reporting Rate
//import FacilityReportingRate from "services/FacilityReportingRate";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";


const FacilityStockStatusbyProduct = (props) => {
  const mapStyles = {
    width: '92%',
    height: '90%',
    overflow: "hidden !important",
  };

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facility-stock-status-by-product-map";
  const { t, i18n } = useTranslation();
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
    const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;

  const FLevelList = JSON.parse(localStorage.getItem("FlevelList"));
  const tmpmostypelist = JSON.parse(localStorage.getItem("MosTypelist"));
  let All_MosType = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const MosTypelist = [All_MosType].concat(tmpmostypelist);

  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));

  const currentDate = new Date();
  const [currmonthId, setmonthId] = useState(currentDate.getMonth() + 1);
  const [currMonthName, setMonthName] = useState(MonthList[currentDate.getMonth() + 1].name);
  const [currYearId, setYearId] = useState(currentDate.getFullYear());
  const [currMostypeId, setMostypeId] = useState(MosTypelist[0].id);
  const [currMostypeName, setMostypeName] = useState(MosTypelist[0].name);


  const [currFLevelId, setFLevelId] = useState(99);
  const [currFLevelName, setFLevelName] = useState(FLevelList[2].name);

  
  //region combo list
  const [RegionList, setRegionListData] = useState([]);
  const [activeRegionData, setAcRegionData] = useState({
    id: RegionList.length > 0 ? RegionList[0].id : 0,
    name: RegionList.length > 0 ? RegionList[0].name : "",
  });

  React.useEffect(() => {
    if (activeRegionData.id == 0) {
      getRegionData();
    }
  }, [activeRegionData.id]);

 
  const handleDepartmentChange = (event, newValue) => { 

    setAcRegionData(newValue);
    getCommuneData(newValue.id); 
  };
  
  const getRegionData = () => {
    let FacilitylevelParam = {
      action: "getDepartmentforreport",
      RoleId: 1, 
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cRegionList(FacilitylevelParam);
  };

  const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setRegionListData(data.data.datalist);

        setAcRegionData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getCommuneData(data.data.datalist[0].id);
      }
    },
  });
 
  ////distict combo list
  const [Communelist, setCommunelistData] = useState([]);
  const [activeCommuneData, setAcCommuneData] = useState({
    id: 0,
    name: Communelist.length > 0 ? Communelist[0].name : "",
  });
 
  const getCommuneData = (RegionId) => {
    let FacilitylevelParam = {
      action: "getcommuneforreport",
      RoleId: 1,
      UserId: UserId,
      RegionId: RegionId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
    onSuccess: (data,params) => {
 
      if (data.status == 200) {
        setCommunelistData(data.data.datalist);

        setAcCommuneData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        
        // refreshAllData(
        //   params.RegionId,
        //   data.data.datalist.length ? data.data.datalist[0].id : 0,
        //   currFLevelId,
        //   currProductGroupId,
        //   acValue.id,
        //   currMostypeId
        // );
      
      }
    },
  });

  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    refreshAllData(
      activeRegionData.id,
      newValue.id,
      currFLevelId,
      currProductGroupId,
      acValue.id,
      currMostypeId
    );
    setAcCommuneData(newValue);
    
  };

  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroupList"));
  
  const [currProductGroupId, setProductGroupId] = useState(ProductGroup.length>0?ProductGroup[0].id:0);
  const [ProductGroupName, setProductGroupName] = useState(
    ProductGroup.length>0?ProductGroup[0].name:""
  );
  const [Productlist, setProductlist] = useState([]);
  
  const [acValue, setACValue] = useState({
    id:-1,
    name: "",
  });

  useEffect(() => {
    
      let ProductlistParam = {
        action: "getProductItem",
        ItemGroupId: currProductGroupId,
        chechkvalue:-1,
        menukey: "",
      };
      cProductList(ProductlistParam);
  
  }, [currProductGroupId]);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    setProductGroupId(rowId);
    setProductGroupName(rowName);

    let ProductlistParam = {
      action: "getProductItem",
      ItemGroupId: newValue.id,
      menukey: "",
    };
    cProductList(ProductlistParam);
  };

  const { mutate: cProductList } = useMutation(api.getProductListData, {
    onSuccess: (data, ProductlistParam) => {
      if (data.status == 200) {
        // console.log("Dta list", data.data.datalist);
        setProductlist(data.data.datalist);
        setACValue({
          id: data.data.datalist.length>0?data.data.datalist[0].id:-1,
          name: data.data.datalist.length>0?data.data.datalist[0].name:"",
        });
       
        //alert(ProductlistParam.ItemGroupId);
         
      //  if(ProductlistParam.chechkvalue !=-1){
        refreshAllData(
          activeRegionData.id,
          activeCommuneData.id,
          currFLevelId,
          ProductlistParam.ItemGroupId,
          data.data.datalist.length>0?data.data.datalist[0].id:1,
          currMostypeId
        );
      //  }
         
       
         
       
      
      }
    },
  });

  // product Change
  const handleProductChange = (event, newValue) => {
    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      currFLevelId,
      currProductGroupId,
      newValue.id,
      currMostypeId
    );
    setACValue(newValue);
  };


 

    // mostype  Change
    const handleMosTypeChange = (event, newValue) => {
      let rowId = '';
      let rowName = '';
  
      if (newValue == null) {
        rowId = '';
        rowName = '';
      } else {
        rowId = newValue.id;
        rowName = newValue.name;
      }
      if (rowId !== currMostypeId) {
        setMostypeId(rowId);
        setMostypeName(rowName);
  
  
      }

      refreshAllData(
        activeRegionData.id,
        activeCommuneData.id,
        currFLevelId,
        currProductGroupId,
        acValue.id,
        newValue.id,
      );
    };
  

  // FLevel  Change
  const handleFLevelChange = (event, newValue) => {
    let rowId = '';
    let rowname = '';
    if (newValue == null) {
      rowId = '';
      rowname = '';
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }
    if (rowId !== currFLevelId) {
      setFLevelId(rowId);
      setFLevelName(rowname);
    }
    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      newValue.id,
      currProductGroupId,
      acValue.id,
      currMostypeId,
    );
  };







  const [getReportingRate, setReportingRate] = useState([]);
  const [getFLvelelList, setFLvelelList] = useState([]);
  

  const [getMosTypelist, setMosTypelist] = useState([]);


  let param1 = {
    menukey: menukey,
    action: "GetMosScale",
    lan: lan,
    FLevelId: currFLevelId,
    FLevelName: currFLevelName,

  };

  const { data: data1 } = useQuery(
    [param1],
    api.getNationalStockkReport,
    {
      onSuccess: (data) => {
        // console.log("hhhhhhhhh", data);
        setMosTypelist(data.data.datalist);


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const [dataItems, setDataItems] = useState([]);
  const [MapLatitudeLongitude, setMapLatitudeLongitude] = useState([]);
  const [MapDataTable, setMapDataTable] = React.useState(true);

  const refreshAllData = (
    RegionId,
    DistrictId,
    FLevelId,
    pItemGroupId,
    pItemId,
    pMosTypeId
    
  ) => {
    let params = {
      menukey: menukey,
      lan: lan,
      RegionId: RegionId,
      DistrictId: DistrictId,
      FLevelId: FLevelId,
      ItemGroupId : pItemGroupId,
      ItemId: pItemId,
      MonthId: '',
      MosTypeId: pMosTypeId,
      Year:'',
      ProvinceName:'',
      BCZName:'',
      ProductGroup:'',
      MonthName:'',
      FLevelName:'',
      ProductName:'',
      MosTypeName:''


    };
    GetFacilityStockStatusTable({ queryKey: [{ ...params, action: "FacilityStockStatusTable" }] });
    GetFacilityStockStatusbyproductMap({ queryKey: [{ ...params, action: "FacilityStockStatusbyproductMap" }] });
    
  };

  const { mutate: GetFacilityStockStatusTable } = useMutation(api.getNationalStockkReport, {
    onSuccess: (data) => {
      setDataItems(data.data.datalist);
    },
  });

  const { mutate: GetFacilityStockStatusbyproductMap } = useMutation(api.getNationalStockkReport, {
    onSuccess: (data) => {
      setMapLatitudeLongitude(data.data.datalist);
    },
  });

 


  // For Map
  const [state, setState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    markerParams: {}
  });

  const onMarkerClick = (props, marker, e) => {
    console.log("kkkkkkkjjj",props.params);
    setState({
      selectedPlace: props,
      markerParams: props.params,
      activeMarker: marker,
      showingInfoWindow: true
    })
  };

  const onClose = (props) => {
    if (state.showingInfoWindow) {
      setState({
        selectedPlace: {},
        showingInfoWindow: false,
        activeMarker: null,
        markerParams: {}
      });
    }
  };



  // let ParamList = {
  //   menukey: menukey,
  //   action: "FacilityStockStatusbyproductMap",
  //   lan: lan,
  //   RegionId: activeRegionData.id,
  //   DistrictId: activeCommuneData.id,
  //   FLevelId: currFLevelId,
  //   ItemGroupId: currProductGroupId,
  //   ItemId: acValue.id,
  //   MonthId: currmonthId,
  //   MosTypeId: currMostypeId,
  //   Year: currYearId,
  //   ProvinceName: activeRegionData.name,
  //   BCZName: activeCommuneData.name,
  //   ProductGroup: currItemGroupname,
  //   MonthName: currMonthName,
  //   FLevelName: currFLevelName,
  //   ProductName: acValue.name,
  //   MosTypeName: currMostypeName,
  // }

  // const { data:datamap ,refetch} = useQuery(
  //   [ParamList],
  //   api.getNationalStockkReport,
  //   {
  //     onSuccess: (data) => {
  //       // console.log("hhhhhhhhh", data);
  //       setMapLatitudeLongitude(data.data.datalist);


  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0,//1000 * 60 * 60 * 24,
  //   }
  // );


 




  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerSort: false,
      headerFilter: true,

    },


    {
      title: DispensingLanguage[lan][menukey]["Supplier"],
      field: "Supplier",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,


    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "ClosingStock",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 115,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",

      width: 70,
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 70,
      //formatter: "money",
    },




  ];


  // const current = new Date();
  // const currentDate = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";

    window.open(
      finalUrl +
      "?action=FacilityStockStatusbyProductExport" +
      "&reportType=" +
      reportType +
      "&RegionId=" +
      activeRegionData.id + 
      "&DistrictId=" +
      activeCommuneData.id +
      "&FLevelId=" +
       currFLevelId +
       "&ItemGroupId=" +
       currProductGroupId +
       "&ItemId=" +
       acValue.id +
       "&MonthId=" +
       currmonthId +
       "&MosTypeId=" +
       currMostypeId +
       "&Year=" +
       currYearId +
       "&DepartmentName=" +
       activeRegionData.name +
       "&DistrictName=" +
       activeCommuneData.name +
       "&ProductGroupName=" +
       ProductGroupName +
       "&ProductGroup=" +
       acValue.name +
       "&MonthName=" +
       currMonthName +
       "&FLevelName=" +
       currFLevelName+
       "&ProductName=" +
       acValue.name +
       "&MosTypeName=" +
       currMostypeName +
      "&menukey=" +
      menukey +
      "&lan=" +

      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };

  /* =====End of Excel Export Code==== */
  let temFTypeId = "";
  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding sw_inner_container`}>
        <div className="sw_inner_card">

          <div className="d-flex justify-uiLanguage">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Facility Stock Status by Product Map"]}
                </div>
               
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card mb-3">
            <CardContent className="sw_filterCardContent">
              <Grid container>

                <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="RegionList"
                      disableClearable
                      options={RegionList}
                      value={activeRegionData}
                      onChange={(event, newValue) =>
                        handleDepartmentChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>

                </Grid>

                <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}
                      value={activeCommuneData}
                      onChange={(event, newValue) =>
                        handleCommuneChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["District"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

               

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="FLevelList"
                      disableClearable
                      options={FLevelList}
                      onChange={(event, newValue) => handleFLevelChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={FLevelList[FLevelList.findIndex(FLevelList => FLevelList.id == currFLevelId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility Level"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


              </Grid>
              <Grid container>

                <Grid item xs={3} sm={3} style={{marginTop:'15px'}}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroup[ProductGroup.findIndex(ProductGroup => ProductGroup.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product Group"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3} style={{marginTop:'15px'}}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Productlist"
                      disableClearable
                      options={Productlist}
                      value={acValue}
                      onChange={(event, newValue) => handleProductChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField

                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} sm={2} style={{marginTop:'15px'}}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MosTypelist"
                      disableClearable
                      options={MosTypelist}
                      onChange={(event, newValue) => handleMosTypeChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MosTypelist[MosTypelist.findIndex(MosTypelist => MosTypelist.id == currMostypeId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["MOS Type"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>


                {/* <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="MonthList"
                      disableClearable
                      options={MonthList}
                      onChange={(event, newValue) => handleMonthChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={MonthList[MonthList.findIndex(MonthList => MonthList.id == currmonthId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Month"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="YearList"
                      disableClearable
                      options={YearList}
                      onChange={(event, newValue) => handleYearChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={YearList[YearList.findIndex(YearList => YearList.id == currYearId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Year"]}
                          variant="standard"

                        />
                      )}
                    />
                  </FormControl>
                </Grid> */}


              </Grid>


            </CardContent>
          </Card>

          {/* Facility Reporting Rate */}
          {/* <FacilityReportingRate
            ReportingLabelList={getFLvelelList}
            ReportingValueList={getReportingRate}
            lan={lan}
            menukey={menukey}
          /> */}
          {/* Facility Reporting Rate */}


          {/* new row */}
          <div className="row">

            <div className="col-md-6 mb-3">


              <Card className="sw_card Map_Style">

                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Facility Stock Status by Product Map"]}
                />


                <CardContent>

                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Map
                        google={props.google}
                        zoom={5.55}
                        style={mapStyles}
                        initialCenter={
                          {
                            lat: 18.113637527839103,
                            lng: -1.8086445733566374
                          }
                        }
                        
                      >
                        {
                          MapLatitudeLongitude.map((item, index) => {
                            //console.log("Im UUUUUUUUUU",item.FTypeIcon);
                            //let gIcon = item.FTypeIcon;
                            /*
                            let finalUrl = "";
                            if(currRegionId==0){
                              finalUrl = "http://localhost/epharmacie/src/assets/img/marker_icon.png";
                           
                            }else{
                               finalUrl = "http://localhost/epharmacie/src/assets/img/"+item.FTypeIcon;
                             }
                  
                             */


                            return (
                              <Marker
                                onClick={onMarkerClick}
                                params={item}
                                position={{ lat: item.Latitude, lng: item.Longitude }}
                                icon={{
                                  //url: item.img=='1.png'? require("assets/img/icon/1.png"): require("assets/img/marker_icon.png"),
                                  url: require("assets/img/icon/"+item.img),
                                  //url: finalUrl,
                                   // anchor: { x: 16, y: 16 },
                                    scaledSize: { height: 40, width: 27 },  

                                }}
                              />
                            );
                          })

                        }

                        <InfoWindow
                          marker={state.activeMarker}
                          visible={state.showingInfoWindow}
                          onClose={onClose}
                        >
                          <div>

                            <TableContainer>
                              <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                    >
                                      {DispensingLanguage[lan][menukey]["Facility Name"]}
                                    </TableCell>
                                    <TableCell >
                                      {state.markerParams.FacilityName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                      align="left"
                                    >
                                      {DispensingLanguage[lan][menukey]["Closing Balance"]}
                                    </TableCell>

                                    <TableCell > {state.markerParams.ClosingStock}  </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                      align="left"
                                    >
                                      {DispensingLanguage[lan][menukey]["AMC"]}
                                    </TableCell>
                                    <TableCell >  {state.markerParams.AMC} </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                    >
                                      {DispensingLanguage[lan][menukey]["MOS"]}
                                    </TableCell>
                                    <TableCell > {state.markerParams.MOS} </TableCell>

                                  </TableRow>

                                  <TableRow>
                                    <TableCell
                                      style={{
                                        "font-weight": "bold",
                                        "border-right": "1px solid antiquewhite",
                                      }}
                                    >
                                      {DispensingLanguage[lan][menukey]["Supplier"]}
                                    </TableCell>
                                    <TableCell > {state.markerParams.Supplier} </TableCell>

                                  </TableRow>

                                 

                                </TableBody>

                              </Table>
                            </TableContainer>


                          </div>
                        </InfoWindow>


                      </Map>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </div>
            <div className="col-md-6 mb-3">
              <Card className="sw_card">
                <CardHeader
                  title={DispensingLanguage[lan][menukey]["Facility Stock Status"]}
                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }
                />

                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    height={540}
                    layout={"fitColumns"}
                  />
                </CardContent>
              </Card>
            </div>

          </div>
          {/* end of row */}

          <Card className="sw_card sw_filter_card">
                <CardContent className="sw_filterCardContent">
                <div dangerouslySetInnerHTML={{__html: getMosTypelist}} />
                  {/* <Grid container>
                    <Grid item xs={12} sm={12}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className={classes.mylegend_area}>
                            {

                              getMosTypelist.map((item, index) => {

                                if (temFTypeId != item.FTypeId) {
                                  temFTypeId = item.FTypeId;
                                  return (

                                    <div className={classes.legend_group} >{item.FTypeName}</div>


                                  );


                                } else {
                                  return (
                                    <div className="my_legend">
                                      <div
                                        className="mylegend_color"
                                        style={{ background: item.ColorCode }}
                                      ></div>
                                      <div className="mylegend_label">{item.name}</div>
                                      <div className="mylegend_val">
                                        {DispensingLanguage[lan][menukey]["MOS"]}{":"} {item.MosLabel}
                                      </div>
                                    </div>
                                  );
                                }


                              })}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid> */}
                </CardContent>
              </Card>


        </div>
      </div>
    </>
  );
};


export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,  // Hides or shows the InfoWindow
    activeMarker: {},          // Shows the active marker upon click
    selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
  };

  // ...
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCD7OEdGUC1V__0-mBJIoYifI5UtEILYbg'
})(FacilityStockStatusbyProduct);


// export default FacilityStockStatusbyProduct;


const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});

