import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

import swal from "sweetalert";
// materialui table
// import MaterialTable from "material-table";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  TextField,
  Grid,
  FormControl,
  Typography,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

// import MomentUtils from "@date-io/moment";
import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

import {
  saveLayoutSettings,
  clearLayoutSettings,
  getFilterValue,
} from "../../../services/LayoutSettings";

const Products = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const menukey = "adjustment";
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  /* Get saved filter value from layoutSettings */
  const StartDateFilter = getFilterValue(menukey, "StartDateFilter");
  const EndDateFilter = getFilterValue(menukey, "EndDateFilter");

  /* Get saved filter value from layoutSettings */

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    StartDateFilter || moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    EndDateFilter || moment().format("YYYY-MM-DD")
  );

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({ menukey });
  const [isLoading, setLoading] = useState(true);

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    TransactionTypeId: 3,
  };
  const { refetch } = useQuery([params], api.getAdjustmentInvs, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const { mutate } = useMutation(api.deleteAdjustmentInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        refetch();
        queryClient.getQueriesData("adjustment");

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
          duration: 10000,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=getAdjustment" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editRecord(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Adj. Date"],
      width: 170,
      field: "TransactionDate",
      headerFilter: true,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adj. Invoice No"],
      field: "TransactionNo",
      headerFilter: true,
      width: 210,
    },
    {
      title: DispensingLanguage[lan][menukey]["Adj. Type"],
      field: "AdjType",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["To_From"],
      field: "to_from",
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Amount (CFA)"],
      field: "Amount",
      //width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: true,
      visible: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Status"],
      width: 85,
      headerHozAlign: "center",
      field: "bStockUpdated",
      hozAlign: "center",
      //formatter: "tickCross",
      formatter: function (cell) {
        let value = cell.getValue();
        let bCompleted = cell.getRow(cell).getData().bStockUpdated;

        if (bCompleted == 1)
          return "<span class='statusCompleted'></span>"; //+ value green;
        else return "<span class='statusDraft'></span> "; //+ value red;
      },
      tooltip: function (cell) {
        if (cell.getValue() == 1) {
          return t(DispensingLanguage[lan][menukey]["Posted"]);
        } else {
          return t(DispensingLanguage[lan][menukey]["Draft"]);
        }
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editRecord = (data) => {
    let route = `adjustment/edit/${data.id}`;
    props.history.push(route);
  };

  // const deleteRecord = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //       "Once deleted, you will not be able to recover this record!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: [t(DispensingLanguage[lan][menukey]["No"]),
  //     t(DispensingLanguage[lan][menukey]["Yes"])],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       mutate(data.id);
  //     }
  //   });
  // };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;

    if (!rowData.bStockUpdated)
      return (
        <>
          <Edit
            onClick={() => {
              editRecord(rowData);
            }}
          />
          <DeleteOutline
            onClick={() => {
              modalPopupDelete(mutate, rowData.id);
              // deleteRecord(rowData);
            }}
          />
        </>
      );
    else
      return (
        <>
          <ViewList
            onClick={() => {
              editRecord(rowData);
            }}
          />
        </>
      );
  }

  useEffect(() => {
    refetch();
  }, [selectedStartDate, selectedEndDate]);


  /* start save/clear setting layout handler */
  const saveLayoutSettingsHandler = () => {
    const singleLayoutSettingsParam = {
      filter: {
        StartDateFilter: selectedStartDate,
        EndDateFilter: selectedEndDate,
      },
    };

    saveLayoutSettings(menukey, singleLayoutSettingsParam);

    props.openNoticeModal({
      isOpen: true,
      msg: t(DispensingLanguage[lan][menukey]["Layout saved successfully"]),
      msgtype: 1,
    });
  };

  const clearLayoutSettingsHandler = () => {
    clearLayoutSettings(menukey);

    props.openNoticeModal({
      isOpen: true,
      msg: t(DispensingLanguage[lan][menukey]["Layout cleared successfully"]),
      msgtype: 1,
    });
  };

  /* end save/clear setting layout handler */

  return (
    <>
      <div className="sw_inner_card">
        <div className="sw_makeStyles_tableContainer">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Adjustment"]}
                </div>
              </div>
            </Grid>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={DateFnsUtils}
                    locale={lan == "en_GB" ? enGB : fr}
                  >
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        showtodaybutton="true"
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={DispensingLanguage[lan][menukey]["From"]}
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={DateFnsUtils}
                    locale={lan == "en_GB" ? enGB : fr}
                  >
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={DispensingLanguage[lan][menukey]["To"]}
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={8} sm={8}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2"
                      onClick={() => {
                        props.history.push("/adjustment/add");
                      }}
                    >
                      {DispensingLanguage[lan][menukey]["Add Adjustment"]}
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          <div className="layoutsettings-container">
            <div className="text-right icon-container">
              <i
                onClick={saveLayoutSettingsHandler}
                style={{ cursor: "pointer" }}
                className="far fa-save"
                aria-hidden="true"
                title={t(
                  DispensingLanguage[lan][menukey]["Save Layout Settings"]
                )}
              ></i>

              <i
                onClick={clearLayoutSettingsHandler}
                style={{ cursor: "pointer" }}
                className="far fa-stop-circle"
                aria-hidden="true"
                title={t(
                  DispensingLanguage[lan][menukey]["Clear Layout Settings"]
                )}
              ></i>
            </div>
          </div>

          <div className="sw_relative">
            {isLoading && <LoadingSpinnerOpaque />}
            <div className="uniqueName">
              <ReactTabulator
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height="450px"
                rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
