import React, { useState, useEffect } from "react"; //, useEffect
// import {
//   Typography
// } from "@material-ui/core";

import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
// import PropTypes from "prop-types";
// import Box from "@material-ui/core/Box";
// import swal from "sweetalert";
// import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import DispenserFormData from "./IssueItems.js";

// import Constants from "../../../services/Constants";
import moment from "moment";

const AddIssue = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "issue";
  const FacilityId = localStorage.getItem("FacilityId");

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;

const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const [selectedDate, setSelectedDate] = useState(
    // new Date()
    moment().format("YYYY-MM-DD")
  );
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [formData, setFormData] = useState({
    TransactionDate: selectedDate,
    SupplierInvDate: selectedDate,
    SupplierInvNo: "",
    TransactionNo: generatedInvoiceNo,
    FacilityId: FacilityId,
    TransactionTypeId: 2,
    IssuedToGroupId: "",
    IssuedToFacility: "",
    OrderNo: "",
    OrderDate: "",
    Remarks:"",
    PreparedBy:UserId
   // ApprovedBy: "",
  });

  const [ManyTableData, setManyTableData] = useState([]);
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }

    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });

    // setFormData({ ...formData, [field]: date });
    // setErrorObject({ ...errorObject, [field]: null });
  };

  //  const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  // const queryClient = useQueryClient();

  let params = {
    menukey: "issue",
    FacilityId: FacilityId,
    TransactionTypeId: 2,
    TransactionDate: moment(formData.TransactionDate).format("DD/MM/YYYY"),
    ChangeTransactionDate:true
  };
  const { data: invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, {
    onSuccess: (data) => {
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData };
      fdata["TransactionNo"] = data;
      setFormData(fdata);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });
  /*
  const { mutate } = useMutation(api.saveIssueInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `/${menukey}/edit/${data.TransactionId}`;
        props.history.push(route);
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });
  */
  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };

    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      SupplierInvDate: selectedDate,
      SupplierInvNo: "",
      TransactionNo: generatedInvoiceNo,
      FacilityId: FacilityId,
      TransactionTypeId: 2,
      IssuedToGroupId: "",
      IssuedToFacility: "",
      OrderNo: "",
      OrderDate: "",
      Remarks:"",
      PreparedBy: UserId
      //ApprovedBy: "",
    });
    setManyTableData([]);
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "IssuedToGroupId",
      "IssuedToFacility"
     
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };
  /*
  const handleSubmit = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        menukey: "issue",
      };
      mutate(fDtat);
    }
  };
  */
  const deleteReceiveInv = (data) => {
    let newarray = ManyTableData.filter((element) => element != data);
    setManyTableData(newarray);
  };

  /*
  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (validateForm(formData)) {
      let fDtat = { ...formData, ManyJsonSave: rows, menukey: "issue" };
      mutate(fDtat);
    }

    setManyTableData(rows);
  };
  */

  const handleChangeIssuedToGroupIdProps = (e) => {
    if (e != "null") {
      setErrorObject({ ...errorObject, ["IssuedToGroupId"]: null });
    }
  };

  const handleChangeissuedToFacilityProps = (e) => {
    if (e != "null") {
      setErrorObject({ ...errorObject, ["IssuedToFacility"]: null });
    }
  };


  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':[]}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };
  
  useEffect(() => {}, []);

  return (
    <>
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
       // handleSubmit={handleSubmit}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        handleChangeissuedToFacilityProps={handleChangeissuedToFacilityProps}
        handleChangeIssuedToGroupIdProps={handleChangeIssuedToGroupIdProps}
        selectedDate={selectedDate}
        deleteReceiveInv={deleteReceiveInv}
       // setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
       // bStockUpdate={0}
        handleAdd={handleAdd}
        generatedInvoiceNo={generatedInvoiceNo}
        checkValidateForm={checkValidateForm}
        {...props}
      />
    </>
  );
};

export default AddIssue;
