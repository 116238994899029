import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from "@material-ui/core";
import * as Service from "../../../services/Service.js";

import { useTranslation } from "react-i18next";




const SupplierFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCountryChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "supplier";
  const classes = useStyles();
  const { t } = useTranslation();
  const [countryList, setCountryList] = useState([]);


  function cCountryList() {
    let CountryParam = { action: "CountryList", menukey: "" }
    Service.default
      .postApi("source/combo_generic.php", CountryParam)
      .then((res) => {
        setCountryList(res.datalist);
      })
      .catch((err) => { });


  }

  useEffect(() => {

    cCountryList();

  }, [formData]);



  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-start">
              <div className="sw_heading_title_inner"> {DispensingLanguage[lan][menukey]['Supplier Entry Form - Add/Edit']} </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Supplier Entry Form']} />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} sm={4}> </Grid> */}
                      <Grid item xs={12} sm={4}>
                        <TextField
                          error={errorObject.SupplierName}
                          helperText={errorObject.SupplierName}
                          required
                          id="SupplierName"
                          name="SupplierName"
                          label={DispensingLanguage[lan][menukey]['Supplier Name']}
                          value={formData.SupplierName}
                          fullWidth
                          // autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>

                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Country"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.ISO3}
                            labelId="demo-simple-select-helper-label"
                            id="ISO3"
                            name="ISO3"
                            value={formData.ISO3}
                            fullWidth
                            required={true}
                            onChange={(e) => handleCountryChange(e)}
                          >
                            <MenuItem value="0">
                              {DispensingLanguage[lan][menukey]["Select Country"]}
                            </MenuItem>

                            {countryList.map((item, index) => {
                              return (
                                <MenuItem key={`CountryList-${index + 1}`} value={item['ISO3']}>{item['CountryName']}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.RegionId}>
                            {errorObject.RegionId}
                          </FormHelperText>
                        </FormControl>

                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          error={errorObject.Email}
                          helperText={errorObject.Email}
                          // required
                          id="Email"
                          name="Email"
                          label={DispensingLanguage[lan][menukey]['Email']}
                          value={formData.Email}
                          fullWidth
                          // autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          error={errorObject.ContactName}
                          helperText={errorObject.ContactName}
                          // required
                          id="ContactName"
                          name="ContactName"
                          label={DispensingLanguage[lan][menukey]['Contact Name']}
                          value={formData.ContactName}
                          fullWidth
                          // autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          error={errorObject.ContactNo}
                          helperText={errorObject.ContactNo}
                          required
                          id="ContactNo"
                          name="ContactNo"
                          label={DispensingLanguage[lan][menukey]['Contact No']}
                          value={formData.ContactNo}
                          fullWidth
                          // autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          error={errorObject.Fax}
                          helperText={errorObject.Fax}
                          // required
                          id="Fax"
                          name="Fax"
                          label={DispensingLanguage[lan][menukey]['Fax']}
                          value={formData.Fax}
                          fullWidth
                          // autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          error={errorObject.SupplierAddress}
                          helperText={errorObject.SupplierAddress}
                          // required
                          id="SupplierAddress"
                          name="SupplierAddress"
                          label={DispensingLanguage[lan][menukey]['Supplier Address']}
                          value={formData.SupplierAddress}
                          fullWidth
                          // autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          error={errorObject.Url}
                          helperText={errorObject.Url}
                          // required
                          id="Url"
                          name="Url"
                          label={DispensingLanguage[lan][menukey]['URL']}
                          value={formData.Url}
                          fullWidth
                          // autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={4}> </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {t(DispensingLanguage[lan][menukey]['Reset'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {t(DispensingLanguage[lan][menukey]['Save'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/supplier")}
              >
                {t(DispensingLanguage[lan][menukey]['Cancel'])}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {t(DispensingLanguage[lan][menukey]['update'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/supplier")}
              >
                {t(DispensingLanguage[lan][menukey]['Cancel'])}
              </Button>
            </Grid>
          )}



        </Grid>
      </div>
    </div>
  );
};

export default SupplierFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
