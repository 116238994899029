import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  FormControl,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useQuery} from "react-query";
import * as api from "../actions/api";

import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";
//get DispensingLanguage



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProductsLotModal = ({ handleClose, open, addProduct,FacilityId, ...props }) => {


  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "adjustment";

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const Product_Group = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Product = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const ProductGroupList = [All_Product].concat(Product_Group);

  const [activeProductGroupData, setAcProductGroupData] = useState({
    id:ProductGroupList.length>0?ProductGroupList[0].id:"",
    name: ProductGroupList.length>0?ProductGroupList[0].name:""
  });


  const handleProductGroupChange = (event, newValue) => {
    setAcProductGroupData(newValue);
   
  };
 


  let tblLotRef = null;


  let params = {
    action: "getProductLot",
    menukey: menukey,
    FacilityId:FacilityId,
    ItemGroupId: activeProductGroupData.id,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { data } = useQuery([params], 
    api.getProductsLot,
    {    
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0 
      
      }
    );

  // const { isLoading, error, isError, data, refetch } = useQuery(
  //   "products_lot",
  //   api.getProductsLot
  // );
  // const [tabledata, setTabledata] = useState([]); 
  // const { data } =  useQuery(['products_lot', FacilityId], () =>
  //   api.getProductsLot(FacilityId).then((res) => { 
  //     setTabledata(res); 
  //     return res.data
  //   }), {
  //   enabled: Boolean(FacilityId)
  // })



  // const [selectedProducts, setselectedProducts] = useState([]);

  const ProductColumns = [
    { title: "BatchId", field: "BatchId", visible: false },
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },
    { title: "Group Name", field: "GroupName", visible: false },
    

    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:60, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Product Code'], field: "ItemCode", width: 140, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Product Name'], 
    field: "ItemName", 
    width: 200,
    headerFilter:true  },
    {
      title: DispensingLanguage[lan][menukey]['Lot Qty'],
      field: "CurrentStockQty",
      width: 150,
      hozAlign: "right",
      headerHozAlign: "right",
       
    },
    {
      title: DispensingLanguage[lan][menukey]['Lot No'],
      field: "BatchNo",
      width: 180,
      
    },
    {
      title: DispensingLanguage[lan][menukey]['Expiry Date'],
      field: "ExpiryDate",
      width: 140 
    },

    {
      title: DispensingLanguage[lan][menukey]['Manufacturer'],
      field: "ManufacturerName",
      //width: 220 
    },
    
    // {
    //   title: DispensingLanguage[lan][menukey]['Unit Price (CFA)'],
    //   field: "UnitPrice",
    //   width: 100,
    //   hozAlign: "right",
    //   headerHozAlign: "right",
       
    // } 
  ];

  
  const ProdustDataItemsLot = data?.map((item, index) => { 
    return item;
  });

  let selectedRows = [];
  
  const [btnDisable, setbtnDisable] = useState(false);
  const SaveSelectedProducts = () => { 

    //console.log("All Product >>>> ",ProdustDataItemsLot);
    //console.log("Product modal selected product >>>> ",tblLotRef.table.getSelectedData());

    if(tblLotRef.table.getSelectedData().length>0){
      setbtnDisable(true);
      selectedRows = tblLotRef.table.getSelectedData();
      checkExpiringBatches();
      addProduct(tblLotRef.table.getSelectedData());  
      setTimeout(() => setbtnDisable(false), 500);
    }  
  };


   const checkExpiringBatches = () => {
   
    let count = 0;

    // removing current selected rows
     let rowsWithoutSelected = ProdustDataItemsLot.filter((o) => {
      for (var i = 0; i < selectedRows.length; i++) {
        if (
          selectedRows[i].ItemCode === o.ItemCode &&
          selectedRows[i].ExpiryDate === o.ExpiryDate
        ) {
          return false;
        }
      }
      return true;
    });

   //console.log("removing current selected rows", rowsWithoutSelected);
   //console.log("props ManyTableData ", props.ManyTableData);


    // removing already selected rows in many grid
    if (props.ManyTableData) {
      rowsWithoutSelected = rowsWithoutSelected.filter((o) => {
        for (var i = 0; i < props.ManyTableData.length; i++) {
          if (
            props.ManyTableData[i].ItemCode === o.ItemCode &&
            props.ManyTableData[i].ExpiryDate === o.ExpiryDate
          ) {
            return false;
          }
        }
        return true;
      });
    }

    //console.log("rowsWithoutSelected: ", rowsWithoutSelected);


    rowsWithoutSelected.forEach((p) => {
      const expiringProds = selectedRows.filter((o) => {
        //console.log("o.ExpiryDate== ", o.ItemCode, "====", o.ExpiryDate);
        
        

        if (o.ItemCode === p.ItemCode) {

          if (moment(o.ExpiryDate, "DD/MM/YYYY") > moment(p.ExpiryDate, "DD/MM/YYYY")) {
            return true;
          }
        } else {
          return false;
        }
      });

      //console.log("Expiring products: ", expiringProds);

      if (expiringProds.length > 0) {
        count++;
      }
    });

    

     if (count > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]['Some of your product batches expiring before your selected ones, please select batches which will expire early.'],
        msgtype: 0,
        duration: 10000,
      });
      return;
    } 

 
  }; 

  // const checkSelect = (row) => { 
  //   console.log(row);
  //   console.log("row");
  // };

  useEffect(() => { 
    if (tblLotRef == null) return;
    


}, []); 
  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('Products')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg" 
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('Products')}
        >
          {DispensingLanguage[lan][menukey]['Products Lot']} 
        </BootstrapDialogTitle>
 {/* start of filter */}
 <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                
                <Grid container spacing={1}>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="ProductGroupList"
                        disableClearable
                        options={ProductGroupList}
                        value={activeProductGroupData}
                        onChange={(event, newValue) =>
                          handleProductGroupChange(event, newValue)
                        }
                        getOptionLabel={(option) => option.name || ""}
                       
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              DispensingLanguage[lan][menukey]["Product Group"]
                            }
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                 
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}


        <DialogContent dividers>
          <ReactTabulator
            ref={(rf) => (tblLotRef = rf)}
            options={{
              selectableRangeMode: 'click',
              groupBy: ["GroupName"],
             }}
            selectable={true}
            columns={ProductColumns}
            data={ProdustDataItemsLot}
            height="300px"
            layout={"fitColumns"}
            //selectableCheck={(row)=>checkSelect(row)}


          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            disabled={btnDisable}
            className="mr-2 float-left "
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
            {DispensingLanguage[lan][menukey]['ADD']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('Products')}
          >
           {DispensingLanguage[lan][menukey]['CANCEL']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProductsLotModal;

const useStyles = makeStyles({
  fullWidth: {
    width: "95%",
  },
 
});
