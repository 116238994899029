import React, {
  useState,
  useEffect,
  useContext,
  Component,
  useRef,
} from "react";
import swal from "sweetalert";
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";

// import ChartStates from './ChartStates';

import {
  Select,
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  InputLabel,
  Typography,
  TextField,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { Launch } from "@material-ui/icons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { Button } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useTranslation } from "react-i18next";

import Highcharts from "highcharts/highmaps";

import ChartStates from "./ChartStates";
import statesData from "../../services/mapjsonfile/benin_regions.json";

const PercentageofFacilityStockoutbyProducts = (props) => {
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "percentage-of-facility-stockout-by-products";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start


  //region combo list
  const [RegionList, setRegionListData] = useState([]);
  const [activeRegionData, setAcRegionData] = useState({
    id: RegionList.length > 0 ? RegionList[0].id : 0,
    name: RegionList.length > 0 ? RegionList[0].name : "",
  });

  React.useEffect(() => {
    if (activeRegionData.id == 0) {
      getRegionData();
    }
  }, [activeRegionData.id]);

 
  const handleDepartmentChange = (event, newValue) => { 

    setAcRegionData(newValue);
    getCommuneData(newValue.id); 
  };
  
  const getRegionData = () => {
    let FacilitylevelParam = {
      action: "getDepartmentforreport",
      RoleId: 1, 
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cRegionList(FacilitylevelParam);
  };

  const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setRegionListData(data.data.datalist);

        setAcRegionData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getCommuneData(data.data.datalist[0].id);
      }
    },
  });
 
  ////distict combo list
  const [Communelist, setCommunelistData] = useState([]);
  const [activeCommuneData, setAcCommuneData] = useState({
    id: 0,
    name: Communelist.length > 0 ? Communelist[0].name : "",
  });
 
  const getCommuneData = (RegionId) => {
    let FacilitylevelParam = {
      action: "getcommuneforreport",
      RoleId: 1,
      UserId: UserId,
      RegionId: RegionId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
    onSuccess: (data,params) => {
 
      if (data.status == 200) {
        setCommunelistData(data.data.datalist);

        setAcCommuneData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        
        // refreshAllData(
        //   params.RegionId,
        //   data.data.datalist.length ? data.data.datalist[0].id : 0,
        //   currProductGroupId,
        //   acValue.id
        // );
      
      }
    },
  });

  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    refreshAllData(
      activeRegionData.id,
      newValue.id,
      currProductGroupId,
      acValue.id
    );
    setAcCommuneData(newValue);
    
  };

  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroupList"));
  
  const [currProductGroupId, setProductGroupId] = useState(ProductGroup.length>0?ProductGroup[0].id:0);
  const [ProductGroupName, setProductGroupName] = useState(
    ProductGroup.length>0?ProductGroup[0].name:""
  );
  const [Productlist, setProductlist] = useState([]);
  
  const [acValue, setACValue] = useState({
    id:-1,
    name: "",
  });

  useEffect(() => {
    
      let ProductlistParam = {
        action: "getProductItem",
        ItemGroupId: currProductGroupId,
        chechkvalue:-1,
        menukey: "",
      };
      cProductList(ProductlistParam);
  
  }, [currProductGroupId]);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    setProductGroupId(rowId);
    setProductGroupName(rowName);

    let ProductlistParam = {
      action: "getProductItem",
      ItemGroupId: newValue.id,
      menukey: "",
    };
    cProductList(ProductlistParam);
  };

  const { mutate: cProductList } = useMutation(api.getProductListData, {
    onSuccess: (data, ProductlistParam) => {
      if (data.status == 200) {
        // console.log("Dta list", data.data.datalist);
        setProductlist(data.data.datalist);
        setACValue({
          id: data.data.datalist.length>0?data.data.datalist[0].id:-1,
          name: data.data.datalist.length>0?data.data.datalist[0].name:"",
        });
       
        //alert(ProductlistParam.ItemGroupId);
         
      //  if(ProductlistParam.chechkvalue !=-1){
        refreshAllData(
          activeRegionData.id,
          activeCommuneData.id,
          ProductlistParam.ItemGroupId,
          data.data.datalist.length>0?data.data.datalist[0].id:1
        );
      //  }
         
       
         
       
      
      }
    },
  });

  // product Change
  const handleProductChange = (event, newValue) => {
    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      currProductGroupId,
      newValue.id

    );
    setACValue(newValue);
  };


  //Map api include
  const [dataItemsMap, setDataItemsMap] = useState([]);
  const [dataItems, setDataItems] = useState([]);

  const refreshAllData = (
    RegionId,
    DistrictId,
    pItemGroupId,
    pItemId
    
  ) => {
    let params = {
      menukey: menukey,
      lan: lan,
      RegionId: RegionId,
      DistrictId: DistrictId,
      ItemGroupId : pItemGroupId,
      ItemNo: pItemId,
      GroupName: '',
      ProductName: ''
     
    };
    PercentageofFacilityStockoutbyProductsTable({ queryKey: [{ ...params, action: "PercentageofFacilityStockoutbyProducts" }] });
    GetPercentageofFacilityStockoutbyProductsMap({ queryKey: [{ ...params, action: "PercentageofFacilityStockoutbyProductsMap" }] });
    
  };

  const { mutate: PercentageofFacilityStockoutbyProductsTable } = useMutation(api.getProductLotStockReport, {
    onSuccess: (data) => {
      setDataItems(data);
    },
  });

  const { mutate: GetPercentageofFacilityStockoutbyProductsMap } = useMutation(api.getProductLotStockReportMap, {
    onSuccess: (data) => {
       // let tempData = [];
        // for (let i = 0; i < data.length; i++) {
        //   tempData.push([data[i]['RegionName'], Math.round(data[i]['StockoutPercentage'])]);

        // }
        // console.log("hhhhhhhhh", data);
      setDataItemsMap(data);
    },
  });


  // let params = {
  //   menukey: menukey,
  //   action: "PercentageofFacilityStockoutbyProducts",
  //   lan: lan,
  //   RegionId: activeRegionData.id,
  //   DistrictId: activeCommuneData.id,
  //   GroupName: currItemGroupname,
  //   ItemGroupId: currProductGroupId,
  //   ItemNo: acValue.id,
  //   ProductName: acValue.name,
  // };

  // const { data: scanloglist } = useQuery(
  //   [params],
  //   api.getProductLotStockReport,
  //   {
  //     onSuccess: (data) => {
  //       // console.log("hhhhhhhhh", data);
  //       setDataItems(data);
  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0, //1000 * 60 * 60 * 24,
  //   }
  // );


  // //console.log('Map Data', dataItemsMap);
  // let params2 = {
  //   menukey: menukey,
  //   action: "PercentageofFacilityStockoutbyProductsMap",
  //   lan: lan,
  //   RegionId: activeRegionData.id,
  //   DistrictId: activeCommuneData.id,
  //   GroupName: currItemGroupname,
  //   ItemGroupId: currProductGroupId,
  //   ItemNo: acValue.id,
  //   ProductName: acValue.name,
  // };

  // const { data: scanloglist2 } = useQuery(
  //   [params2],
  //   api.getProductLotStockReportMap,
  //   {
  //     onSuccess: (data) => {
  //       // let tempData = [];
  //       // for (let i = 0; i < data.length; i++) {
  //       //   tempData.push([data[i]['RegionName'], Math.round(data[i]['StockoutPercentage'])]);

  //       // }
  //       // console.log("hhhhhhhhh", data);
  //       setDataItemsMap(data);
  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0, //1000 * 60 * 60 * 24,
  //   }
  // );

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      headerSort: false,
      width: 200,
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Product Group"],
      field: "ProductGroup",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 280,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ProductName",
      headerSort: false,
      hozAlign: "left",
      headerHozAlign: "left",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "StockQty",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      // headerFilter: true,
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
  ];

  const [geoJson, setGeoJson] = useState(statesData);

  var data1111 = [
    ["Plateau", 728],
    /*,
    ["DE.BE", 710],
    ["DE.MV", 963],
    ["DE.HB", 541],
    ["DE.HH", 622],
    ["DE.RP", 866],
    ["DE.SL", 398],
    ["DE.BY", 785],
    ["DE.SN", 223],
    ["DE.ST", 605],
    ["DE.NW", 237],
    ["DE.BW", 157],
    ["DE.HE", 134],
    ["DE.NI", 136],
    ["DE.TH", 704],
    ["DE.", 361]*/
  ];

  async function getGeoJson() {
    try {
      console.log("start load");
      const result = await fetch(
        "https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/germany.geo.json"
      );
      // const result = await fetch(
      //   "http://192.241.155.223/epharmacie/wp-content/themes/betheme-child/report/mapjsonfile/benin_regions.json"
      // );

      const data = await result.json();

      console.log("data = ", data);

      return data;
      // this line won't run until above has completed
    } catch (error) {
      console.log(error);
    }
  }

  // useEffect(() => {
  //   //console.log('mounted')
  //   // load geoJson file
  //   getGeoJson().then((data) => {
  //     //setGeoJson(data);
  //     // console.log("start complete: geoJson ", data);
  //     // console.log("start complete: geoJson benin", statesData);
  //     // console.log("start complete: data loaded", data1111);
  //   });
  // }, []);

 
  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=PercentageOfFacilityStockOutbyProduct" +
        "&reportType=" +
        reportType +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&Districtid=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&ItemGroupId=" +
        currProductGroupId +
        "&ItemNo=" +
        acValue.id +
        "&ProductName=" +
        acValue.name +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div
        className={`section signup-top-padding sw_inner_container`}
      >
        <div className="sw_inner_card">
          <div className="d-flex justify-uiLanguage">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(
                    DispensingLanguage[lan][menukey][
                      "Percentage of Facility Stockout by Products"
                    ]
                  )}
                </div>
                {/* <div className="float-right sw_btn_control2">
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/esigl-reports")}
                  >
                    {DispensingLanguage[lan][menukey]["Back_to_List"]}
                  </Button>
                </div> */}
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="RegionList"
                      disableClearable
                      options={RegionList}
                      value={activeRegionData}
                      onChange={(event, newValue) =>
                        handleDepartmentChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}
                      value={activeCommuneData}
                      onChange={(event, newValue) =>
                        handleCommuneChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["District"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

              
              {/* </Grid>
              <Grid container> */}
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroup"
                      disableClearable
                      options={ProductGroup}
                      //defaultValue={(((FacilityList.find(item => item.id) == 0)) && (UserName !="admin"))?-1:FacilityList.find(item => item.id == currFacilityId)}

                      onChange={(event, newValue) =>
                        handleProductGroupChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name}
                      defaultValue={
                        ProductGroup[
                          ProductGroup.findIndex(
                            (ProductGroup) =>
                              ProductGroup.id == currProductGroupId
                          )
                        ]
                      }
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            DispensingLanguage[lan][menukey]["Product Group"]
                          }
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Productlist"
                      disableClearable
                      options={Productlist}
                      value={acValue}
                      onChange={(event, newValue) =>
                        handleProductChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Product"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* new row */}
          <div className="row">
            <div className="col-md-12 mb-4">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <ChartStates mapGeoJson={geoJson} data={dataItemsMap} />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}

          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "Percentage of Facility Stockout by Products"
                    ]
                  )}
                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="info"
                        className="mr-2 sw_icons"
                        onClick={() => PrintPDFExcelExportFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          options={{
                            groupBy: ["ProvinceName", "ZoneName", "BCZName"],

                            columnCalcs: "both",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PercentageofFacilityStockoutbyProducts;


const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});


// const useStyles = makeStyles({
//   dispenserContainer: {
//     backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
//     backgroundSize: "cover",
//     backgroundPosition: "top center",
//     minHeight: "745px",
//   },
//   dispenserPageTitle: {
//     marginTop: "60px",
//     color: "white",
//     background: "whitesmoke",
//     color: "black",
//     borderRadius: "10px",
//     padding: "1rem",
//   },
//   tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
//   fullWidth: {
//     width: "95%",
//   },
//   filterDiv: {
//     width: "80%",
//     display: "flex",
//   },
// });
