import React from "react";
import { Link } from "react-router-dom";
// import Constants from "../../services/Constants";
// reactstrap components

// import * as Service from "../../services/Service.js";

// import swal from "sweetalert";

// import { UserContext } from "../../context/user-info-context";
import "assets/css/sw_menu.css";
// import {
//   Collapse,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
//   UncontrolledDropdown,
//   NavbarBrand,
//   Navbar,
//   NavItem,
//   NavLink,
//   Nav,
//   Button,
//   Container,
//   UncontrolledTooltip,
// } from "reactstrap";

import { ImageList,ImageListItem } 
from "@material-ui/core";
;

function AfterLoginNavbar(props) {
  // const userCtx = useContext(UserContext);

  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [role, setRole] = React.useState(false);
  const [bOrderapprover, setBOrderapprover] = React.useState(0);
  const [menu, setMenu] = React.useState([]);
  const [InitComplete, setInitComplete] = React.useState(0);
  const lan = localStorage.getItem("LangCode"); //userCtx.userInfo.LangId; //"fr_FR";
  const initFacilityData = localStorage.getItem("initFacilityData"); 
  const TitleText=localStorage.getItem("LangCode")=="fr_FR"?'Mali OSPSANTE eSIGL Vaccins':'Mali OSPSANTE Vaccine eLMIS';
 
  const menukey = "menu";

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

const itemData = [
  {
    img: require("assets/img/mali_logo.png"),
    title: 'Mali Logo',
  },
];

  /*
  const setMenuTable = () => {
    // let auth = sessionStorage.getItem("User_info");
    //auth = JSON.parse(auth);
    // console.log("auth");
    // console.log(auth);

    let token = sessionStorage.getItem("token");
    //  let token = Constants.token;
    if (!token) {
      // swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
      sessionStorage.clear();
    }

    let auth = Constants.USER_INFO;
    // console.log("USER_INFO");
    // console.log(USER_INFO);

    if (auth) {
      if (menu.length == 0 && auth.length > 0) {
        setMenu(auth[0].menu);
      }
    } else {
      props.history.push("login");
    }
  };*/
  
 
  const setMenuTable = () => {
    let auth = sessionStorage.getItem("User_info");
    
    auth = JSON.parse(auth);
    if (auth) {
      if (menu.length === 0 && auth.length > 0) {
        
        
                // setMenu(auth[0].menu);
        setMenu(auth[0].menu1);

       // console.log("auth[0].menu: ", auth[0].menu1);
      }
    } else {
      props.history.push("login");
    }
  };

  // function recurciveMenus(menu) {}

  React.useEffect(() => {
    setMenuTable();
    let auth = sessionStorage.getItem("User_info");
    auth = JSON.parse(auth); 
    
    let initFacilityDataJson=JSON.parse(initFacilityData);
    if (initFacilityDataJson!='') {
    setInitComplete(JSON.parse(initFacilityData)[0].InitComplete);
    }else{
      setInitComplete(0);
    } 
    // let auth = Constants.USER_INFO;
    if (auth) {
      var isAdmin = auth[0].role.filter((name) => name.role.includes("Admin"));
      
      setBOrderapprover(auth[0].role[0].bOrderApprover==null?0:auth[0].role[0].bOrderApprover)
      if (isAdmin.length > 0) {
        sessionStorage.setItem("userRole", "Admin");

      } else {
        sessionStorage.setItem("userRole", "User");
      }
    }

    let r = sessionStorage.getItem("userRole");
    setRole(r);
    
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        //  setNavbarColor("");
        setNavbarColor("sticky");
      } else if (
        document.documentElement.scrollTop < 51 ||
        document.body.scrollTop < 51
      ) {
        //  setNavbarColor("navbar-transparent");
        setNavbarColor("");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const onMobileMenuOpen = () => {
    let element = document.getElementById("mobileMenuWrapper");
    element.classList.add("open");
  };
  const onMobileMenuClose = () => {
    let element = document.getElementById("mobileMenuWrapper");
    element.classList.remove("open");
  };
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      {/*============================*/}

      <div id="sticky-header" className={"header-menu " + navbarColor}>
        <div className="container-fluid">
          <div className="row">

            <div className="col-lg-4">
              <div className="logo">
                <div className="logoFormate">
                    <div className="imge_section">
                        <ImageList sx={{ width: 45, height: 50}} cols={1} rowHeight="auto" gap={2}>
                          {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                              <img
                                src={`${item.img}`}
                                alt={item.title}
                                loading="logo"
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                    </div> 
                    <div className="logo_item">
                      <a href="#">{TitleText}</a> 
                        <span className="sw_sub_title">
                        {" "}
                        {localStorage.getItem("FacilityName")}
                      </span>
                    </div>
                </div>
              </div>
              <button
                onClick={() => onMobileMenuOpen()}
                className="mobile_menu_bars_icon"
                type="button"
              >
                <i className="fas fa-bars"></i>
              </button>
            </div>

            <div className="col-lg-8">
              <div className="tp-mega-full">
                <div className="tp-menu align-self-center">
                  <nav className="desktop_menu">
                    <ul>
                      {menu.map((row, i) => {
                        if(props.location.pathname==row.url)
                        document.title =TitleText+' - '+ DispensingLanguage[lan][menukey][row.title]; 

                        if(props.location.pathname=="/my-profile")
                        document.title =TitleText+' - '+ DispensingLanguage[lan][menukey]["My Profile"];
                        return (
                          <li key={i}>
                            <Link
                              to="#"
                              onClick={() => props.history.push(row.url)}
                            >
                              {DispensingLanguage[lan][menukey][row.title]}

                              {row.submenu.length > 0 ? (
                                <span className="tp-angle pull-right">
                                  <i className="fa fa-angle-down"></i>
                                </span>
                              ) : (
                                <></>
                              )}
                            </Link>

                            {row.submenu.length > 0 ? (
                              <ul className={"submenu " + row.position}>
                                {row.submenu.map((row1, i1) => {
                                  if(props.location.pathname==row1.url)
                                  document.title =TitleText+' - '+  DispensingLanguage[lan][menukey][row1.title];
                                  return (

                                   
                                    <li key={i1}
                                     hidden={
                                      (bOrderapprover==0)&&(row.id==6)?                                      
                                      (InitComplete==1?false:((row1.id==42||row1.id==184)?false:true))
                                      :false
                                     } 
                                    > 
                                       
                                      <Link
                                        to="#" 
                                        onClick={() =>
                                          props.history.push(row1.url)
                                        }
                                      >
                                        {
                                          DispensingLanguage[lan][menukey][
                                            row1.title
                                          ]
                                        }

                                        {row1.submenu.length > 0 ? (
                                          <span className="tp-angle pull-right">
                                            <i className="fa fa-angle-right"></i>
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </Link>  
                                      {row1.submenu.length > 0 ? (
                                        <ul className={"submenu " + row1.position}>
                                          {row1.submenu.map((row2, i2) => {
                                            if(props.location.pathname==row2.url)
                                            document.title =TitleText+' - '+  DispensingLanguage[lan][menukey][row2.title];
                                            return (
                                              <li key={i2}>
                                                <Link
                                                  to="#"
                                                  onClick={() =>
                                                    props.history.push(row2.url)
                                                  }
                                                >
                                                  {
                                                    DispensingLanguage[lan][
                                                      menukey
                                                    ][row2.title]
                                                  }
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      ) : (
                                        <></>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </li>
                        );
                      })}

                      <li>
                        <a href="#">{ DispensingLanguage[lan][menukey]['Profile'] }
                          <span className="tp-angle pull-right">
                            <i className="fa fa-angle-down"></i>
                          </span>
                        </a>
                        <ul className="submenu left_position">
                          {/* <li>
                            <a href="#">My account</a>
                          </li>
                          <li>
                            <a href="#">My profile</a>
                          </li>
                          <li>
                            <a href="#">Admin Permissions</a>
                          </li> */}


                          <li>
                              <Link to="#" onClick={(e) => {
                              e.preventDefault();
                              props.history.push("/my-profile");
                              //props.history.push("/my-profile");
                              }}>{DispensingLanguage[lan][menukey]["My Profile"]}</Link>
                          </li>

                          <li>
                            <Link
                              to="javascript:void(0)"
                              onClick={(e) => {
                                document.title =TitleText+' - '+  'Login';
                                e.preventDefault();
                                sessionStorage.clear();
                                setTimeout(() => {
                                  props.history.push("/login");
                                }, 1000);
                              }}
                            >
                                {DispensingLanguage[lan][menukey]['Logout']}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* =========Mobile Menu======== */}
      <div id="mobileMenuWrapper" className="mobile-menu-wrapper">
        <div className="off-canvas-overlay">
          <div className="offcanvas-body">
            <div className="mobile_menu_close">
              <button
                onClick={() => onMobileMenuClose()}
                className="mobile_menu_close_icon"
                type="button"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
            <ul className="sw_mobile_menu">
              {menu.map((row, i) => {
                if(props.location.pathname==row.url)
                document.title =TitleText+' - '+  DispensingLanguage[lan][menukey][row.title];
                return (
                  <li key={i}>
                    <Link
                      to="#"
                      onClick={() => props.history.push(row.url)}
                    >
                      {DispensingLanguage[lan][menukey][row.title]}

                      {row.submenu.length > 0 ? (
                        <span className="tp-angle">
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <></>
                      )}
                    </Link>

                    {row.submenu.length > 0 ? (
                      <ul className="submenu">
                        {row.submenu.map((row1, i1) => {
                          if(props.location.pathname==row1.url)
                          document.title =TitleText+' - '+  DispensingLanguage[lan][menukey][row1.title];
                          return (
                            <li key={i1}>
                              <Link
                                to="#"
                                onClick={() => props.history.push(row1.url)}
                              >
                                {DispensingLanguage[lan][menukey][row1.title]}

                                {row1.submenu.length > 0 ? (
                                  <span className="tp-angle">
                                    <i className="fa fa-angle-right"></i>
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </Link>

                              {row1.submenu.length > 0 ? (
                                <ul className="submenu">
                                  {row1.submenu.map((row2, i2) => {
                                  if(props.location.pathname==row2.url)
                                  document.title =TitleText+' - '+  DispensingLanguage[lan][menukey][row2.title];
                                    return (
                                      <li key={i2}>
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            props.history.push(row2.url)
                                          }
                                        >
                                          {
                                            DispensingLanguage[lan][menukey][
                                              row2.title
                                            ]
                                          }
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <></>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                );
              })}

              <li>
                <a href="#">{ DispensingLanguage[lan][menukey]['Profile'] }
                  <span className="tp-angle">
                    <i className="fa fa-angle-down"></i>
                  </span>
                </a>
                <ul className="submenu">
                  <li>
                      <Link to="#" onClick={(e) => {
                      document.title =TitleText+' - '+  DispensingLanguage[lan][menukey]["My Profile"];
                      e.preventDefault();
                      props.history.push("/my-profile");
                      //props.history.push("/my-profile");
                      }}>{DispensingLanguage[lan][menukey]["My Profile"]}</Link>
                  </li>
                  {/*<li>
                    <a href="#">My account</a>
                  </li>
                  <li>
                    <a href="#">My profile</a>
                  </li>
                  <li>
                    <a href="#">Admin Permissions</a>
                  </li>*/}
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        document.title =TitleText+' - '+  DispensingLanguage[lan][menukey]["Logout"];
                        sessionStorage.clear();
                        setTimeout(() => {
                          props.history.push("/login");
                        }, 1000);
                      }}
                    >
                      {DispensingLanguage[lan][menukey]['Logout']}
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* =========Mobile Menu======== */}
    </>
  );
}

export default AfterLoginNavbar;
