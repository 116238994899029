import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as api from "../../actions/api";
import { useMutation } from "react-query";
// material components
import {
  Typography,
  TextField,
  FormControl,
  Grid,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ReactTabulator } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
// import Notification from "../../../services/Notification";

import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const FacilityeLMISActivityStatusReport = (props) => {
  let TempZoneList = useState([]);
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const menukey = "facility-elmis-activity-status";
  const [isLoadingUi, setLoading] = useState(true);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

 //region combo list
 const [RegionList, setRegionListData] = useState([]);
 const [activeRegionData, setAcRegionData] = useState({
   id: RegionList.length > 0 ? RegionList[0].id : 0,
   name: RegionList.length > 0 ? RegionList[0].name : "",
 });

 React.useEffect(() => {
   if (activeRegionData.id == 0) {
     getRegionData();
   }
 }, [activeRegionData.id]);

 const handleDepartmentChange = (event, newValue) => {
   setAcRegionData(newValue);
   getCommuneData(newValue.id);
 };

 const getRegionData = () => {
   let FacilitylevelParam = {
     action: "getDepartmentforreport",
     RoleId: 1,
     UserId: UserId,
     IsAll: 1,
     menukey: menukey,
     lan: lan,
   };
   cRegionList(FacilitylevelParam);
 };

 const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
   onSuccess: (data) => {
     if (data.status == 200) {
       setRegionListData(data.data.datalist);

       setAcRegionData({
         id: data.data.datalist.length ? data.data.datalist[0].id : 0,
         name: data.data.datalist.length ? data.data.datalist[0].name : "",
       });
       getCommuneData(data.data.datalist[0].id);
     }
   },
 });

 ////distict combo list
 const [Communelist, setCommunelistData] = useState([]);
 const [activeCommuneData, setAcCommuneData] = useState({
   id: -1,
   name: Communelist.length > 0 ? Communelist[0].name : "",
 });

 const getCommuneData = (RegionId) => {
   let FacilitylevelParam = {
     action: "getcommuneforreport",
     RoleId: 1,
     UserId: UserId,
     RegionId: RegionId,
     IsAll: 1,
     menukey: menukey,
     lan: lan,
   };
   cgetCommuneList(FacilitylevelParam);
 };

 const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
   onSuccess: (data, params) => {
     if (data.status == 200) {
       setCommunelistData(data.data.datalist);

       setAcCommuneData({
         id: data.data.datalist.length ? data.data.datalist[0].id : 0,
         name: data.data.datalist.length ? data.data.datalist[0].name : "",
       });

       getFacilityData(
         params.RegionId,
         data.data.datalist.length ? data.data.datalist[0].id : 0
       );
     }
   },
 });

 // Commune Change
 const handleCommuneChange = (event, newValue) => {
   setAcCommuneData(newValue);
   getFacilityData(activeRegionData.id, newValue.id);
 };

 //facility combo load

 const [Facility_list, setFacilityData] = useState([]);
 const [activeFacilityData, setAcFacilityData] = useState({
   id: -1,
   name: Facility_list.length > 0 ? Facility_list[0].name : "",
 });

 const getFacilityData = (regid, communeid) => {
   let FacilityParam = {
     action: "FacilitylistforReport",
     RegionId: regid,
     DistrictId: communeid,
     RoleId: 1,
     UserId: UserId,
     IsAll: 1,
     menukey: menukey,
     lan: lan,
   };
   cFacilityList(FacilityParam);
 };

 const { mutate: cFacilityList } = useMutation(api.getFacility, {
   onSuccess: (data, params) => {
     if (data.status == 200) {
       setFacilityData(data.data.datalist);

       setAcFacilityData({
         id: data.data.datalist.length ? data.data.datalist[0].id : 0,
         name: data.data.datalist.length ? data.data.datalist[0].name : "",
       });

       refreshAllData(
         params.RegionId,
         params.DistrictId,
         data.data.datalist.length ? data.data.datalist[0].id : 0,
         selectedStartDate,
         selectedEndDate
       );
     }
   },
 });

 const handleFacilityListChange = (event, newValue) => {
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     newValue.id,
     selectedStartDate,
     selectedEndDate
   );
   setAcFacilityData(newValue);
 };

 const [selectedStartDate, setSelectedStartDate] = useState(
   moment().subtract(30, "days").format("YYYY-MM-DD")
 );
 //console.log("Selected Date",selectedStartDate);

 const [selectedEndDate, setSelectedEndDate] = useState(
   moment().format("YYYY-MM-DD")
 );

 const handleStartDateChange = (date) => {
   setSelectedStartDate(date);
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     activeFacilityData.id,
     date,
     selectedEndDate
   );
 };

 const handleEndDateChange = (date) => {
   setSelectedEndDate(date);
   refreshAllData(
     activeRegionData.id,
     activeCommuneData.id,
     activeFacilityData.id,
     selectedStartDate,
     date
   );
 };

 const [dataItems, setDataItems] = useState([]);

 const refreshAllData = (RegionId, DistrictId, FacilityId, StartDate, EndDate) => {

   let params1 = {
     menukey: menukey,
     RegionId: RegionId,
     DistrictId: DistrictId,
     FacilityId: FacilityId,
     StartDate: StartDate,
     EndDate: EndDate,
     lan: lan
   };

   TableMutate({ queryKey: [{ ...params1, action: "getFacilityeLMISActivityStatusReportList" }] });

 };

 const { mutate: TableMutate } = useMutation(api.getFacilityeLMISActivityStatusReport, {
   onSuccess: (data) => {
    setDataItems(data);
    setLoading(false);
   },
 });

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 60,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      field: "FacilityCode",
      headerSort: true,
      headerFilter: true,
      width: 120,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerSort: true,
      headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 160,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Last Activity"],
      field: "last_activity",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 164,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Non-zero Product"],
      field: "nonzeroproducts",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 150,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock Take"],
      field: "StockTake",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 105,

    },
    {
      title: DispensingLanguage[lan][menukey]["Order"],
      field: "Indent",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive From Supplier"],
      field: "RecvFromSupplier",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 176,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive From Warehouse"],
      field: "ReceiveFromWarehouse",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 195,
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue Against Order"],
      field: "IssueAgainstOrder",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 160,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Issue To Field"],
      field: "IssueToField",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Field Dispense"],
      field: "FieldDispense",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment"],
      field: "Adj",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 115,
    },
   
    {
      title: DispensingLanguage[lan][menukey]["LMIS"],
      field: "LMIS_Report",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
    },
    {
      title: DispensingLanguage[lan][menukey]["AEFI"],
      field: "AEFI",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
    },
   
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    window.open(
      finalUrl +
        "?action=getFacilityLMISActiveReprot" +
        "&reportType=" +
        reportType +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&DistrictId=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="section signup-top-padding sw_inner_container">
        <div className="sw_inner_card">
          <div className="d-flex justify-uiLanguage">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Facility eLMIS Activity Status"]}
                </div>
              </div>
            </Grid>
          </div>
          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            className={classes.fullWidth}
                            //disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label={
                              DispensingLanguage[lan][menukey]["Start date"]
                            }
                            value={selectedStartDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={3} sm={2}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            className={classes.fullWidth}
                            //disableToolbar
                            autoOk={true}
                            variant="inline"
                            format="dd/MM/yyyy"
                            id="date-picker-inline"
                            label={DispensingLanguage[lan][menukey]["End date"]}
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            disableFuture={true}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* end of filter */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardContent>
                  <ReactTabulator
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height={550}
                  />
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end of row */}
        </div>
      </div>
    </>
  );
};

export default FacilityeLMISActivityStatusReport;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});
