import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
  Typography,
} from "@material-ui/core";

import { DeleteOutline, ViewList, FileCopy, Add, Event } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';

import ProductsModal from "../../../services/ProductsModal";
import DatePickerModal from "../../../services/DatePickerModal";
import { cellFocusEditor, generateBatchId } from "../../../services/Common";
import SupplierModal from "./SupplierModal";


import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale
import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";

let timeStamp = "";

let isDirty=false;
let masterDirty=false;

const DispenserFormData = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "receive-from-supplier";
  const FacilityId = localStorage.getItem("FacilityId");
  moment.locale(lan == "en_GB" ? "enGB" : "fr");

  const classes = useStyles();

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  let ReceiveDetailsRef = null;
  const { t, i18n } = useTranslation();

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);
  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({menukey});
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({menukey});

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [DatePickerOpen, setDatePickerOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const [SupplierOpen, setSupplierOpen] = useState(false);
  const [ManufacturerOpen, setManufacturerOpen] = useState(false);
  const [supplierCode, setSupplierCode] = useState("");
  const [ManufacturerCode, setManufacturerCode] = useState("");

  let SupplierListByFacility = JSON.parse(localStorage.getItem("SupplierListByFacility"));
  let ManufacturerList = JSON.parse(localStorage.getItem("ManufacturerList"));

  let ManufacturerListVal = {};
  let ManufacturerListText = {};
  for (let i = 0; i < ManufacturerList.length; i++) {
    ManufacturerListVal[ManufacturerList[i].ManufacturerName]=ManufacturerList[i].ManufacturerName;
    ManufacturerListText[ManufacturerList[i].ManufacturerId]=ManufacturerList[i].ManufacturerName;
     
  }

  const UsersListByFacility = JSON.parse(
    localStorage.getItem("UsersListByFacility")
  );

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [currExpiryDate, setCurrExpiryDate] = useState("");

  /* ====Start of Print Excel Many Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/receive_from_supplier_entry_list_print.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TransactionId=" +
      props.formData.TransactionId +
      "&FacilityId=" +
      FacilityId +
      "&TimeStamp=" +
      Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/receive_from_supplier_entry_list_excel.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TransactionId=" +
      props.formData.TransactionId +
      "&FacilityId=" +
      FacilityId +
      "&TimeStamp=" +
      Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "DatePickerOpen":
        setDatePickerOpen(true);
        break;

      case "DatePickerClose":
        setDatePickerOpen(false);
        break;

      case "SupplierModal":
        setSupplierOpen(false);
        break;
      case "SupplierOpen":
        setSupplierOpen(true);
        break;

      case "ManufacturerModal":
        setManufacturerOpen(false);
        break;
      case "ManufacturerOpen":
        setManufacturerOpen(true);
        break;

      default:
        break;
    }
  };


  const isContainDuplicate = (dataArray) => {

    const mappedData = dataArray.map((el, i) => ({ ...el, BatchId: generateBatchId(el) }));
    const removedDuplicate = new Set(mappedData.map((el) => el.BatchId));

    if (mappedData.length !== removedDuplicate.size) {
      return { data: '', status: true };
    } else {
      setManyTableData(mappedData);
      return { data: mappedData, status: false };
    };

  };

  const addProduct = (NonOrderProductData) => {

    let rows = [];
    let autoId = 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.BatchId = generateBatchId(row);
      newRow.ItemNo = row.ItemNo;
      newRow.dirty = row.dirty;
      newRow.ItemCode = row.ItemCode;

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = row.Quantity * row.UnitPrice;
      newRow.ManufacturerId = row.ManufacturerId;
      newRow.ManufacturerName = row.ManufacturerName;
      rows.push(newRow);
    });


    let temNonordata = NonOrderProductData;
    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.BatchId = generateBatchId(row);
      newRow.ItemNo = row.ItemNo;

      newRow.ItemCode = row.ItemCode;
      newRow.dirty = 1;

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.UnitPrice = row.UnitPrice;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      manyTableData.forEach((rowssss, ij) => {
        if (rowssss.ItemNo == row.ItemNo) {
          newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
          return;
        }
      });

      newRow.CurrentStockQty = 0;
      newRow.Quantity = '';
      newRow.BatchNo = null;
      newRow.ExpiryDate = null;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      newRow.ManufacturerId = null;
      newRow.ManufacturerName = null;
      rows.push(newRow);

      

    });

    isDirty=true;
    onSetDisable();

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
  };

  /*
  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.BatchId = generateBatchId(row);
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
  };

  */

  const addDatePicker = (datePickerData) => {
    if (!datePickerData) {
      return;
    }

    let selectedExpiryDate = moment(datePickerData).format("DD/MM/YYYY");

    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU == currRowId) {
        newRow.ExpiryDate = selectedExpiryDate;
        newRow.dirty = 1;
        isDirty=true;
        onSetDisable();
      } else {
        newRow.ExpiryDate = row.ExpiryDate;
        newRow.dirty = row.dirty;
      }

      newRow.BatchId = generateBatchId(row);
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
     

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;

      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;
      newRow.ManufacturerId = row.ManufacturerId;
      newRow.ManufacturerName = row.ManufacturerName;
      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    //productAdd(rows);
    handleClose("DatePickerClose");
    
  };

  function ShowDatePickerPopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate || props.formData.InvFrom == 2) {
      return <></>;
    } else {
      return (
        <>
          <Event
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              setCurrExpiryDate(rowData.ExpiryDate);
              // setCurrExpiryDate(rowData);
              handleClose("DatePickerOpen");
            }}
          />
        </>
      );
    }
  }

  const ReceiveDetailsColumns = [
    {
      title: "BatchId",
      field: "BatchId",
      headerSort: false,
      headerHozAlign: "center",
      visible: false,
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    {
      title: "TransactionItemId",
      field: "TransactionItemId",
      visible: false
    },
    {
      title: "id",
      field: "id",
      visible: false
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(
        <ActionButton
        /* insertBatchInUpdatedDetailGridData={props.insertBatchInUpdatedDetailGridData} */
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 130,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      //headerFilter: true,
      width: 200,
    },
    /*
    {
      title: DispensingLanguage[lan][menukey]["Current Stock Quantity"],
      field: "CurrentStockQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    */
    {
      title: DispensingLanguage[lan][menukey]["Quantity Received"],
      field: "Quantity",
      width: 150,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? cellFocusEditor: '',
      editor:
        props.bStockUpdate == 0
          ? props.formData.InvFrom == 2
            ? false
            : cellFocusEditor
          : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        /*
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.UnitPrice * curcellval;
        pricecell.setValue(totprice);
        */

        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }

      },
    }, //
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 150,
      cssClass: "tabluator-column-editable text-field-editable",
      //editor: props.bStockUpdate == 0 ? cellFocusEditor: '',
      editor:
        props.bStockUpdate == 0
          ? props.formData.InvFrom == 2
            ? false
            : cellFocusEditor
          : "",
      cellEdited: function (cell) {

        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        //   props.setUpdatedDetailsGridDataHandler(rowdata);
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }

      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 150,
      cssClass: "tabluator-column-editable text-field-editable",

      editor:
        props.bStockUpdate == 0
          ? props.formData.InvFrom == 2
            ? false
            : cellFocusEditor
          : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }

      },
    },
    {
      title: "",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 20,
      formatter: reactFormatter(<ShowDatePickerPopup />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerId",
      visible: false,
      hozAlign: "left",
      headerHozAlign: "left",
      
      cssClass: "tabluator-column-editable text-field-editable",
      width: 190,
      headerSort: false,
       
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      hozAlign: "left",
      headerHozAlign: "left",
      
      cssClass: "tabluator-column-editable text-field-editable",
       
      headerSort: false,
      visible: (props.bStockUpdate ? false : true),      
      editor:
         props.bStockUpdate == 0
          ? props.formData.InvFrom == 2
            ? false
            : 'autocomplete'
          : "",
        
        editorParams:{ 
          values:ManufacturerListVal,
          showListOnEmpty:true, //show all values when the list is empty,
          freetext:false, //allow the user to set the value of the cell to a free text entry
          allowEmpty:true, //allow empty string values
         
          searchingPlaceholder:"Filtering ...", //set the search placeholder
         // emptyPlaceholder:"(no matching results found)", //set the empty list placeholder
          listItemFormatter:function(value, title){ 
              return  title; 
          },
          sortValuesList:"asc", //if creating a list of values from values:true then choose how it should be sorted
        defaultValue:"", //set the value that should be selected by default if the cells value is undefined
        elementAttributes:{
            maxlength:"30", //set the maximum character length of the input element to 10 characters
        },
       // mask:"AAA-999",
        verticalNavigation:"hybrid",

        searchFunc:function(term, allItems){ //search for exact matches

        if(term==null) return allItems;
        if(term=='') return allItems;
        console.log("searchFN");
                
        
          let matches={};
          
          for (const [key, value] of Object.entries(ManufacturerListVal)) {
            console.log(key.search(term));
             
            if(key.toLocaleLowerCase().search(term.toLocaleLowerCase())==-1){
             
             
            }else if(key.toLocaleLowerCase().search(term.toLocaleLowerCase()>=0)){
              matches[key]=value;
            }
            
            
            
          } 

          if(Object.keys(matches).length==0)
          matches[''] =DispensingLanguage[lan][menukey]["No Data Found"]; 
          
          return matches;
          },
        },
        cellEdited: function (cell) { 
          const curcellval = cell.getValue();
          const currow = cell.getRow();
          
          let CellOldValue = cell._cell.oldValue;
  
          if (
            CellOldValue == null ||
            CellOldValue == "" ||
            CellOldValue == undefined
          ) {
            CellOldValue = "";
          }
  
          if (CellOldValue != curcellval) {
            currow.getCell("dirty").setValue(1);
            for (const [key, value] of Object.entries(ManufacturerListText)) {
              if(`${value}`==cell.getValue()){
                currow.getCell("ManufacturerId").setValue(`${key}`);                
              } 
            }

            if(curcellval=='')
              currow.getCell("ManufacturerId").setValue('');   
            
            isDirty = true;
            onSetDisable();
          }
        },
      },
    // {
    //   title: DispensingLanguage[lan][menukey]["Manufacturer"],
    //   field: "ManufacturerId",
    //   hozAlign: "left",
    //   cssClass: "tabluator-column-editable text-field-editable",
    //   headerHozAlign: "left",
    //   //width: 200,
    //   headerSort: false,
    //   visible: (props.bStockUpdate ? false : true),
    //   formatter: reactFormatter(
    //     <RowInputData
    //       labelName="Manufacturer"
    //       field="ManufacturerId"
    //       fieldType="drop-down-manufacturer"
    //       dropDownKey="manufacturer"
    //     />
    //   ),
    // },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      hozAlign: "left",
      cssClass: "tabluator-column-editable text-field-editable",
      headerHozAlign: "left",
      //width: 200,
      headerSort: false,
      visible: (props.bStockUpdate ? true : false),
    },
    /*
    {
      title: DispensingLanguage[lan][menukey]["Unit Price (CFA)"],
      field: "UnitPrice",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["min:0"],
      cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? cellFocusEditor: '',
      editor:
        props.bStockUpdate == 0
          ? props.formData.InvFrom == 2
            ? false
            : cellFocusEditor
          : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const pricecell = currow.getCell("LineTotal");
        const totprice = rowdata.Quantity * curcellval;
        pricecell.setValue(totprice);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Line Total (CFA)"],
      field: "LineTotal",
      width: "10%",
      formatter: "money",
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
    },

    */
  ];




  const onSetDisable = () => {  
    if(isDirty==true){   
      //document.getElementById("is_dirty_message").classList.remove("dnone"); 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }
    else{  
     //document.getElementById("is_dirty_message").classList.add("dnone"); 
     document.getElementById("master_dirty_message").classList.add("dnone");
    }    
  }


  const onMasterSetDirty = () => {   
    if(masterDirty==true){ 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  }


  // Manufacturer  comboscript or autocomplete

  const [selectedManufacturers, setSelectedManufacturers] = useState({});

  const handleManufacturerChange = (event, newValue, rowData) => {
    setSelectedManufacturers((prevState) => ({
      ...prevState,
      [rowData.SKU]: newValue,
    }));

    setcombochangevalue(newValue, rowData.SKU);
  };

  function RowInputData(props: any, type = null) {
    console.log(props.cell._cell.row.data);
    const rowData = props.cell._cell.row.data;
    let fieldType = props.fieldType;
    switch (fieldType) {

      case "drop-down-manufacturer":
        return (
          <FormControl className={classes.fullWidth}>
            <Autocomplete
              autoHighlight
              id={`ManufacturerId_${rowData.SKU}`}
              options={ManufacturerList}
              value={selectedManufacturers[rowData.SKU] || ""}
              onChange={(event, newValue) => handleManufacturerChange(event, newValue, rowData)}
              getOptionLabel={(option) => option.ManufacturerName || ""}
              renderOption={(option) => (
                <Typography className="sw_fontSize">{option.ManufacturerName}</Typography>
              )}
              //renderInput={(params) => <TextField {...params} variant="standard" />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          </FormControl>
        );

      default:
        return null;
    }
  }

  useEffect(() => {
    // Set initial values when the page is edited
    const initialValues = {};
    manyTableData.forEach((rowData) => {
      initialValues[rowData.SKU] = {
        ManufacturerId: rowData.ManufacturerId || "",
        ManufacturerName: rowData.ManufacturerName || "",
      };
    });
    setSelectedManufacturers(initialValues);

  }, [manyTableData]);


  const setcombochangevalue = (newValue, skusl) => {

    let rows = [];
    let autoId = Date.now(); // 0;
    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.BatchId = generateBatchId(row);
      if (row.SKU == skusl) {
        if (newValue == null || newValue == "") {
          newRow.ManufacturerId = null;
          newRow.ManufacturerName = null;
        } else {
          newRow.ManufacturerId = newValue.ManufacturerId;
          newRow.ManufacturerName = newValue.ManufacturerName;
        }


        newRow.dirty = 1;
        isDirty=true;
        onSetDisable();

      } else {
        newRow.ManufacturerId = row.ManufacturerId;
        newRow.ManufacturerName = row.ManufacturerName;
        newRow.dirty = row.dirty;
      }


      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      

      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.NonPerishable = row.NonPerishable;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;
      rows.push(newRow);
      

    });

    setManyTableData(rows);
    

  };

  // End  manufecture comboscript or autocomplete


  /*
    const onPostBtnClick = () => {
      setbStockUpdate(true);
      props.handlePost();
    };
  
    const onSaveUpdateBtnClick = () => {
      props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
      //  props.handleUpdate();
    };
  
    */

  const onPostBtnClick = () => {


    ///===================Check Non Non Perishable  of any item==========/////////////
    let CheckPerishable=1;
    let NonPerishableInfo = {};

    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if(row.NonPerishable==0){
        if (row.ExpiryDate === null || row.ExpiryDate === "" || row.ExpiryDate === undefined) {
          NonPerishableInfo = row;

          props.openNoticeModal({
            isOpen: true,
            msg: t(DispensingLanguage[lan][menukey]["Perishable item - blank expiry date is not allowed"]),// + ' - ' + NonPerishableInfo.ItemName,
            msgtype: 0,
          });
          CheckPerishable=0;
          return;
        }
      }
    });
    ///===================Check Perishable  of any item==========/////////////

    if(CheckPerishable==1){


    if (ReceiveDetailsRef == null) return;


    ///===================Check qty is blank of any item==========/////////////

    let blankinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (row.Quantity === null || row.Quantity === "") {
        blankinfo = row;
      }
    });

    if (Object.keys(blankinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["Blank quantity is not allowed"]) + ' - ' + blankinfo.ItemName,
        msgtype: 0,
      });
      return;
    }
    ///===================Check qty is blank of any item==========/////////////

    handlePost();
    
    }


  };

  /*
  const handlePost1111 = async (e) => {

    let manydata = ReceiveDetailsRef.table.getData();

    const isDuplicate = isContainDuplicate(manydata);

    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"],
        msgtype: 0,
      });

    } else {

      // let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');

      let validForm = props.checkValidateForm(isDuplicate.data, 'post');
      if (validForm) {
        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
          icon: "warning",
          buttons: {
            confirm: {
              text: t(DispensingLanguage[lan][menukey]["Yes"]),
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
            cancel: {
              text: t(DispensingLanguage[lan][menukey]["No"]),
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
            
        }).then((willAction) => {
          if (willAction) {
            let fDtat = { ...validForm, "bStockUpdated": 1 };
            //console.log('fDtat: ', fDtat);
            mutateUpdate(fDtat);

          } else {
            // setbStockUpdate(0);
            props.stockPosted(0);
            // let route = `${TransactionId}`;
            let route = `${validForm.TransactionId}`;
            props.history.push(route);
          }
        });
        // End Post Alert


      }
    }


  };

  */

  


  
  const handlePost = async (e) => {

    let manydata = ReceiveDetailsRef.table.getData();

    const isDuplicate = isContainDuplicate(manydata);

    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"],
        msgtype: 0,
      });

    } else {

      // let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');

      let manydataP=[]; 
      isDuplicate.data.forEach((row,i)=>{
        if(row.dirty==1)
        manydataP.push(row);
      
      })

      let validForm = props.checkValidateForm(manydataP, 'post');
      if (validForm) {

      let fDtat = { ...validForm, "bStockUpdated": 1, "masterDirty":masterDirty };
      modalPopupDelete(mutateUpdate, fDtat, {PostQ:"modalQPost"}, ModalNo,validForm); 

      }

   }

  };

  const ModalNo=(validForm)=>{
       
       props.stockPosted(0);
       let route = `${validForm.TransactionId}`;
       props.history.push(route);

  }


  const onSaveUpdateBtnClick = () => {

    ///===================Check Non Non Perishable  of any item==========/////////////
      let CheckPerishable=1;
      let NonPerishableInfo = {};

      ReceiveDetailsRef.table.getData().forEach((row, i) => {
        if(row.NonPerishable==0){
          if (row.ExpiryDate === null || row.ExpiryDate === "" || row.ExpiryDate === undefined) {
            NonPerishableInfo = row;

            props.openNoticeModal({
              isOpen: true,
              msg: t(DispensingLanguage[lan][menukey]["Perishable item - blank expiry date is not allowed"]),// + ' - ' + NonPerishableInfo.ItemName,
              msgtype: 0,
            });
            CheckPerishable=0;
            return;
          }
        }
      });
  ///===================Check Perishable  of any item==========/////////////

  if(CheckPerishable==1){
    let manydata = ReceiveDetailsRef.table.getData();

    const isDuplicate = isContainDuplicate(manydata);
    if (isDuplicate.status) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Duplicate Lot available"],
        msgtype: 0,
      });
    } else {
      setUpdatedDetailsGridDataHandler(isDuplicate.data);
    }

  }

    // setUpdatedDetailsGridDataHandler(manydata);
  };


  /*
  const onSaveUpdateBtnClick = () => {

    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    
  }

  */


  const setUpdatedDetailsGridDataHandler = (rows) => {

    let manydataR=[]; 
    rows.forEach((row,i)=>{
      if(row.dirty==1)
      manydataR.push(row);
    
    })

    let validForm = props.checkValidateForm(manydataR, 'edit');
    // console.log('isval: ', validForm);
    if (validForm) {

      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutate({...validForm,'masterDirty':masterDirty});
      } else {
        //when update
        mutateUpdate({...validForm,'masterDirty':masterDirty});
      }



    }
  };


  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveReceiveFromSupplierInv, {
    onSuccess: (data) => {
      if (data.status == 200) {

        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `edit/${data.TransactionId}`;
        props.history.push(route);

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })


  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(api.updateReceiveFromSupplierInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) {
        masterDirty=false;
        onMasterSetDirty();
        isDirty=false;
        //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        if (data.bStockUpdated == 1) {
          mutatePost(props.formData.TransactionId);
        }
        else {
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

        }

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(api.updateReceiveFromSupplierStock, {
    onSuccess: (data) => {
      if (data.status == 200) {


        // setDeletedDataSet([]);

        if (data.data.success == 0) {
          //setbStockUpdate(0);
          props.stockPosted(0);
          props.refetchInvoice();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        } else {
          // setbStockUpdate(1);
          props.stockPosted(1);
          props.refetchInvoice();

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

        }

      } else {
        // setbStockUpdate(0);
        props.stockPosted(0);
        props.refetchInvoice();
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  });

  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter((element) => element != data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
    isDirty = true;
    onSetDisable();
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  function ActionButton(propsAction) {
    const rowData = propsAction.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = propsAction.cell._cell.row.data; //{};

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);
        console.log("Rposition", setRposition);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = null;
        obj.ExpiryDate = "";
        obj.BatchNo = "";
        obj.Quantity = 0;
        obj.SKU = Date.now();
        obj.dirty = 1;

        tmpPrevRows.splice(setRposition + 1, 0, obj);

        isDirty = true;
        onSetDisable();

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    if (props.formData.InvFrom == 2) {
      return <></>;
    } else {
      if (bStockUpdate == 0)
        return (
          <>
            <FileCopy
              onClick={() => {
                if (!bStockUpdate) goToDetails(rowData);
              }}
            />

            <DeleteOutline
              onClick={() => {
                if (!bStockUpdate) deleteReceiveInv(rowData);
              }}
            />
          </>
        );
      else return <></>;
    }
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    setManyTableData([]);
    props.handleReset();
  };


  const backToList = () => {

    if((masterDirty==true)||(isDirty==true)){
      
      modalPopupBacktoList(backToListCallBack, "/receive-from-supplier", {PostQ:"modalBackToListQ"}); 
  
    }else props.history.push("/receive-from-supplier")
    
  };

  const backToListCallBack = (PageUrl) => {
      masterDirty=false;
      isDirty=false;
      props.history.push(PageUrl);
  }


  const formOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     
     if(cases=='handleChange')
     props.handleChange(e);
     else if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);

     masterDirty=true;
     onMasterSetDirty();

   };

  const [chosenValues, setChosenValues] = useState({
    "SupplierId": { "SupplierId": "", "SupplierName": "" },
    //"ManufacturerId": { "ManufacturerId": "", "ManufacturerName": "" },
    "PreparedBy": { "id": "", "name": "" },
    "ApprovedBy": { "id": "", "name": "" }
  });

  useEffect(() => {

    let chosenValuesData = { ...chosenValues };

    chosenValuesData['SupplierId'] = SupplierListByFacility[SupplierListByFacility.findIndex(Supplier_List => Supplier_List.SupplierId == props.formData.SupplierId)];

    chosenValuesData['PreparedBy'] = UsersListByFacility[UsersListByFacility.findIndex(Users_ListByFacility => Users_ListByFacility.id == props.formData.PreparedBy)];

    chosenValuesData['ApprovedBy'] = UsersListByFacility[UsersListByFacility.findIndex(Users_ListByFacility_A => Users_ListByFacility_A.id == props.formData.ApprovedBy)];

    setChosenValues(chosenValuesData);
  }, [props.formData.SupplierId, props.formData.PreparedBy, props.formData.ApprovedBy]);


  useEffect(() => {
    let chosenValuesData = { ...chosenValues };

    chosenValuesData['SupplierId'] = SupplierListByFacility[SupplierListByFacility.findIndex(Supplier_List => Supplier_List.SupplierId == supplierCode)];
    setChosenValues(chosenValuesData);

    props.formData["SupplierId"] = supplierCode;

  }, [supplierCode, ManufacturerCode]);

  const addSupplier = (SupplierData) => {
    if (SupplierData != "") {

      handleClose("SupplierModal");
      setTimeout(() => setSupplierCode(SupplierData.SupplierId), 500);
    }
  };

  /*
  const addManufacturer = (ManufacturerData) => {
    if (ManufacturerData != "") {

      handleClose("ManufacturerModal");
      setTimeout(() => setManufacturerCode(ManufacturerData.ManufacturerId), 500);
    }
  };

  */

  const handleChangeChoosen = (name, valueobj, value) => {

    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;

    setChosenValues(chosenValuesData);

    props.handleChangeChoosenProps(name, value);

    masterDirty=true;
    onMasterSetDirty();
  };

  useEffect(() => {

    let chosenValuesData = { ...chosenValues };

    if (props.addProductForm) {
      if (props.formData.PreparedBy == '') {
        chosenValuesData['PreparedBy'] = UsersListByFacility[UsersListByFacility.findIndex(Users_ListByFacility => Users_ListByFacility.id == UserId)];
        props.formData.PreparedBy = UserId;
      }
    }
    setChosenValues(chosenValuesData);

  }, [UserId]);

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Receive From Supplier - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                    
                    <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                    
                    {/* <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      // onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button> */}
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/receive-from-supplier")}
                        onClick={() => backToList()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    
                    <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                    
                    <Button
                      // disabled={props.bStockUpdate}
                      disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>
                    <Button
                      //disabled={props.bStockUpdate}
                      disabled={props.bStockUpdate || !(manyTableData.length > 0) || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/receive-from-supplier")}
                      onClick={() => backToList()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Receive Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        value={
                          props.formData.TransactionDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        //onChange={(e) =>props.handleRDateChange(e, "TransactionDate")}                    
                        onChange={(e) =>formOnchange(e,'handleRDateChange','TransactionDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Receive Invoice No"]
                      }
                      // value={formData.FirstName || "Mahmudul"}
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={
                        props.bStockUpdate || props.formData.InvFrom == 2
                      }
                      error={props.errorObject.SupplierInvNo}
                      helperText={props.errorObject.SupplierInvNo}
                      required
                      id="SupplierInvNo"
                      name="SupplierInvNo"
                      label={
                        DispensingLanguage[lan][menukey]["Supplier Invoice No"]
                      }
                      value={props.formData.SupplierInvNo}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','SupplierInvNo')}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}
                    >
                      <KeyboardDatePicker
                        required
                        disabled={props.bStockUpdate}
                        error={props.errorObject.SupplierInvDate}
                        helperText={props.errorObject.SupplierInvDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="SupplierInvDate"
                        label={
                          DispensingLanguage[lan][menukey][
                          "Supplier Invoice Date"
                          ]
                        }
                        autoOk={true}
                        name="SupplierInvDate"
                        fullWidth
                        showTodayButton={true}
                        value={
                          props.formData.SupplierInvDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        //onChange={(e) =>props.handleRDateChange(e, "SupplierInvDate")}
                        onChange={(e) =>formOnchange(e,'handleRDateChange','SupplierInvDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>



                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth_1}>
                      <Autocomplete
                        disabled={manyTableData.length > 0 ? true : props.bStockUpdate}
                        autoHighlight
                        id="SupplierId"
                        options={SupplierListByFacility}
                        onChange={(event, valueobj) => handleChangeChoosen('SupplierId', valueobj, valueobj ? valueobj.SupplierId : "")}
                        getOptionLabel={(option) => option.SupplierName}
                        value={chosenValues['SupplierId']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.SupplierName}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.SupplierId}
                            label={DispensingLanguage[lan][menukey]["Receive From"]}
                            variant="standard"
                            error={props.errorObject.SupplierId}
                            helperText={props.errorObject.SupplierId}
                            id="SupplierId"
                            name="SupplierId"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl className={classes.fullWidth_2}>
                      <Add
                        className="add_plus"
                        hidden={manyTableData.length > 0 ? true : props.bStockUpdate}
                        onClick={() => handleClose("SupplierOpen")}
                      />
                    </FormControl>

                    <SupplierModal
                      handleClose={handleClose}
                      openOrder={SupplierOpen}
                      addSupplier={addSupplier}
                      {...props}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <TextField
                    disabled={
                      props.bStockUpdate || props.formData.InvFrom == 2
                    }
                      // error={props.errorObject.Remarks}
                      // helperText={props.errorObject.Remarks}
                      // required
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>formOnchange(e,'handleChange','Remarks')}
                    />
                  </Grid>
                  {/*
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth_1}>
                      <Autocomplete
                        disabled={manyTableData.length > 0 ? true : props.bStockUpdate}
                        autoHighlight
                        id="ManufacturerId"
                        options={ManufacturerList}
                        onChange={(event, valueobj) => handleChangeChoosen('ManufacturerId', valueobj, valueobj ? valueobj.ManufacturerId : "")}
                        getOptionLabel={(option) => option.ManufacturerName}
                        value={chosenValues['ManufacturerId']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.ManufacturerName}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ManufacturerId}
                            label={DispensingLanguage[lan][menukey]["Manufacturer"]}
                            variant="standard"
                            error={props.errorObject.ManufacturerId}
                            helperText={props.errorObject.ManufacturerId}
                            id="ManufacturerId"
                            name="ManufacturerId"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl className={classes.fullWidth_2}>
                      <Add
                        className="add_plus"
                        hidden={manyTableData.length > 0 ? true : props.bStockUpdate}
                        onClick={() => handleClose("ManufacturerOpen")}
                      />
                    </FormControl>

                    <ManufacturerModal
                      handleClose={handleClose}
                      openOrder={ManufacturerOpen}
                      addManufacturer={addManufacturer}
                      {...props}
                    />
                  </Grid>
                  */}

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={props.IsCompleted || props.bStockUpdate}
                        autoHighlight
                        id="PreparedBy"
                        options={UsersListByFacility}
                        onChange={(event, valueobj) => handleChangeChoosen('PreparedBy', valueobj, valueobj ? valueobj.id : "")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['PreparedBy']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.PreparedBy}
                            label={DispensingLanguage[lan][menukey]["Receive By"]}
                            variant="standard"
                            error={props.errorObject.PreparedBy}
                            helperText={props.errorObject.PreparedBy}
                            id="PreparedBy"
                            name="PreparedBy"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={props.IsCompleted || props.bStockUpdate}
                        autoHighlight
                        id="ApprovedBy"
                        options={UsersListByFacility}
                        onChange={(event, valueobj) => handleChangeChoosen('ApprovedBy', valueobj, valueobj ? valueobj.id : "")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['ApprovedBy']}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ApprovedBy}
                            label={DispensingLanguage[lan][menukey]["Approved By"]}
                            variant="standard"
                            error={props.errorObject.ApprovedBy}
                            helperText={props.errorObject.ApprovedBy}
                            id="ApprovedBy"
                            name="ApprovedBy"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="DeliveredBy"
                      name="DeliveredBy"
                      label={DispensingLanguage[lan][menukey]["Delivered By"]}
                      value={props.formData.DeliveredBy}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}




                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  {/* new row */}
                  <Grid item xs={12} sm={12}>
                    <Card className="sw_card">
                      <CardContent>
                        <Grid container className="mb-2">
                          <Grid item xs={12} sm={12}>
                            <div className="float-right sw_btn_control">
                              <Button
                                //disabled={props.bStockUpdate}
                                /*
                                disabled={
                                  props.formData.InvFrom == 2
                                    ? true
                                    : props.bStockUpdate
                                }
                                */
                                disabled={(props.addProductForm == true ? true : (props.formData.InvFrom == 2 ? true : props.bStockUpdate))}
                                className="mr-2"
                                variant="contained"
                                color="primary"
                                onClick={() => handleClose("ProductsOpen")}
                              >
                                {
                                  DispensingLanguage[lan][menukey][
                                  "SELECT NON-ORDER PRODUCT"
                                  ]
                                }
                              </Button>
                            </div>

                            <ProductsModal
                              handleClose={handleClose}
                              open={open}
                              addProduct={addProduct}
                              {...props}
                            />

                            <DatePickerModal
                              handleClose={handleClose}
                              open={DatePickerOpen}
                              addDatePicker={addDatePicker}
                              reload={0}
                              ExpiryDate={currExpiryDate}
                              {...props}
                            />
                          </Grid>
                        </Grid>
                        <ReactTabulator
                          ref={(r) => (ReceiveDetailsRef = r)}
                          columns={ReceiveDetailsColumns}
                          data={manyTableData.sort((a, b) => {
                            const groupComparison = a.GroupName.localeCompare(b.GroupName);
                            return groupComparison !== 0 ? groupComparison : a.ItemName.localeCompare(b.ItemName);
                          })}
                          height="350px"
                          layout={"fitColumns"}
                          options={{
                            groupBy: "GroupName",
                            columnCalcs: "both",
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* new row */}
                </Grid>
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
});
