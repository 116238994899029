import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { checkLogin, checkUserPermission } from "../../../services/CheckUserAccess";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
// import swal from "sweetalert";
// Shipment screen
import Shipment from "./Shipment";
import AddShipment from "./AddShipment";
import EditShipment from "./EditShipment";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "shipment";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);

  const [hasUserPermission, setHasUserPermission] = React.useState(false);
  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");
  //   if (!token) {
  //     swal({
  //       title: "Oops!",
  //       text: "token expired. Please login again",
  //       icon: "warning",
  //       buttons: ["No", "Yes"],
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         window.location.href = process.env.REACT_APP_BASE_NAME + `/login`;
  //         sessionStorage.clear();
  //       }
  //     });
  //   }
  // };

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }


  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    if(document.getElementById("Snackbarh")!=null)
    document.getElementById("Snackbarh").classList.remove("dnone"); 
    setMsgObj(obj);
    setTimeout(function(){
      if(document.getElementById("Snackbarh")!=null)
      document.getElementById("Snackbarh").classList.add("dnone"); 
     }, (obj.msgtype==1? 3000:8000));
  };
  const closeNoticeModal = (event, reason) => {
    if (reason == "clickaway") {
      return;
    }
    if(document.getElementById("Snackbarh")!=null)
    document.getElementById("Snackbarh").classList.add("dnone"); 
  };

  return (
    hasUserPermission && (
      <div>
        <AfterLoginNavbar {...props} />
        <div className="section signup-top-padding sw_inner_container">
          <Switch>
            <Route
              path={`${path}/add`}
              render={(props) => (
                <AddShipment {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
            <Route
              path={`${path}/edit/:id`}
              render={(props) => (
                <EditShipment {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
            <Route
              path={`${path}/`}
              render={(props) => (
                <Shipment {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
          </Switch>
          <Notification
            closeNoticeModal={closeNoticeModal}
            msgObj={msgObj}
            {...props}
          ></Notification>
        </div>
      </div>

    )
  );
};

export default Index;

const useStyles = makeStyles({

});
