import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
// import swal from "sweetalert";
// import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import AEFIseverityFormData from "./AEFIseverityFormData";


const AddAEFIseverity = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "aefi-severity";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].name;

  const [formData, setFormData] = useState({
    SeverityName: "",

  });
  const [errorObject, setErrorObject] = useState({});
  // const [pricebesideLevel, setPricebesideLevel] = useState(null);
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["aefiSeverity", id],
    () =>
      api.geIntevals(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.saveAEFIseverityEntry, {
    onSuccess: (data) => {

      if(data.status==200) {

      // api.getAllDropdown("RouteOfAdminlist").then((response) => {
      //   localStorage.setItem(
      //     "RouteOfAdminlist",
      //     JSON.stringify(response.datalist.RouteOfAdminlist)
      //   );
      // });

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("aefiSeverity"); //api page name
      props.history.push("/aefi-severity"); //
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      SeverityName: "",
      
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["SeverityName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    // console.log("from submit ", formData);
    // console.log(validateForm(formData));
    if (validateForm(formData)) {

      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);

      //swal("Success!", "", "success");
    }
  };

  return (
    <>
      <AEFIseverityFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddAEFIseverity;
