import React, { useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  FormControl,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import BasicInformation from "./BasicInformation";
import FundingSource from "./FundingSource";
import QuantityRequired from "./QuantityRequired";
import GapAnalysis from "./GapAnalysis";

const steps = [
  "Basic Information",
  "Funding Source",
  "Quantity Required",
  "Gap Analysis",
];

const CountryProfile = (props) => {
  const classes = useStyles();

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "country-profile-entry";
  const { t, i18n } = useTranslation();
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  const [currLangCode, setCurrLangCode] = useState(lan);

  //combo box start
  const YearList = JSON.parse(localStorage.getItem("YearList"));

  const currentDate = new Date();
  let previousMonth = currentDate.getMonth() + 1 - 1;
  let previousYear = currentDate.getFullYear();

  if (previousMonth < 0) {
    // If the previous month is negative, subtract 1 from the year and set the month to 11 (December)
    previousMonth = 11;
    previousYear -= 1;
  }

  // Create a new Date object for the first day of the previous month
  const previousMonthDate = new Date(previousYear, previousMonth - 1, 15);
  const [currYearId, setYearId] = useState(previousMonthDate.getFullYear());

  const GroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
  // let All_Item_label = {
  //   id: "0",
  //   name: t(DispensingLanguage[lan][menukey]["All"]),
  // };
  // const GroupList = [All_Item_label].concat(ProductGroupList);

  const [currGroupId, setcurrGroupId] = useState(
    GroupList.length > 0 ? GroupList[0].id : ""
  );
  const [GroupName, setgroupName] = useState(
    GroupList.length > 0 ? GroupList[0].name : ""
  );

  const handleGroupChange = (event, newValue) => {
    let rowId = "";
    let rowname = "";
    if (newValue == null) {
      rowId = "";
      rowname = "";
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }
    setcurrGroupId(rowId);
    setgroupName(rowname);
  };

  const handleYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);
    }
  };

  //combo box end

  const [activeStep, setActiveStep] = useState(0);
  // const [skipped, setSkipped] = useState(new Set());

  // const isStepOptional = (step) => step === 1;

  // const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //setSkipped(newSkipped);
  };

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  // const handleReset = () => setActiveStep(0);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BasicInformation
            ItemGroupId={currGroupId}
            YearId={currYearId}
            {...props}
          />
        );
      case 1:
        return (
          <FundingSource
            ItemGroupId={currGroupId}
            YearId={currYearId}
            {...props}
          />
        );
      case 2:
        return (
          <QuantityRequired
            ItemGroupId={currGroupId}
            YearId={currYearId}
            {...props}
          />
        );
      case 3:
        return (
          <GapAnalysis
            ItemGroupId={currGroupId}
            YearId={currYearId}
            {...props}
          />
        );

      // default:
      //   return "Unknown step";
    }
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/Country_Profile_Print_server.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&ItemGroupId=" +
        currGroupId +
        "&YearId=" +
        currYearId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  /* =====Start of Excel Export Code==== */

  const ExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/country_Profile_Excel_server.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&ItemGroupId=" +
        currGroupId +
        "&YearId=" +
        currYearId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="sw_topmargin">
        <div className="sw_makeStyles_tableContainer">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Country Profile Entry"]}
                </div>
              </div>
            </Grid>
          </div>

          {/* combobox Start  */}

          <Card className="sw_card sw_filter_card mb-2">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={3} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="GroupList"
                      disableClearable
                      options={GroupList}
                      onChange={(event, newValue) =>
                        handleGroupChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name}
                      defaultValue={
                        GroupList[
                          GroupList.findIndex(
                            (GroupList) => GroupList.id == currGroupId
                          )
                        ]
                      }
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            DispensingLanguage[lan][menukey]["Product Group"]
                          }
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="YearList"
                      disableClearable
                      options={YearList}
                      onChange={(event, newValue) =>
                        handleYearChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name}
                      defaultValue={
                        YearList[
                          YearList.findIndex(
                            (YearList) => YearList.id == currYearId
                          )
                        ]
                      }
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Year"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* combobox end */}

          <Box sx={{ width: "100%" }}>
            {/* <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Country Profile Wizard"]}
              </div>
            </div> */}
            {/* <Stepper nonLinear activeStep={activeStep}> */}
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                // if (isStepOptional(index)) {
                //   labelProps.optional = (
                //     <Typography variant="caption">{t("Optional")}</Typography>
                //   );
                // }
                // if (isStepSkipped(index)) {
                //   stepProps.completed = false;
                // }
                return (
                  <Step key={label} {...stepProps}>
                    {/* step button */}
                    {/* <StepButton color="inherit" onClick={handleStep(index)}>
                    {DispensingLanguage[lan][menukey][label]}
                    </StepButton> */}
                      {/* step button */}
                    <StepLabel {...labelProps}>{DispensingLanguage[lan][menukey][label]}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {/* {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {t("All steps completed - you're finished")}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>{"Reset"}</Button>
                </Box>
              </React.Fragment>
            ) : ( */}
            <React.Fragment>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  color="primary"
                  className="mr-2"
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {DispensingLanguage[lan][menukey]["Previous"]}
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {/* {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      {t("Skip")}
                    </Button>
                  )} */}
                <Button
                  color="primary"
                  className="mr-2"
                  variant="contained"
                  disabled={activeStep === 3}
                  onClick={handleNext}
                >
                  {DispensingLanguage[lan][menukey]["Next"]}
                </Button>
              </Box>
              <Box sx={{ flexDirection: "row", pt: 1 }}>
                {getStepContent(activeStep)}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  color="primary"
                  className="mr-2"
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {DispensingLanguage[lan][menukey]["Previous"]}
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {/* {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      {t("Skip")}
                    </Button>
                  )} */}
                <Button
                  color="primary"
                  className="mr-2"
                  variant="contained"
                  disabled={activeStep === 3}
                  onClick={handleNext}
                >
                  {DispensingLanguage[lan][menukey]["Next"]}
                </Button>
              </Box>
            </React.Fragment>
            {/* )} */}
          </Box>
        </div>
      </div>
    </>
  );
};

export default CountryProfile;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});
