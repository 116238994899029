import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom"; 
import * as api from "../../../actions/api";
import ManufacturerEntryFormData from "./ManufacturerEntryFormData";



const EditManufacturerForm = (props) => {

    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "manufacturer-entry-form";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const [formData, setFormData] = useState({
    ManufacturerId: "",
    ManufacturerName: "",
    ISO3: "",
    Email: "",
    ContactName: "",
    ContactNo: "",
    Fax: "",
    ManufacturerAddress: "",
    Url: ""
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["getManufacturerForm", id],
    () =>
      api.getManufacturerForm(id).then((res) => {
        console.log(res.data, '===========')
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.updateManufacturerForm, {
    onSuccess: (data) => { 
      if (data.status == 200) {

        // api.getAllDropdown("ABC_List").then((response) => {
        //   localStorage.setItem(
        //     "ABC_List",
        //     JSON.stringify(response.datalist.ABC_List)
        //   );
        // });

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

        // queryClient.getQueriesData("supplier-form"); // include the page name
        props.history.push("/manufacturer-entry-form"); //page url
      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  // const handleCountryChange = (e) => {
  //   const { name, value } = e.target;

  //   let data = { ...formData };
    
  //   data[name] = value;

  //   setFormData(data);

  //   setErrorObject({ ...errorObject, [name]: null });
  // }

  const handleCountryChange = (valueObj) => {

    const { name, value } = valueObj;

    let data = { ...formData };

    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  }


  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ManufacturerId: "",
      ManufacturerName: "",
      ISO3: "",
      Email: "",
      ContactName: "",
      ContactNo: "",
      Fax: "",
      ManufacturerAddress: "",
      Url: ""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ManufacturerId", "ManufacturerName" , "ContactNo", "ISO3"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    console.log(formData);
    console.log(validateForm(formData));
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat); 
    }
  };

  // const handleUpdate = async (e) => {
  //   // console.log("from submit , checking of validation", formData);
  //       console.log(formData);
  //   console.log(validateForm(formData));
  //   if (validateForm(formData)) {
  //     let fDtat = {
  //       ...formData,
  //       menukey: menukey,
  //       lan: lan,
  //       UserName: UserName,
  //     };
  //     mutate(fDtat);
  //   }
  // };

  return (
    <>
      <ManufacturerEntryFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleCountryChange={handleCountryChange}
        // handleChosenCountryChange={handleChosenCountryChange}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        {...props}
      />
    </>
  );
};

export default EditManufacturerForm;
