import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
// import swal from "sweetalert";
import * as api from "../../../actions/api";
import MOSRangeFormData from "./MOSRangeFormData.js";



// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }
const EditMOSRange = (props) => {
  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "mos-range";
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const [ColorCode, setColorCode] = useState("#00aabb");
  
  const handleChangeColorAdd = (value) => {
    //console.log(value);
    setColorCode(value);
  };

  const [formData, setFormData] = useState({
    MosTypeName: "",
    MosTypeNameFrench:"",
    MinMos: "",
    MaxMos: "",
    ColorCode: "",
    MosLabel: ""
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["mosrange", id],
    () =>
      api.getMOSRange(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  const {mutate } = useMutation(api.updateMOSRange, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
		
        queryClient.getQueriesData("mosrange");
        props.history.push("/mos-range");
            
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
		
        }
    
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      MosTypeName: "",
      MosTypeNameFrench:"",
      MinMos: "",
      MaxMos: "",
      ColorCode:"",
      MosLabel: ""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["MosTypeName","MosTypeNameFrench","MinMos","MaxMos","ColorCode","MosLabel"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        if (formData[field] != 0) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
        }
      }

    });
    setErrorObject(errorData);
    return isValid;
  };
  
  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //     mutate(formData);
      
  //     swal("Success!", "", "success");
  //   }
  // };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //mutate(formData);
     
    }
  };

  return (
    <>
      <MOSRangeFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        handleChangeColorAdd={handleChangeColorAdd}
        {...props}
      />
    </>
  );
};

export default EditMOSRange;
