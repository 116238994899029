import React, { useState } from "react";
import { Typography } from "@material-ui/core";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
// import swal from "sweetalert";
// import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import ProductFormData from "./ProductFormData.js";
// import Constants from "../../../services/Constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value == index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AddProduct = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "products";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  //const [selectedDefaultPtype, setDefaultPtype] = useState('1');

  const [formData, setFormData] = useState({
    productCode: "",
    productName: "",
    ItemShortName: "",
    ShortName: "",
    // icn: "",
    genericName: "",
    // tradeName: "",
    // ecn: "",
    strengthSize: "",
    price: "",
    // ven: "",
    // nsn: "",
    form: "",
    routeOfAdmin: "",
    // abc: "",
    // fin: "",
    packSize: "",
    unit: "",
    // atc: "",
    // rxLevel: "",
    // ProductTypeId: "",
    // administrationUnit: "",
    // bPediatric: false,
    // bInjectable: false,
    bKeyItem: false,
    NonPerishable: false,
    bCovid:false,
    // binLocation: "",
    // markUp: "",
    // SellingPrice: "",
    // shippingPack: "",
    // productDescription: "",
    // bIncludeTrade: false,
    // bExcludeGeneric: false,
    // availableforDispensing: false,
    // breakAdministrationUnit: false,
    productType: "None",
    ItemGroupId: "",
    VvmId: ""
  });
  const [errorObject, setErrorObject] = useState({});
  // const [pricebesideLevel, setPricebesideLevel] = useState(null);
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["product", id],
    () =>
      api.getProduct(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.saveProduct, {
    onSuccess: (data) => {
      if (data.status == 200) {



        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("products");
        props.history.push("/products");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const generateProductCodeAndName = (data) => {
    // Product# = Form ID + Generic ID + Strength ID + PackSize ID. Like as AA + 9999 + 999 + 999 = AA9999999999
    // Product Name = Generic Name + Trade Name + Strength/Size + Form, Pack Size + Unit [Route of Admin]
    const {
      form,
      genericName,
      strengthSize,
      packSize,
      tradeName,
      routeOfAdmin,
      unit,
    } = data;

    let icn = "";

    let productName = "";
    let ItemShortName = "";

    if (form && genericName && strengthSize && packSize) {
      icn =
        form +
        genericName.toString().padStart(4, "0") +
        strengthSize.toString().padStart(3, "0") +
        packSize.toString().padStart(3, "0");

      //productCode = form + genericName + strengthSize + packSize;
    }

    const genericNames = JSON.parse(localStorage.getItem("GenericList"));
    const strengthSizes = JSON.parse(localStorage.getItem("Strengthlist"));
    const forms = JSON.parse(localStorage.getItem("DosageFormlist"));
    const packSizes = JSON.parse(localStorage.getItem("PackSizelist"));
    const units = JSON.parse(localStorage.getItem("Unitofmeasurelist"));
    const routeOfAdmins = JSON.parse(localStorage.getItem("RouteOfAdminlist"));

    let genericNameStr = "";
    if (genericName != "") {
      genericNameStr = genericNames.find((g) => g.id == genericName)?.name;
    }

    let strengthSizeStr = "";
    if (strengthSize != "") {
      strengthSizeStr = strengthSizes.find((st) => st.id == strengthSize)?.name;
    }

    let formStr = "";
    if (form != "") {
      formStr = forms.find((f) => f.id == form)?.name;
    }

    let packSizeStr = "";
    if (packSize != "") {
      packSizeStr = packSizes.find((pack) => pack.id == packSize)?.name;
    }

    let unitStr = "";
    if (unit != "") {
      unitStr = units.find((u) => u.id == unit)?.name;
    }

    let routeOfAdminStr = "";
    if (routeOfAdmin != "") {
      routeOfAdminStr = routeOfAdmins.find(
        (admin) => admin.id == routeOfAdmin
      )?.name;
    }

    let trade_Name = '';
    if (tradeName) {
      trade_Name = tradeName + " ";
    }

    productName =
      (genericNameStr || "") +
      " " + trade_Name +
      (strengthSizeStr || "") +
      " " +
      (formStr || "") +
      ", " +
      (packSizeStr || "") +
      " " +
      (unitStr || "") +
      (routeOfAdminStr ? " [" + routeOfAdminStr + "]" : "");

    ItemShortName =
      (genericNameStr || "") +
      " " +
      (strengthSizeStr || "") +
      " " +
      (formStr || "") +
      ", " +
      (routeOfAdminStr ? "[" + routeOfAdminStr + "]" : "");

    return {
      icn: icn,
      productName: productName,
      ItemShortName: ItemShortName,
    };
  };

  const checkNumberValidation = (name, value) => {
    if (name == "price" || name == "SellingPrice") {
      let newValue = "";
      if (
        value.split(".") &&
        value.split(".")[1] &&
        value.split(".")[1].length > 2
      ) {
        newValue = parseFloat(value).toFixed(2);
      } else {
        newValue = value;
      }
      setFormData({ ...formData, [name]: newValue });
    }

    if (name == "markUp") {
      value = value && value != "" ? parseInt(value) : value;
      if (value == "" || value == 0 || (value >= 0 && value <= 100)) {
        setFormData({ ...formData, [name]: value });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log("target ======== ",  name);

    if (name == "price" || name == "SellingPrice" || name == "markUp") {
      checkNumberValidation(name, value);
    } else if (name == 'VvmType') {
      let data = { ...formData };
      data['VvmId'] = value;
      setFormData(data);
    } else {
      let data = { ...formData };
      data[name] = value;

      /*let productData = generateProductCodeAndName(data);
      if (productData.icn) {
        data["icn"] = productData.icn;
      }
      if (productData.productName) {
        data["productName"] = productData.productName;
      }
      if (productData.ItemShortName) {
        data["ItemShortName"] = productData.ItemShortName;
      }*/

      setFormData(data);
    }
    setErrorObject({ ...errorObject, [name]: null });
  };


  //==== Start Choosen Dropdown========
  const handleChangeChoosenProps = (name, value) => {
    /* let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" }); */

    // console.log(name, value ,'========')

    if (name == "price" || name == "SellingPrice" || name == "markUp") {
      checkNumberValidation(name, value);
    } else if (name == 'VvmType') {
      let data = { ...formData };
      data['VvmId'] = value;
      setFormData(data);
    } else {
      let data = { ...formData };
      data[name] = value;

      /*let productData = generateProductCodeAndName(data);
      if (productData.icn) {
        data["icn"] = productData.icn;
      }
      if (productData.productName) {
        data["productName"] = productData.productName;
      }
      if (productData.ItemShortName) {
        data["ItemShortName"] = productData.ItemShortName;
      }*/

      setFormData(data);
    }
    setErrorObject({ ...errorObject, [name]: null });
  }
  //==== End Choosen Dropdown========


  const handleChangeProductGroupProps = (e) => {
    if (e > 0) {
      setErrorObject({ ...errorObject, ['ItemGroupId']: null });
    }
  };

  const handleATCChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    let data = { ...formData };
    data['atc'] = rowId;
    setFormData(data);
  };



  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      productCode: "",
      productName: "",
      ItemShortName: "",
      ShortName: "",
      // icn: "",
      genericName: "",
      // tradeName: "",
      // ecn: "",
      strengthSize: "",
      price: "",
      // ven: "",
      // nsn: "",
      form: "",
      routeOfAdmin: "",
      // abc: "",
      // fin: "",
      packSize: "",
      unit: "",
      // atc: "",
      // rxLevel: "",
      // ProductTypeId: "",
      // administrationUnit: "",
      // bPediatric: false,
      // bInjectable: false,
      bKeyItem: false,
      NonPerishable: false,
      bCovid:false,
      // binLocation: "",
      // markUp: "",
      // SellingPrice: "",
      // shippingPack: "",
      // productDescription: "",
      // bIncludeTrade: false,
      // bExcludeGeneric: false,
      // availableforDispensing: false,
      // breakAdministrationUnit: false,
      productType: "None",
      ItemGroupId: "",
      VvmId: ""
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "productCode",
      "productName",
      "ShortName",
      // "icn",
      "genericName",
      "packSize",
      "unit",
      // "administrationUnit",
      "ItemGroupId"
      // "ProductTypeId"
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  const ValidateProductForDispensing = async (e) => {
    return;
  };



  return (
    <>
      <ProductFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        handleChangeChoosenProps={handleChangeChoosenProps}
        ValidateProductForDispensing={ValidateProductForDispensing}
        handleATCChange={handleATCChange}
        handleChangeProductGroupProps={handleChangeProductGroupProps}
        {...props}
      />
    </>
  );
};

export default AddProduct;
