import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import FacilityFormData from "./FacilityFormData.js";
// import * as Service from "../../../services/Service.js";

const EditFacility = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facility";

  const [formData, setFormData] = useState({
    FacilityCode: "",
    FacilityName: "",
    RegionId:"",
    // ZoneId:"",
    DistrictId:"",
    FTypeId:"",
    FLevelId:"",
    FacilityAddress:"",
    FacilityPhone:"",
    FacilityEmail:"",
    FacilityInCharge:"",
    ServiceAreaId:"",
    OwnerTypeId:"",
    FacilityIdDHIS2:"",
    SOBAPSCode:"",
    location:"",
    bDispense: false,
    initmothYear:""

  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isCheckboxDisable, setIsCheckboxDisable] = useState(false);

  
  const { data } = useQuery(
    ["EditF", id],
    () =>
      api.getFacilitySingleView(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.updateSingleFacility, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        
        api.getAllDropdown('FacilityList').then((response) => {
         
          localStorage.setItem(
            "FacilityList",
            JSON.stringify(response.datalist.FacilityList)
          );
        }) 
        
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
  
        queryClient.getQueriesData("facility");
        props.history.push("/facility");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };


  const handleCheckOnInitialDateCancel = (monthYearData) => {
 
    setIsCheckboxDisable(false);

    setFormData(Object.assign({ ...formData,
     ['bDispense']: false,
     ['YearId']:null,
     ['MonthId']:null, 
     ['InitialDate']:null,
     ['InitComplete']:null 
    }));

}

  const handleCheckOnInitialDate = (monthYearData) => {
 
       setIsCheckboxDisable(true);

       setFormData(Object.assign({ ...formData,
        ['bDispense']: true,
        ['YearId']:monthYearData['YearID'],
        ['MonthId']:monthYearData['MonthId'], 
        ['InitialDate']:monthYearData['InitialDate'],
        ['InitComplete']:monthYearData['InitComplete'] 
       }));

  }
  const handleCheck = (e) => {
 
    const { name, checked } = e.target;
    
    let bDispenseVal = checked;

    if(bDispenseVal == true){
      
      setFormData({ ...formData, [name]: checked });



    }else{
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text:/*
          bDispenseVal == true
            ? t(
                DispensingLanguage[lan][menukey][
                  "You want to update this facility!"
                ]
              )
            : 
            
            */t(
                DispensingLanguage[lan][menukey][
                  "You want to delete this facilities relevant transaction records!"
                ]
              ),
        icon: "warning",
        buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setIsCheckboxDisable(true);
          setFormData({ ...formData, [name]: checked });
         
        } else {
          setIsCheckboxDisable(false);
           
          //refetch();
        }
      });

    }
  };

  const handleReset = () => {
    setFormData({
      FacilityCode: "",
      FacilityName: "",
      bDispense: false
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["FacilityCode","FacilityName","RegionId","DistrictId","FTypeId","FLevelId","OwnerTypeId","ServiceAreaId"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      console.log(formData);
      mutate(formData);
      
    }
  };

  return (
    <>
      <FacilityFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleCheckOnInitialDate={handleCheckOnInitialDate}
        handleCheckOnInitialDateCancel={handleCheckOnInitialDateCancel}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        isCheckboxDisable={isCheckboxDisable}
        {...props}
      />
    </>
  );
};

export default EditFacility;
