import React from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
// import * as Service from "../../../services/Service.js";
// import Constants from "../../../services/Constants";
// import swal from "sweetalert";
// materialui table


//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  TextField,
  Grid,
  FormControl,
  Typography,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useQuery, useMutation } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { ViewList } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

import { fr, enGB } from 'date-fns/locale'; // import French and English locale
import 'moment/locale/fr'; // import Moment.js French locale


// const fileType =
//   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
// const fileExtension = ".xlsx";

const hasBothValues = (valueOne, valueTwo) => {
  const hasValueOne = valueOne === 0 ? true : Boolean(valueOne)
  const hasValueTwo = valueTwo === 0 ? true : Boolean(valueTwo)

  if (hasValueOne && hasValueTwo) {
    return true
  }
  return false

}

const hasLeastOneValue = (valueOne, valueTwo) => {
  const hasValueOne = valueOne === 0 ? true : Boolean(valueOne)
  const hasvalueTwo = valueTwo === 0 ? true : Boolean(valueTwo)

  if (hasValueOne || hasvalueTwo) {
    return true
  }
  return false

}

let isDirty=false;
const Products = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "stock-status";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const userId = UserInfo == 0 ? '' : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleItemGroupId = UserInfo == 0 ? '' : UserInfo[0].ItemGroupId;

  const MosTypelist = JSON.parse(localStorage.getItem("MosTypelist"));

  //console.log("MosTypelist   Values", MosTypelist);

  const classes = useStyles();
  let tblRef = null;
  // const tableRef = useRef();
  const { useState } = React;
  // const [selectedRow, setSelectedRow] = useState(null);
  // const [productsData, selectProductsData] = useState([]);
  // const [productsAllData, selectProductsAllData] = useState([]);
  // const [productGroupData, selectProductGroupData] = useState([]);
  // const [productGroupFilter, selectProductGroupFilter] = useState("0");
  const [firstLoad, setFirstLoad] = useState(true);
  // const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  // const [totalRow, setTotalRow] = useState(0);
  // const [pageNumber, setPageNumber] = useState(0);
  // const [query, setQuery] = useState("");

  // const [TableDataUpdate, setTableDataUpdate] = useState([]);
  // const [ManyJsonSave, setInputChange] = useState({});
  const [radioValue, setRadioValue] = useState(2);
  const [dataItems, setDataItems] = useState([]);
  const FLevelId = localStorage.getItem("FLevelId");

  // const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
  //const [currProductGroupId, setCurrProductGroupId] = useState(ProductGroupList[0].id);
  // const [currProductGroupId, setCurrProductGroupId] = useState(RoleItemGroupId);


  //   const handleProductGroupChange = (event, newValue) => {
  //     let rowId = '';
  //     if(newValue == null){
  //       rowId = '';
  //     }else{
  //       rowId = newValue.id;
  //     }

  //     setFirstLoad(true);
  //     setLoading(true);

  //     setCurrProductGroupId(rowId);
  //  };

  // Set Moment.js French/English by user locale
  //const UserLocal = (lan == 'en_GB' ? 'enGB' : 'fr');
  moment.locale(lan == 'en_GB' ? 'enGB' : 'fr');

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const [MosTypeLegend, setMosTypeLegend] = useState([]);

  let param1 = {
    menukey: menukey,
    action: "GetMosScale",
    lan: lan,
    FLevelId: FLevelId,
    FLevelName: "",
  };

  const { data: data1 } = useQuery([param1], api.getNationalStockkReport, {
    onSuccess: (data) => {
      setMosTypeLegend(data.data.datalist);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getDataList",
    StartDate: selectedStartDate,
    // ItemGroupId: currProductGroupId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    RadioValue: radioValue,
  };

  const [isLoading, setLoading] = useState(false);

  const [SaveAMCBtnDisabled, setSaveAMCBtnDisabled] = useState(false);

  const { refetch: stockData } = useQuery(
    [params],
    api.getstockstatus,
    {
      onSuccess: (data) => {
        if (firstLoad) {
          localStorage.setItem(
            "StockData",
            JSON.stringify(data.datalist)
          );
          //console.log("GGGGGGG",data.datalist);

          if((data.LASTLMISPeriod==null)){
            setSaveAMCBtnDisabled(false);
          }else{
            setSaveAMCBtnDisabled(true);
          }

          setDataItems(data.datalist);
          setLoading(false);
          setFirstLoad(false);

        }else{
          localStorage.setItem(
            "StockData",
            JSON.stringify(data.datalist)
          );
          setDataItems(data.datalist);

        }

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }, {
    refetchOnWindowFocus: false,
    enabled: true
  }
  );


  // const setUpdatedDetailsGridDataHandler = (rows) => {
  //   const updateTableData = data.map((row) => {
  //     return row;
  //   });
  //   //setTableDataUpdate(updateTableData);
  //   //setDataItems(data);
  // };

  const { mutate: SaveAMC } = useMutation(api.UpdateAMC, {
    onSuccess: (data) => {
      
      isDirty=false;
      onSetDisable();
      
      
      if (data.status == 200) {
        stockData();

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        //swal("Success!", "", "success");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });


  const handleSave = async (e) => {

    let manydataR=[]; 
    tblRef.table.getData().forEach((row,i)=>{
      if(row.dirty==1)
      manydataR.push(row);
    
    })

    let FinalData = {
      action: "AMCUpdate",
      ManyJsonSave: manydataR,
      menukey: menukey,
      FacilityId: FacilityId,
      lan: lan,
      UserName: UserName
    };
    SaveAMC(FinalData);
  };

  /* ====Start of Excel Export Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=StockMasterExcelExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&StartDate=" +
      formatedDate +
      "&FacilityId=" +
      FacilityId +
      "&RadioValue=" +
      radioValue +
      "&FacilityName=" +
      FacilityName +
      "&RoleItemGroupId=" +
      RoleItemGroupId +
      "&FLevelId=" +
      FLevelId + 
      "&TimeStamp=" + Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    console.log("row data",row._row);
    row._row.parent.rows.forEach((rowss, i) => {


      if (row._row.data.ItemNo == rowss.data.ItemNo) {
        localStorage.setItem(
          "StockDataIndex",
          JSON.stringify(i)
        );
        editUser(rowData);
        return;
      }

    });


  };

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 75,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ProductCode",
      headerFilter: true,
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ProductName",
      headerFilter: true,
    },
    {
      title:  FLevelId==99?DispensingLanguage[lan][menukey]["AMC"]:DispensingLanguage[lan][menukey]["DMM"],
      field: "AMC",
      width: 80,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: SaveAMCBtnDisabled?"":"tabluator-column-editable",
      // editor: cellFocusEditor,
      editor: SaveAMCBtnDisabled?"": function (cell, onRendered, success, cancel, editorParams) {
        const currow = cell.getRow();
        const rowData = currow.getData();

        // if (!rowData["MaximumQuantity"] && !rowData["MinimumQuantity"]) {
        //   return;
        // }

        // If the condition is not met, return your editor (cellFocusEditor, for example)
        return cellFocusEditor(cell, onRendered, success, cancel, editorParams);
      },
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();

        if (!curcellval) return;

        const pricecell = currow.getCell("MinimumQuantity");
        const MinimumQuantity = rowdata.MinimumQuantity_ * curcellval;
        pricecell.setValue(MinimumQuantity);

        const MaximumQuantity = currow.getCell("MaximumQuantity");
        MaximumQuantity.setValue(rowdata.MaximumQuantity_ * curcellval);

        let MOS = 0;
        const Quantity = rowdata.Quantity;
        // console.log('Quantity: ', Quantity);
        // console.log('curcellval: ', curcellval );
        if (Quantity > 0 && curcellval == 0) {
          MOS = -1;
        }
        else if (curcellval > 0 && Quantity > 0) {
          MOS = (parseInt(Quantity) / parseInt(curcellval)).toFixed(1);
        }
        // console.log('MOS: ', MOS);

        const MOSCol = currow.getCell("MOS");
        MOSCol.setValue(MOS);

        // Start Dirty
        let CellOldValue = cell._cell.oldValue;
        if( (CellOldValue==null) || (CellOldValue=="") || (CellOldValue==undefined) ){
          CellOldValue = "";
        }  
        if(CellOldValue!=curcellval){
          currow.getCell("dirty").setValue(1); 
          isDirty=true;
          onSetDisable();
        }
        // End Dirty


      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Minimum Quantity"],
      field: "MinimumQuantity",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Maximum Quantity"],
      field: "MaximumQuantity",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "Quantity",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 80,
      formatter: function (cell, formatterParams) {
        // MosTypelist
        let value = cell.getValue();
        let cellColor = "transparent";
        const rowData = cell.getRow().getData();

        if ((value == "" || value == null)) {
          value = 0.0;
        }

        const hasMaxOrMinQty = hasLeastOneValue(rowData["MaximumQuantity"], rowData["MinimumQuantity"] )
        const hasAmcAndQty = hasBothValues(rowData["AMC"], rowData["Quantity"])
        const NonDispensing = DispensingLanguage[lan][menukey]["ND"]

        MosTypelist.forEach((row, i) => {
          if ((value > row["MinMos"]) && (value <= row["MaxMos"]) && hasMaxOrMinQty) {
            if (!hasAmcAndQty) {
              cellColor = '#a2a09d';
              value = NonDispensing[0]
            } else {
              cellColor = row["ColorCode"];
            }
          }
        });


        cell.getElement().style.backgroundColor = cellColor;

        if (hasMaxOrMinQty) {
          return value
        }

      },

    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Unit Price"],
    //   field: "UnitPrice",
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   //width: 110,
    //   visible: false,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Line Total"],
    //   field: "LineTotal",
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   formatter: "money",
    //   //width: 130,
    //   visible: false,
    // },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: 80,
      formatter: reactFormatter(<ActionButton />),
    },
  ];


  const onSetDisable = () => {  
    if(isDirty==true){   
      //document.getElementById("is_dirty_message").classList.remove("dnone"); 
      document.getElementById("master_dirty_message").classList.remove("dnone");   
    }
    else{  
     //document.getElementById("is_dirty_message").classList.add("dnone"); 
     document.getElementById("master_dirty_message").classList.add("dnone");
    }    
  }

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // console.log(props.cell._cell.row);
    //const cellValue = props.cell._cell.value || 'Edit | Show';

    return (
      <>
        
          <ViewList
            onClick={() => {
              dataItems.forEach((row, i) => {
                if (rowData.ItemNo == row.ItemNo) {
                  localStorage.setItem(
                    "StockDataIndex",
                    JSON.stringify(i)
                  );
                  editUser(rowData);
                  return;
                }

              });

            }}
          /> 

      </>
    );
  }

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };


  // const handleChangePage = (data) => {
  //   setPageNumber(data);
  //   setFirstLoad(false);
  // };
  // const handleChangeRowPerPage = (data) => {
  //   setNumberRowPerPage(data);
  // };

  // const checkLogin = () => {
  //   let token = Constants.token;

  //   if (!token) {
  //     swal("Oops!", `token expired. Please login again`, "error");
  //     props.history.push("/login");
  //   }
  // };
  const editUser = (data, dataItemss) => {

    if(data.ItemNo !=null){
      let route = `stock-status/edit/${data.ItemNo}`;
      props.history.push(route);  
    }
  };

  const changeRadio = (value) => {
    setFirstLoad(true);
   // setLoading(true);
    setRadioValue(value);
  };

  React.useEffect(() => {
    if (tblRef == null) return;
    setLoading(true);
    stockData();
  }, []);


  // React.useEffect(() => {
  //   let token = Constants.token;
  //   if (token) {
  //   }
  // }, []);

  // React.useEffect(() => {}, [
  //   pageNumber,
  //   numberRowPerPage,
  //   query,
  //   productGroupFilter,
  // ]);


  return (
    <>
      <div className="sw_topmargin">
        <div className="sw_makeStyles_tableContainer">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {DispensingLanguage[lan][menukey]["Stock Status"]}
                </div>
              </div>
            </Grid>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lan == 'en_GB' ? enGB : fr}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={DispensingLanguage[lan][menukey]["Stock Date"]}
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>


                {/* <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ProductGroupList"
                      disableClearable
                      disabled={userId==1?false:true}
                      options={ProductGroupList}
                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroupList[ProductGroupList.findIndex(productGroupList => productGroupList.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Product Group"]}
                        variant="standard"
                        hiddenLabel
                      />
                      )}
                    />

                  </FormControl>
                </Grid> */}

                <Grid item xs={5} sm={5}>
                  <FormControl component="fieldset" className="mt-3 ml-3">
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="row-radio-buttons-group"
                      defaultValue="SHOW_NONE_ZERO"
                    >
                      <FormControlLabel
                        value="SHOW_ALL"
                        control={<Radio />}
                        label={DispensingLanguage[lan][menukey]["SHOW ALL"]}
                        onClick={() => changeRadio(0)}
                      />
                      <FormControlLabel
                        value="SHOW_ZERO"
                        control={<Radio />}
                        label={DispensingLanguage[lan][menukey]["SHOW ZERO"]}
                        onClick={() => changeRadio(1)}
                      />
                      <FormControlLabel
                        value="SHOW_NONE_ZERO"
                        control={<Radio />}
                        label={DispensingLanguage[lan][menukey]["SHOW NONE-ZERO"]}
                        onClick={() => changeRadio(2)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>


                <Grid item xs={5} sm={5}>
                  <div className="float-right sw_btn_control">
                    <div className="mr-4 mt-2 float-left">
                      <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
                    </div>
                    <Button
                      className="mr-2"
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={() => handleSave()}
                      disabled={SaveAMCBtnDisabled}
                    >
                      {DispensingLanguage[lan][menukey]["Save AMC"]}
                      {/* t('Save') */}
                    </Button>

                 <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i className="fas fa-print"></i>
                    </Button> 

                  <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          {/* start of legend */}
          <Card className="sw_card sw_filter_card">
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <div dangerouslySetInnerHTML={{ __html: MosTypeLegend }} />

                  {/* <div className="mylegend_area">
                    {MosTypelist.map((item, index) => {
                      return (
                        <div key={index} className="my_legend">
                          <div
                            className="mylegend_color"
                            style={{ background: item.ColorCode }}
                          ></div>
                          <div className="mylegend_label">{item.name}</div>
                          <div className="mylegend_val">
                            {item.name=='Non Dispensing'?'-1':item.MosLabel}
                          </div>
                        </div>
                      );
                    })}
                  </div> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of legend */}

          <div className="sw_relative">
            {isLoading && <LoadingSpinnerOpaque />}

            <div className="uniqueName">
              <ReactTabulator
                ref={(r) => (tblRef = r)}
                columns={columns}
                data={dataItems}
                layout={"fitColumns"}
                height="400px"
                options={{
                  groupBy: "GroupName",
                  columnCalcs: "both",
                }}
                rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
