import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

// import { useTranslation } from "react-i18next";


// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

const AdjustmentTypeFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "adjustment-type";

  const classes = useStyles();
  // const theme = useTheme();
  // const [value, setValue] = useState(0);
  // const { t, i18n } = useTranslation();

  // function tabChangeHandler(event, newValue) {
  //   setValue(newValue);
  // }

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  // const getRxLevel = (type = null) => {
  //   let level = "";

  //   if (formData.rxLevel) {
  //     level = formData.rxLevel;
  //   }
  //   return level;
  // };

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Adjustment Type - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Adjustment Type Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={9}>
                        <TextField
                          error={errorObject.AdjType}
                          helperText={errorObject.AdjType}
                          required
                          id="AdjType"
                          name="AdjType"
                          label={DispensingLanguage[lan][menukey]['Adjustment Type']}
                          value={formData.AdjType}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>

                        <FormControl component="fieldset">
                          <FormLabel id="demo-row-radio-buttons-group-label" component="legend">

                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="IsPositive"
                            value="1"
                            className="sw_radiogroup"
                          >
                            <FormControlLabel
                              value="1"
                              checked={formData.IsPositive == 1}
                              control={<Radio />}
                              label={DispensingLanguage[lan][menukey]["Plus"]}
                              onChange={(e) => handleChange(e)}
                              disabled={!addProductForm}
                            />
                            <FormControlLabel
                              value="-1"
                              checked={formData.IsPositive == -1}
                              control={<Radio />}
                              label={DispensingLanguage[lan][menukey]["Minus"]}
                              onChange={(e) => handleChange(e)}
                              disabled={!addProductForm}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                    </Grid>
                  </Grid>

                </Grid>


              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/adjustment-type")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/adjustment-type")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div>
  );
};

export default AdjustmentTypeFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
