import React, { useState, useEffect } from "react";
// import swal from "sweetalert";
// import ProductSelectInPrescriptionModal from "../../services/ScanLogModal";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import { addDays, subDays } from "date-fns";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useQuery, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
import { ViewList } from "@material-ui/icons";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  Grid,
  Button,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { DeleteOutline, Edit } from "@material-ui/icons";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

// import DefaultFooter from "components/Footers/DefaultFooter";
import { getDefaultMonthYear } from "../../../services/Common";
import { useTranslation } from "react-i18next";

import OrderDetailsModal from "./OrderDetailsModal";
import ReceiveDetailsModal from "./ReceiveDetailsModal";

// import {
//   checkLogin,
//   checkUserPermission,
// } from "../../services/CheckUserAccess";

//National Stock Summary (Month of Stock = MOS)

const FacilityDashboard = (props) => {
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t } = useTranslation();
  const menukey = "facility-dashboard";

  exporting(Highcharts);
  const classes = useStyles();
  let TempZoneList = useState([]);
  const queryClient = useQueryClient();
  //==============New Combo Start==============
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;

  //region combo list
  const [RegionList, setRegionListData] = useState([]);
  const [activeRegionData, setAcRegionData] = useState({
    id: RegionList.length > 0 ? RegionList[0].id : 0,
    name: RegionList.length > 0 ? RegionList[0].name : "",
  });

  React.useEffect(() => {
    if (activeRegionData.id == 0) {
      getRegionData();
      getFacilityData(0, 0);
    }
  }, [activeRegionData.id]);

  const getRegionData = () => {
    let FacilitylevelParam = {
      action: "getDepartmentforreport",
      RoleId: RoleId,
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cRegionList(FacilitylevelParam);
  };

  const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setRegionListData(data.data.datalist);

        setAcRegionData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getCommuneData(data.data.datalist[0].id);
      }
    },
  });

  const handleDepartmentChange = (event, newValue) => {
    setAcRegionData(newValue);
    getCommuneData(newValue.id);
    getFacilityData(newValue.id, 0);
  };

  ////distict combo list
  const [Communelist, setCommunelistData] = useState([]);
  const [activeCommuneData, setAcCommuneData] = useState({
    id: Communelist.length > 0 ? Communelist[0].id : 0,
    name: Communelist.length > 0 ? Communelist[0].name : "",
  });

  const getCommuneData = (RegionId) => {
    let FacilitylevelParam = {
      action: "getcommuneforreport",
      RoleId: RoleId,
      UserId: UserId,
      RegionId: RegionId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setCommunelistData(data.data.datalist);

        setAcCommuneData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        //getFacilityData(activeRegionData.id, data.data.datalist[0].id);
      }
    },
  });

  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setAcCommuneData(newValue);

    getFacilityData(activeRegionData.id, newValue.id);
  };

  //facility combo load

  const [FacilityLevel, setFacilityLevel] = useState(1);

  const [Facility_list, setFacilityData] = useState([]);
  const [activeFacilityData, setAcFacilityData] = useState({
    id: Facility_list.length > 0 ? Facility_list[0].id : 0,
    name: Facility_list.length > 0 ? Facility_list[0].name : "",
  });

  const getFacilityData = (regid, communeid) => {
    let FacilityParam = {
      action: "FacilitylistforReport",
      RegionId: regid,
      DistrictId: communeid,
      RoleId: RoleId,
      UserId: UserId,
      IsAll: 0,
      menukey: menukey,
      lan: lan,
    };
    cFacilityList(FacilityParam);
  };

  const { mutate: cFacilityList } = useMutation(api.getFacility, {
    onSuccess: (data) => {
      if (data.status == 200) {
        //console.log("bbbbbbbbbbbbbb",data.data.datalist);
        setFacilityData(data.data.datalist);
        setFacilityLevel(
          data.data.datalist.length ? data.data.datalist[0].FLevelId : 0
        );
        setAcFacilityData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
      }
    },
  });

  const handleFacilityListChange = (event, newValue) => {
    setFacilityLevel(newValue.FLevelId);
    setAcFacilityData(newValue);
  };

  // =========Handle Change End===========

  //Facility Details Page

  // let params1 = {
  //   action: "ShowFacilityDetails",
  //   menukey: menukey,
  //   RegionId: activeRegionData.id,
  //   ZoneId: activeCommuneData.id,
  //   FacilityId: activeFacilityData.id,
  // };
  // const [facilityDetailsData, setfacilityDetailsData] = useState(null);
  // const { data, error, isLoading, refetch } = useQuery(
  //   [params1],
  //   api.getFacilityDetails,
  //   {
  //     onSuccess: (data) => {
  //       setfacilityDetailsData(data);

  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0,
  //   }
  // );

  // Total Products Count

  const [totalItem, setTotalItem] = useState(0);
  const [ItemNo, setItemNo] = useState("0");
  const getTotalitems = useQuery(
    ["ItemTotal"],
    () => api.getTotalitems(ItemNo),
    {
      onSuccess: (data1) => {
        setTotalItem(data1[0].item);
        console.log("Data fetched successfully:", data1);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  //Total Count NUMBER OF PRODUCTS AVAILABLE IN THIS CENTER

  const [totalAvailableItems, settotalAvailableItems] = useState(0);
  const [ItemNos, setItemNos] = useState("0");
  const getTotalNonzeroProducts = useQuery(
    ["TotalItemAvailable", activeFacilityData.id],
    () => api.getTotalNonzeroProducts(activeFacilityData.id),
    {
      onSuccess: (data1) => {
        settotalAvailableItems(data1[0].item);
        //console.log("Data fetched successfully:", data1);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // useEffect(() => {
  //   //console.log("useEffect calling for refresh");

  //   if (ItemNos > 0) {
  //     getTotalNonzeroProducts.refetch();
  //   }
  // }, [ItemNos]);

  // Last Activity

  const [TodayDate, setTodayDate] = useState("");
  const getTodayDate = useQuery(["LastLMISDate"], () => api.getTodayDate(lan), {
    onSuccess: (data) => {
      //console.log("dddddddddddddddd", data);
      setTodayDate(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const [LastLMIS, setLastLMIS] = useState("");
  const getLastLMISDate = useQuery(
    ["LastLMISDate", activeFacilityData.id],
    () => api.getLastLMIS(activeFacilityData.id, lan),
    {
      onSuccess: (data) => {
        // console.log("dddddddddddddddd", data2[0].last_activity);
        setLastLMIS(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const [lastActivity, setLastActivity] = useState("");
  const specificDate = "";
  const getLastActivity = useQuery(
    ["LastActivity", activeFacilityData.id],
    () => api.getLastActivity(specificDate, activeFacilityData.id, lan),
    {
      onSuccess: (data1) => {
        setLastActivity(data1);
        //console.log("mmmmmmmmmm:", data1);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // useEffect(() => {
  //   //console.log("useEffect calling for refresh");

  //   if (specificDate) {
  //     getLastActivity.refetch();
  //   }
  // }, [specificDate]);

  // BalanceOfMajorCommodities
  const [balanceOfMajorCommodities, setbalanceOfMajorCommodities] = useState(
    []
  );

  const getBalanceOfMajorCommodities = useQuery(
    [
      "BalanceOfMajorCommodities",
      activeRegionData.id,
      activeCommuneData.id,
      activeFacilityData.id,
    ],
    () =>
      api.getBalanceOfMajorCommodities(
        activeRegionData.id,
        activeCommuneData.id,
        activeFacilityData.id
      ),
    {
      onSuccess: (data1) => {
        // console.log("Data from API:", data1);
        setbalanceOfMajorCommodities(data1);
        // console.log("Data fetched successfully:", data1);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // Activities Not Completed Month

  const [activitiesNotCompletedMonth, setactivitiesNotCompletedMonth] =
    useState([]);

  const getActivitiesNotCompletedMonth = useQuery(
    ["ActivitiesNotCompletedMonths", activeFacilityData.id, lan],
    () => api.getActivitiesNotCompletedMonth(activeFacilityData.id, lan),
    {
      onSuccess: (data1) => {
        // console.log("Data from API:", data1);
        setactivitiesNotCompletedMonth(data1);
        // console.log("Data fetched successfully:", data1);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // Activities Completed Month

  const [activitiesCompletedMonth, setactivitiesCompletedMonth] = useState([]);

  const getActivitiesCompletedMonth = useQuery(
    ["ActivitiesCompletedMonths", activeFacilityData.id, lan],
    () => api.getActivitiesCompletedMonth(activeFacilityData.id, lan),
    {
      onSuccess: (data1) => {
        // console.log("Data from API:", data1);
        setactivitiesCompletedMonth(data1);
        // console.log("Data fetched successfully:", data1);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // redirect_url Onclick event

  const goToRedirectUrl = (url) => {
    window.open(url);
  };

  // Pending Order
  const [dataItems, setDataItems] = useState([]);
  const getPendingOrder = useQuery(
    ["PendingOrder", activeFacilityData.id],
    () => api.getPendingOrder(activeFacilityData.id),
    {
      onSuccess: (data) => {
        console.log("Data from API:", data);
        setDataItems(data);
        console.log("Data fetched successfully:", data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  // Pending Receive
  const [dataItemsReceive, setDataItemsReceive] = useState([]);
  const getPendingReceive = useQuery(
    ["PendingReceive", activeFacilityData.id],
    () => api.getPendingReceive(activeFacilityData.id),
    {
      onSuccess: (data) => {
        //console.log("Data from API:", data);
        setDataItemsReceive(data);
        //console.log("Data fetched successfully:", data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );
  // ============ISSUE DISPENSE TREND START=============

  // START OF Issue Dispense Line Chart

  const [receive_details, setOption_receive_details] = useState([]);

  //END OF Issue Dispense Line Chart

  const getIssueDespenseChartData = useQuery(
    ["IssueDespenseChart", activeFacilityData.id],
    () => api.getIssueDespenseChartData(activeFacilityData.id),
    {
      onSuccess: (data) => {
        console.log(data, "chart data");

        if (data.success === 1) {
          let month_name = data.datalist.month_name;
          let month_name1 = data.datalist.month_name1;
          let overview_name = data.datalist.overview_name;
          let colorarr = data.datalist.color;
          //overview_val = response.overview_val;
          let datalist = data.datalist.datalist;
          let numberformat = data.datalist.numberformat;
          let i;
          let seriesCounter = 0;
          let seriesOptions = [];

          for (i = 0; i < overview_name.length; i++) {
            //alert(datalist[i]);
            seriesOptions[i] = {
              color: colorarr[i],
              name: overview_name[i],
              data: datalist[i],
            };
            seriesCounter++;
          }
          if (seriesCounter == overview_name.length) {
            //	onSetLineChart(month_name, seriesOptions,numberformat,month_name1,colorarr);
          }

          setOption_receive_details({
            chart: {
              type: "line",
            },
            title: {
              text: null, //data.datalist.duration,
            },
            yAxis: {
              title: {
                text: "",
              },
            },
            xAxis: {
              categories: month_name,
              labels: {
                enabled: true,
                formatter: function (i) {
                  return month_name1[this.value];
                },
              },
            },
            tooltip: {
              shared: true,
            },
            credits: {
              enabled: false,
            },
            series: seriesOptions /* [
              {
                name: data.datalist.name,
                data: data.datalist.seriesdata.data,
                color: "#002f6c",
              },
            ],*/,
            //data.datalist.seriesdata,
            //  series: [
            //   {
            //     name: "Total Patients",
            //     data:  [179562],
            //     color: "#fe9929",
            //    },
            //   {
            //      name: "Adult Patients",
            //      data:  [173129],
            //      color: "#4f81bd",
            //    },
            //    {
            //      name: "Paediatric Patients",
            //      data:  [6433],
            //      color: "#c0504d",
            //    },
            //  ],
          });
        }
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    }
  );

  // ============ISSUE DISPENSE TREND END===========

  const OrderModalColumns = [
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 80,
      formatter: reactFormatter(<ShowOrderDetailsPopup />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Order No"],
      field: "OrderNo",
      width: 163,
    },
    {
      title: DispensingLanguage[lan][menukey]["Order Date"],
      field: "OrderDate",
      width: 105,
      // formatter: function (cell, formatterParams) {
      //   let value = cell.getValue();

      //   return value == null ? "" : moment(value).format("DD/MM/YYYY");
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Order Type"],
      field: "OrderType",
      width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["Order From"],
      field: "OrderFrom",
    },
  ];

  const IssueModalColumns = [
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      headerHozAlign: "center",
      width: "10%",
      formatter: reactFormatter(<ShowReceiveDetailsPopup />),
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue Invoice No"],
      field: "TransactionNo",
      width: FacilityLevel == 99 ? "15%" : "25%",
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue Date"],
      field: "TransactionDate",
      width: FacilityLevel == 99 ? "10%" : "15%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued From"],
      field: "IssueFromFacility",
      width: "25%",
    },
    {
      title: DispensingLanguage[lan][menukey]["Order No"],
      field: "OrderNo",
      width: FacilityLevel == 99 ? "15%" : "25%",
    },
    {
      title: DispensingLanguage[lan][menukey]["Order Date"],
      field: "OrderDate",
      width: FacilityLevel == 99 ? "10%" : "15%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Order Type"],
      field: "OrderType",
      width: "15%",
    },
  ];

  // Facility Stock Dashboard Date Range

  // const [currentDate, setCurrentDate] = useState(new Date());

  // useEffect(() => {

  //   const interval = setInterval(() => {
  //     setCurrentDate(new Date());
  //   }, 10000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";

    window.open(
      finalUrl +
        "?action=PendingOrderExport" +
        "&reportType=" +
        reportType +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&ZoneId=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };
  const PrintPDFExcelExportsssFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";

    window.open(
      finalUrl +
        "?action=PendingReceiveExport" +
        "&reportType=" +
        reportType +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&ZoneId=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const PrintPDFExcelExportBalanceFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";

    window.open(
      finalUrl +
        "?action=BalanceOfMajorCommoditiesExport" +
        "&reportType=" +
        reportType +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&ZoneId=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };






  // +
  // "&productGroupId=" + currProductGroupId +
  // "&productId=" + currProductId
  /* =====End of Excel Export Code==== */
  const [getOrderId, setOrderId] = useState(0);
  const [openOrderdetails, setOrderdetails] = useState(false);
  const [getTransactionId, setTransactionId] = useState(0);
  const [openReceivedetails, setReceivedetails] = useState(false);

  const handleClose = (modalname) => {
    switch (modalname) {
      case "closeorderdetails":
        setOrderdetails(false);
        break;
      case "OrderDetailsOpen":
        setOrderdetails(true);
        break;
      case "closeReceivedetails":
        setReceivedetails(false);
        break;
      case "ReceiveDetailsOpen":
        setReceivedetails(true);
        break;

      default:
        break;
    }
  };

  function ShowOrderDetailsPopup(props: any) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <ViewList
          onClick={() => {
            setOrderId(rowData.OrderId);
            handleClose("OrderDetailsOpen");
          }}
        />
      </>
    );
  }
  function ShowReceiveDetailsPopup(props: any) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <ViewList
          onClick={() => {
            setTransactionId(rowData.TransactionId);
            handleClose("ReceiveDetailsOpen");
          }}
        />
      </>
    );
  }

  // useEffect(() => {
  //   handleClose("OrderDetailsOpen");
  // }, [getOrderId]);

  return (
    <>
      <AfterLoginNavbar {...props} />

      <div className="section signup-top-padding sw_inner_container">
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-1">
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Facility Dashboard"])}
              </div>
            </div>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="RegionList"
                      disableClearable
                      options={RegionList}
                      value={activeRegionData}
                      onChange={(event, newValue) =>
                        handleDepartmentChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Department"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item className={classes.pt_grid} xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Communelist"
                      disableClearable
                      options={Communelist}
                      value={activeCommuneData}
                      onChange={(event, newValue) =>
                        handleCommuneChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["District"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="Facility_list"
                      disableClearable
                      options={Facility_list}
                      value={activeFacilityData}
                      onChange={(event, newValue) =>
                        handleFacilityListChange(event, newValue)
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility"]}
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* end of filter */}

          {/* new row */}
          <div className="row mb-3">
            <div className="col-4">
              <div className="row">
                <div className="col-12">
                  <Card className="sw_card">
                    <CardContent>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="stat-cell bgPrimary text-center pt-4 facility_dashboard_block">
                            <i className="fa fa-file bg-icon"></i>
                            <div className="text-labelval">
                              <span className="text-sm text-center">
                                {TodayDate}
                              </span>
                            </div>

                            <br />
                            <span className="text-bg">
                              {
                                DispensingLanguage[lan][menukey][
                                  "Facility Stock Dashboard"
                                ]
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Card className="sw_card">
                        <CardContent>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="stat-cell bgPrimary text-center pt-4 facility_dashboard_block">
                                <i className="fa fa-file bg-icon"></i>
                                <div className="text-labelval">
                                  <span className="text-xlg text-center">
                                    {totalItem}
                                  </span>
                                </div>
                                <br />
                                <span className="text-bg">
                                  {
                                    DispensingLanguage[lan][menukey][
                                      "TOTAL ITEMS IN PROGRAM"
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Card className="sw_card">
                        <CardContent>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="stat-cell bgPrimary text-center pt-4 facility_dashboard_block">
                                <i className="fa fa-file bg-icon"></i>
                                <div className="text-labelval">
                                  <span className="text-xlg text-center text-labelval">
                                    {totalAvailableItems}
                                  </span>
                                </div>
                                <br />
                                <span className="text-bg">
                                  {
                                    DispensingLanguage[lan][menukey][
                                      "NUMBER OF PRODUCTS AVAILABLE IN THIS CENTER"
                                    ]
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="row">
                <div className="col-12">
                  <Card className="sw_card">
                    <CardContent>
                      <div className="stat-cell bgPrimary text-center pt-4 facility_dashboard_block">
                        <i className="fa fa-file bg-icon"></i>
                        <div className="text-labelval">
                          <span className="text-sm text-center">
                            {LastLMIS}
                          </span>
                        </div>
                        <br />
                        <span className="text-bg">
                          {DispensingLanguage[lan][menukey]["LAST LMIS Date"]}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="row">
                <div className="col-12">
                  <Card className="sw_card">
                    <CardContent>
                      <div className="stat-cell bgPrimary text-center pt-4 facility_dashboard_block">
                        <i className="fa fa-file bg-icon"></i>
                        <div className="text-labelval">
                          <span className="text-sm text-center">
                            {lastActivity ? lastActivity : "-"}
                          </span>
                        </div>
                        <br />
                        <span className="text-bg">
                          {DispensingLanguage[lan][menukey]["LAST ACTIVITY"]}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
          </div>
          {/* end row */}

          <div className="row">
            <div className="col-lg-6 mb-4">
              <Card
                className="sw_card facilitydashboard_sBlock"
                style={{ overflow: "scroll" }}
              >
                <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "BALANCE OF MAJOR COMMODITIES"
                    ]
                  )}
                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 mt-1 sw_icons"
                        onClick={() => PrintPDFExcelExportBalanceFunction("print")}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 mt-1 sw_icons"
                        onClick={() => PrintPDFExcelExportBalanceFunction("excel")}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    {balanceOfMajorCommodities &&
                    balanceOfMajorCommodities.length > 0 ? (
                      balanceOfMajorCommodities.map((activity, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                          <Card className="balance_cardbg">
                            <CardContent>
                              <div className="text-center ">
                                <span className="text-bg">
                                  {activity.TotalBalance}
                                </span>
                                <br />
                                <span className="text-bg">
                                  {activity.ItemName}
                                </span>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    ) : (
                      <p></p>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </div>

            <div className="col-md-6 mb-4">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Card className="sw_card facilitydashboard_sBlock">
                        <CardHeader
                          title={
                            DispensingLanguage[lan][menukey][
                              "ISSUE DISPENSE TREND"
                            ]
                          }
                        />
                        <CardContent>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={receive_details}
                            height={900}
                          />
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* New Row of Activities Data Start */}

          <div className="row">
            <div className="col-lg-6 mb-4">
              <Card className="sw_card facilitydashboard_tBlock">
                <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "ACTIVITES COMPLETED THIS MONTH"
                    ]
                  )}
                />
                <CardContent>
                  <div className="row p-3">
                    {activitiesCompletedMonth &&
                    activitiesCompletedMonth.length > 0 ? (
                      activitiesCompletedMonth.map((activity, index) => (
                        <div className="col-md-4 mb-4" key={index}>
                          <div className="text-center mb-4">
                            <a
                              href="javascript:void(0);"
                              className="text-xlg text-success"
                              style={{ textDecoration: "none" }}
                              onClick={() =>
                                goToRedirectUrl(activity.redirect_url)
                              }
                            >
                              {activity.C}
                            </a>
                            <br></br>
                            <span className="text-sm ">
                              {activity.TransactionType}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p></p>
                    )}
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="col-md-6 mb-4">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Card className="sw_card facilitydashboard_tBlock">
                        <CardHeader
                          title={
                            DispensingLanguage[lan][menukey][
                              "ACTIVITES NOT COMPLETED THIS MONTH"
                            ]
                          }
                        />
                        <CardContent>
                          <div className="row p-3">
                            {activitiesNotCompletedMonth &&
                            activitiesNotCompletedMonth.length > 0 ? (
                              activitiesNotCompletedMonth.map(
                                (activity, index) => (
                                  <div className="col-md-4 mb-4" key={index}>
                                    <div className="text-center mb-4">
                                      <a
                                        href="javascript:void(0);"
                                        className="text-xlg text-danger"
                                        style={{ textDecoration: "none" }}
                                        onClick={() =>
                                          goToRedirectUrl(activity.redirect_url)
                                        }
                                      >
                                        {activity.C}
                                      </a>
                                      <br></br>
                                      <span className="text-sm">
                                        {activity.TransactionType}
                                      </span>
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              <p></p>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* New Row of Activities Data End */}

          {/* new row */}

          {(() => {
            if (FacilityLevel === 2 || FacilityLevel === 3) {
              return (
                <div className="row">
                  <div className="col-md-6">
                    <Card className="sw_card">
                      <CardHeader
                        title={
                          DispensingLanguage[lan][menukey]["PENDING ORDER"]
                        }
                        action={
                          <div className="float-right sw_btn_control">
                            <Button
                              color="primary"
                              variant="contained"
                              className="mr-2 mt-1 sw_icons"
                              onClick={() =>
                                PrintPDFExcelExportFunction("print")
                              }
                            >
                              <i class="fas fa-print"></i>
                            </Button>

                            <Button
                              color="primary"
                              variant="contained"
                              className="mr-2 mt-1 sw_icons"
                              onClick={() =>
                                PrintPDFExcelExportFunction("excel")
                              }
                            >
                              <i className="far fa-file-excel"></i>
                            </Button>
                          </div>
                        }
                      />
                      <CardContent>
                        <ReactTabulator
                          height={153}
                          columns={OrderModalColumns}
                          data={dataItems}
                          layout={"fitColumns"}
                        />
                      </CardContent>
                    </Card>
                  </div>

                  <div className="col-md-6">
                    <Card className="sw_card">
                      <CardHeader
                        title={
                          DispensingLanguage[lan][menukey]["Pending Receive"]
                        }
                        action={
                          <div className="float-right sw_btn_control">
                            <Button
                              color="primary"
                              variant="contained"
                              className="mr-2 mt-1 sw_icons"
                              onClick={() =>
                                PrintPDFExcelExportsssFunction("print")
                              }
                            >
                              <i class="fas fa-print"></i>
                            </Button>

                            <Button
                              color="primary"
                              variant="contained"
                              className="mr-2 mt-1 sw_icons"
                              onClick={() =>
                                PrintPDFExcelExportsssFunction("excel")
                              }
                            >
                              <i className="far fa-file-excel"></i>
                            </Button>
                          </div>
                        }
                      />
                      <CardContent>
                        <ReactTabulator
                          height={153}
                          columns={IssueModalColumns}
                          data={dataItemsReceive}
                          layout={"fitColumns"}
                        />
                      </CardContent>
                    </Card>
                  </div>
                </div>
              );
            } else if (FacilityLevel == 99) {
              return (
                <div className="row">
                  <div className="col-md-12">
                    <Card className="sw_card">
                      <CardHeader
                        title={
                          DispensingLanguage[lan][menukey]["Pending Receive"]
                        }
                        action={
                          <div className="float-right sw_btn_control">
                            <Button
                              color="primary"
                              variant="contained"
                              className="mr-2 mt-1 sw_icons"
                              onClick={() =>
                                PrintPDFExcelExportsssFunction("print")
                              }
                            >
                              <i class="fas fa-print"></i>
                            </Button>

                            <Button
                              color="primary"
                              variant="contained"
                              className="mr-2 mt-1 sw_icons"
                              onClick={() =>
                                PrintPDFExcelExportsssFunction("excel")
                              }
                            >
                              <i className="far fa-file-excel"></i>
                            </Button>
                          </div>
                        }
                      />
                      <CardContent>
                        <ReactTabulator
                          height={153}
                          columns={IssueModalColumns}
                          data={dataItemsReceive}
                          layout={"fitColumns"}
                        />
                      </CardContent>
                    </Card>
                  </div>
                </div>
              );
            } else {
              return <div></div>;
            }
          })()}

          {/* end row */}
        </div>
      </div>

      <OrderDetailsModal
        handleClose={handleClose}
        open={openOrderdetails}
        //FacilityId={activeFacilityData.id>0?activeFacilityData.id:0}
        OrderId={getOrderId}
        {...props}
      />
      <ReceiveDetailsModal
        handleClose={handleClose}
        open={openReceivedetails}
        //FacilityId={activeFacilityData.id>0?activeFacilityData.id:0}
        TransactionId={getTransactionId}
        {...props}
      />
    </>
  );
};

export default FacilityDashboard;

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
