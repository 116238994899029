import React, { useState, useEffect } from "react";
import { makeStyles, } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControl,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ColorPicker } from 'material-ui-color';
// import SwipeableViews from "react-swipeable-views";
// import { useTranslation } from "react-i18next";
//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "mos-type-for-facility-entry";

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }



const MOSTypeForFacilityFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  handleChangeColorAdd,
  ...props
}) => {
  const classes = useStyles();
  // const theme = useTheme();
  // const [value, setValue] = useState(0);
  // const { t, i18n } = useTranslation();


  const FlevelList = JSON.parse(localStorage.getItem("FlevelList"));
  // const FTypeList = JSON.parse(localStorage.getItem("FTypeList"));



  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    "FLevelId": { "id": "", "name": "" }
    // "FTypeId": {"id": "","name": ""}
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData['FLevelId'] = FlevelList[FlevelList.findIndex(FlevelList_list => FlevelList_list.id == formData.FLevelId)];
    // chosenValuesData['FTypeId'] = FTypeList[FTypeList.findIndex(FTypeList_list => FTypeList_list.id == formData.FTypeId)]; 

    setChosenValues(chosenValuesData);

  }, [formData.FLevelId]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========


  //const [ColorCode, setColor] = useState(createColor("#00aabb"));
  const handleChangeColor = (name) => {

    // if (Object.keys(name).length == 0) {
    //   formData["ColorCode"]=name.css.backgroundColor;
    //   handleChangeColorAdd(name.css.backgroundColor);
    // }else{
    //   formData["ColorCode"]=name;
    //   handleChangeColorAdd(name);
    // }

    if (typeof (name) == "string") {
      formData["ColorCode"] = name;
      handleChangeColorAdd(name);
    } else {
      formData["ColorCode"] = name.css.backgroundColor;
      handleChangeColorAdd(name.css.backgroundColor);
    }




    //setColor(name.css.backgroundColor);
  };



  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['MOS Type for Facility - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['MOS Type for Facility Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>

                      <Grid item xs={12} sm={2}>

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            id="FLevelId"
                            options={FlevelList}
                            onChange={(event, valueobj) => handleChangeChoosen('FLevelId', valueobj, valueobj ? valueobj.id : "")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['FLevelId']}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.FLevelId}
                                label={DispensingLanguage[lan][menukey]["Facility Level"] + " *"}
                                variant="standard"
                                error={errorObject.FLevelId}
                                helperText={errorObject.FLevelId}
                                id="FLevelId"
                                name="FLevelId"
                                fullWidth
                              />
                            )}
                          />

                        </FormControl>
                      </Grid>

                      {/* 
                          <Grid item xs={12} sm={2}>

                                <FormControl className={classes.fullWidth}>
                                    <Autocomplete
                                    autoHighlight
                                    id="FTypeId"
                                    options={FTypeList}
                                    onChange={(event, valueobj) => handleChangeChoosen('FTypeId', valueobj, valueobj?valueobj.id:"")}
                                    getOptionLabel={(option) => option.name}
                                    value={chosenValues['FTypeId']}
                                    renderOption={(option) => (
                                    <Typography className="sw_fontSize">{option.name}</Typography>
                                    )}
                                    renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      value={formData.FTypeId}
                                      label={DispensingLanguage[lan][menukey]["Facility Type"]+ " *"}
                                      variant="standard"
                                      error={errorObject.FTypeId}
                                      helperText={errorObject.FTypeId}
                                      id="FTypeId"
                                      name="FTypeId"
                                      fullWidth
                                    />
                                    )}
                                    />

                                </FormControl>
                          </Grid> */}

                      <Grid item xs={3} sm={3}>
                        <TextField
                          error={errorObject.MosTypeName}
                          helperText={errorObject.MosTypeName}
                          required
                          id="MosTypeName"
                          name="MosTypeName"
                          label={DispensingLanguage[lan][menukey]['MOS Type']}
                          inputProps={{ maxLength: 50 }}
                          value={formData.MosTypeName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={2} sm={2}>
                        <TextField
                          error={errorObject.MinMos}
                          helperText={errorObject.MinMos}
                          required
                          id="MinMos"
                          name="MinMos"
                          type="number"
                          label={DispensingLanguage[lan][menukey]['Minimum MOS']}
                          value={formData.MinMos}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={2} sm={2}>
                        <TextField
                          error={errorObject.MaxMos}
                          helperText={errorObject.MaxMos}
                          required
                          id="MaxMos"
                          name="MaxMos"
                          type="number"
                          label={DispensingLanguage[lan][menukey]['Maximum MOS']}
                          value={formData.MaxMos}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={2} className="marginTop10">

                        {/* <FormLabel component="legend">
                          {DispensingLanguage[lan][menukey]["Color Code"]}
                        </FormLabel> */}

                        <ColorPicker
                          error={errorObject.MaxMos}
                          helperText={errorObject.MaxMos}
                          required
                          name='ColorCode'
                          defaultValue='#00aabb'
                          value={formData.ColorCode}
                          onChange={handleChangeColor}

                        />

                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <TextField
                          error={errorObject.MosLabel}
                          helperText={errorObject.MosLabel}
                          required
                          id="MosLabel"
                          name="MosLabel"
                          label={DispensingLanguage[lan][menukey]['MOS Label']}
                          value={formData.MosLabel}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}></Grid>

                    </Grid>
                  </Grid>

                </Grid>


              </CardContent>
            </Card>
          </Grid>


          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/mos-type-for-facility-entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/mos-type-for-facility-entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}

              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div>
  );
};

export default MOSTypeForFacilityFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
