import React, { useEffect, useState } from "react";//, 
import { useParams } from "react-router-dom";
import { makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography,
  FormLabel,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

import moment from "moment";
import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

let manyDirtyMedicine=false;

const AefiItemsModal = ({ handleClose, openOrder, ...props }) => {

  const classes = useStyles();

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "aefi";

  const { id } = useParams();
  const queryClient = useQueryClient();

  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const ManufacturerList = JSON.parse(localStorage.getItem("ManufacturerList"));
  const Unitofmeasurelist = JSON.parse(localStorage.getItem("Unitofmeasurelist"));
  const RouteOfAdminlist = JSON.parse(localStorage.getItem("RouteOfAdminlist"));
  
  const [errorObject, setErrorObject] = useState({});

  const [aefiItemFormData, setAefiItemFormData] = useState({
    TradeDCIDosage: "",
    UnitId: "",
    RouteId: "",
    DosageRoute: "",
    BeginningDate: "",
    StopDate: "",
    BatchNo: "",
    ExpiryDate: "",
    ManufacturerId: "",
    Reason: "",
  });

  const handleReset = () => {
    setAefiItemFormData({
      TradeDCIDosage: "",
      UnitId: "",
      RouteId: "",
      DosageRoute: "",
      BeginningDate: "",
      StopDate: "",
      BatchNo: "",
      ExpiryDate: "",
      ManufacturerId: "",
      Reason: "",
    });
  };

  const onManySetDirty = () => {   
    if(manyDirtyMedicine==true){ 
      document.getElementById("medicine_dirty_message").classList.remove("dnone");   
    }else{ 
      document.getElementById("medicine_dirty_message").classList.add("dnone");
    }
  }

  const { mutate } = useMutation(api.saveAEFIItemsData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        handleClose('AEFIItemsModal');

        props.refetchItemData(props.formData.AefiMasterId);
        handleReset();

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...aefiItemFormData };
    data[name] = value;

    setAefiItemFormData(data);

    setErrorObject({ ...errorObject, [name]: null });

    manyDirtyMedicine=true;
    onManySetDirty();

  };

  const validateForm = (formData) => {
    let validateFields = ["TradeDCIDosage"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })

    setErrorObject(errorData);
    return isValid
  }

  /*
  const mformOnchange = (e,cases,inputname) => { 
    console.log("On form change",inputname);
    //isDirty=true;
     
     if(cases=='handleChange')
     handleChange(e);
     else if(cases=='handleRDateChange')
     handleRDateChange(e,inputname);

     manyDirtyMedicine=true;
     onManySetMedicineDirty();

   };

   */

  const handleRDateChange = (date, field) => {

    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }

    setAefiItemFormData({ ...aefiItemFormData, [field]: newDate });
    manyDirtyMedicine=true;
    onManySetDirty();
  };

  const handleSubmit = async (e) => {

    let fDtat = {
      ...aefiItemFormData,
      menukey: menukey,
      lan: lan,
      UserName: UserName,
      AefiMasterId: props.formData.AefiMasterId,
    };

    if (validateForm(aefiItemFormData)) {
      mutate(fDtat);
    }

  };

  useEffect(() => {

    if (props.formDataForAefiItem) {
      setAefiItemFormData(props.formDataForAefiItem);
    }
  }, [props.formDataForAefiItem]);

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('AEFIItemsModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('AEFIItemsModal')}
        >
          {DispensingLanguage[lan][menukey]['Add AEFI Item']}
        </BootstrapDialogTitle>
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={10}>
              <Grid item xs={4} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      error={errorObject.TradeDCIDosage}
                      helperText={errorObject.TradeDCIDosage}
                      required
                      id="TradeDCIDosage"
                      name="TradeDCIDosage"
                      value={aefiItemFormData.TradeDCIDosage}
                      label={DispensingLanguage[lan][menukey]['Trade_name_Dosage']}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                      //onChange={(e) =>mformOnchange(e,'handleChange','TradeDCIDosage')}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Unit"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="UnitId"
                        name="UnitId"
                        value={aefiItemFormData.UnitId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {Unitofmeasurelist.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UnitId-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Route of administration"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="RouteId"
                        name="RouteId"
                        value={aefiItemFormData.RouteId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {RouteOfAdminlist.map((item, index) => {
                          return (
                            <MenuItem
                              key={`RouteId-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="DosageRoute"
                      name="DosageRoute"
                      value={aefiItemFormData.DosageRoute}
                      label={DispensingLanguage[lan][menukey]['Dosage']}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                      //onChange={(e) =>mformOnchange(e,'handleChange','DosageRoute')}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}>
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        className={classes.fullWidth}
                        variant="inline"
                        id="BeginningDate"
                        label={DispensingLanguage[lan][menukey]["Date of beginning"]}
                        autoOk={true}
                        name="BeginningDate"
                        fullWidth
                        showTodayButton={true}
                        value={aefiItemFormData.BeginningDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) => handleRDateChange(e, "BeginningDate")}
                        //onChange={(e) =>mformOnchange(e,'handleRDateChange','BeginningDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}>
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        className={classes.fullWidth}
                        variant="inline"
                        id="StopDate"
                        label={DispensingLanguage[lan][menukey]["Stop date"]}
                        autoOk={true}
                        name="StopDate"
                        fullWidth
                        showTodayButton={true}
                        value={aefiItemFormData.StopDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) => handleRDateChange(e, "StopDate")}
                        //onChange={(e) =>mformOnchange(e,'handleRDateChange','StopDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="BatchNo"
                      name="BatchNo"
                      value={aefiItemFormData.BatchNo}
                      label={DispensingLanguage[lan][menukey]['Batch number']}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                      //onChange={(e) =>mformOnchange(e,'handleChange','BatchNo')}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} className="marginBottom20">
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}>
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="ExpiryDate"
                        label={DispensingLanguage[lan][menukey]["Expiration date"]}
                        autoOk={true}
                        name="ExpiryDate"
                        fullWidth
                        showTodayButton={true}
                        value={aefiItemFormData.ExpiryDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) => handleRDateChange(e, "ExpiryDate")}
                        //onChange={(e) =>mformOnchange(e,'handleRDateChange','ExpiryDate')}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} sm={6} className="marginBottom20">
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]["Manufacturer name"]}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="ManufacturerId"
                      name="ManufacturerId"
                      value={aefiItemFormData.ManufacturerId}
                      fullWidth
                      onChange={(e) => handleChange(e)}
                    >
                      {ManufacturerList.map((item, index) => {
                        return (
                          <MenuItem
                            key={`ManufacturerId-${index + 1}`}
                            value={item.ManufacturerId}
                          >
                            {item.ManufacturerName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6} className="marginBottom20">
                    <TextField
                      id="Reason"
                      name="Reason"
                      value={aefiItemFormData.Reason}
                      label={DispensingLanguage[lan][menukey]['Reason_administration']}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                      //onChange={(e) =>mformOnchange(e,'handleChange','Reason')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <DialogActions>
          <div className="mr-4 mt-2 float-left">
            <span id="medicine_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["Form has dirty data"]}</span>
          </div>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
          >
            {DispensingLanguage[lan][menukey]['Save']}
          </Button>
          <Button autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose('AEFIItemsModal')}>
            {DispensingLanguage[lan][menukey]['Cancel']}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AefiItemsModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});