import React, { useState, useEffect, } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import { addDays, subDays } from "date-fns";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, } from "react-tabulator";

import { useQuery } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Grid,

} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { Button } from "reactstrap";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

// import DefaultFooter from "components/Footers/DefaultFooter";
import { getDefaultMonthYear } from "../../services/Common";

import { useTranslation } from "react-i18next";
import { checkLogin, checkUserPermission, } from "../../services/CheckUserAccess";


const ReportPage = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, } = useTranslation();
  const menukey = "stock-summary";
  const FacilityId = localStorage.getItem("FacilityId");
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const Facility_List = JSON.parse(localStorage.getItem("FacilityList"));

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);
  const MosTypelist = JSON.parse(localStorage.getItem("MosTypelist"));
  const bFacilitySelected = localStorage.getItem("bFacilitySelected");

  exporting(Highcharts);
  const classes = useStyles();

  // const [pickerData, setPickerData] = useState([{
  //     startDate: subDays(new Date(), 29),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  // const [isLoadingDashboard, setLoading] = useState(true);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );

  const [currFacilityId, setCurrFacilityId] = useState(bFacilitySelected == 1 ? FacilityId : 0);

  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
  };

  /*   const handleFacilityChange = (event) => {
      setCurrFacilityId(event.target.value);
    }; */

  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    setCurrFacilityId(rowId);
  };

  const [nationalStockCategory, setNationalStockCategory] = useState([]);
  const [nationalStockData, setNationalStockData] = useState([]);
  const [nationalStockDateRange, setnationalStockDateRange] = useState([]);

  const option_National_Stock_Summary = () => {
    let National_Stock_Summary = {
      chart: {
        type: "bar",
        borderColor: "#C3DDEC",
        height: 600,
        exporting: {
          enabled: true
        },
        //   borderWidth: 1,
        //   plotBorderWidth: 1,
        margin: [50, 50, 50, 300],

      },
      title: {
        text:
          t(DispensingLanguage[lan][menukey]["Summary Report of"]) +
          " " +
          nationalStockDateRange,
      },
      xAxis: {
        categories: nationalStockCategory,

      },
      yAxis: {
        min: 0,
        max: 36,
        title: {
          text: t(DispensingLanguage[lan][menukey]["Month of Stock (MOS)"]),
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        }
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: " month(s)",
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            crop: true,
            formatter: function () {
              return this.y;
            },
          },
        },
      },
      series: [
        {
          name: nationalStockDateRange,
          data: nationalStockData,
        },
      ],
    };

    return National_Stock_Summary;
  };

  let params = {
    action: "getStockSummaryChart",
    menukey: menukey,
    FacilityId: currFacilityId,
    Month: currMonthId,
    Year: currYearId,
  };
  const { data: stockDetailData } = useQuery([params], api.getStockSummaryChart, {
    onSuccess: (data) => {

      setNationalStockCategory(data.data.NationalStockCategory.category);
      setnationalStockDateRange(data.data.NationalStockCategory.date_range);

      let Ndata = [];

      for (let i = 0; i < data.data.NationalStockCategory.data.length; i++) {
        let newdata = {};
        newdata.y = data.data.NationalStockCategory.data[i].y;
        newdata.color = data.data.NationalStockCategory.data[i].color;

        Ndata.push(newdata);
      }
      setNationalStockData(Ndata);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  let params1 = {
    action: "getStockSummaryTable",
    menukey: menukey,
    FacilityId: currFacilityId,
    Month: currMonthId,
    Year: currYearId,
  };
  const [dataItems, setDataItems] = useState([]);
  const { refetch } = useQuery([params1], api.getStockSummaryTable, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    if (currYearId > 0 && currMonthId > 0) {
      // getConsumptionTrendsTopFiveProducts.refetch();
    }
  }, [currYearId, currMonthId, currFacilityId]);

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  /* const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
  }, []);

  // const handleDatePicker = (e) => {};

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#."],
      formatter: "rownum",
      width: 40,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "ClosingStock",
      width: 150,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false }
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      width: 150,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false }
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      width: 150,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
    }
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
      "?action=StockSummaryExcelExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" + Date.now() +
      "&Month=" + currMonthId +
      "&Year=" + currYearId +
      "&FacilityId=" + currFacilityId
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div className="section signup-top-padding sw_inner_container">
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Stock Summary"])}
                </div>
              </div>
            </div>

            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={1}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="YearList"
                        name="YearList"
                        value={currYearId}
                        onChange={handleYearChange}
                        fullWidth
                      >
                        {YearList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="MonthList"
                        name="MonthList"
                        value={currMonthId}
                        onChange={handleMonthChange}
                        fullWidth
                      >
                        {MonthList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      {/* <Select
                          labelId="demo-simple-select-helper-label"
                          id="FacilityList"
                          name="FacilityList"
                          value={currFacilityId}
                          onChange={handleFacilityChange}
                          fullWidth
                        >
                          <MenuItem value="0">
                            {t(DispensingLanguage[lan][menukey]["All Facility"])}
                          </MenuItem>

                          {FacilityList.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select> */}

                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="FacilityList"
                        disableClearable
                        options={FacilityList}
                        onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label={DispensingLanguage[lan][menukey]["All Facility"]}
                            variant="standard"
                            hiddenLabel
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}></Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey][
                      "Stock Summary (Month of Stock = MOS)"
                      ]
                    )}
                  />
                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={option_National_Stock_Summary()}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mylegend_area">
                          {MosTypelist.map((item, index) => {
                            return (
                              <div className="my_legend" key={index}>
                                <div
                                  className="mylegend_color"
                                  style={{ background: item.ColorCode }}
                                ></div>
                                <div className="mylegend_label">{item.name}</div>
                                <div className="mylegend_val">
                                  {item.MosLabel}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12">
                <Card className="sw_card">
                  <CardHeader
                    title={t(DispensingLanguage[lan][menukey]["Stock Summary"])}

                    action={
                      <div className="float-right sw_btn_control">
                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("print")}
                        >
                          <i className="fas fa-print"></i>
                        </Button>

                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    }
                  />

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="uniqueName">
                          <ReactTabulator
                            columns={columns}
                            data={dataItems}
                            layout={"fitColumns"}
                          />
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}
          </div>
        </div>
      </>)
  );
};

export default ReportPage;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
