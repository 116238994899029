import React, { useState, useEffect, } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import { addDays, subDays } from "date-fns";

import { useQuery } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  Grid,

} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  //  Link,
  //  AddToHomeScreen,
  Launch
} from "@material-ui/icons";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";



// import DefaultFooter from "components/Footers/DefaultFooter";
import { getDefaultMonthYear } from "../../services/Common";

import { useTranslation } from "react-i18next";

import { checkLogin, checkUserPermission, } from "../../services/CheckUserAccess";




//National Stock Summary (Month of Stock = MOS)

const DashboardPage = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, } = useTranslation();
  const menukey = "Logistics Dashboard";
  const FacilityId = localStorage.getItem("FacilityId");
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const Facility_List = JSON.parse(localStorage.getItem("FacilityList"));

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);
  const MosTypelist = JSON.parse(localStorage.getItem("MosTypelist"));
  const bFacilitySelected = localStorage.getItem("bFacilitySelected");


  exporting(Highcharts);
  const classes = useStyles();

  // const [pickerData, setPickerData] = useState([
  //   {
  //     startDate: subDays(new Date(), 29),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  /*
  let currDate = new Date();
  let defaultYear = currDate.getFullYear();
  let defaultMonthId = currDate.getMonth();
  //0=jan
  if (defaultMonthId == 0) {
    defaultYear = defaultYear - 1;
    defaultMonthId = 12;
  } else {
    defaultMonthId = defaultMonthId;
  }

  */

  const goToStockStatusDetails = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/stock-status-details`
    );
  }

  const goToStockSummary = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/stock-summary`
    );
  }

  const goToConsumptionTrends = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/consumption-trends-for-the-top-five-products`
    );
  }


  const goToAMCTrends = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/amc-trends-for-the-top-five-products`
    );
  }

  const goToTracerCommodities = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/percentage-of-facilities-with-a-stockout-of-tracer-commodities`
    );
  }

  const goToAvailabilityOfCommodities = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/availability-of-commodities`
    );
  }

  // const [isLoadingDashboard, setLoading] = useState(true);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );

  //const [currFacilityId, setCurrFacilityId] = useState(0);
  const [currFacilityId, setCurrFacilityId] = useState(bFacilitySelected === 1 ? FacilityId : 0);

  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
    // setLoading(true);
  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
    // setLoading(true);
  };

  /*   const handleFacilityChange = (event) => {
      setCurrFacilityId(event.target.value);
      // setLoading(true);
    }; */

  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    setCurrFacilityId(rowId);
  };

  const [availability, setAvailability] = useState([]);
  const [availabilityCategories, setAvailabilityCategories] = useState([]);
  // const [stockStatusDetails, setStockStatusDetails] = useState([]);
  const [nationalStockCategory, setNationalStockCategory] = useState([]);
  const [nationalStockData, setNationalStockData] = useState([]);

  const [nationalStockDateRange, setnationalStockDateRange] = useState([]);
  const [AMCTrendsTopFiveProducts, setAMCTrendsTopFiveProducts] = useState([]);
  const [
    ConsumptionTrendsTopFiveProducts,
    setConsumptionTrendsTopFiveProducts,
  ] = useState([]);
  const [
    PercentageOfFacilitiesWithStockoutOfTracerCommodities,
    setPercentageOfFacilitiesWithStockoutOfTracerCommodities,
  ] = useState([]);

  const [
    option_Stock_Status_Details,
    setOption_Stock_Status_Detailss,
  ] = useState([]);


  const getConsumptionTrendsTopFiveProducts = useQuery(
    ["consumptiontrendstopfiveproducts"],
    () =>
      api.getConsumptionTrendsTopFiveProducts(
        currYearId,
        currMonthId,
        currFacilityId
      ),
    {
      onSuccess: (data) => {
        setConsumptionTrendsTopFiveProducts({
          chart: {
            type: "line",
          },
          title: {
            text: data.range,
          },
          xAxis: {
            categories: data.category,
          },
          yAxis: {
            title: {
              text: t(
                DispensingLanguage[lan][menukey]["Monthly Consumption"]
              ),
            },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#808080",
              },
            ],
            min: 0,
          },
          tooltip: {
            shared: true,
            crosshairs: true,
          },
          credits: {
            enabled: false,
          },
          series: data.seriesdata,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const getAMCTrendsTopFiveProducts = useQuery(
    ["amctrendstopfiveproducts"],
    () =>
      api.getAMCTrendsTopFiveProducts(currYearId, currMonthId, currFacilityId),
    {
      onSuccess: (data) => {
        setAMCTrendsTopFiveProducts({
          chart: {
            type: "line",
          },
          title: {
            text: data.range,
          },
          xAxis: {
            categories: data.category,
          },
          yAxis: {
            title: {
              text: t(
                DispensingLanguage[lan][menukey]["Average Monthly Consumption"]
              ),
            },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#808080",
              },
            ],
            min: 0,
          },
          tooltip: {
            shared: true,
            crosshairs: true,
          },
          credits: {
            enabled: false,
          },
          series: data.seriesdata,
        });
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  //Percentage of Facilities with a Stockout of Tracer Commodities
  const getPercentageOfFacilitiesWithStockoutOfTracerCommodities = useQuery(
    ["StockoutOfTracerCommodities"],
    () =>
      api.getPercentageOfFacilitiesWithStockoutOfTracerCommodities(
        currYearId,
        currMonthId,
        currFacilityId
      ),
    {
      onSuccess: (data) => {
        setPercentageOfFacilitiesWithStockoutOfTracerCommodities({
          chart: {
            type: "line",
          },
          title: {
            text: data.duration,
          },
          xAxis: {
            categories: data.category,
          },
          yAxis: {
            min: 0,
            max: 100,
            title: {
              text: "",
            },
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            formatter: function () {
              return (
                "<b>" +
                this.x +
                "</b><br/>" +
                data.name +
                ": " +
                Highcharts.numberFormat(this.y, 0) +
                "%"
              );
            },
          },
          series: [
            {
              name: data.name,
              data: data.series,
              color: "#002f6c",
            },
          ],
        });
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const option_Availability_of_commodities = () => {
    let Availability_of_commodities = {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: availabilityCategories,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      legend: {
        reversed: true,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: availability,
    };

    return Availability_of_commodities;
  };



  const option_National_Stock_Summary = () => {
    let National_Stock_Summary = {
      chart: {
        type: "bar",
        borderColor: "#C3DDEC",
        height: 400,
        exporting: {
          enabled: true
        },
        //   borderWidth: 1,
        //   plotBorderWidth: 1,
        margin: [50, 50, 50, 300],

      },
      title: {
        text:
          t(DispensingLanguage[lan][menukey]["Summary Report of"]) +
          " " +
          nationalStockDateRange,
      },
      xAxis: {
        categories: nationalStockCategory,

      },
      yAxis: {
        min: 0,
        max: 36,
        title: {
          text: t(DispensingLanguage[lan][menukey]["Month of Stock (MOS)"]),
          align: 'middle'
        },
        labels: {
          overflow: 'justify'
        }
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        valueSuffix: " month(s)",
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            crop: true,
            formatter: function () {
              return this.y;
            },
          },
        },
      },
      series: [
        {
          name: nationalStockDateRange,
          data: nationalStockData,
        },
      ],
    };

    return National_Stock_Summary;
  };

  let params = {
    action: "getAvailability",
    menukey: menukey,
    FacilityId: currFacilityId,
    Month: currMonthId,
    Year: currYearId,
  };
  const { data, } = useQuery([params], api.getLogisticDashboard, {
    onSuccess: (data) => {
      // console.log("Datalist", data);

      if (data.data.Availability.level_name.length == 0) {

        setAvailability([]);

        setAvailabilityCategories([]);

      }
      else {
        setAvailability([
          {
            name: data.data.Availability.level_name[0],
            data: data.data.Availability.dataValue[0],
            color: data.data.Availability.barcolor[0],
          },
          {
            name: data.data.Availability.level_name[1],
            data: data.data.Availability.dataValue[1],
            color: data.data.Availability.barcolor[1],
          },
        ]);

        setAvailabilityCategories(data.data.Availability.item_name);

      }





    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  let params1 = {
    action: "getStockStatusDetails",
    menukey: menukey,
    FacilityId: currFacilityId,
    Month: currMonthId,
    Year: currYearId,
  };
  const { data: stockDetailData } = useQuery([params1], api.getLogisticDashboard, {
    onSuccess: (data) => {


      setOption_Stock_Status_Detailss({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 495,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: t(DispensingLanguage[lan][menukey]["Stock Status Details"]),
            colorByPoint: true,
            data: data.data.StockStatusDetails.data,
          },
        ],
      })

      //  setStockStatusDetails(data.data.StockStatusDetails.data);

      setNationalStockCategory(data.data.NationalStockCategory.category);
      setnationalStockDateRange(data.data.NationalStockCategory.date_range);

      let Ndata = [];

      for (let i = 0; i < data.data.NationalStockCategory.data.length; i++) {
        let newdata = {};
        newdata.y = data.data.NationalStockCategory.data[i].y;
        newdata.color = data.data.NationalStockCategory.data[i].color;

        Ndata.push(newdata);
      }
      setNationalStockData(Ndata);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });



  useEffect(() => {
    // console.log("useEffect calling for refresh");

    if (currYearId > 0 && currMonthId > 0) {
      getConsumptionTrendsTopFiveProducts.refetch();
      getAMCTrendsTopFiveProducts.refetch();
      getPercentageOfFacilitiesWithStockoutOfTracerCommodities.refetch();
    }
  }, [currYearId, currMonthId, currFacilityId]);

  const [RedirectLogin, setRedirectLogin] = React.useState(true);

  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  /* const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */
  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  // const checkAccess = () => {

  //   // let auth = Constants.USER_INFO;
  //   // if (auth) {
  //   //   let menu = auth[0].menu;
  //   //   if (menu.length > 0) {
  //   //     var check = menu.filter((name) => name.title.includes("Dashboard"));
  //   //     if (check.length == 0) {
  //   //       props.history.push(menu[0].url);
  //   //     }
  //   //   } else {
  //   //     swal("Oops!", `You don't have permission to access any page`, "error");
  //   //     props.history.push("/login");
  //   //     sessionStorage.clear();
  //   //   }
  //   // }

  // };

  // const handleDatePicker = (e) => {};

  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");
  //   if (!token) {
  //     swal("Oops!", `token expired. Please login again`, "error");
  //     props.history.push("/login");
  //   }
  // };

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div className="section signup-top-padding sw_inner_container">
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Logistics Dashboard"])}
                </div>
              </div>
            </div>
            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={1}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="YearList"
                        name="YearList"
                        value={currYearId}
                        onChange={handleYearChange}
                        fullWidth
                      >
                        {YearList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="MonthList"
                        name="MonthList"
                        value={currMonthId}
                        onChange={handleMonthChange}
                        fullWidth
                      >
                        {MonthList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      {/*  <Select
                          labelId="demo-simple-select-helper-label"
                          id="FacilityList"
                          name="FacilityList"
                          value={currFacilityId}
                          onChange={handleFacilityChange}
                          fullWidth
                        >
                          <MenuItem value="0">
                            {t(DispensingLanguage[lan][menukey]["All Facility"])}
                          </MenuItem>

                          {FacilityList.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select> */}

                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="FacilityList"
                        disableClearable
                        options={FacilityList}
                        onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label={DispensingLanguage[lan][menukey]["All Facility"]}
                            variant="standard"
                            hiddenLabel
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}></Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}
            <div className="row">
              <div className="col-md-6 mb-4">
                <Card className="sw_card">



                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey]["Stock Status Details"]
                    )}
                    action={
                      <a href="javascript:void(0);" className="HyColor" onClick={goToStockStatusDetails}><Launch /></a>
                    }
                  />

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={option_Stock_Status_Details}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-6 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey]["Stock Summary (Month of Stock = MOS)"]
                    )}
                    action={
                      <a href="javascript:void(0);" className="HyColor" onClick={goToStockSummary}><Launch /></a>
                    }
                  />
                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={option_National_Stock_Summary()}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mylegend_area">
                          {MosTypelist.map((item, index) => {
                            return (
                              <div className="my_legend" key={index}>
                                <div
                                  className="mylegend_color"
                                  style={{ background: item.ColorCode }}
                                ></div>
                                <div className="mylegend_label">{item.name}</div>
                                <div className="mylegend_val">
                                  {item.MosLabel}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>

            {/* new row */}
            <div className="row">
              <div className="col-md-6 mb-4">
                <Card className="sw_card">



                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey]["Consumption Trends for the Top Five Products"]
                    )}
                    action={
                      <a href="javascript:void(0);" className="HyColor" onClick={goToConsumptionTrends}><Launch /></a>
                    }
                  />





                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={ConsumptionTrendsTopFiveProducts}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="col-md-6 mb-4">
                <Card className="sw_card">



                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey]["AMC Trends for the Top Five Products"]
                    )}
                    action={
                      <a href="javascript:void(0);" className="HyColor" onClick={goToAMCTrends}><Launch /></a>
                    }
                  />




                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={AMCTrendsTopFiveProducts}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}

            {/* new row */}
            <div className="row">
              <div className="col-md-6 mb-4">
                <Card className="sw_card">




                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey]["Percentage of Facilities with a Stockout of Tracer Commodities"]
                    )}
                    action={
                      <a href="javascript:void(0);" className="HyColor" onClick={goToTracerCommodities}><Launch /></a>
                    }
                  />



                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            PercentageOfFacilitiesWithStockoutOfTracerCommodities
                          }
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="col-md-6 mb-4">
                <Card className="sw_card">

                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey]["Availability of commodities"]
                    )}
                    action={
                      <a href="javascript:void(0);" className="HyColor" onClick={goToAvailabilityOfCommodities}><Launch /></a>
                    }
                  />

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={option_Availability_of_commodities()}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}
          </div>
        </div>
      </>)
  );
};

export default DashboardPage;

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
