import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  TextField,
  Button,
  Checkbox,
  FormControlLabel

} from "@material-ui/core";
import { useTranslation } from "react-i18next";
//import { Button } from "reactstrap";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

//delete modal import
import useModalPopupDelete from "../../../hooks/useModalPopupDelete/useModalPopupDelete";

import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { AddCircle, DeleteOutline, ViewList } from "@material-ui/icons";
//import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from "@material-ui/lab/Autocomplete";

//Services
import { getDefaultMonthYear } from "../../../services/Common";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

const MonthClose = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  
  const { t } = useTranslation();
  const menukey = "month-close";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo === 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo === 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));

  const FLevelId = localStorage.getItem("FLevelId");

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupDelete({menukey});
  const { bDeleteConfirm:bMonthclosepopup, modalPopupDelete:Monthclosepopup } = useModalPopupPost({ menukey });
  const classes = useStyles();
  // const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [isLoadingUi, setLoading] = useState(false);
  // const [MenuKeyValue, setMenuKeyValue] = useState(0);
  const [reloadData, setReloadData] = useState(true);

  const [dataProducts, setDataProducts] = useState([]);

  const [selectedLmisId, setSelectedLmisId] = useState(0);
  // const [bFirst, setBFirst] = useState(true); 
 

  const [selectedrow, setSelectedrow] = useState(false);
  let tblMenuRef = null;



  const MosTypelist = JSON.parse(localStorage.getItem("MosTypelist"));

  //facility combo load

  const [Facility_list, setFacilityData] = useState([]);
  const [activeFacilityData, setAcFacilityData] = useState({
    id: Facility_list.length > 0 ? Facility_list[0].id : "",
    name: Facility_list.length > 0 ? Facility_list[0].name : "",
  });

  React.useEffect(() => {
    if (activeFacilityData.id == "") {
      getFacilityData();
    }
  }, [activeFacilityData.id]);

  const getFacilityData = () => {
    let FacilityParam = {
      action: "FacilitylistforReport",
      RegionId: 0,
      DistrictId: 0,
      RoleId: RoleId,
      UserId: UserId,
      IsAll:1,
      menukey :menukey,
      lan:lan,
    };
    cFacilityList(FacilityParam);
  };

  const { mutate: cFacilityList } = useMutation(api.getFacility, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setFacilityData(data.data.datalist);

        
        for(let i=0;i<data.data.datalist.length;i++){
          if(FacilityId==data.data.datalist[i].id)
          {
            setAcFacilityData({
            id: data.data.datalist[i].id,
            name: data.data.datalist[i].name,
          });

          }
        }
        
      }
    },
  });

  const handleFacilityListChange = (event, newValue) => {
    setAcFacilityData(newValue);
  };
 
  const [currYearId, setCurrYearId] = useState(getDefaultMonthYear().defaultYear); 
  const [currMonthId, setCurrMonthId] = useState(getDefaultMonthYear().defaultMonthId); 
  const [MonthId, setMonthId] = useState(0); 
  const [YearId, setYearId] = useState(0); 
  const [timestamp, setTimestamp] = useState(Date.now());

  const [checked, setChecked] = React.useState(false);
  const [CheckAll, setCheckAll] = useState(false);
  const [disablecheckBox, setDisablecheckBox] = useState(true);
  

  let params = {
    menukey: menukey,
    action: "getDataList",
    currYearId: currYearId,
    currMonthId: currMonthId,
    currFacilityId:activeFacilityData.id,    
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    timeStamp: timestamp,
  };

  const { refetch } = useQuery([params], api.getMonthCloseData, {
    onSuccess: (data) => {
      //alert(6);
      setDataItems(data);  
      MenuRefetch();     
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });
  const handleApproveChange = (event) => {
    setChecked(event.target.checked);
    setLoading(true); 
    setCheckAll(event.target.checked);
   
  };

  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
    setLoading(true);
  };

  const [all, setAll] = React.useState(getDefaultMonthYear().defaultMonthId);

  const handleMonthChange = (event) => {
    setAll(event.target.value);
    setCurrMonthId(event.target.value);
    setLoading(true);
  };

  const { mutate: monthCloseByFacility } = useMutation(
    api.monthCloseByFacility,
    {
      onSuccess: (data) => {  
        refetch();   
      },
    }
  );

  const { mutate: monthCloseDeleteByFacility } = useMutation(
    api.monthCloseDeleteByFacility,
    {
      onSuccess: (data) => {
        setLoading(true);
        refetch(); 
        
       // setReloadData(!reloadData); 
      },
    }
  );

  const handleSave = async (e) => {
    let YearId = e.YearId;
    let MonthId = e.MonthId;
    let FacilityId = e.FacilityId;
    let StatusId = e.StatusId; 
    setSelectedLmisId(e.id);

    Monthclosepopup(monthCloseByFacility,{
      currYearId:YearId, 
      MonthId:MonthId,
      FacilityId:FacilityId,
      StatusId:StatusId,
      UserId:UserId
    }, { PostQ: "LMISPostQ" }, ModalNo, {});


    // swal({
    //   title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    //   text: t(
    //     DispensingLanguage[lan][menukey][
    //       "You want to month close this facility!"
    //     ]
    //   ),
    //   icon: "warning",
    //   buttons: [
    //     t(DispensingLanguage[lan][menukey]["No"]),
    //     t(DispensingLanguage[lan][menukey]["Yes"]),
    //   ],
    //   dangerMode: true,
    // }).then((willUpdate) => {
    //   if (willUpdate) {
    //     setSelectedLmisId(e.id);
    //     // alert(5);
        
    //     monthCloseByFacility({
    //       currYearId,
    //       MonthId,
    //       FacilityId,
    //       StatusId,
    //       UserId,
    //     }); 
    //   }
    // });
  };

  const ModalNo = (validForm) => {

    return;
 
  }

  const handleDeleteByFacility = async (e) => {
    let MonthId = e.MonthId;
    let YearId = e.YearId;
    let FacilityId = e.FacilityId;
    let StatusId = e.StatusId;
    setMonthId(e.MonthId);
    setYearId(e.YearId);

    setSelectedLmisId(e.id);
   
    modalPopupDelete( monthCloseDeleteByFacility, {

      currYearId:YearId, 
      MonthId:MonthId,
      FacilityId:FacilityId,
      StatusId:StatusId,
      UserId:UserId 
    });
     
  };

  
  

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    { title: "StatusId", field: "StatusId", visible: false },
    { title: "MonthId", field: "MonthId", visible: false },
    { title: "FacilityId", field: "FacilityId", visible: false },
    
    {
      title: DispensingLanguage[lan][menukey]["Date"],
      headerFilter: false,
      width: 140,
      field: "fData",
    },
     
    {
      title: DispensingLanguage[lan][menukey]["Month Close Date"],
      headerFilter: true,
    //  width: 200,
      visible: false,
      
      field: "MonthCloseActionDate",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
    //  width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
  
    let statusButton = (
      <>
        
      </>
    );

    if (rowData.IsAllowAction === 1) {
      if(rowData.StatusId==1){

        statusButton=(
          <>
           <ViewList
            onClick={() => {
              editunit(rowData);
            }}
          />
          <DeleteOutline
              onClick={() => {
                
                handleDeleteByFacility(rowData);
              // modalPopupDelete(monthCloseDeleteByFacility, rowData.currYearId);
              }}
            />
         
             
          </>
        );

      }else{

        statusButton=(
          <>
          <ViewList
            onClick={() => {
              editunit(rowData);
            }}
          />
             
          </>
        );

      }
      
    }else{
      rowData.StatusId==1?
      statusButton=(
        <>
         
          <AddCircle
            onClick={() => {
              handleSave(rowData);
            }}
          />
        </>
      ):statusButton=(<></>);


    }

     

    return statusButton;
  }

  const editunit = (data) => {
    //setLoading(true);
    setMonthId(data.MonthId);
    setYearId(data.YearId);
    setSelectedLmisId(data.id);
    
  };

 

  const columnsProducts = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 30,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product_Code"],
      field: "ItemCode",
      headerSort: false,
      headerFilter: false,
      width: 100,
      formatter: "textarea",
     
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Designation"],
      field: "ItemName",
      width: 198,
      headerSort: false, 
      formatter: "textarea",
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Stock"],
      field: "OpB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive"],
      field: "RecB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue"],
      field: "Issue",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Dispense"],
      field: "Consumption",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 80,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment"],
      field: "AdjB",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       formatter:"textarea",
       width: 100,
      
    },
    {
      title: DispensingLanguage[lan][menukey]["AdjustmentMin"],
      field: "AdjC",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 100,
       headerWordWrap:true,
     
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "Stock",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       headerWordWrap:true,
       width: 100,
     
    } ,
    {
      title: DispensingLanguage[lan][menukey]["Stockout Days"],
      field: "StockoutDays",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      //width: 120,
      headerWordWrap:true
     
    },
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 60,
     
    },
   
    {
      title: DispensingLanguage[lan][menukey]["DMM"],
      field: "DMM",
      headerSort: false,
       hozAlign: "right",
       headerHozAlign: "right",
       width: 60,
       visible: FLevelId==99?false:true,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 70,
      formatter: function (cell, formatterParams) {
        // MosTypelist
        let value = cell.getValue();
        let cellColor = "transparent";
        const rowData = cell.getRow().getData(); 
        const NonDispensing = DispensingLanguage[lan][menukey]["Dispense"];

        
        if(rowData.COLOR!=null){

          cellColor= rowData.COLOR;
          cell.getElement().style.backgroundColor = cellColor;
  

        } 
          return value;
       

      },

    },
   
    
  ];


let paramsProduct = {
  menukey: menukey,
  action: "MonthCloseReportList",
  lan: lan,
  FacilityId: FacilityId,
  MonthId: MonthId,
  Year: YearId,
  CheckAll:CheckAll
 // log_id: selectedRoleData,
  };

  const {
    data: logData,
    refetch: MenuRefetch,
  } = useQuery([paramsProduct], api.getMonthCloseViewReport, {
    onSuccess: (logData) => {
      setLoading(false);
      setDataProducts(logData);
      
      
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  
  /*

  useEffect(() => {
    
   // setTimeout(function () {
      refetch();
   // }, 9000);
  }, [reloadData]);
*/
  const rowClick = (e: any, row: any) => {
    let rowData = row._row.data;
    setYearId(rowData.YearId); 
    setMonthId(rowData.MonthId);
    setSelectedLmisId(rowData.id);
    setDisablecheckBox(false);
    
  };

  useEffect(() => { 
    if(dataItems!=undefined)
    if (tblMenuRef.table!=null) {      
      tblMenuRef.table.selectRow(selectedLmisId); 
      MenuRefetch();
    }
   
  }, [dataItems]);
  


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/LMIS_ReportPrintExcel.php";

    window.open(
      finalUrl +
        "?action=A7reportExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
         FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&MonthId=" +
        MonthId +
        "&Year=" +
        YearId+
        "&CheckAll="+
        CheckAll+
        "&TimeStamp=" +
        Date.now() 
    );
  };
  /* =====End of Excel Export Code==== */



  return (
    <>
      <div className={classes.uiLanguagePageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={9} sm={9}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Month Close"]}
              </div>
            </div>
          </Grid>
          <Grid item xs={3} sm={3}>
                  <div className="float-right sw_btn_control">
                 <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i className="fas fa-print"></i>
                    </Button> 

                  <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card"  hidden={false}>
          <CardContent  className={classes.sw_filterCardContent}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}></Grid>
              <Grid item xs={4} sm={3}>

              <FormControlLabel
                      disabled={disablecheckBox}
                      className="OrderApprovalCheck"
                      control={
                        <Checkbox
                          color="primary"
                          name="bDispensingFilter"
                          checked={checked}
                          onChange={handleApproveChange}
                          //value="no"
                          
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["SHOW ALL"]}
                    />
                </Grid>
              <Grid item xs={1} sm={1}   hidden={true}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Year"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="YearList"
                    name="YearList"
                    value={currYearId}
                    onChange={handleYearChange}
                    fullWidth>
                    {YearList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={2}  hidden={true}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Month"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="MonthList"
                    name="MonthList"
                    //value={currMonthId}
                    value={all}
                    onChange={handleMonthChange}
                    fullWidth>
                    <MenuItem value="0">
                      {" "}
                      {DispensingLanguage[lan][menukey]["All"]}
                    </MenuItem>
                    {MonthList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={3} sm={3}  hidden={true}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    className="sw_chosen_filter"
                    id="Facility_list"
                    disableClearable
                    options={Facility_list}
                    value={activeFacilityData}
                    onChange={(event, newValue) =>
                      handleFacilityListChange(event, newValue)
                    }
                    getOptionLabel={(option) => option.name || ""}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

             
            </Grid>

            {/* <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSave()}
              >
                {DispensingLanguage[lan][menukey]["Close Month"]}
              </Button>
            </Grid> */}
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          
           
        </div>




        <Grid item xs={12} sm={12}>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={2}>

              <div className="sw_relative">
                <div className="uniqueName">
                  <ReactTabulator
                     ref={(r) => (tblMenuRef = r)}
                    options={{
                      selectable: 1,
                      selectableRangeMode: 'click',

                    }}
                    columns={columns}
                    data={dataItems}
                    layout={"fitColumns"}
                    height={"500px"}
                    
                    rowClick={(e, row) => rowClick(e, row)}

                  />
                </div>
              </div>
            </Grid>

              <Grid item xs={8} sm={10}>
              <div className="sw_relative">
                <div className="uniqueName">
                  <ReactTabulator
                    columns={columnsProducts}
                    data={dataProducts}
                   // layout={"fitColumns"}
                    height={"500px"}
                    options={{
                      groupBy: ["GroupName"] 
                    }}
                  />
                </div>
              </div>
            </Grid>  

            
          </Grid>
        </Grid>




      </div>
    </>
  );
};

export default MonthClose;

const useStyles = makeStyles({
  uiLanguagePageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  sw_filterCardContent: {
    padding: "0px !important",
  },
});
