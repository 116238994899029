import React, { useState, useEffect } from "react";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardContent,
  FormControl,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { Button } from "reactstrap";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { useTranslation } from "react-i18next";

const CommoditiesStockStatus = (props) => {
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t } = useTranslation();
  const menukey = "commodities-stock-status";
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;

  const tempFLevelList = JSON.parse(localStorage.getItem("FlevelList"));
  let All_FLevel_List = {
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const FLevelList = [All_FLevel_List].concat(tempFLevelList);

  const [currFLevelId, setFLevelId] = useState(FLevelList[0].id);
  const [currFLevelName, setFLevelName] = useState(FLevelList[0].name);

  // FLevel  Change
  const handleFLevelChange = (event, newValue) => {
    let rowId = "";
    let rowname = "";
    if (newValue == null) {
      rowId = "";
      rowname = "";
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }
    if (rowId !== currFLevelId) {
      setFLevelId(rowId);
      setFLevelName(rowname);
    }

    refreshAllData(activeRegionData.id,activeCommuneData.id,activeFacilityData.id,currGroupId,newValue.id,radioValue);
  };

  //region combo list
  const [RegionList, setRegionListData] = useState([]);
  const [activeRegionData, setAcRegionData] = useState({
    id: RegionList.length > 0 ? RegionList[0].id :0,
    name: RegionList.length > 0 ? RegionList[0].name : "",
  });

  React.useEffect(() => {
    if (activeRegionData.id == 0) {
      getRegionData();
    }
  }, [activeRegionData.id]);



  const handleDepartmentChange = (event, newValue) => {
    setAcRegionData(newValue);
    getCommuneData(newValue.id);
    //getFacilityData(newValue.id, activeCommuneData.id);
  };

  const getRegionData = () => {
    let FacilitylevelParam = {
      action: "getDepartmentforreport",
      RoleId: RoleId,
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cRegionList(FacilitylevelParam);
  };

  const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setRegionListData(data.data.datalist);

        setAcRegionData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getCommuneData(data.data.datalist[0].id);
      }
    },
  });

  ////distict combo list
  const [Communelist, setCommunelistData] = useState([]);
  const [activeCommuneData, setAcCommuneData] = useState({
    id: -1,
    name: Communelist.length > 0 ? Communelist[0].name : "",
  });



  const getCommuneData = (RegionId) => {
    let FacilitylevelParam = {
      action: "getcommuneforreport",
      RoleId: RoleId,
      UserId: UserId,
      RegionId: RegionId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
    onSuccess: (data, params) => {
      if (data.status == 200) {
        setCommunelistData(data.data.datalist);

        setAcCommuneData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });

        getFacilityData(
          params.RegionId,
          data.data.datalist.length ? data.data.datalist[0].id : 0
        );
       
      }
    },
  });

  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setAcCommuneData(newValue);
    getFacilityData(activeRegionData.id, newValue.id);
  };

  //facility combo load

  const [Facility_list, setFacilityData] = useState([]);
  const [activeFacilityData, setAcFacilityData] = useState({
    id: -1,
    name: Facility_list.length > 0 ? Facility_list[0].name : "",
  });



  const getFacilityData = (regid, communeid) => {
    let FacilityParam = {
      action: "FacilitylistforReport",
      RegionId: regid,
      DistrictId: communeid,
      RoleId: RoleId,
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cFacilityList(FacilityParam);
  };

  const { mutate: cFacilityList } = useMutation(api.getFacility, {
    onSuccess: (data, params) => {
      if (data.status == 200) {
        setFacilityData(data.data.datalist);

        setAcFacilityData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });

        refreshAllData(
          params.RegionId,
          params.DistrictId,
          data.data.datalist.length ? data.data.datalist[0].id : 0,
          currGroupId,
          currFLevelId,
          radioValue
        );
      }
    },
  });

  const handleFacilityListChange = (event, newValue) => {
    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      newValue.id,
      currGroupId,
      currFLevelId,
      radioValue
    );
    setAcFacilityData(newValue);
  };

  const [firstLoad, setFirstLoad] = useState(true);
  const [radioValue, setRadioValue] = useState(2);

  const changeRadio = (value) => {
    setFirstLoad(true);
    setLoading(true);
    setRadioValue(value);
    refreshAllData(activeRegionData.id,activeCommuneData.id,activeFacilityData.id,currGroupId,currFLevelId,value);
  };

  const [currIsStockId, setIsStockId] = useState("All");

  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Item_label = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const GroupList = [All_Item_label].concat(ProductGroupList);

  const [currGroupId, setcurrGroupId] = useState(0);
  const [GroupName, setgroupName] = useState([]);
  // console.log(GroupName);
  const handleGroupChange = (event, newValue) => {
    let rowId = "";
    let rowname = "";
    if (newValue == null) {
      rowId = "";
      rowname = "";
    } else {
      rowId = newValue.id;
      rowname = newValue.name;
    }
    refreshAllData(activeRegionData.id,activeCommuneData.id,activeFacilityData.id,newValue.id,currFLevelId,radioValue);

    setcurrGroupId(rowId);
    setgroupName(rowname);
  };

  // isstck Change
  const handleIsStockChange = (event, newValue) => {
    let rowId = "";
    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currIsStockId) {
      setIsStockId(rowId);
    }
  };

  const [dataItems, setDataItems] = useState([]);

  const refreshAllData = (RegionId,DistrictId,FacilityId,ItemGroupId,FLevelId,radioValuep) => {
    let params1 = {
      menukey: menukey,
      RegionId: RegionId,
      ZoneId: DistrictId,
      FacilityId: FacilityId,
      ItemGroupId: ItemGroupId,
      FLevelId: FLevelId,
      isStock: radioValuep,
    };
    CommoditiesStockStatusTable({queryKey: [{ ...params1, action: "CommoditiesStockStatus" }],});
  };

  const { mutate: CommoditiesStockStatusTable } = useMutation(
    api.getCommoditiesStockStatusTable,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
    }
  );


  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#."],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      headerFilter: true,
      width: 140,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
      //width: 140,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock Balance"],
      field: "StockQty",
      width: 160,
      hozAlign: "right",
      headerHozAlign: "right",
      //headerFilter: true,
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    } /*
    {
      title: DispensingLanguage[lan][menukey]["AMC"],
      field: "AMC",  
      width: 140,
      hozAlign: "right",
      headerHozAlign: "right",
    },{
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "MOS", 
      width: 140,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    */,
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";

    window.open(
      finalUrl +
        "?action=commoditiesStockStatusPrint" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now() +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&ZoneId=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&ItemGroupId=" +
        currGroupId +
        "&GroupName=" +
        GroupName +
        "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name +
        "&isStock=" +
        radioValue+
        "&FLevelId=" +
        currFLevelId+
        "&FLevelName=" +
        currFLevelName
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className="section signup-top-padding sw_inner_container">
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-1">
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(
                  DispensingLanguage[lan][menukey]["Commodities Stock Status"]
                )}
              </div>
            </div>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="FLevelList"
                          disableClearable
                          options={FLevelList}
                          onChange={(event, newValue) =>
                            handleFLevelChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            FLevelList[
                              FLevelList.findIndex(
                                (FLevelList) => FLevelList.id == currFLevelId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Facility Level"
                                ]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="GroupList"
                          disableClearable
                          options={GroupList}
                          onChange={(event, newValue) =>
                            handleGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            GroupList[
                              GroupList.findIndex(
                                (GroupList) => GroupList.id == currGroupId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Groups"]}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <FormControl component="fieldset" className="mt-3 ml-3">
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="row-radio-buttons-group"
                          defaultValue="SHOW_NONE_ZERO"
                        >
                          <FormControlLabel
                            value="SHOW_ALL"
                            control={<Radio />}
                            label={DispensingLanguage[lan][menukey]["SHOW_ALL"]}
                            onClick={() => changeRadio(0)}
                          />
                          <FormControlLabel
                            value="SHOW_ZERO"
                            control={<Radio />}
                            label={
                              DispensingLanguage[lan][menukey]["Show Zero"]
                            }
                            onClick={() => changeRadio(1)}
                          />
                          <FormControlLabel
                            value="SHOW_NONE_ZERO"
                            control={<Radio />}
                            label={
                              DispensingLanguage[lan][menukey]["Show None-Zero"]
                            }
                            onClick={() => changeRadio(2)}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          height="600px"
                          options={{
                            groupBy: "GroupName",
                            columnCalcs: "both",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}
        </div>
      </div>
    </>
  );
};

export default CommoditiesStockStatus;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});
