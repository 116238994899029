import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import swal from "sweetalert";

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  Button,
  ButtonGroup,
} from "@material-ui/core";

import { DeleteOutline, Edit } from "@material-ui/icons";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import SaveQueryModal from "./SaveQueryModal";
// import DataExtractToolFormData from "./DataExtractToolFormData";
import FilterModal from "./FilterModal";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import CodeMirror from "@uiw/react-codemirror";
import "codemirror/keymap/sublime";
import "codemirror/theme/monokai.css";

const DataExtractTool = (props) => {
  console.log('props', props);

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "data-extract-tool";
  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;

  const UserName = UserInfo == 0 ? '' : UserInfo[0].name;
  const UserId = UserInfo == 0 ? '' : UserInfo[0].id;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;

  const [firstLoad, setFirstLoad] = useState(true);
  const [DataBaseInfo, setDataBaseInfo] = useState([]);
  const [TableList, setTableList] = useState([]);
  const [ColumnsList, setColumnsList] = useState([]);
  const [TableName, setTableName] = useState([]);
  const [ColumnsListForTabulator, setColumnsListForTabulator] = useState([]);
  const [dataItems, setDataItems] = useState([]);

  const [SQLEditorButton, setSQLEditorButton] = useState(true);
  const [SavedQueriesButton, setSavedQueriesButton] = useState(false);
  const [SavedQueryData, setSavedQueryData] = useState([]);

  const [formData, setFormData] = useState({
    QueryId: "",
    QueryName: "",
    StrQuery: "",
    Description: "",
    UserName: UserId,
    lan: lan,
    Overwrite: true
  });

  const [errorObject, setErrorObject] = useState({});

  const [openOrder, setOpenOrder] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [customParams, setCustomParams] = useState({});

  const [isLoading, setLoading] = useState(false);
  const [isQueryName, setQueryName] = useState('');

  const { t, i18n } = useTranslation();
  const editorRef = useRef(null);

  const { mutate: getColumnsListMutate } = useMutation(api.getColumnsList, {
    onSuccess: (data) => {
      setColumnsList(data);
    },
  });

  let params = {
    action: "getDataBaseTableInfo",
    menukey: menukey,
  };
  const {
    error,
    isError,
  } = useQuery([params], api.getDataBaseTableInfo, {
    onSuccess: (data) => {
      setDataBaseInfo(data.DataBase);
      setTableList(data.TableList);

      let colDataParams = {
        action: "getColumnsList",
        menukey: menukey,
        DataBaseName: data.DataBase.DBName,
        DataBaseTableName: TableName
      };
      getColumnsListMutate(colDataParams);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  const handleChangeChoosen = (name, valueobj, value) => {
    setTableName(value)
  };

  const onNewCustomQuery = () => {

    if (TableName == '') {

      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["First select a table from left side"]),
        msgtype: 0,
      });

      return;
    }

    let data = { ...formData };
    data['StrQuery'] = 'SELECT * FROM ' + TableName;
    data['Overwrite'] = false;
    data['QueryName'] = "";
    data['Description'] = "";
    data['QueryId'] = "";

    setFormData(data);
    setCustomParams({});
  };

  const { mutate: getDataMutate } = useMutation(api.getDataForCustomQueryTable, {
    onSuccess: (data) => {
      if (data.status == 200) {
        handleFilterModal('FilterModalClose');

        let DataList = data.datalist;
        setDataItems(DataList);

        setLoading(false);
      } else {

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: 0,
        });
        setLoading(false);

      }
    },
  });

  const { mutate } = useMutation(api.getDataForCustomQueryTableColumns, {
    onSuccess: (data) => {
      if (data.status == 200) {
        let colList = data.datalist.collist;
        setColumnsListForTabulator(colList);

        let sqlQuery = replaceParamsInSQL(); //replace custom parameter to value from picklist
        let Str_Query = removeNewLine(sqlQuery);

        let fDtat = {
          action: "getDataForCustomQueryTable",
          menukey: menukey,
          txtSQL: Str_Query,
          pSqlFieldList: data.datalist.sqlFieldList
        };
        getDataMutate(fDtat);

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        setLoading(false);

      }
    },
  });


  const handleChangeSetParams = (e) => {
    const { name, value } = e.target;

    let data = { ...customParams };
    data[name] = value;
    setCustomParams(data);

  };

  // const getControlList = () => {
  //   // const { name, value } = e.target;

  //   let data = { ...customParams };
  //  return data;

  // };

  const removeNewLine = (sqlQuery) => {
    let txtSQL = sqlQuery.replace(/(\r\n|\n|\r)/gm, " "); //remove new line from string
    return txtSQL;
  };

  function getParamList() {
    let sqlString = formData.StrQuery;

    // const sqlString = "fdf {@aaAa} fdfdsf {@aaaa} dfdfsf aaaa sfsdfssserer e {@bbbb} {@ddsfg} dffff f{@cccc} dfff {@dddd} dddd";
    let matches = sqlString.match(/\{@([^}]+)\}/g);

    if (matches) {
      let plist = matches.map(match => match.slice(2, -1)); // Removing the braces
      plist = plist.filter((value, index, array) => array.indexOf(value) === index); // create unique array

      let plistobj = [];
      plist.forEach((field, i) => {
        plistobj[field] = field;

      });

      setCustomParams(plistobj);
    } else {
      setCustomParams([]);
    }

  }

  const executeCustomSqlCommand = async (e) => {

    /**When there are custom params */
    let sqlString = formData.StrQuery;
    let FindPosition = sqlString.search("{@");
    if (FindPosition != -1) {

      getParamList();

      handleFilterModal("FilterOpen");
      return;
    }


    let Str_Query = removeNewLine(formData.StrQuery);
    /**When there are no custom params */
    let fDtat = {
      action: "getDataForCustomQueryTableColumns",
      menukey: menukey,
      txtSQL: Str_Query
    };
    mutate(fDtat);
  };

  //replace custom parameter to value from picklist
  function replaceParamsInSQL() {

    let sqlString = formData.StrQuery;
    let paramsList = { ...customParams };

    let isValidParam = 0;

    let replacements = {};
    for (const property in paramsList) {
      let pName = "{@" + property + "}";

      replacements[pName] = paramsList[property];

      if (paramsList[property] === property) {
        //if key and value are save then not set param value
        isValidParam = 1;
      }
    }

    sqlString = sqlString.replace(/\{@[a-z0-9A-Z]+\}/g, match => replacements[match]);

    if (isValidParam === 1) {
      sqlString = -1;
    }

    return sqlString;
  }

  const executeFnSqlCommand = async (e) => {
    setLoading(true);

    let sqlQuery = replaceParamsInSQL(); //replace custom parameter to value from picklist

    if (sqlQuery === -1) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Please enter all parameter(s)"],
        msgtype: 0,
      });

      setLoading(false);

      return;
    }

    let Str_Query = removeNewLine(sqlQuery);

    let fDtat = {
      action: "getDataForCustomQueryTableColumns",
      menukey: menukey,
      txtSQL: Str_Query
      // txtSQL: formData.StrQuery
    };
    mutate(fDtat);
  };

  const { mutate: getSavedQueriesMutate } = useMutation(api.getSavedQueriesa, {
    onSuccess: (data) => {
      if (data.status == 200) {
        let DataList = data.datalist;
        setSavedQueryData(DataList);
      } else {
      }
    },
  });

  const SavedQueryColumns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]['Sl#.'],
      formatter: "rownum",
      width: 80,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center"
    },
    {
      title: DispensingLanguage[lan][menukey]['Label'],
      headerFilter: true,
      field: "QueryName"
    },
    {
      title: DispensingLanguage[lan][menukey]['User'],
      headerFilter: true,
      field: "name"
    },
    {
      title: DispensingLanguage[lan][menukey]['Last Modified'],
      headerFilter: true,
      field: "LastModified"
    },
    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 133,
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteunit(rowData);
          }}
        />
      </>
    );
  }

  const { mutate: getQueryDataById } = useMutation(api.getQueryDataById, {
    onSuccess: (data) => {
      if (data.status == 200) {
        let DataList = data.datalist[0];

        DataList['Overwrite'] = true;

        setFormData(DataList);
        setColumnsListForTabulator([]);
        handleOnClickForButton('SQL_Editor');
      } else {
      }
    },
  });

  const editunit = (data) => {
    setQueryName(data.QueryName);

    let fDtat = {
      menukey: menukey,
      lan: lan,
      UserName: UserId,
      QueryId: data.id,
      action: "getQueryDataById",
    };

    getQueryDataById(fDtat);

    setCustomParams({});
  };

  const { mutate: deleteApi } = useMutation(api.deleteSavedQueriesData, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        handleOnClickForButton('Saved_Queries');

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const deleteunit = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this Record!"
        ]
      ),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]),
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({
          QueryId: data.id,
          menukey: menukey,
          lan: lan,
          UserName: UserName,
          "action": "deleteSavedQueriesData"
        });

      }
    });
  };

  const { mutate: SaveQueryMutate } = useMutation(api.onSaveQuery, {
    onSuccess: (data) => {
      if (data.status == 200) {

        handleOnClickForButton('Saved_Queries');
        handleModal('OrderModal');

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const onCancelCustomQuery = () => {
    setFormData({
      QueryId: "",
      QueryName: "",
      StrQuery: "",
      Description: "",
      UserName: UserId,
      lan: lan,
      Overwrite: true
    });

    setColumnsListForTabulator([]);

    setCustomParams({});

  };

  useEffect(() => {
    let colDataParams = {
      action: "getColumnsList",
      menukey: menukey,
      DataBaseName: DataBaseInfo.DBName,
      DataBaseTableName: TableName
    };
    getColumnsListMutate(colDataParams);

  }, [TableName]);

  const handleOnClickForButton = (type) => {
    if (type == 'SQL_Editor') {
      setSQLEditorButton(true);
      setSavedQueriesButton(false);
    } else if (type == 'Saved_Queries') {
      setSQLEditorButton(false);
      setSavedQueriesButton(true);

      let fDtat = {
        action: "getSavedQueriesa",
        menukey: menukey
      };
      getSavedQueriesMutate(fDtat);
    }
  };

  const handleModal = (modalname) => {
    switch (modalname) {
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;
      default:
        break;
    }
  };

  const handleFilterModal = (modalname) => {
    switch (modalname) {
      case "FilterModalClose":
        modalClose();
        break;
      case "FilterOpen":
        setOpenFilterModal(true);
        break;
      default:
        break;
    }
  };

  function modalClose() {
    setOpenFilterModal(false);
    setLoading(false);
  }


  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleChange = (e) => {

    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const onChangeForCodeMirror = (e) => {

    let value = e.getValue();
    let data = { ...formData };
    data['StrQuery'] = value;

    setFormData(data);
  };

  const validateForm = (formData) => {
    let validateFields = ["QueryName", "Description"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {

    if (formData.StrQuery == '') {
      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["Please, Enter select query and Query label"]),
        msgtype: 0,
      });
      return;
    }

    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserId,
        StrQuery: formData.StrQuery,
        action: "onSaveQuery",
      };

      SaveQueryMutate(fDtat);
    }
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/excel_master_customquery.php";

    let sql_Query = replaceParamsInSQL(); //replace custom parameter to value from picklist
    let sqlQuery = removeNewLine(sql_Query);

    window.open(
      finalUrl +
      "?reportType=" + reportType +
      "&menukey=" + menukey +
      "&sqlText=" + sqlQuery +
      "&TableHeaderWidth=" + '' +
      "&lan=" + localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const insertValueQuery = (e) => {
    if (e.detail === 2) {
      const { name, value } = e.target;

      let queryEditor = editorRef.current.editor.getDoc()
      queryEditor.replaceSelection("`" + value + "`");

    }
  };

  return (
    <>
      <div className="sw_inner_card">
        <Grid container spacing={3}>
          <Grid item xs={3} sm={3}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Database Structure']} />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={12}>

                    {/* <div className="sw_dataname">
                        <strong>Database:</strong>
                        <span>{ DataBaseInfo.DBName }</span>
                      </div> */}

                    <Autocomplete
                      autoHighlight
                      id="TableName"
                      options={TableList}
                      onChange={(event, valueobj) => handleChangeChoosen('MenuKey', valueobj, valueobj ? valueobj.id : "")}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Select Table"]}
                          variant="standard"
                          id="TableName"
                          name="TableName"
                          fullWidth
                        />
                      )}
                    />

                    <div className="sw_columns_control">
                      <label>{DispensingLanguage[lan][menukey]["Columns"]} {TableName}</label>
                      <select
                        id="ColumnsList"
                        name="ColumnsList"
                        className="sw_columnslist"
                        size="15"
                        onClick={insertValueQuery}
                      >
                        <option value={TableName}>{TableName}</option>
                        {ColumnsList.map((item, index) => {
                          return (
                            <option value={item.COLUMN_NAME}>{item.COLUMN_NAME}</option>
                          );
                        })}
                      </select>
                    </div>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => onNewCustomQuery()}
                    >
                      {DispensingLanguage[lan][menukey]['New Query']}
                    </Button>

                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={9} sm={9}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                className={SQLEditorButton ? 'active' : ''}
                onClick={() => handleOnClickForButton('SQL_Editor')}
              >{DispensingLanguage[lan][menukey]['SQL Editor']}</Button>
              <Button
                className={SavedQueriesButton ? 'active' : ''}
                onClick={() => handleOnClickForButton('Saved_Queries')}
              >{DispensingLanguage[lan][menukey]['Saved Queries']}</Button>
            </ButtonGroup>

            <div className={SQLEditorButton ? 'sw_SQL_Editor' : 'sw_SQL_Editor dnone'}>
              {/* <DataExtractToolFormData
                      formData={formData}
                      onChangeForCodeMirror={onChangeForCodeMirror}
                      isQueryName={isQueryName}
                      customParams={customParams}
                      {...props}
                    /> */}

              <div>
                <div className="sw_textare_control">
                  <label>
                    SQL {isQueryName && "- " + isQueryName}{" "}
                    {JSON.stringify(customParams) === "{}"
                      ? ""
                      : JSON.stringify(customParams)}
                  </label>

                  <CodeMirror
                    ref={editorRef}
                    id="queryText"
                    name="queryText"
                    className="sw_textarea"
                    value={formData.StrQuery}
                    onChange={(e, change) => onChangeForCodeMirror(e)}
                    height="200px"
                    options={{
                      // theme: 'monokai',
                      keyMap: "sublime",
                      mode: "sql",
                    }}
                  />
                </div>
              </div>


              <Grid item xs={12} className="mt-2">
                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => executeCustomSqlCommand()}
                >
                  {DispensingLanguage[lan][menukey]['Run Query']}
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => handleModal("OrderOpen")}
                >
                  {DispensingLanguage[lan][menukey]['Save Query']}
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => onCancelCustomQuery()}
                >
                  {DispensingLanguage[lan][menukey]['Clear']}
                </Button>

                {ColumnsListForTabulator.length > 0 ? (
                  <div className="pull-right">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => PrintPDFExcelExportFunction("xlsx")}
                    >
                      {DispensingLanguage[lan][menukey]['Excel']}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => PrintPDFExcelExportFunction("csv")}
                    >
                      {DispensingLanguage[lan][menukey]['CSV']}
                    </Button>
                  </div>) : ''}
              </Grid>

              <SaveQueryModal
                handleClose={handleModal}
                openOrder={openOrder}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errorObject={errorObject}
                handleCheck={handleCheck}
                {...props}
              />

              <FilterModal
                handleClose={handleFilterModal}
                openFilterModal={openFilterModal}
                executeFnSqlCommand={executeFnSqlCommand}
                handleChangeSetParams={handleChangeSetParams}
                customParamsForControlShowHide={customParams}
                isLoading={isLoading}
                {...props}
              />

              {ColumnsListForTabulator.length > 0 ? (
                <div className="mt-2">
                  <div className="uniqueName">
                    <ReactTabulator
                      columns={ColumnsListForTabulator}
                      data={dataItems}
                      height={350}
                      layout={"fitDataFill"}
                    />
                  </div>
                </div>
              ) : ''}

            </div>

            <div className={SavedQueriesButton ? 'sw_Saved_Queries' : 'sw_Saved_Queries dnone'}>
              <div className="uniqueName mt-2">
                <ReactTabulator
                  columns={SavedQueryColumns}
                  data={SavedQueryData}
                  layout={"fitColumns"}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DataExtractTool;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "black",
    background: "whitesmoke",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
