import React, { useState } from "react";

import {
  useMutation,
  // useQueryClient,
} from "react-query";
// import { useParams } from 'react-router-dom';

// import swal from "sweetalert";
import moment from "moment";
import * as api from "../../../actions/api";
import StockTakeFormData from "./StockTakeFormData.js";

const AddStockTake = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "stock-take";
  const FacilityId = localStorage.getItem("FacilityId");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const initFacilityData = JSON.parse(localStorage.getItem("initFacilityData"));
  //console.log("hhhhhhh");
  console.log(initFacilityData);
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;

  // const UserInfo = sessionStorage.getItem("User_info")
  // ? JSON.parse(sessionStorage.getItem("User_info"))
  // : 0;
  // const UserName = UserInfo == 0 ? "" : UserInfo[0].name;

  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    parseInt(initFacilityData[0].InitComplete)
      ? moment().format("YYYY-MM-DD")
      : initFacilityData[0].validStartDate
  );

  const [selecteEnddDate, setSelecteEnddDate] = useState(
    parseInt(initFacilityData[0].InitComplete)
      ? moment().format("YYYY-MM-DD")
      : initFacilityData[0].validStartDate
    // initFacilityData[0].InitComplete?null:initFacilityData[0].validStartDate
  );

  const [formData, setFormData] = useState({
    StockTakeDate: selectedDate,
    CompleteDate: selecteEnddDate,
    PreparedBy: UserId,
    IsCompleted: 0,
    ManyJsonSave: "",
    FacilityId: FacilityId,
    Remarks:""
  });

  const [ManyTableData, setManyTableData] = useState([]);

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const [errorObject, setErrorObject] = useState({});

  // const { id } = useParams();
  // const queryClient = useQueryClient();

  /*

  const { mutate } = useMutation(api.saveStockTakeInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        let route = `/${menukey}/edit/${data.StockTakeId}`;
        props.history.push(route);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  */

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    // console.log('formData: ', formData);
    data[name] = value;
    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleReset = () => {
    setFormData({
      ManyJsonSave: "",
      FacilityId: FacilityId,
      CompleteDate: selecteEnddDate,
      IsCompleted: 0,
      Remarks:""
      //Get the url send id
    });
  };

  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
  };

  const validateForm = (formData) => {
    let validateFields = ["StockTakeDate"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };
  /*
  const handleSubmit = async (e) => {
    if (validateForm(formData)) {
      mutate(formData);
    }
  };
  */

  function checkValidateForm(rows, callfrom) {
    if (callfrom == "edit") {
      setManyTableData(rows);
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: rows,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      //mutateUpdate(fDtat);
      return fDtat;
    } else {
      return false;
    }
  }

  return (
    <>
      <StockTakeFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleReset={handleReset}
        isFirstPhysical={parseInt(initFacilityData[0].InitComplete)}
        //handleSubmit={handleSubmit}
        ManyTableData={ManyTableData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        selecteEnddDate={selecteEnddDate}
        handleChangeChoosenProps={handleChangeChoosenProps}
        checkValidateForm={checkValidateForm}
        {...props}
      />
    </>
  );
};

export default AddStockTake;
