import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import ProductSubgroupFormData from "./ProductSubgroupFormData.js";


function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const EditProductSubgroup = (props) => {
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "product-subgroup-entry";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;
  const ItemGroupId = localStorage.getItem("ItemGroupId");


  const [formData, setFormData] = useState({
    ItemGroupId:ItemGroupId,
    ProductSubGroupName: "",
    ProductSubGroupId: ""
    
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["productsubgroup", id],
    () =>
      api.getProductSubGroupData(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.updateProductSubGroup, {
    onSuccess: (data) => {
      if (data.status == 200) {
        api.getAllDropdown("ProductSubGroupList").then((response) => {
          localStorage.setItem(
            "ProductSubGroupList",
            JSON.stringify(response.datalist.ProductSubGroupList)
          );
        });
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        queryClient.getQueriesData("productsubgroup");
        props.history.push("/product-subgroup-entry");
      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ItemGroupId:ItemGroupId,
    ProductSubGroupName: "",
    ProductSubGroupId: ""
    
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ItemGroupId","ProductSubGroupName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //swal("Success!", "", "success");
    }
  };

  return (
    <>
      <ProductSubgroupFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        {...props}
      />
    </>
  );
};

export default EditProductSubgroup;
