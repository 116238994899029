import React, { useState, useEffect } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";


// import {
//   DeleteOutline,
//   Edit,
//   ViewList,
//   FileCopy
// } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";

import CloseIcon from "@material-ui/icons/Close";

// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";

// import MomentUtils from "@date-io/moment";
import moment from "moment";

import { fr, enGB } from 'date-fns/locale'; // import French and English locale
import 'moment/locale/fr'; // import Moment.js French locale


// import { useQuery, useMutation, useQueryClient } from "react-query";
// import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
// import { ReactTabulator, reactFormatter } from "react-tabulator";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DatePickerModal = ({ handleClose, open, addDatePicker, reload, ExpiryDate, ...props }) => {
  /*  let currExpDate =  moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD');
   if(rowData){
     currExpDate =  moment(rowData.ExpiryDate?rowData.ExpiryDate:new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD');
   } */

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "receive";

  moment.locale(lan === 'en_GB' ? 'enGB' : 'fr');

  let currExpDate = moment(ExpiryDate ? ExpiryDate : new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD');

  // let tblRef = null;

  const [selectedProducts, setselectedProducts] = useState(null);

  // let selected = [];

  const [btnDisable, setbtnDisable] = useState(false);


  const [selectedDate, setSelectedDate] = useState(new Date());
  //const [selectedDate, setSelectedDate] = useState(ExpiryDate?ExpiryDate:new Date());

  // const [reloadExtend, setReloadExtend] = useState(0);
  const [selectedDefaultDate, setSelectedDefaultDate] = useState(new Date());


  setInterval(myTimer, 500);

  function myTimer() {
    if ((reload === 0) && (selectedDefaultDate !== currExpDate)) {
      setSelectedDate(currExpDate);
      setSelectedDefaultDate(currExpDate);
      reload = 1;
    }
  }


  const SaveSelectedProducts = () => {

    let validCurrDate = moment(selectedDate); // invalid date
    if (!validCurrDate.isValid()) {
      const currentDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-DD'); // get current date
      validCurrDate = moment(currentDate);
    }

    //setbtnDisable(true);
    setselectedProducts(validCurrDate);
    setSelectedDefaultDate(new Date());
    //setTimeout(() => setbtnDisable(false), 500);

  };

  useEffect(() => {
    addDatePicker(selectedProducts);

  }, [selectedProducts]);


  const handleDateChange = (date) => {
    setSelectedDate(date);
    //handleCloseModal();
  };

  const handleModalColse = () => {
    handleClose('DatePickerClose');
    setSelectedDefaultDate(new Date());
  };

  const classes = useStyles();


  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <BootstrapDialog
        onClose={() => handleModalColse()}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xs"
      >
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('DatePickerClose')}
        >
         {DispensingLanguage[lan][menukey]['Source Lists']} 
        </BootstrapDialogTitle> */}
        <DialogContent dividers>

          <Box display="flex" justifyContent="center">
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={DateFnsUtils}
              locale={lan === "en_GB" ? enGB : fr}
            >
              <KeyboardDatePicker
                autoOk
                variant="static"
                openTo="date"
                className={classes.fullWidth}
                fullWidth
                showTodayButton={true}
                value={selectedDate}
                format="dd/MM/yyyy"
                onChange={handleDateChange}
              /* KeyboardButtonProps={{
                "aria-label": "change date"
              }} */
              />
            </MuiPickersUtilsProvider>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button
            disabled={btnDisable}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
            {DispensingLanguage[lan][menukey]['ADD']}
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleModalColse()}
          >
            {DispensingLanguage[lan][menukey]['CANCEL']}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DatePickerModal;


const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});