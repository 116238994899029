import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  Card,
  Button,
  CardContent,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
// import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import Autocomplete from "@material-ui/lab/Autocomplete";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import * as Service from "../../../services/Service.js";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const UserName = UserInfo === 0 ? "" : UserInfo[0].name;

const ZS = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "zone-entry";

  const classes = useStyles();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [RegionIdValue, setRegionIdvalue] = useState(0);
  const [currRegionText, setRegionText] = useState("");
  const [isLoadingUi, setLoading] = useState(true);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // const [Regionlist, setRegionlist] = useState([]);
  const [Region, setRegion] = React.useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const Region_list = JSON.parse(localStorage.getItem("Regionlist"));
  let All_Regionallist = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const Regionlist = [All_Regionallist].concat(Region_list);

  const [currRegionId, setcurrRegionId] = useState(0);

  if (Region) {
    setRegion(false);

    let ParamList = {
      action: "Regionlist",
      RegionId: currRegionId,
      menukey: "",
    };
    // Service.default
    //     .postApi("source/combo_generic.php",ParamList)
    //     .then((res) => {
    //       let All_Region_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
    //       const Region_List = [All_Region_label].concat(res.datalist);
    //       setRegionlist(Region_List);

    //     })
    //     .catch((err) => {});
  }

  let params = {
    menukey: menukey,
    action: "getDataList",
    RegionId: currRegionId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

  const { refetch } = useQuery([params], api.getZSs, {
    onSuccess: (data) => {
      setDataItems(data);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });
  const { mutate: deleteApi } = useMutation(api.deleteZS, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        //  api.getAllDropdown("ZSlist").then((response) => {
        //   localStorage.setItem(
        //     "ZSlist",
        //    JSON.stringify(response.datalist.ZSlist)
        //    );
        //  });

        refetch();
        queryClient.getQueriesData("zs");
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  //====== Start Choosen Dropdown===========
  const [chosenValuesRegionlist, setChosenValuesRegionlist] = useState({
    Regionlist: { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) },
  });

  const handleChangeChoosenWarehouse = (name, valueobj, value) => {
    let chosenValuesDataRegion = { ...chosenValuesRegionlist };
    chosenValuesDataRegion[name] = valueobj;
    setChosenValuesRegionlist(chosenValuesDataRegion);

    setFirstLoad(true);
    setLoading(false);
    setcurrRegionId(value);
    setRegionText(valueobj.name);
  };

  const WarehousehandleChange = (event) => {
    setFirstLoad(true);
    setLoading(false);
    setRegionIdvalue(event.target.value);
    //console.log('warehouse Data',event.target.value);
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ZSExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&RegionId=" +
        currRegionId +
        "&RegionText=" +
        currRegionText +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editZS(rowData);
  };

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl."],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department Name"],
      width: 120,
      headerFilter: true,
      field: "RegionName",
    },

    {
      title: DispensingLanguage[lan][menukey]["ZS Name"],
      headerFilter: true,
      field: "ZoneName",
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: 120,
      headerHozAlign: "center",
      headerSort: false,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editZS = (data) => {
    let route = `zone-entry/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteZS = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this ZS!"
        ]
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({
          ZoneId: data.id,
          menukey: menukey,
          lan: lan,
          action: "dataDalete",
          UserName: UserName,
        });
      }
      // if (willDelete) {
      //   mutate(data.id);

      // }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <Edit
          onClick={() => {
            editZS(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteZS(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.zsPageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Zone Entry Form"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    id="Regionlist"
                    options={Regionlist}
                    onChange={(event, valueobj) =>
                      handleChangeChoosenWarehouse(
                        "Regionlist",
                        valueobj,
                        valueobj ? valueobj.id : ""
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    value={chosenValuesRegionlist["Regionlist"]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          DispensingLanguage[lan][menukey]["Department Name"]
                        }
                        variant="standard"
                        id="Regionlist"
                        name="Regionlist"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={10} sm={10}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/zone-entry/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add"]}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ZS;

const useStyles = makeStyles({
  zsPageTitle: {
    marginTop: "60px",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
