import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
const GapAnalysis = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "country-profile-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const UserId = UserInfo==0?'': UserInfo[0].id;
  const FacilityId = localStorage.getItem("FacilityId");

  const classes = useStyles();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(true);
  const [dataItems, setDataItems] = useState([]);
  const [fieldList, setfieldList] = useState([]);

  useEffect(() => {
    refreshAllData(props.ItemGroupId, props.YearId);
  }, [props.ItemGroupId, props.YearId]);

  const refreshAllData = (pItemGroupId, pyearid) => {
    let params = {
      menukey: menukey,
      lan: lan,
      ItemGroupId: pItemGroupId,
      YearId: pyearid,
    };
    GetGapanalysicsTable({
      queryKey: [{ ...params, action: "getPledgedFundingData" }],
    });
  };

  const { mutate: GetGapanalysicsTable } = useMutation(
    api.getGapAnalysicsData,
    {
      onSuccess: (data) => {
        let tmpfield = [];
        for (let i = 0; i < data.data.collist.length; i++) {
          //console.log("gg", data.data.collist[i]);

          tmpfield.push({
            title: data.data.collist[i].title,
            field: data.data.collist[i].field,
            width: data.data.collist[i].width,
            hozAlign: data.data.collist[i].hozAlign,
            headerHozAlign: data.data.collist[i].headerHozAlign,
            headerSort: true,
            cssClass: data.data.collist[i].cssClass,
            editable: data.data.collist[i].editable,
            editor: data.data.collist[i].editor,
            validator: ["integer", "min:0"],
            cellEdited: function (cell) {
              const currow = cell.getRow();
              const rowdata = currow.getData();
              let _FundingSourceId =
                data.data.collist[i].field + "_FundingSourceId";
              const PledgedFundingId = rowdata[_FundingSourceId];
              const newval = cell.getValue().trim();
              let CellOldValue = cell._cell.oldValue;
              if (
                CellOldValue == null ||
                CellOldValue == "" ||
                CellOldValue == undefined
              ) {
                CellOldValue = "";
              }
              if (CellOldValue != newval) {
                cellDataUpdate(PledgedFundingId, newval);
              }
            },
          });
        }
        setfieldList(tmpfield);
        setDataItems(data.data.datalist);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const { mutate: getrefressdata } = useMutation(
    api.getGapAnalysicsData,
    {
      onSuccess: (data) => {
       
        setDataItems(data.data.datalist);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const cellDataUpdate = (PledgedFundingId, newval) => {
    //setLoading(true);
    mutatedUpdate({ PledgedFundingId, newval });
  };

  const { mutate: mutatedUpdate } = useMutation(api.updategapData, {
    onSuccess: (data) => {
      if (data.status == 200) {

        let params = {
          menukey: menukey,
          lan: lan,
          ItemGroupId: props.ItemGroupId,
          YearId: props.YearId,
        };
       

        getrefressdata({
          queryKey: [{ ...params, action: "getPledgedFundingData" }],
        });
       
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

       
        //props.history.push("/source-entry");//
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const handleSubmit = async (e) => {
    //console.log("from submit ", e);
    //if (validateForm(formData)) {
    let fDtat = {
      menukey: menukey,
      lan: lan,
      UserName: UserName,
      UserId: UserId,
      FacilityId: FacilityId,
      CountryProfileType: 5,
      YearId: props.YearId,
      ItemGroupId: props.ItemGroupId,
    };
    mutateSave(fDtat);

    //}
  };

  const { mutate: mutateSave } = useMutation(api.SaveYcProfileData, {
    onSuccess: (data) => {
      if (data.status == 200) {
        refreshAllData(props.ItemGroupId, props.YearId);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        queryClient.getQueriesData("source"); //api page name
        //props.history.push("/source-entry");//
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleDelete = async (e) => {
    //console.log("from submit ", e);
    //if (validateForm(formData)) {
    let fDtat = {
      menukey: menukey,
      lan: lan,
      UserName: UserName,
      UserId: UserId,
      FacilityId: FacilityId,
      CountryProfileType: 5,
      YearId: props.YearId,
      ItemGroupId: props.ItemGroupId,
    };
    mutateDelete(fDtat);

    //}
  };

  const { mutate: mutateDelete } = useMutation(api.DeleteCountryProfile, {
    onSuccess: (data) => {
      if (data.status == 200) {
        refreshAllData(props.ItemGroupId, props.YearId);

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        queryClient.getQueriesData("source"); //api page name
        //props.history.push("/source-entry");//
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  return (
    <>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Gap Analysis"]}
              </div>

              <div className="float-right sw_btn_control">
                <Grid item xs={12} className="text-center">
                  <Button
                    color="primary"
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleSubmit()}
                  >
                    <i class="fas fa-plus"></i>
                    {/* {DispensingLanguage[lan][menukey]["Add Record"]} */}
                  </Button>
                  <Button
                    style={{ background: "#f50057" }}
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleDelete()}
                  >
                    <i style={{ color: "white" }} class="fas fa-times"></i>
                    {/* {
                        DispensingLanguage[lan][menukey][
                          "Clear Basic Information"
                        ]
                      } */}
                  </Button>
                </Grid>
              </div>
            </div>
          </Grid>
        </div>

        {/* start of legend */}
        <Card
          className="sw_card sw_filter_card"
          style={{ background: "#E6FAFA" }}
        >
          <CardContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ color: "#008000", fontWeight: "bold" }}
              >
                {
                  DispensingLanguage[lan][menukey][
                    "Please enter all monetary values in US$"
                  ]
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of legend */}
        {/* New row */}

        {/* <Grid container spacing={3}> */}
        <div className="sw_relative">
          <div className="uniqueName">
            {isLoading ? (
              isLoading && <LoadingSpinnerOpaque />
            ) : (
              <Grid item xs={12} sm={12}>
                <Card className="sw_card">
                  <CardContent>
                    <ReactTabulator
                      //ref={(r) => (ReceiveDetailsRef = r)}
                      columns={fieldList}
                      data={dataItems}
                      height="452px"
                      layout={"fitColumns"}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
          </div>
        </div>
      </div>

      {/* </Box> */}
    </>
  );
};

export default GapAnalysis;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
});
