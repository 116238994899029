import React, { useState, useEffect } from "react";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardContent,
  FormControl,
  Grid,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { Button } from "reactstrap";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import { useTranslation } from "react-i18next";
// import {
//   checkLogin,
//   checkUserPermission,
// } from "../../services/CheckUserAccess";
// import moment from "moment";
// import "moment/locale/fr"; // import Moment.js French locale

const CommoditiesStockstatusbyLotExpiry = (props) => {
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t } = useTranslation();
  const menukey = "commodities-stock-status-by-lot-expiry";

  // moment.locale(lan == "en_GB" ? "enGB" : "fr");

  const classes = useStyles();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;

  //region combo list
  const [RegionList, setRegionListData] = useState([]);
  const [activeRegionData, setAcRegionData] = useState({
    id: RegionList.length > 0 ? RegionList[0].id :0,
    name: RegionList.length > 0 ? RegionList[0].name : "",
  });

  React.useEffect(() => {
    if (activeRegionData.id == 0) {
      getRegionData();
    }
  }, [activeRegionData.id]);



  const handleDepartmentChange = (event, newValue) => {
    setAcRegionData(newValue);
    getCommuneData(newValue.id);
  };

  const getRegionData = () => {
    let FacilitylevelParam = {
      action: "getDepartmentforreport",
      RoleId: RoleId,
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cRegionList(FacilitylevelParam);
  };

  const { mutate: cRegionList } = useMutation(api.getRegionforReport, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setRegionListData(data.data.datalist);

        setAcRegionData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });
        getCommuneData(data.data.datalist[0].id);
      }
    },
  });

  ////distict combo list
  const [Communelist, setCommunelistData] = useState([]);
  const [activeCommuneData, setAcCommuneData] = useState({
    id: -1,
    name: Communelist.length > 0 ? Communelist[0].name : "",
  });



  const getCommuneData = (RegionId) => {
    let FacilitylevelParam = {
      action: "getcommuneforreport",
      RoleId: RoleId,
      UserId: UserId,
      RegionId: RegionId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cgetCommuneList(FacilitylevelParam);
  };

  const { mutate: cgetCommuneList } = useMutation(api.getCommuneforReport, {
    onSuccess: (data, params) => {
      if (data.status == 200) {
        setCommunelistData(data.data.datalist);

        setAcCommuneData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });

        getFacilityData(
          params.RegionId,
          data.data.datalist.length ? data.data.datalist[0].id : 0
        );
       
      }
    },
  });

  // Commune Change
  const handleCommuneChange = (event, newValue) => {
    setAcCommuneData(newValue);
    getFacilityData(activeRegionData.id, newValue.id);
  };

  //facility combo load

  const [Facility_list, setFacilityData] = useState([]);
  const [activeFacilityData, setAcFacilityData] = useState({
    id: -1,
    name: Facility_list.length > 0 ? Facility_list[0].name : "",
  });



  const getFacilityData = (regid, communeid) => {
    let FacilityParam = {
      action: "FacilitylistforReport",
      RegionId: regid,
      DistrictId: communeid,
      RoleId: RoleId,
      UserId: UserId,
      IsAll: 1,
      menukey: menukey,
      lan: lan,
    };
    cFacilityList(FacilityParam);
  };

  const { mutate: cFacilityList } = useMutation(api.getFacility, {
    onSuccess: (data, params) => {
      if (data.status == 200) {
        setFacilityData(data.data.datalist);

        setAcFacilityData({
          id: data.data.datalist.length ? data.data.datalist[0].id : 0,
          name: data.data.datalist.length ? data.data.datalist[0].name : "",
        });

        refreshAllData(
          params.RegionId,
          params.DistrictId,
          data.data.datalist.length ? data.data.datalist[0].id : 0,
          currGroupId
          
        );
      }
    },
  });

  const handleFacilityListChange = (event, newValue) => {
    refreshAllData(
      activeRegionData.id,
      activeCommuneData.id,
      newValue.id,
      currGroupId
      
    );
    setAcFacilityData(newValue);
  };

  // =================New Combo========================

  const ProductGroupList = JSON.parse(localStorage.getItem("ProductGroupList"));
  let All_Item_label = {
    id: "0",
    name: t(DispensingLanguage[lan][menukey]["All"]),
  };
  const GroupList = [All_Item_label].concat(ProductGroupList);

  const [currGroupId, setcurrGroupId] = useState(0);
  const [currGroupName, setcurrGroupName] = useState("");
  const handleGroupChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    refreshAllData(activeRegionData.id,activeCommuneData.id,activeFacilityData.id,newValue.id);
    setcurrGroupId(rowId);
    setcurrGroupName(rowName);
  };


  // =================New Combo========================


  const [dataItems, setDataItems] = useState([]);

  const refreshAllData = (RegionId,DistrictId,FacilityId,ItemGroupId) => {
    let params1 = {
    menukey: menukey,
    RegionId: RegionId,
    ZoneId: DistrictId,
    FacilityId: FacilityId,
    ItemGroupId: ItemGroupId,
    };
    CommoditiesStockStatusTable({queryKey: [{ ...params1, action: "ShowProductLotStockReport" }],});
  };

  const { mutate: CommoditiesStockStatusTable } = useMutation(
    api.getProductLotStockReport,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
    }
  );

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      headerSort: true,
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Groups"],
      field: "GroupName",
      headerSort: true,
      width: 170,
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      headerFilter: true,
      headerSort: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 160,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 140,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 240,
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Stock Balance"],
      field: "StockQty",
      width: 140,
      hozAlign: "right",
      headerHozAlign: "right",
      headerSort: true,
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
  ];

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;
  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server_report.php";

    window.open(
      finalUrl +
        "?action=commoditiesStockStatusbyLotPrint" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now() +
        "&RegionId=" +
        activeRegionData.id +
        "&DepartmentName=" +
        activeRegionData.name +
        "&ZoneId=" +
        activeCommuneData.id +
        "&DistrictName=" +
        activeCommuneData.name +
        "&ItemGroupId=" +
        currGroupId +
        "&GroupName=" +
        currGroupName +
        "&FacilityId=" +
        activeFacilityData.id +
        "&FacilityName=" +
        activeFacilityData.name
    );
  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className="section signup-top-padding sw_inner_container">
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-2">
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(
                  DispensingLanguage[lan][menukey][
                    "Commodities Stock Status by Lot/Expiry"
                  ]
                )}
              </div>
            </div>
          </div>

          {/* start of filter */}
          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="RegionList"
                          disableClearable
                          options={RegionList}
                          value={activeRegionData}
                          onChange={(event, newValue) =>
                            handleDepartmentChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Communelist"
                          disableClearable
                          options={Communelist}
                          value={activeCommuneData}
                          onChange={(event, newValue) =>
                            handleCommuneChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["District"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_chosen_filter"
                          id="Facility_list"
                          disableClearable
                          options={Facility_list}
                          value={activeFacilityData}
                          onChange={(event, newValue) =>
                            handleFacilityListChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name || ""}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                DispensingLanguage[lan][menukey]["Facility"]
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={2} style={{marginTop:'4px'}}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          className="sw_fontSize"
                          id="GroupList"
                          disableClearable
                          options={GroupList}
                          onChange={(event, newValue) =>
                            handleGroupChange(event, newValue)
                          }
                          getOptionLabel={(option) => option.name}
                          defaultValue={
                            GroupList[
                              GroupList.findIndex(
                                (GroupList) => GroupList.id == currGroupId
                              )
                            ]
                          }
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Groups"]}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={1} sm={1}>
                  <div className="float-right sw_btn_control">
                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}>
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("excel")}>
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {/* end of filter */}

          {/* new row */}
          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                        <ReactTabulator
                          columns={columns}
                          data={dataItems}
                          layout={"fitColumns"}
                          height="600px"
                          options={{
                            groupBy: "GroupName",
                            columnCalcs: "both",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* end row */}
        </div>
      </div>
    </>
  );
};

export default CommoditiesStockstatusbyLotExpiry;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  }
});
