import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent
} from "@material-ui/core";
import { useTranslation } from "react-i18next";




const AtcFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "atc";
  const classes = useStyles();
  const { t } = useTranslation();




  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner"> {DispensingLanguage[lan][menukey]['Atc Entry Form - Add/Edit']} </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Atc Entry from']} />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errorObject.ATC}
                          helperText={errorObject.ATC}
                          required
                          id="ATC"
                          name="ATC"
                          label={DispensingLanguage[lan][menukey]['Atc Code']}
                          // value={formData.FirstName || "Mahmudul"}
                          value={formData.ATC}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                          disabled={!addProductForm}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          error={errorObject.ATC_NAME}
                          helperText={errorObject.ATC_NAME}
                          required
                          id="ATC_NAME"
                          name="ATC_NAME"
                          label={DispensingLanguage[lan][menukey]['Atc Name']}
                          // value={formData.FirstName || "Mahmudul"}
                          value={formData.ATC_NAME}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {t(DispensingLanguage[lan][menukey]['Reset'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {t(DispensingLanguage[lan][menukey]['Save'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/atc-entry")}
              >
                {t(DispensingLanguage[lan][menukey]['Cancel'])}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {t(DispensingLanguage[lan][menukey]['update'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/atc-entry")}
              >
                {t(DispensingLanguage[lan][menukey]['Cancel'])}
              </Button>
            </Grid>
          )}



        </Grid>
      </div>
    </div>
  );
};

export default AtcFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
