import React, {
  useState,
  useEffect,
  useContext,
  Component,
  useRef,
} from "react";
import { useQuery, useMutation } from "react-query";
import * as api from "../../actions/api";
import { ReactTabulator } from "react-tabulator";
// material components
import {
  FormControl,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import { ReactTabulator, reactFormatter } from "react-tabulator";
// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
// import { Button } from "reactstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";
const ConsumptionTrendbyProduct = (props) => {
  const [pivotData, setpivotData] = useState([]);
  const [pivotfieldlist, setpivotfieldlist] = useState({});
  const [pivotfields, setpivotfields] = useState({});

  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "national-consumption-trend-by-product";
  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserId = UserInfo == 0 ? "" : UserInfo[0].id;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].name;
  const RoleId = UserInfo == 0 ? "" : UserInfo[0].role[0].id;
  const [firstLoad, setFirstLoad] = useState(true);
  // Filter Start

  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const [dataItems, setDataItems] = useState([]);
  const [fieldList, setfieldList] = useState([]);

 

  const currentDate = new Date();

  const MonthListlocal = JSON.parse(localStorage.getItem("MonthList"));
  let All_Month = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const MonthList = MonthListlocal; //[All_Month].concat();

  const YearListlocal = JSON.parse(localStorage.getItem("YearList"));
  let All_Year = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const YearList = YearListlocal;

  let previousMonth = currentDate.getMonth() + 1 - 1;
  let previousYear = currentDate.getFullYear();

  if (previousMonth < 0) {
    // If the previous month is negative, subtract 1 from the year and set the month to 11 (December)
    previousMonth = 11;
    previousYear -= 1;
  }

  // Create a new Date object for the first day of the previous month
  const previousMonthDate = new Date(previousYear, previousMonth - 1, 15);

  previousMonth = previousMonthDate.getMonth() + 1 - 1;
  previousYear = previousMonthDate.getFullYear();
  if (previousMonth < 0) {
    previousMonth = 11;
    previousYear -= 1;
  }
  const previousYearMonthDate = new Date(previousYear, previousMonth - 11, 15);

  const [currmonthId, setmonthId] = useState(
    previousYearMonthDate.getMonth() + 1
  );
  const [currYearId, setYearId] = useState(previousYearMonthDate.getFullYear());

  const [currEndmonthId, setEndmonthId] = useState(
    previousMonthDate.getMonth() + 1
  );
  const [currEndYearId, setEndYearId] = useState(
    previousMonthDate.getFullYear()
  );
  // month  Change
  const handleMonthChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currmonthId) {
      setmonthId(rowId);
    }
  };

  const handleEndMonthChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currEndmonthId) {
      setEndmonthId(rowId);
    }
  };
  //year
  const handleYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currYearId) {
      setYearId(rowId);
    }
  };

  const handleEndYearChange = (event, newValue) => {
    let rowId = "";

    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currEndYearId) {
      setEndYearId(rowId);
    }
  };

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(360, "days").format("YYYY-MM")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const ProductGroup = JSON.parse(localStorage.getItem("ProductGroupList"));
  // let All_Product_Group = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  // const ProductGroup = [All_Product_Group].concat(Product_Group);

  const [currProductGroupId, setProductGroupId] = useState(ProductGroup[0].id);
  const [currItemGroupname, setItemGroupname] = useState(ProductGroup[0].name);

  //toggole button
  const [toggleButtonValue, setToggleButton] = React.useState("0");

  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      if (tmpToggleButtonValue == 0) {
      }

      // setLoadingPrescription(true);
      setToggleButton(tmpToggleButtonValue);
    }
  };



  const [isLoading, setLoading] = useState(false);

  exporting(Highcharts);

  // Product Group Change
  const handleProductGroupChange = (event, newValue) => {
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductGroupId) {
      setProductGroupId(rowId);
      setItemGroupname(rowName);
    }
    getProductData(rowId);
  };

  const getProductData = (rowId) => {
    let ProductParam = {
      action: "getProductItem",
      ItemGroupId: rowId,
      menukey: "",
    };
    cProductList(ProductParam);
  };

  const { mutate: cProductList } = useMutation(
    api.getProductListData, //api name
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setProductData(data.data.datalist);
          setProductRefec({
            id: data.data.datalist[0].id,
            name: data.data.datalist[0].name,
          });

          setProductId(data.data.datalist[0].id);
        }
      },
    }
  );

  const [activeProductfre, setProductRefec] = useState("");
  const [Product_list, setProductData] = useState("");

  const getProductList = Product_list; // [All_ProduccgtList].concat();
  const [currProductId, setProductId] = useState("0");
  const [ProductName, setProductName] = useState("");

  const handleProductListChange = (event, newValue) => {
    setProductRefec(newValue);
    let rowId = "";
    let rowName = "";
    if (newValue == null) {
      rowId = "";
      rowName = "";
    } else {
      rowId = newValue.id;
      rowName = newValue.name;
    }
    if (rowId !== currProductId) {
      setProductId(rowId);
      setProductName(rowName);
    }
  };

  useEffect(() => {
    let ProductParam = {
      action: "getProductItem",
      ItemGroupId: currProductGroupId,
      menukey: "",
    };
    cProductList(ProductParam);
  }, [currProductGroupId]);



  let params = {
    menukey: menukey,
    action: "ConsumptionTrendByProductTable",
    lan: lan,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    YearId: currYearId,
    ItemNo: currProductId,
    EndMonthId: currEndmonthId,
    EndYearId: currEndYearId,
    toggleButtonValue: toggleButtonValue
  
  };

  const { data } = useQuery([params], api.ConsumptionTrendbyProductdReport, {
    onSuccess: (data) => {
      let tmpfield = [];
      for (let i = 0; i < data.data.data.column.length; i++) {
        if (i == 0)
          tmpfield.push({
            title: DispensingLanguage[lan][menukey][data.data.data.column[i]],
            field: data.data.data.column[i],
            width: 187,
            hozAlign: "left",
            headerHozAlign: "left",
            headerSort: false,
          });
        else
          tmpfield.push({
            title: data.data.data.column[i],
            field: data.data.data.column[i],
            width: 108,
              // toggleButtonValuereg == 1
              //   ? 108
              //   : toggleButtonValuereg == 2
              //   ? 95
              //   : 80,
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
            formatter: function (cell, formatterParams) {
              let value = cell.getValue();

              return value == 0 ? "" : value;
            },
          });
      }

      setfieldList(tmpfield);
      setDataItems(data.data.data.list);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });


 // ============ISSUE DISPENSE TREND START=============

  // START OF Issue Dispense Line Chart

  const [receive_details, setOption_receive_details] = useState([]);

  //END OF Issue Dispense Line Chart

  let params1 = {
    menukey: menukey,
    action: "ConsumptionByProductChart",
    lan: lan,
    ItemGroupId: currProductGroupId,
    MonthId: currmonthId,
    YearId: currYearId,
    ItemNo: currProductId,
    EndMonthId: currEndmonthId,
    EndYearId: currEndYearId,
    toggleButtonValue: toggleButtonValue
  
  };

  const getConsumptionTrendProductChart = useQuery(
    [params1], api.getConsumptionTrendProductChart,
    {
      onSuccess: (data) => {
        console.log(data, "chart data");

        if (data.success === 1) {
          let month_name = data.datalist.month_name;
          let month_name1 = data.datalist.month_name1;
          let overview_name = data.datalist.overview_name;
          let colorarr = data.datalist.color;
          //overview_val = response.overview_val;
          let datalist = data.datalist.datalist;
          let numberformat = data.datalist.numberformat;
          let i;
          let seriesCounter = 0;
          let seriesOptions = [];

          for (i = 0; i < overview_name.length; i++) {
            //alert(datalist[i]);
            seriesOptions[i] = {
              color: colorarr[i],
              name: overview_name[i],
              data: datalist[i],
            };
            seriesCounter++;
          }
          if (seriesCounter == overview_name.length) {
            //	onSetLineChart(month_name, seriesOptions,numberformat,month_name1,colorarr);
          }

          setOption_receive_details({
            chart: {
              type: "line",
            },
            title: {
              text: null, //data.datalist.duration,
            },
            yAxis: {
              title: {
                text: "",
              },
            },
            xAxis: {
              categories: month_name,
              labels: {
                enabled: true,
                formatter: function (i) {
                  return month_name1[this.value];
                },
              },
            },
            tooltip: {
              shared: true,
            },
            credits: {
              enabled: false,
            },
            series: seriesOptions /* [
              {
                name: data.datalist.name,
                data: data.datalist.seriesdata.data,
                color: "#002f6c",
              },
            ],*/,
            //data.datalist.seriesdata,
            //  series: [
            //   {
            //     name: "Total Patients",
            //     data:  [179562],
            //     color: "#fe9929",
            //    },
            //   {
            //      name: "Adult Patients",
            //      data:  [173129],
            //      color: "#4f81bd",
            //    },
            //    {
            //      name: "Paediatric Patients",
            //      data:  [6433],
            //      color: "#c0504d",
            //    },
            //  ],
          });
        }
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    }
  );

  // ============ISSUE DISPENSE TREND END===========


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/ConsumptionTrendByProductPrint.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&ItemGroupId=" +
        currProductGroupId +
        "&MonthId=" +
        currmonthId +
        "&YearId=" +
        currYearId +
        "&EndMonthId=" +
        currEndmonthId +
        "&EndYearId=" +
        currEndYearId +
        "&toggleButtonValue=" +
        toggleButtonValue +
        "&ProductGroup=" +
        currItemGroupname +
        "&ItemNo=" +
        currProductId +
        "&ItemName=" +
        activeProductfre.name +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/ConsumptionTrendByProductExcel.php";

      window.open(
        finalUrl +
          "?menukey=" +
          menukey +
          "&ItemGroupId=" +
          currProductGroupId +
          "&MonthId=" +
          currmonthId +
          "&YearId=" +
          currYearId +
          "&EndMonthId=" +
          currEndmonthId +
          "&EndYearId=" +
          currEndYearId +
          "&toggleButtonValue=" +
          toggleButtonValue +
          "&ProductGroup=" +
          currItemGroupname +
          "&ItemNo=" +
          currProductId +
          "&ItemName=" +
          activeProductfre.name +
          "&lan=" +
          localStorage.getItem("LangCode") +
          "&TimeStamp=" +
          Date.now()
      );
  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <AfterLoginNavbar {...props} />
      <div className={`section signup-top-padding sw_inner_container`}>
        <div className="sw_inner_card">
          <div className="d-flex justify-product mb-2">
            <Grid item xs={12} sm={12}>
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(
                    DispensingLanguage[lan][menukey][
                      "National Consumption Trend by Product"
                    ]
                  )}
                </div>
              </div>
            </Grid>
          </div>

          <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container>
                <Grid item xs={11} sm={11}>
                <Grid container spacing={2}>
                   <Grid className="text-center" item xs={12} sm={12}>
                     <div className="float-center">
                       <ToggleButtonGroup
                         id="tglbuttongroup"
                         className="sw_ToggleButtonGroup"
                         color="primary"
                         value={toggleButtonValue}
                         exclusive
                         onChange={handleChangeToggle}
                       >
                         <ToggleButton value="0">
                           {DispensingLanguage[lan][menukey]["All"]}
                         </ToggleButton>
                         <ToggleButton value="1">
                         {DispensingLanguage[lan][menukey]["DISPENSED"]}
                         </ToggleButton>
                         <ToggleButton value="2">
                         {DispensingLanguage[lan][menukey]["FORECASTED"]}
                         </ToggleButton>
                         <ToggleButton value="3">
                         {DispensingLanguage[lan][menukey]["ISSUED"]}
                         
                         </ToggleButton>
                        
                       </ToggleButtonGroup>
                     </div>
                   </Grid>
                 </Grid>

                  <Grid container >
                    <Grid container spacing={2}>
                      <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="ProductGroup"
                            disableClearable
                            options={ProductGroup}
                           
                            onChange={(event, newValue) =>
                              handleProductGroupChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              ProductGroup[
                                ProductGroup.findIndex(
                                  (ProductGroup) =>
                                    ProductGroup.id == currProductGroupId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Group"
                                  ]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="getProductList"
                            disableClearable
                            options={getProductList}
                            value={activeProductfre}
                            onChange={(event, newValue) =>
                              handleProductListChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name || ""}
                            defaultValue={
                              Product_list.length > 0
                                ? getProductList[
                                    getProductList.findIndex(
                                      (getProductList) =>
                                        getProductList.id == currProductId
                                    )
                                  ]
                                : 0
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["Product"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="MonthList"
                            disableClearable
                            options={MonthList}
                            onChange={(event, newValue) =>
                              handleMonthChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              MonthList[
                                MonthList.findIndex(
                                  (MonthList) => MonthList.id == currmonthId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["Start Month"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="YearList"
                            disableClearable
                            options={YearList}
                            onChange={(event, newValue) =>
                              handleYearChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              YearList[
                                YearList.findIndex(
                                  (YearList) => YearList.id == currYearId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={DispensingLanguage[lan][menukey]["Start Year"]}
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="MonthList"
                            disableClearable
                            options={MonthList}
                            onChange={(event, newValue) =>
                              handleEndMonthChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              MonthList[
                                MonthList.findIndex(
                                  (MonthList) => MonthList.id == currEndmonthId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["End Month"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={2} sm={2} style={{ marginTop: "15px" }}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            className="sw_chosen_filter"
                            id="YearList"
                            disableClearable
                            options={YearList}
                            onChange={(event, newValue) =>
                              handleEndYearChange(event, newValue)
                            }
                            getOptionLabel={(option) => option.name}
                            defaultValue={
                              YearList[
                                YearList.findIndex(
                                  (YearList) => YearList.id == currEndYearId
                                )
                              ]
                            }
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  DispensingLanguage[lan][menukey]["End Year"]
                                }
                                variant="standard"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                 

                </Grid>

               
              </Grid>
            </CardContent>
          </Card>


          <div className="row">
            <div className="col-md-12 mb-3">
              <Card className="sw_card">
              <CardHeader
                  title={
                    DispensingLanguage[lan][menukey]["National Consumption Trend by Product"]
                  }
                />
                <CardContent>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={receive_details}
                    height={900}
                  />
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader
                  title={t(
                    DispensingLanguage[lan][menukey][
                      "National Consumption Trend by Product Data List"
                    ]
                  )}
                  action={
                    <div className="float-right sw_btn_control">
                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 sw_icons"
                        onClick={() => PrintMany()}
                      >
                        <i class="fas fa-print"></i>
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        className="mr-2 sw_icons"
                        onClick={() => ExcelMany()}
                      >
                        <i className="far fa-file-excel"></i>
                      </Button>
                    </div>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="uniqueName">
                      <ReactTabulator
                          columns={fieldList}
                          data={dataItems}
                          layout={"fitColumns"}
                          height={200}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
   

        </div>
      </div>
    </>
  );
};

export default ConsumptionTrendbyProduct;

const useStyles = makeStyles({
  PageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
  pt_grid: {
    paddingTop: "10px",
  },
});

// const useStyles = makeStyles({
//   dispenserContainer: {
//     backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
//     backgroundSize: "cover",
//     backgroundPosition: "top center",
//     minHeight: "745px",
//   },
//   dispenserPageTitle: {
//     marginTop: "60px",
//     color: "white",
//     background: "whitesmoke",
//     color: "black",
//     borderRadius: "10px",
//     padding: "1rem",
//   },
//   tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
//   fullWidth: {
//     width: "95%",
//   },
//   filterDiv: {
//     width: "80%",
//     display: "flex",
//   },
// });
