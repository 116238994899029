import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { DeleteOutline, Add, Cached } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";

import ProductsLotModal from "../../../services/ProductsLotModal";
import SingleProductLotModal from "../../../services/SingleProductLotModal";

import { cellFocusEditor, dateEditor } from "../../../services/Common";
import IssuedToGroupModal from "./IssuedToGroupModal";
import IssuedToModal from "./IssuedToModal";
import * as Service from "../../../services/Service.js";

import moment from "moment";

import { fr, enGB } from "date-fns/locale"; // import French and English locale
import "moment/locale/fr"; // import Moment.js French locale
import useModalPopupPost from "../../../hooks/useModalPopupDelete/useModalPopupPost";
import useModalPopupBacktoList from "../../../hooks/useModalPopupDelete/useModalPopupBacktoList";

let timeStamp = "";
let isDirty = false;
let masterDirty = false;

const DispenserFormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "issue";
  const FacilityId = localStorage.getItem("FacilityId");
  moment.locale(lan == "en_GB" ? "enGB" : "fr");
  const classes = useStyles();
  let ReceiveDetailsRef = null;
  const { t, i18n } = useTranslation();
  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const { bDeleteConfirm, modalPopupDelete } = useModalPopupPost({ menukey });
  const { bBacktoListConfirm, modalPopupBacktoList } = useModalPopupBacktoList({
    menukey,
  });
  const [open, setOpen] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  let getIssueToGroupList = JSON.parse(
    localStorage.getItem("IssueToGroupList")
  );
  let IssueToGroupList = getIssueToGroupList.data;
  let IsDispense = getIssueToGroupList.IsDispense;
  const [openLotChnage, setOpenLotChnage] = useState(false);
  const [manyTableCurrentRow, setManyTableCurrentRow] = useState([]);

  // let IssueToFacility = JSON.parse(localStorage.getItem("IssueToFacility"));
  /*
  const IssueToList = IssueToFacility.hasOwnProperty(props.formData.ItemGroupId)
    ? IssueToFacility[props.formData.ItemGroupId]
    : [];
*/

  // const IssueToList = IssueToFacility;

  const UsersListByFacility = JSON.parse(
    localStorage.getItem("UsersListByFacility")
  );

  const [IssuedToGroupOpen, setIssuedToGroupOpen] = useState(false);
  const [IssueToGroupCode, setIssueToGroupCode] = useState("");
  const [currIssuedToGroupId, setcurrIssuedToGroupId] = useState("");

  const [IssuedToOpen, setIssuedToOpen] = useState(false);
  const [IssueToCode, setIssueToCode] = useState("");
  const [IssueToBtn, setIssueToBtn] = useState(false);
  const [issuedToFacilityValue, setIssuedToFacilityValue] = useState({
    id: "",
    name: "",
  });

  const [IsOrderNo, setIsOrderNo] = useState(false);

  const [IssueToList, setIssueToListLocal] = useState([]);

  const cIssuedToList = () => {
    let IssueToParams = {
      action: "getIssuedToList",
      IssuedToGroupId: currIssuedToGroupId,
      FacilityId: FacilityId,
      language: lan,
    };
    Service.default
      .postApi("source/combo_generic.php", IssueToParams)
      .then((res) => {
        setIssueToListLocal(res.datalist);
      })
      .catch((err) => {});
  };

  /* ====Start of Print Excel Many Code=== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/issue_against_order_entry_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/issue_against_order_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* ====End of Excel Export Code=== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      case "ProductsLotChange":
        setOpenLotChnage(false);
        break;

      case "ProductsOpenLotChange":
        setOpenLotChnage(true);
        break;

      case "IssuedToGroupModal":
        setIssuedToGroupOpen(false);
        break;

      case "IssuedToGroupOpen":
        setIssuedToGroupOpen(true);
        break;

      case "IssuedToModal":
        setIssuedToOpen(false);
        break;

      case "IssuedToOpen":
        setIssuedToOpen(true);
        break;

      default:
        break;
    }
  };

 
  const addProduct = (NonOrderProductData) => {

 
    
    let rowsNotInGrid = [];
  
    if (manyTableData.length > 0) {

      console.log(NonOrderProductData);
      console.log(props.ManyTableData);

      
      rowsNotInGrid = NonOrderProductData.filter((obj1) => {
        return !manyTableData.some(
          (obj2) =>
            obj1.BatchId == obj2.BatchId 
        );
      });
      

    } else {
      rowsNotInGrid = NonOrderProductData;
    }


    let temNonordata = rowsNotInGrid;
    let rows = [];

    let autoId = 0;
    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.dirty = 1;
      newRow.ItemCode = row.ItemCode;
      newRow.ManufacturerId = row.ManufacturerId
      newRow.ManufacturerName = row.ManufacturerName
      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      

      // newRow.SourceId = row.SourceId;
      // newRow.SourceName = row.SourceName;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = null;

      manyTableData.forEach((rowssss, ij) => {
        if (rowssss.ItemNo == row.ItemNo) {
          newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
         
         
          return;
        }
      });
      newRow.SwBatchId = 0;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = 0;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;

      newRow.OrderId = row.OrderId;
      newRow.OrderItemId = row.OrderItemId;

      newRow.OrderId = null;
      newRow.OrderItemId = null;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      newRow.BatchId = row.BatchId;
      rows.push(newRow);


    });

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.dirty = row.dirty;
      newRow.ItemCode = row.ItemCode;
      newRow.ManufacturerId = row.ManufacturerId
      newRow.ManufacturerName = row.ManufacturerName
      newRow.GroupName = row.GroupName;
      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.OrderId = row.OrderId;
      newRow.OrderItemId = row.OrderItemId;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = row.Quantity * row.UnitPrice;
      newRow.SwBatchId = row.SwBatchId;

      // newRow.SourceId = row.SourceId;
      // newRow.SourceName = row.SourceName;
      newRow.BatchId = row.BatchId;
      rows.push(newRow);
    });

    isDirty=true;
    onSetDisable();

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
  };



  const changeProductLot = (NewLotRowPopup) => {

    
    
    let rowsNotInGrid = [];
  
    if (manyTableData.length > 0) {
 
      
      rowsNotInGrid = NewLotRowPopup.filter((obj1) => {
        return !manyTableData.some(
          (obj2) =>
            obj1.BatchId == obj2.BatchId 
        );
      });
      

    } else {
      rowsNotInGrid = NewLotRowPopup;
    }
    if(rowsNotInGrid.length==0){
      handleClose("ProductsLotChange");
      return;
    }
    
    // console.log("ssssssssssmanyTableCurrentRow: ", manyTableCurrentRow);
    // console.log("sssssssssssssssssNewLotRowPopup: ", NewLotRowPopup[0]);
    
    let temNewLotRowPopup = rowsNotInGrid[0];
    let rows = [];

    let curLotchangerowId = manyTableCurrentRow.SKU;
    // console.log('curLotchangerowId: ', curLotchangerowId);
    let curLotchangeItemListLotId = manyTableCurrentRow.ItemListLotId;
    // console.log('curLotchangeItemListLotId: ', curLotchangeItemListLotId);

    manyTableData.forEach((row, i) => {
      // autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.GroupName = row.GroupName;

      newRow.TransactionItemId = row.TransactionItemId;
      newRow.ReqQty = row.ReqQty;
      newRow.Quantity = row.Quantity;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      newRow.OrderRemainingQty = row.OrderRemainingQty;
      
      newRow.OrderId = row.OrderId;
      newRow.OrderItemId = row.OrderItemId;


      if(row.SKU === curLotchangerowId){
        //lot changing row in many table
        // console.log("Changing");
        newRow.SwBatchId = 1;
        newRow.dirty = 1;

        newRow.BatchId = temNewLotRowPopup.BatchId;
        newRow.CurrentStockQty = temNewLotRowPopup.CurrentStockQty;
        newRow.BatchNo = temNewLotRowPopup.BatchNo;
        newRow.ExpiryDate = temNewLotRowPopup.ExpiryDate;
        
        newRow.ManufacturerId = temNewLotRowPopup.ManufacturerId;
        newRow.ManufacturerName = temNewLotRowPopup.ManufacturerName;

        newRow.ItemListLotId = temNewLotRowPopup.ItemListLotId;
      }else{
        newRow.dirty = row.dirty;
        newRow.CurrentStockQty = row.CurrentStockQty;
        newRow.BatchNo = row.BatchNo;
        newRow.ExpiryDate = row.ExpiryDate;
        newRow.BatchId = row.BatchId;

        newRow.ManufacturerId = row.ManufacturerId;
        newRow.ManufacturerName = row.ManufacturerName;
       
        
        newRow.ItemListLotId = row.ItemListLotId;
        newRow.SwBatchId = 0;
      }

      
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      
      

      rows.push(newRow);
      isDirty=true;
      onSetDisable();
    });

    setManyTableData(rows);
    props.handleAdd(rows);

    handleClose("ProductsLotChange");
    
  };

  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    { title: "BatchId", field: "BatchId", visible: false },
     
    
    {
      title: "dirty",
      field: "dirty",
      visible: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 55,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 70,
      formatter: reactFormatter(
        <ActionButton
        /* insertBatchInUpdatedDetailGridData={props.insertBatchInUpdatedDetailGridData} */
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 130,
      //  headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      // headerFilter: true,
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity Ordered"],
      field: "OrderRemainingQty",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Current Stock"],
      field: "CurrentStockQty",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity Issued"],
      field: "Quantity",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: [
        "integer",
        "min:0",
        function (cell, value, parameters) {
          if (value == "") return true;
          // if (!props.isPositive) return true;
          const balance = cell.getRow().getCell("CurrentStockQty").getValue();
          const validstatus = parseInt(value) <= parseInt(balance);
          return validstatus;
        },
      ],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);

        let CellOldValue = cell._cell.oldValue;
        if (
          CellOldValue == null ||
          CellOldValue == "" ||
          CellOldValue == undefined
        ) {
          CellOldValue = "";
        }
        if (CellOldValue != curcellval) {
          currow.getCell("dirty").setValue(1);
          isDirty = true;
          onSetDisable();
        }
      },
    }, //
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 130,
      // cssClass: "tabluator-column-editable text-field-editable",
      // editor: props.bStockUpdate == 0 ? cellFocusEditor: '',
      // cellEdited: function (cell) {
      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      // }
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 110,
    },

    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      //width: 210,
      hozAlign: "left",
      headerHozAlign: "left",
    },

    // {
    //   title: "SourceId",
    //   field: "SourceId",
    //   visible: false,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Source"],
    //   field: "SourceName",
    //   width: 120,
    // },

    // {
    //   title: DispensingLanguage[lan][menukey]["Unit Price (CFA)"],
    //   field: "UnitPrice",
    //   width: 100,
    //   hozAlign: "right",
    //   headerHozAlign: "right",

    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Line Total (CFA)"],
    //   field: "LineTotal",
    //   width: "10%",
    //   formatter: "money",
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   bottomCalc: "sum",
    //   bottomCalcFormatter: "money",
    // },
  ];

  const onSetDisable = () => {
    if (isDirty == true) {
      //document.getElementById("is_dirty_message").classList.remove("dnone");
      document.getElementById("master_dirty_message").classList.remove("dnone");
    } else {
      //document.getElementById("is_dirty_message").classList.add("dnone");
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  };

  const onMasterSetDirty = () => {
    if (masterDirty == true) {
      document.getElementById("master_dirty_message").classList.remove("dnone");
    } else {
      document.getElementById("master_dirty_message").classList.add("dnone");
    }
  };

  /*
  const onPostBtnClick = () => {
    setbStockUpdate(true);
    props.handlePost();
  };

  const onSaveUpdateBtnClick = () => {
    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    //  props.handleUpdate();
  };

  */

  const onPostBtnClick = () => {
    if (ReceiveDetailsRef == null) return;

    ///===================Check qty is blank of any item==========/////////////
    /*
    let blankinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (row.Quantity === null || row.Quantity === "" || row.Quantity === 0) {
        blankinfo = row;
      }
    });

    if (Object.keys(blankinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["Blank quantity is not allowed"]) + ' - ' + blankinfo.ItemName,
        msgtype: 0,
      });
      return;
    }

    */
    ///===================Check qty is blank of any item==========/////////////

    ///===================Check duplicate lots exist in invoice==========/////////////
    // if (ReceiveDetailsRef == null) return;

    /*
    
    let invoiceLotIds = [];
    let duplicateinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if(invoiceLotIds.includes(row.ItemListLotId)){
        duplicateinfo = row;
      }else{
        invoiceLotIds.push(row.ItemListLotId);
      }
    });

    // console.log('invoiceLotIds: ', invoiceLotIds);
    // console.log('duplicateinfo: ', duplicateinfo);
    
    if(Object.keys(duplicateinfo).length>0){
      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["Duplicate Lot available"]) + ' - ' + duplicateinfo.ItemName,
        msgtype: false,
      });
      return;
    }

    */
    ///===================Check duplicate lots exist in invoice==========/////////////

    //setbStockUpdate(true);
    // props.handlePost();
    handlePost();
  };

  /*
  const handlePost2222 = async (e) => {

    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');
    // console.log('isval: ', validForm);
    // console.log('props from post: ', props);

    // if (validateForm(formData)) {
    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        
        
      }).then((willAction) => {
        if (willAction) {
          let fDtat = { ...validForm, "bStockUpdated": 1 };
          //console.log('fDtat: ', fDtat);
          mutateUpdate(fDtat);

        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          // let route = `${TransactionId}`;
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert


    }


  };

  */

  const handlePost = async (e) => {
    let manydataP = [];
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (row.dirty == 1) manydataP.push(row);
    });

    let validForm = props.checkValidateForm(manydataP, "post");

    if (validForm) {
      let fDtat = { ...validForm, bStockUpdated: 1, masterDirty: masterDirty };
      modalPopupDelete(
        mutateUpdate,
        fDtat,
        { PostQ: "modalQPost" },
        ModalNo,
        validForm
      );
    }
  };

  const ModalNo = (validForm) => {
    props.stockPosted(0);
    let route = `${validForm.TransactionId}`;
    props.history.push(route);
  };

  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    let manydata = ReceiveDetailsRef.table.getData();
    // if(manydata.length==0){
    //   manydata = [];
    // }
    // console.log('manydata: ', manydata);
    // console.log('manydata: ', manydata.length);

    setUpdatedDetailsGridDataHandler(manydata);
  };

  /*
  const onSaveUpdateBtnClick = () => {

    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    
  }

  */

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let manydataR = [];
    rows.forEach((row, i) => {
      if (row.dirty == 1) manydataR.push(row);
    });

    let validForm = props.checkValidateForm(manydataR, "edit");
    // console.log('isval: ', validForm);
    if (validForm) {
      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutate({ ...validForm, masterDirty: masterDirty });
      } else {
        //when update
        mutateUpdate({ ...validForm, masterDirty: masterDirty });
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveIssueInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
        masterDirty = false;
        onMasterSetDirty();
        isDirty = false;

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = `edit/${data.TransactionId}`;
        props.history.push(route);
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.updateIssueInv,
    {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) {
          masterDirty = false;
          onMasterSetDirty();
          isDirty = false;

          //  console.log("props: ",props.formData.TransactionId);
          //setbStockUpdate(data.bStockUpdated);
          // props.successSave(data.bStockUpdated);
          if (data.bStockUpdated == 1) {
            mutatePost(props.formData.TransactionId);
          } else {
            // props.refetchInvoice(data.bStockUpdated); //refetch();
            props.refetchInvoice(); //refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(
    api.IssueAgainstIndentPost,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          // setDeletedDataSet([]);

          if (data.data.success == 0) {
            //setbStockUpdate(0);
            props.stockPosted(0);
            props.refetchInvoice();
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          } else {
            // setbStockUpdate(1);
            props.stockPosted(1);
            props.refetchInvoice();

            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          props.refetchInvoice();
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      },
    }
  );

  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter((element) => element != data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    console.log("OrderItemId",rowData.OrderItemId);
    //alert(rowData.OrderItemId)
    //if (bStockUpdate == 0 && !rowData.OrderItemId)
    if (bStockUpdate == 0 && rowData.OrderItemId==null)
      return (
        <>
          <Cached
            onClick={() => {
              if (!bStockUpdate) {
                setManyTableCurrentRow(rowData);
                // console.log("Current Row......", rowData);
                // console.log("ItemCode......", rowData.ItemCode);
                // console.log("TransactionItemId......", rowData.TransactionItemId);
                // console.log("ItemListLotId......", rowData.ItemListLotId);
                handleClose("ProductsOpenLotChange");
              }
            }}
          />

          {
          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />

          }
        </>
      );
      else if (bStockUpdate == 0 && rowData.OrderItemId!='')
        return (
          <>
            <Cached
              onClick={() => {
                if (!bStockUpdate) {
                  setManyTableCurrentRow(rowData);
                  // console.log("Current Row......", rowData);
                  // console.log("ItemCode......", rowData.ItemCode);
                  // console.log("TransactionItemId......", rowData.TransactionItemId);
                  // console.log("ItemListLotId......", rowData.ItemListLotId);
                  handleClose("ProductsOpenLotChange");
                }
              }}
            />
   
          </>
        );
    else return <></>;
  }

  const [chosenValues, setChosenValues] = useState({
    IssuedToGroupId: { id: "", name: "" },
    IssuedToFacility: { id: "", name: "" },
  });

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]);

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    setManyTableData([]);
    props.handleReset();
  };

  const backToList = () => {
    if (masterDirty == true || isDirty == true) {
      modalPopupBacktoList(backToListCallBack, "/issue", {
        PostQ: "modalBackToListQ",
      });
    } else props.history.push("/issue");
  };

  const backToListCallBack = (PageUrl) => {
    masterDirty = false;
    isDirty = false;
    props.history.push(PageUrl);
  };

  const formOnchange = (e, cases, inputname) => {
    console.log("On form change", inputname);
    //isDirty=true;

    if (cases == "handleChange") props.handleChange(e);
    else if (cases == "handleRDateChange")
      props.handleRDateChange(e, inputname);

    masterDirty = true;
    onMasterSetDirty();
  };

  const [IssuedToGroupIdValue, setIssuedToGroupIdValue] = useState({
    id: "",
    name: "",
  });

  useEffect(() => {
    setIssuedToGroupIdValue(
      IssueToGroupList[
        IssueToGroupList.findIndex(
          (IssueToGroup_List) =>
            IssueToGroup_List.id == props.formData.IssuedToGroupId
        )
      ]
    );
  }, [props.formData.IssuedToGroupId]);

  const changeIssueToGroupValue = (val) => {
    const FLevel2 = val.id;
    let FLevelStr = FLevel2.toString();
    const FLevelArray = FLevelStr.split("_");
    if (FLevelArray.length > 1) {
      setIssueToBtn(false);
    } else {
      setIssueToBtn(true);
    }

    setIssuedToGroupIdValue(val);

    if (val != null) {
      props.formData.IssuedToGroupId = val.id;
    } else {
      props.formData.IssuedToGroupId = "";
    }

    props.handleChangeIssuedToGroupIdProps(val);
  };

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };

    chosenValuesData["IssuedToGroupId"] =
      IssueToGroupList[
        IssueToGroupList.findIndex(
          (IssueToGroup_List) =>
            IssueToGroup_List.IssuedToGroupId == IssueToGroupCode
        )
      ];
    setChosenValues(chosenValuesData);

    props.formData["IssuedToGroupId"] = IssueToGroupCode;
  }, [IssueToGroupCode]);

  const addIssuedToGroup = (IssueToGroupData) => {
    if (IssueToGroupData != "") {
      setIssueToBtn(true);

      handleClose("IssuedToGroupModal");
      setTimeout(
        () => setIssueToGroupCode(IssueToGroupData.IssuedToGroupId),
        500
      );
    }
  };

  const addIssuedTo = (IssueToData) => {
    if (IssueToData != "") {
      handleClose("IssuedToModal");
      setTimeout(() => setIssueToCode(IssueToData.IssuedToId), 500);
    }
  };

  const changeissuedToFacilityValue = (val) => {
    setIssuedToFacilityValue(val);

    if (val != null) {
      props.formData.IssuedToFacility = val.id;
    } else {
      props.formData.IssuedToFacility = "";
    }

    props.handleChangeissuedToFacilityProps(val);
  };

  React.useEffect(() => {
    if (props.formData.IssuedToGroupId !== "") {
      const FLevel2 = props.formData.IssuedToGroupId;
      let FLevelStr = FLevel2.toString();
      const FLevelArray = FLevelStr.split("_");
      if (FLevelArray.length > 1) {
        setIssueToBtn(false);
      } else {
        setIssueToBtn(true);
      }

      setcurrIssuedToGroupId(props.formData.IssuedToGroupId);
    }
  }, [props.formData.IssuedToGroupId]);

  useEffect(() => {
    if (currIssuedToGroupId != "") {
      cIssuedToList();
    }
  }, [currIssuedToGroupId]);

  useEffect(() => {
    if (props.formData.IssuedToFacility != "") {
      setIssuedToFacilityValue(
        IssueToList[
          IssueToList.findIndex(
            (IssueTo_List) => IssueTo_List.id == props.formData.IssuedToFacility
          )
        ]
      );
    }
  }, [props.formData.IssuedToFacility, IssueToList]);

  useEffect(() => {
    if (IssueToCode !== "") {
      let chosenValuesData = { ...chosenValues };

      chosenValuesData["IssuedToFacility"] =
        IssueToList[
          IssueToList.findIndex(
            (IssueTo_List) => IssueTo_List.id == IssueToCode
          )
        ];
      setChosenValues(chosenValuesData);

      props.formData["IssuedToFacility"] = IssueToCode;
    }
  }, [IssueToCode]);

  React.useEffect(() => {
    if (props.formData.OrderNo) {
      setIsOrderNo(true);
    }
  }, [props.formData.OrderNo]);

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Issue - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <div className="mr-4 mt-2 float-left">
                        <span
                          id="master_dirty_message"
                          className={"masterredtextcolor dnone"}>
                          {" "}
                          {
                            DispensingLanguage[lan][menukey][
                              "Form has dirty data"
                            ]
                          }
                        </span>
                      </div>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                        // onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/issue")}
                        onClick={() => backToList()}>
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <div className="mr-4 mt-2 float-left">
                      <span
                        id="master_dirty_message"
                        className={"masterredtextcolor dnone"}>
                        {" "}
                        {
                          DispensingLanguage[lan][menukey][
                            "Form has dirty data"
                          ]
                        }
                      </span>
                    </div>
                    <Button
                      // disabled={props.bStockUpdate}
                      disabled={
                        props.bStockUpdate || isLoadingUpdate || isLoadingPost
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}>
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>
                    <Button
                      //disabled={props.bStockUpdate}
                      disabled={
                        props.bStockUpdate ||
                        !(manyTableData.length > 0) ||
                        isLoadingUpdate ||
                        isLoadingPost
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}>
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/issue")}
                      onClick={() => backToList()}>
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}>
                      <i className="fas fa-print"></i>
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}>
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}>
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Issue Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        value={
                          props.formData.TransactionDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        //onChange={(e) => props.handleRDateChange(e, "TransactionDate")}
                        onChange={(e) =>
                          formOnchange(
                            e,
                            "handleRDateChange",
                            "TransactionDate"
                          )
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Issue Invoice No"]
                      }
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl
                      className={
                        IsDispense ? classes.fullWidth_1 : classes.fullWidth
                      }>
                      <Autocomplete
                        disabled={props.bStockUpdate || IsOrderNo}
                        autoHighlight
                        id="IssuedToGroupId"
                        options={IssueToGroupList}
                        onChange={(event, value) =>
                          changeIssueToGroupValue(value)
                        }
                        getOptionLabel={(option) => option.name}
                        value={IssuedToGroupIdValue}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.IssuedToGroupId}
                            label={
                              DispensingLanguage[lan][menukey]["Issue to Group"]
                            }
                            variant="standard"
                            error={props.errorObject.IssuedToGroupId}
                            helperText={props.errorObject.IssuedToGroupId}
                            required
                            id="IssuedToGroupId"
                            name="IssuedToGroupId"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                    {IsDispense ? (
                      <FormControl className={classes.fullWidth_2}>
                        <Add
                          className="add_plus"
                          hidden={
                            manyTableData.length > 0 ? true : props.bStockUpdate
                          }
                          onClick={() => handleClose("IssuedToGroupOpen")}
                        />
                      </FormControl>
                    ) : (
                      <></>
                    )}
                    <IssuedToGroupModal
                      handleClose={handleClose}
                      openOrder={IssuedToGroupOpen}
                      addIssuedToGroup={addIssuedToGroup}
                      {...props}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl
                      className={
                        IssueToBtn ? classes.fullWidth_1 : classes.fullWidth
                      }>
                      <Autocomplete
                        disabled={props.bStockUpdate || IsOrderNo}
                        autoHighlight
                        id="IssuedToFacility"
                        options={IssueToList}
                        onChange={(event, value) =>
                          changeissuedToFacilityValue(value)
                        }
                        getOptionLabel={(option) => option.name}
                        value={issuedToFacilityValue}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.IssuedToFacility}
                            label={DispensingLanguage[lan][menukey]["Issue to"]}
                            variant="standard"
                            error={props.errorObject.IssuedToFacility}
                            helperText={props.errorObject.IssuedToFacility}
                            required
                            id="IssuedToFacility"
                            name="IssuedToFacility"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                    {IssueToBtn ? (
                      <FormControl className={classes.fullWidth_2}>
                        <Add
                          className="add_plus"
                          hidden={
                            manyTableData.length > 0 ? true : props.bStockUpdate
                          }
                          onClick={() => handleClose("IssuedToOpen")}
                        />
                      </FormControl>
                    ) : (
                      <></>
                    )}
                    <IssuedToModal
                      handleClose={handleClose}
                      openOrder={IssuedToOpen}
                      addIssuedTo={addIssuedTo}
                      IssuedToGroupId={props.formData.IssuedToGroupId}
                      cIssuedToList={cIssuedToList}
                      {...props}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.OrderNo}
                      helperText={props.errorObject.OrderNo}
                      id="OrderNo"
                      name="OrderNo"
                      required
                      label={DispensingLanguage[lan][menukey]["Order No"]}
                      // value={formData.FirstName || "Mahmudul"}
                      value={props.formData.OrderNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                      locale={lan == "en_GB" ? enGB : fr}>
                      <KeyboardDatePicker
                        disabled={true}
                        required
                        error={props.errorObject.OrderDate}
                        helperText={props.errorObject.OrderDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="OrderDate"
                        label={DispensingLanguage[lan][menukey]["Order Date"]}
                        autoOk={true}
                        name="OrderDate"
                        fullWidth
                        showTodayButton={true}
                        value={props.formData.OrderDate || props.selectedDate}
                        format="dd/MM/yyyy"
                        //onChange={(e) => props.handleRDateChange(e, "TransactionDate")}
                        onChange={(e) =>
                          formOnchange(e, "handleRDateChange", "OrderDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <TextField
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      //onChange={(e) => props.handleChange(e)}
                      onChange={(e) =>
                        formOnchange(e, "handleChange", "Remarks")
                      }
                      disabled={props.bStockUpdate}
                    />
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Prepared By"]} <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.PreparedBy}
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','PreparedBy')}
                        disabled={props.IsCompleted || props.bStockUpdate}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UsersListByFacility-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Approved By"]} <span className="sw_star">*</span>
                      </InputLabel>
                      <Select
                        error={props.errorObject.ApprovedBy}
                        labelId="demo-simple-select-helper-label"
                        id="ApprovedBy"
                        name="ApprovedBy"
                        value={props.formData.ApprovedBy}
                        fullWidth
                        //onChange={(e) => props.handleChange(e)}
                        onChange={(e) =>formOnchange(e,'handleChange','ApprovedBy')}
                        disabled={props.IsCompleted || props.bStockUpdate}
                      >
                        {UsersListByFacility.map((item, index) => {
                          return (
                            <MenuItem
                              key={`UsersListByFacility-${index + 1}`}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.ApprovedBy}>
                        {props.errorObject.ApprovedBy}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  {/* new row */}
                  <Grid item xs={12} sm={12}>
                    <Card className="sw_card">
                      <CardContent>
                        <Grid container className="mb-2">
                          <Grid item xs={12} sm={12}>
                            { <div className="float-right sw_btn_control">
                              <Button
                                disabled={props.bStockUpdate}
                                className="mr-2"
                                variant="contained"
                                color="primary"
                                onClick={() => handleClose("ProductsOpen")}
                              >
                                {
                                  DispensingLanguage[lan][menukey][
                                  "SELECT PRODUCT"
                                  ]
                                }
                              </Button>
                            </div> }

                            <ProductsLotModal
                              handleClose={handleClose}
                              open={open}
                              FacilityId={FacilityId}
                              addProduct={addProduct}
                              {...props}
                            />

                            <SingleProductLotModal
                              handleClose={handleClose}
                              open={openLotChnage}
                              changeProductLot={changeProductLot}
                              manyTableCurrentRow={manyTableCurrentRow}
                              FacilityId={FacilityId}
                              {...props}
                            />
                          </Grid>
                        </Grid>
                        <ReactTabulator
                          ref={(r) => (ReceiveDetailsRef = r)}
                          columns={ReceiveDetailsColumns}
                          data={manyTableData.sort((a, b) => {
                            const groupComparison = a.GroupName.localeCompare(b.GroupName);
                            return groupComparison !== 0 ? groupComparison : a.ItemName.localeCompare(b.ItemName);
                          })}
                          //data={manyTableData}
                          height="350px"
                          layout={"fitColumns"}
                          options={{
                            groupBy: "GroupName",
                            columnCalcs: "both",
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* new row */}
                </Grid>
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
});
