import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { useQuery } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SourceModal = ({ handleClose, open, addProduct, ...props }) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "receive";

  let tblRef = null;
  const { data, refetch } = useQuery(
    "source",
    api.getSourceList
  );
  const [selectedProducts, setselectedProducts] = useState([]);

  const ProductColumns = [
    { title: "id", field: "SourceId", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'], formatter: "rownum", width: 50, hozAlign: "center", headerHozAlign: "center", headerSort: false },
    { title: DispensingLanguage[lan][menukey]['Source'], field: "SourceName", headerFilter: true },

  ];

  let selected = [];
  const ProdustDataItems = data?.map((item, index) => {
    return item;
  });

  const [btnDisable, setbtnDisable] = useState(false);
  const SaveSelectedProducts = () => {
    if (tblRef.table.getSelectedData().length > 0)
      setbtnDisable(true);
    setselectedProducts(tblRef.table.getSelectedData());
    setTimeout(() => setbtnDisable(false), 500);

  };

  useEffect(() => {
    if (tblRef == null) return;
    addProduct(selectedProducts);

  }, [selectedProducts]);


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('SourceClose')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('SourceClose')}
        >
          {DispensingLanguage[lan][menukey]['Source Lists']}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={{
              selectableRangeMode: 'click',
            }}

            selectable={1}
            columns={ProductColumns}
            data={ProdustDataItems}
            height="300px"
            layout={"fitColumns"}

          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={btnDisable}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
            {DispensingLanguage[lan][menukey]['ADD']}
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose('SourceClose')}
          >
            {DispensingLanguage[lan][menukey]['CANCEL']}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SourceModal;
