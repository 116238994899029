import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
import { checkLogin, checkUserPermission } from "../../../services/CheckUserAccess";

// import swal from "sweetalert";
// Products screen
import OrderToSupplier from "./OrderToSupplier";
import OrderToSupplierAdd from "./AddOrderToSupplier"
import OrderToSupplierEdit from "./EditOrderToSupplier";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "order-to-supplier";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  /*   const checkLogin = () => {  
      let token = sessionStorage.getItem("token");
      if (!token) {
        swal({
          title: "Oops!",
          text: 'token expired. Please login again',
          icon: "warning",
          buttons: ["No", "Yes"],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) { 
            window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
            sessionStorage.clear();
          }
        });
      }
    }; */

  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {   
    if(document.getElementById("Snackbarh")!=null)
      document.getElementById("Snackbarh").classList.remove("dnone"); 
      setMsgObj(obj);
      setTimeout(function(){
        if(document.getElementById("Snackbarh")!=null)
          document.getElementById("Snackbarh").classList.add("dnone"); 
       }, (obj.msgtype==1? 3000:8000)); 

  };
  const closeNoticeModal = (event, reason) => {
    if (reason == 'clickaway') {
      return;
    }
    if(document.getElementById("Snackbarh")!=null)
      document.getElementById("Snackbarh").classList.add("dnone"); 
   // setMsgObj({ isOpen: false });
  };

  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");
  //   // let token = Constants.token;
  //   //console.log(Constants.token);
  //   if (!token) {
  //     swal("Oops!", `token expired. Please login again`, "error");
  //     props.history.push("/login");
  //     sessionStorage.clear();
  //   }
  // };

  // const checkAccess = () => {
  //  // let auth = JSON.parse(sessionStorage.getItem("User_info"));
  //   let auth = Constants.USER_INFO;
  //   if (auth) {
  //     let menu = auth[0].menu;
  //     if (menu.length > 0) {
  //       var check = menu.filter(name => name.title.includes('Admin'));
  //       if(check.length == 0){
  //        props.history.push(menu[0].url);
  //       }
  //     } else {
  //       swal("Oops!", `You don't have permission to access any page`, "error");
  //       props.history.push("/login");
  //       sessionStorage.clear();
  //     }
  //   }
  // };

  return (
    hasUserPermission && (
      <div>
        <AfterLoginNavbar {...props} />
        <div className="section signup-top-padding sw_inner_container">

          <Switch>
            <Route
              path={`${path}/add`}
              render={(props) => (
                <OrderToSupplierAdd {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
            <Route
              path={`${path}/edit/:id`}
              render={(props) => (
                <OrderToSupplierEdit {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
            <Route
              path={`${path}/`}
              render={(props) => (
                <OrderToSupplier {...props} openNoticeModal={openNoticeModal} />
              )}
            ></Route>
          </Switch>

          <Notification
            closeNoticeModal={closeNoticeModal}
            msgObj={msgObj}
            {...props}
          ></Notification>
        </div>
      </div>)
  );
};

export default Index;

const useStyles = makeStyles({

});
