import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/export_import.css";

import { CloudUploadOutlined } from "@material-ui/icons";

//Import Preloader
import LoaderForGlobal from "services/LoaderForGlobal";

import swal from "sweetalert";

import { Grid, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useMutation } from "react-query";
import * as api from "../../../actions/api";
// import { el } from "date-fns/locale";


const PatientImport = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "patient-import";
  const FacilityId = localStorage.getItem("FacilityId");

  const classes = useStyles();
  const { useState } = React;

  const { t, } = useTranslation();
  // const queryClient = useQueryClient();

  const [buttonDisabled, setbuttonDisabled] = useState(true);
  const [UploadedFileName, setUploadedFileName] = useState();
  const [ErrorMgs, setErrorMgs] = useState();
  const [isLoading, setLoading] = useState(false);
  const [ErrorMsgShowing, setErrorMsgShowing] = useState(false);

  const [ShowErrorMsg, setShowErrorMsg] = useState();

  const [uploadFormData, setFormData] = useState({
    FileName: "",
    menukey: menukey,
    lan: lan,
    FacilityId: FacilityId,
    ImportType: ''
  });

  const APP_URL = process.env.REACT_APP_API_URL;

  const changeHandlerForFileUpload = (event) => {

    let selectedFile = event.target.files[0];

    if (selectedFile === undefined) {
      return;
    }

    setErrorMsgShowing(false);
    setLoading(true);
    setbuttonDisabled(false);

    let FileName = selectedFile.name;
    let FileExt = FileName.split(".").pop();
    let Filetype = FileExt.toLowerCase();

    //The file Check extension
    if (Filetype === "xlsx" || Filetype === "xls") {
      setErrorMgs("");
      handleFileUpload(selectedFile);
    } else {
      setErrorMgs(
        DispensingLanguage[lan][menukey]["Sorry, only excel file are allowed"]
      );
      setUploadedFileName("");
      uploadFormData["FileName"] = "";
      setbuttonDisabled(true);
      setLoading(false);
    }
  };

  const handleFileUpload = (selectedFile) => {
    const formData = new FormData();

    formData.append("FileName", selectedFile);

    let finalUrl = APP_URL + "upload/upload.php";

    fetch(finalUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.msgType === "success") {
          setUploadedFileName(res.FileName);
          uploadFormData["FileName"] = res.FileName;
          setErrorMgs("");
        } else {
          setErrorMgs(res.msg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setErrorMgs(error);
        setLoading(false);
      });
  };

  const { mutate } = useMutation(api.savePatientImport, {
    onSuccess: (data) => {
      let msg = data.message;
      if (data.success === 1) {
        swal(msg, "", "success");
      } else {

        // swal(msg, "", "error");
        setShowErrorMsg(msg);
        setErrorMsgShowing(true);
      }

      uploadFormData["FileName"] = "";
      setUploadedFileName("");
      setLoading(false);
      setbuttonDisabled(true);
    },
  });

  const handleSubmit = (data) => {
    setLoading(true);
    setbuttonDisabled(true);
    let FileName = uploadFormData.FileName;
    if (FileName !== "") {
      mutate(uploadFormData);
    } else {
      setErrorMgs(DispensingLanguage[lan][menukey]["Select a excel file"]);
      setLoading(false);
    }
  }

  const handleSubmitForDeleteImport = async (e) => {

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Confirm Delete and Import Message"]),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]),
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        uploadFormData["ImportType"] = 'delete_and_import';
        handleSubmit();
      }
    });
  };

  const handleSubmitForImportMerge = async (e) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Confirm Import and Merge Message"]),
      icon: "warning",
      buttons: [t(DispensingLanguage[lan][menukey]["No"]),
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        uploadFormData["ImportType"] = 'import_and_merge';
        handleSubmit();
      }
    });
  };

  /* =====Start of Excel Export Code==== */
  // const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  //  const ExcelTemplateDownload = (reportType) => {
  //    let finalUrl = EXCEL_EXPORT_URL + "report/patient_excel_template_download.php";

  //  window.open(finalUrl + "?menukey=" + menukey +  "&lan=" + localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
  //   );
  //  };
  /* =====End of Excel Export Code==== */

  return (
    <>
      <div className="sw_inner_card">
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Patient Import"]}
              </div>

            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <Grid container spacing={3}>
              <Grid item xs={2} sm={2}></Grid>
              <Grid item xs={8} sm={8}>
                {/* ====Upload Box=== */}
                <div className="tp-upload-box marginTopBottom">
                  <div className="tp-header">
                    {DispensingLanguage[lan][menukey]["Patient Import"]}

                    <div className="float-right sw_btn_control">

                      <Button
                        className="mr-2 pull-right"
                        variant="contained"
                        color="info"
                        onClick={() => {
                          props.history.push("/Patients");
                        }}
                      >
                        {t(DispensingLanguage[lan][menukey]["Back to Patients"])}
                      </Button>

                    </div>

                  </div>




                  <div className="tp-body">
                    <div className="tp-file-upload">
                      <input
                        type="text"
                        name="selectFile"
                        id="selectFile"
                        value={UploadedFileName}
                        placeholder={
                          DispensingLanguage[lan][menukey]["Choose a file"]
                        }
                        className="tp-form-control"
                        readOnly={true}
                      />
                      <div className="file_browse_box">
                        <input
                          type="file"
                          name="FileName"
                          id="FileName"
                          className="tp-upload"
                          onChange={changeHandlerForFileUpload}
                        />
                        <label for="FileName" className="file_browse_icon">
                          <span className="icon-upload">
                            <CloudUploadOutlined />
                          </span>
                          {DispensingLanguage[lan][menukey]["Browse"]}
                        </label>
                      </div>
                    </div>
                    <div className="selectFile text-left">
                      <span className="red">{ErrorMgs}</span>
                    </div>
                    <div className="SuccessMgs display-none"></div>
                  </div>
                  <div className="tp-footer text-right sw_relative">
                    {isLoading && <LoaderForGlobal />}
                    {/* <Button
                      className="mr-3 pull-left dnone"
                      variant="contained"
                      color="primary"
                      onClick={() => ExcelTemplateDownload()}
                    >
                      {DispensingLanguage[lan][menukey]["Excel Template Download"]}
                    </Button> */}

                    <Button
                      disabled={buttonDisabled}
                      className="pull-right"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmitForImportMerge()}
                    >
                      {DispensingLanguage[lan][menukey]["Import and Merge"]}
                    </Button>

                    <Button
                      disabled={buttonDisabled}
                      className="mr-3 pull-right"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmitForDeleteImport()}
                    >
                      {DispensingLanguage[lan][menukey]["Delete and Import"]}
                    </Button>
                  </div>
                </div>
                {/* ====Upload Box=== */}
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
            {ErrorMsgShowing &&
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div className="msg-error">
                    <textarea className="error-msg-box" rows="5" value={ShowErrorMsg} readOnly={true}></textarea>
                  </div>
                </Grid>
              </Grid>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientImport;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    // color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
