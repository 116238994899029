import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { ColorPicker } from "material-ui-color";

const FacilityLevelFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  handleChangeColorAdd,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "facility-level-entry";

  const classes = useStyles();
  const { t } = useTranslation();

  const handleChangeColor = (name) => {
    if (typeof name == "string") {
      formData["ColorCode"] = name;
      handleChangeColorAdd(name);
    } else {
      formData["ColorCode"] = name.css.backgroundColor;
      handleChangeColorAdd(name.css.backgroundColor);
    }
  };

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">
                {
                  DispensingLanguage[lan][menukey][
                  "Facility Level Entry Form - Add/Edit"
                  ]
                }
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["Facility Level Entry"]}
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.FLevelName}
                          helperText={errorObject.FLevelName}
                          required
                          id="FLevelName"
                          name="FLevelName"
                          label={
                            DispensingLanguage[lan][menukey]["Facility Level"]
                          }
                          value={formData.FLevelName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.FLevelNameFrench}
                          helperText={errorObject.FLevelNameFrench}
                          required
                          id="FLevelNameFrench"
                          name="FLevelNameFrench"
                          label={
                            DispensingLanguage[lan][menukey]["Facility Level(French)"]
                          }
                          value={formData.FLevelNameFrench}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.FLevelShortName}
                          helperText={errorObject.FLevelShortName}
                          required
                          id="FLevelShortName"
                          name="FLevelShortName"
                          label={
                            DispensingLanguage[lan][menukey][
                            "Short Facility Level"
                            ]
                          }
                          value={formData.FLevelShortName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.PriceMarkupMin}
                          helperText={errorObject.PriceMarkupMin}
                          required
                          id="PriceMarkupMin"
                          name="PriceMarkupMin"
                          label={
                            DispensingLanguage[lan][menukey]["Minimum Markup"]
                          }
                          value={formData.PriceMarkupMin}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.PriceMarkupMax}
                          helperText={errorObject.PriceMarkupMax}
                          required
                          id="PriceMarkupMax"
                          name="PriceMarkupMax"
                          label={
                            DispensingLanguage[lan][menukey]["Maximum Markup"]
                          }
                          value={formData.PriceMarkupMax}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          {/* COLOR FILED */}
                          <Grid item xs={6} sm={4} lg={2} className="">
                            {/* <FormLabel component="legend" >
                          {DispensingLanguage[lan][menukey]["Color Code"]}
                        </FormLabel> */}

                            <ColorPicker
                              error={errorObject.ColorCode}
                              helperText={errorObject.ColorCode}
                              required
                              name="ColorCode"
                              defaultValue="#00aabb"
                              value={formData.ColorCode}
                              onChange={handleChangeColor}
                            />
                          </Grid>
                          <Grid className="d-flex justify-content-start align-items-center ml-2" item xs={6} sm={3} lg={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={formData.IsOrder}
                                  onChange={(e) => handleCheck(e)}
                                  name="IsOrder"
                                  value="no"
                                />
                              }
                              label={
                                DispensingLanguage[lan][menukey]['Order']
                              }
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={formData.IsDispense}
                                  onChange={(e) => handleCheck(e)}
                                  name="IsDispense"
                                  value="no"
                                />
                              }
                              label={
                                DispensingLanguage[lan][menukey]['Dispense']
                              }
                            />

                            {/* content */}
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {t(DispensingLanguage[lan][menukey]["Reset"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {t(DispensingLanguage[lan][menukey]["Save"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/facility-level-entry")}
              >
                {t(DispensingLanguage[lan][menukey]["Cancel"])}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {t(DispensingLanguage[lan][menukey]["update"])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/facility-level-entry")}
              >
                {t(DispensingLanguage[lan][menukey]["Cancel"])}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default FacilityLevelFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
