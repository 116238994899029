import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";


const RoleFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "role-entry";
  const MenuUrl = JSON.parse(localStorage.getItem("MenuUrl"));
  // const ProductGroup_List = JSON.parse(localStorage.getItem("ProductGroupList"));
  const { t } = useTranslation();

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner"> {DispensingLanguage[lan][menukey]['Role Entry Form - Add/Edit']} </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Role Entry from']} />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={6} sm={5}>
                        <TextField

                          error={errorObject.role}
                          helperText={errorObject.role}
                          required
                          id="role"
                          name="role"
                          label={DispensingLanguage[lan][menukey]['Role Name']}
                          value={formData.role}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={5}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Default Redirect']}
                          </InputLabel>
                          <Select
                            error={errorObject.defaultredirect}
                            labelId="demo-simple-select-helper-label"
                            id="defaultredirect"
                            name="defaultredirect"
                            value={formData.defaultredirect}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            {
                              MenuUrl.map(
                                (item, index) => {
                                  return (
                                    <MenuItem key={index} value={item.url}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={errorObject.defaultredirect}>
                            {errorObject.defaultredirect}
                          </FormHelperText>
                        </FormControl>

                      </Grid>

                      {/* <Grid item xs={6} sm={3}>
                        <FormControl className={classes.fullWidth}>
                            <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Product Group']}*
                            </InputLabel>
                            <Select
                              error={errorObject.ItemGroupId}
                              required
                              labelId="demo-simple-select-helper-label"
                              id="ItemGroupId"
                              name="ItemGroupId"
                              value={formData.ItemGroupId}
                              fullWidth
                              onChange={(e) => handleChange(e)}
                            >
                                {
                              ProductGroup_List.map(
                              (item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              }
                            )}
                            </Select>
                            <FormHelperText error={errorObject.ItemGroupId}>
                              {errorObject.ItemGroupId}
                            </FormHelperText>
                          </FormControl>
                          
                      </Grid>
 */}


                      <Grid item xs={6} sm={2} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.bFacilitySelected}
                              onChange={(e) => handleCheck(e)}
                              name="bFacilitySelected"
                              value="no"
                            />
                          }
                          label={DispensingLanguage[lan][menukey]["Is Active"]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {t(DispensingLanguage[lan][menukey]['Reset'])}
              </Button>
              <Button
                className="mr-2"
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}
              >
                {t(DispensingLanguage[lan][menukey]['Save'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/role-entry")}
              >
                {t(DispensingLanguage[lan][menukey]['Cancel'])}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {t(DispensingLanguage[lan][menukey]['update'])}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/role-entry")}
              >
                {t(DispensingLanguage[lan][menukey]['Cancel'])}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default RoleFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
