import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";


// import { useTranslation } from "react-i18next";

const MenuEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "menu-entry";
  const ParentMenu = JSON.parse(localStorage.getItem("ParentMenu"));

  return (
    <div className="sw_topmargin">
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Menu Entry - Add/Edit']}</div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Menu Entry Form']} />
              <CardContent>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.title}
                          helperText={errorObject.title}
                          required
                          id="title"
                          name="title"
                          label={DispensingLanguage[lan][menukey]['Menu Name']}
                          value={formData.title}
                          fullWidth
                          autoComplete="family-name"
                          // inputProps={{ maxLength: 4 }}
                          onChange={(e) => handleChange(e)}

                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.url}
                          helperText={errorObject.url}
                          required
                          id="url"
                          name="url"
                          label={DispensingLanguage[lan][menukey]['Menu Url']}
                          value={formData.url}
                          fullWidth
                          autoComplete="family-name"
                          disabled={!addProductForm}
                          inputProps={{ maxLength: 150 }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>

                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{DispensingLanguage[lan][menukey]['Menu Position']}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="position"
                            value={formData.position}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={"left_position"}>Left Position</MenuItem>
                            <MenuItem value={"right_position"}>Right Position</MenuItem>
                          </Select>
                          {/* <FormHelperText error={errorObject.position}>
                          {errorObject.position}
                        </FormHelperText> */}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">{DispensingLanguage[lan][menukey]['Menu MenuLevel']}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="MenuLevel"
                            value={formData.MenuLevel}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={"menu_level_1"}>Menu Level 1</MenuItem>
                            <MenuItem value={"menu_level_2"}>Menu Level 2</MenuItem>
                            <MenuItem value={"menu_level_3"}>Menu Level 3</MenuItem>
                          </Select>
                          {/* <FormHelperText error={errorObject.MenuLevel}>
                          {errorObject.MenuLevel}
                        </FormHelperText> */}
                        </FormControl>

                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Parent menu"]}
                          </InputLabel>
                          <Select
                            //error={errorObject.parentId}
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            name="parentId"
                            value={formData.parentId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >

                            {ParentMenu.map((item, index) => {
                              return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                            })}
                          </Select>
                          {/* <FormHelperText error={errorObject.parentId}>
                          {errorObject.parentId}
                        </FormHelperText> */}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]['Reset']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/menu-entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['update']}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/menu-entry")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button>
            </Grid>
          )}

        </Grid>
      </div>
    </div>
  );
};

export default MenuEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
