import React, { useState, useEffect } from "react";
// import swal from "sweetalert";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import {
  ReactTabulator,
  // reactFormatter
} from "react-tabulator";

import { useQuery } from "react-query";
import * as api from "../../actions/api";

import {
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Select,
  // FormLabel,
  MenuItem,
  FormControl,
  Grid,
  Button,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import { getDefaultMonthYear } from "../../services/Common";

import { useTranslation } from "react-i18next";
import {
  checkLogin,
  checkUserPermission,
  // getUserActionPermission
} from "../../services/CheckUserAccess";


const DashboardPage = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const {
    t,
    // i18n 
  } = useTranslation();
  const menukey = "stock-status-details";
  const FacilityId = localStorage.getItem("FacilityId");
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const Facility_List = JSON.parse(localStorage.getItem("FacilityList"));

  let All_Facility_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All Facility"]) };
  const FacilityList = [All_Facility_label].concat(Facility_List);
  const bFacilitySelected = localStorage.getItem("bFacilitySelected");

  exporting(Highcharts);
  const classes = useStyles();

  // const [pickerData, setPickerData] = useState([
  //   {
  //     startDate: subDays(new Date(), 29),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );

  const [currFacilityId, setCurrFacilityId] = useState(bFacilitySelected == 1 ? FacilityId : 0);

  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
  };

  /*   const handleFacilityChange = (event) => {
      setCurrFacilityId(event.target.value);
    }; */

  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }

    setCurrFacilityId(rowId);
  };


  const [
    option_Stock_Status_Details,
    setOption_Stock_Status_Detailss,
  ] = useState([]);

  let params1 = {
    action: "getStockStatusDetailsChart",
    menukey: menukey,
    FacilityId: currFacilityId,
    Month: currMonthId,
    Year: currYearId,
  };
  const { data: stockDetailData } = useQuery([params1], api.getStockStatusDetailsChart, {
    onSuccess: (data) => {
      setOption_Stock_Status_Detailss({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          height: 495,
        },
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: t(DispensingLanguage[lan][menukey]["Stock Status Details"]),
            colorByPoint: true,
            data: data.data.StockStatusDetails.data,
          },
        ],
      })

      let Ndata = [];

      for (let i = 0; i < data.data.NationalStockCategory.data.length; i++) {
        let newdata = {};
        newdata.y = data.data.NationalStockCategory.data[i].y;
        newdata.color = data.data.NationalStockCategory.data[i].color;

        Ndata.push(newdata);
      }
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });


  const [columnsStockStatusTable, setColumnsStockStatusTable] = useState([]);
  const [stockStatusTableData, setStockStatusTableData] = useState([]);

  const { refetch: fetchDashboardStockStatusTableData } = useQuery(
    ["dashboardStockStatusTableDataList"],
    () => api.getStockStatusDetailsTable(currYearId, currMonthId, currFacilityId, menukey),
    {
      onSuccess: (data) => {

        setColumnsStockStatusTable([
          {
            title: DispensingLanguage[lan][menukey]["MOS Type"],
            field: "name",
            width: 350,
            headerSort: false,
          },
          {
            title: DispensingLanguage[lan][menukey]["Percentage"],
            field: "y",
            hozAlign: "right",
            headerHozAlign: "right",
            headerSort: false,
          }
        ]);

        setStockStatusTableData(data);

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  useEffect(() => {

    if (currYearId > 0 && currMonthId > 0) {
      fetchDashboardStockStatusTableData();
    }
  }, [currYearId, currMonthId, currFacilityId]);

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  /* const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
  }, []);

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintExportFunction = (reportType) => {
    let Print_finalUrl = EXCEL_EXPORT_URL + "report/StockStatusDetails_print.php";

    window.open(
      Print_finalUrl +
      "?reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" + Date.now() +
      "&Month=" + currMonthId +
      "&Year=" + currYearId +
      "&FacilityId=" + currFacilityId
    );

  };

  const ExcelExportFunction = (reportType) => {
    let Excel_finalUrl = EXCEL_EXPORT_URL + "report/StockStatusDetails_excel.php";

    window.open(
      Excel_finalUrl +
      "?reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TimeStamp=" + Date.now() +
      "&Month=" + currMonthId +
      "&Year=" + currYearId +
      "&FacilityId=" + currFacilityId
    );

  };

  /* =====End of Excel Export Code==== */

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div
          className="section signup-top-padding sw_inner_container">
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(DispensingLanguage[lan][menukey]["Stock Status Details"])}
                </div>
              </div>
            </div>
            {/* start of filter */}
            <Card className="sw_card sw_filter_card">
              <CardContent className="sw_filterCardContent">
                <Grid container spacing={1}>
                  <Grid item xs={1} sm={1}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="YearList"
                        name="YearList"
                        value={currYearId}
                        onChange={handleYearChange}
                        fullWidth
                      >
                        {YearList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="MonthList"
                        name="MonthList"
                        value={currMonthId}
                        onChange={handleMonthChange}
                        fullWidth
                      >
                        {MonthList.map((item, index) => {
                          return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      {/* <Select
                          labelId="demo-simple-select-helper-label"
                          id="FacilityList"
                          name="FacilityList"
                          value={currFacilityId}
                          onChange={handleFacilityChange}
                          fullWidth
                        >
                          <MenuItem value="0">
                            {t(DispensingLanguage[lan][menukey]["All Facility"])}
                          </MenuItem>

                          {FacilityList.map((item, index) => {
                            return <MenuItem value={item.id}>{item.name}</MenuItem>;
                          })}
                        </Select> */}

                      <Autocomplete
                        autoHighlight
                        className="sw_chosen_filter"
                        id="FacilityList"
                        disableClearable
                        options={FacilityList}
                        onChange={(event, newValue) => handleFacilityChange(event, newValue)}
                        getOptionLabel={(option) => option.name}
                        defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityId)]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                        )}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label={DispensingLanguage[lan][menukey]["All Facility"]}
                            variant="standard"
                            hiddenLabel
                          />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={6}></Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* end of filter */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12">
                <Card className="sw_card">
                  <CardHeader
                    title={t(
                      DispensingLanguage[lan][menukey]["Stock Status Details"]
                    )}
                  />
                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={option_Stock_Status_Details}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* new row */}

            {/* new row */}
            <div className="row">
              <div className="col-md-12">
                <Card className="sw_card">
                  <CardHeader
                    title={t(DispensingLanguage[lan][menukey]["Stock Status Details"])}

                    action={
                      <div className="float-right sw_btn_control">
                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 sw_icons"
                          onClick={() => PrintExportFunction("print")}
                        >
                          <i className="fas fa-print"></i>
                        </Button>

                        <Button
                          color="primary"
                          variant="contained"
                          className="mr-2 sw_icons"
                          onClick={() => ExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button>
                      </div>
                    }
                  />

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="uniqueName">
                          <ReactTabulator
                            columns={columnsStockStatusTable}
                            data={stockStatusTableData ? stockStatusTableData : []}
                            layout={"fitColumns"}
                          />
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end row */}

          </div>
        </div>
      </>)
  );
};

export default DashboardPage;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
