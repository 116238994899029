 
import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({ ...props }) => {
  

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id="Snackbarh" 
      open={props.msgObj.isOpen}
     // autoHideDuration={props.msgObj.duration===undefined?(props.msgObj.msgtype === 1?duration:5000):(props.msgObj.msgtype === 1?props.msgObj.duration:5000)}
      //autoHideDuration={props.msgObj.msgtype === 1?duration:8000}
    //  autoHideDuration={900}
   //  autoHideDuration={props.msgObj.msgtype === 1?5000:5000}
      onClose={props.closeNoticeModal}
    >
      <Alert
        onClose={props.closeNoticeModal}
        severity={
          props.msgObj.msgtype === 1
            ? "success"
            : props.msgObj.msgtype === 0
            ? "error"
            : ""
        }
        sx={{
          width:
            props.msgObj.msgtype === 1
              ? "100%"
              : props.msgObj.msgtype === 0
              ? "100%"
              : "0%",
        }}
      >
        {props.msgObj.msg}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
